import { Button } from '@shadcnComponent';

export function BackButton({
  showDateField,
  showSelectField,
  showNameField,
  showAccount,
  showProperties,
  isNonAuthConnector,
  handleBackClick,
  setShowConnectDataSourceDialog,
  connectedDataSourceLoading,
  setOAuthListing,
  clickUpSteps,
  setClickUpSteps,
  setTeamId,
  setSpaceId,
  setFolderId,
  setListId,
}) {
  const handleClick = () => {
    if (showDateField || showNameField || showAccount || showProperties || isNonAuthConnector || showSelectField) {
      handleBackClick();
    } else if (Object.values(clickUpSteps).includes(true)) {
      switch (true) {
      case clickUpSteps.listing:
        setClickUpSteps({ ...clickUpSteps, listing: false, folderListing: true });
        setListId(null);
        return;
      case clickUpSteps.folderListing:
        setClickUpSteps({ ...clickUpSteps, folderListing: false, spaceListing: true });
        setFolderId(null);
        return;
      case clickUpSteps.spaceListing:
        setClickUpSteps({ ...clickUpSteps, spaceListing: false, teamListing: true });
        setSpaceId(null);
        return;
      case clickUpSteps.teamListing:
        setClickUpSteps({ ...clickUpSteps, teamListing: false });
        setTeamId(null);
        return;
      }
    } else {
      setShowConnectDataSourceDialog({});
      setOAuthListing({});
    }
  };

  const getButtonText = () => {
    return showDateField || showNameField || showAccount || showProperties || isNonAuthConnector || showSelectField
      ? 'Back'
      : Object.values(clickUpSteps).includes(true)
        ? 'Back'
        : 'Close';
  };

  return (
    <Button
      variant='outline'
      className='h-10 w-full md:w-[8.75rem] lg:h-[1.875rem] lg:w-[6.25rem] xxl:w-[8.75rem]'
      onClick={handleClick}
      disabled={connectedDataSourceLoading}
    >
      {getButtonText()}
    </Button>
  );
}
