import { Label, Textarea } from '@shadcnComponent';
import { cn } from '@shadcnUtils';

export function FormTextArea({
  id,
  labelText,
  placeholder,
  value = '',
  handleChange,
  labelClassName,
  className,
  textAreaClassName,
  showError,
  ...restProps
}) {
  return (
    <div id={id} className={cn('flex flex-col gap-2 lg:gap-1.5', className)}>
      <div className='flex items-center justify-between'>
        <Label htmlFor={id} className={`${labelClassName}`}>
          {labelText}
        </Label>
      </div>
      <Textarea
        id={id}
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange}
        className={textAreaClassName}
        showError={showError}
        {...restProps}
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>{showError}</p>
      )}
    </div>
  );
}
