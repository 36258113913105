export function GoalTrackingDarkIcon({ className }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`h-6 w-6 md:h-5 md:w-5 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6 ${className}`}
    >
      <g clipPath='url(#clip0_6874_42512)'>
        <path
          d='M12.75 0C13.1875 0.0625 13.6313 0.10625 14.0675 0.1875C14.5037 0.26875 14.9225 0.36625 15.3444 0.4775C15.8025 0.59875 16.0387 1.0075 15.9156 1.42438C15.7944 1.83188 15.3906 2.04938 14.9462 1.91937C13.646 1.54046 12.2848 1.41631 10.9375 1.55375C9.16375 1.7375 7.53062 2.32375 6.06875 3.345C3.76625 4.95438 2.25688 7.125 1.72875 9.88937C0.97875 13.8038 2.1175 17.1469 5.04688 19.8469C6.57875 21.2594 8.41687 22.0969 10.4794 22.385C13.3681 22.7887 16.0081 22.1244 18.3337 20.3581C20.6075 18.6313 21.955 16.3275 22.3894 13.505C22.6122 12.0323 22.5106 10.5287 22.0919 9.09937C21.925 8.515 22.2794 8.03062 22.8319 8.05812C23.1525 8.07437 23.4369 8.27437 23.5081 8.61063C23.6731 9.3875 23.8069 10.1731 23.9525 10.9525C23.9712 11.0513 23.9844 11.1506 24 11.25V12.75C23.9869 12.8125 23.9694 12.87 23.9606 12.9312C23.8794 13.4862 23.8281 14.0481 23.7138 14.5962C22.7481 19.2525 18.7762 23.0556 14.0887 23.815C13.6438 23.8875 13.195 23.94 12.7481 24.0025H11.25C11.1975 23.99 11.1463 23.9725 11.0931 23.965C10.5456 23.885 9.9925 23.8319 9.45063 23.7237C4.79813 22.79 0.94375 18.7887 0.1875 14.1144C0.114375 13.6613 0.0625 13.205 0 12.75V11.25C0.076875 10.7188 0.13375 10.1831 0.2325 9.65625C1.12812 4.875 5.11562 0.973125 9.90875 0.1875C10.3537 0.11125 10.8031 0.0625 11.25 0H12.75Z'
          fill='white'
        />
        <path
          d='M23.9999 4.54688C23.8592 4.73 23.7355 4.92938 23.5749 5.0925C22.7967 5.88313 22.0036 6.655 21.2286 7.45125C21.018 7.67298 20.7313 7.80693 20.4261 7.82625C19.2911 7.93042 18.1571 8.04333 17.0242 8.165C16.9132 8.18867 16.8124 8.2466 16.7361 8.33062C15.3519 9.70562 13.9709 11.0856 12.593 12.4706C12.3861 12.6788 12.1511 12.795 11.8574 12.7362C11.7271 12.71 11.6061 12.6497 11.5068 12.5613C11.4076 12.473 11.3335 12.3599 11.2923 12.2335C11.2511 12.1072 11.2442 11.9722 11.2724 11.8423C11.3005 11.7124 11.3626 11.5924 11.4524 11.4944C11.6399 11.2944 11.8361 11.1056 12.0299 10.9125C13.2382 9.70417 14.448 8.49583 15.6592 7.2875C15.7733 7.18376 15.8429 7.04004 15.8536 6.88625C15.9555 5.76688 16.0749 4.64937 16.1805 3.53C16.2012 3.24844 16.327 2.98497 16.533 2.79187C17.3349 2 18.1249 1.1975 18.9236 0.40875C19.0817 0.25375 19.2755 0.135 19.453 0H19.828C20.2292 0.175625 20.3849 0.485 20.4055 0.919375C20.4411 1.69 20.523 2.4575 20.578 3.22688C20.588 3.365 20.6355 3.41438 20.773 3.42188C21.5424 3.47688 22.3105 3.55937 23.0805 3.59437C23.518 3.61437 23.8236 3.77125 23.9999 4.17188V4.54688ZM19.0167 2.42812C18.5861 2.85938 18.1924 3.245 17.8117 3.64313C17.7292 3.73099 17.6756 3.842 17.658 3.96125C17.5749 4.68125 17.5074 5.40375 17.4374 6.125C17.4217 6.2825 17.4099 6.44125 17.3949 6.61188C18.2824 6.52375 19.1355 6.43625 19.9892 6.35813C20.1515 6.34518 20.3025 6.26992 20.4105 6.14813C20.7399 5.80438 21.0817 5.47312 21.4174 5.13562C21.4618 5.08602 21.5035 5.03407 21.5424 4.98C21.028 4.94125 20.5467 4.9025 20.0655 4.86875C19.413 4.82312 19.1811 4.5925 19.1342 3.94187C19.0961 3.46062 19.0586 2.97875 19.0167 2.42812Z'
          fill='white'
        />
        <path
          d='M12.0073 3.6875C12.7573 3.7325 13.4929 3.82 14.2085 4.0325C14.3641 4.07601 14.5008 4.17022 14.5968 4.30019C14.6928 4.43016 14.7427 4.58847 14.7385 4.75C14.7385 5.07562 14.5404 5.36937 14.2167 5.44625C14.0349 5.4827 13.8472 5.47715 13.6679 5.43C10.4148 4.635 7.1379 6.2425 5.7854 9.305C4.03853 13.2619 6.54415 17.9375 10.7979 18.6581C14.4692 19.28 17.8698 16.98 18.6354 13.3456C18.8391 12.3628 18.8197 11.3468 18.5785 10.3725C18.5271 10.186 18.5154 9.99072 18.5442 9.79937C18.611 9.47 18.9154 9.26125 19.2504 9.25813C19.4121 9.25448 19.5703 9.30461 19.7004 9.40065C19.8305 9.49668 19.925 9.63319 19.9692 9.78875C20.1786 10.5098 20.2797 11.258 20.2692 12.0087C20.2335 15.7956 17.7629 19.0012 14.1179 19.9956C9.6629 21.2113 4.95103 18.3706 3.9404 13.865C2.9229 9.32625 5.7154 4.92438 10.2704 3.91563C10.8385 3.79 11.4279 3.76062 12.0073 3.6875Z'
          fill='white'
        />
        <path
          d='M12.1614 9.00002C10.5164 8.95877 9.21268 10.0875 9.01393 11.6875C8.83143 13.1531 9.85081 14.5694 11.3414 14.9219C13.1377 15.3494 14.9377 13.975 15.0002 12.1294C14.9983 12.0357 15.0044 11.9421 15.0183 11.8494C15.0555 11.6669 15.1593 11.5047 15.3095 11.3945C15.4597 11.2843 15.6455 11.2339 15.8308 11.2531C16.2158 11.2925 16.4989 11.6156 16.5046 12.0238C16.5321 13.9438 15.1727 15.7581 13.2927 16.3113C12.5992 16.517 11.8665 16.5527 11.1563 16.4156C10.4461 16.2785 9.77934 15.9724 9.21233 15.5233C8.64532 15.0742 8.19471 14.4953 7.89856 13.8354C7.60241 13.1755 7.46941 12.454 7.51081 11.7319C7.64393 9.25752 9.85893 7.3144 12.3233 7.50877C12.9008 7.5544 13.2514 7.93315 13.1539 8.40627C13.1223 8.58081 13.0293 8.7383 12.8918 8.85037C12.7543 8.96244 12.5813 9.02171 12.4039 9.01752C12.3052 9.01627 12.2114 9.00627 12.1614 9.00002Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_6874_42512'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
