export function HelpDesk({ className }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <path
        d='M14.1362 20.8515C14.1362 20.951 14.1362 21.035 14.1362 21.1223C14.1335 21.3531 14.0403 21.5737 13.8766 21.7364C13.7128 21.8991 13.4917 21.991 13.2608 21.9922C12.4254 22.0017 11.5904 22.0017 10.7559 21.9922C10.2556 21.9875 9.888 21.6057 9.87582 21.1013C9.87108 20.932 9.87108 20.7628 9.87582 20.5942C9.87519 20.4777 9.89769 20.3623 9.94202 20.2545C9.98635 20.1468 10.0516 20.049 10.1341 19.9667C10.2165 19.8843 10.3144 19.8192 10.4222 19.7751C10.53 19.7309 10.6455 19.7086 10.762 19.7094C11.2264 19.7013 11.6909 19.7053 12.1553 19.7094C13.9832 19.7168 15.5701 19.0743 16.962 17.9201C17.9098 17.1354 18.6329 16.1714 19.0695 15.0232C19.2523 14.5425 19.3011 14.0076 19.3931 13.4965C19.4771 13.0321 19.5346 12.5629 19.6064 12.0958C19.7608 11.0803 19.4906 10.1325 19.121 9.21175C17.8523 6.06638 14.7563 4.16129 11.3788 4.41449C7.80554 4.6853 4.83213 7.53752 4.39005 11.0952C4.3149 11.6997 4.44759 12.2711 4.52545 12.8554C4.671 13.9081 4.84499 14.9541 4.99935 16.0089C5.08939 16.6304 4.90119 16.937 4.29662 17.1022C3.94187 17.199 3.56952 17.2891 3.20597 17.2864C2.3746 17.281 1.79373 16.7888 1.37805 16.1314C0.387595 14.5581 0.252194 12.8818 0.97185 11.1724C1.32525 10.3335 1.98736 9.77434 2.90199 9.56447C3.04157 9.54044 3.18238 9.52417 3.32376 9.51572C3.34272 9.46901 3.36845 9.40943 3.39147 9.34783C4.65408 5.78001 7.14681 3.63526 10.8873 3.12006C15.2539 2.51888 19.2638 5.20727 20.5813 9.26455C20.6321 9.41959 20.6646 9.54551 20.8853 9.5577C22.1073 9.6254 22.7931 10.3904 23.1539 11.4608C23.7355 13.1878 23.5601 14.8458 22.4715 16.3426C22.0829 16.8768 21.5616 17.2783 20.8724 17.2945C20.4946 17.304 20.1128 17.1774 19.7344 17.1002C19.6098 17.0745 19.5218 17.0609 19.4331 17.1875C18.1468 19.0229 16.4184 20.2259 14.2574 20.8115C14.2249 20.819 14.1965 20.8319 14.1362 20.8515Z'
        fill='white'
      />
      <path d='M12.89 8.7041H11.9883V12.7224H12.89V8.7041Z' fill='url(#paint0_linear_4306_32605)' />
      <path d='M11.3411 9.66602H10.4395V13.6844H11.3411V9.66602Z' fill='url(#paint1_linear_4306_32605)' />
      <path
        d='M13.5125 10.6847V12.8765C13.5128 13.2096 13.4331 13.5379 13.2801 13.8338C13.1271 14.1297 12.9054 14.3846 12.6334 14.577C12.3615 14.7694 12.0474 14.8937 11.7174 14.9395C11.3875 14.9853 11.0514 14.9512 10.7374 14.8401C10.4233 14.729 10.1405 14.5442 9.91277 14.3011C9.68501 14.058 9.51892 13.7638 9.42846 13.4433C9.33801 13.1227 9.32583 12.7851 9.39295 12.4588C9.46007 12.1325 9.60453 11.8271 9.81419 11.5683V10.3284C9.46227 10.5558 9.16153 10.854 8.93111 11.2039C8.70069 11.5539 8.54564 11.9479 8.47581 12.3611C8.40599 12.7742 8.42292 13.1974 8.52553 13.6036C8.62814 14.0098 8.81419 14.3902 9.07183 14.7207C9.32948 15.0511 9.65308 15.3242 10.022 15.5228C10.391 15.7213 10.7973 15.8409 11.2149 15.8739C11.6326 15.9069 12.0526 15.8525 12.4482 15.7144C12.8437 15.5762 13.2062 15.3572 13.5125 15.0714V15.9016H14.4575V7.74414H13.5125V10.6847Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4306_32605'
          x1='12.4391'
          y1='8.7041'
          x2='12.4391'
          y2='12.7224'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00ACE7' />
          <stop offset='1' stopColor='#8AC33D' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4306_32605'
          x1='10.8903'
          y1='9.66602'
          x2='10.8903'
          y2='13.6844'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00ACE7' />
          <stop offset='1' stopColor='#8AC33D' />
        </linearGradient>
      </defs>
    </svg>
  );
}
