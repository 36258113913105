import { useContext, useEffect, useRef, useState } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { debounce, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useDebounce } from '@common/hooks';
import { ROUTES } from '@routes';
import {
  apiEndpoints,
  performGetRequest,
  useDeleteMutation,
  useGetQuery,
  usePatchMutation,
  usePostMutation,
} from '@services';
import { AppContext } from '@useContext';
import {
  fileResizeWithoutCrop,
  capitalizeSourceName,
  getNextPage,
  // getLocalStorageItem
} from '@utils';
import {
  ERROR_MESSAGE,
  WIDGETS_TYPE,
  SUCCESSFUL_MESSAGE,
  STATIC,
  DATE_FORMAT,
  VIDEO_REGEX,
  EMPTY_TEXT_STATIC_WIDGET,
  DATE_RANGE_OPTIONS,
  COLORS,
  DASHBOARD_VIEW_TYPE,
  FIELD_REQUIRED,
} from '@constants';

export function useDashboard() {
  const csvRef = useRef(null);
  const logoRef = useRef(null);
  const widgetImageRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { organizationId, dashboardId } = useParams();
  const { isMobileScreen } = useContext(AppContext);

  // const showAiToken = getLocalStorageItem('showAiToken');

  const [isEditMode, setIsEditMode] = useState(location?.state?.showEditMode || false);
  const [isEditWidgetMode, setIsEditWidgetMode] = useState(false);
  const [isCommentMode, setIsCommentMode] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [widgetCounter, setWidgetCounter] = useState(0);
  const [speedometerSizes, setSpeedometerSizes] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [isUploadedLogo, setIsUploadedLogo] = useState(null);
  const [uploadedLogoId, setUploadedLogoId] = useState(null);
  const [dashboardName, setDashboardName] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDashboardLoading, setDeleteDashboardLoading] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [isDateCleared, setIsDateCleared] = useState(false);
  const [showDateTimeFilter, setShowDateTimeFilter] = useState(false);
  const [dateRangeSet, setDateRangeSet] = useState();
  const [dateRangeStart, setDateRangeStart] = useState();
  const [dateRangeEnd, setDateRangeEnd] = useState();
  const [compareRangeSet, setCompareRangeSet] = useState();
  const [compareRangeEnd, setCompareRangeEnd] = useState();
  const [compareRangeStart, setCompareRangeStart] = useState();
  const [page, setPage] = useState(1);
  const [csvPage, setCsvPage] = useState(1);
  const [layoutWidth, setLayoutWidth] = useState(null);
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [isDateCompareRangeFilterApply, setIsDateCompareRangeFilterApply] = useState(false);
  const [showAddDataSourceDialog, setShowAddDataSourceDialog] = useState(false);
  const [selectedDataSourceIds, setSelectedDataSourceIds] = useState([]);
  const [addedDataSource, setAddedDataSource] = useState([]);
  const [dropZonePosition, setDropZonePosition] = useState(null);
  const [droppedWidget, setDroppedWidget] = useState(null);
  const [editWidget, setEditWidget] = useState({});
  const [editWidgetDateRange, setEditWidgetDateRange] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [editWidgetDateRangeStart, setEditWidgetDateRangeStart] = useState();
  const [editWidgetDateRangeEnd, setEditWidgetDateRangeEnd] = useState();
  const [editWidgetDateRangeSet, setEditWidgetDateRangeSet] = useState();
  const [editWidgetCompareRangeStart, setEditWidgetCompareRangeStart] = useState();
  const [editWidgetCompareRangeEnd, setEditWidgetCompareRangeEnd] = useState();
  const [editWidgetCompareRangeSet, setEditWidgetCompareRangeSet] = useState();
  const [isEditWidgetDateCleared, setIsEditWidgetDateCleared] = useState(false);
  const [uploadedWidgetImage, setUploadedWidgetImage] = useState(null);
  const [isUploadedWidgetImage, setIsUploadedWidgetImage] = useState(null);
  const [staticWidgetIndex, setStaticWidgetIndex] = useState(null);
  const [uploadedWidgetCsv, setUploadedWidgetCsv] = useState(null);
  const [isUploadedWidgetCsv, setIsUploadedWidgetCsv] = useState(null);
  const [selectSliderWidget, setSelectSliderWidget] = useState(0);
  const [dashboardMode, setDashboardMode] = useState();
  const [isNewSlideAdded, setIsNewSlideAdded] = useState(false);
  const [commentedWidget, setCommentedWidget] = useState(null);
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [removeWidgetSlide, setRemoveWidgetSlide] = useState({});
  const [isGoalTrackingMode, setIsGoalTrackingMode] = useState(false);
  const [showGoalDialog, setShowGoalDialog] = useState(false);
  const [goalId, setGoalId] = useState(null);
  const [removeGoalId, setRemoveGoalId] = useState(null);
  const [showDashboardGoalDialog, setShowDashboardGoalDialog] = useState(false);
  const [summaryContent, setSummaryContent] = useState('');

  const createDateRangeHandler =
    (setStartDate, setEndDate, setSelectedRange, setRange, setRangeDateFilterApply, setIsFilterApply) =>
    /* eslint-disable indent */
    selectedRange => {
      const today = moment();
      let startDate;
      switch (selectedRange.id || selectedRange) {
        case DATE_RANGE_OPTIONS.TODAY:
          startDate = today.clone();
          break;
        case DATE_RANGE_OPTIONS.YESTERDAY:
          startDate = today.clone().subtract(1, 'days');
          break;
        case DATE_RANGE_OPTIONS.LAST_7_DAY:
          startDate = today.clone().subtract(7, 'days');
          break;
        case DATE_RANGE_OPTIONS.LAST_30_DAYS:
          startDate = today.clone().subtract(30, 'days');
          break;
        default:
          startDate = today.clone();
      }

      setStartDate(startDate.format(DATE_FORMAT.DATE_FORMAT));
      setEndDate(moment().format(DATE_FORMAT.DATE_FORMAT));
      setSelectedRange(selectedRange);
      setRange && setRange();
      setRangeDateFilterApply && setRangeDateFilterApply(false);
      setIsFilterApply && setIsFilterApply(false);
    };
  /* eslint-enable indent */

  const handleSelectDateRange = createDateRangeHandler(
    setDateRangeStart,
    setDateRangeEnd,
    setDateRangeSet,
    setDateRange,
    setIsDateCompareRangeFilterApply,
    setIsFilterApply
  );
  const handleSelectCompareRange = createDateRangeHandler(
    setCompareRangeStart,
    setCompareRangeEnd,
    setCompareRangeSet,
    setDateRange,
    setIsDateCompareRangeFilterApply,
    setIsFilterApply
  );

  const handleSelectEditWidgetDateRange = createDateRangeHandler(
    setEditWidgetDateRangeStart,
    setEditWidgetDateRangeEnd,
    setEditWidgetDateRangeSet,
    setEditWidgetDateRange,
    setIsEditWidgetDateCleared
  );
  const handleSelectEditWidgetCompareRange = createDateRangeHandler(
    setEditWidgetCompareRangeStart,
    setEditWidgetCompareRangeEnd,
    setEditWidgetCompareRangeSet,
    setEditWidgetDateRange,
    setIsEditWidgetDateCleared
  );

  const debouncedSearch = useDebounce(dashboardName, 1000);

  let queryParams = {};

  if (dateRange)
    queryParams = {
      startDate: moment(dateRange?.from).format(DATE_FORMAT.RANGE_DATE),
      endDate: moment(dateRange?.to).format(DATE_FORMAT.RANGE_DATE),
    };
  else if (dateRangeStart)
    queryParams = {
      startDate1: moment(dateRangeStart).format(DATE_FORMAT.RANGE_DATE),
      endDate1: moment(dateRangeEnd).format(DATE_FORMAT.RANGE_DATE),
      startDate2: moment(compareRangeStart).format(DATE_FORMAT.RANGE_DATE),
      endDate2: moment(compareRangeEnd).format(DATE_FORMAT.RANGE_DATE),
    };
  else queryParams = {};

  const {
    data: dashboard,
    isLoading: dashboardFetching,
    refetch: dashboardRefetch,
  } = useGetQuery('dashboard', apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId), queryParams, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: resp => {
      setDashboardName(resp?.data?.name);
      setUploadedLogo(resp?.data?.logo?.image);
      resp?.data?.dashboardConfig && setWidgets(resp?.data?.dashboardConfig);
      setShowDateTimeFilter(false);
      isDateCompareRangeFilterApply && setIsFilterApply(true);
      setSelectedDataSourceIds(resp?.data?.dataSources);
      setDashboardMode(resp?.data?.viewType);
      resp?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES &&
        !resp?.data?.dashboardConfig?.length &&
        handleDashboardMode();
    },
    cacheTime: 0,
  });

  const {
    isFetching: summaryWidgetFetching,
    // refetch: summaryWidgetRefetch
  } = useGetQuery('summary-widget', apiEndpoints.GET_SUMMARY_WIDGET(organizationId, dashboardId), queryParams, {
    // enabled: !!showAiToken,
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: resp => setSummaryContent(resp?.data?.text),
    onError: ({ response: { data } }) => setSummaryContent(data?.detail),
  });

  const getTemplateCategory = ({ pageParam = 1 }) => performGetRequest(apiEndpoints.TEMPLATE_CATEGORY(pageParam));

  const {
    data: templateCategory,
    isFetching: templateCategoryFetching,
    fetchNextPage: templateCategoryNextPage,
    hasNextPage: templateCategoryHasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: ['template-category'],
    queryFn: getTemplateCategory,
    getNextPageParam: getNextPage,
  });

  const { data: widgetDimension, refetch: widgetDimensionRefetch } = useGetQuery(
    'widget-dimension',
    apiEndpoints.WIDGET_DIMENSION(editWidget?.connectorId),
    { table: editWidget?.table },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!editWidget?.connectorId && !isEmpty(editWidget?.table),
    }
  );

  const { data: addDataSourceListing } = useGetQuery(
    'add-dataSource-listing',
    apiEndpoints.DASHBOARD_DATA_SOURCE(organizationId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: showAddDataSourceDialog }
  );

  const { refetch: customWidgetsRefetch } = useGetQuery(
    'custom-widgets',
    apiEndpoints.CUSTOM_WIDGETS(organizationId, dashboardId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const handleRangeDateFilter = () => {
    dashboardRefetch();
    // showAiToken && summaryWidgetRefetch();
    setIsDateCleared(false);
    setIsDateCompareRangeFilterApply(true);
  };

  const handleEditWidgetRangeDateFilter = () => {
    let payload = {
      ...editWidget,
      dateRange: 'CUSTOM',
      startDate1: moment(editWidgetDateRangeStart).format(DATE_FORMAT.RANGE_DATE),
      endDate1: moment(editWidgetDateRangeEnd).format(DATE_FORMAT.RANGE_DATE),
      startDate2: moment(editWidgetCompareRangeStart).format(DATE_FORMAT.RANGE_DATE),
      endDate2: moment(editWidgetCompareRangeEnd).format(DATE_FORMAT.RANGE_DATE),
      comparisonDateRange: editWidgetDateRangeSet,
      comparisonCompareTo: editWidgetCompareRangeSet,
      isWidgetComparisonFilterApply: true,
    };

    setShowDatePicker(false);

    (editWidget?.startDate === '' || editWidget?.startDate) && delete payload['startDate'];
    (editWidget?.endDate === '' || editWidget?.endDate) && delete payload['endDate'];

    isEditWidgetDateCleared && delete payload['startDate1'];
    isEditWidgetDateCleared && delete payload['endDate1'];
    isEditWidgetDateCleared && delete payload['startDate2'];
    isEditWidgetDateCleared && delete payload['endDate2'];
    isEditWidgetDateCleared && delete payload['isWidgetComparisonFilterApply'];

    setEditWidget(payload);
    setIsEditWidgetDateCleared(false);
  };

  const handleEditCommentMode = widget => {
    setIsCommentMode(!isCommentMode);
    setCommentedWidget(widget);
  };

  const handleClearRangeDateFilter = () => {
    setDateRange();
    setDateRangeStart();
    setDateRangeEnd();
    setDateRangeSet();
    setCompareRangeStart();
    setCompareRangeEnd();
    setCompareRangeSet();
    setIsDateCleared(true);
    setIsFilterApply(false);
    setIsDateCompareRangeFilterApply(false);
  };

  const handleEditClearRangeDateFilter = () => {
    setEditWidgetDateRangeStart();
    setEditWidgetDateRangeEnd();
    setEditWidgetDateRangeSet();
    setEditWidgetCompareRangeStart();
    setEditWidgetCompareRangeEnd();
    setEditWidgetCompareRangeSet();
    setIsEditWidgetDateCleared(true);
  };

  const handleDateRange = date => {
    setDateRangeStart();
    setDateRangeEnd();
    setDateRangeSet();
    setCompareRangeStart();
    setCompareRangeEnd();
    setCompareRangeSet();
    setDateRange(date);
  };

  const { mutate: handleLogoUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedLogo(resp?.data?.image);
      setUploadedLogoId(resp?.data?.id);
      setIsUploadedLogo(false);
      logoRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : !isEmpty(data.image?.detail)
          ? toast.error(data.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedLogo(false);
      logoRef.current.value = '';
      setUploadedLogo(null);
    }
  );

  const { mutate: handleWidgetImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      if (staticWidgetIndex !== -1) {
        let updatedWidgets = [...widgets];

        const updateWidgetImage = (widgetArray, index) => ({
          ...widgetArray[index],
          image: resp?.data?.image,
        });

        if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
          updatedWidgets[selectSliderWidget][staticWidgetIndex] = updateWidgetImage(
            updatedWidgets[selectSliderWidget],
            staticWidgetIndex
          );
        } else updatedWidgets[staticWidgetIndex] = updateWidgetImage(updatedWidgets, staticWidgetIndex);

        handleDashboardUpdate({
          payload: {
            viewType: dashboardMode,
            dashboardConfig: dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? updatedWidgets : updatedWidgets.flat(),
          },
        });

        setWidgets(updatedWidgets);
      }
      setIsUploadedWidgetImage(false);
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedWidgetImage(false);
      widgetImageRef.current.value = '';
    }
  );

  const { mutate: handleWidgetCsvUpload } = usePostMutation(
    apiEndpoints.UPLOAD_CSV,
    resp => {
      if (staticWidgetIndex !== -1) {
        let updatedWidgets = [...widgets];

        const updateWidgetCsv = (widgetArray, index) => ({
          ...widgetArray[index],
          label: uploadedWidgetCsv?.name,
          displayLabel: uploadedWidgetCsv?.name,
          page: csvPage,
          dbId: resp?.data?.data?.id,
        });

        if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
          updatedWidgets[selectSliderWidget][staticWidgetIndex] = updateWidgetCsv(
            updatedWidgets[selectSliderWidget],
            staticWidgetIndex
          );
        } else updatedWidgets[staticWidgetIndex] = updateWidgetCsv(updatedWidgets, staticWidgetIndex);

        handleDashboardUpdate({
          payload: {
            viewType: dashboardMode,
            dashboardConfig: dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? updatedWidgets : updatedWidgets.flat(),
          },
        });
        setWidgets(updatedWidgets);
      }
      setIsUploadedWidgetCsv(false);
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
      setIsUploadedWidgetCsv(false);
      csvRef.current.value = '';
    }
  );

  const { mutate: handleAddDataSource, isLoading: addDataSourceLoading } = usePostMutation(
    apiEndpoints.ADD_DASHBOARD_DATA_SOURCE(organizationId, dashboardId),
    () => {
      setShowAddDataSourceDialog(false);
      customWidgetsRefetch();
      dashboardRefetch();
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleSaveAsTemplate, isLoading: saveAsTemplateLoading } = usePostMutation(
    apiEndpoints.SAVE_AS_TEMPLATE(organizationId, dashboardId),
    () => {
      setShowTemplateDialog(false);
      saveAsTemplateResetForm();
      toast.success(SUCCESSFUL_MESSAGE('Dashboard saved as template'));
    },
    ({ response: { data } }) => toast.error(data?.category[0])
  );

  const { mutate: handleDashboardUpdate } = usePatchMutation(
    apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId),
    resp => {
      setUploadedLogoId(null);
      setDashboardName(resp?.data?.name);
      dashboardRefetch();
      isNewSlideAdded && setSelectSliderWidget(() => dashboard?.data?.dashboardConfig.length);
      setIsNewSlideAdded(false);
      isMobileScreen && toast.success(SUCCESSFUL_MESSAGE('Widget added'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleCommentUpdate } = usePatchMutation(
    apiEndpoints.UPDATE_COMMENT(dashboardId, commentedWidget?.i)
  );

  const { mutate: handleDeleteDashboard } = useDeleteMutation(
    apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId),
    () => {
      setShowDeleteDialog(false);
      navigate(generatePath(ROUTES.DASHBOARD, { organizationId }));
      toast.success(SUCCESSFUL_MESSAGE('Dashboard deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteDashboardLoading(false);
    }
  );

  const { mutate: handleDeleteComment } = useDeleteMutation(
    apiEndpoints.DELETE_WIDGET_COMMENT(dashboardId, commentedWidget?.i)
  );

  const { mutate: handleDeleteWidgetGoal } = useDeleteMutation(
    apiEndpoints.DELETE_WIDGET_GOAL(organizationId, removeGoalId)
  );

  const { data: dashboardsListing } = useGetQuery(
    'team-dashboards-listing',
    apiEndpoints.CREATE_TEAM_DASHBOARDS_LISTING(organizationId),
    {},
    {
      enabled: !isEmpty(showGoalDialog) || showDashboardGoalDialog,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: resp => {
        setFieldValue(
          'dashboard',
          resp?.data?.results?.find(v => v.id === parseInt(dashboardId))
        );
      },
    }
  );

  const { data: widgetListing, refetch: widgetListingRefetch } = useGetQuery(
    'goal-widget-listing',
    apiEndpoints.WIDGET_LIST(organizationId, dashboardId),
    {},
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: resp => {
        if (!isEmpty(showGoalDialog)) {
          let result = resp?.data?.results?.find(v => v.widgetId === Object.keys(showGoalDialog)[0]);
          setFieldValue('widget', {
            id: parseInt(result?.widgetId),
            name: result?.widgetLabel,
            widgetSource: result?.widgetSource,
            widgetPrefix: result?.widgetPrefix,
          });
        }
      },
    }
  );

  const { mutate: handleCreateGoal, isLoading: createGoalLoading } = usePostMutation(
    apiEndpoints.CREATE_GOAL(organizationId),
    resp => {
      resetForm();
      setShowGoalDialog(false);
      setShowDashboardGoalDialog(false);
      setGoalId(resp?.data?.id);
      toast.success(SUCCESSFUL_MESSAGE('Goal created'));
    },
    ({ response: { data } }) => {
      setErrors({
        name: data.name[0] && data.name[0],
      });
    }
  );

  const { mutate: handleAddToDashboard } = usePostMutation(
    apiEndpoints.ADD_TO_DASHBOARD(organizationId, dashboardId, goalId),
    () => {
      setGoalId(null);
      dashboardRefetch();
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const goalInitialValues = {
    name: '',
    dashboard: '',
    timePeriod: '',
    description: '',
    widget: '',
    condition: '',
    metric: '',
  };

  const goalValidationSchema = Yup.object({
    name: Yup.string().required(FIELD_REQUIRED('Goal name')),
    dashboard: Yup.object().required(FIELD_REQUIRED('Dashboard')),
    timePeriod: Yup.object().required(FIELD_REQUIRED('Time period')),
    description: Yup.string().required(FIELD_REQUIRED('Description')),
    widget: Yup.object().required(FIELD_REQUIRED('Widget')),
    condition: Yup.object().required(FIELD_REQUIRED('Condition')),
    metric: Yup.string().required(FIELD_REQUIRED('Metric')),
  });

  const handleSubmit = () => {
    let payload = {
      name: values?.name,
      dashboard: values?.dashboard?.id,
      description: values?.description,
      widgetId: values?.widget?.id || values?.widget?.widgetId,
      widgetLabel: values?.widget?.name || values?.widget?.widgetLabel,
      widgetSource: values?.widget?.widgetSource.toUpperCase(),
      widgetPrefix: values?.widget?.widgetPrefix,
      periodInDays: values?.timePeriod?.id,
      target: parseInt(values?.metric),
      condition: values?.condition?.id,
    };
    handleCreateGoal({ payload });
  };

  const createFormik = useFormik({
    initialValues: goalInitialValues,
    onSubmit: handleSubmit,
    validationSchema: goalValidationSchema,
    enableReinitialize: true,
  });
  const { values, resetForm, setErrors, setFieldValue } = createFormik;

  const handleSelectedDataSource = id => {
    setSelectedDataSourceIds(prevIds =>
      prevIds.includes(id) ? prevIds.filter(item => item !== id) : [...prevIds, id]
    );
  };

  const handleOnDrag = (e, dataSource, customWidget) => {
    if (dataSource?.type === WIDGETS_TYPE.GOAL) {
      let dataObject = { dataSource };
      let widgetTypeAsString = JSON.stringify(dataObject);

      e.dataTransfer.setData('application/json', widgetTypeAsString);
    } else {
      let dataObject = { dataSource, customWidget };
      let widgetTypeAsString = JSON.stringify(dataObject);

      e.dataTransfer.setData('application/json', widgetTypeAsString);
    }
    setDroppedWidget(dataSource);
  };

  const addWidget = (dataSource, customWidget, e) => {
    const { type, dimensions, label, connectors, table } = dataSource;

    const isTextOrTableWidget = [WIDGETS_TYPE.TEXT, WIDGETS_TYPE.TABLE].includes(type);
    const isVideoOrImageWidget = [WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.IMAGE].includes(type);
    const isVideoOrImageOrCsvWidget = [WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.IMAGE, WIDGETS_TYPE.CSV].includes(type);
    const isTextWidget = [WIDGETS_TYPE.TEXT].includes(type);
    const isTableWidget = [WIDGETS_TYPE.TABLE].includes(type);

    const isVideoWidget = type === WIDGETS_TYPE.VIDEO;
    const isPieChartOrMapWidget = [WIDGETS_TYPE.PIE_CHART, WIDGETS_TYPE.MAP].includes(type);
    const isImageWidget = type === WIDGETS_TYPE.IMAGE;
    const isCsvWidget = type === WIDGETS_TYPE.CSV;

    const { layerX, layerY } = e.nativeEvent;

    const x = Math.floor(layerX / 100);
    const y = Math.floor(layerY / 30);

    let newWidget = null;

    if (dataSource?.type === WIDGETS_TYPE.GOAL) {
      newWidget = {
        ...dimensions[0],
        i: new Date().getTime().toString(),
        id: `${type}-${new Date().getTime().toString()}`,
        x: x % 12,
        y,
        w: dimensions[0]?.minW,
        h: dimensions[0]?.minH + 4,
        minH: dimensions[0]?.minH + 4,
        maxH: 21,
        minW: 3,
        label: dataSource?.name,
        displayLabel: dataSource?.name,
        themeColor: 'DEFAULT_VALUE',
        foreground: COLORS.DARK_PRIMARY,
        background: COLORS.WHITE_COLOR,
        themeStyle: 'light',
        connectorName: capitalizeSourceName(dataSource?.type),
        showLabel: true,
        goalId: dataSource?.id,
        widgetLabel: dataSource?.widgetLabel,
        target: dataSource?.target,
        achieved: dataSource?.achieved,
        percentage: dataSource?.percentage,
        trend: dataSource?.trend,
        timePeriod: dataSource?.timePeriod,
        remainingDays: dataSource?.remainingDays,
      };
    } else {
      newWidget = {
        ...dimensions[0],
        i: new Date().getTime().toString(),
        id: `${type}-${new Date().getTime().toString()}`,
        x: x % 12,
        y,
        w: isTextOrTableWidget
          ? 12
          : isVideoOrImageWidget
            ? 3
            : isPieChartOrMapWidget || isCsvWidget
              ? 6
              : dimensions[0]?.minW,
        h: isTextWidget ? 2 : isVideoOrImageOrCsvWidget ? 7 : dimensions[0]?.minH + 4,
        minH: isTextWidget ? 2 : isVideoOrImageOrCsvWidget ? 7 : dimensions[0]?.minH + 4,
        maxH: 21,
        minW: 3,
        connectorId: connectors?.id,
        connectorName:
          isTextWidget || isVideoOrImageOrCsvWidget
            ? STATIC
            : capitalizeSourceName(customWidget?.connector?.sourceName),
        logo: connectors?.logo?.image,
        accountName: customWidget?.accountName,
        prefix: customWidget?.prefix,
        label: isTextWidget
          ? 'Text'
          : isVideoWidget
            ? 'Video'
            : isImageWidget
              ? 'Image'
              : isCsvWidget
                ? 'CSV File'
                : label,
        displayLabel: isTextWidget
          ? 'Text'
          : isVideoWidget
            ? 'Video'
            : isImageWidget
              ? 'Image'
              : isCsvWidget
                ? 'CSV File'
                : label,
        page: type === WIDGETS_TYPE.TABLE ? page : type === WIDGETS_TYPE.CSV ? csvPage : '',
        dbId: '',
        dateRange: '',
        startDate: '',
        endDate: '',
        startDate1: '',
        endDate1: '',
        startDate2: '',
        endDate2: '',
        showCurvedLine: true,
        showIntegrationIcon: true,
        showLabel: true,
        themeColor: 'DEFAULT_VALUE',
        foreground: COLORS.DARK_PRIMARY,
        background: COLORS.WHITE_COLOR,
        themeStyle: 'light',
        table,
        limit: isTableWidget ? 10 : 100,
      };
    }

    if (isTextWidget || isVideoWidget || isImageWidget) {
      delete newWidget.accountName;
      delete newWidget.logo;
      delete newWidget.prefix;
      delete newWidget.page;
    }

    let updatedWidgets = [];

    if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
      updatedWidgets = [...widgets];
      updatedWidgets[selectSliderWidget] = [...widgets[selectSliderWidget], newWidget];
    } else updatedWidgets = [...widgets, newWidget];

    setWidgets(updatedWidgets);
    setWidgetCounter(prevCounter => prevCounter + 1);

    handleDashboardUpdate({
      payload: { viewType: dashboardMode, dashboardConfig: updatedWidgets },
    });
    setDropZonePosition(null);
  };

  const handleOnDrop = e => {
    const { dataSource, customWidget } = JSON.parse(e.dataTransfer.getData('application/json'));

    addWidget(dataSource, customWidget, e);
  };

  const AddWidgetsOnClick = (dataSource, customWidget, e) => addWidget(dataSource, customWidget, e);

  const handleDragOver = e => {
    e.preventDefault();
    const { layerX, layerY } = e.nativeEvent;
    const x = Math.floor(layerX / 100);
    const y = Math.floor(layerY / 30);
    setDropZonePosition({ x, y });
  };

  const handleDragLeave = () => setDropZonePosition(null);

  const handleResizeSpeedoMeter = (layout, oldItem, newItem) => {
    const updatedSpeedometerSizes = [...speedometerSizes];
    const speedometerIndex = widgets.findIndex(widget => widget.i === newItem.i);
    if (speedometerIndex !== -1) {
      updatedSpeedometerSizes[speedometerIndex] = { width: newItem.w * 100, height: newItem.h * 100 };
      setSpeedometerSizes(updatedSpeedometerSizes);
    }
  };

  const handleGridLayoutChange = layout => {
    const updatedWidgets = layout.map(item => {
      const originalLayout = widgets.flat([1]).find(layoutItem => layoutItem.i === item.i);
      return {
        ...item,
        id: originalLayout?.id,
        logo: originalLayout?.logo,
        connectorId: originalLayout?.connectorId,
        connectorName: originalLayout?.connectorName,
        label: originalLayout?.label,
        tooltip: originalLayout?.tooltip,
        displayLabel: originalLayout?.displayLabel,
        dbId: originalLayout?.dbId,
        dateRange: originalLayout?.dateRange,
        startDate: originalLayout?.startDate,
        endDate: originalLayout?.endDate,
        startDate1: originalLayout?.startDate1,
        endDate1: originalLayout?.endDate1,
        comparisonDateRange: originalLayout?.comparisonDateRange,
        comparisonCompareTo: originalLayout?.comparisonCompareTo,
        isWidgetComparisonFilterApply: originalLayout?.isWidgetComparisonFilterApply,
        startDate2: originalLayout?.startDate2,
        endDate2: originalLayout?.endDate2,
        accountName: originalLayout?.accountName,
        prefix: originalLayout?.prefix,
        url: originalLayout?.url,
        page: originalLayout?.page,
        showCurvedLine: originalLayout?.showCurvedLine,
        showIntegrationIcon: originalLayout?.showIntegrationIcon,
        showLabel: originalLayout?.showLabel,
        themeColor: originalLayout?.themeColor,
        foreground: originalLayout?.foreground,
        background: originalLayout?.background,
        themeStyle: originalLayout?.themeStyle,
        table: originalLayout?.table,
        metrics: originalLayout?.metrics,
        operationType: originalLayout?.operationType,
        limit: originalLayout?.limit,
        sortMetrics: originalLayout?.sortMetrics,
        sort: originalLayout?.sort,
        image: originalLayout?.image,
        csv: originalLayout?.csv,
        dimensions: originalLayout?.dimensions,
        goalId: originalLayout?.goalId,
        widgetLabel: originalLayout?.widgetLabel,
        target: originalLayout?.target,
        achieved: originalLayout?.achieved,
        percentage: originalLayout?.percentage,
        trend: originalLayout?.trend,
        timePeriod: originalLayout?.timePeriod,
        remainingDays: originalLayout?.remainingDays,
      };
    });

    [WIDGETS_TYPE.TEXT, WIDGETS_TYPE.VIDEO]?.includes(updatedWidgets?.type) &&
      (delete updatedWidgets['accountName'],
      delete updatedWidgets['logo'],
      delete updatedWidgets['prefix'],
      delete updatedWidgets['page']);

    const widgetUpdated = updatedWidgets?.find(widget => !widget.connectorName);

    if (isEmpty(widgetUpdated)) {
      if (dashboardMode === DASHBOARD_VIEW_TYPE.NORMAL) {
        setWidgets(updatedWidgets);
        handleDashboardUpdate({ payload: { viewType: dashboardMode, dashboardConfig: updatedWidgets.flat([1]) } });
      } else {
        const newWidgets = widgets.map((widget, index) => (index === selectSliderWidget ? updatedWidgets : widget));
        setWidgets(newWidgets);
        handleDashboardUpdate({ payload: { viewType: dashboardMode, dashboardConfig: newWidgets } });
      }
    }
  };

  /**
   * Handles the text change event for a text widget, updating the widget's label and the dashboard configuration.
   *
   * The function is debounced to delay execution by 1000 milliseconds (1 second) after the last event is triggered.
   *
   * @param {Event} e - The text change event.
   * @param {Object} textWidget - The text widget object that triggered the change.
   * @param {string} textWidget.i - The unique identifier of the text widget.
   */

  const handleTextChange = debounce((e, textWidget) => {
    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetIndex = widgetArray.findIndex(widget => widget.i === textWidget.i);

    if (e?.target?.innerText === '') {
      toast.error(EMPTY_TEXT_STATIC_WIDGET);
      return;
    }

    if (widgetIndex !== -1) {
      let updatedWidgets = [...widgets];
      if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
        const updatedSlideWidgets = [...updatedWidgets[selectSliderWidget]];
        updatedSlideWidgets[widgetIndex] = {
          ...updatedSlideWidgets[widgetIndex],
          label: e?.target?.innerText,
        };
        updatedWidgets[selectSliderWidget] = updatedSlideWidgets;
      } else {
        updatedWidgets[widgetIndex] = {
          ...updatedWidgets[widgetIndex],
          label: e?.target?.innerText,
        };
      }

      handleDashboardUpdate({
        payload: {
          viewType: dashboardMode,
          dashboardConfig: updatedWidgets,
        },
      });

      setWidgets(updatedWidgets);
    }
  }, 1000);

  /**
   * Removes a widget from the dashboard layout based on its unique identifier.
   *
   * @param {string} removeWidget - The widget to be removed.
   */

  const removeLayoutWidget = removeWidget => {
    let filteredWidgets = null;
    setCommentedWidget(removeWidget);
    handleDeleteComment();
    setRemoveGoalId(removeWidget?.i);
    if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
      filteredWidgets = widgets[selectSliderWidget]?.filter(widget => widget.i !== removeWidget?.i);
      widgets.splice(selectSliderWidget, 1, filteredWidgets);
      setWidgets(widgets);
      handleDashboardUpdate({ payload: { viewType: dashboardMode, dashboardConfig: widgets } });
    } else {
      filteredWidgets = widgets?.filter(widget => widget.i !== removeWidget?.i);
      setWidgets(filteredWidgets);
      handleDashboardUpdate({ payload: { viewType: dashboardMode, dashboardConfig: filteredWidgets } });
    }
  };

  /**
   * Handles the input change event for a video widget, updating the widget's URL and the dashboard configuration.
   *
   * @param {Event} e - The input change e.
   * @param {Object} videoWidget - The video widget object that triggered the change.
   * @param {string} videoWidget.i - The unique identifier of the video widget.
   */

  const handleVideoInputChange = (e, videoWidget) => {
    e.stopPropagation();
    const url = e.target.value;

    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetIndex = widgetArray.findIndex(widget => widget.i === videoWidget.i);

    if (widgetIndex !== -1 && VIDEO_REGEX.test(url)) {
      let updatedWidgets = [...widgets];
      if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
        const updatedSlideWidgets = [...updatedWidgets[selectSliderWidget]];
        updatedSlideWidgets[widgetIndex] = {
          ...updatedSlideWidgets[widgetIndex],
          url,
        };
        updatedWidgets[selectSliderWidget] = updatedSlideWidgets;
      } else {
        updatedWidgets[widgetIndex] = {
          ...updatedWidgets[widgetIndex],
          url,
        };
      }

      handleDashboardUpdate({
        payload: {
          viewType: dashboardMode,
          dashboardConfig: updatedWidgets,
        },
      });

      setWidgets(updatedWidgets);
    }
  };

  /**
   * Handles the input change event for an image widget, setting the uploaded image and updating the widget index.
   *
   * @param {Event} e - The input change event.
   * @param {Object} imageWidget - The image widget object that triggered the change.
   * @param {string} imageWidget.i - The unique identifier of the image widget.
   */

  const handleImageInputChange = (e, imageWidget) => {
    e.stopPropagation();
    const file = e.target.files[0];
    setUploadedWidgetImage(file);
    setIsUploadedWidgetImage(true);

    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetIndex = widgetArray.findIndex(widget => widget.i === imageWidget.i);

    setStaticWidgetIndex(widgetIndex);
  };

  /**
   * Handles the input change event for a CSV widget, setting the uploaded CSV file and updating the widget index.
   *
   * @param {Event} e - The input change event.
   * @param {Object} csvWidget - The CSV widget object that triggered the change.
   * @param {string} csvWidget.i - The unique identifier of the CSV widget.
   */

  const handleCsvInputChange = (e, csvWidget) => {
    e.stopPropagation();
    const file = e.target.files[0];

    setUploadedWidgetCsv(file);
    setIsUploadedWidgetCsv(true);

    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetIndex = widgetArray.findIndex(widget => widget.i === csvWidget.i);

    setStaticWidgetIndex(widgetIndex);
  };

  /**
   * Handles table pagination event, updating the page number for the specified table widget.
   *
   * @param {number} page - The new page number (0-based index) for pagination.
   * @param {Object} tableWidget - The table widget object to update.
   * @param {string} tableWidget.i - The unique identifier of the table widget.
   */

  const handleTablePagination = (page, tableWidget) => {
    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetIndex = widgetArray.findIndex(widget => widget.i === tableWidget.i);

    if (widgetIndex !== -1) {
      let updatedWidgets = [...widgets];
      if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
        const updatedSlideWidgets = [...updatedWidgets[selectSliderWidget]];
        updatedSlideWidgets[widgetIndex] = {
          ...updatedSlideWidgets[widgetIndex],
          page: page + 1,
        };
        setPage(page + 1);
        updatedWidgets[selectSliderWidget] = updatedSlideWidgets;
      } else {
        updatedWidgets[widgetIndex] = {
          ...updatedWidgets[widgetIndex],
          page: page + 1,
        };
        setPage(page + 1);
      }

      handleDashboardUpdate({
        payload: {
          viewType: dashboardMode,
          dashboardConfig: updatedWidgets,
        },
      });

      setWidgets(updatedWidgets);
    }
  };

  /**
   * Handles cloning a widget in the dashboard, creating a copy with updated properties.
   *
   * @param {string} widgetIndex - The unique identifier of the widget to clone.
   */

  const handleClone = widgetIndex => {
    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetToClone = widgetArray.find(widget => widget.i === widgetIndex);

    if (!isEmpty(widgetToClone)) {
      let updatedWidgets = [...widgets];
      const clonedWidget = {
        ...widgetToClone,
        i: new Date().getTime().toString(),
        x: widgetToClone.x + widgetToClone.w,
        y: widgetToClone.y,
      };

      if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) {
        const updatedSlideWidgets = [...updatedWidgets[selectSliderWidget], clonedWidget];
        updatedWidgets[selectSliderWidget] = updatedSlideWidgets;
      } else {
        updatedWidgets = [...updatedWidgets, clonedWidget];
      }

      handleDashboardUpdate({
        payload: {
          viewType: dashboardMode,
          dashboardConfig: updatedWidgets,
        },
      });

      setWidgets(updatedWidgets);
    }
  };

  const handleEditWidget = widget => {
    setIsEditMode(false);
    setIsEditWidgetMode(true);
    setEditWidget(widget);
    setCommentedWidget(widget);
  };

  const handleDateRangeChange = value => {
    const today = moment();
    let startDate;
    let payload = { ...editWidget };

    (editWidget?.startDate1 === '' || editWidget?.startDate1) && delete payload['startDate1'];
    (editWidget?.endDate1 === '' || editWidget?.endDate1) && delete payload['endDate1'];
    (editWidget?.startDate2 === '' || editWidget?.startDate2) && delete payload['startDate2'];
    (editWidget?.endDate2 === '' || editWidget?.endDate2) && delete payload['endDate2'];
    editWidget?.isWidgetComparisonFilterApply && delete payload['isWidgetComparisonFilterApply'];

    /* eslint-disable indent */
    switch (value) {
      case DATE_RANGE_OPTIONS.TODAY:
        startDate = today.clone();
        payload = {
          ...payload,
          dateRange: value,
          startDate: startDate.format(DATE_FORMAT.RANGE_DATE),
          endDate: today.clone().format(DATE_FORMAT.RANGE_DATE),
        };
        setEditWidget(payload);
        break;
      case DATE_RANGE_OPTIONS.YESTERDAY:
        startDate = today.clone().subtract(1, 'days');
        payload = {
          ...payload,
          dateRange: value,
          startDate: startDate.format(DATE_FORMAT.RANGE_DATE),
          endDate: today.clone().format(DATE_FORMAT.RANGE_DATE),
        };
        setEditWidget(payload);
        break;
      case DATE_RANGE_OPTIONS.LAST_7_DAY:
        startDate = today.clone().subtract(7, 'days');
        payload = {
          ...payload,
          dateRange: value,
          startDate: startDate.format(DATE_FORMAT.RANGE_DATE),
          endDate: today.clone().format(DATE_FORMAT.RANGE_DATE),
        };
        setEditWidget(payload);
        break;
      case DATE_RANGE_OPTIONS.LAST_30_DAYS:
        startDate = today.clone().subtract(30, 'days');
        payload = {
          ...payload,
          dateRange: value,
          startDate: startDate.format(DATE_FORMAT.RANGE_DATE),
          endDate: today.clone().format(DATE_FORMAT.RANGE_DATE),
        };
        setEditWidget(payload);
        break;
      default:
        startDate = today.clone();
    }
    /* eslint-disable indent */
  };

  const getDateRange = (selectRange, setStartDate, setEndDate, setRange, startDate, endDate) => {
    const today = moment();
    switch (selectRange) {
      case DATE_RANGE_OPTIONS.TODAY:
        startDate = today.clone();
        break;
      case DATE_RANGE_OPTIONS.YESTERDAY:
        startDate = today.clone().subtract(1, 'days');
        break;
      case DATE_RANGE_OPTIONS.LAST_7_DAY:
        startDate = today.clone().subtract(7, 'days');
        break;
      case DATE_RANGE_OPTIONS.LAST_30_DAYS:
        startDate = today.clone().subtract(30, 'days');
        break;
      case DATE_RANGE_OPTIONS.CUSTOM:
        startDate = moment(startDate);
        break;
      default:
        startDate = today.clone();
    }

    setStartDate(startDate.format(DATE_FORMAT.DATE_FORMAT));
    setEndDate(moment(endDate).format(DATE_FORMAT.DATE_FORMAT));
    setRange(selectRange);
  };

  const handleDashboardMode = mode => {
    setDashboardMode(mode);

    const dashboardConfig = mode === DASHBOARD_VIEW_TYPE.NORMAL ? widgets.flat() : [widgets];

    handleDashboardUpdate({ payload: { viewType: mode, dashboardConfig } });
  };

  const addDashboardSlide = () => {
    const addedSlide = [...widgets, []];
    setWidgets(addedSlide);
    setIsNewSlideAdded(true);
    handleDashboardUpdate({ payload: { viewType: DASHBOARD_VIEW_TYPE.SLIDES, dashboardConfig: addedSlide } });
  };

  const handleRemoveSlide = slideIndex => {
    let updatedWidgets = widgets?.filter((widget, index) => index !== slideIndex);

    handleDashboardUpdate({
      payload: {
        viewType: dashboardMode,
        dashboardConfig: updatedWidgets,
      },
    });

    setWidgets(updatedWidgets);
  };

  const saveAsTemplateInitialValues = {
    templateName: '',
    category: '',
  };

  const saveAsTemplateValidationSchema = Yup.object({
    templateName: Yup.string().required(FIELD_REQUIRED('Template name')),
    category: Yup.string().required(FIELD_REQUIRED('Category')),
  });

  const saveAsTemplateHandleSubmit = values => {
    let payload = {
      name: values?.templateName,
      category: values?.category,
    };
    handleSaveAsTemplate({ payload });
  };

  const saveAsTemplateFormik = useFormik({
    initialValues: saveAsTemplateInitialValues,
    onSubmit: saveAsTemplateHandleSubmit,
    validationSchema: saveAsTemplateValidationSchema,
  });

  const { resetForm: saveAsTemplateResetForm } = saveAsTemplateFormik;

  useEffect(() => {
    if (isEmpty(editWidget)) return;

    const widgetArray = dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES ? widgets[selectSliderWidget] : widgets;
    const widgetIndex = widgetArray.findIndex(widget => widget.i === editWidget.i);

    const setCustomDateRange = (editWidget, startDateKey, endDateKey, setRange) => {
      if (editWidget?.dateRange === DATE_RANGE_OPTIONS.CUSTOM && editWidget[startDateKey] && editWidget[endDateKey]) {
        setRange({
          from: moment(editWidget[startDateKey]).toDate(),
          to: moment(editWidget[endDateKey]).toDate(),
        });
      } else setRange({});
    };

    const handleCustomComparisonRange = (
      comparisonDateRangeKey,
      startDateKey,
      endDateKey,
      setStart,
      setEnd,
      setRangeSet
    ) => {
      if (editWidget?.dateRange === DATE_RANGE_OPTIONS.CUSTOM && editWidget[comparisonDateRangeKey]) {
        getDateRange(
          editWidget[comparisonDateRangeKey],
          setStart,
          setEnd,
          setRangeSet,
          editWidget[startDateKey],
          editWidget[endDateKey]
        );
      } else handleEditClearRangeDateFilter();
    };

    setCustomDateRange(editWidget, 'startDate', 'endDate', setEditWidgetDateRange);
    handleCustomComparisonRange(
      'comparisonDateRange',
      'startDate1',
      'endDate1',
      setEditWidgetDateRangeStart,
      setEditWidgetDateRangeEnd,
      setEditWidgetDateRangeSet
    );
    handleCustomComparisonRange(
      'comparisonCompareTo',
      'startDate2',
      'endDate2',
      setEditWidgetCompareRangeStart,
      setEditWidgetCompareRangeEnd,
      setEditWidgetCompareRangeSet
    );

    if (widgetIndex !== -1) {
      const updatedWidgets = [...widgets];

      if (dashboardMode === DASHBOARD_VIEW_TYPE.SLIDES) updatedWidgets[selectSliderWidget][widgetIndex] = editWidget;
      else updatedWidgets[widgetIndex] = editWidget;

      setPage(1);
      setCsvPage(1);
      setWidgets(updatedWidgets);
      handleDashboardUpdate({
        payload: {
          viewType: dashboardMode,
          dashboardConfig: updatedWidgets,
        },
      });
    }
  }, [editWidget]);

  useEffect(() => {
    if (uploadedLogoId) handleDashboardUpdate({ payload: { logo: uploadedLogoId } });
  }, [uploadedLogoId]);

  useEffect(() => {
    if (dashboardName && !isEqual(dashboard?.data?.name, dashboardName))
      handleDashboardUpdate({ payload: { name: dashboardName?.slice(0, 40) } });
  }, [dashboard?.data?.name, debouncedSearch]);

  useEffect(() => {
    isDateCleared && dashboardRefetch();
    // showAiToken && summaryWidgetRefetch();
  }, [isDateCleared]);

  useEffect(() => {
    const filteredData = addDataSourceListing?.data?.results?.filter(data => selectedDataSourceIds?.includes(data?.id));
    setAddedDataSource(filteredData);
  }, [selectedDataSourceIds, addDataSourceListing?.data?.results]);

  useEffect(() => {
    if (isUploadedWidgetImage) {
      fileResizeWithoutCrop(uploadedWidgetImage, 300, 300, handleWidgetImageUpload);
    }
  }, [isUploadedWidgetImage]);

  useEffect(() => {
    if (isUploadedWidgetCsv) {
      const formData = new FormData();
      formData.append('csv_file', uploadedWidgetCsv);

      handleWidgetCsvUpload({
        payload: formData,
      });
    }
  }, [isUploadedWidgetCsv]);

  useEffect(() => {
    goalId && handleAddToDashboard({});
  }, [goalId]);

  useEffect(() => {
    removeGoalId && handleDeleteWidgetGoal({});
  }, [removeGoalId]);

  useEffect(() => {
    values?.dashboard?.id && widgetListingRefetch();
  }, [values?.dashboard?.id]);

  return {
    isEditMode,
    setIsEditMode,
    isEditWidgetMode,
    setIsEditWidgetMode,
    isCommentMode,
    setIsCommentMode,
    widgetCounter,
    setWidgetCounter,
    handleOnDrag,
    handleOnDrop,
    handleDragOver,
    speedometerSizes,
    handleResizeSpeedoMeter,
    handleGridLayoutChange,
    dashboard,
    dashboardFetching,
    logoRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    isUploadedLogo,
    dashboardName,
    setDashboardName,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    setDeleteDashboardLoading,
    handleDeleteDashboard,
    removeLayoutWidget,
    handleTextChange,
    handleVideoInputChange,
    dateRange,
    setDateRange,
    handleRangeDateFilter,
    showDateTimeFilter,
    setShowDateTimeFilter,
    handleClearRangeDateFilter,
    setIsDateCleared,
    dateRangeSet,
    handleSelectDateRange,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    compareRangeSet,
    handleSelectCompareRange,
    compareRangeEnd,
    compareRangeStart,
    setCompareRangeEnd,
    setCompareRangeStart,
    handleDateRange,
    page,
    csvPage,
    handleTablePagination,
    layoutWidth,
    setLayoutWidth,
    AddWidgetsOnClick,
    isDateCleared,
    isFilterApply,
    showAddDataSourceDialog,
    setShowAddDataSourceDialog,
    addedDataSource,
    handleSelectedDataSource,
    addDataSourceListing,
    selectedDataSourceIds,
    handleAddDataSource,
    addDataSourceLoading,
    handleDragLeave,
    dropZonePosition,
    droppedWidget,
    summaryWidgetFetching,
    summaryWidget: summaryContent,
    handleClone,
    handleEditWidget,
    editWidget,
    setEditWidget,
    editWidgetDateRange,
    setEditWidgetDateRange,
    handleDateRangeChange,
    showDatePicker,
    setShowDatePicker,
    handleSelectEditWidgetDateRange,
    handleSelectEditWidgetCompareRange,
    handleEditClearRangeDateFilter,
    editWidgetDateRangeStart,
    editWidgetDateRangeEnd,
    editWidgetDateRangeSet,
    editWidgetCompareRangeStart,
    editWidgetCompareRangeEnd,
    editWidgetCompareRangeSet,
    setEditWidgetDateRangeStart,
    setEditWidgetDateRangeEnd,
    setEditWidgetCompareRangeStart,
    setEditWidgetCompareRangeEnd,
    handleEditWidgetRangeDateFilter,
    widgetDimension,
    widgetDimensionRefetch,
    handleImageInputChange,
    widgetImageRef,
    handleCsvInputChange,
    csvRef,
    handleDashboardMode,
    selectSliderWidget,
    setSelectSliderWidget,
    dashboardMode,
    addDashboardSlide,
    organizationId,
    dashboardId,
    handleRemoveSlide,
    handleLogoUpload,
    isUploadedWidgetImage,
    isUploadedWidgetCsv,
    handleEditCommentMode,
    commentedWidget,
    setCommentedWidget,
    handleCommentUpdate,
    saveAsTemplateLoading,
    showTemplateDialog,
    setShowTemplateDialog,
    templateCategory,
    templateCategoryFetching,
    templateCategoryNextPage,
    templateCategoryHasNextPage,
    removeWidgetSlide,
    setRemoveWidgetSlide,
    isGoalTrackingMode,
    setIsGoalTrackingMode,
    showGoalDialog,
    setShowGoalDialog,
    createFormik,
    dashboardsListing,
    widgetListing,
    createGoalLoading,
    showDashboardGoalDialog,
    setShowDashboardGoalDialog,
    saveAsTemplateFormik,
  };
}
