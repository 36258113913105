import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';

export function useAIToken() {
  const {
    data: aiTokenListing,
    refetch: aiTokenListingRefetch,
    isFetching: aiTokenListingFetching,
  } = useGetQuery('ai-token', apiEndpoints.AI_PRODUCT_LISTING);

  const { mutate: handleAiTokenCheckout } = usePostMutation(
    apiEndpoints.AI_PRODUCT_CHECKOUT,
    resp => {
      window.location.href = resp?.data?.checkoutSessionUrl;
      aiTokenListingRefetch();
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  return { aiTokenListing, handleAiTokenCheckout, aiTokenListingFetching };
}
