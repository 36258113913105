import { useCallback, useEffect, useRef, useState } from 'react';
import { Command, CommandGroup, CommandItem, Badge } from '@shadcnComponent';
import { Command as CommandPrimitive } from 'cmdk';
import { isEmpty, uniq } from 'lodash';
import { ChevronDown, X } from 'lucide-react';
import { BACKSPACE, DELETE, ENTER, ESCAPE } from '@constants';

export function MultiSelect({ options, editWidget, setEditWidget, disabled }) {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [dropDownOptions, setDropDownOptions] = useState(options);

  const handleUnselect = useCallback(option => {
    setSelected(prev => prev?.filter(s => s.value !== option.value));
    setDropDownOptions(uniq([...dropDownOptions, option]));
  }, []);

  const handleKeyDown = useCallback(e => {
    const input = inputRef.current;
    if (input) {
      if (e.key === DELETE || e.key === BACKSPACE) {
        if (input.value === '') {
          setSelected(prev => {
            const newSelected = [...prev];
            newSelected.pop();
            return newSelected;
          });
        }
      }
      if (e.key === ESCAPE) {
        input.blur();
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(selected)) {
      setEditWidget({ ...editWidget, metrics: selected?.map(item => item.value) });
    } else {
      setEditWidget({ ...editWidget, metrics: '' });
    }
    setDropDownOptions(() => dropDownOptions?.filter(option => !selected?.includes(option)));
  }, [selected]);

  useEffect(() => {
    setSelected(options?.filter(option => editWidget?.metrics?.includes(option.value)));
  }, []);

  return (
    <Command onKeyDown={handleKeyDown} disabled={disabled} className='overflow-visible bg-transparent'>
      <div className='group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2'>
        <div className='flex flex-wrap gap-1'>
          {!isEmpty(selected) &&
            selected?.map((option, index) => (
              <Badge key={index} variant='secondary'>
                {option.label}
                <button
                  disabled={disabled}
                  className='ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2'
                  onKeyDown={e => {
                    if (e.key === ENTER) {
                      handleUnselect(option);
                    }
                  }}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => handleUnselect(option)}
                >
                  <X className='h-3 w-3 text-muted-foreground hover:text-foreground' />
                </button>
              </Badge>
            ))}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            disabled={disabled}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder='Search metrics'
            className='ml-2 w-[6.25rem] bg-transparent outline-none placeholder:text-muted-foreground'
          />
          <ChevronDown width={16} height={16} className='ml-auto' />
        </div>
      </div>
      {open && dropDownOptions.length > 0 && (
        <div className='relative mt-2'>
          <div className='absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in'>
            <CommandGroup className='h-full overflow-auto'>
              {dropDownOptions?.map((option, index) => (
                <CommandItem
                  key={index}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onSelect={() => {
                    setInputValue('');
                    setSelected(prev => [...prev, option]);
                  }}
                  className='cursor-pointer'
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </div>
        </div>
      )}
    </Command>
  );
}
