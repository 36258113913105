import { useEffect } from 'react';
import { Button } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { FormInput, AuthDescription, ImageUpload, Loader, ImageCrop } from '@common/components';
import { useOnBoarding } from '@pages/onBoarding';
import { getFile, handleSave, removeLocalStorageItem } from '@utils';
import { ORGANIZATION_ID_BY_INVITE_ACCEPTANCE } from '@constants';
import { ImageUploaderIcon, Logo, PlusIconWhite } from '@assets/svgs';

export function OnBoarding() {
  const {
    formik,
    imageRef,
    uploadedImage,
    isUploadedImage,
    invitedOrganization,
    onBoardingLoading,
    handleImageUpload,
    setIsUploadedImage,
  } = useOnBoarding();

  const { initialValues, values, handleChange, touched, errors, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    return () => {
      removeLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
    };
  }, []);

  return (
    <div className='flex h-screen min-h-[42.5rem] overflow-y-auto'>
      <div className='relative flex-1'>
        <div className='absolute left-5 top-6 md:left-8 md:top-10 lg:left-7 lg:top-7 xxl:left-10 xxl:top-10'>
          <Logo />
        </div>
        <form
          onSubmit={handleSubmit}
          className='h-available mx-auto flex max-w-[30rem] flex-col justify-center px-5 md:max-w-[25rem] md:px-0 lg:max-w-[15.5rem] xxl:max-w-[21.875rem]'
        >
          <h2 className='whitespace-nowrap'>Onboarding</h2>
          <p className='mb-8 mt-3 text-sm font-normal leading-5 md:mb-10 md:mt-3 md:text-sm md:leading-[1.0588rem] lg:mb-4 lg:text-xs lg:leading-[0.9075rem] xxl:mb-[2.625rem] xxl:mt-4 xxl:text-sm xxl:leading-5'>
            We&apos;re excited to have you join us on this journey! 🎉
          </p>
          <div className='mb-6 md:mb-8 lg:mb-6 xxl:mb-[1.875rem]'>
            <ImageUpload
              id='profileImage'
              icon={
                <PlusIconWhite className='h-[1.125rem] w-[1.125rem] md:h-[0.9844rem] md:w-[0.9844rem] lg:h-[0.5831rem] lg:w-[0.5831rem] xxl:h-3.5 xxl:w-3.5' />
              }
              imageRef={imageRef}
              placeHolderIcon={<ImageUploaderIcon />}
              className='h-[7.5rem] w-[7.5rem] md:h-[7.5rem] md:w-[7.5rem] lg:h-20 lg:w-20 xxl:h-[6.75rem] xxl:w-[6.75rem]'
              profileImage={uploadedImage?.image || values?.profileImage}
              handleChange={e => getFile(e.target.files[0], 'profileImage', setFieldValue, setIsUploadedImage)}
              showError={touched.profileImage && errors.profileImage}
              errorMessage={errors.profileImage}
              inputWrapperClassName='md:h-[1.875rem] md:w-[1.875rem]'
              showSkeletonLoader={isUploadedImage}
            />
          </div>
          <div className='flex flex-col gap-6 lg:gap-4 xxl:gap-6'>
            <FormInput
              id='firstName'
              labelText='First Name'
              placeholder='Enter first name'
              value={values.firstName}
              handleChange={handleChange}
              showError={touched.firstName && errors.firstName}
            />
            <FormInput
              id='lastName'
              labelText='Last Name'
              placeholder='Enter last name'
              value={values.lastName}
              handleChange={handleChange}
              showError={touched.lastName && errors.lastName}
            />
            {!isEmpty(invitedOrganization) && (
              <FormInput
                labelText='Organization Name'
                placeholder='Cogent Organization'
                disabled={true}
                value={invitedOrganization.name}
              />
            )}
            <FormInput labelText='Email' placeholder='Email' disabled={true} value={values.email} />
            <Button
              type='submit'
              className='start-button mt-2 xxl:mt-0'
              disabled={isUploadedImage || onBoardingLoading}
            >
              Get Started {onBoardingLoading ? <Loader /> : ''}
            </Button>
          </div>
        </form>
        {isUploadedImage && (
          <ImageCrop
            uploadedImage={values?.profileImage}
            handleCancel={() => {
              setIsUploadedImage(false);
              setFieldValue('profileImage', initialValues.profileImage);
              imageRef.current.value = initialValues.profileImage;
            }}
            handleCloseDialog={() => setFieldValue('profileImage', initialValues.profileImage)}
            imageRef={imageRef}
            setIsUploaded={setIsUploadedImage}
            handleSave={file => handleSave(file, handleImageUpload)}
            isDialog
          />
        )}
      </div>
      <AuthDescription />
    </div>
  );
}
