import { Link } from 'react-router-dom';
import { Sheet, SheetContent, SheetTrigger } from '@shadcnComponent';
import { ResponsiveSidebar, UserAction } from '@common/components';
import { useManageOrganization } from '@pages/manageOrganization';
import { ROUTES } from '@routes';
import { ResponsiveSidebarIcon } from '@assets/svgs';

export function Header({ ActiveText, headerWrapperClassName, logo }) {
  const { manageOrganizationFetching, userDetail, userDetailRefetch } = useManageOrganization();

  return (
    <div
      className={`sticky left-0 top-0 z-10 flex items-center justify-between border-b px-5 py-3.5 md:px-8 md:pb-[1.375rem] md:pt-6 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-5 ${headerWrapperClassName} ${logo ? 'bg-gradient-to-b from-gradientPrimary to-gradientSecondary' : 'border-lightGray bg-white'}`}
    >
      <>
        {logo && <Link to={ROUTES.HOME}>{logo}</Link>}
        <h3 className='hidden lg:flex lg:text-base lg:leading-5 xxl:text-2xl xxl:leading-6'>
          {!manageOrganizationFetching && ActiveText}
        </h3>
        {!logo && (
          <div className='flex h-6 w-6 md:h-9 md:w-9 lg:hidden'>
            <Sheet>
              <SheetTrigger>
                <ResponsiveSidebarIcon />
              </SheetTrigger>
              <SheetContent closeIconStyle='hidden' side='left' className='border-r-0 p-0'>
                <ResponsiveSidebar />
              </SheetContent>
            </Sheet>
          </div>
        )}
      </>
      <div className='flex items-center gap-5 md:gap-3 lg:gap-4 xxl:gap-5'>
        <UserAction iconColor={logo ? '#fff' : '#000'} userDetail={userDetail} userDetailRefetch={userDetailRefetch} />
      </div>
    </div>
  );
}
