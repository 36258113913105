export function EmptyBill({ className }) {
  return (
    <svg
      width='226'
      height='207'
      viewBox='0 0 226 207'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_3847_27836)'>
        <path
          d='M56.2332 20.7176C56.2332 20.7176 21.8368 44.765 7.43794 76.4089C-6.96091 108.053 -0.286826 145.202 26.0297 169.831C52.3461 194.461 94.709 184.807 133.071 197.371C171.434 209.935 210.516 200.78 222.306 161.164C234.095 121.549 206.521 111.215 196.118 68.7286C185.714 26.2424 138.024 -31.4368 56.2332 20.7176Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.9'
          d='M56.2332 20.7176C56.2332 20.7176 21.8368 44.765 7.43794 76.4089C-6.96091 108.053 -0.286826 145.202 26.0297 169.831C52.3461 194.461 94.709 184.807 133.071 197.371C171.434 209.935 210.516 200.78 222.306 161.164C234.095 121.549 206.521 111.215 196.118 68.7286C185.714 26.2424 138.024 -31.4368 56.2332 20.7176Z'
          fill='white'
        />
        <path
          d='M112.788 38.3926H74.3125V89.2004H112.788V38.3926Z'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.309 44.3125H78.7959V83.2816H108.309V44.3125Z'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M55.0745 64.5371H16.5986V115.345H55.0745V64.5371Z'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50.5959 70.457H21.083V109.426H50.5959V70.457Z'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.233 77.6749C182.442 76.4519 191.348 65.563 190.125 53.3538C188.902 41.1446 178.013 32.2385 165.804 33.4614C153.595 34.6844 144.689 45.5733 145.912 57.7825C147.135 69.9917 158.023 78.8978 170.233 77.6749Z'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.725 73.7993C180.782 72.3035 187.722 62.9385 186.226 52.8821C184.73 42.8256 175.365 35.8857 165.308 37.3815C155.252 38.8773 148.312 48.2422 149.808 58.2987C151.304 68.3552 160.669 75.2951 170.725 73.7993Z'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.272 55.5781H168.017V72.5174'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.089 161.373L160.883 173.927C161.106 174.291 161.227 174.708 161.235 175.134C161.244 175.561 161.138 175.982 160.929 176.354C160.721 176.726 160.417 177.036 160.049 177.252C159.681 177.468 159.263 177.582 158.836 177.582H142.923C143.35 177.582 143.77 177.468 144.138 177.252C144.507 177.036 144.811 176.726 145.02 176.353C145.229 175.98 145.334 175.558 145.325 175.131C145.316 174.703 145.194 174.286 144.97 173.922L137.428 161.639L153.089 161.373Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.089 161.373L137.428 161.654L140.328 166.38H156.197L153.089 161.373Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.452 164.17H175.154C175.947 164.17 176.59 163.527 176.59 162.734V109.381C176.59 108.589 175.947 107.946 175.154 107.946H111.452C110.659 107.946 110.017 108.589 110.017 109.381V162.734C110.017 163.527 110.659 164.17 111.452 164.17Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.931 164.17H172.633C173.426 164.17 174.068 163.527 174.068 162.734V109.381C174.068 108.589 173.426 107.946 172.633 107.946H108.931C108.138 107.946 107.495 108.589 107.495 109.381V162.734C107.495 163.527 108.138 164.17 108.931 164.17Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.735 107.945H171.834C172.128 107.945 172.419 108.003 172.69 108.116C172.962 108.229 173.208 108.394 173.416 108.602C173.623 108.81 173.788 109.057 173.9 109.328C174.012 109.6 174.069 109.891 174.068 110.185V154.087H107.476V110.185C107.476 109.889 107.534 109.596 107.648 109.323C107.762 109.05 107.928 108.803 108.138 108.594C108.349 108.386 108.598 108.222 108.872 108.11C109.146 107.999 109.439 107.943 109.735 107.945Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.292 151.023H171.271V110.185H110.292V151.023Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M131.04 114.664H131.77V119.306H131.04V114.664Z' fill='#263238' />
        <path
          d='M132.954 115.947V119.306H132.298V114.664H133.21L133.96 117.441V114.664H134.611V119.306H133.862L132.954 115.947Z'
          fill='#263238'
        />
        <path
          d='M136.235 118.452L136.792 114.664H137.463L136.748 119.306H135.653L134.938 114.664H135.672L136.235 118.452Z'
          fill='#263238'
        />
        <path
          d='M137.734 115.778C137.724 115.626 137.745 115.474 137.796 115.331C137.847 115.188 137.927 115.056 138.031 114.945C138.135 114.834 138.26 114.746 138.4 114.685C138.539 114.625 138.689 114.594 138.841 114.594C138.993 114.594 139.144 114.625 139.283 114.685C139.423 114.746 139.548 114.834 139.652 114.945C139.756 115.056 139.836 115.188 139.887 115.331C139.938 115.474 139.959 115.626 139.949 115.778V118.19C139.959 118.341 139.938 118.493 139.887 118.637C139.836 118.78 139.756 118.911 139.652 119.022C139.548 119.133 139.423 119.221 139.283 119.282C139.144 119.342 138.993 119.374 138.841 119.374C138.689 119.374 138.539 119.342 138.4 119.282C138.26 119.221 138.135 119.133 138.031 119.022C137.927 118.911 137.847 118.78 137.796 118.637C137.745 118.493 137.724 118.341 137.734 118.19V115.778ZM138.464 118.244C138.464 118.574 138.612 118.698 138.844 118.698C139.076 118.698 139.224 118.574 139.224 118.244V115.738C139.224 115.403 139.076 115.279 138.844 115.279C138.612 115.279 138.464 115.403 138.464 115.738V118.244Z'
          fill='#263238'
        />
        <path d='M140.423 114.664H141.153V119.306H140.423V114.664Z' fill='#263238' />
        <path
          d='M143.796 117.574V118.191C143.796 118.935 143.426 119.36 142.711 119.36C141.996 119.36 141.626 118.935 141.626 118.191V115.778C141.626 115.034 141.996 114.609 142.711 114.609C143.426 114.609 143.796 115.034 143.796 115.778V116.227H143.111V115.734C143.111 115.399 142.963 115.275 142.731 115.275C142.499 115.275 142.351 115.399 142.351 115.734V118.24C142.351 118.57 142.499 118.689 142.731 118.689C142.963 118.689 143.111 118.57 143.111 118.24V117.574H143.796Z'
          fill='#263238'
        />
        <path
          d='M144.975 116.622H145.962V117.283H144.975V118.645H146.233V119.306H144.26V114.664H146.233V115.33H144.975V116.622Z'
          fill='#263238'
        />
        <path d='M169.111 121.141H112.828V124.623H169.111V121.141Z' fill='#00ACE7' />
        <path
          d='M113.011 147.176V127.006H168.746V147.906'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M113.198 133.787H168.564' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M153.347 126.863V147.907' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M82.9991 174.785H81.0605V175.431H82.9991V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.5792 174.785H83.6406V175.431H85.5792V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.1642 174.785H86.2256V175.431H88.1642V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.7443 174.785H88.8057V175.431H90.7443V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.3282 174.785H91.3896V175.431H93.3282V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.9093 174.785H93.9707V175.431H95.9093V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.4933 174.785H96.5547V175.431H98.4933V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.073 174.785H99.1348V175.431H101.073V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.658 174.785H101.72V175.431H103.658V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.238 174.785H104.3V175.431H106.238V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.822 174.785H106.884V175.431H108.822V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.402 174.785H109.464V175.431H111.402V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.987 174.785H112.049V175.431H113.987V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.567 174.785H114.628V175.431H116.567V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.152 174.785H117.214V175.431H119.152V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.732 174.785H119.793V175.431H121.732V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.317 174.785H122.378V175.431H124.317V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.897 174.785H124.958V175.431H126.897V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.482 174.785H127.543V175.431H129.482V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.061 174.785H130.122V175.431H132.061V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.647 174.785H132.708V175.431H134.647V174.785Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.052 175.523H80.0596V177.61H136.052V175.523Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.052 175.523H122.393V177.61H136.052V175.523Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.174 130.453V123.236H184.995V130.453H181.547L187.585 140.911L193.617 130.453H190.174Z'
          fill='#263238'
        />
        <path d='M150.278 104.738V103.998' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M150.278 102.544V97.3397C150.278 96.4239 150.642 95.5456 151.29 94.8981C151.937 94.2505 152.815 93.8867 153.731 93.8867H184.315C185.23 93.8867 186.109 94.2505 186.756 94.8981C187.404 95.5456 187.768 96.4239 187.768 97.3397V119.799'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
          strokeDasharray='2.18 2.18'
        />
        <path d='M187.768 120.523V121.263' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M169.885 145.186H201.208C202.026 145.186 202.81 145.51 203.388 146.089C203.966 146.667 204.291 147.451 204.291 148.269V173.184H166.802V148.269C166.802 147.451 167.127 146.667 167.705 146.089C168.283 145.51 169.067 145.186 169.885 145.186Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M202.317 141.732H168.774V173.184H202.317V141.732Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M149.715 156.822H208.519C209.018 156.822 209.498 157.021 209.851 157.374C210.205 157.728 210.403 158.207 210.403 158.707V177.377H149.73V156.822H149.715Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M141.921 156.822H200.271C200.584 156.822 200.883 156.946 201.104 157.168C201.326 157.389 201.45 157.689 201.45 158.001V177.377H140.776V158.001C140.776 157.694 140.896 157.4 141.109 157.18C141.323 156.959 141.614 156.831 141.921 156.822Z'
          fill='#636363'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M145.966 156.822H196.211V170.279C196.211 171.382 195.773 172.439 194.993 173.219C194.214 173.999 193.156 174.437 192.053 174.437H150.124C149.021 174.437 147.964 173.999 147.184 173.219C146.404 172.439 145.966 171.382 145.966 170.279V156.822Z'
          fill='#636363'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M186.913 156.822H155.264V177.377H186.913V156.822Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M158.036 167.516C158.036 167.516 159.111 172.636 156.689 174.52C154.267 176.405 142.138 176 142.138 176H181.743C181.743 176 186.592 177.214 186.321 168.458L158.036 167.516Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M182.32 175.235H156.971L158.352 174.461H183.317L182.32 175.235Z' fill='#00ACE7' />
        <path
          d='M160.265 169.316C160.265 172.444 159.525 173.593 159.525 173.593H183.958C183.958 173.593 185.028 172.937 185.275 169.4'
          stroke='#A3A3A3'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M159.94 172.113H184.698' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M160.187 170.551H185.028' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M176.145 173.509C176.145 173.509 177.378 172.36 177.294 169.316'
          stroke='#A3A3A3'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M186.913 156.822H155.264V168.617H186.913V156.822Z'
          fill='#636363'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M186.908 176.025H152.394V177.377H186.908V176.025Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M154.119 156.822H188.052V158.455C188.052 158.883 187.968 159.307 187.804 159.703C187.64 160.098 187.4 160.458 187.097 160.761C186.794 161.063 186.435 161.304 186.039 161.467C185.643 161.631 185.219 161.716 184.791 161.716H157.385C156.956 161.716 156.532 161.631 156.137 161.467C155.741 161.304 155.382 161.063 155.079 160.761C154.776 160.458 154.536 160.098 154.372 159.703C154.208 159.307 154.124 158.883 154.124 158.455V156.822H154.119Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M199.472 160.492C199.472 160.702 199.409 160.907 199.293 161.081C199.176 161.256 199.011 161.392 198.817 161.472C198.623 161.552 198.41 161.573 198.204 161.532C197.999 161.491 197.81 161.39 197.661 161.242C197.513 161.094 197.412 160.905 197.371 160.699C197.33 160.493 197.351 160.28 197.431 160.086C197.512 159.893 197.648 159.727 197.822 159.61C197.996 159.494 198.201 159.432 198.411 159.432C198.692 159.432 198.962 159.543 199.161 159.742C199.36 159.941 199.472 160.211 199.472 160.492Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M199.472 165.248C199.472 165.458 199.409 165.663 199.293 165.837C199.176 166.012 199.011 166.148 198.817 166.228C198.623 166.308 198.41 166.329 198.204 166.288C197.999 166.247 197.81 166.146 197.661 165.998C197.513 165.85 197.412 165.661 197.371 165.455C197.33 165.249 197.351 165.036 197.431 164.842C197.512 164.648 197.648 164.483 197.822 164.366C197.996 164.25 198.201 164.188 198.411 164.188C198.692 164.188 198.962 164.299 199.161 164.498C199.36 164.697 199.472 164.967 199.472 165.248Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M198.663 175.695H198.022C197.789 175.695 197.565 175.602 197.401 175.437C197.236 175.273 197.144 175.049 197.144 174.817C197.144 174.583 197.236 174.359 197.4 174.194C197.565 174.028 197.788 173.935 198.022 173.934H198.663C198.779 173.934 198.893 173.956 199 174.001C199.107 174.045 199.204 174.11 199.285 174.193C199.367 174.275 199.432 174.372 199.475 174.479C199.519 174.586 199.542 174.701 199.541 174.817C199.541 175.049 199.448 175.273 199.284 175.437C199.119 175.602 198.896 175.695 198.663 175.695Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M170.517 204.515V183.053H161.056V204.589' fill='white' />
        <path
          d='M170.517 204.515V183.053H161.056V204.589'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.518 183.053H165.787V204.782H170.518V183.053Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M212.87 204.515V183.053H203.414V204.589' fill='white' />
        <path
          d='M212.87 204.515V183.053H203.414V204.589'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M212.87 183.053H208.14V204.782H212.87V183.053Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.391 179.377H22.5186V186.865H215.391V179.377Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M215.391 179.377H168.983V186.865H215.391V179.377Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M215.391 177.336H22.5186V179.965H215.391V177.336Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M215.391 177.336H173.19V179.965H215.391V177.336Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M83.9619 74.9279C83.9619 74.9279 84.317 77.9715 84.0803 79.1406C83.8435 80.3096 82.561 81.015 82.6744 81.8339C82.7879 82.6527 85.7179 86.8653 84.317 88.2465C83.6031 89.0196 82.7225 89.6202 81.7421 90.0026L80.8049 92.1089C80.8049 92.1089 75.1864 95.7394 72.9617 96.6767C70.737 97.6139 72.725 102.058 72.725 102.058L66.993 100.771L65.6069 107.47C65.6069 107.47 61.2759 103.257 55.4207 101.383C49.5655 99.5081 47.3457 99.7449 47.3457 99.7449C48.2947 97.4031 49.0006 94.9699 49.452 92.4838C50.2709 88.1528 50.7395 78.5585 50.7395 77.8531C50.7395 77.1477 54.4884 66.3843 57.7638 64.278C61.0392 62.1717 74.3873 64.5099 81.4067 66.2511C88.4261 67.9924 85.3875 73.0387 85.3875 73.0387L83.9619 74.9279Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M83.7294 78.6735C83.7294 78.6735 83.3792 77.386 81.8549 77.8546C80.3307 78.3232 79.9854 79.1421 80.5675 79.1421C81.1495 79.1421 82.5406 78.4416 82.9105 79.1421C83.2805 79.8425 83.611 79.4923 83.7294 78.6735Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M81.623 82.8711L80.9176 83.2213C80.9176 83.2213 81.9732 83.9218 81.5046 85.5595C81.036 87.1972 80.5674 88.603 80.5674 88.603'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M77.4103 71.7672C77.4103 71.7672 78.4609 76.2067 77.9923 77.1538C77.5237 78.1009 74.0116 79.3785 73.6613 80.6659C73.3111 81.9534 72.6106 88.8593 72.8425 90.1468C73.0743 91.4342 73.6613 93.6589 74.2483 93.6589C74.8353 93.6589 79.1811 90.3786 80.804 90.2652C82.4269 90.1517 81.7412 93.0719 80.9175 94.0092C80.0937 94.9464 78.4511 95.1782 78.5793 96.2339C78.7076 97.2895 80.804 100.915 78.4609 104.077C76.1179 107.239 73.4295 106.543 70.6178 104.309C67.8061 102.074 67.3424 98.3402 68.1514 96.2339C68.9604 94.1276 70.2577 88.6225 70.3761 86.166C70.4945 83.7095 69.9075 80.5476 68.1514 81.2332C66.3953 81.9189 67.8012 88.8445 66.5137 90.3638C65.2262 91.8831 60.0764 96.2191 58.6706 96.2191C57.2647 96.2191 49.6583 92.8253 49.4216 92.4701C49.1848 92.115 50.122 76.4336 49.1897 70.2331C48.2574 64.0325 50.4772 59.5782 57.6149 57.4719C64.7527 55.3656 77.7506 59.228 77.7506 59.228C77.7506 59.228 76.4632 57.1217 79.2699 56.1845C82.0767 55.2472 88.7508 57.8221 89.2194 62.0397C89.688 66.2572 87.2463 66.3707 87.5817 67.7716C87.9171 69.1725 92.3813 71.8708 90.0481 74.0955C87.7149 76.3202 83.961 74.9143 83.961 74.9143L83.4677 71.2838C83.4677 71.2838 81.0359 68.255 77.4103 71.7672Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M77.9926 80.5475L66.874 80.1973C66.874 80.1973 64.531 80.7793 64.2991 82.0668C64.0673 83.3543 63.8305 86.3978 64.531 86.5063C65.2314 86.6148 65.2364 85.8059 65.2364 84.7502C65.2364 83.6946 65.2364 82.0569 66.1687 81.4749C66.9744 81.0739 67.8508 80.8342 68.7485 80.7695L78.2293 81.7067L87.1084 82.4121V80.8977L77.9926 80.5475Z'
          fill='#5E5E5E'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M70.046 85.1105C70.046 85.1105 70.3963 81.3665 68.5218 80.3109C66.6473 79.2553 65.3599 82.1854 65.2464 84.7504C65.133 87.3155 66.8841 90.4873 69.1088 89.9003'
          fill='white'
        />
        <path
          d='M70.046 85.1105C70.046 85.1105 70.3963 81.3665 68.5218 80.3109C66.6473 79.2553 65.3599 82.1854 65.2464 84.7504C65.133 87.3155 66.8841 90.4873 69.1088 89.9003'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M87.1231 82.4219C87.1231 82.4219 87.3599 85.1102 86.3043 86.3977C85.9072 86.8675 85.4315 87.2647 84.8984 87.5717'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M119.763 104.215L115.057 120.686L111.53 130.098L107.707 140.245C107.707 140.245 97.1167 140.097 92.4157 139.214C87.7147 138.331 79.2451 135.322 79.2451 135.322C83.4291 128.649 86.6663 121.427 88.8641 113.864C90.8372 106.879 92.4157 101.275 92.4157 101.275L119.763 104.215Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M96.1896 111.762C96.1896 111.762 92.6725 124.404 91.2568 126.965L108.379 129.525C108.379 129.525 111.58 123.921 113.982 114.159L96.1896 111.762Z'
          stroke='#A3A3A3'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M95.2334 114.643L112.996 117.04' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M94.4336 118.801L111.501 121.065' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M93.0283 123.029L110.155 125.432' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M104.195 124.637L100.836 133.846L106.277 134.966L108.196 128.563'
          stroke='#A3A3A3'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M84.6719 127.922L91.711 129.204' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M84.1924 128.885L91.2315 130.167' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M83.71 129.846L90.754 131.123' stroke='#A3A3A3' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M93.1563 102.424L91.0352 109.779L115.798 112.753L118.2 105.393L93.1563 102.424Z' fill='#00ACE7' />
        <path d='M94.0588 103.875L94.5965 103.929L93.6099 107.382L93.0723 107.328L94.0588 103.875Z' fill='#263238' />
        <path
          d='M95.2026 104.966L94.4972 107.432L94.0039 107.383L94.9905 103.93L95.6663 103.999L95.6317 106.11L96.2187 104.058L96.712 104.107L95.7254 107.56L95.173 107.506L95.2026 104.966Z'
          fill='#263238'
        />
        <path
          d='M97.0921 107.067L98.3006 104.315L98.7939 104.364L97.2894 107.738L96.4854 107.654L96.9342 104.172L97.4818 104.226L97.0921 107.067Z'
          fill='#263238'
        />
        <path
          d='M98.7594 105.207C98.8029 104.965 98.9379 104.75 99.1362 104.605C99.3346 104.459 99.5812 104.396 99.8249 104.428C100.353 104.482 100.55 104.828 100.392 105.375L99.8989 107.161C99.8565 107.402 99.7223 107.617 99.5246 107.762C99.3269 107.906 99.0809 107.968 98.8383 107.935C98.3105 107.881 98.1132 107.541 98.2661 106.993L98.7594 105.207ZM98.7594 107.082C98.6903 107.324 98.7594 107.427 98.9419 107.447C99.1244 107.467 99.2477 107.383 99.3168 107.136L99.8446 105.286C99.9186 105.04 99.8446 104.936 99.6621 104.916C99.4796 104.897 99.3612 104.98 99.2872 105.227L98.7594 107.082Z'
          fill='#263238'
        />
        <path d='M100.98 104.576L101.517 104.635L100.531 108.088L99.9932 108.034L100.98 104.576Z' fill='#263238' />
        <path
          d='M102.854 106.993L102.725 107.452C102.685 107.693 102.553 107.908 102.357 108.054C102.161 108.199 101.917 108.263 101.675 108.231C101.147 108.177 100.964 107.837 101.122 107.284L101.616 105.504C101.652 105.262 101.782 105.044 101.978 104.897C102.174 104.751 102.419 104.687 102.661 104.719C103.189 104.773 103.372 105.119 103.219 105.666L103.12 106.002L102.627 105.947L102.735 105.578C102.804 105.336 102.735 105.232 102.553 105.213C102.37 105.193 102.247 105.277 102.178 105.523L101.65 107.373C101.576 107.62 101.65 107.718 101.833 107.738C102.015 107.758 102.133 107.679 102.207 107.432L102.346 106.939L102.854 106.993Z'
          fill='#263238'
        />
        <path
          d='M103.919 106.376L104.659 106.455L104.521 106.948L103.781 106.874L103.495 107.86L104.423 107.959L104.285 108.452L102.805 108.299L103.791 104.871L105.271 105.024L105.133 105.517L104.201 105.424L103.919 106.376Z'
          fill='#263238'
        />
        <path
          d='M115.231 107.59C115.277 107.963 115.208 108.342 115.033 108.676C114.859 109.01 114.587 109.282 114.254 109.458C113.921 109.633 113.542 109.703 113.168 109.658C112.794 109.613 112.443 109.455 112.161 109.206C111.879 108.956 111.679 108.627 111.589 108.261C111.499 107.895 111.522 107.511 111.656 107.159C111.789 106.807 112.027 106.504 112.337 106.29C112.646 106.076 113.014 105.962 113.391 105.962C113.85 105.936 114.301 106.093 114.645 106.397C114.99 106.702 115.2 107.131 115.231 107.59Z'
          fill='white'
        />
        <path
          d='M97.7089 155.092C97.7089 155.092 102.119 144.655 102.558 140.536C102.997 136.417 103.293 132.594 104.324 131.42C105.355 130.246 107.708 124.657 108.147 123.034C108.586 121.411 109.627 119.798 110.796 120.977C111.965 122.156 111.531 124.923 111.087 125.974C110.643 127.025 110.061 128.623 110.061 128.623L113.149 125.239L115.058 120.681C115.058 120.681 116.675 120.681 116.971 122.161C117.267 123.641 119.028 125.836 118.584 127.163C118.261 128 117.632 128.682 116.823 129.072C116.823 129.072 117.558 132.747 117.41 134.074C117.078 135.811 116.139 137.374 114.762 138.484C113.282 139.658 109.178 143.481 109.178 143.481C109.178 143.481 111.235 159.068 109.178 166.714C107.121 174.36 103.441 176.713 100.792 177.157C98.143 177.601 92.998 171.731 92.998 171.731C92.998 171.731 98.4439 168.914 97.7089 155.092Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M116.824 129.066L114.471 131.419' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M113.148 125.245L115.501 123.336' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M45.7025 96.761C45.7025 96.761 49.8115 95.7745 56.0614 98.4086C60.6384 100.259 64.6601 103.259 67.7373 107.12C69.0896 108.939 70.1943 110.929 71.0225 113.039C71.0225 113.039 47.1823 103.835 44.5531 103.835H38.6338C38.6338 103.835 41.7563 97.0915 45.7025 96.761Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M127.587 204.791C108.512 188.02 78.7521 188.02 78.7521 188.02L74.3126 183.748H21.4922V205.038'
          fill='white'
        />
        <path
          d='M127.587 204.791C108.512 188.02 78.7521 188.02 78.7521 188.02L74.3126 183.748H21.4922V205.038'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M77.6026 189.004C77.6026 189.004 75.1362 199.693 70.3662 204.296'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M76.1225 188.186C74.2632 192.398 71.2391 195.992 67.4062 198.544'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M39.2898 101.861C39.2898 101.861 42.9056 101.038 49.9792 102.518C57.0528 103.997 67.4068 110.079 72.0091 112.053C76.6114 114.026 80.2321 114.193 81.0558 117.153C81.8796 120.113 90.9214 148.062 94.8677 151.189C100.683 155.797 106.213 163.886 99.8005 170.59C96.017 174.536 90.9214 174.867 88.6178 174.043C86.3142 173.219 80.7253 167.468 80.7253 165.657V163.354L69.8732 150.859C69.8732 150.859 72.1768 170.427 73.1634 173.057C74.1499 175.686 76.7791 179.469 76.7791 181.112C76.7791 182.754 72.9956 186.045 68.5561 188.841C64.1166 191.638 59.6771 194.105 49.4859 194.598C39.2947 195.091 27.1305 193.444 24.4914 189.991C21.8524 186.538 18.5721 182.429 18.2416 176.672C17.9111 170.916 16.7617 148.23 16.7617 148.23C16.7617 148.23 17.4227 129.811 17.916 123.729C18.4093 117.646 19.7214 113.7 22.8488 112.383C25.9762 111.066 37.1539 101.861 39.2898 101.861Z'
          fill='#00ACE7'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M44.3955 139.781C44.8462 140.49 45.3405 141.171 45.8753 141.818'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M36.9961 116.822C36.9961 116.822 38.762 129.154 43.1818 137.653'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M38.1406 132.277C38.1406 132.277 41.7564 143.953 47.3452 150.035'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M61.6504 140.994L69.8734 150.86' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M59.1846 138.033L60.4671 139.572' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M59.5137 142.475L67.2434 148.063' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M70.5391 120.77C70.5391 120.77 62.8144 128.169 62.9771 136.717'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M94.8679 151.189C94.8679 151.189 97.6599 156.616 93.5509 160.562C89.4418 164.508 80.7256 163.354 80.7256 163.354V165.657C80.7256 167.468 86.3144 173.219 88.6181 174.043C90.9217 174.867 96.0173 174.536 99.8007 170.59C106.223 163.886 100.689 155.797 94.8679 151.189Z'
          fill='white'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path d='M73.1632 173.058L64.2842 164.672' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path d='M59.3516 159.9L68.0629 166.15' stroke='#263238' strokeWidth='0.739286' strokeMiterlimit='10' />
        <path
          d='M46.8613 168.701C47.4891 169.158 48.1484 169.57 48.8344 169.934'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M27.7822 154.312C27.7822 154.312 37.6774 162.037 43.9717 166.644'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M29.7549 159.246C29.7549 159.246 36.9864 164.509 38.1406 165.165'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M21.4085 162.945H60.3777C61.5878 162.945 62.7484 163.426 63.6041 164.282C64.4598 165.137 64.9405 166.298 64.9405 167.508V200.805C64.9405 201.688 64.5897 202.535 63.9653 203.159C63.3408 203.783 62.4939 204.134 61.6109 204.134H20.1753C19.2923 204.134 18.4454 203.783 17.8209 203.159C17.1965 202.535 16.8457 201.688 16.8457 200.805V167.508C16.8457 166.298 17.3264 165.137 18.1821 164.282C19.0378 163.426 20.1984 162.945 21.4085 162.945Z'
          fill='#263238'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M17.4623 162.945H56.4314C57.6415 162.945 58.8021 163.426 59.6578 164.282C60.5135 165.137 60.9942 166.298 60.9942 167.508V200.805C60.9942 201.688 60.6434 202.535 60.019 203.159C59.3946 203.783 58.5476 204.134 57.6646 204.134H16.2291C15.346 204.134 14.4991 203.783 13.8746 203.159C13.2502 202.535 12.8994 201.688 12.8994 200.805V167.508C12.8994 166.298 13.3801 165.137 14.2358 164.282C15.0915 163.426 16.2521 162.945 17.4623 162.945Z'
          fill='#636363'
          stroke='#263238'
          strokeWidth='0.739286'
          strokeMiterlimit='10'
        />
        <path
          d='M22.0251 207V175.031C22.0251 174.638 21.8692 174.262 21.5917 173.984C21.3141 173.707 20.9377 173.551 20.5453 173.551C20.1528 173.551 19.7764 173.707 19.4989 173.984C19.2213 174.262 19.0654 174.638 19.0654 175.031V207H22.0251Z'
          fill='#263238'
        />
        <path
          d='M48.9089 207V175.031C48.9089 174.638 48.753 174.262 48.4755 173.984C48.1979 173.707 47.8215 173.551 47.4291 173.551C47.0366 173.551 46.6602 173.707 46.3827 173.984C46.1051 174.262 45.9492 174.638 45.9492 175.031V207H48.9089Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_3847_27836'>
          <rect width='226' height='207' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
