import React from 'react';
import { ChatbotFooter, ChatbotMessage } from '@common/components';
import { MESSAGE } from '@constants';

export function ChatbotBody({
  message,
  setMessage,
  handleChatMessage,
  chatbotRef,
  chatbotMessages,
  chatMessageLoading,
}) {
  return (
    <div className='px-5 pb-5 pt-3'>
      <div ref={chatbotRef} className='flex h-[468px] max-h-[23.125rem] flex-col gap-2 overflow-y-auto scrollbar-none'>
        {chatbotMessages?.data?.history?.map((chat, i) => (
          <React.Fragment key={i}>
            <ChatbotMessage text={chat?.human} />
            <ChatbotMessage Message={MESSAGE.RECEIVER} text={chat?.ai} isLoading={!chat?.ai && chatMessageLoading} />
          </React.Fragment>
        ))}
      </div>
      <ChatbotFooter message={message} setMessage={setMessage} handleChatMessage={handleChatMessage} />
    </div>
  );
}
