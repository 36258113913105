import { useEffect, useState } from 'react';
import { Link, NavLink, generatePath, useLocation, useParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { ChevronLeft } from 'lucide-react';
import { CreateReport, ScheduledReports } from '@pages/reports';
import { ROUTES } from '@routes';
import { REPORTING_TABS } from '@constants';

export function Reports() {
  const { pathname } = useLocation();
  const { organizationId, dashboardId } = useParams();

  const [activeTab, setActiveTab] = useState(REPORTING_TABS.CREATE_REPORT);

  useEffect(() => {
    if (pathname === pathname.includes(ROUTES.CREATE_REPORT)) {
      setActiveTab(REPORTING_TABS.CREATE_REPORT);
    } else if (pathname.includes(ROUTES.SCHEDULED_REPORTS)) {
      setActiveTab(REPORTING_TABS.SCHEDULED_REPORTS);
    }
  }, [pathname]);

  return (
    <div className='px-5 py-6 md:px-8 md:py-[2.1875rem] lg:py-5 xxl:px-10 xxl:py-6'>
      <NavLink
        to={generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId })}
        className='mb-[1.0625rem] inline-flex items-center gap-2'
      >
        <ChevronLeft className='h-[20px] w-[20px]' />
        <h2 className='text-xl font-bold leading-[1.5125rem] md:text-2xl md:leading-[1.8156rem] lg:text-base lg:leading-[1.21rem] xxl:text-2xl xxl:leading-[1.8156rem]'>
          Reports
        </h2>
      </NavLink>
      <Tabs value={activeTab} onValueChange={value => setActiveTab(value)}>
        <TabsList className='w-available mb-5 h-12 max-w-[24.375rem] md:mb-6 md:h-[2.625rem] md:max-w-[22.625rem] md:px-[0.3125rem] lg:mb-4 lg:h-[2rem] lg:max-w-[14rem] lg:px-1 xxl:mb-[1.875rem] xxl:h-[2.625rem] xxl:max-w-[22.625rem]'>
          <Link to={ROUTES.CREATE_REPORT} className='w-full'>
            <TabsTrigger
              value={REPORTING_TABS.CREATE_REPORT}
              className='h-10 text-xs xs:text-sm md:h-[2rem] md:w-[11rem] lg:h-6 lg:w-[108px] lg:text-[0.625rem] xxl:h-8 xxl:w-[11rem] xxl:text-sm'
            >
              Create Report
            </TabsTrigger>
          </Link>
          <Link to={ROUTES.SCHEDULED_REPORTS} className='w-full'>
            <TabsTrigger
              value={REPORTING_TABS.SCHEDULED_REPORTS}
              className='h-10 text-xs xs:text-sm md:h-[2rem] md:w-[11rem] lg:h-6 lg:w-[6.75rem] lg:text-[0.625rem] xxl:h-8 xxl:w-[11rem] xxl:text-sm'
            >
              Scheduled Reports
            </TabsTrigger>
          </Link>
        </TabsList>
        <TabsContent value={REPORTING_TABS.CREATE_REPORT}>
          <CreateReport />
        </TabsContent>
        <TabsContent value={REPORTING_TABS.SCHEDULED_REPORTS}>
          <ScheduledReports />
        </TabsContent>
      </Tabs>
    </div>
  );
}
