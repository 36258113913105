import { Popover, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { InfoIcon } from 'lucide-react';

export function ConnectorGuide({ showInfoIcon = false, infoContent }) {
  return (
    <>
      {showInfoIcon && (
        <span className={'absolute right-[-20px] top-[1px]'}>
          <Popover>
            <PopoverTrigger asChild>
              <button>
                <InfoIcon className='h-4 w-4' />
              </button>
            </PopoverTrigger>
            <PopoverContent align='start' side='top' className='inline-block w-auto gap-1 px-3 py-1.5 text-[13px]'>
              <p>
                {infoContent.text}{' '}
                {!!infoContent.link && (
                  <a className='text-darkPrimary underline' target='_blank' href={infoContent.link} rel='noreferrer'>
                    link
                  </a>
                )}
              </p>
            </PopoverContent>
          </Popover>
        </span>
      )}
    </>
  );
}
