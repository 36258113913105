import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { DashboardCustomWidgets, DashboardStaticWidgets } from '@pages/dashboard';
import { WIDGETS_TABS, STATIC_WIDGETS_ID, STATIC_WIDGETS_TAB_ID } from '@constants';

export function DashboardTabsSection({ handleOnDrag, isEditMode, AddWidgetsOnClick }) {
  return (
    <Tabs defaultValue={WIDGETS_TABS.STATIC_WIDGETS}>
      <TabsList className='justify-between md:h-[2.625rem] md:w-[16.75rem] md:p-[.3125rem] lg:h-8 lg:w-[11.875rem] xxl:h-[2.625rem] xxl:w-[16.75rem]'>
        <TabsTrigger
          id={STATIC_WIDGETS_TAB_ID}
          value={WIDGETS_TABS.STATIC_WIDGETS}
          className='w-1/2 lg:text-[.625rem] lg:leading-3 xxl:w-[7.625rem] xxl:text-sm xxl:leading-5'
        >
          Static Widgets
        </TabsTrigger>
        <TabsTrigger
          value={WIDGETS_TABS.CUSTOM_WIDGETS}
          className='w-1/2 lg:text-[.625rem] lg:leading-3  xxl:w-[7.625rem] xxl:text-sm xxl:leading-5'
        >
          Custom Widgets
        </TabsTrigger>
      </TabsList>
      <div className='mt-8 md:mt-6 lg:mt-5 xxl:mt-6'>
        <TabsContent id={STATIC_WIDGETS_ID} value={WIDGETS_TABS.STATIC_WIDGETS}>
          <DashboardStaticWidgets
            handleOnDrag={handleOnDrag}
            isEditMode={isEditMode}
            AddWidgetsOnClick={AddWidgetsOnClick}
          />
        </TabsContent>
        <TabsContent value={WIDGETS_TABS.CUSTOM_WIDGETS}>
          <DashboardCustomWidgets
            handleOnDrag={handleOnDrag}
            isEditMode={isEditMode}
            AddWidgetsOnClick={AddWidgetsOnClick}
          />
        </TabsContent>
      </div>
    </Tabs>
  );
}
