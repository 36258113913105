import { useEffect, useRef } from 'react';
import { Button } from '@shadcnComponent';
import Slider from 'react-slick';
import { BaseLoader, DatePicker } from '@common/components';
import { useShareDashboard } from '@pages/dashboard';
import { SharedDashboardLayout } from '@pages/sharedDashboard';
import { ARROW_KEYS, DASHBOARD_VIEW_TYPE } from '@constants';
import { DownloadIconWhite } from '@assets/svgs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function SharedDashboard() {
  const {
    shareDashboard,
    shareDashboardLoading,
    handleDateRange,
    dateRange,
    handleRangeDateFilter,
    handleClearRangeDateFilter,
    setIsDateCleared,
    showDateTimeFilter,
    setShowDateTimeFilter,
    sharedDashboardRef,
    downloadScreenshot,
    handleTablePagination,
    handleCsvPagination,
  } = useShareDashboard();

  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    infinite: shareDashboard?.data?.dashboardConfig?.length > 1,
    arrows: false,
  };

  useEffect(() => {
    const sliderElement = sliderRef.current;

    const handleKeyDown = event => {
      if (event.keyCode === ARROW_KEYS.RIGHT) {
        sliderElement.slickNext();
      } else if (event.keyCode === ARROW_KEYS.LEFT) {
        sliderElement.slickPrev();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return shareDashboardLoading ? (
    <BaseLoader />
  ) : (
    <div
      ref={sharedDashboardRef}
      className={`relative z-0 flex min-h-screen flex-col ${shareDashboard?.data?.isTextureApplied ? 'bg-[url("/src/assets/images/SlidesImgBg.png")]' : 'bg-themeColor'}`}
    >
      <div
        className='absolute left-0 top-0 z-[-1] h-full w-full opacity-[.4]'
        style={shareDashboard?.data?.isTextureApplied ? { backgroundColor: shareDashboard?.data?.headerColor } : {}}
      />
      <div
        style={shareDashboard?.data?.isTextureApplied ? {} : { backgroundColor: shareDashboard?.data?.headerColor }}
        className='px-5 py-6 md:px-8 md:py-8 lg:py-[2.8125rem] lg:pl-[4.375rem] lg:pr-[3.75rem] xxl:py-10 xxl:pl-[5.25rem] xxl:pr-[6.125rem]'
      >
        <div className='flex flex-col gap-6 md:flex-row md:items-center md:justify-between'>
          <div
            style={{
              color: shareDashboard?.data?.headerTextColor,
            }}
            className='flex items-center gap-3 text-xl font-semibold leading-[1.5125rem] md:gap-6 md:text-2xl md:leading-[1.8156rem] lg:gap-[1.125rem] lg:text-[1.0494rem] lg:leading-[1.2594rem] xxl:gap-8 xxl:text-[1.875rem] xxl:leading-9'
          >
            <img
              src={shareDashboard?.data?.logo?.image}
              alt='logo'
              className='h-[3.125rem] w-[3.125rem] rounded-full md:h-[4.375rem] md:w-[4.375rem] lg:h-[3.8475rem] lg:w-[3.8475rem] xxl:h-[6.875rem] xxl:w-[6.875rem]'
            />
            {shareDashboard?.data?.name}
          </div>
          <div id='date-picker-downloadBtn' className='flex gap-3 md:gap-4'>
            <Button
              onClick={downloadScreenshot}
              className='flex w-[4.375rem] items-center justify-center gap-2 rounded bg-slateBlue px-0 py-3 md:w-[3.5rem] md:rounded-md md:py-2.5 lg:w-[2.5rem] lg:gap-1.5 lg:rounded-md lg:py-2 xxl:w-[3.5rem] xxl:gap-2 xxl:py-2.5'
            >
              <DownloadIconWhite className='h-6 w-6 md:h-5 md:w-5 lg:h-4 lg:w-4 xxl:h-5 xxl:w-5' />
            </Button>
            <DatePicker
              placeholder='Date Filter'
              showDateTimeFilter={showDateTimeFilter}
              setShowDateTimeFilter={setShowDateTimeFilter}
              setIsDateCleared={setIsDateCleared}
              setDate={handleDateRange}
              date={dateRange}
              handleClearRangeDateFilter={handleClearRangeDateFilter}
              handleRangeDateFilter={handleRangeDateFilter}
              className='flex-grow rounded'
              buttonClass='py-4 pl-5 md:py-2 md:pl-3 lg:py-1 xxl:py-2 md:w-[12.75rem] lg:w-[10rem] xxl:w-[12.75rem] bg-white border-none lg:leading-6 rounded md:rounded-md'
              calendarClass='hidden'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-1 flex-col'>
        <div>
          {shareDashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES && (
            <div className='relative flex flex-1 flex-col justify-between px-[1.25rem] pb-10 scrollbar-none md:px-10 md:py-6'>
              <Slider ref={sliderRef} {...settings} className='bg-transparent'>
                {shareDashboard?.data?.dashboardConfig?.map((item, i) => (
                  <div key={i} className='!max-h-[calc(100vh-24.5rem)] overflow-hidden'>
                    <SharedDashboardLayout
                      layout={item}
                      handleTablePagination={handleTablePagination}
                      handleCsvPagination={handleCsvPagination}
                      isSlideMode={true}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
          {shareDashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.NORMAL && (
            <div className='relative flex flex-1 flex-col justify-between px-[1.25rem] pb-10 scrollbar-none md:px-10 md:py-6'>
              <SharedDashboardLayout
                layout={shareDashboard?.data?.dashboardConfig}
                handleTablePagination={handleTablePagination}
                handleCsvPagination={handleCsvPagination}
              />
            </div>
          )}
          {shareDashboard?.data?.sponsorLogo?.image && (
            <div className='mt-6 flex flex-col items-center justify-center gap-3 md:gap-5'>
              <span className='text-sm font-normal leading-[1.0588rem] text-darkGray md:text-base md:leading-[1.21rem]'>
                Powered by
              </span>
              <img src={shareDashboard?.data?.sponsorLogo?.image} alt='logo' className='h-6 object-contain md:h-10' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
