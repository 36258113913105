import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { ROUTES } from '@routes';
import { COLORS } from '@constants';
import { OptionsDot } from '@assets/svgs';

export function DashboardActionPopover({
  dashboard,
  setDashboardId,
  handleDuplicateDashboard,
  setSelectedDashboard,
  setShowDeleteDialog,
  showDeleteDialog,
  isMember,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  return (
    <Popover>
      <PopoverTrigger
        onClick={e => {
          e.stopPropagation();
          setDashboardId(dashboard?.id);
        }}
        className={isMember ? 'pointer-events-none' : ''}
      >
        <OptionsDot strokeColor={isMember ? COLORS.TINT_GRAY : COLORS.DARK_SECONDARY} />
      </PopoverTrigger>
      <PopoverContent align='end' className='w-[10.6875rem] p-0'>
        <ul className='cursor-pointer'>
          <li
            className='border-b border-tableRowHover px-[0.8125rem] py-[0.6875rem] text-sm font-medium leading-5'
            onClick={e => {
              e.stopPropagation();
              navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: dashboard?.id }), {
                state: { showEditMode: true },
              });
            }}
          >
            Edit
          </li>
          <li
            className='border-b border-tableRowHover px-[0.8125rem] py-[0.6875rem] text-sm font-medium leading-5'
            onClick={e => {
              e.stopPropagation();
              handleDuplicateDashboard({});
            }}
          >
            Copy as Duplicate
          </li>
          <li
            className='px-[0.8125rem] py-[0.6875rem] text-sm font-medium leading-5 text-errorColor'
            onClick={e => {
              e.stopPropagation();
              setSelectedDashboard(dashboard);
              setShowDeleteDialog({ ...showDeleteDialog, [dashboard]: true });
            }}
          >
            Delete
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
}
