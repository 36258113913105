import { useLocation } from 'react-router-dom';
import { Button, ScrollArea } from '@shadcnComponent';
import { BaseLoader, Chatbot } from '@common/components';
import {
  DashboardComments,
  WidgetsSidebar,
  EditWidgetsSidebar,
  useComments,
  useDashboard,
  useShareDashboard,
  DashboardWidgetsLayout,
  DashboardHeader,
  DashboardSlideWidgets,
  GoalTrackingSidebar,
} from '@pages/dashboard';
import { getLocalStorageItem } from '@utils';
import { DASHBOARD_VIEW_TYPE } from '@constants';
import performanceMeterIcon from '@assets/images/PerformanceMeterIcon.png';

export function Dashboard() {
  const {
    isEditMode,
    setIsEditMode,
    isCommentMode,
    setIsCommentMode,
    handleGridLayoutChange,
    handleOnDrag,
    handleOnDrop,
    handleDragOver,
    handleDragLeave,
    logoRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    isUploadedLogo,
    dashboardName,
    setDashboardName,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    setDeleteDashboardLoading,
    handleDeleteDashboard,
    dashboard,
    dashboardFetching,
    removeLayoutWidget,
    handleTextChange,
    handleVideoInputChange,
    dateRange,
    handleRangeDateFilter,
    showDateTimeFilter,
    setShowDateTimeFilter,
    handleClearRangeDateFilter,
    setIsDateCleared,
    dateRangeSet,
    handleSelectDateRange,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    compareRangeSet,
    handleSelectCompareRange,
    compareRangeEnd,
    compareRangeStart,
    setCompareRangeEnd,
    setCompareRangeStart,
    handleDateRange,
    page,
    handleTablePagination,
    setLayoutWidth,
    AddWidgetsOnClick,
    isFilterApply,
    showAddDataSourceDialog,
    setShowAddDataSourceDialog,
    addedDataSource,
    handleSelectedDataSource,
    addDataSourceListing,
    selectedDataSourceIds,
    handleAddDataSource,
    addDataSourceLoading,
    layoutWidth,
    dropZonePosition,
    droppedWidget,
    isEditWidgetMode,
    setIsEditWidgetMode,
    summaryWidget,
    summaryWidgetFetching,
    handleClone,
    handleEditWidget,
    editWidget,
    setEditWidget,
    editWidgetDateRange,
    setEditWidgetDateRange,
    handleDateRangeChange,
    showDatePicker,
    setShowDatePicker,
    handleSelectEditWidgetDateRange,
    handleSelectEditWidgetCompareRange,
    handleEditClearRangeDateFilter,
    editWidgetDateRangeStart,
    editWidgetDateRangeEnd,
    editWidgetDateRangeSet,
    editWidgetCompareRangeStart,
    editWidgetCompareRangeEnd,
    editWidgetCompareRangeSet,
    setEditWidgetDateRangeStart,
    setEditWidgetDateRangeEnd,
    setEditWidgetCompareRangeStart,
    setEditWidgetCompareRangeEnd,
    handleEditWidgetRangeDateFilter,
    widgetDimension,
    widgetDimensionRefetch,
    handleImageInputChange,
    widgetImageRef,
    handleCsvInputChange,
    csvRef,
    handleDashboardMode,
    selectSliderWidget,
    dashboardMode,
    setSelectSliderWidget,
    addDashboardSlide,
    organizationId,
    dashboardId,
    handleRemoveSlide,
    isUploadedWidgetCsv,
    handleLogoUpload,
    isUploadedWidgetImage,
    handleEditCommentMode,
    commentedWidget,
    setCommentedWidget,
    handleCommentUpdate,
    csvPage,
    saveAsTemplateLoading,
    showTemplateDialog,
    setShowTemplateDialog,
    templateCategory,
    templateCategoryFetching,
    templateCategoryNextPage,
    templateCategoryHasNextPage,
    removeWidgetSlide,
    setRemoveWidgetSlide,
    isGoalTrackingMode,
    setIsGoalTrackingMode,
    showGoalDialog,
    setShowGoalDialog,
    createFormik,
    dashboardsListing,
    widgetListing,
    createGoalLoading,
    showDashboardGoalDialog,
    setShowDashboardGoalDialog,
    saveAsTemplateFormik,
  } = useDashboard();

  const {
    commentsListing,
    formik,
    createCommentLoading,
    scrollContainerRef,
    handleScroll,
    resetForm,
    tagUsersList,
    isUsersFetching,
  } = useComments(isCommentMode, commentedWidget);

  const {
    sharedDashboardURL,
    shareDashboardUrlFetching,
    shareDashboardUrlRefetch,
    copyToClipboard,
    isCopied,
    setIsCopied,
  } = useShareDashboard();

  const { state } = useLocation();

  const showAiToken = getLocalStorageItem('showAiToken');
  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  return (
    <div className='relative h-full overflow-hidden'>
      <DashboardHeader
        logoRef={logoRef}
        uploadedLogo={uploadedLogo}
        setUploadedLogo={setUploadedLogo}
        setIsUploadedLogo={setIsUploadedLogo}
        isUploadedLogo={isUploadedLogo}
        dashboardName={dashboardName}
        setDashboardName={setDashboardName}
        isCommentMode={isCommentMode}
        isEditMode={isEditMode}
        dashboard={dashboard}
        handleDeleteDashboard={handleDeleteDashboard}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setDeleteDashboardLoading={setDeleteDashboardLoading}
        deleteDashboardLoading={deleteDashboardLoading}
        showAddDataSourceDialog={showAddDataSourceDialog}
        setShowAddDataSourceDialog={setShowAddDataSourceDialog}
        addedDataSource={addedDataSource}
        handleSelectedDataSource={handleSelectedDataSource}
        addDataSourceListing={addDataSourceListing}
        selectedDataSourceIds={selectedDataSourceIds}
        handleAddDataSource={handleAddDataSource}
        addDataSourceLoading={addDataSourceLoading}
        setIsEditMode={setIsEditMode}
        setIsCommentMode={setIsCommentMode}
        setIsEditWidgetMode={setIsEditWidgetMode}
        setEditWidget={setEditWidget}
        setIsDateCleared={setIsDateCleared}
        dateRange={dateRange}
        handleDateRange={handleDateRange}
        handleRangeDateFilter={handleRangeDateFilter}
        showDateTimeFilter={showDateTimeFilter}
        setShowDateTimeFilter={setShowDateTimeFilter}
        handleClearRangeDateFilter={handleClearRangeDateFilter}
        dateRangeSet={dateRangeSet}
        handleSelectDateRange={handleSelectDateRange}
        dateRangeStart={dateRangeStart}
        setDateRangeStart={setDateRangeStart}
        dateRangeEnd={dateRangeEnd}
        setDateRangeEnd={setDateRangeEnd}
        compareRangeSet={compareRangeSet}
        handleSelectCompareRange={handleSelectCompareRange}
        compareRangeEnd={compareRangeEnd}
        compareRangeStart={compareRangeStart}
        setCompareRangeEnd={setCompareRangeEnd}
        setCompareRangeStart={setCompareRangeStart}
        commentsListing={commentsListing}
        formik={formik}
        createCommentLoading={createCommentLoading}
        scrollContainerRef={scrollContainerRef}
        handleScroll={handleScroll}
        shouldRenderComponent={dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES}
        resetForm={resetForm}
        setIsCopied={setIsCopied}
        shareDashboardUrlRefetch={shareDashboardUrlRefetch}
        shareDashboardUrlFetching={shareDashboardUrlFetching}
        sharedDashboardURL={sharedDashboardURL}
        copyToClipboard={copyToClipboard}
        isCopied={isCopied}
        AddWidgetsOnClick={AddWidgetsOnClick}
        handleDashboardMode={handleDashboardMode}
        dashboardMode={dashboardMode}
        addDashboardSlide={addDashboardSlide}
        organizationId={organizationId}
        dashboardId={dashboardId}
        selectSliderWidget={selectSliderWidget}
        handleLogoUpload={handleLogoUpload}
        setCommentedWidget={setCommentedWidget}
        isEditWidgetMode={isEditWidgetMode}
        saveAsTemplateLoading={saveAsTemplateLoading}
        showTemplateDialog={showTemplateDialog}
        setShowTemplateDialog={setShowTemplateDialog}
        templateCategory={templateCategory}
        templateCategoryFetching={templateCategoryFetching}
        templateCategoryNextPage={templateCategoryNextPage}
        templateCategoryHasNextPage={templateCategoryHasNextPage}
        isGoalTrackingMode={isGoalTrackingMode}
        setIsGoalTrackingMode={setIsGoalTrackingMode}
        page={state?.page}
        viewType={state?.viewType}
        saveAsTemplateFormik={saveAsTemplateFormik}
        userOrganizationRole={userOrganizationRole}
      />
      {dashboardFetching ? (
        <BaseLoader />
      ) : (
        <div className='relative flex'>
          <div
            className={`flex h-[calc(100vh-22.5rem)] flex-1 flex-col overflow-y-auto scrollbar-none md:h-[calc(100vh-17.75rem)] lg:h-[calc(100vh-120px)] xxl:h-[calc(100vh-200px)] ${(isCommentMode || isGoalTrackingMode) && 'mr-[21.25rem] transition-all duration-500 ease-in'}`}
            onDrop={handleOnDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {isEditMode || isGoalTrackingMode
              ? ''
              : showAiToken && (
                <div>
                  <div className='relative w-full overflow-hidden overflow-y-auto border border-t-0 border-widgetBorderColor bg-white px-6 py-5 shadow-[0_5px_20px_0_rgba(0,0,0,0.05)] scrollbar-none md:h-[11.9375rem] lg:h-[9.75rem] xxl:h-[14.375rem]'>
                    <div className='flex items-start gap-6'>
                      <span className='h-8 min-w-8'>
                        <img src={performanceMeterIcon} alt='' className='h-full w-full' />
                      </span>
                      <div className='flex flex-col gap-[0.875rem]'>
                        <h3 className='text-[1.125rem] leading-[1.3613rem] text-darkSecondary'>Dashboard Insights</h3>
                        {summaryWidgetFetching ? (
                          <BaseLoader />
                        ) : (
                          <p className='text-sm leading-6 text-darkSecondary'>{summaryWidget}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div
              className={`flex flex-col md:h-full lg:flex-row ${(isEditMode || isGoalTrackingMode) && dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES ? 'overflow-y-hidden lg:h-[calc(100vh-120px)] xxl:h-[calc(100vh-10.625rem)]' : ''}`}
            >
              {dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES ? (
                <div
                  className={`w-vw min-w-[266px] space-y-4 bg-white pt-6 md:flex md:flex-col-reverse md:pb-5 lg:flex-col lg:pb-0 ${isEditMode || isGoalTrackingMode ? 'overflow-y-hidden md:min-h-[239px] lg:min-h-[calc(100vh-120px)] xxl:min-h-[calc(100vh-10.625rem)]' : ''}`}
                >
                  {isEditMode || isGoalTrackingMode ? (
                    <div className='flex pr-[25px] md:justify-center lg:justify-end'>
                      <Button className='w-[12.5625rem] md:mt-2 lg:mt-0' onClick={addDashboardSlide}>
                        Add slide
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                  <ScrollArea
                    className={` overflow-hidden scrollbar-none ${isEditMode || isGoalTrackingMode ? '!mt-0 lg:!mt-4 lg:h-[calc(100vh-188px)] xxl:h-[calc(100vh-250px)]' : 'lg:h-[calc(100vh-16.25rem)]'}`}
                  >
                    <div
                      className={`flex w-[calc(100vw-2.5rem)] gap-4 overflow-auto text-center scrollbar md:flex md:gap-x-7 md:pl-10 md:pr-5 lg:w-[unset] lg:flex-col lg:pl-[24px] xxl:w-[unset] xxl:pb-5 ${isEditMode || isGoalTrackingMode ? 'md:w-[calc(100vw-304px)]' : 'md:w-[100vw]'}`}
                    >
                      <DashboardSlideWidgets
                        dashboard={dashboard?.data?.dashboardConfig}
                        handleEditWidget={handleEditWidget}
                        handleClone={handleClone}
                        page={page}
                        csvPage={csvPage}
                        selectSliderWidget={selectSliderWidget}
                        setSelectSliderWidget={setSelectSliderWidget}
                        handleRemoveSlide={handleRemoveSlide}
                        removeWidgetSlide={removeWidgetSlide}
                        setRemoveWidgetSlide={setRemoveWidgetSlide}
                      />
                    </div>
                  </ScrollArea>
                </div>
              ) : (
                ''
              )}
              <DashboardWidgetsLayout
                dashboard={dashboard}
                handleGridLayoutChange={handleGridLayoutChange}
                layoutWidth={layoutWidth}
                setLayoutWidth={setLayoutWidth}
                isEditMode={isEditMode}
                isGoalTrackingMode={isGoalTrackingMode}
                isEditWidgetMode={isEditWidgetMode}
                removeLayoutWidget={removeLayoutWidget}
                handleEditWidget={handleEditWidget}
                handleClone={handleClone}
                compareRangeSet={compareRangeSet}
                dateRangeSet={dateRangeSet}
                isFilterApply={isFilterApply}
                page={page}
                handleTablePagination={handleTablePagination}
                handleTextChange={handleTextChange}
                handleVideoInputChange={handleVideoInputChange}
                handleImageInputChange={handleImageInputChange}
                widgetImageRef={widgetImageRef}
                handleCsvInputChange={handleCsvInputChange}
                csvRef={csvRef}
                dropZonePosition={dropZonePosition}
                droppedWidget={droppedWidget}
                editWidget={editWidget}
                selectSliderWidget={selectSliderWidget}
                isUploadedWidgetCsv={isUploadedWidgetCsv}
                isUploadedWidgetImage={isUploadedWidgetImage}
                handleEditCommentMode={handleEditCommentMode}
                csvPage={csvPage}
                isCommentMode={isCommentMode}
                showGoalDialog={showGoalDialog}
                setShowGoalDialog={setShowGoalDialog}
                createFormik={createFormik}
                dashboardsListing={dashboardsListing}
                widgetListing={widgetListing}
                createGoalLoading={createGoalLoading}
                userOrganizationRole={userOrganizationRole}
              />
            </div>
          </div>
          <WidgetsSidebar handleOnDrag={handleOnDrag} isEditMode={isEditMode} />
          <EditWidgetsSidebar
            editWidget={editWidget}
            setEditWidget={setEditWidget}
            setIsEditWidgetMode={setIsEditWidgetMode}
            isEditWidgetMode={isEditWidgetMode}
            setIsEditMode={setIsEditMode}
            handleDateRangeChange={handleDateRangeChange}
            editWidgetDateRange={editWidgetDateRange}
            setEditWidgetDateRange={setEditWidgetDateRange}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            handleSelectEditWidgetDateRange={handleSelectEditWidgetDateRange}
            handleSelectEditWidgetCompareRange={handleSelectEditWidgetCompareRange}
            handleEditClearRangeDateFilter={handleEditClearRangeDateFilter}
            editWidgetDateRangeStart={editWidgetDateRangeStart}
            editWidgetDateRangeEnd={editWidgetDateRangeEnd}
            editWidgetDateRangeSet={editWidgetDateRangeSet}
            editWidgetCompareRangeStart={editWidgetCompareRangeStart}
            editWidgetCompareRangeEnd={editWidgetCompareRangeEnd}
            editWidgetCompareRangeSet={editWidgetCompareRangeSet}
            setEditWidgetDateRangeStart={setEditWidgetDateRangeStart}
            setEditWidgetDateRangeEnd={setEditWidgetDateRangeEnd}
            setEditWidgetCompareRangeStart={setEditWidgetCompareRangeStart}
            setEditWidgetCompareRangeEnd={setEditWidgetCompareRangeEnd}
            handleEditWidgetRangeDateFilter={handleEditWidgetRangeDateFilter}
            widgetDimension={widgetDimension}
            widgetDimensionRefetch={widgetDimensionRefetch}
            handleCommentUpdate={handleCommentUpdate}
            setIsCommentMode={setIsCommentMode}
            setIsGoalTrackingMode={setIsGoalTrackingMode}
          />
          <DashboardComments
            isCommentMode={isCommentMode}
            commentsListing={commentsListing}
            formik={formik}
            createCommentLoading={createCommentLoading}
            scrollContainerRef={scrollContainerRef}
            handleScroll={handleScroll}
            isUsersFetching={isUsersFetching}
            tagUsersList={tagUsersList?.data?.results || []}
          />
          <GoalTrackingSidebar
            handleOnDrag={handleOnDrag}
            isGoalTrackingMode={isGoalTrackingMode}
            setIsGoalTrackingMode={setIsGoalTrackingMode}
            showDashboardGoalDialog={showDashboardGoalDialog}
            setShowDashboardGoalDialog={setShowDashboardGoalDialog}
            createFormik={createFormik}
            dashboardsListing={dashboardsListing}
            widgetListing={widgetListing}
            createGoalLoading={createGoalLoading}
          />
          {showAiToken && <Chatbot />}
        </div>
      )}
    </div>
  );
}
