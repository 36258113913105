import { DATA_STAT } from '@constants';
import chart1 from '@assets/images/chart1.png';
import chart2 from '@assets/images/chart2.png';
import chart3 from '@assets/images/chart3.png';
import graph1 from '@assets/images/graph1.png';
import map from '@assets/images/map.png';

export function WhiteLabelPreview({
  dashoboardHeader,
  logo,
  sponsorLogo,
  headerColor,
  headerTextColor,
  isTextureApplied,
  className,
}) {
  return (
    <div
      className={`relative z-0 h-[22.125rem] w-full ${className} ${isTextureApplied ? 'bg-[url("/src/assets/images/SlidesImgBg.png")]' : 'bg-themeColor'} md:h-[30.375rem] lg:h-[unset] lg:w-full`}
    >
      <div
        className='absolute left-0 top-0 z-[-1] h-full w-full opacity-[.4]'
        style={isTextureApplied ? { backgroundColor: headerColor } : {}}
      />
      <div
        style={isTextureApplied ? {} : { backgroundColor: headerColor }}
        className={`h-[5.1937rem] w-full pl-5 pr-[1.4375rem] pt-[0.5625rem] md:h-[7.125rem] md:pl-[1.6875rem] md:pr-8 md:pt-[0.8125rem] lg:h-[4.6744rem] lg:pl-[1.145rem] lg:pr-[1.3356rem] lg:pt-[0.545rem] xxl:h-[7.3262rem] xxl:pl-[1.7944rem] xxl:pr-[2.0931rem] xxl:pt-[0.8544rem] ${dashoboardHeader}`}
      >
        <div className='mb-[0.6056rem] flex items-center gap-[0.4844rem] md:mb-[0.8325rem] md:gap-[0.6656rem] lg:mb-[0.545rem] lg:gap-[0.4363rem] xxl:mb-[0.8544rem] xxl:gap-[0.6837rem]'>
          <img
            src={logo}
            alt='logo'
            className='h-[1.6656rem] w-[1.6656rem] rounded-full md:h-[2.2881rem] md:w-[2.2881rem] lg:h-[1.4988rem] lg:w-[1.4988rem] xxl:h-[2.3494rem] xxl:w-[2.3494rem]'
          />
          <h6
            style={{ color: headerTextColor }}
            className='text-[0.4544rem] font-semibold leading-[0.545rem] lg:text-[0.4088rem] lg:leading-[0.4906rem] xxl:text-[0.6406rem] xxl:leading-[0.7688rem]'
          >
            Dashboard
          </h6>
        </div>
        <div className='flex h-[0.8481rem] w-full items-center rounded-[0.1281rem] bg-slateGray pl-[0.3631rem] md:h-[1.125rem] md:pl-2 lg:h-[0.7631rem] lg:pl-[0.3269rem] xxl:h-[1.1963rem] xxl:pl-[0.5125rem]'>
          <h6 className='md:leading-2 text-[0.3031rem] font-semibold leading-[0.3631rem] text-white md:text-[0.4375rem] lg:text-[0.2725rem] lg:leading-[0.3269rem] xxl:text-[0.4275rem] xxl:leading-[0.5125rem]'>
            SEO
          </h6>
        </div>
      </div>
      <div className='relative pl-5 pr-[1.4375rem] md:pl-[1.6875rem] md:pr-8 lg:pl-[1.145rem] lg:pr-[1.3356rem] xxl:pl-[1.7944rem] xxl:pr-[2.0931rem]'>
        <div className='absolute -top-[34px] flex gap-[0.5313rem] md:-top-12 lg:-top-[1.5625rem] lg:gap-[0.4644rem] xxl:-top-[2.905rem] xxl:gap-[0.715rem]'>
          {DATA_STAT.map((data, index) => (
            <div
              key={index}
              className='h-8 w-[15%] rounded md:h-[2.3125rem] md:w-[4.6875rem] lg:h-[1.5369rem] lg:w-[3.0731rem] xxl:h-[2.3881rem] xxl:w-[4.7919rem]'
            >
              <img src={data.imgUrl} alt='stat-image' />
            </div>
          ))}
        </div>
        <div className='mt-[1.125rem] flex justify-between md:mt-[1.5625rem] lg:mt-4 xxl:mt-[1.655rem]'>
          <img
            src={graph1}
            alt='graph1'
            className='h-[5.0625rem] w-[60%] md:h-[9.0625rem] md:w-[28.125rem] lg:h-[4.5625rem] lg:w-[10.1156rem] xxl:h-[7.125rem] xxl:w-[15.8125rem]'
          />
          <img
            src={map}
            alt='map'
            className='h-[5.0625rem] w-[30%] md:h-[9.0625rem] md:w-[11.25rem] lg:h-[4.5625rem] lg:w-[6.5625rem] xxl:h-[7.125rem] xxl:w-[10.25rem]'
          />
        </div>
        <div className='md:leading-2 my-[0.4375rem] flex h-[0.8481rem] w-full items-center rounded-[0.125rem] bg-slateGray pl-[0.5125rem] text-[0.3031rem] font-semibold  leading-[0.3631rem] text-white text-white md:my-2.5 md:h-[1.125rem] md:pl-2 md:text-[0.4375rem] lg:mb-[0.4088rem] lg:mt-[0.4194rem] lg:h-[0.7631rem] lg:text-[0.2725rem] lg:leading-[0.3269rem] xxl:mb-[0.6406rem] xxl:mt-[0.6581rem] xxl:h-[1.1963rem] xxl:text-[0.4275rem] xxl:leading-[0.5125rem]'>
          Youtube
        </div>
        <div className='flex justify-between'>
          <img
            src={chart1}
            alt='chart1'
            className='h-[5.75rem] w-1/3 md:h-[9.375rem] lg:h-[5.125rem] lg:w-[5.4375rem] xxl:h-[8.0781rem] xxl:w-[8.53rem]'
          />
          <img
            src={chart2}
            alt='chart2'
            className='h-[5.75rem] w-1/3 md:h-[9.375rem] lg:h-[5.125rem]  lg:w-[5.4375rem] xxl:h-[8.0781rem] xxl:w-[8.53rem]'
          />
          <img
            src={chart3}
            alt='chart3'
            className='h-[5.75rem] w-1/3 md:h-[9.375rem] lg:h-[5.125rem] lg:w-[5.4375rem] xxl:h-[8.0781rem] xxl:w-[8.53rem]'
          />
        </div>
        {!!sponsorLogo && (
          <div className='mt-4 flex flex-col items-center justify-center gap-[0.3956rem]  md:mt-5 lg:mt-[0.8969rem] xxl:mt-[1.4056rem]'>
            <span className='text-[0.3419rem] font-normal leading-[0.4138rem] text-darkGray'>Powered by</span>
            <img
              src={sponsorLogo}
              alt='logo'
              className='h-2.5  w-[3.25rem] object-contain md:h-3.5 md:w-[4.5rem] lg:!h-[0.545rem] lg:w-[2.9444rem] xxl:!h-[0.8538rem] xxl:w-[4.6144rem]'
            />
          </div>
        )}
      </div>
    </div>
  );
}
