export function CopyIcon({ className }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_3619_28635)'>
        <path
          d='M8.33398 10.8335C8.69186 11.3119 9.14845 11.7078 9.67278 11.9943C10.1971 12.2807 10.7769 12.4511 11.3729 12.4937C11.9688 12.5364 12.567 12.4504 13.1268 12.2416C13.6866 12.0328 14.1949 11.706 14.6173 11.2835L17.1173 8.78347C17.8763 7.99762 18.2963 6.94512 18.2868 5.85263C18.2773 4.76014 17.8391 3.71509 17.0666 2.94256C16.294 2.17002 15.249 1.73182 14.1565 1.72233C13.064 1.71283 12.0115 2.13281 11.2257 2.8918L9.79232 4.3168'
          stroke='#00ACE7'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.6659 9.16653C11.308 8.68809 10.8514 8.29221 10.3271 8.00575C9.80274 7.71928 9.22293 7.54893 8.62698 7.50625C8.03103 7.46357 7.43287 7.54956 6.87307 7.75838C6.31327 7.9672 5.80493 8.29397 5.38252 8.71653L2.88252 11.2165C2.12353 12.0024 1.70355 13.0549 1.71305 14.1474C1.72254 15.2399 2.16075 16.2849 2.93328 17.0574C3.70581 17.83 4.75087 18.2682 5.84335 18.2777C6.93584 18.2872 7.98835 17.8672 8.77419 17.1082L10.1992 15.6832'
          stroke='#00ACE7'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3619_28635'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
