import { Label, RadioGroup, RadioGroupItem } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { CREATE_DASHBOARD_TYPE_CLASS } from '@constants';
import dashboardTypeImage from '@assets/images/DashboardTypeImage.png';
import { EmptyDashboardIcon, SliderDashboardIcon } from '@assets/svgs';

export function CreateDashboardStep5({ value, handleSelect, setCreateDashboardStep1 }) {
  return (
    <div id='step-5' className='mt-4 md:mt-6 lg:mt-4 xxl:mt-6'>
      <div className='flex flex-col gap-2 md:gap-1.5 lg:gap-1 xxl:gap-1.5'>
        <h2 className='text-sm font-semibold leading-[1.0625rem] text-midNightBlue md:text-lg md:leading-7 lg:text-xs lg:leading-[.9375rem] xxl:text-lg'>
          Select Dashboard Mode
        </h2>
        <p className='text-xs font-normal leading-[.9375rem] text-slateGray md:text-sm md:leading-5 lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-5'>
          Make changes to your profile here. Click save when you&apos;re done.
        </p>
      </div>
      <div className='mt-8 flex flex-col gap-4 md:mt-6 md:flex-row lg:mt-4 xxl:mt-8 xxl:gap-8'>
        <RadioGroup
          className={cn(
            'flex flex-row gap-5 md:basis-[15rem] md:flex-col md:gap-4 lg:basis-[12.5rem] lg:gap-[0.8125rem] xxl:basis-[18.75rem] xxl:gap-3',
            CREATE_DASHBOARD_TYPE_CLASS
          )}
          value={value}
          onValueChange={value => {
            handleSelect(value);
            setCreateDashboardStep1(prev => {
              return {
                ...prev,
                dashboardType: value,
              };
            });
          }}
        >
          <div className='h-40 w-40 md:h-[13.625rem] md:w-[15rem] lg:h-[9.125rem] lg:w-[12.5rem] xxl:h-[13.25rem] xxl:w-[18.75rem]'>
            <RadioGroupItem value='NORMAL' id='NORMAL' className='peer sr-only' />
            <Label
              htmlFor='NORMAL'
              className='flex h-full flex-col items-center justify-center gap-5 rounded-md border-2 border-muted bg-popover px-4 py-4 peer-data-[state=checked]:border-darkPrimary md:gap-4 md:p-6 lg:gap-4 lg:gap-5 lg:py-5 xxl:gap-4 xxl:gap-5 xxl:gap-5 xxl:pb-6 xxl:pt-[1.6875rem] [&:has([data-state=checked])]:border-darkPrimary'
            >
              <div>
                <EmptyDashboardIcon className='h-20 w-[5.4375rem] md:h-[5.625rem] md:w-[7.5rem] lg:h-[75px] lg:w-[6.25rem] xxl:h-[6.9375rem] xxl:w-[9.25rem]' />
              </div>
              <p className='text-xs font-semibold leading-[.875rem] text-midNightBlue md:text-sm md:leading-4 lg:text-xs lg:leading-[.875rem] xxl:text-sm xxl:leading-4'>
                Normal Mode
              </p>
            </Label>
          </div>
          <div className='h-40 w-40 md:h-[13.625rem] md:w-[15rem] lg:h-[9.125rem] lg:w-[12.5rem] xxl:h-[13.25rem] xxl:w-[18.75rem]'>
            <RadioGroupItem value='SLIDES' id='SLIDES' className='peer sr-only' />
            <Label
              htmlFor='SLIDES'
              className='flex h-full flex-col items-center justify-center gap-5 rounded-md border-2 border-muted bg-popover px-4 py-4 peer-data-[state=checked]:border-darkPrimary md:gap-4 md:p-6 lg:gap-4 lg:gap-5 lg:py-5 xxl:gap-5 xxl:gap-5 xxl:pb-6 xxl:pt-[1.875rem] [&:has([data-state=checked])]:border-darkPrimary'
            >
              <SliderDashboardIcon className='h-20 w-[5.4375rem] md:h-[5.625rem] md:w-[7.5rem] lg:h-[4.6875rem] lg:w-[6.25rem] xxl:h-[6.9375rem] xxl:w-[9.25rem]' />
              <p className='text-xs font-semibold leading-[.875rem] text-midNightBlue md:text-sm md:leading-4 lg:text-xs lg:leading-[.875rem] xxl:text-sm xxl:leading-4'>
                Slide Mode
              </p>
            </Label>
          </div>
        </RadioGroup>
        <div className='flex-1'>
          <img src={dashboardTypeImage} className='h-[13.75rem] w-full md:h-full lg:h-[19.0625rem] xxl:h-[27.25rem]' />
        </div>
      </div>
    </div>
  );
}
