import { Command, CommandInput } from '@shadcnComponent';
import { debounce } from 'lodash';

export function DataSourceSearch({ setSearch }) {
  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  return (
    <Command className='h-12 rounded-lg border md:h-11 lg:h-8 xxl:h-10' onChange={handleSearchChange}>
      <CommandInput
        placeholder='Search'
        className='h-[2.9375rem] md:h-[2.6875rem] lg:h-[1.9375rem] xxl:h-[2.4375rem]'
      />
    </Command>
  );
}
