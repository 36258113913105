import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { DataTab, DisplayTab, GeneralTab } from '@pages/dashboard';
import { EDIT_WIDGET_TABS, STATIC, WIDGETS_TYPE } from '@constants';

export function EditWidgetsTabs({
  editWidget,
  setEditWidget,
  editWidgetDateRange,
  setEditWidgetDateRange,
  handleDateRangeChange,
  showDatePicker,
  setShowDatePicker,
  handleSelectEditWidgetDateRange,
  handleSelectEditWidgetCompareRange,
  handleEditClearRangeDateFilter,
  editWidgetDateRangeStart,
  editWidgetDateRangeEnd,
  editWidgetDateRangeSet,
  editWidgetCompareRangeStart,
  editWidgetCompareRangeEnd,
  editWidgetCompareRangeSet,
  setEditWidgetDateRangeStart,
  setEditWidgetDateRangeEnd,
  setEditWidgetCompareRangeStart,
  setEditWidgetCompareRangeEnd,
  handleEditWidgetRangeDateFilter,
  widgetDimension,
  widgetDimensionRefetch,
  activeTab,
  setActiveTab,
  handleCommentUpdate,
}) {
  return (
    <Tabs
      value={activeTab}
      onValueChange={value => {
        value === EDIT_WIDGET_TABS.GENERAL && editWidget?.connectorId && widgetDimensionRefetch();
        setActiveTab(value);
      }}
    >
      <TabsList className='mb-6'>
        <TabsTrigger value={EDIT_WIDGET_TABS.GENERAL} className='!w-available'>
          General
        </TabsTrigger>
        {![STATIC, WIDGETS_TYPE.GOAL].includes(editWidget?.connectorName) && (
          <TabsTrigger value={EDIT_WIDGET_TABS.DATA} className='!w-available'>
            Data
          </TabsTrigger>
        )}
        {![WIDGETS_TYPE.GOAL].includes(editWidget?.connectorName) && (
          <TabsTrigger value={EDIT_WIDGET_TABS.DISPLAY} className='!w-available'>
            Display
          </TabsTrigger>
        )}
      </TabsList>
      <TabsContent value={EDIT_WIDGET_TABS.GENERAL}>
        <GeneralTab
          editWidget={editWidget}
          setEditWidget={setEditWidget}
          editWidgetDateRange={editWidgetDateRange}
          setEditWidgetDateRange={setEditWidgetDateRange}
          handleDateRangeChange={handleDateRangeChange}
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
          handleSelectEditWidgetDateRange={handleSelectEditWidgetDateRange}
          handleSelectEditWidgetCompareRange={handleSelectEditWidgetCompareRange}
          handleEditClearRangeDateFilter={handleEditClearRangeDateFilter}
          editWidgetDateRangeStart={editWidgetDateRangeStart}
          editWidgetDateRangeEnd={editWidgetDateRangeEnd}
          editWidgetDateRangeSet={editWidgetDateRangeSet}
          editWidgetCompareRangeStart={editWidgetCompareRangeStart}
          editWidgetCompareRangeEnd={editWidgetCompareRangeEnd}
          editWidgetCompareRangeSet={editWidgetCompareRangeSet}
          setEditWidgetDateRangeStart={setEditWidgetDateRangeStart}
          setEditWidgetDateRangeEnd={setEditWidgetDateRangeEnd}
          setEditWidgetCompareRangeStart={setEditWidgetCompareRangeStart}
          setEditWidgetCompareRangeEnd={setEditWidgetCompareRangeEnd}
          handleEditWidgetRangeDateFilter={handleEditWidgetRangeDateFilter}
          handleCommentUpdate={handleCommentUpdate}
        />
      </TabsContent>
      {editWidget?.connectorName !== STATIC && !!editWidget.table && (
        <TabsContent value={EDIT_WIDGET_TABS.DATA}>
          <DataTab editWidget={editWidget} setEditWidget={setEditWidget} widgetDimension={widgetDimension} />
        </TabsContent>
      )}
      <TabsContent value={EDIT_WIDGET_TABS.DISPLAY}>
        <DisplayTab editWidget={editWidget} setEditWidget={setEditWidget} />
      </TabsContent>
    </Tabs>
  );
}
