import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { CopyPlus, Pencil, Trash2 } from 'lucide-react';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import { MenuDots } from '@assets/svgs';

export function WidgetMenu({ widget, handleEditWidget, handleClone, onRemoveClick }) {
  return (
    <Popover>
      <PopoverTrigger onMouseDown={e => e.stopPropagation()} className='ml-auto'>
        <MenuDots
          strokeColor={
            widget?.themeColor === DEFAULT_VALUE
              ? widget.themeStyle === THEME_STYLE.DARK
                ? COLORS.WHITE_COLOR
                : COLORS.DARK_SECONDARY
              : widget.themeStyle === THEME_STYLE.DARK
                ? COLORS.WHITE_COLOR
                : COLORS.COLOR_PRIMARY
          }
        />
      </PopoverTrigger>
      <PopoverContent
        className='w-[8.75rem] cursor-pointer p-0 text-sm font-normal leading-5 text-errorColor'
        align='end'
      >
        <ul>
          <li
            className='flex items-center gap-2 px-3 py-[0.8125rem] text-sm font-normal leading-5'
            onMouseDown={() => handleEditWidget(widget)}
          >
            <Pencil width={16} height={16} />
            Edit
          </li>
          <PopoverClose>
            <li
              className='flex items-center gap-2 px-3 py-[0.8125rem] text-sm font-normal leading-5'
              onMouseDown={e => {
                e.stopPropagation();
                handleClone(widget?.i);
              }}
            >
              <CopyPlus width={16} height={16} />
              Clone
            </li>
          </PopoverClose>
          <li
            className='flex items-center gap-2 px-3 py-[0.8125rem] text-sm font-normal leading-5'
            onMouseDown={e => {
              e.stopPropagation();
              onRemoveClick(widget);
            }}
          >
            <Trash2 width={16} height={16} color='#EF4444' />
            Remove
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
}
