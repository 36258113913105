import { DialogContent, Button } from '@shadcnComponent';
import { Loader } from '@common/components';

export function DeleteDialog({ heading, text, deleteBtnClick, className, isLoading, textClass }) {
  return (
    <DialogContent className={`flex items-center justify-center rounded-lg ${className}`}>
      <div className='flex flex-col items-center'>
        <h3 className='text-center text-lg font-bold leading-[1.375rem] md:max-w-[27.375rem] md:text-2xl md:leading-[1.875rem] lg:text-lg lg:leading-7 xxl:text-2xl xxl:leading-[1.8156rem]'>
          Delete <span className='break-all'>{heading}</span>?
        </h3>
        <p
          className={`mb-6 mt-3 text-center text-sm font-medium leading-[1.0588rem] text-darkSecondary md:mb-[1.875rem] md:mt-4 md:leading-5 lg:mb-4 lg:mt-3 lg:text-xs xxl:mb-[1.875rem] xxl:mt-4 xxl:text-sm xxl:leading-5 ${textClass}`}
        >
          Are you sure, you want to delete {text} ?
        </p>
        <Button
          onClick={deleteBtnClick}
          className='h-10 w-[13.75rem] md:w-[11.25rem] lg:w-40 xxl:w-[11.25rem]'
          variant='destructive'
          disabled={isLoading}
        >
          Delete
          {isLoading ? <Loader /> : ''}
        </Button>
      </div>
    </DialogContent>
  );
}
