export function ResponsiveSidebarIcon() {
  return (
    <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 12.0184C0 11.2294 0.653698 10.5898 1.46008 10.5898H22.5399C23.3463 10.5898 24 11.2294 24 12.0184C24 12.8074 23.3463 13.447 22.5399 13.447H1.46008C0.653698 13.447 0 12.8074 0 12.0184Z'
        fill='#202427'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 5.15904C0 4.37006 0.653698 3.73047 1.46008 3.73047H22.5399C23.3463 3.73047 24 4.37006 24 5.15904C24 5.94802 23.3463 6.58761 22.5399 6.58761H1.46008C0.653698 6.58761 0 5.94802 0 5.15904Z'
        fill='#202427'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 18.8739C0 18.0849 0.653698 17.4453 1.46008 17.4453H22.5399C23.3463 17.4453 24 18.0849 24 18.8739C24 19.6629 23.3463 20.3025 22.5399 20.3025H1.46008C0.653698 20.3025 0 19.6629 0 18.8739Z'
        fill='#202427'
      />
    </svg>
  );
}
