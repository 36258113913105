/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { isEmpty } from 'lodash';
import { EmptyWidget, TableWidgetPagination } from '@common/widgets';
import { convertCamelCaseToReadable } from '@utils';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function TableWidget({
  widget,
  page,
  handleTablePagination,
  tableBodyClass,
  textClass,
  tableHeadClass,
  tableCellClass,
}) {
  return isEmpty(widget?.widgetData?.data?.results) ? (
    <EmptyWidget widget={widget} />
  ) : (
    <div className='relative z-[1]'>
      <Table className={cn('text-lg', textClass)}>
        <TableHeader
          className='sticky top-0 rounded-t-lg'
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? {
                  color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                  backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.THEME_COLOR,
                }
              : { backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : COLORS.THEME_COLOR }
          }
        >
          <TableRow
            style={
              widget?.themeColor === DEFAULT_VALUE
                ? { borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                : { borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground }
            }
          >
            {widget?.widgetData?.data?.fields?.map((header, i) => (
              <TableHead
                key={i}
                className={cn(
                  'px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-[3.375rem] xxl:pb-3 xxl:pt-3.5',
                  tableHeadClass
                )}
                style={
                  widget?.themeColor === DEFAULT_VALUE
                    ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                    : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
                }
              >
                {convertCamelCaseToReadable(header)}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className={cn('pt-5', tableBodyClass)}>
          {widget?.widgetData?.data?.results?.map((row, i) => (
            <TableRow
              key={i}
              style={
                widget?.themeColor === DEFAULT_VALUE
                  ? {
                      borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                    }
                  : { borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground }
              }
            >
              {Object.entries(row)?.map(([key, value], i) => (
                <TableCell
                  key={i}
                  className={cn(
                    'pointer-events-none px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-[3.375rem] xxl:pb-3 xxl:pt-3.5',
                    tableCellClass
                  )}
                  style={
                    widget?.themeColor === DEFAULT_VALUE
                      ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                      : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
                  }
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {widget?.widgetData?.data?.count > 1 && (
        <div className='my-6'>
          <TableWidgetPagination page={page} widget={widget} handleTablePagination={handleTablePagination} />
        </div>
      )}
    </div>
  );
}
