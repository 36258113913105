/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import {
  capitalizeSourceName,
  getLinkedinAccountIds,
  getLocalStorageItem,
  parseLinkedinListing,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@utils';
import {
  ACTIVE_CAMPAIGN_ACCOUNT_USERNAME_TEXT,
  ACTIVE_CAMPAIGN_API_KEY_TEXT,
  DATE_FORMAT,
  NON_AUTH_CONNECTORS,
  SUCCESSFUL_MESSAGE,
  UPPERCASE_DATA_SOURCES,
  ZOHO_CRM_EDITION_TEXT,
} from '@constants';

const nonAuthConnectors = {
  PIPE_DRIVE: {
    apiToken: '',
    apiTokenContent: {
      text: 'Obtain your pipe drive api key from this ',
      link: 'https://pipedrive.readme.io/docs/how-to-find-the-api-token',
    },
  },
  KLAVIYO: {
    apiKey: '',
    apiTokenContent: {
      text: 'Find your klaviyo API key from this ',
      link: 'https://help.klaviyo.com/hc/en-us/articles/115005062267#your-private-api-keys3',
    },
  },
  STRIPE: {
    accountId: '',
    secretKey: '',
    accountIdContent: {
      text: 'Your Stripe account id (starts with "acct_"), find yours from this',
      link: 'https://dashboard.stripe.com/settings/account',
    },
    secretKeyContent: {
      text: 'Stripe API key (usually starts with "sk_live_"); find yours',
      link: 'https://dashboard.stripe.com/apikeys',
    },
  },
  ZOHO_CRM: {
    edition: '',
    environment: '',
    editionContent: {
      text: ZOHO_CRM_EDITION_TEXT,
    },
    environmentContent: {
      text: 'Please choose the environment your account is using, default is Production.',
    },
  },
  ACTIVE_CAMPAIGN: {
    apiKey: '',
    accountUsername: '',
    accountUsernameContent: {
      text: ACTIVE_CAMPAIGN_ACCOUNT_USERNAME_TEXT,
    },
    apiKeyContent: {
      text: ACTIVE_CAMPAIGN_API_KEY_TEXT,
    },
  },
};

export function useAddDataSource() {
  const { organizationId, page: pageNumber } = useParams();
  const navigate = useNavigate();

  const showConnector = getLocalStorageItem('showConnector');
  const savePageNumber = getLocalStorageItem('dataSourceCurrentPage');

  const page = +pageNumber || savePageNumber || 1;
  const [search, setSearch] = useState('');
  const [showConnectDataSourceDialog, setShowConnectDataSourceDialog] = useState(showConnector || {});
  const [connectedDataSourceLoading, setConnectDataSourceLoading] = useState(false);
  const [checkedOauthId, setCheckedOauthId] = useState();
  const [checkedAccountId, setCheckedAccountId] = useState();
  const [showDateField, setShowDateField] = useState(false);
  const [showNameField, setShowNameField] = useState(false);
  const [isNonAuthConnector, setNonAuthConnector] = useState(false);
  const [date, setDate] = useState();
  const [showAccount, setShowAccount] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState();
  const [linkedInAccounts, setLinkedInAccounts] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [showProperties, setShowProperties] = useState();
  const [checkedPropertyId, setCheckedPropertyId] = useState();
  const [checkedProperty, setCheckedProperty] = useState();
  const [nameField, setNameField] = useState('');
  const [nonAuthFields, setNonAuthFields] = useState(nonAuthConnectors);
  const [showMultiChecks, setShowMultiChecks] = useState(false);
  const [showSelectField, setShowSelectField] = useState(false);
  const [oAuthListing, setOAuthListing] = useState({});
  const [clickUpSteps, setClickUpSteps] = useState({
    teamListing: false,
    spaceListing: false,
    folderListing: false,
    listing: false,
  });
  const [teamId, setTeamId] = useState(null);
  const [spaceId, setSpaceId] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [listId, setListId] = useState(null);
  const [connectNewAccountProcessing, setConnectNewAccountProcessing] = useState(false);

  let queryParams = {};

  if (search) queryParams = { search };
  else queryParams = { page };

  const { data: accountListing, refetch: accountListingRefetch } = useGetQuery(
    'account-listing',
    apiEndpoints.GOOGLE_ANALYTICS_ACCOUNT(checkedOauthId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: false, onSuccess: () => setShowAccount(true) }
  );

  const { data: linkedinAccountListing, refetch: linkedinAccountListingRefetch } = useGetQuery(
    'linkedinAccount-listing',
    apiEndpoints.LINKEDIN_ADS(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: resp => {
        setShowAccount(true);
        isEmpty(resp?.data?.elements) && handleConnectDataSource();
        !isEmpty(resp?.data) && setShowMultiChecks(true);
      },
    }
  );

  const { data: typeFormAccountListing, refetch: typeFormAccountListingRefetch } = useGetQuery(
    'typeFormAccount-listing',
    apiEndpoints.TYPEFORM_CONNECTION(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        setShowAccount(true);
        setShowMultiChecks(true);
      },
    }
  );

  const { data: accountPropertiesListing, refetch: accountPropertiesListingRefetch } = useGetQuery(
    'account-properties-listing',
    apiEndpoints.GOOGLE_ANALYTICS_ACCOUNT_PROPERTIES(checkedOauthId, checkedAccount?.name?.split('/')[1]),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: false, onSuccess: () => setShowProperties(true) }
  );

  const { data: fbAccountListing, refetch: fbAccountListingRefetch } = useGetQuery(
    'fb-account-listing',
    apiEndpoints.FACEBOOK_ACCOUNT(checkedOauthId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: false, onSuccess: () => setShowAccount(true) }
  );

  const {
    data: addDataSourceListing,
    refetch: addDataSourceListingRefetch,
    isFetching: addDataSourceLoading,
  } = useGetQuery('addDataSource-listing', apiEndpoints.ADD_DATA_SOURCE, queryParams);

  const { isFetching: oAuthListingLoading } = useGetQuery(
    ['oauth-listing'],
    apiEndpoints.DATA_SOURCE_OAUTH_LISTING,
    { sourceName: capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName) },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled:
        !NON_AUTH_CONNECTORS.includes(capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName)) &&
        !isEmpty(showConnectDataSourceDialog),
      onSuccess: resp => setOAuthListing(resp),
    }
  );

  const { data: clickUpTeamListing, refetch: clickUpTeamListingRefetch } = useGetQuery(
    'click-up-listing',
    apiEndpoints.CLICK_UP_TEAMS(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => setClickUpSteps({ ...clickUpSteps, teamListing: true }),
    }
  );

  const { data: clickUpSpacingListing, refetch: clickUpSpacingListingRefetch } = useGetQuery(
    'click-up-spacing-listing',
    apiEndpoints.CLICK_UP_SPACING(checkedOauthId, teamId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => setClickUpSteps({ ...clickUpSteps, spaceListing: true, teamListing: false }),
    }
  );

  const { data: clickUpFolderListing, refetch: clickUpFolderListingRefetch } = useGetQuery(
    'click-up-folder-listing',
    apiEndpoints.CLICK_UP_FOLDER(checkedOauthId, spaceId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => setClickUpSteps({ ...clickUpSteps, folderListing: true, spaceListing: false }),
    }
  );

  const { data: clickUpListing, refetch: clickUpListingRefetch } = useGetQuery(
    'listing',
    apiEndpoints.CLICK_UP_LIST(checkedOauthId, folderId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => setClickUpSteps({ ...clickUpSteps, listing: true, folderListing: false }),
    }
  );

  const handlePage = pageNum => {
    navigate(generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: pageNum }));
  };

  const handleNameChange = e => setNameField(e.target.value);

  const clearDialogue = () => {
    setShowConnectDataSourceDialog({});
    setShowDateField(false);
    setShowSelectField(false);
    setShowNameField(false);
    setNonAuthConnector(false);
    setDate();
    setShowAccount(false);
    setShowProperties(false);
    setCheckedOauthId(null);
    setCheckedAccountId(null);
    setLinkedInAccounts([]);
    setCheckedIds([]);
    setCheckedProperty(null);
    setShowMultiChecks(false);
    setNonAuthFields(nonAuthConnectors);
    setOAuthListing({});
    setConnectNewAccountProcessing(false);
    setNameField('');
  };

  const handleBackClick = () => {
    if (showProperties) {
      setShowProperties(false);
    } else {
      setShowDateField(false);
      setShowSelectField(false);
      setShowNameField(false);
      setNonAuthConnector(false);
      setLinkedInAccounts([]);
      setCheckedIds([]);
      setShowAccount(false);
      setShowMultiChecks(false);
      setConnectNewAccountProcessing(false);
    }
  };

  const handleNonAuthFields = (connector, fieldName, value) => {
    setNonAuthFields(prev => {
      return {
        ...prev,
        [connector]: { ...nonAuthFields[connector], [fieldName]: value },
      };
    });
  };

  const handleConnectDataSource = () => {
    const { sourceName, id } = showConnectDataSourceDialog?.dataSource || {};
    const commonPayload = { connectorName: capitalizeSourceName(sourceName), connectorId: id, oauth: checkedOauthId };

    let payload = {};

    switch (capitalizeSourceName(sourceName)) {
      case UPPERCASE_DATA_SOURCES.WOO_COMMERCE:
        if (showDateField) {
          payload = { ...commonPayload, startDate: moment(date).format(DATE_FORMAT.RANGE_DATE) };
        } else {
          setShowDateField(true);
          return;
        }
        break;

      case UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS:
        if (showAccount) {
          if (showProperties) {
            payload = { ...commonPayload, propertyId: checkedProperty?.name?.split('/')[1] };
          } else {
            accountPropertiesListingRefetch();
            return;
          }
        } else {
          accountListingRefetch();
          return;
        }
        break;

      case UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING:
        if (showAccount) {
          payload = { ...commonPayload, accountId: checkedAccount?.id };
        } else {
          fbAccountListingRefetch();
          return;
        }
        break;

      case UPPERCASE_DATA_SOURCES.LINKEDIN_ADS:
        if (showAccount) {
          payload = {
            ...commonPayload,
            propertyId: checkedProperty?.name?.split('/')[1],
            accountIds: isEmpty(linkedinAccountListing?.data?.elements) ? [] : getLinkedinAccountIds(linkedInAccounts),
          };
        } else {
          linkedinAccountListingRefetch();
          return;
        }
        break;

      case UPPERCASE_DATA_SOURCES.TYPEFORM:
        if (showAccount) {
          payload = {
            ...commonPayload,
            formIds: isEmpty(typeFormAccountListing?.data) ? [] : checkedIds,
          };
        } else {
          typeFormAccountListingRefetch();
          return;
        }
        break;

      case UPPERCASE_DATA_SOURCES.ZOHO_CRM:
        if (showSelectField) {
          payload = {
            ...commonPayload,
            edition: nonAuthFields?.ZOHO_CRM?.edition?.id,
            environment: nonAuthFields?.ZOHO_CRM?.environment?.id,
          };
        } else {
          setShowSelectField(true);
          return;
        }
        break;

      case UPPERCASE_DATA_SOURCES.CLICK_UP:
        if (!!teamId && clickUpSteps.teamListing) {
          clickUpSpacingListingRefetch();
          return;
        } else if (!!spaceId && clickUpSteps.spaceListing) {
          clickUpFolderListingRefetch();
          return;
        } else if (!!folderId && clickUpSteps.folderListing) {
          clickUpListingRefetch();
          return;
        } else if (!!listId && clickUpSteps.listing) {
          payload = { ...commonPayload, teamId: teamId, spaceId: spaceId, folderId: folderId, listId: listId };
          handleOauthConnection({ payload });
          setConnectDataSourceLoading(true);
          return;
        } else if (checkedOauthId) {
          clickUpTeamListingRefetch();
          return;
        }
        break;

      default:
        if (NON_AUTH_CONNECTORS.includes(capitalizeSourceName(sourceName))) {
          payload = { ...nonAuthFields[capitalizeSourceName(sourceName)], ...commonPayload };
        } else {
          payload = commonPayload;
        }
        break;
    }

    if (!isEmpty(payload)) {
      setConnectDataSourceLoading(true);
      handleOauthConnection({ payload });
    }
  };

  const handleConnectNewAccount = () => {
    setConnectNewAccountProcessing(true);
    let payload = {};

    if (
      capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.WOO_COMMERCE
    ) {
      showNameField
        ? (payload = {
            connectorName: capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName),
            storeName: nameField,
          })
        : setShowNameField(true);
    } else if (
      capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.BING_ADS
    ) {
      showNameField
        ? (payload = {
            connectorName: capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName),
            name: nameField,
          })
        : setShowNameField(true);
    } else if (
      NON_AUTH_CONNECTORS.includes(capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName))
    ) {
      setNonAuthConnector(true);
    } else {
      payload = {
        connectorName: capitalizeSourceName(showConnectDataSourceDialog?.dataSource?.sourceName),
      };
    }

    !isEmpty(payload) && connectNewAccount({ payload });
  };

  const { mutate: connectNewAccount } = usePostMutation(
    apiEndpoints.DATA_SOURCES_OAUTH,
    resp => {
      window.location.href = resp?.data?.url;
      setConnectDataSourceLoading(false);
      setLocalStorageItem('organizationId', organizationId);
      setLocalStorageItem('showConnector', showConnectDataSourceDialog);
      setLocalStorageItem('dataSourceCurrentPage', page);
    },
    ({ response: { data } }) => {
      setConnectDataSourceLoading(false);
      toast.error(data?.detail);
    }
  );

  const { mutate: handleOauthConnection } = usePostMutation(
    apiEndpoints.CONNECT_DATA_SOURCE(organizationId),
    () => {
      setShowConnectDataSourceDialog({});
      toast.success(SUCCESSFUL_MESSAGE('Data source connected'));
      navigate(generatePath(ROUTES.DATA_SOURCE, { organizationId }));
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
      setConnectDataSourceLoading(false);
    }
  );

  const handleMultipleChecked = item => {
    const { id } = item;
    setCheckedIds(prevIds => (prevIds.includes(id) ? prevIds.filter(item => item !== id) : [...prevIds, id]));
  };

  const handleLinkedInAccountsChecked = (item, listing) => {
    const accounts = listing.map(account => {
      if (account.name === item.name) {
        return { ...account, isChecked: !account.isChecked };
      }
      return account;
    });
    setLinkedInAccounts(accounts);
  };

  useEffect(() => {
    page && addDataSourceListingRefetch();
    removeLocalStorageItem('organizationId');
    removeLocalStorageItem('showConnector');

    return () => {
      removeLocalStorageItem('dataSourceCurrentPage');
    };
  }, [page, search]);

  useEffect(() => {
    if (!isEmpty(linkedinAccountListing)) {
      const accounts = parseLinkedinListing(linkedinAccountListing);
      setLinkedInAccounts(accounts);
    }
  }, [linkedinAccountListing]);

  return {
    addDataSourceListing,
    page,
    setPage: handlePage,
    setSearch,
    showConnectDataSourceDialog,
    setShowConnectDataSourceDialog,
    addDataSourceLoading,
    connectedDataSourceLoading,
    organizationId,
    handleConnectNewAccount,
    oAuthListing,
    setOAuthListing,
    oAuthListingLoading,
    handleConnectDataSource,
    checkedOauthId,
    setCheckedOauthId,
    setConnectDataSourceLoading,
    showDateField,
    showNameField,
    clearDialogue,
    handleBackClick,
    date,
    setDate,
    showAccount,
    accountListing,
    checkedAccountId,
    setCheckedAccountId,
    setCheckedAccount,
    showProperties,
    accountPropertiesListing,
    checkedPropertyId,
    setCheckedPropertyId,
    setCheckedProperty,
    fbAccountListing,
    nameField,
    handleNameChange,
    handleNonAuthFields,
    nonAuthFields,
    isNonAuthConnector,
    linkedInAccounts,
    handleLinkedInAccountsChecked,
    typeFormAccountListing,
    showMultiChecks,
    handleMultipleChecked,
    checkedIds,
    showSelectField,
    clickUpSteps,
    clickUpTeamListing,
    clickUpSpacingListing,
    clickUpFolderListing,
    clickUpListing,
    setClickUpSteps,
    teamId,
    setTeamId,
    spaceId,
    setSpaceId,
    folderId,
    setFolderId,
    listId,
    setListId,
    connectNewAccountProcessing,
  };
}
