import { useEffect } from 'react';
import { Button } from '@shadcnComponent';
import { FormInput, SignInTerms, AuthDescription, Loader } from '@common/components';
import { useGoogleSignIn, useExpressLogin } from '@pages/signIn';
import { removeLocalStorageItem } from '@utils';
import { ORGANIZATION_ID_BY_INVITE_ACCEPTANCE } from '@constants';
import { Logo, Google, InformationIcon } from '@assets/svgs';

export function SignIn() {
  const { handleGoogleLogin } = useGoogleSignIn();
  const { formik, isExpressLoading } = useExpressLogin();

  const { values, handleChange, touched, errors, submitForm, setFieldValue } = formik;

  useEffect(() => {
    return () => {
      removeLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
    };
  }, []);

  return (
    <div className='flex h-screen min-h-[42.5rem] overflow-y-auto'>
      <div className='relative flex-1'>
        <div className='absolute left-5 top-6 md:left-8 md:top-10 lg:left-7 lg:top-7 xxl:left-10 xxl:top-10'>
          <Logo />
        </div>
        <div className='h-available mx-auto flex max-w-[30rem] flex-col justify-center px-5 md:max-w-[25rem] md:px-0 lg:max-w-[15.5rem] xxl:max-w-[21.875rem]'>
          <h2>Sign In</h2>
          <p className='md:ledaing-[1.0588rem] mb-8 mt-3 text-sm font-normal leading-5 md:mb-10 md:mt-3 md:text-sm lg:mb-8 lg:text-xs lg:leading-[0.9075rem] xxl:mb-10 xxl:mt-4 xxl:text-sm xxl:leading-5'>
            Welcome! Please enter your email below and get started.
          </p>
          <div className='flex flex-col gap-4 md:gap-3 lg:gap-3 xxl:gap-6'>
            <FormInput
              id='email'
              labelText='Email'
              placeholder='Enter your email'
              type='email'
              value={values.email}
              handleChange={handleChange}
              showError={touched.email && errors.email}
            />
            <SignInTerms
              id='termsAndPrivacy'
              value={values.termsAndPrivacy}
              setFieldValue={setFieldValue}
              showError={touched.termsAndPrivacy && errors.termsAndPrivacy}
            />
            <div className='mt-4 flex flex-col gap-2 md:mt-5 md:gap-3 lg:mt-3 lg:gap-2 xxl:mt-0 xxl:gap-3'>
              <Button disabled={isExpressLoading} onClick={submitForm}>
                Get Started {isExpressLoading ? <Loader /> : ''}
              </Button>
              <div className='flex gap-2 md:gap-5 lg:gap-1.5'>
                <div>
                  <InformationIcon />
                </div>
                <p className='text-xs font-normal leading-5 md:text-sm md:leading-[1.375rem] lg:text-[0.625rem] lg:leading-4 xxl:text-xs xxl:leading-4'>
                  The email used for login cannot be used for signing in with Google and vice versa.
                </p>
              </div>
            </div>
            <div className='my-2 flex items-center gap-[0.9375rem] text-sm font-normal leading-[1.0588rem] text-darkSecondary md:text-[1.125rem] md:leading-[1.3613rem] lg:my-0 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-[1.0588rem]'>
              <div className='w-available h-px bg-lightPrimary'></div>
              OR
              <div className='w-available h-px bg-lightPrimary'></div>
            </div>
            <Button variant='outline' onClick={handleGoogleLogin}>
              <Google className='h-4 w-4 md:h-5 md:w-5 lg:h-[0.8013rem] lg:w-[0.8013rem] xxl:h-[1.125rem] xxl:w-[1.125rem]' />
              <span className='ml-2 md:ml-[0.8931rem] lg:ml-2'>Continue With Google</span>
            </Button>
          </div>
        </div>
      </div>
      <AuthDescription />
    </div>
  );
}
