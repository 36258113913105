import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, ChartContainer } from '@shadcnComponent';
import { Label, PolarGrid, PolarRadiusAxis, RadialBar, RadialBarChart } from 'recharts';

export function CircularProgressBar({ title, value, color }) {
  const [dimensions, setDimensions] = useState({
    innerRadius: 50,
    outerRadius: 60,
    polarRadius: [53, 48],
  });

  const chartData = [{ tracking: 1, fill: color }];

  const chartConfig = {
    tracking: {
      label: 'tracking',
    },
  };

  const updateRadii = () => {
    if (window.innerWidth >= 1440) {
      setDimensions({ innerRadius: 66, outerRadius: 76, polarRadius: [70, 63] });
    } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
      setDimensions({ innerRadius: 45, outerRadius: 55, polarRadius: [48, 43] });
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setDimensions({ innerRadius: 66, outerRadius: 76, polarRadius: [70, 63] });
    } else if (window.innerWidth < 768) {
      setDimensions({ innerRadius: 50, outerRadius: 60, polarRadius: [53, 48] });
    }
  };

  useEffect(() => {
    updateRadii(); // Set initial values
    window.addEventListener('resize', updateRadii);
    return () => window.removeEventListener('resize', updateRadii);
  }, []);

  return (
    <Card className='flex w-full flex-col p-5 md:py-4 lg:px-6 lg:pb-4 lg:pt-6 xxl:pb-[1.875rem]'>
      <CardHeader className='px-0 pb-0'>
        <CardTitle className='mb-4 text-base font-semibold leading-[1.1875rem] md:text-lg md:leading-[1.375rem] xxl:text-lg xxl:leading-[1.3125rem]'>
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className='flex-1 px-0 pb-0'>
        <ChartContainer
          config={chartConfig}
          className='mx-auto aspect-square max-h-[6.875rem] min-h-[6.875rem] md:max-h-[8.875rem] md:min-h-[8.875rem] lg:max-h-[6.25rem] lg:min-h-[6.25rem] xxl:max-h-[8.875rem] xxl:min-h-[8.875rem]'
        >
          <RadialBarChart
            data={chartData}
            startAngle={0}
            endAngle={value * -3.6}
            innerRadius={dimensions.innerRadius}
            outerRadius={dimensions.outerRadius}
          >
            <PolarGrid
              gridType='circle'
              radialLines={false}
              stroke='none'
              className='first:fill-muted last:fill-background'
              polarRadius={dimensions.polarRadius}
            />
            <RadialBar dataKey='tracking' background />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor='middle' dominantBaseline='middle'>
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className='fill-foreground text-[1.75rem] font-medium leading-[1.1875rem] md:text-4xl md:leading-6 lg:text-[1.625rem] lg:leading-[1.0625rem] xxl:text-4xl xxl:leading-6'
                        >
                          {value}%
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </PolarRadiusAxis>
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
