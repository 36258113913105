import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Header, Sidebar } from '@common/components';
import { ROUTES } from '@routes';
import { convertToTitleCase } from '@utils';

export function Layout() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('Dashboards');
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname) {
      let split = location.pathname.split('/');
      setActiveLink(convertToTitleCase(split[1]));
      location.pathname === '/' && navigate(ROUTES.HOME);
    }
  }, [location.pathname]);

  return (
    <div className='flex'>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Sidebar />
      </ErrorBoundary>
      <div className='flex-1'>
        <Header ActiveText={activeLink} />
        <div className='overflow-y-auto overflow-x-hidden  md:h-[calc(100vh-5.45rem)] lg:h-[calc(100vh-3.0625rem)] xxl:h-[calc(100vh-5.0625rem)]'>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
