import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePatchMutation } from '@services';

export function useDashboardFullView() {
  const { organizationId, dashboardId, selectSliderWidget } = useParams();

  const [page, setPage] = useState(1);

  const { data: dashboard, refetch: dashboardRefetch } = useGetQuery(
    'dashboard',
    apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId)
  );

  const { mutate: handleDashboardUpdate } = usePatchMutation(
    apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId),
    () => dashboardRefetch(),
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const handleTablePagination = (page, tableWidget) => {
    const dashboardConfig = dashboard?.data?.dashboardConfig;
    if (!dashboardConfig) return;

    const widgetIndex = dashboardConfig[selectSliderWidget].findIndex(widget => widget.i === tableWidget.i);

    if (widgetIndex === -1) return;

    const updatedWidgets = [...dashboardConfig];
    const updatedSlideWidgets = [...updatedWidgets[selectSliderWidget]];

    updatedSlideWidgets[widgetIndex] = {
      ...updatedSlideWidgets[widgetIndex],
      page: page + 1,
    };

    updatedWidgets[selectSliderWidget] = updatedSlideWidgets;

    setPage(page + 1);

    handleDashboardUpdate({
      payload: {
        viewType: 'SLIDES',
        dashboardConfig: updatedWidgets,
      },
    });
  };

  return { dashboard, selectSliderWidget, organizationId, dashboardId, page, handleTablePagination };
}
