import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@shadcnComponent';
export function CustomWidgetAccordion({ accordionTitle, titleImage, children }) {
  return (
    <Accordion type='single' collapsible>
      <AccordionItem value='item-1'>
        <AccordionTrigger>
          <div className='flex items-center gap-2'>
            {titleImage}
            <p className='md:text-sm md:leading-[1.0625rem] lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-[1.0625rem]'>
              {accordionTitle}
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
