import { Button } from '@shadcnComponent';
import { isEqual } from 'lodash';
import { FormInput, ImageCrop, ImageUpload, Loader } from '@common/components';
import { useProfileTab } from '@pages/profile';
import { getFile, handleSave } from '@utils';
import { EditIconWhite, ImageUploaderIcon } from '@assets/svgs';

export function ProfileTab() {
  const {
    formik,
    imageRef,
    uploadedImage,
    setIsUploadedImage,
    handleCancelClick,
    isUploadedImage,
    profileUpdateLoading,
    userDetailFetching,
    handleImageUpload,
  } = useProfileTab();

  const { initialValues, values, handleChange, touched, errors, submitForm, setFieldValue } = formik;

  const isActionDisabled =
    isEqual(initialValues, values) || isUploadedImage || profileUpdateLoading || userDetailFetching;

  return (
    <div className='flex justify-center overflow-y-auto border border-lightGray bg-white px-[1.1875rem] pt-10 shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] md:pt-20 lg:h-[calc(100vh-10.875rem)] lg:pt-[3.75rem] xxl:h-[calc(100vh-16.625rem)] xxl:pt-[6.25rem]'>
      <div className='flex w-full flex-col md:max-w-[24rem] lg:max-w-[17.125rem] xxl:max-w-[24rem]'>
        {!isUploadedImage && (
          <div className='mb-6 md:mb-[1.875rem] lg:mb-6 xxl:mb-[1.875rem]'>
            <ImageUpload
              id='profileImage'
              imageRef={imageRef}
              placeHolderIcon={<ImageUploaderIcon />}
              className='h-[6.25rem] w-[6.25rem] md:h-[7.625rem] md:w-[7.625rem] lg:h-20 lg:w-20 xxl:h-[7.625rem] xxl:w-[7.625rem]'
              profileImage={uploadedImage?.image || values?.profileImage}
              handleChange={e => getFile(e.target.files[0], 'profileImage', setFieldValue, setIsUploadedImage)}
              showError={touched.profileImage && errors.profileImage}
              errorMessage={errors.profileImage}
              icon={<EditIconWhite />}
              inputWrapperClassName='h-[2rem] w-[2rem] md:h-[1.875rem] md:w-[1.875rem] lg:h-5 lg:w-5 xxl:h-[1.875rem] xxl:w-[1.875rem]'
              editIconClassName='h-[0.875rem] w-[0.875rem] lg:w-2 lg:h-2'
              showSkeletonLoader={isUploadedImage}
            />
          </div>
        )}
        <div className='flex flex-col gap-4 md:gap-6 lg:gap-4 xxl:gap-6'>
          <FormInput
            id='firstName'
            labelText='First Name'
            placeholder='Enter your first name'
            value={values.firstName}
            handleChange={handleChange}
            showError={touched.firstName && errors.firstName}
          />
          <FormInput
            id='lastName'
            labelText='Last Name'
            placeholder='Enter your last name'
            value={values.lastName}
            handleChange={handleChange}
            showError={touched.lastName && errors.lastName}
          />
          <FormInput
            id='email'
            labelText='Email'
            placeholder='Enter your email'
            type='email'
            value={values.email}
            handleChange={handleChange}
            showError={touched.email && errors.email}
            disabled={true}
          />
          <div className='mb-20 mt-2 flex flex-col-reverse gap-4 md:mt-0 md:flex-row lg:mt-2 xxl:mt-0 xxl:gap-6'>
            <div className='md:w-1/2'>
              <Button variant='outline' onClick={handleCancelClick} disabled={isActionDisabled}>
                Cancel
              </Button>
            </div>
            <div className='md:w-1/2'>
              <Button onClick={submitForm} disabled={isActionDisabled}>
                Save {profileUpdateLoading ? <Loader /> : ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isUploadedImage && (
        <ImageCrop
          uploadedImage={values?.profileImage}
          handleCancel={() => {
            setIsUploadedImage(false);
            setFieldValue('profileImage', initialValues.profileImage);
          }}
          applyRef={false}
          handleCloseDialog={() => setFieldValue('profileImage', initialValues.profileImage)}
          setIsUploaded={setIsUploadedImage}
          handleSave={file => handleSave(file, handleImageUpload)}
          isDialog
        />
      )}
    </div>
  );
}
