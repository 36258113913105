import { Progress } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { EmptyWidget } from '@common/widgets';
import { COLORS, DATE_FORMAT, DEFAULT_VALUE, INVALID_DATE, THEME_STYLE } from '@constants';

export function ProgressWidget({ isComparisonFilterApply, widget, className, progressTitle, progressBar }) {
  return isEmpty(widget?.widgetData?.data) ? (
    <EmptyWidget widget={widget} />
  ) : (
    <>
      {widget?.widgetData?.data?.map((widgetData, index) => (
        <div key={index} className={cn('my-4 flex flex-col justify-between', className)}>
          <span
            className={cn('mb-2 text-sm font-semibold', progressTitle)}
            style={{ color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }}
          >
            {moment(widgetData?.label).format(DATE_FORMAT.BILLING_DATE) === INVALID_DATE
              ? widgetData?.label
              : moment(widgetData?.label).format(DATE_FORMAT.BILLING_DATE)}
          </span>
          <div className='flex w-full items-center'>
            <Progress
              value={widgetData?.data}
              className={cn('h-4 w-full', progressBar)}
              style={{
                backgroundColor:
                  widget?.themeColor === DEFAULT_VALUE
                    ? widget.themeStyle === THEME_STYLE.DARK
                      ? `${COLORS.WHITE_COLOR}80`
                      : COLORS.DARK_PRIMARY
                    : widget.themeStyle === THEME_STYLE.DARK
                      ? `${widget?.foreground}80`
                      : widget?.foreground,
              }}
            />
            <span
              className={cn('ml-4 text-sm font-semibold', progressTitle)}
              style={{ color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }}
            >
              {widgetData?.data}
            </span>
          </div>
          {isComparisonFilterApply && (
            <div className='mt-2 flex w-full items-center'>
              <Progress
                value={widgetData?.data2}
                className={cn('h-4 w-full', progressBar)}
                style={{
                  backgroundColor:
                    widget?.themeColor === DEFAULT_VALUE
                      ? widget.themeStyle === THEME_STYLE.DARK
                        ? `${COLORS.WHITE_COLOR}80`
                        : COLORS.DARK_PRIMARY
                      : widget.themeStyle === THEME_STYLE.DARK
                        ? `${widget?.foreground}80`
                        : `${widget?.foreground}70`,
                }}
              />
              <span
                className={cn('ml-4 text-sm font-semibold', progressTitle)}
                style={{ color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }}
              >
                {widgetData?.data2}
              </span>
            </div>
          )}
        </div>
      ))}
    </>
  );
}
