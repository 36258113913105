import {
  ScrollArea,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Dialog,
  DialogContent,
  Popover,
  PopoverTrigger,
  PopoverContent,
  ScrollBar,
} from '@shadcnComponent';
import { Edit2Icon, Trash2 } from 'lucide-react';
import moment from 'moment';
import { BaseToolTip, CustomPagination, DeleteDialog } from '@common/components';
import { CreateEditReport, useScheduledReport } from '@pages/reports';
import { NumbersIntoDays, handleTimeChange, truncateString } from '@utils';
import { COLORS, DATE_FORMAT, ONE_TIME, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function ScheduledReports() {
  const {
    reportsListing,
    showDeleteDialog,
    setShowDeleteDialog,
    dialogReport,
    setDialogReport,
    deleteReportLoading,
    setDeleteReportLoading,
    page,
    setPage,
    showEditDialog,
    setShowEditDialog,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    emailFieldErrorMessage,
    invalidEmails,
    selectDay,
    handleSelectedDay,
    handleDeleteReport,
    setEditReport,
    reportUpdateLoading,
    reportDetailFetching,
    isReadOnly,
    setIsReadOnly,
  } = useScheduledReport();

  return (
    <div>
      <ScrollArea className='relative z-[1] h-full w-[calc(100vw-2.5rem)] whitespace-nowrap rounded-md border md:w-[calc(100vw-4rem)] lg:h-[calc(100vh-12.875rem)] lg:w-auto xxl:h-[calc(100vh-17rem)]'>
        <Table>
          <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
            <TableRow>
              <TableHead className='pl-8 pr-[2.1875rem] font-medium md:pl-8 md:pr-[2.1875rem] lg:pl-5 lg:pr-[1.0625rem] xxl:pl-8 xxl:pr-[2.1875rem]'>
                Subject
              </TableHead>
              <TableHead className='px-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:px-[2.1875rem] lg:px-[1.0625rem] lg:text-xs lg:leading-[0.9075rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0588rem]'>
                Date
              </TableHead>
              <TableHead className='px-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:px-[2.1875rem] lg:px-[1.0625rem] lg:text-xs lg:leading-[0.9075rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0588rem] '>
                Time
              </TableHead>
              <TableHead className='px-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:px-[2.1875rem] lg:px-[1.0625rem] lg:text-xs lg:leading-[0.9075rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0588rem]'>
                Recipients
              </TableHead>
              <TableHead className='px-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:px-[2.1875rem] lg:px-[1.0625rem] lg:text-xs lg:leading-[0.9075rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0588rem]'>
                Repeat
              </TableHead>
              <TableHead className='pl-[2.1875rem] pr-8 text-sm font-medium leading-[1.0588rem] md:pl-[2.1875rem] md:pr-8 lg:pl-[1.0625rem] lg:pr-5 lg:text-xs lg:leading-[0.9075rem] xxl:pl-[2.1875rem] xxl:pr-8 xxl:text-sm xxl:leading-[1.0588rem]'>
                Actions
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className='!leading-[1.0588rem] md:!leading-[1.0588rem] lg:!leading-[1.045rem] xxl:!leading-[1.0625rem]'>
            {reportsListing?.data?.results?.map(report => (
              <TableRow
                key={report.id}
                onClick={() => {
                  setIsReadOnly(true);
                  setDialogReport(report);
                  setEditReport(report);
                  setShowEditDialog(true);
                }}
              >
                <TableCell className=' pl-8 pr-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:pl-[2.1875rem] md:pr-8  lg:pl-[1.0625rem] lg:pr-5 lg:text-xs lg:leading-[1.045rem] xxl:pl-[2.1875rem] xxl:pr-8 xxl:text-sm xxl:leading-[1.0588rem]'>
                  <p id={`scheduled-${report?.id}`}>{truncateString(report?.subject, 15, 'text-darkSecondary')}</p>
                  {report?.subject?.length > 15 && (
                    <BaseToolTip className='w-full' id={`scheduled-${report?.id}`} content={report?.subject} />
                  )}
                </TableCell>
                <TableCell className=' px-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:px-[2.1875rem]  lg:px-[1.0625rem] lg:text-xs lg:leading-[1.045rem] xxl:max-h-[3.625rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0588rem]'>
                  {moment(report.scheduledDate).format(DATE_FORMAT.REPORT_LISTING_FORMAT)}
                </TableCell>
                <TableCell className=' px-[2.1875rem] text-sm font-medium  md:px-[2.1875rem]  lg:px-[1.0625rem] lg:text-xs lg:leading-[1.045rem]  xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0625rem]'>
                  {report.scheduledTime}
                </TableCell>
                <TableCell className='px-[2.1875rem] text-sm font-medium leading-[1.0588rem] md:px-[2.1875rem]  lg:px-[1.0625rem] lg:text-xs lg:leading-[1.045rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0625rem]'>
                  {report.recipients.split(',')[0]}
                  {report?.recipients.split(',')?.length > 1 && (
                    <Popover>
                      <PopoverTrigger className=' ml-1 h-5 w-5 rounded-full bg-darkPrimary p-1 text-[0.5625rem] font-medium leading-[0.6806rem] text-white lg:h-3.5 lg:w-3.5 lg:text-[0.375rem] lg:leading-[0.4375rem] xxl:h-5 xxl:w-5 xxl:text-[0.5625rem] xxl:leading-[0.6806rem]'>
                        +{report.recipients.split(',').length - 1}
                      </PopoverTrigger>
                      <PopoverContent align='end' className='max-h-[9.75rem] w-min overflow-y-auto px-[0.9375rem] py-3'>
                        <ul className='flex flex-col gap-3'>
                          {report?.recipients
                            .split(',')
                            .slice(1)
                            ?.map(recipient => (
                              <li key={recipient} className='text-sm font-normal leading-5'>
                                {recipient}
                              </li>
                            ))}
                        </ul>
                      </PopoverContent>
                    </Popover>
                  )}
                </TableCell>
                <TableCell className=' px-[2.1875rem] text-sm font-medium leading-[1.0588rem]  md:px-[2.1875rem] lg:px-[1.0625rem] lg:text-xs  lg:leading-[1.045rem] xxl:px-[2.1875rem] xxl:text-sm xxl:leading-[1.0625rem]'>
                  {report?.scheduledType === ONE_TIME
                    ? 'One Time'
                    : `Every ${report?.scheduledFrequency} ${report?.scheduledType?.toLowerCase().replace('ly', '(s)')} on ${NumbersIntoDays(report.scheduledDay)}`}
                </TableCell>
                <TableCell className='flex  gap-1.5 pl-[2.1875rem] pr-8 text-sm font-medium leading-[1.0588rem] md:pl-[2.1875rem] md:pr-8  lg:pl-[1.0625rem] lg:pr-5 lg:text-xs lg:leading-[1.045rem]  xxl:pl-[2.1875rem] xxl:pr-8 xxl:text-sm xxl:leading-[1.0588rem]'>
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      setDialogReport(report);
                      setDeleteReportLoading(false);
                      setShowDeleteDialog(true);
                    }}
                  >
                    <Trash2 color='#EF4444' className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                  </span>
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      setIsReadOnly(false);
                      setDialogReport(report);
                      setEditReport(report);
                      (handleTimeChange(report?.scheduledDate, report?.scheduledTime)?.isFuture ||
                        report?.scheduledType !== ONE_TIME) &&
                        setShowEditDialog(true);
                    }}
                  >
                    <Edit2Icon
                      className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6'
                      color={
                        handleTimeChange(report?.scheduledDate, report?.scheduledTime)?.isFuture ||
                        report?.scheduledType !== ONE_TIME
                          ? COLORS.DARK_SECONDARY
                          : COLORS.TINT_GRAY
                      }
                    />
                  </span>
                  <Dialog
                    open={showEditDialog[report.id]}
                    onOpenChange={isOpen => {
                      setShowEditDialog({ ...showEditDialog, [report.id]: isOpen });
                      formik.resetForm();
                    }}
                  >
                    <span
                      onClick={() => {
                        setEditReport(report);
                        setShowEditDialog({ ...showEditDialog, [report.id]: true });
                      }}
                    >
                      <Edit2Icon className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                    </span>
                    <DialogContent className='max-h-[85vh] max-w-[calc(100vw-2.5rem)] overflow-y-auto px-5 py-6 md:max-w-[41.25rem] md:px-[1.875rem] md:py-[2.125rem] lg:max-w-[39rem] lg:p-8 xxl:max-w-[47.1875rem] xxl:p-[1.875rem]'>
                      <CreateEditReport
                        label='Edit Report'
                        formik={formik}
                        handleInvitedEmailKeyDown={handleInvitedEmailKeyDown}
                        invitedEmails={invitedEmails}
                        handleRemoveInvitedEmail={handleRemoveInvitedEmail}
                        emailFieldErrorMessage={emailFieldErrorMessage}
                        invalidEmails={invalidEmails}
                        selectDay={selectDay}
                        handleSelectedDay={handleSelectedDay}
                        scheduleLoading={reportUpdateLoading}
                        isFetching={reportDetailFetching}
                        scheduleClass='lg:w-[17.0625rem]'
                      />
                    </DialogContent>
                  </Dialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <Dialog
        open={showDeleteDialog}
        onOpenChange={isOpen => {
          setDeleteReportLoading(false);
          setShowDeleteDialog(isOpen);
        }}
      >
        <DeleteDialog
          heading={dialogReport?.subject}
          text={dialogReport?.subject}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          deleteBtnClick={() => {
            setDeleteReportLoading(true);
            handleDeleteReport();
          }}
          isLoading={deleteReportLoading}
        />
      </Dialog>
      <Dialog
        open={showEditDialog}
        onOpenChange={isOpen => {
          setShowEditDialog(isOpen);
          formik.resetForm();
        }}
      >
        <DialogContent className='max-h-[85vh] max-w-[calc(100vw-2.5rem)] overflow-y-auto px-5 py-6 md:max-w-[41.25rem] md:px-[1.875rem] md:py-[2.125rem] lg:max-w-[39rem] lg:p-8 xxl:max-w-[47.1875rem] xxl:p-[1.875rem]'>
          <CreateEditReport
            label='Edit Report'
            isReadOnly={isReadOnly}
            formik={formik}
            handleInvitedEmailKeyDown={handleInvitedEmailKeyDown}
            invitedEmails={invitedEmails}
            handleRemoveInvitedEmail={handleRemoveInvitedEmail}
            emailFieldErrorMessage={emailFieldErrorMessage}
            invalidEmails={invalidEmails}
            selectDay={selectDay}
            handleSelectedDay={handleSelectedDay}
            scheduleLoading={reportUpdateLoading}
            isFetching={reportDetailFetching}
          />
        </DialogContent>
      </Dialog>
      <div className='mb-6 md:mb-0'>
        {reportsListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={page} setPage={setPage} data={reportsListing} />
        )}
      </div>
    </div>
  );
}
