import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from '@routes';
import { useSentry, useSetupAxios } from '@services';
import { AppContext } from '@useContext';
import { getLocalStorageItem } from '@utils';

export function App() {
  useSetupAxios();
  useSentry();

  const dashboardCurrentPage = getLocalStorageItem('dashboardCurrentPage');

  // eslint-disable-next-line no-unused-vars
  const [permissions, setPermissions] = useState('');
  const [isResponsive, setIsResponsive] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);
  const [dataSourcePage, setDataSourcePage] = useState(1);
  const [dashboardPage, setDashboardPage] = useState(dashboardCurrentPage || 1);

  const windowSizeHandler = () => setIsResponsive(window.innerWidth < 1024);
  const mobileSizeHandler = () => setIsMobileScreen(window.innerWidth < 767);
  const screenSizeHandler = () => setScreenHeight(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', windowSizeHandler);
    window.addEventListener('resize', mobileSizeHandler);
    window.addEventListener('resize', screenSizeHandler);

    windowSizeHandler();
    mobileSizeHandler();
    screenSizeHandler();
    return () => {
      window.removeEventListener('resize', windowSizeHandler);
      window.removeEventListener('resize', mobileSizeHandler);
      window.removeEventListener('resize', screenSizeHandler);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        permissions,
        isResponsive,
        isMobileScreen,
        dataSourcePage,
        setDataSourcePage,
        dashboardPage,
        setDashboardPage,
        screenHeight,
      }}
    >
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}
