export function ArrowLeft({ className, strokeColor = '#1C1C23' }) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M13 3L6 10L13 17' stroke={strokeColor} strokeWidth='2' />
    </svg>
  );
}
