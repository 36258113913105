export function SendMessageIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3521 10.5208L18.6357 15.6701C17.4255 19.3008 16.8204 21.1161 15.933 21.6319C15.0889 22.1227 14.0463 22.1227 13.2022 21.6319C12.3148 21.1161 11.7097 19.3008 10.4995 15.6701C10.3052 15.0872 10.208 14.7957 10.0449 14.5521C9.88687 14.316 9.68404 14.1131 9.44793 13.9551C9.2043 13.792 8.91282 13.6948 8.32987 13.5005C4.69923 12.2903 2.88392 11.6852 2.36806 10.7978C1.87731 9.95369 1.87731 8.91112 2.36806 8.06698C2.88392 7.17964 4.69924 6.57453 8.32987 5.36432L13.4792 3.64788C17.9776 2.14842 20.2268 1.39869 21.414 2.58595C22.6013 3.77322 21.8516 6.02242 20.3521 10.5208ZM13.0457 10.9022C12.7544 10.6077 12.7571 10.1328 13.0516 9.84153L17.2621 5.67742C17.5566 5.38615 18.0315 5.38878 18.3227 5.6833C18.614 5.97781 18.6114 6.45268 18.3169 6.74394L14.1063 10.9081C13.8118 11.1993 13.337 11.1967 13.0457 10.9022Z'
        fill='url(#paint0_linear_4097_32701)'
      />
      <defs>
        <linearGradient id='paint0_linear_4097_32701' x1='12' y1='2' x2='12' y2='22' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#00ACE7' />
          <stop offset='1' stopColor='#8AC33D' />
        </linearGradient>
      </defs>
    </svg>
  );
}
