import { cn } from '@shadcnUtils';
import { BaseSkeltonLoader } from '@common/components';
import { SKELETON_TYPE } from '@constants';

export function ImageUpload({
  id,
  icon,
  imageRef,
  placeHolderIcon,
  className,
  profileImage,
  handleChange,
  showError,
  errorMessage,
  inputWrapperClassName,
  editIconClassName,
  showSkeletonLoader,
}) {
  return (
    <div className='flex flex-col items-center gap-3'>
      <div
        className={`relative mx-auto flex items-center justify-center rounded-full border bg-lightGray ${className} ${showError ? 'border-errorColor' : ''}`}
      >
        {showSkeletonLoader ? (
          <BaseSkeltonLoader type={SKELETON_TYPE.IMAGE_UPLOADER} />
        ) : (
          <>
            {profileImage ? (
              <div className='h-full w-full'>
                <img src={profileImage} alt='profile-image' className='h-full w-full rounded-full object-contain' />
              </div>
            ) : (
              placeHolderIcon
            )}
          </>
        )}
        <div
          className={cn(
            'absolute bottom-0 right-0 flex h-[2rem] w-[2rem] items-center justify-center rounded-full border-2 border-white bg-darkPrimary md:h-10 md:w-10 lg:h-5 lg:w-5 xxl:h-[1.875rem] xxl:w-[1.875rem]',
            inputWrapperClassName
          )}
        >
          <input
            type='file'
            ref={imageRef}
            id={id}
            accept='.jpg, .jpeg, .png'
            onChange={handleChange}
            className='absolute z-10 h-8 w-8 opacity-0 md:h-10 md:w-10 lg:h-5 lg:w-5 xxl:h-[1.875rem] xxl:w-[1.875rem]'
          />
          <div className={`${editIconClassName}`}>{icon}</div>
        </div>
      </div>
      {showError && <p className='text-xs font-normal leading-[0.9075rem] text-errorColor'>{errorMessage}</p>}
    </div>
  );
}
