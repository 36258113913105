import { useRef } from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@shadcnComponent';
import { BaseToolTip } from '@common/components';
import { truncateString } from '@utils';
import { EmptyTemplate } from '@assets/svgs';

export function TemplateListingTabContent({
  templateListing,
  handleScroll,
  handleSelectedTemplate,
  selectedTemplateId,
  setCreateDashboardStep1,
}) {
  const scrollContainerRef = useRef();

  return (
    <div className='h-[12.625rem] w-full overflow-hidden md:h-[27.25rem] md:w-[20.6875rem] lg:h-[17.8125rem] lg:w-[17rem] xxl:h-[23.875rem] xxl:w-[26.6875rem]'>
      <div className='h-available'>
        <div
          ref={scrollContainerRef}
          onScroll={handleScroll}
          className='relative z-[1] h-full overflow-y-auto rounded-md border'
        >
          <Table className='text-lg'>
            <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
              <TableRow>
                <TableHead className='w-[9.625rem] px-5 pb-1.5 pt-2.5 text-xs leading-[.875rem] text-darkSecondary md:w-[6.5rem] md:px-6 md:py-3 md:pr-3 md:text-sm md:leading-[1.0625rem] lg:px-3 lg:py-2 lg:text-[.625rem] lg:leading-3 xxl:w-[10.25rem] xxl:px-6 xxl:py-3 xxl:pr-3 xxl:text-sm'>
                  Name
                </TableHead>
                <TableHead className='px-5 pb-1.5 pt-2.5 text-xs leading-[.875rem] text-darkSecondary md:px-6 md:py-[.6875rem] md:text-sm md:leading-[1.0625rem] lg:px-3 lg:py-2 lg:text-[.625rem] lg:leading-3 xxl:px-6 xxl:py-3 xxl:pr-3 xxl:text-sm'>
                  Category
                </TableHead>
                <TableHead className='whitespace-pre px-5 pb-1.5 pt-2.5 text-xs leading-[.875rem] text-darkSecondary md:px-6 md:py-[.6875rem] md:text-sm md:leading-[1.0625rem] lg:px-3 lg:py-2 lg:text-[.625rem] lg:leading-3 xxl:px-6 xxl:py-3 xxl:pr-3 xxl:text-sm'>
                  Data Sources
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {templateListing?.pages?.map(page =>
                page?.data?.count === 0 ? (
                  <div
                    key={page}
                    className='absolute left-[50%] flex w-[21.625rem] translate-x-[-50%] flex-col items-center justify-center md:h-[24.5rem] md:w-[20.5rem] lg:h-[15.8125rem] lg:w-[16.875rem] xxl:h-[24.25rem] xxl:w-[26.4375rem]'
                  >
                    <EmptyTemplate />
                    <h3 className='mb-2 mt-4 text-sm font-semibold leading-[1.0588rem]'>No Templates Found</h3>
                    <p className='text-xs font-light leading-[1.125rem]'>You don&apos;t have any templates yet</p>
                  </div>
                ) : (
                  page?.data?.results?.map(template => (
                    <TableRow
                      key={template.id}
                      className={`hover-row h-10 hover:bg-darkPrimary lg:h-8 xxl:h-[2.6875rem] ${template?.id === selectedTemplateId ? 'bg-darkPrimary' : ''}`}
                      onClick={() => {
                        handleSelectedTemplate(template?.id);
                        setCreateDashboardStep1(prev => {
                          return {
                            ...prev,
                            template: template?.id,
                          };
                        });
                      }}
                    >
                      <TableCell className='px-5 py-2.5 font-medium leading-4 hover:text-white md:px-6 md:py-3 md:pr-3 lg:px-3 lg:py-2 xxl:px-6 xxl:py-3'>
                        <p
                          className={`text-xs leading-[.9375rem] md:text-sm lg:text-[.625rem] lg:leading-3 xxl:text-sm ${template?.id === selectedTemplateId ? 'text-white' : ''}`}
                          id={`templateName-${template.id}`}
                        >
                          {truncateString(template?.name, 24, 'text-darkSecondary text-inherit')}
                        </p>
                        {template?.name?.length > 24 && (
                          <BaseToolTip id={`templateName-${template.id}`} content={template?.name} />
                        )}
                      </TableCell>
                      <TableCell className='px-5 py-2.5 font-medium leading-4 hover:text-white md:px-6 md:py-3 md:pr-3 lg:px-3 lg:py-2 xxl:px-6 xxl:py-3'>
                        <p
                          className={`text-xs leading-[.9375rem] md:text-sm lg:text-[.625rem] lg:leading-3 xxl:text-sm ${template?.id === selectedTemplateId ? 'text-white' : ''}`}
                          id={`category-${template.id}`}
                        >
                          {truncateString(template?.categoryName, 24, 'text-darkSecondary text-inherit')}
                        </p>
                        {template?.categoryName?.length > 24 && (
                          <BaseToolTip
                            className='w-full'
                            id={`category-${template.id}`}
                            content={template?.categoryName}
                          />
                        )}
                      </TableCell>
                      <TableCell className='px-5 py-2.5 md:px-6 md:py-3 lg:px-3 lg:py-2 xxl:px-6 xxl:py-3'>
                        <div className='flex gap-2 md:gap-3'>
                          <Popover>
                            {template?.widgets?.slice(0, 5).map((widget, i) => (
                              <PopoverTrigger key={i} onClick={e => e.stopPropagation()}>
                                <div className='flex gap-2 md:gap-3.5'>
                                  <div className='h-5 w-5 lg:h-3.5 lg:w-3.5 xxl:h-5 xxl:w-5'>
                                    <img src={widget?.connectors?.logo?.image} alt='widget' />
                                  </div>
                                </div>
                              </PopoverTrigger>
                            ))}
                            <PopoverContent
                              align='start'
                              className='max-h-[9.75rem] w-[12.75rem] overflow-y-auto px-[0.9375rem] py-3'
                            >
                              <ul className='flex flex-col gap-3'>
                                {template.widgets.map((widget, i) => (
                                  <li
                                    onClick={e => e.stopPropagation()}
                                    key={i}
                                    className='flex items-center gap-2 text-sm font-normal leading-5'
                                  >
                                    <div className='flex h-6 w-6 items-center justify-center rounded-full'>
                                      <img src={widget?.connectors?.logo?.image} alt='widget' />
                                    </div>
                                    <span className='max-w-[8.875rem] overflow-hidden whitespace-nowrap'>
                                      {truncateString(widget?.connectors?.sourceName, 15, 'text-darkSecondary')}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </PopoverContent>
                          </Popover>
                          <Popover>
                            <PopoverTrigger onClick={e => e.stopPropagation()}>
                              {template?.widgets?.length > 5 && (
                                <div className='flex h-5 w-5 items-center rounded-full border bg-white px-0.5 text-[.5rem] font-bold leading-[.625rem] lg:h-3.5 lg:w-3.5 lg:text-[.375rem] xxl:h-5 xxl:w-5 xxl:text-[.5rem]'>
                                  +{template?.widgets?.length - 5}
                                </div>
                              )}
                            </PopoverTrigger>
                            <PopoverContent
                              align='start'
                              className='max-h-[9.75rem] w-[12.75rem] overflow-y-auto px-[0.9375rem] py-3'
                            >
                              <ul className='flex flex-col gap-3'>
                                {template.widgets.map((widget, i) => (
                                  <li
                                    onClick={e => e.stopPropagation()}
                                    key={i}
                                    className='flex items-center gap-2 text-sm font-normal leading-5'
                                  >
                                    <div className='flex h-6 w-6 items-center justify-center rounded-full'>
                                      <img src={widget?.connectors?.logo?.image} alt='widget' />
                                    </div>
                                    <span className='max-w-[8.875rem] overflow-hidden whitespace-nowrap'>
                                      {truncateString(widget?.connectors?.sourceName, 15, 'text-darkSecondary')}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
