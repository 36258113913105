import { ScrollArea, ScrollBar, Switch, Button, Dialog, DialogContent } from '@shadcnComponent';
import { useMembershipTab } from '../hooks/useMembershipTab';
import { BaseLoader, Loader, TourButton } from '@common/components';
import { useAppTours } from '@utils';
import { INTEGRATION_FEATURES, CUSTOMIZATION_FEATURE, CURRENCY } from '@constants';

export function MembershipTab() {
  const {
    isChecked,
    handleSwitchToggle,
    membershipPlan,
    userDetail,
    handleMembershipCheckout,
    handleMembershipUpgrade,
    handleCancelMembership,
    showCancelSubscriptionDialog,
    setShowCancelSubscriptionDialog,
    isCancelMembershipLoading,
    membershipCheckoutLoading,
    membershipUpgradeLoading,
    calculateTrialDays,
    membershipFetching,
  } = useMembershipTab();

  const { createOrgTour } = useAppTours();

  return (
    <div className='overflow-y-scroll border border-lightGray bg-white px-5 py-5 shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] md:px-6 md:py-10 lg:h-[calc(100vh-138px)] lg:px-5 xxl:h-[calc(100vh-202px)] xxl:px-[1.1875rem] xxl:pb-5 xxl:pt-10'>
      <div className='flex flex-col items-center '>
        <h3 className='lg:text-base lg:leading-[1.21rem] xxl:text-2xl xxl:leading-6'>Pricing Plan</h3>
        <p className='my-3 text-center text-sm font-normal leading-6 text-darkSecondary md:mb-6 md:text-xs md:leading-[1.3369rem] lg:mb-4 lg:mt-2 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:mb-6 xxl:mt-3 xxl:text-xs xxl:leading-[1.3369rem]'>
          Unlock Your Potential: Choose the Perfect Plan for You!
        </p>
        <div className='mx-auto flex items-center justify-center gap-2 text-sm font-medium leading-[0.875rem] lg:text-[0.625rem] lg:leading-[0.7562rem] xxl:text-sm xxl:leading-[0.875rem]'>
          <span>Monthly</span>
          <Switch checked={isChecked} onClick={handleSwitchToggle} />
          <span>Yearly</span>
        </div>
      </div>
      <div className='relative h-full pt-6 md:pt-[1.875rem] lg:pt-[1.625rem] xxl:pt-2.5'>
        {membershipFetching ? (
          <BaseLoader />
        ) : (
          <div className='w-full'>
            <ScrollArea className='max-w-[calc(100vw-5rem)] rounded-lg border-b border-paleBlueGray md:max-w-full'>
              <div className='flex'>
                <div className='mt-3.5 w-[13.9375rem] rounded-l-lg border-l border-r border-t border-paleBlueGray border-x-paleBlueGray lg:mt-2.5 lg:w-[9.75rem] xxl:mt-3.5 xxl:w-[13.9375rem]'>
                  <div className='h-[12.125rem] border-b border-paleBlueGray px-4 pb-4 pt-6 lg:h-[8.75rem] lg:px-3 lg:py-4 xxl:h-[12.125rem] xxl:px-4 xxl:pb-4 xxl:pt-6'>
                    <h5 className='mb-3 text-base font-semibold leading-[1.21rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-base xxl:leading-[1.21rem]'>
                      Features List
                    </h5>
                    <p className='max-w-[10.8125rem] text-xs font-light leading-5 lg:max-w-[7.6875rem] lg:text-[0.5625rem] lg:leading-[0.8888rem] xxl:max-w-[10.8125rem] xxl:text-xs xxl:leading-5'>
                      Review the features provided in each pricing plan to determine the best fit for your needs.
                      Compare below to find your ideal plan.
                    </p>
                  </div>
                  <ul className='space-y-4 border-b border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    <li>AI Tokens</li>
                    <li>Organizations</li>
                    <li>Users</li>
                    <li>Dashboards</li>
                    <li>Multi-integration Dashboards</li>
                    <li>Preset Templates</li>
                    <li>Drag and Drop Builder</li>
                  </ul>
                  <div className='flex h-6 items-center bg-slateBlue pl-4 text-xs font-normal leading-[0.9075rems] text-white lg:h-5 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:h-6 xxl:text-xs xxl:leading-[0.9075rem]'>
                    Integrations
                  </div>
                  <ul className='space-y-4 border-b border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    {INTEGRATION_FEATURES.map((data, index) => (
                      <li key={index}>{data.name}</li>
                    ))}
                  </ul>
                  <div className='flex h-6 items-center bg-slateBlue pl-4 text-xs font-normal leading-[0.9075rems] text-white lg:h-5 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:h-6 xxl:text-xs xxl:leading-[0.9075rem]'>
                    White Label & Customization
                  </div>
                  <ul className='space-y-4 p-4 text-xs font-normal leading-[0.9075rem] lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    {CUSTOMIZATION_FEATURE.map((data, index) => (
                      <li key={index}>{data.name}</li>
                    ))}
                  </ul>
                </div>
                <div className='flex flex-grow'>
                  {membershipPlan?.data?.results.map(membership => (
                    <div
                      key={membership?.id}
                      className='mt-3.5 w-full min-w-[13.5rem] border-t border-paleBlueGray lg:mt-2.5 lg:min-w-[9.375rem] xxl:mt-3.5 xxl:min-w-[13.5rem]'
                    >
                      <div className='custom-border flex'>
                        <div
                          className={`${membership?.isActiveMembership ? 'active-subscription' : 'border-b'} relative flex h-[12.125rem] w-full flex-col justify-between border-x-paleBlueGray p-4 px-4 pb-4 pt-6 lg:h-[8.75rem] xxl:h-[12.125rem]`}
                        >
                          <div className='flex flex-col gap-3 lg:gap-1.5 xxl:gap-3'>
                            <span className='text-base font-semibold leading-[1.1875rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-base xxl:leading-[1.1875rem]'>
                              {membership?.nickname}
                            </span>
                            <div className='flex h-[1.9375rem] items-center gap-[0.3125rem] lg:mb-0.5 lg:h-[0.9375rem] xxl:mb-0 xxl:h-[1.9375rem]'>
                              <h5 className='text-[1.625rem] font-bold leading-[1.9837rem] lg:text-lg lg:leading-[1.3613rem] xxl:text-[1.625rem] xxl:leading-[1.9837rem]'>
                                {membership?.currency === CURRENCY?.USD ? '$' : ''}
                                {membership?.actualAmount}
                              </h5>
                              <span className='whitespace-nowrap text-xs font-semibold leading-[0.9075rem] text-slateGray lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:text-xs xxl:leading-[0.9075rem]'>
                                per month
                              </span>
                            </div>
                            {JSON.parse(membership?.metadata?.key).save && (
                              <div
                                className={`flex h-8 items-center justify-center rounded-[3.125rem] lg:h-[1.375rem]  lg:w-[7.5rem] lg:rounded-[2.5rem] xxl:h-8 xxl:w-[11.4375rem] xxl:rounded-[3.125rem] ${membership?.isActiveMembership ? 'border border-white' : 'border-gradient'}`}
                              >
                                <div
                                  className={`flex h-[1.875rem] w-[11.3125rem] items-center justify-center gap-[0.3125rem] rounded-[3.125rem] bg-white py-[0.125rem] text-sm font-normal leading-[1.0588rem] lg:h-5 lg:w-[7.375rem] lg:gap-[0.1875rem] lg:rounded-[2.5rem] lg:text-[9px] lg:leading-[0.7531rem] xxl:h-[1.875rem] xxl:w-[11.3125rem] xxl:gap-[0.3125rem] xxl:rounded-[3.125rem] xxl:text-sm xxl:leading-[1.0588rem] ${membership?.isActiveMembership && '!bg-darkPrimary text-white'}`}
                                >
                                  Save{' '}
                                  <span className='text-sm font-semibold leading-[1.0588rem] lg:text-[0.5625rem] lg:leading-[0.7562rem] xxl:text-sm xxl:leading-[1.0588rem]'>
                                    ${JSON.parse(membership?.metadata?.key).save} / yearly
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <Dialog
                            open={showCancelSubscriptionDialog[membership?.id]}
                            onOpenChange={isOpen =>
                              setShowCancelSubscriptionDialog({
                                ...showCancelSubscriptionDialog,
                                [membership.id]: isOpen,
                              })
                            }
                          >
                            <Button
                              className='flex h-8 items-center gap-1 text-xs leading-[0.9075rem] md:h-8 md:text-xs md:leading-[0.9075rem] lg:h-[1.4375rem] lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:h-8 xxl:text-xs xxl:leading-[0.9075rem]'
                              onClick={() => {
                                membership?.isActiveMembership
                                  ? setShowCancelSubscriptionDialog({
                                    ...showCancelSubscriptionDialog,
                                    [membership.id]: true,
                                  })
                                  : userDetail?.data?.isSubscribed
                                    ? handleMembershipUpgrade({ payload: { plan: membership?.id } })
                                    : handleMembershipCheckout({ payload: { plan: membership?.id } });
                              }}
                              disabled={membershipCheckoutLoading || membershipUpgradeLoading}
                            >
                              {userDetail?.data?.isSubscribed
                                ? membership?.isActiveMembership
                                  ? 'Cancel'
                                  : 'Purchase'
                                : 'Get Started'}
                            </Button>
                            <DialogContent className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[15.625rem] md:max-w-[500px] md:p-10 lg:min-h-[12.75rem] lg:max-w-[26.625rem] xxl:min-h-[20rem] xxl:max-w-[37.0625rem]'>
                              <div className='flex min-h-[10.25rem] flex-col items-center justify-center md:min-h-[10.625rem] lg:min-h-[7.75rem] xxl:min-h-[15rem]'>
                                <h3 className='text-center'>Cancel Subscription</h3>
                                <p className='my-4 text-center text-sm font-medium leading-5 text-darkSecondary'>
                                  Are you sure you want to cancel your Subscription?
                                </p>
                                <Button
                                  className='w-[11.25rem]'
                                  variant='destructive'
                                  onClick={() => handleCancelMembership({ payload: '' })}
                                  disabled={isCancelMembershipLoading}
                                >
                                  Delete {isCancelMembershipLoading ? <Loader /> : ''}
                                </Button>
                              </div>
                            </DialogContent>
                          </Dialog>
                          {membership?.nickname === 'Individual Plan' &&
                            membership?.interval === 'month' &&
                            membership?.trialEndDate !== null && (
                            <div className='absolute -top-3.5 left-[1.8125rem] flex h-7 w-40 items-center justify-center gap-[0.3125rem] rounded-[3.75rem] bg-lightGray text-xs font-medium leading-[0.9075rem] lg:-top-2.5 lg:left-[1.125rem] lg:h-5 lg:w-[7.125rem] lg:gap-[0.1875rem] lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:-top-[3.5] xxl:left-[1.8125rem] xxl:h-7 xxl:w-40 xxl:gap-[0.3125rem] xxl:text-xs xxl:leading-[0.9075rem]'>
                                Trial ends in
                              <span className='font-semibold !text-errorColor'>
                                {calculateTrialDays(membership?.trialEndDate)} days
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='custom-border flex'>
                        <ul
                          className={`${membership?.isActiveMembership ? 'active-subscription' : 'border-b'} flex w-full flex-col items-center gap-y-4 border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:gap-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:gap-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]`}
                        >
                          <li>{JSON.parse(membership?.metadata?.key).total_tokens}</li>
                          <li>{JSON.parse(membership?.metadata?.key).organizationCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).usersCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).dashboardCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).multiDashboardCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).presetTemplateCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).dragDropBuilderCount}</li>
                        </ul>
                      </div>
                      <div
                        className={`h-6 bg-slateBlue lg:h-5 xxl:h-6 ${membership?.isActiveMembership ? '!bg-darkPrimary' : '!bg-slateBlue'}`}
                      ></div>
                      <div className='custom-border flex'>
                        <ul
                          className={`${membership?.isActiveMembership ? 'active-subscription' : 'border-b'} flex w-full flex-col items-center gap-y-4 border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:gap-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:gap-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]`}
                        >
                          <li>{JSON.parse(membership?.metadata?.key).integrationCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).dataSourceCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).SEMrush}</li>
                          <li>{JSON.parse(membership?.metadata?.key).Ahrefs}</li>
                        </ul>
                      </div>
                      <div
                        className={`h-6 bg-slateBlue lg:h-5 xxl:h-6 ${membership?.isActiveMembership ? '!bg-darkPrimary' : '!bg-slateBlue'}`}
                      ></div>
                      <div className='flex'>
                        <ul
                          className={`${membership?.isActiveMembership ? 'active-subscription' : 'border-b'} flex w-full flex-col items-center gap-y-4 border-r border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:gap-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:gap-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]`}
                        >
                          <li>{JSON.parse(membership?.metadata?.key).customLogo}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
                {/* TODO: We will integrate it with the backend */}
                <div className='mt-3.5 w-[13.9375rem] rounded-r-lg border-r border-t border-paleBlueGray lg:mt-2.5 lg:w-[9.375rem] xxl:mt-3.5 xxl:w-[13.4375rem]'>
                  <div
                    className={
                      'relative flex h-[12.125rem] w-[13.875rem] flex-col justify-between border-b border-paleBlueGray p-4 px-4 pb-4 pt-6 lg:h-[8.75rem] lg:w-[9.375rem] xxl:h-[12.125rem] xxl:w-[13.4375rem]'
                    }
                  >
                    <div className='flex flex-col gap-3 lg:gap-1.5 xxl:gap-3'>
                      <span className='text-base font-semibold leading-[1.1875rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-base xxl:leading-[1.1875rem]'>
                        Enterprise Plan
                      </span>
                      <div className='flex h-[1.9375rem] items-center gap-[0.3125rem] lg:mb-0.5 lg:h-[0.9375rem] lg:gap-[0.1875rem] xxl:mb-0 xxl:h-[1.9375rem] xxl:gap-[0.3125rem]'>
                        <h5 className='text-[1.625rem] font-bold leading-[1.9837rem] lg:text-[1.3613rem] lg:text-lg xxl:text-[1.625rem] xxl:leading-[1.9837rem]'>
                          Custom
                        </h5>
                      </div>
                      <p className='text-xs font-normal leading-[1.0625rem] lg:text-[0.5625rem] lg:leading-3 xxl:text-xs xxl:leading-[1.0625rem]'>
                        Create custom plan according to your needs
                      </p>
                    </div>
                    <Dialog>
                      <a
                        className='flex h-8 items-center justify-center gap-1 bg-darkPrimary px-[1rem] py-[0.5rem] text-xs leading-[0.9075rem] text-white hover:bg-slateGray focus:bg-darkPrimary/80 md:h-8 md:rounded-lg md:text-xs md:leading-[0.9075rem] lg:h-[1.4375rem] lg:rounded lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:h-8 xxl:rounded-md xxl:text-xs xxl:leading-[0.9075rem]'
                        href='mailto:sales@zendash.com'
                      >
                        Contact to sale
                      </a>
                      <DialogContent className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[15.625rem] md:max-w-[500px] md:p-10 lg:min-h-[12.75rem] lg:max-w-[26.625rem] xxl:min-h-[20rem] xxl:max-w-[37.0625rem]'>
                        <div className='flex min-h-[10.25rem] flex-col items-center justify-center md:min-h-[10.625rem] lg:min-h-[7.75rem] xxl:min-h-[15rem]'>
                          <h3 className='text-center'>Cancel Subscription</h3>
                          <p className='my-4 text-center text-sm font-medium leading-5 text-darkSecondary'>
                            Are you sure you want to cancel your Subscription?
                          </p>
                          <Button className='w-[11.25rem]' variant='destructive'>
                            Delete
                          </Button>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                  <ul className='flex flex-col  items-center gap-y-4 border-b border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:gap-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:gap-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    <li>Unlimited</li>
                    <li>50+</li>
                    <li>Unlimited</li>
                    <li>Unlimited</li>
                    <li>Yes</li>
                    <li>Yes</li>
                    <li>Yes</li>
                  </ul>
                  <div className='h-6 bg-slateBlue lg:h-5 xxl:h-6'></div>
                  <ul className='flex w-full flex-col items-center gap-y-4 border-b border-paleBlueGray p-4 text-xs font-normal leading-[0.9075rem] lg:gap-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:gap-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    <li>50+</li>
                    <li>Unlimited</li>
                    <li>Unlimited</li>
                    <li>Yes</li>
                  </ul>
                  <div className='h-6 bg-slateBlue lg:h-5 xxl:h-6'></div>
                  <div className=' flex'>
                    <ul className='flex w-full flex-col items-center gap-y-4 p-4 text-xs font-normal leading-[0.9075rem] lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                      <li>Yes</li>
                    </ul>
                  </div>
                </div>
              </div>
              <ScrollBar orientation='horizontal' />
            </ScrollArea>
          </div>
        )}
      </div>
      {userDetail?.data?.isSubscribed && (
        <TourButton
          className='hidden lg:flex'
          Label='“Create Organization”'
          onClick={() => {
            createOrgTour('org-switcher').drive();
          }}
        />
      )}
    </div>
  );
}
