export function EmptyInvitation({ className }) {
  return (
    <svg
      width='191'
      height='140'
      viewBox='0 0 191 140'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_3847_28128)'>
        <path
          d='M103.356 113.194C146.272 113.194 181.063 88.3941 181.063 57.8021C181.063 27.2101 146.272 2.4104 103.356 2.4104C60.4392 2.4104 25.6484 27.2101 25.6484 57.8021C25.6484 88.3941 60.4392 113.194 103.356 113.194Z'
          fill='#F5F5FF'
        />
        <path
          d='M35.4733 138.912C55.0646 138.912 70.9465 136.468 70.9465 133.453C70.9465 130.438 55.0646 127.994 35.4733 127.994C15.8819 127.994 0 130.438 0 133.453C0 136.468 15.8819 138.912 35.4733 138.912Z'
          fill='#DFDFEB'
        />
        <path
          d='M93.4528 140.003C128.846 140.003 157.538 137.559 157.538 134.545C157.538 131.53 128.846 129.086 93.4528 129.086C58.0593 129.086 29.3672 131.53 29.3672 134.545C29.3672 137.559 58.0593 140.003 93.4528 140.003Z'
          fill='#DFDFEB'
        />
        <path
          d='M76.0205 -9.25369e-05C73.5014 0.0706267 71.0587 0.880273 68.9961 2.32817C66.9335 3.77606 65.342 5.79832 64.4195 8.14348C63.497 10.4886 63.2842 13.0532 63.8076 15.5184C64.331 17.9835 65.5675 20.2403 67.3633 22.0083C67.5477 22.1896 67.6858 22.4125 67.766 22.6583C67.8462 22.904 67.8661 23.1655 67.8241 23.4206L66.8726 29.0462L72.7286 25.6341C73.0618 25.4391 73.4544 25.3715 73.8337 25.4438C75.6098 25.8053 77.4422 25.7871 79.2108 25.3904C80.9793 24.9936 82.644 24.2273 84.0955 23.1418C85.547 22.0563 86.7525 20.676 87.6329 19.0917C88.5133 17.5074 89.0486 15.7548 89.2039 13.9489C89.3591 12.1431 89.1306 10.3248 88.5334 8.61347C87.9363 6.90215 86.984 5.33646 85.739 4.01918C84.494 2.7019 82.9845 1.66281 81.3096 0.970115C79.6347 0.27742 77.8322 -0.0532166 76.0205 -9.25369e-05Z'
          fill='#D3DDF7'
        />
        <path
          d='M75.7883 17.3041L75.7516 14.2325C76.1732 14.1312 76.5837 13.988 76.9769 13.8052C77.3254 13.6417 77.6528 13.4366 77.9518 13.1942C78.2213 12.9722 78.4575 12.7124 78.6529 12.423C78.838 12.1386 78.9819 11.8295 79.0802 11.5048C79.1779 11.1674 79.2262 10.8177 79.2238 10.4665C79.2253 9.9512 79.0942 9.44418 78.8432 8.99415C78.5865 8.5299 78.2094 8.14351 77.7514 7.8757C77.237 7.58083 76.6515 7.433 76.0587 7.44835C75.3693 7.44874 74.6951 7.65067 74.119 8.02928C73.445 8.51358 72.9581 9.21499 72.7401 10.0158L72.4062 9.79877C72.6347 8.984 73.1266 8.26784 73.8051 7.76219C74.4543 7.28947 75.2391 7.03995 76.042 7.05105C76.6983 7.03794 77.3469 7.19433 77.9251 7.50511C78.4539 7.79621 78.8933 8.22619 79.1957 8.74866C79.4981 9.27113 79.6521 9.86626 79.6411 10.4698C79.6451 10.9362 79.5624 11.3992 79.3974 11.8354C79.2342 12.2588 79.0005 12.6516 78.7063 12.9972C78.3977 13.3597 78.0324 13.6698 77.6246 13.9153C77.178 14.1849 76.6927 14.3842 76.1856 14.5063L76.1522 17.3074L75.7883 17.3041ZM75.568 19.5944C75.5633 19.4534 75.6082 19.3152 75.6948 19.2038C75.7409 19.156 75.7962 19.118 75.8572 19.0921C75.9183 19.0661 75.984 19.0528 76.0504 19.0528C76.1168 19.0528 76.1825 19.0661 76.2435 19.0921C76.3046 19.118 76.3599 19.156 76.406 19.2038C76.4852 19.3206 76.5239 19.4601 76.5161 19.6011C76.5188 19.7387 76.4766 19.8735 76.396 19.985C76.3549 20.0393 76.3012 20.0826 76.2395 20.1111C76.1777 20.1396 76.1099 20.1525 76.042 20.1486C75.9757 20.1534 75.9092 20.1426 75.8477 20.1171C75.7863 20.0915 75.7316 20.0521 75.6882 20.0017C75.603 19.8838 75.5628 19.7394 75.5746 19.5944H75.568Z'
          fill='black'
        />
        <path
          d='M38.8227 125.771L39.397 130.071C39.397 130.071 50.0807 134.625 49.0958 134.688C48.1109 134.752 32.7363 133.763 32.7363 133.763L33.6411 125.961L38.8227 125.771Z'
          fill='black'
        />
        <path
          d='M35.8447 55.6085C35.8447 55.6085 34.9933 63.8083 34.3557 64.2323C33.8923 64.5179 33.3894 64.7339 32.8633 64.8733L34.2488 55.5017L35.8447 55.6085Z'
          stroke='#424242'
          strokeMiterlimit='10'
        />
        <path
          d='M18.9643 49.9729C18.9643 49.9729 14.6241 59.7552 15.8894 65.2072L39.9078 61.5347L39.3969 51.3785L18.9643 49.9729Z'
          fill='black'
        />
        <path
          d='M39.9072 61.5447C39.9072 61.5447 40.0441 85.0188 40.211 93.1986C40.378 101.378 41.8069 126.639 41.8069 126.639C41.8069 126.639 37.3231 129.109 32.649 127.113C32.2784 126.952 28.252 61.3411 28.252 61.3411L39.9072 61.5447Z'
          fill='black'
        />
        <path
          d='M16.2866 62.3027L6.15039 126.869C6.15039 126.869 10.5775 131.016 16.1664 127.24L26.4728 74.4989L31.4808 71.1402L32.2287 61.5215L16.2866 62.3027Z'
          fill='black'
        />
        <path
          d='M13.6252 125.253L14.5466 128.963C14.5466 128.963 21.2907 137.072 20.2958 137.072C19.3009 137.072 8.22656 132.064 8.22656 132.064L9.64883 124.342L13.6252 125.253Z'
          fill='black'
        />
        <path
          d='M19.9893 53.9326C19.9893 53.9326 13.5991 80.7955 8.85156 128.204'
          stroke='#424242'
          strokeMiterlimit='10'
        />
        <path
          d='M24.3722 56.8206C24.3722 56.8206 22.1353 62.4128 18.3125 62.4963'
          stroke='#424242'
          strokeMiterlimit='10'
        />
        <path d='M33.8135 127.5L30.5449 71.9346L33.74 68.5859' stroke='#424242' strokeMiterlimit='10' />
        <path
          d='M33.771 21.8614L36.6456 21.2771L47.0122 26.9528L47.0656 26.9829C50.461 28.8559 51.9634 32.0376 51.8732 36.4947L52.3774 42.9483L46.7618 38.3911L42.0108 47.549L39.0061 58.3596C22.9037 64.1922 16.6504 53.6855 16.6504 53.6855L21.9922 42.1437L24.139 31.4834C25.1606 26.0147 28.3223 22.9765 33.771 21.8614Z'
          fill='#00ACE7'
        />
        <path
          d='M120.793 40.0971C120.793 40.0971 119.167 46.8913 109.979 55.1778C109.979 55.1778 109.101 53.9392 111.064 49.1682C113.027 44.3973 120.406 38.3944 120.793 40.0971Z'
          fill='#8AC33D'
        />
        <path
          d='M120.793 40.0972C120.793 40.0972 112.677 45.1486 109.979 55.1779C109.979 55.1779 109.358 58.6367 110.36 61.735'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M99.5797 44.3072C99.5797 44.3072 101.863 42.7981 98.3812 38.4378C95.0759 34.2978 91.5336 30.5452 84.252 28.7891C84.252 28.7891 85.8345 38.9019 90.4819 41.5861C93.2587 43.195 96.3755 44.1272 99.5797 44.3072Z'
          fill='#8AC33D'
        />
        <path
          d='M81.2405 50.4937C81.2405 50.4937 88.8159 52.6639 102.121 49.1583C102.121 49.1583 101.399 47.7594 95.6068 46.9347C89.8142 46.1101 79.7949 49.2785 81.2405 50.4937Z'
          fill='#8AC33D'
        />
        <path
          d='M81.2402 50.4937C81.2402 50.4937 90.7688 46.2069 102.12 49.1583C102.12 49.1583 103.282 49.5288 104.384 51.2149'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M87.1593 57.8622C87.1593 57.8622 95.0319 63.8017 108.34 60.2894C107.53 58.7972 106.381 57.5157 104.986 56.548C103.591 55.5803 101.988 54.9532 100.307 54.7172C94.5144 53.8892 85.717 56.6469 87.1593 57.8622Z'
          fill='#8AC33D'
        />
        <path
          d='M87.1582 57.8621C87.1582 57.8621 96.2828 54.2964 108.339 60.2893C109.354 60.8904 110.215 61.7204 110.853 62.7132'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M116.67 33.2996C116.67 33.2996 119.117 33.1661 112.843 38.2642C106.924 43.0752 107.632 43.162 107.632 43.162C107.632 43.162 110.249 35.5599 116.67 33.2996Z'
          fill='black'
        />
        <path
          d='M103.398 45.5193C103.398 45.5193 105.165 46.3339 108.623 43.5461C112.082 40.7584 113.732 34.1512 116.67 33.2998C116.67 33.2998 109.311 35.1695 103.398 45.5193Z'
          fill='#8AC33D'
        />
        <path
          d='M113.809 69.2436C110.122 60.3717 105.342 51.9948 99.5797 44.3072C99.5797 44.3072 98.0139 41.9701 92.4484 35.7769C86.8828 29.5837 84.252 28.7891 84.252 28.7891'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M115.798 33.6035C115.798 33.6035 108.744 36.1576 103.399 45.5192C102.103 47.7895 103.355 49.7292 104.384 51.1949'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M187.192 121.838C187.192 121.838 180.355 123.891 168.943 116.496C168.943 116.496 169.691 114.509 174.853 114.189C180.014 113.868 188.565 120.242 187.192 121.838Z'
          fill='#8AC33D'
        />
        <path
          d='M186.939 121.337C186.939 121.337 179.424 115.424 169.077 116.299C169.077 116.299 165.612 116.893 163.041 118.886'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M172.308 106.593C172.308 106.593 174.501 108.226 177.419 103.472C180.187 98.9511 182.511 94.3437 181.686 86.9019C181.686 86.9019 172.712 91.8297 171.77 97.1115C171.202 100.27 171.386 103.519 172.308 106.593Z'
          fill='#8AC33D'
        />
        <path
          d='M189.947 115.09C189.947 115.09 191.382 117.073 183.707 114.556C176.455 112.182 176.766 112.823 176.766 112.823C176.766 112.823 184.575 110.91 189.947 115.09Z'
          fill='black'
        />
        <path
          d='M172.492 110.536C172.492 110.536 172.763 112.463 176.979 113.875C181.196 115.287 187.643 113.087 189.947 115.1C189.947 115.09 184.398 109.912 172.492 110.536Z'
          fill='#8AC33D'
        />
        <path
          d='M153.699 128.458C160.789 121.974 167.041 114.628 172.309 106.593C172.309 106.593 173.978 104.323 177.904 96.9846C181.831 89.6462 181.687 86.9019 181.687 86.9019'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M189.222 114.516C189.222 114.516 183.259 109.965 172.492 110.533C169.881 110.673 168.699 111.942 166.746 114.379'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M116.073 53.7691C118.165 53.769 120.191 53.0354 121.798 51.6958L126.255 47.9732C127.778 46.6662 129.714 45.939 131.721 45.9199H153.846C154.324 45.9195 154.797 46.0132 155.238 46.1958C155.679 46.3784 156.08 46.6461 156.418 46.9838C156.756 47.3215 157.024 47.7225 157.206 48.1638C157.389 48.6052 157.482 49.0781 157.482 49.5557V129.664C157.482 130.487 157.32 131.303 157.004 132.064C156.689 132.825 156.227 133.517 155.644 134.099C155.062 134.682 154.37 135.144 153.609 135.459C152.848 135.775 152.032 135.937 151.209 135.937H40.9026C39.6086 135.937 38.3676 135.423 37.4523 134.508C36.537 133.594 36.0224 132.353 36.0215 131.059V58.6502C36.0224 57.356 36.5369 56.1149 37.4521 55.1997C38.3673 54.2845 39.6083 53.77 40.9026 53.7691H116.073Z'
          fill='#00475F'
        />
        <path
          d='M36.0249 131.466C36.0249 131.466 43.5302 117.384 44.2814 115.775L64.7975 73.9779C65.0501 73.4336 65.453 72.9729 65.9588 72.6499C66.4645 72.327 67.052 72.1552 67.6521 72.155H143.897C146.668 72.155 150.675 70.7027 152.805 68.9265L155.879 66.3591C157.402 65.0512 159.338 64.3228 161.345 64.3025H182.402C182.918 64.3035 183.426 64.4313 183.881 64.6747C184.336 64.9181 184.724 65.2697 185.011 65.6984C185.299 66.1271 185.476 66.6199 185.528 67.1333C185.58 67.6468 185.505 68.1651 185.31 68.6427L159.842 130.234C159.144 131.922 157.961 133.365 156.443 134.38C154.924 135.395 153.139 135.937 151.312 135.937H37.3938C37.0248 135.932 36.6715 135.787 36.406 135.531C36.1404 135.274 35.9827 134.926 35.9648 134.558L36.0249 131.466Z'
          fill='#00ACE7'
        />
        <path d='M67.0344 76.1248L36.9297 134.645' stroke='black' strokeLinejoin='round' />
        <path
          d='M79.4394 86.2074H105.371C105.717 86.2086 106.059 86.1231 106.364 85.9586C106.669 85.7942 106.928 85.5561 107.117 85.2659C107.315 84.956 107.425 84.5983 107.436 84.2309C107.447 83.8634 107.358 83.4999 107.179 83.1788C107 82.8578 106.737 82.5911 106.419 82.4072C106.101 82.2233 105.738 82.129 105.371 82.1342H79.4394C79.0927 82.1337 78.7513 82.2199 78.4464 82.3849C78.1414 82.5499 77.8826 82.7885 77.6933 83.0791C77.4953 83.3887 77.3849 83.7462 77.374 84.1136C77.363 84.481 77.4518 84.8445 77.6309 85.1654C77.81 85.4863 78.0728 85.7527 78.3913 85.9362C78.7097 86.1197 79.0719 86.2134 79.4394 86.2074Z'
          stroke='black'
          strokeLinejoin='round'
        />
        <path d='M127.947 106.323L107.758 118.352' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M111.441 105.742L124.128 118.425' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M121.158 123.305C129.238 121.07 134.42 114.308 132.731 108.202C131.042 102.096 123.123 98.9571 115.042 101.192C106.961 103.427 101.779 110.189 103.468 116.295C105.157 122.402 113.077 125.54 121.158 123.305Z'
          stroke='black'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M41.1752 49.1516L44.9178 53.3583C44.9178 53.3583 41.4056 57.5016 37.8365 55.0477L37.2656 54.2264L41.1752 49.1516Z'
          fill='#00ACE7'
        />
        <path
          d='M32.4746 24.0649C32.4746 24.0649 37.6028 25.4004 33.5664 40.2374'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M41.4903 49.5924C38.7192 46.4807 35.3338 42.5812 33.334 39.8735'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M37.838 55.0478C35.1671 52.3201 31.588 48.4439 30.0222 47.005C27.5783 44.7647 25.0409 41.159 25.8689 36.5283L25.7687 32.8157'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M53.7958 53.0579C50.5206 51.2884 49.0149 49.0982 47.7495 47.4756C44.7447 43.6028 43.673 43.1354 44.5544 38.6182L44.6045 33.9006L51.1382 40.9719C53.6322 44.2805 59.3847 50.2133 61.5081 52.5504C61.5081 52.5504 59.0141 55.8757 53.7958 53.0579Z'
          fill='#00ACE7'
        />
        <path
          d='M40.4713 51.8626C40.7722 51.7805 41.0857 51.7545 41.3961 51.7858C42.5145 51.906 45.5193 52.2199 45.8565 52.2265C46.2738 52.2265 46.6144 51.6089 47.5258 53.8658C48.4373 56.1228 48.2603 56.3331 48.2537 56.6302C48.247 56.9274 47.5859 61.261 46.9182 60.6099C46.2505 59.9589 46.3139 59.5783 46.3139 59.5783C46.3139 59.5783 45.94 61.3411 45.7397 61.2743C45.5393 61.2075 44.8082 61.2009 44.925 59.9589C44.925 59.9589 44.5277 61.1675 44.1137 60.867C43.6997 60.5665 43.7799 59.2611 43.7799 59.2611C43.7799 59.2611 43.3392 60.2427 42.835 59.595C42.3309 58.9473 42.7082 57.4248 42.7082 57.4248L42.2841 56.2964L41.3727 55.9024C41.3727 55.9024 38.2944 56.707 37.7669 54.567C37.3396 52.921 39.4663 52.1364 40.4713 51.8626Z'
          fill='#DB9C7F'
        />
        <path
          d='M46.6585 54.6304L47.2361 57.0576L46.2812 59.5783'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M45.175 54.5403L45.9228 57.0276L44.8945 59.9589'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M43.874 54.8174L44.3113 57.2112L43.8105 59.0007'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M41.3625 8.70386C40.7195 9.29072 40.3234 10.0998 40.254 10.9675C40.0311 10.6944 39.7428 10.482 39.416 10.3498'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M56.9544 51.1683C57.9969 50.9304 59.0901 51.0737 60.036 51.5723C61.2847 52.2133 63.2378 53.2049 63.4948 53.2817C63.8721 53.3952 64.1192 53.1915 64.1626 55.4885C64.2074 56.0889 64.1862 56.6924 64.0991 57.2881C64.019 57.5552 62.1828 60.2929 61.5785 59.9123L61.5384 59.8822C61.3742 60.1117 61.1862 60.3232 60.9775 60.5132C60.8273 60.6401 59.9759 60.6234 60.4199 59.5116C60.4199 59.5116 59.839 60.7837 59.385 60.6134C58.7973 60.393 58.8942 59.8288 59.1078 59.348C58.8341 59.7186 58.4969 60.0158 58.2565 59.6986C57.7824 59.0709 58.8541 56.8407 58.8541 56.8407L58.9776 55.5052L58.1597 54.7741C58.1597 54.7741 55.2417 54.8442 55.3118 52.7709C55.3519 51.8093 56.1264 51.3686 56.9544 51.1683Z'
          fill='#DB9C7F'
        />
        <path
          d='M63.3583 54.647L63.3216 57.1109L61.5254 59.7251'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M62.0818 54.6672L61.9415 57.2046L60.4258 59.5216'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M60.7862 54.7339L60.6059 56.8706L59.3105 59.0174'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M57.0905 54.163C57.0905 54.163 57.3843 56.3666 56.6732 56.8507C55.962 57.3348 54.8135 52.7775 55.6015 52.7174C56.7566 52.6339 57.0905 54.163 57.0905 54.163Z'
          fill='#DB9C7F'
        />
        <path
          d='M58.0624 53.8425C57.8362 53.896 57.6302 54.0136 57.4692 54.1812C57.3081 54.3488 57.1988 54.5592 57.1543 54.7874'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M44.334 43.1788L47.7728 38.6983C50.9178 34.5384 48.8211 30.1414 48.8211 30.1414'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M52.3765 42.9317C52.3765 42.9317 50.35 40.1239 49.8926 41.3659'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path d='M53.1624 19.5579L52.5781 19.4343' stroke='white' strokeMiterlimit='10' />
        <path
          d='M40.9683 19.0303L36.6447 21.2939C36.6447 21.2939 36.5346 26.4187 41.3856 30.3083L43.5624 28.1916L45.7593 30.3817C45.7593 30.3817 46.5939 29.714 46.8443 26.7493L46.4504 23.1368L40.9683 19.0303Z'
          fill='white'
        />
        <path
          d='M46.4507 23.1336L40.9653 19.0137L36.6484 21.2773'
          stroke='black'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M39.7305 19.7916C39.7305 19.7916 41.0659 27.6041 43.0424 28.1382C45.0189 28.6724 46.2542 24.7996 46.2542 24.7996L42.4014 14.1426L39.7305 19.7916Z'
          fill='#DB9C7F'
        />
        <path
          d='M46.972 25.7443L46.9954 26.7125C47.0154 26.7125 46.9553 27.013 45.9938 27.1231C41.7637 26.9996 41.4298 22.2387 41.0859 18.4426C41.0859 18.4493 47.3626 24.9864 46.972 25.7443Z'
          fill='black'
        />
        <path
          d='M52.8918 15.1239C52.8918 15.1239 53.9802 25.0397 48.1742 26.6323C42.3683 28.2248 40.9727 18.7731 41.163 15.4979C41.3533 12.2226 42.9993 10.6034 47.0691 10.4565C53.1021 10.2395 52.8918 15.1239 52.8918 15.1239Z'
          fill='#DB9C7F'
        />
        <path
          d='M44.1977 12.443C44.1977 12.443 44.0909 14.523 42.7087 14.937L41.7071 17.9985C41.7071 17.9985 40.8724 16.6363 39.961 16.1556C39.961 16.1556 38.9594 12.1158 40.2214 10.5099C41.4834 8.904 43.2596 8.08937 47.533 8.4466C48.7284 8.55657 49.8792 8.95564 50.886 9.6094C51.8928 10.2632 52.7255 11.152 53.3123 12.1993C53.5563 12.5967 53.6459 13.0699 53.564 13.529C53.4822 13.9882 53.2346 14.4013 52.8682 14.6899C52.829 14.0674 52.602 13.4714 52.2172 12.9805C52.2172 12.9805 50.658 16.1656 44.2044 12.443'
          fill='black'
        />
        <path
          d='M38.6317 18.5228C38.989 19.8282 40.0173 20.6929 40.9254 20.4525C41.8335 20.2122 42.2842 18.9568 41.927 17.6481C41.5698 16.3393 40.5415 15.4779 39.6333 15.7217C38.7252 15.9654 38.2712 17.2174 38.6317 18.5228Z'
          fill='#DB9C7F'
        />
        <path
          d='M47.0059 18.9602C47.0059 19.294 47.1494 19.5778 47.3631 19.5878C47.5768 19.5978 47.7637 19.3341 47.7804 18.9969C47.7971 18.6597 47.6369 18.3792 47.4098 18.3625C47.1828 18.3458 47.0226 18.6229 47.0059 18.9602Z'
          fill='black'
        />
        <path
          d='M51.4818 19.2339C51.4484 19.5678 51.5786 19.8215 51.7723 19.8449C51.9659 19.8683 52.1462 19.6246 52.1796 19.3107C52.2129 18.9969 52.0827 18.7231 51.8891 18.7031C51.6955 18.6831 51.5152 18.9201 51.4818 19.2339Z'
          fill='black'
        />
        <path
          d='M45.7402 16.2925C45.7402 16.2925 47.276 16.6264 47.3795 15.1641'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M50.9375 16.1758C50.9375 16.1758 51.3281 17.5313 52.4833 16.7467'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path d='M46.7421 24.3722C46.7421 24.3722 46.2346 22.3056 44.6621 23.4908L46.7421 24.3722Z' fill='black' />
        <path
          d='M39.543 17.3844C39.543 17.3844 40.5679 16.5063 41.1255 18.1155C41.1255 18.1155 40.5279 18.7499 40.9051 19.2473C40.7362 19.2827 40.5604 19.2644 40.4024 19.195C40.2443 19.1255 40.1119 19.0084 40.0237 18.8601'
          stroke='black'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M39.9863 18.002C40.0618 17.7082 40.2193 17.4418 40.4404 17.2341'
          stroke='black'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50.5938 19.1838C50.5938 20.142 51.1814 20.9165 51.9058 20.9165C52.6303 20.9165 53.2213 20.142 53.2213 19.1838C53.2213 18.2256 52.6337 17.4543 51.9058 17.4543C51.178 17.4543 50.5938 18.2289 50.5938 19.1838Z'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          d='M45.6833 18.9467C45.7149 19.268 45.839 19.5732 46.0406 19.8254C46.2422 20.0776 46.5126 20.2659 46.819 20.3675C47.1254 20.4692 47.4548 20.4798 47.7671 20.3981C48.0794 20.3163 48.3614 20.1458 48.5787 19.9071C48.7961 19.6684 48.9396 19.3718 48.9919 19.0532C49.0441 18.7346 49.0029 18.4077 48.8731 18.1121C48.7433 17.8165 48.5306 17.5648 48.2608 17.3877C47.9909 17.2105 47.6754 17.1153 47.3526 17.1138C46.8888 17.1371 46.453 17.3429 46.1403 17.6863C45.8276 18.0296 45.6633 18.4827 45.6833 18.9467Z'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          d='M50.6538 19.6981C50.6538 19.6981 50.2465 17.9753 48.9277 19.5779'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path d='M45.7168 18.2524L41.834 17.3777' stroke='white' strokeMiterlimit='10' />
        <path
          d='M50.8171 20.4492C50.9092 20.5678 50.9698 20.7077 50.9931 20.856C51.0165 21.0043 51.0019 21.1561 50.9506 21.2972C50.8978 21.4471 50.7982 21.576 50.6666 21.665C50.5349 21.7539 50.3782 21.7982 50.2195 21.7914C50.0714 21.7828 49.9254 21.7536 49.7854 21.7046C49.7253 21.6845 49.6051 21.6177 49.5384 21.6478C49.2045 21.788 48.8439 21.5109 48.9508 21.2906'
          stroke='black'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3847_28128'>
          <rect width='190.053' height='140' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
