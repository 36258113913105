export function GoalTrackingWidgetIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='24' height='24' rx='12' fill='#00ACE7' />
      <g clipPath='url(#clip0_6400_78523)'>
        <path
          d='M12.5 4C12.7917 4.04167 13.0875 4.07083 13.3783 4.125C13.6692 4.17917 13.9483 4.24417 14.2296 4.31833C14.535 4.39917 14.6925 4.67167 14.6104 4.94958C14.5296 5.22125 14.2604 5.36625 13.9642 5.27958C13.0973 5.02697 12.1899 4.94421 11.2917 5.03583C10.1092 5.15833 9.02042 5.54917 8.04583 6.23C6.51083 7.30292 5.50458 8.75 5.1525 10.5929C4.6525 13.2025 5.41167 15.4312 7.36458 17.2312C8.38583 18.1729 9.61125 18.7313 10.9862 18.9233C12.9121 19.1925 14.6721 18.7496 16.2225 17.5721C17.7383 16.4208 18.6367 14.885 18.9263 13.0033C19.0748 12.0215 19.0071 11.0192 18.7279 10.0662C18.6167 9.67667 18.8529 9.35375 19.2213 9.37208C19.435 9.38292 19.6246 9.51625 19.6721 9.74042C19.7821 10.2583 19.8713 10.7821 19.9683 11.3017C19.9808 11.3675 19.9896 11.4338 20 11.5V12.5C19.9913 12.5417 19.9796 12.58 19.9738 12.6208C19.9196 12.9908 19.8854 13.3654 19.8092 13.7308C19.1654 16.835 16.5175 19.3704 13.3925 19.8767C13.0958 19.925 12.7967 19.96 12.4988 20.0017H11.5C11.465 19.9933 11.4308 19.9817 11.3954 19.9767C11.0304 19.9233 10.6617 19.8879 10.3004 19.8158C7.19875 19.1933 4.62917 16.5258 4.125 13.4096C4.07625 13.1075 4.04167 12.8033 4 12.5V11.5C4.05125 11.1458 4.08917 10.7888 4.155 10.4375C4.75208 7.25 7.41042 4.64875 10.6058 4.125C10.9025 4.07417 11.2021 4.04167 11.5 4H12.5Z'
          fill='white'
        />
        <path
          d='M20 7.03125C19.9062 7.15333 19.8237 7.28625 19.7166 7.395C19.1979 7.92208 18.6691 8.43667 18.1525 8.9675C18.0121 9.11532 17.821 9.20462 17.6175 9.2175C16.8608 9.28694 16.1048 9.36222 15.3496 9.44333C15.2756 9.45911 15.2084 9.49773 15.1575 9.55375C14.2347 10.4704 13.314 11.3904 12.3954 12.3137C12.2575 12.4525 12.1008 12.53 11.905 12.4908C11.8181 12.4733 11.7375 12.4331 11.6713 12.3742C11.6051 12.3153 11.5558 12.2399 11.5283 12.1557C11.5008 12.0715 11.4962 11.9815 11.515 11.8949C11.5337 11.8083 11.5751 11.7282 11.635 11.6629C11.76 11.5296 11.8908 11.4037 12.02 11.275C12.8255 10.4694 13.6321 9.66389 14.4396 8.85833C14.5156 8.78917 14.562 8.69336 14.5691 8.59083C14.6371 7.84458 14.7166 7.09958 14.7871 6.35333C14.8009 6.16563 14.8848 5.98998 15.0221 5.86125C15.5566 5.33333 16.0833 4.79833 16.6158 4.2725C16.7212 4.16917 16.8504 4.09 16.9687 4H17.2187C17.4862 4.11708 17.59 4.32333 17.6037 4.61292C17.6275 5.12667 17.6821 5.63833 17.7187 6.15125C17.7254 6.24333 17.7571 6.27625 17.8487 6.28125C18.3616 6.31792 18.8737 6.37292 19.3871 6.39625C19.6787 6.40958 19.8825 6.51417 20 6.78125V7.03125ZM16.6779 5.61875C16.3908 5.90625 16.1283 6.16333 15.8746 6.42875C15.8196 6.48733 15.7838 6.56134 15.7721 6.64083C15.7166 7.12083 15.6716 7.6025 15.625 8.08333C15.6146 8.18833 15.6066 8.29417 15.5966 8.40792C16.1883 8.34917 16.7571 8.29083 17.3262 8.23875C17.4344 8.23012 17.5351 8.17995 17.6071 8.09875C17.8266 7.86958 18.0546 7.64875 18.2783 7.42375C18.3079 7.39068 18.3357 7.35605 18.3616 7.32C18.0187 7.29417 17.6979 7.26833 17.3771 7.24583C16.9421 7.21542 16.7875 7.06167 16.7562 6.62792C16.7308 6.30708 16.7058 5.98583 16.6779 5.61875Z'
          fill='white'
        />
        <path
          d='M12.005 6.4585C12.505 6.4885 12.9954 6.54683 13.4725 6.6885C13.5763 6.7175 13.6674 6.78031 13.7314 6.86696C13.7954 6.95361 13.8286 7.05914 13.8258 7.16683C13.8258 7.38391 13.6938 7.57975 13.4779 7.631C13.3568 7.6553 13.2316 7.65159 13.1121 7.62016C10.9433 7.09016 8.75876 8.16183 7.8571 10.2035C6.69252 12.8414 8.36293 15.9585 11.1988 16.4389C13.6463 16.8535 15.9133 15.3202 16.4238 12.8972C16.5596 12.242 16.5466 11.5647 16.3858 10.9152C16.3516 10.7908 16.3437 10.6606 16.3629 10.5331C16.4075 10.3135 16.6104 10.1743 16.8338 10.1722C16.9415 10.1698 17.0471 10.2032 17.1338 10.2673C17.2205 10.3313 17.2835 10.4223 17.3129 10.526C17.4525 11.0067 17.52 11.5055 17.5129 12.006C17.4892 14.5306 15.8421 16.6677 13.4121 17.3306C10.4421 18.141 7.30085 16.2472 6.6271 13.2435C5.94877 10.2177 7.81043 7.28308 10.8471 6.61058C11.2258 6.52683 11.6188 6.50725 12.005 6.4585Z'
          fill='white'
        />
        <path
          d='M12.1075 9.99969C11.0109 9.97219 10.1417 10.7247 10.0092 11.7914C9.88754 12.7684 10.5671 13.7126 11.5609 13.9476C12.7584 14.2326 13.9584 13.3164 14 12.0859C13.9988 12.0235 14.0028 11.961 14.0121 11.8993C14.0369 11.7776 14.1061 11.6695 14.2062 11.596C14.3064 11.5225 14.4303 11.4889 14.5538 11.5018C14.8105 11.528 14.9992 11.7434 15.003 12.0155C15.0213 13.2955 14.115 14.5051 12.8617 14.8739C12.3994 15.011 11.9109 15.0348 11.4374 14.9434C10.964 14.852 10.5195 14.648 10.1415 14.3486C9.76346 14.0492 9.46306 13.6632 9.26562 13.2233C9.06819 12.7833 8.97953 12.3024 9.00712 11.8209C9.09587 10.1714 10.5725 8.87594 12.2155 9.00552C12.6005 9.03594 12.8342 9.28844 12.7692 9.60386C12.7481 9.72022 12.6861 9.82521 12.5944 9.89992C12.5028 9.97463 12.3874 10.0142 12.2692 10.0114C12.2034 10.0105 12.1409 10.0039 12.1075 9.99969Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_6400_78523'>
          <rect width='16' height='16' fill='white' transform='translate(4 4)' />
        </clipPath>
      </defs>
    </svg>
  );
}
