import { Link, generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '@routes';
import { DataSourceIntegrationIcon } from '@assets/svgs';

export function ConnectDatasource() {
  const { organizationId } = useParams();
  return (
    <div className='flex h-[calc(100%-4rem)] flex-col items-center gap-7 pt-[6.625rem] md:pt-[12.5625rem] lg:pt-[7.8125rem] xxl:pt-[8.8125rem]'>
      <DataSourceIntegrationIcon className='h-[16.5rem] w-[17.8125rem] md:h-[21.125rem] md:w-[21.3125rem] lg:h-[12.5rem] lg:w-[12.625rem] xxl:h-[21.125rem] xxl:w-[21.3125rem]' />
      <div className='flex flex-col items-center gap-6 md:gap-8 lg:gap-6 xxl:gap-[1.6875rem]'>
        <p className='max-w-[17.8125rem] text-center text-sm font-normal leading-6 md:max-w-full md:text-base md:leading-5 lg:text-xs lg:leading-[.9375rem] xxl:text-base xxl:leading-5'>
          Please first connect your data source to create dashboard
        </p>
        <Link
          to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}
          className='flex h-10 w-[24.375rem] items-center justify-center rounded-md bg-darkPrimary text-white md:w-[15.9375rem] lg:h-8 lg:w-60 lg:text-xs lg:leading-3 xxl:h-10 xxl:w-[21.875rem] xxl:text-sm xxl:leading-6'
        >
          Connect Data Source
        </Link>
      </div>
    </div>
  );
}
