import { Button } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { Loader } from '@common/components';
import { capitalizeSourceName, getLinkedinAccountIds, nonAuthConnectorFields } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function ConnectButton({
  connectedDataSourceLoading,
  isNonAuthConnector,
  nonAuthSourceName,
  checkedOauthId,
  showAccount,
  showProperties,
  checkedPropertyId,
  checkedAccountId,
  nonAuthFields,
  handleConnectDataSource,
  forConnectTextButtonConnectors,
  dataSource,
  showDateField,
  checkedIds,
  showMultiChecks,
  showSelectField,
  clickUpSteps,
  listId,
  folderId,
  spaceId,
  teamId,
  linkedInAccounts,
  connectNewAccountProcessing,
  handleConnectNewAccount,
  nameField,
}) {
  const isButtonDisabled = () => {
    if (connectedDataSourceLoading) return true;

    if (connectNewAccountProcessing) {
      return !nameField && nonAuthConnectorFields(nonAuthFields[nonAuthSourceName]);
    } else {
      const isLinkedInAds = nonAuthSourceName === UPPERCASE_DATA_SOURCES.LINKEDIN_ADS;
      const hasCheckedIds = !isEmpty(checkedIds);

      if (Object.values(clickUpSteps).includes(true)) {
        switch (true) {
        case clickUpSteps.listing:
          return listId ? false : true;
        case clickUpSteps.folderListing:
          return folderId ? false : true;
        case clickUpSteps.spaceListing:
          return spaceId ? false : true;
        case clickUpSteps.teamListing:
          return teamId ? false : true;
        }
      }

      if (isLinkedInAds) {
        return showAccount ? checkedOauthId && isEmpty(getLinkedinAccountIds(linkedInAccounts)) : !checkedOauthId;
      }

      if (!isNonAuthConnector) {
        return (
          !checkedOauthId ||
          (showAccount && (showProperties ? !checkedPropertyId : !hasCheckedIds && !checkedAccountId))
        );
      }

      if (isNonAuthConnector) {
        return nonAuthConnectorFields(nonAuthFields[nonAuthSourceName]);
      }
    }
  };

  const getButtonText = () => {
    const { sourceName } = dataSource || {};

    const isGoogleOrFacebook = [
      UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS,
      UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING,
    ].includes(capitalizeSourceName(sourceName));

    const isLinkedin = [UPPERCASE_DATA_SOURCES.LINKEDIN_ADS].includes(capitalizeSourceName(sourceName));

    if (forConnectTextButtonConnectors.includes(sourceName)) {
      if (capitalizeSourceName(sourceName) === UPPERCASE_DATA_SOURCES.HUBSPOT) {
        return 'Connect';
      } else {
        return showDateField || showSelectField || isNonAuthConnector || showMultiChecks ? 'Connect' : 'Next';
      }
    }

    if (isGoogleOrFacebook) {
      if (
        showProperties ||
        (capitalizeSourceName(sourceName) === UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING && showAccount)
      ) {
        return 'Connect';
      }
      return 'Next';
    } else if (isLinkedin) {
      return !!checkedOauthId && !isEmpty(linkedInAccounts) ? 'Connect' : 'Next';
    }

    if (clickUpSteps) {
      return clickUpSteps.listing ? 'Connect' : 'Next';
    }

    return 'Connect';
  };

  return (
    <Button
      disabled={isButtonDisabled()}
      className='h-10 w-full md:w-[8.75rem] lg:h-[1.875rem] lg:w-[6.25rem] xxl:w-[8.75rem]'
      onClick={() => (connectNewAccountProcessing ? handleConnectNewAccount() : handleConnectDataSource())}
    >
      {getButtonText()}
      {connectedDataSourceLoading && <Loader />}
    </Button>
  );
}
