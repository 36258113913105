import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { BaseLoader } from '@common/components';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import { getLocalStorageItem } from '@utils';
import { PROFILE_TABS, PROFILE_TABS_CONTENT, PROFILE_TABS_LIST } from '@constants';

export function Profile() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const showAiToken = getLocalStorageItem('showAiToken');

  const [currentTab, setCurrentTab] = useState(PROFILE_TABS.PROFILE);

  const { isLoading: userDetailLoading, refetch: userDetailRefetch } = useGetQuery(
    'user-details',
    apiEndpoints.USER_DETAIL
  );

  useEffect(() => {
    if (pathname === '/profile/memberships-tab') {
      navigate(ROUTES.MEMBERSHIPS_TAB);
      setCurrentTab(PROFILE_TABS.MEMBERSHIP);
    } else if (pathname === '/profile/billing-tab') {
      navigate(ROUTES.BILLING_TAB);
      setCurrentTab(PROFILE_TABS.BILLING);
    } else if (pathname === '/profile/aitokens-tab') {
      navigate(ROUTES.AI_TOKENS_TAB);
      setCurrentTab(PROFILE_TABS.AI_TOKEN);
    } else if (pathname === '/profile/domain-tab') {
      navigate(ROUTES.DOMAIN_TAB);
      setCurrentTab(PROFILE_TABS.DOMAIN);
    } else {
      navigate(ROUTES.PROFILE_TAB);
      setCurrentTab(PROFILE_TABS.PROFILE);
      userDetailRefetch();
    }
  }, [pathname]);

  return (
    <div className='mx-auto mb-8 mt-6 max-w-[30rem] px-5 md:mt-[1.9375rem] md:max-w-[44rem] md:px-0 lg:mt-6 lg:max-w-full lg:px-8 xxl:mt-10 xxl:pl-[2.125rem] xxl:pr-10'>
      <h3 className='mb-[0.9375rem] text-2xl font-bold leading-[1.8156rem] md:mb-[1.875rem] md:text-[2rem] md:leading-6 lg:hidden'>
        Profile
      </h3>
      {userDetailLoading ? (
        <BaseLoader />
      ) : (
        <Tabs defaultValue={currentTab}>
          <div className='w-[calc(100vw-2.5rem)] max-w-[27.5rem] overflow-scroll md:max-w-[40.1875rem] lg:max-w-[23rem] xxl:max-w-[34.375rem]'>
            <TabsList className='h-12 min-w-[34.875rem] md:h-[2.625rem] md:min-w-[40.1875rem] lg:h-8 lg:min-w-[23rem] xxl:h-[2.625rem] xxl:min-w-[34.375rem]'>
              {PROFILE_TABS_LIST.filter(v =>
                showAiToken ? v : ![PROFILE_TABS.AI_TOKEN, PROFILE_TABS.DOMAIN].includes(v.value)
              ).map(({ path, value, label }) => (
                <Link to={path} key={path} className='w-full'>
                  <TabsTrigger value={value} className='h-10 md:h-[2.125rem] lg:h-6 xxl:h-8'>
                    {label}
                  </TabsTrigger>
                </Link>
              ))}
            </TabsList>
          </div>
          <div>
            {PROFILE_TABS_CONTENT.map(value => (
              <TabsContent key={value} value={value}>
                <Outlet />
              </TabsContent>
            ))}
          </div>
        </Tabs>
      )}
    </div>
  );
}
