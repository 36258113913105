export function HomeBottomIcon({ className }) {
  return (
    <svg
      width='300'
      height='326'
      viewBox='0 0 300 326'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <path opacity='0.1' d='M227.374 49.0557H181.353V254.147H227.374V49.0557Z' fill='url(#paint0_linear_2210_21380)' />
      <path opacity='0.1' d='M148.398 98.0513H102.377V303.143H148.398V98.0513Z' fill='url(#paint1_linear_2210_21380)' />
      <g opacity='0.1'>
        <path
          d='M259.222 150.082V261.95C259.235 278.952 255.167 295.708 247.36 310.81C239.553 325.913 228.234 338.921 214.356 348.741C200.477 358.561 184.444 364.906 167.604 367.243C150.764 369.58 133.609 367.84 117.581 362.17C101.553 356.5 87.1202 347.066 75.4955 334.66C63.8707 322.254 55.3938 307.239 50.7771 290.876C46.1603 274.514 45.5387 257.282 48.9645 240.63C52.3903 223.977 59.7633 208.39 70.464 195.179V131.9C52.5023 143.505 37.1531 158.723 25.3928 176.583C13.6325 194.444 5.71867 214.557 2.15483 235.643C-1.40901 256.729 -0.544833 278.326 4.69241 299.059C9.92964 319.793 19.4253 339.209 32.5751 356.073C45.7249 372.937 62.241 386.879 81.0725 397.013C99.904 407.146 120.639 413.249 141.957 414.932C163.275 416.616 184.711 413.844 204.9 406.792C225.088 399.74 243.588 388.563 259.222 373.973V416.347H307.452V0H259.222V150.082Z'
          fill='white'
        />
        <path
          d='M259.222 150.082V261.95C259.235 278.952 255.167 295.708 247.36 310.81C239.553 325.913 228.234 338.921 214.356 348.741C200.477 358.561 184.444 364.906 167.604 367.243C150.764 369.58 133.609 367.84 117.581 362.17C101.553 356.5 87.1202 347.066 75.4955 334.66C63.8707 322.254 55.3938 307.239 50.7771 290.876C46.1603 274.514 45.5387 257.282 48.9645 240.63C52.3903 223.977 59.7633 208.39 70.464 195.179V131.9C52.5023 143.505 37.1531 158.723 25.3928 176.583C13.6325 194.444 5.71867 214.557 2.15483 235.643C-1.40901 256.729 -0.544833 278.326 4.69241 299.059C9.92964 319.793 19.4253 339.209 32.5751 356.073C45.7249 372.937 62.241 386.879 81.0725 397.013C99.904 407.146 120.639 413.249 141.957 414.932C163.275 416.616 184.711 413.844 204.9 406.792C225.088 399.74 243.588 388.563 259.222 373.973V416.347H307.452V0H259.222V150.082Z'
          fill='url(#paint2_linear_2210_21380)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_2210_21380'
          x1='229.531'
          y1='88.0914'
          x2='138.927'
          y2='316.553'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2210_21380'
          x1='178.086'
          y1='67.6959'
          x2='87.4816'
          y2='296.157'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2210_21380'
          x1='321.864'
          y1='79.2446'
          x2='258.108'
          y2='608.295'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
      </defs>
    </svg>
  );
}
