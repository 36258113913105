export function GoalTrackingIcon({ className }) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_6879_41959)'>
        <path
          d='M8.5 0C8.79167 0.0416667 9.0875 0.0708333 9.37833 0.125C9.66917 0.179167 9.94833 0.244167 10.2296 0.318333C10.535 0.399167 10.6925 0.671667 10.6104 0.949583C10.5296 1.22125 10.2604 1.36625 9.96417 1.27958C9.09735 1.02697 8.18989 0.944207 7.29167 1.03583C6.10917 1.15833 5.02042 1.54917 4.04583 2.23C2.51083 3.30292 1.50458 4.75 1.1525 6.59292C0.6525 9.2025 1.41167 11.4312 3.36458 13.2312C4.38583 14.1729 5.61125 14.7312 6.98625 14.9233C8.91208 15.1925 10.6721 14.7496 12.2225 13.5721C13.7383 12.4208 14.6367 10.885 14.9263 9.00333C15.0748 8.02154 15.0071 7.01916 14.7279 6.06625C14.6167 5.67667 14.8529 5.35375 15.2213 5.37208C15.435 5.38292 15.6246 5.51625 15.6721 5.74042C15.7821 6.25833 15.8713 6.78208 15.9683 7.30167C15.9808 7.3675 15.9896 7.43375 16 7.5V8.5C15.9913 8.54167 15.9796 8.58 15.9738 8.62083C15.9196 8.99083 15.8854 9.36541 15.8092 9.73083C15.1654 12.835 12.5175 15.3704 9.3925 15.8767C9.09583 15.925 8.79667 15.96 8.49875 16.0017H7.5C7.465 15.9933 7.43083 15.9817 7.39542 15.9767C7.03042 15.9233 6.66167 15.8879 6.30042 15.8158C3.19875 15.1933 0.629167 12.5258 0.125 9.40958C0.07625 9.1075 0.0416667 8.80333 0 8.5V7.5C0.05125 7.14583 0.0891667 6.78875 0.155 6.4375C0.752083 3.25 3.41042 0.64875 6.60583 0.125C6.9025 0.0741666 7.20208 0.0416667 7.5 0H8.5Z'
          fill='#263238'
          fillOpacity='0.5'
        />
        <path
          d='M16.0002 3.03125C15.9065 3.15333 15.824 3.28625 15.7169 3.395C15.1981 3.92208 14.6694 4.43667 14.1527 4.9675C14.0123 5.11532 13.8212 5.20462 13.6177 5.2175C12.8611 5.28694 12.1051 5.36222 11.3498 5.44333C11.2758 5.45911 11.2086 5.49773 11.1577 5.55375C10.2349 6.47042 9.31425 7.39042 8.39564 8.31375C8.25773 8.4525 8.10106 8.53 7.90523 8.49083C7.81838 8.47335 7.73774 8.4331 7.67155 8.37422C7.60536 8.31533 7.55601 8.23992 7.52855 8.15569C7.50108 8.07147 7.49648 7.98146 7.51523 7.89487C7.53397 7.80829 7.57538 7.72824 7.63523 7.66292C7.76023 7.52958 7.89106 7.40375 8.02023 7.275C8.82578 6.46944 9.63231 5.66389 10.4398 4.85833C10.5158 4.78917 10.5622 4.69336 10.5694 4.59083C10.6373 3.84458 10.7169 3.09958 10.7873 2.35333C10.8011 2.16563 10.885 1.98998 11.0223 1.86125C11.5569 1.33333 12.0836 0.798333 12.6161 0.2725C12.7215 0.169167 12.8506 0.09 12.969 0H13.219C13.4865 0.117083 13.5902 0.323333 13.604 0.612917C13.6277 1.12667 13.6823 1.63833 13.719 2.15125C13.7256 2.24333 13.7573 2.27625 13.849 2.28125C14.3619 2.31792 14.874 2.37292 15.3873 2.39625C15.679 2.40958 15.8827 2.51417 16.0002 2.78125V3.03125ZM12.6781 1.61875C12.3911 1.90625 12.1286 2.16333 11.8748 2.42875C11.8198 2.48733 11.784 2.56134 11.7723 2.64083C11.7169 3.12083 11.6719 3.6025 11.6252 4.08333C11.6148 4.18833 11.6069 4.29417 11.5969 4.40792C12.1886 4.34917 12.7573 4.29083 13.3265 4.23875C13.4347 4.23012 13.5353 4.17995 13.6073 4.09875C13.8269 3.86958 14.0548 3.64875 14.2786 3.42375C14.3082 3.39068 14.336 3.35605 14.3619 3.32C14.019 3.29417 13.6981 3.26833 13.3773 3.24583C12.9423 3.21542 12.7877 3.06167 12.7565 2.62792C12.7311 2.30708 12.7061 1.98583 12.6781 1.61875Z'
          fill='#263238'
          fillOpacity='0.5'
        />
        <path
          d='M8.00502 2.45898C8.50502 2.48898 8.99543 2.54732 9.47252 2.68898C9.57626 2.71799 9.66736 2.7808 9.73136 2.86745C9.79537 2.95409 9.82862 3.05963 9.82585 3.16732C9.82585 3.3844 9.69377 3.58023 9.47793 3.63148C9.35675 3.65579 9.23163 3.65208 9.1121 3.62065C6.94335 3.09065 4.75877 4.16232 3.8571 6.20398C2.69252 8.8419 4.36293 11.959 7.19877 12.4394C9.64627 12.854 11.9133 11.3207 12.4238 8.89773C12.5596 8.24252 12.5466 7.56519 12.3858 6.91565C12.3516 6.7913 12.3437 6.66113 12.3629 6.53357C12.4075 6.31398 12.6104 6.17482 12.8338 6.17273C12.9415 6.17031 13.0471 6.20372 13.1338 6.26775C13.2205 6.33177 13.2835 6.42278 13.3129 6.52648C13.4526 7.00718 13.52 7.50597 13.5129 8.00648C13.4892 10.5311 11.8421 12.6681 9.4121 13.3311C6.4421 14.1415 3.30085 12.2477 2.6271 9.24398C1.94877 6.21815 3.81043 3.28357 6.8471 2.61107C7.22585 2.52732 7.61877 2.50773 8.00502 2.45898Z'
          fill='#263238'
          fillOpacity='0.5'
        />
        <path
          d='M8.1073 6.00018C7.01063 5.97268 6.14146 6.72518 6.00896 7.79185C5.8873 8.76893 6.56688 9.7131 7.56063 9.9481C8.75813 10.2331 9.95813 9.31685 9.99979 8.08643C9.99856 8.02398 10.0026 7.96153 10.0119 7.89976C10.0367 7.77807 10.1059 7.66995 10.206 7.59647C10.3061 7.523 10.43 7.48941 10.5535 7.50226C10.8102 7.52851 10.999 7.74393 11.0027 8.01601C11.021 9.29601 10.1148 10.5056 8.86146 10.8743C8.39916 11.0115 7.91065 11.0353 7.4372 10.9439C6.96374 10.8525 6.51923 10.6484 6.14123 10.3491C5.76322 10.0497 5.46281 9.6637 5.26538 9.22377C5.06795 8.78383 4.97928 8.30284 5.00688 7.82143C5.09563 6.17185 6.5723 4.87643 8.21521 5.00601C8.60021 5.03643 8.83396 5.28893 8.76896 5.60434C8.74785 5.7207 8.68587 5.8257 8.5942 5.90041C8.50253 5.97512 8.38719 6.01464 8.26896 6.01184C8.20313 6.01101 8.14063 6.00434 8.1073 6.00018Z'
          fill='#263238'
          fillOpacity='0.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_6879_41959'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
