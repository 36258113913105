import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isEmpty, uniq } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, performGetRequest, useGetQuery, usePostMutation } from '@services';
import { doInfiniteCommentsScroll, getNextPage } from '@utils';
import { EMPTY_COMMENT_MESSAGE, TAGGED_REGEX, USER_ID_REGEX } from '@constants';

export function useComments(isCommentMode, commentedWidget) {
  const { dashboardId } = useParams();
  const scrollContainerRef = useRef();

  const getCommentsListing = ({ pageParam = 1 }) =>
    performGetRequest(apiEndpoints.COMMENTS_LISTING(dashboardId, pageParam, commentedWidget?.i));

  const {
    data: commentsListing,
    isFetching: commentsListingFetching,
    refetch: commentsListingRefetch,
    fetchNextPage: commentsListingNextPage,
    hasNextPage: commentsListingHasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    retry: true,
    enabled: isCommentMode,
    queryKey: ['comments-listing'],
    queryFn: getCommentsListing,
    getNextPageParam: getNextPage,
    onError: ({ response: { data } }) => toast.error(data?.detail),
  });

  const { data: tagUsersList, isFetching: isUsersFetching } = useGetQuery(
    ['tag-users'],
    apiEndpoints.TAG_USERS_LISTIBG(dashboardId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const commentInitialValue = {
    text: '',
    selectedName: '',
  };

  const commentValidationSchema = Yup.object({
    text: Yup.string().required(EMPTY_COMMENT_MESSAGE),
    selectedName: Yup.string().notRequired(),
  });

  const handleSubmit = () => {
    const mentionRegex = TAGGED_REGEX;
    const transformedSentence = values.text.replace(mentionRegex, (match, username) => {
      return `<strong>${username}</strong>`;
    });

    const userIdRegex = USER_ID_REGEX;

    let userIds = [];
    let match;
    while ((match = userIdRegex.exec(values.text)) !== null) {
      userIds.push(+match[1]);
    }

    let payload = {
      text: transformedSentence,
      tagged_users: uniq(userIds) ?? [],
      widgetIdentifier: commentedWidget?.i,
      widgetLabel: commentedWidget?.displayLabel,
    };

    isEmpty(commentedWidget) && delete payload['widgetIdentifier'];
    isEmpty(commentedWidget) && delete payload['widgetLabel'];

    handleCreateComment({ payload });
  };

  const scrollToBottom = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
  };

  const handleScroll = e =>
    doInfiniteCommentsScroll(e, commentsListingHasNextPage, commentsListingFetching, commentsListingNextPage);

  const { ...formik } = useFormikForm(commentInitialValue, handleSubmit, commentValidationSchema);

  const { values, resetForm } = formik;

  const { mutate: handleCreateComment, isLoading: createCommentLoading } = usePostMutation(
    apiEndpoints.CREATE_COMMENT(dashboardId, commentedWidget),
    () => {
      commentsListingRefetch();
      resetForm();
      scrollToBottom();
    },
    ({ response: { data } }) => {
      toast.error(data?.text[0] || data?.detail[0]);
    }
  );

  return {
    commentsListing,
    formik,
    createCommentLoading,
    scrollContainerRef,
    handleScroll,
    resetForm,
    isUsersFetching,
    tagUsersList,
  };
}
