import { Button, DialogContent, DialogFooter, DialogHeader } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { CreateDashboardStep2 } from '@pages/dashboard';

export function AddDataSourceDialog({
  addedDataSource,
  handleSelectedDataSource,
  addDataSourceListing,
  selectedDataSourceIds,
  handleAddDataSource,
  addDataSourceLoading,
}) {
  return (
    <DialogContent className='w-[calc(100vw-2.5rem)] max-w-[24.375rem] overflow-y-auto overflow-x-hidden rounded-md px-5 py-6 scrollbar-none md:h-[44rem] md:max-w-[44rem] md:p-8 md:px-8 md:py-10 lg:h-[32.75rem] lg:max-w-[39rem] lg:p-8 xxl:h-[45rem] xxl:max-w-[55rem] xxl:p-10'>
      <DialogHeader className='block w-full gap-4 md:gap-6 lg:gap-4 xxl:w-[50rem] xxl:gap-6'></DialogHeader>
      <div className='flex h-[calc(100%-3.75rem)] flex-col gap-6 md:gap-[2.625rem] lg:h-[calc(100%-2.75rem)] lg:gap-9 xxl:h-[calc(100%-3.75rem)] xxl:gap-6'>
        <CreateDashboardStep2
          addDataSourceListing={addDataSourceListing}
          handleCheck={handleSelectedDataSource}
          addedDataSource={addedDataSource}
          selectedDataSourceIds={selectedDataSourceIds}
          showTour={false}
        />
        <DialogFooter className='mt-auto w-full flex-col-reverse md:flex-row md:items-end md:gap-4 xxl:gap-3'>
          <div className='w-full md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'>
            <Button
              className='h-12 md:h-10 lg:h-8 lg:text-[.625rem] lg:leading-3 xxl:h-10 xxl:text-sm xxl:leading-6'
              disabled={isEmpty(selectedDataSourceIds) || addDataSourceLoading}
              onClick={() => {
                handleAddDataSource({
                  payload: { dataSources: selectedDataSourceIds },
                });
              }}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </div>
    </DialogContent>
  );
}
