export const TEAMS_TAB_TOUR_ID = 'TEAMS_TAB_TOUR_ID';
export const CREATE_TEAM_BUTTON_ID = 'CREATE_TEAM_BUTTON_ID';
export const CREATE_TEAM_MODAL_BUTTON_ID = 'CREATE_TEAM_MODAL_BUTTON_ID';
export const CREATE_TEAM_NAME_ID = 'teamName';
export const CREATE_TEAM_PERMISSION_ID = 'permission';
export const CREATE_TEAM_PERMISSION_DROPDOWN_ID = 'CREATE_TEAM_PERMISSION_DROPDOWN_ID';
export const CREATE_TEAM_DASHBOARD_ID = 'dashboardsTeam';
export const CREATE_TEAM_DASHBOARD_DROPDOWN_ID = 'CREATE_TEAM_DASHBOARD_DROPDOWN_ID';
export const CREATE_TEAM_MEMBER_ID = 'members';
export const CREATE_TEAM_MEMBER_DROPDOWN_ID = 'CREATE_TEAM_MEMBER_DROPDOWN_ID';
export const CREATE_DASHBOARD_BUTTON_ID = 'CREATE_DASHBOARD_BUTTON_ID';
export const CREATE_DASHBOARD_LOGO_ID = 'CREATE_DASHBOARD_LOGO_ID';
export const CREATE_DASHBOARD_NAME_ID = 'name';
export const CREATE_DASHBOARD_DOMAIN_ID = 'domain';
export const CREATE_DASHBOARD_CURRENCY_ID = 'CREATE_DASHBOARD_CURRENCY_ID';
export const CREATE_DASHBOARD_STEP1_BUTTON = 'CREATE_DASHBOARD_STEP1_BUTTON';
export const CREATE_DASHBOARD_CURRENCY_DROPDOWN = 'CREATE_DASHBOARD_CURRENCY_DROPDOWN';
export const CREATE_DASHBOARD_DATA_SOURCE_ID = 'CREATE_DASHBOARD_DATA_SOURCE_ID';
export const CREATE_DASHBOARD_TYPE_ID = 'CREATE_DASHBOARD_TYPE_ID';
export const CREATE_DASHBOARD_TEMPLATE_ID = 'CREATE_DASHBOARD_TEMPLATE_ID';
export const CREATE_DASHBOARD_TYPE_CLASS = 'CREATE_DASHBOARD_TYPE_CLASS';
export const ADD_WIDGETS_BUTTON_ID = 'ADD_WIDGET_BUTTON_ID';
export const EDIT_DASHBOARD_BUTTON_ID = 'EDIT_DASHBOARD_BUTTON_ID';
export const STATIC_WIDGETS_ID = 'STATIC_WIDGETS_ID';
export const STATIC_WIDGETS_TAB_ID = 'STATIC_WIDGET_TAB_ID';
export const INVITE_MEMBER_TAB = 'INVITE_MEMBER_TAB';
export const INVITE_MEMBER_BUTTON = 'INVITE_MEMBER_BUTTON';
export const INVITE_MEMBER_ROLE = 'INVITE_MEMBER_ROLE';
export const INVITE_MEMBER_ROLE_DROPDOWN = 'INVITE_MEMBER_ROLE_DROPDOWN';
export const INVITE_MEMBER_TEAM = 'INVITE_MEMBER_TEAM';
export const INVITE_MEMBER_TEAM_DROPDOWN = 'INVITE_MEMBER_TEAM_DROPDOWN';
export const INVITE_MEMBER_EMAIL = 'email';
export const INVITE_MEMBER_SUBMIT = 'INVITE_MEMBER_SUBMIT';
export const SAVE_BTN_ID = 'SAVE_BTN_ID';
