export const ERROR_MESSAGE = 'Something went wrong!';
export const POST_ERROR_MESSAGE = 'Error while posting post.';
export const INVALID_EMAIL_ADDRESS = 'Invalid email address';
export const FIELD_REQUIRED = text => `${text} is required`;
export const MIN_LENGTH_REQUIRED = text => `${text} must be at least 3 characters`;
export const MAX_LENGTH_REQUIRED = text => `${text} should not exceed 60 characters`;
export const TERMS_AND_CONDITIONS_REQUIRED = 'Please accept terms and conditions';
export const SEND_OTP_MESSAGE = 'OTP sent successfully. Please check your inbox for the code.';
export const OTP_VERIFY_MESSAGE = 'OTP verified successfully.';
export const PROFILE_UPDATED_MESSAGE = 'Profile updated successfully.';
export const SUCCESSFUL_MESSAGE = text => `${text} successfully.`;
export const DEFAULT_ORGANIZATION_DELETE_MESSAGE = 'Default organization deletion is not allowed.';
export const PRESS_ENTER_MESSAGE = 'Press enter after adding an email';
export const NO_NOTIFICATION_MESSAGE = 'No notification yet.';
export const NO_COMMENTS_MESSAGE = 'No comments yet.';
export const EMPTY_COMMENT_MESSAGE = 'The comment field should not be left blank.';
export const DATA_SOURCE_SEARCH_NOT_MATCHED = 'No data source found.';
export const DATA_SOURCE_NOT_CONNECTED = 'Please first connect your data source to create dashboard';
export const MEMBERSHIP_CONSUMED = 'The owner membership has been consumed';
export const EMPTY_TEXT_STATIC_WIDGET = 'Text widget cannot be empty.';
export const NO_DATA_MESSAGE = text => `No ${text} yet.`;
export const NOT_FOUND = 'Not found.';
export const INVITATION_EXPIRED = 'Invitation link is expired.';
export const WHITE_LABEL_LOGO_ERROR = (type, text) => `${type} must be less than or equals to ${text} pixels.`;
export const FILE_EXCEED_MESSAGE = fileLimit =>
  `File size exceeds limit. Please upload a file of up to ${fileLimit}MB.`;
export const WIDGET_EXCEEDS_SLIDE_AREA = 'Widget Exceeds Slide Area';
export const PAST_TIME_ERROR_MESSAGE = 'Scheduled time cannot be in the past.';
