import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  ScrollArea,
  ScrollBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcnComponent';
import { Copy, Trash2 } from 'lucide-react';
import { useDomainTab } from '../hooks/useDomainTab';
import { BaseToolTip, CustomPagination, DeleteDialog, FormInput, Loader } from '@common/components';
import { DomainInstruction } from '@pages/whiteLabel';
import { truncateString } from '@utils';
import { TOTAL_ITEMS_PER_PAGE } from '@constants';

export function DomainTab() {
  const {
    formik,
    emailListing,
    addDomainLoading,
    showAddDomain,
    setShowAddDomain,
    addDomainValidateLoading,
    setDomainId,
    accordionItem,
    setAccordionItem,
    copyToClipboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDomainLoading,
    setDeleteDomainLoading,
    setDeleteDomainId,
    handleDeleteDomain,
    page,
    setPage,
  } = useDomainTab();

  const { values, setFieldValue, errors, submitForm } = formik;

  return (
    <div className='overflow-y-auto border border-lightGray bg-white p-5 shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] md:px-6 md:pb-8 md:pt-6 lg:h-[calc(100vh-10.875rem)] lg:px-6 lg:pb-4 lg:pt-5 xxl:h-[calc(100vh-16.625rem)] xxl:px-8 xxl:py-6'>
      <div className='mb-4 flex flex-col gap-4 md:mb-6 md:flex-row md:items-center md:justify-between lg:mb-4 xxl:mb-6'>
        <div className='flex items-center gap-1.5'>
          <h2 className='text-xl font-semibold leading-[1.5125rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-xl xxl:leading-[1.5125rem]'>
            Custom Email Domains
          </h2>
          <DomainInstruction parentClass='!static' />
        </div>
        <Dialog open={showAddDomain} onOpenChange={setShowAddDomain}>
          <DialogTrigger className='flex h-12 w-full items-center justify-center rounded-md bg-darkPrimary text-sm font-medium leading-[1.0588rem] text-white md:h-10 md:w-[8.75rem] lg:h-8 lg:w-[7.5rem] lg:text-xs lg:leading-6 xxl:h-10 xxl:w-[8.75rem] xxl:text-sm xxl:leading-6'>
            Add Domain
          </DialogTrigger>
          <DialogContent className='w-[calc(100vw-2.5rem)] max-w-[24.125rem] rounded-md px-6 py-10 md:max-w-[33.75rem] md:p-10 lg:max-w-[22.25rem] lg:px-6 xxl:max-w-[33.75rem] xxl:p-10'>
            <h2 className='mb-6 text-2xl font-bold leading-[1.8156rem] md:mb-8 lg:mb-6 lg:text-lg lg:leading-5 xxl:mb-8 xxl:text-2xl xxl:leading-[1.8156rem]'>
              Add Custom Email Domain
            </h2>
            <FormInput
              labelText='Domain'
              value={values?.emailDomain}
              handleChange={e => setFieldValue('emailDomain', e?.target.value)}
              placeholder='gmail.com'
              labelClassName='text-base md:text-base lg:text-xs xxl:text-base leading-[1.21rem] md:leading-[1.21rem] lg:leading-[0.9075rem] xxl:leading-base font-semibold'
              className='gap-3 lg:gap-1.5 xxl:gap-3'
              showError={errors.emailDomain}
            />
            <div className='mt-6 flex justify-end lg:mt-4 xxl:mt-6'>
              <Button
                className='w-full md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'
                disabled={addDomainLoading || !values?.emailDomain}
                onClick={submitForm}
              >
                Add
                {addDomainLoading && <Loader />}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <ScrollArea className='relative z-[1] w-[calc(100vw-5rem)] whitespace-nowrap rounded-md border xsm:w-[27.5rem] md:w-full lg:h-[calc(100vh-21.25rem)] lg:w-auto xxl:h-[calc(100vh-28.75rem)]'>
        <div className='flex w-[40rem] justify-between border-b border-lightGray pb-3.5 pl-[3.3125rem] pr-5 pt-[1.1875rem] md:w-[41rem] md:pl-16 md:pr-8 lg:w-full lg:min-w-[46.1875rem] lg:py-3 lg:pl-[3.375rem] xxl:pb-3.5 xxl:pl-[4.5rem] xxl:pr-[2.4375rem] xxl:pt-[1.1875rem]'>
          <span className='w-[9.75rem] text-sm font-medium leading-6 text-slateGray lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6'>
            Domain
          </span>
          <span className='w-[4.4375rem] text-sm font-medium leading-6 text-slateGray lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6'>
            Status
          </span>
          <span className='w-[6.25rem] text-end text-sm font-medium leading-6 text-slateGray lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6'>
            Action
          </span>
        </div>
        {emailListing?.data?.results?.map(emailDomainDetail => (
          <Accordion
            key={emailDomainDetail?.id}
            value={accordionItem}
            onValueChange={status => setAccordionItem(status)}
            type='single'
            collapsible
            className='w-full pt-[0.0625rem]'
          >
            <AccordionItem value={`item-${emailDomainDetail?.id}`} className='relative'>
              <AccordionTrigger
                className={`w-[40rem] flex-row-reverse gap-3 border-b border-b-lightGray px-5 py-4 md:w-[41rem] md:gap-2 md:px-8 lg:w-full lg:py-3 xxl:gap-3 xxl:px-[2.4375rem] xxl:py-4 ${accordionItem === `item-${emailDomainDetail?.id}` ? 'bg-lightGray' : ''}`}
              >
                <div className='flex flex-1 items-center justify-between'>
                  <span className='w-[9.75rem] text-left text-sm font-semibold leading-[1.0588rem] xxl:text-sm'>
                    {emailDomainDetail?.domain}
                  </span>
                  <Badge
                    className={`w-[4.4375rem] bg-white hover:bg-white ${emailDomainDetail?.isValid ? 'border-limeGreen text-limeGreen' : 'border-yellowOrange text-yellowOrange'}`}
                  >
                    {emailDomainDetail?.isValid ? 'Verified' : 'Pending'}
                  </Badge>
                  <div className='flex w-[6.25rem] gap-4'></div>
                </div>
              </AccordionTrigger>
              {accordionItem === `item-${emailDomainDetail?.id}` ? (
                <Button
                  className='absolute right-5 top-[13px] h-[1.875rem] w-[6.25rem] bg-limeGreen md:right-8 md:h-[1.875rem] lg:top-[0.6875rem] lg:h-[1.5625rem] lg:w-[3.75rem] lg:py-[0.4063rem] xxl:right-[39px] xxl:top-[0.8125rems] xxl:h-[1.875rem] xxl:h-[1.875rem] xxl:w-[6.25rem]'
                  disabled={addDomainValidateLoading}
                  onClick={() => setDomainId(emailDomainDetail?.domainId)}
                >
                  Verify
                  {addDomainValidateLoading}
                </Button>
              ) : (
                <Dialog
                  open={showDeleteDialog[emailDomainDetail?.id]}
                  onOpenChange={isOpen => {
                    setShowDeleteDialog({
                      ...showDeleteDialog,
                      [emailDomainDetail.id]: isOpen,
                    });
                  }}
                >
                  <span
                    className='absolute right-5 top-[15px] cursor-pointer hover:opacity-[.7] md:right-8 xxl:right-[2.4375rem]'
                    onClick={() => {
                      setShowDeleteDialog({
                        ...showDeleteDialog,
                        [emailDomainDetail.id]: true,
                      });
                      setDeleteDomainLoading(false);
                      setDeleteDomainId(emailDomainDetail.id);
                    }}
                  >
                    <Trash2 color='#EF4444' className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                  </span>
                  <DeleteDialog
                    heading={emailDomainDetail?.domain}
                    text={emailDomainDetail?.domain}
                    className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[21.8125rem] md:min-h-[16.5625rem] md:max-w-[28.125rem] lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
                    deleteBtnClick={() => {
                      handleDeleteDomain();
                      setDeleteDomainLoading(true);
                    }}
                    isLoading={deleteDomainLoading}
                  />
                </Dialog>
              )}
              <AccordionContent className='rounded-b-lg border border-y-lightGray pb-0'>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className='py-3 pl-[2.5625rem] pr-[0.9375rem] text-xs font-medium leading-[0.9075rem] md:py-[0.8125rem] md:pl-[1.875rem] md:pr-6 lg:px-10 lg:py-2.5 xxl:px-20 xxl:py-3'>
                        Type
                      </TableHead>
                      <TableHead className='px-[0.9375rem] py-3 text-xs font-medium leading-[0.9075rem] md:px-6 md:py-[0.8125rem] lg:px-10 lg:py-2.5 xxl:px-20 xxl:py-3'>
                        Host
                      </TableHead>
                      <TableHead className='py-3 pl-[0.9375rem] pr-[2.4375rem] text-xs font-medium leading-[0.9075rem] md:py-[0.8125rem] md:pl-6 md:pr-[1.875rem] lg:px-10 lg:py-2.5 xxl:px-20 xxl:py-3'>
                        Data
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {emailDomainDetail?.dnsRecords?.map((record, index) => (
                      <TableRow key={index}>
                        <TableCell className='py-3 pl-[2.5625rem] pr-[0.9375rem] text-xs font-normal leading-[0.9075rem] md:py-[0.8125rem] md:pl-[1.875rem] md:pr-6 lg:px-10 lg:py-2.5 xxl:px-20 xxl:py-3'>
                          <div className='inline-flex items-center gap-1.5'>
                            {record.type}
                            <span
                              className='cursor-pointer hover:opacity-[.7]'
                              onClick={() => copyToClipboard(record.type)}
                            >
                              <Copy className='h-3 w-3' color='#00ACE7' />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell className='flex items-center gap-1.5 px-[0.9375rem] py-3 text-xs font-normal leading-[0.9075rem] md:px-6 md:py-[0.8125rem] lg:px-10 lg:py-2.5 xxl:px-20 xxl:py-3'>
                          <div className='inline-flex items-center gap-1.5'>
                            <span id={`recordHost-${index}`}>
                              {truncateString(record?.host, 23, 'text-darkSecondary')}
                            </span>
                            <span
                              className='cursor-pointer hover:opacity-[.7]'
                              onClick={() => copyToClipboard(record.host)}
                            >
                              <Copy className='h-3 w-3' color='#00ACE7' />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell className='py-3 pl-[0.9375rem] pr-[2.5625rem] text-xs font-normal leading-[0.9075rem] md:py-[0.8125rem] md:pl-6 md:pr-[1.875rem] lg:px-10 lg:py-2.5 xxl:px-20 xxl:py-3 xxl:text-xs'>
                          <div className='inline-flex items-center gap-1.5'>
                            <span id={`recordData-${index}`}>
                              {truncateString(record?.data, 41, 'text-darkSecondary')}
                            </span>
                            <span
                              className='cursor-pointer hover:opacity-[.7]'
                              onClick={() => copyToClipboard(record.data)}
                            >
                              <Copy className='h-3 w-3' color='#00ACE7' />
                            </span>
                          </div>
                        </TableCell>
                        {record?.host?.length > 23 && <BaseToolTip id={`recordHost-${index}`} content={record?.host} />}
                        {record?.data?.length > 41 && <BaseToolTip id={`recordData-${index}`} content={record?.data} />}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      {emailListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
        <div className='mt-4'>
          <CustomPagination page={page} setPage={setPage} data={emailListing} />
        </div>
      )}
    </div>
  );
}
