import { cn } from '@shadcnUtils';
import { Tooltip } from 'react-tooltip';
import { WidgetCommentBtn, WidgetMenu } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import { TooltipIcon } from '@assets/svgs';
import 'react-tooltip/dist/react-tooltip.css';

export function TextWidget({
  widget,
  onRemoveClick,
  isEditMode,
  handleClone,
  textClass,
  handleEditWidget,
  isSharedDashboard,
  showCommentBtn,
  handleEditCommentMode,
  widgetCommentClass,
  hideTooltip,
}) {
  return (
    <div className={cn('flex h-full items-center justify-between')}>
      <div className='flex items-center'>
        {widget?.widgetData?.error ? (
          <p
            className='text-md flex h-[calc(100%-3.4375rem)] w-full items-center justify-center font-bold'
            style={
              widget?.themeColor === DEFAULT_VALUE
                ? {
                  color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                }
                : {
                  color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
                }
            }
          >
            {widget?.widgetData?.error}
          </p>
        ) : (
          <h3
            className={cn('break-all text-xl font-semibold leading-6 focus:outline-none focus:ring-0', textClass)}
            style={
              widget?.themeColor === DEFAULT_VALUE
                ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
            }
          >
            {widget?.displayLabel}
          </h3>
        )}
        {widget?.tooltip?.length > 0 && (
          <span className='ml-2 cursor-pointer' id={widget?.id}>
            <TooltipIcon
              strokeColor={
                widget?.themeColor === DEFAULT_VALUE
                  ? widget.themeStyle === THEME_STYLE.DARK
                    ? COLORS.WHITE_COLOR
                    : COLORS.DARK_PRIMARY
                  : widget.themeStyle === THEME_STYLE.DARK
                    ? COLORS.WHITE_COLOR
                    : COLORS.DARK_PRIMARY
              }
            />
          </span>
        )}
        {!hideTooltip && (
          <Tooltip
            className='!max-w-[12.5rem] break-words !bg-darkPrimary !opacity-[1]'
            anchorSelect={`#${widget?.id}`}
            content={widget?.tooltip}
            place='right'
          />
        )}
      </div>
      {!widget?.widgetData?.error && (
        <>
          {!isSharedDashboard && (
            <WidgetCommentBtn
              widget={widget}
              showCommentBtn={showCommentBtn}
              handleEditCommentMode={() => handleEditCommentMode(widget)}
              className={widgetCommentClass}
            />
          )}
        </>
      )}
      {isEditMode && (
        <WidgetMenu
          widget={widget}
          handleEditWidget={handleEditWidget}
          handleClone={handleClone}
          onRemoveClick={onRemoveClick}
        />
      )}
    </div>
  );
}
