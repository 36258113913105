const CustomSuggestionComponent = ({ user }) => {
  return (
    <li key={user?.id} className='px-2 py-1'>
      <div className='flex items-center gap-2 lg:gap-4 xxl:gap-2'>
        <img
          src={user?.img}
          alt='person'
          className='h-10 w-10 rounded-full md:h-8 md:w-8 lg:h-6 lg:w-6 xxl:h-8 xxl:w-8'
        />
        <p className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-[1.0588rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-[1.0588rem]'>
          {user?.display}
        </p>
      </div>
    </li>
  );
};

export default CustomSuggestionComponent;
