import { Dialog, ScrollArea, ScrollBar } from '@shadcnComponent';
import { BaseLoader, DeleteDialog } from '@common/components';
import { DataSourceCard } from '@pages/dashboard';

export function DashboardGridView({
  dashboardsListing,
  dashboardsListingFetching,
  setDashboardId,
  handleDeleteDashboard,
  selectedDashboard,
  setSelectedDashboard,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteDashboardLoading,
  handleDuplicateDashboard,
  isMember,
  setDeleteDashboardLoading,
  viewType,
  page,
}) {
  return dashboardsListingFetching ? (
    <BaseLoader />
  ) : (
    <ScrollArea className='h-full overflow-y-auto lg:h-[calc(100vh-14rem)] xxl:h-[calc(100vh-18.125rem)]'>
      <div className='mt-6 grid grid-cols-18 gap-x-4 gap-y-5 pb-1 md:mt-0 md:grid-cols-19 md:gap-[1.5625rem] lg:grid-cols-20 lg:gap-3 xxl:grid-cols-17 xxl:gap-5'>
        {dashboardsListing?.data?.results.map(dashboard => (
          <DataSourceCard
            key={dashboard?.id}
            dashboard={dashboard}
            setDashboardId={setDashboardId}
            handleDeleteDashboard={handleDeleteDashboard}
            selectedDashboard={selectedDashboard}
            setSelectedDashboard={setSelectedDashboard}
            showDeleteDialog={showDeleteDialog}
            setShowDeleteDialog={setShowDeleteDialog}
            deleteDashboardLoading={deleteDashboardLoading}
            handleDuplicateDashboard={handleDuplicateDashboard}
            isMember={isMember}
            page={page}
            viewType={viewType}
          />
        ))}
      </div>
      <ScrollBar orientation='horizontal' />
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DeleteDialog
          heading={selectedDashboard?.name}
          text={selectedDashboard?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleDeleteDashboard();
            setDeleteDashboardLoading(true);
          }}
          isLoading={deleteDashboardLoading}
        />
      </Dialog>
    </ScrollArea>
  );
}
