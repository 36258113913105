/* eslint-disable indent */
import { useContext, useRef } from 'react';
import { Dialog } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  BarChartWidget,
  CounterWidget,
  CsvWidget,
  GoalWidget,
  ImageWidget,
  LineChartWidget,
  MapWidget,
  PieChartWidget,
  ProgressWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  WidgetTopHead,
} from '@common/widgets';
import { CreateEditGoalDialog } from '@pages/goalTracking';
import { AppContext } from '@useContext';
import { calculateDashboardHeight } from '@utils';
import { COLORS, DASHBOARD_VIEW_TYPE, DEFAULT_VALUE, STATIC, THEME_STYLE, WIDGETS_TYPE } from '@constants';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function DashboardWidgetsLayout({
  dashboard,
  handleGridLayoutChange,
  layoutWidth,
  setLayoutWidth,
  isEditMode,
  isGoalTrackingMode,
  isEditWidgetMode,
  removeLayoutWidget,
  handleEditWidget,
  handleClone,
  compareRangeSet,
  dateRangeSet,
  isFilterApply,
  page,
  handleTablePagination,
  handleVideoInputChange,
  handleImageInputChange,
  widgetImageRef,
  handleCsvInputChange,
  csvRef,
  dropZonePosition,
  droppedWidget,
  editWidget,
  selectSliderWidget,
  isUploadedWidgetCsv,
  isUploadedWidgetImage,
  handleEditCommentMode,
  csvPage,
  isCommentMode,
  showGoalDialog,
  setShowGoalDialog,
  createFormik,
  dashboardsListing,
  widgetListing,
  createGoalLoading,
  userOrganizationRole,
}) {
  const dashboardLayoutRef = useRef(null);

  const { screenHeight } = useContext(AppContext);

  const isNormalView = dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.NORMAL;
  const dashboardWidgetsLayout = isNormalView
    ? dashboard?.data?.dashboardConfig
    : dashboard?.data?.dashboardConfig?.length > 0
      ? dashboard?.data?.dashboardConfig[selectSliderWidget]
      : [];

  return (
    <div
      className={`m-2 w-full ${!isNormalView && (isEditMode || isGoalTrackingMode) ? 'overflow-y-auto overflow-x-hidden lg:h-[calc(100vh-120px)] xxl:h-[calc(100vh-10.625rem)]' : ''}`}
    >
      <div
        ref={dashboardLayoutRef}
        className={`${dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES ? `!h-[calc(100vh-14.6rem)] ${isEditMode || isGoalTrackingMode ? 'border border-darkPrimary' : ''}` : 'h-full'} ${dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES && !isEditMode ? 'overflow-y-hidden' : ''}`}
      >
        {(dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.NORMAL || !isEmpty(dashboard?.data?.dashboardConfig)) && (
          <ResponsiveGridLayout
            className='layout flex !h-full w-full flex-wrap items-start gap-2.5'
            layouts={{ lg: dashboardWidgetsLayout }}
            rowHeight={isNormalView ? 30 : calculateDashboardHeight(screenHeight)}
            onDragStop={handleGridLayoutChange}
            onResizeStop={handleGridLayoutChange}
            isResizable={layoutWidth < 501 ? false : isEditMode || isGoalTrackingMode}
            isDraggable={layoutWidth < 501 ? false : isEditMode || isEditWidgetMode || isGoalTrackingMode}
            cols={isNormalView ? { lg: 12, md: 12, sm: 9, xs: 6, xxs: 1 } : { lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
            breakpoints={{ lg: 1440, md: 1024, sm: 768, xs: 500, xxs: 0 }}
            onWidthChange={w => setLayoutWidth(w)}
            margin={{ xxs: [0, 16] }}
          >
            {dashboardWidgetsLayout?.map((widget, index) => (
              <div key={widget?.i} className={`${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'text-widget' : ''}`}>
                <div
                  className={`group h-full w-full overflow-hidden overflow-y-auto border border-widgetBorderColor px-4 py-5 shadow-[0_5px_20px_0_rgba(0,0,0,0.05)] scrollbar-none ${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'rounded-[0.375rem] px-6 py-4' : ''} ${editWidget?.i === widget?.i ? '!border-2 !border-darkPrimary' : ''}`}
                  style={
                    widget?.themeColor === DEFAULT_VALUE
                      ? {
                          backgroundColor:
                            widget.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.WHITE_COLOR,
                        }
                      : { backgroundColor: widget?.background }
                  }
                >
                  <div className='h-full w-full'>
                    {![STATIC].includes(widget?.connectorName) && (
                      <WidgetTopHead
                        dashboardAccess={dashboard?.data?.canEdit && isEditMode}
                        widget={widget}
                        onRemoveClick={removeLayoutWidget}
                        handleEditWidget={handleEditWidget}
                        handleClone={handleClone}
                        showCommentBtn={isEditMode || isEditWidgetMode || isCommentMode || isGoalTrackingMode}
                        handleEditCommentMode={handleEditCommentMode}
                        widgetCommentClass='group-hover:flex'
                        showGoalDialog={showGoalDialog}
                        setShowGoalDialog={setShowGoalDialog}
                        userOrganizationRole={userOrganizationRole}
                      />
                    )}
                    {widget?.widgetData?.error ? (
                      <p
                        className='text-md flex h-[calc(100%-3.4375rem)] w-full items-center justify-center font-bold'
                        style={
                          widget?.themeColor === DEFAULT_VALUE
                            ? {
                                color:
                                  widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                              }
                            : {
                                color:
                                  widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
                              }
                        }
                      >
                        {widget?.widgetData?.error}
                      </p>
                    ) : (
                      <>
                        {widget?.id?.includes(WIDGETS_TYPE.GOAL) && (
                          <div className='h-[calc(100%-3.4375rem)] w-full'>
                            <GoalWidget widget={widget} />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.COUNTER) && (
                          <div className='h-[calc(100%-3.4375rem)] w-full'>
                            <CounterWidget
                              index={index}
                              value={widget?.widgetData?.data}
                              widget={widget}
                              isComparisonFilterApply={
                                ((!!compareRangeSet || !!dateRangeSet) && isFilterApply) ||
                                widget?.isWidgetComparisonFilterApply
                              }
                            />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.BAR_CHART) && (
                          <div className='h-[calc(100%-3.4375rem)] w-full'>
                            <BarChartWidget
                              widget={widget}
                              isWidgetComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                            />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.LINE_CHART) && (
                          <div className='h-[calc(100%-3.4375rem)] w-full'>
                            <LineChartWidget
                              widget={widget}
                              isWidgetComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                            />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.PIE_CHART) && (
                          <div className='flex h-[calc(100%-3.4375rem)] w-full flex-col'>
                            <PieChartWidget
                              widget={widget}
                              isComparisonFilterApply={
                                ((!!compareRangeSet || !!dateRangeSet) && isFilterApply) ||
                                widget?.isWidgetComparisonFilterApply
                              }
                              isWidgetComparisonFilterApply={widget.isWidgetComparisonFilterApply}
                            />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.PROGRESS_BAR) && (
                          <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto'>
                            <ProgressWidget
                              widget={widget}
                              isComparisonFilterApply={
                                ((!!compareRangeSet || !!dateRangeSet) && isFilterApply) ||
                                widget?.isWidgetComparisonFilterApply
                              }
                            />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.TABLE) && (
                          <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto'>
                            <TableWidget widget={widget} page={page} handleTablePagination={handleTablePagination} />
                          </div>
                        )}
                        {widget?.id?.includes(WIDGETS_TYPE.MAP) && <MapWidget widget={widget} />}
                      </>
                    )}
                    {widget?.id?.includes(WIDGETS_TYPE.TEXT) && (
                      <TextWidget
                        widget={widget}
                        onRemoveClick={removeLayoutWidget}
                        isEditMode={isEditMode}
                        handleEditWidget={handleEditWidget}
                        handleClone={handleClone}
                        showCommentBtn={isEditMode || isEditWidgetMode || isCommentMode}
                        handleEditCommentMode={handleEditCommentMode}
                        widgetCommentClass='group-hover:flex'
                      />
                    )}
                    {widget?.id?.includes(WIDGETS_TYPE.VIDEO) && (
                      <VideoWidget
                        widget={widget}
                        onRemoveClick={removeLayoutWidget}
                        handleVideoInputChange={handleVideoInputChange}
                        isEditMode={isEditMode}
                        handleEditWidget={handleEditWidget}
                        handleClone={handleClone}
                        showCommentBtn={isEditMode || isEditWidgetMode || isCommentMode}
                        handleEditCommentMode={handleEditCommentMode}
                        widgetCommentClass='group-hover:flex'
                      />
                    )}
                    {widget?.id?.includes(WIDGETS_TYPE.IMAGE) && (
                      <ImageWidget
                        widget={widget}
                        onRemoveClick={removeLayoutWidget}
                        handleImageInputChange={handleImageInputChange}
                        isEditMode={isEditMode}
                        handleEditWidget={handleEditWidget}
                        handleClone={handleClone}
                        widgetImageRef={widgetImageRef}
                        isUploadedWidgetImage={isUploadedWidgetImage}
                        showCommentBtn={isEditMode || isEditWidgetMode || isCommentMode}
                        handleEditCommentMode={handleEditCommentMode}
                        widgetCommentClass='group-hover:flex'
                      />
                    )}
                    {widget?.id?.includes(WIDGETS_TYPE.CSV) && (
                      <CsvWidget
                        widget={widget}
                        onRemoveClick={removeLayoutWidget}
                        handleCsvInputChange={handleCsvInputChange}
                        isEditMode={isEditMode}
                        handleEditWidget={handleEditWidget}
                        handleClone={handleClone}
                        widgetImageRef={csvRef}
                        isUploadedWidgetCsv={isUploadedWidgetCsv}
                        page={csvPage}
                        handleTablePagination={handleTablePagination}
                        showCommentBtn={isEditMode || isEditWidgetMode || isCommentMode}
                        handleEditCommentMode={handleEditCommentMode}
                        widgetCommentClass='group-hover:flex'
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
            {dropZonePosition && (
              <div
                key='drop-zone'
                className='drop-zone'
                data-grid={{
                  x: dropZonePosition.x,
                  y: dropZonePosition.y,
                  w: [WIDGETS_TYPE.TABLE, WIDGETS_TYPE.TEXT].includes(droppedWidget?.type)
                    ? 12
                    : [WIDGETS_TYPE.PIE_CHART, WIDGETS_TYPE.MAP, WIDGETS_TYPE.CSV].includes(droppedWidget?.type)
                      ? 6
                      : 3,
                  h: WIDGETS_TYPE.TEXT === droppedWidget?.type ? 2 : 7,
                }}
              />
            )}
          </ResponsiveGridLayout>
        )}
      </div>
      <Dialog
        open={showGoalDialog}
        onOpenChange={status => {
          setShowGoalDialog(status);
          !status && createFormik.resetForm();
        }}
      >
        <CreateEditGoalDialog
          title='Create Goal'
          formik={createFormik}
          disableDashboardField={true}
          disableWidgetField={true}
          dashboardsListing={dashboardsListing}
          widgetListing={widgetListing}
          isLoading={createGoalLoading}
        />
      </Dialog>
    </div>
  );
}
