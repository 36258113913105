import { useEffect, useState } from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const TIME_OUT = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(TIME_OUT);
  }, [value, delay]);

  return debouncedValue;
}
