import { Label, Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@shadcnComponent';
import { DisplayTabSelectField } from '@pages/dashboard';
import { COLORS, DEFAULT_VALUE, THEME_COLORS_OPTIONS, THEME_STYLE, WIDGETS_TYPE } from '@constants';

export function DisplayTab({ editWidget, setEditWidget }) {
  return (
    <div className='flex flex-col gap-6'>
      {editWidget?.id?.split('-')[0] === WIDGETS_TYPE.LINE_CHART && (
        <DisplayTabSelectField
          label='Curved Lines'
          value={editWidget?.showCurvedLine}
          handleChange={value => setEditWidget({ ...editWidget, showCurvedLine: value })}
        />
      )}
      {![WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.IMAGE, WIDGETS_TYPE.TEXT, WIDGETS_TYPE.CSV].includes(
        editWidget?.id?.split('-')[0]
      ) && (
        <DisplayTabSelectField
          label='Show Integration Icon'
          value={editWidget?.showIntegrationIcon}
          handleChange={value => setEditWidget({ ...editWidget, showIntegrationIcon: value })}
        />
      )}
      {!editWidget?.id?.includes(WIDGETS_TYPE.TEXT) && (
        <DisplayTabSelectField
          label='Show Data Labels'
          value={editWidget?.showLabel}
          handleChange={value => setEditWidget({ ...editWidget, showLabel: value })}
        />
      )}
      <div className='flex flex-col gap-1.5'>
        <Label>Theme Color</Label>
        <Select
          value={editWidget?.themeColor}
          onValueChange={value => {
            const findThemeColor = THEME_COLORS_OPTIONS.find(themeColor => themeColor.value === value);
            if (findThemeColor) {
              setEditWidget({
                ...editWidget,
                themeColor: findThemeColor.value,
                foreground:
                  editWidget?.themeStyle === THEME_STYLE.LIGHT ? findThemeColor.foreground : findThemeColor.background,
                background:
                  editWidget?.themeStyle === THEME_STYLE.LIGHT ? findThemeColor.background : findThemeColor.foreground,
              });
            } else {
              setEditWidget({
                ...editWidget,
                themeColor: value,
                foreground: COLORS.DARK_PRIMARY,
                background: COLORS.WHITE_COLOR,
              });
            }
          }}
        >
          <SelectTrigger className='theme-select bg-transparent'>
            <SelectValue
              placeholder={
                <div className='relative h-10 w-11 rounded-md' style={{ backgroundColor: editWidget.background }}>
                  <span
                    className='absolute bottom-1 left-1 h-2 w-8 rounded-[1.875rem]'
                    style={{ backgroundColor: COLORS.WHITE_COLOR }}
                  ></span>
                </div>
              }
            />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup className='mx-auto flex h-[20rem] w-[16.25rem] flex-col justify-start gap-2 gap-x-4 overflow-y-auto py-5 scrollbar-none'>
              <p>Default</p>
              <div className='flex flex-wrap gap-2 gap-x-4'>
                <SelectItem value={DEFAULT_VALUE} className='h-min w-min cursor-pointer p-0'>
                  <div className='relative h-10 w-11 rounded-md border' style={{ backgroundColor: COLORS.WHITE_COLOR }}>
                    <span
                      className='absolute bottom-1 left-1 h-2 w-8 rounded-[1.875rem]'
                      style={{ backgroundColor: COLORS.DARK_PRIMARY }}
                    ></span>
                  </div>
                </SelectItem>
              </div>
              <p className='mt-3'>Social</p>
              <div className='flex flex-wrap gap-3.5'>
                {THEME_COLORS_OPTIONS.map((themeColor, index) => (
                  <SelectItem key={index} value={themeColor.value} className='h-min w-min cursor-pointer p-0'>
                    <div
                      className='relative h-10 w-10 rounded-md border'
                      style={{ backgroundColor: themeColor.foreground }}
                    >
                      <span
                        className='absolute bottom-1 left-[0.1875rem] h-2 w-8 rounded-[1.875rem]'
                        style={{ backgroundColor: themeColor.background }}
                      ></span>
                    </div>
                  </SelectItem>
                ))}
              </div>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className='flex flex-col gap-1.5'>
        <Label>Theme Style</Label>
        <Select
          value={editWidget?.themeStyle}
          onValueChange={value => {
            setEditWidget({
              ...editWidget,
              themeStyle: value,
              foreground: editWidget.background,
              background: editWidget.foreground,
            });
          }}
        >
          <SelectTrigger className='bg-transparent'>
            <SelectValue placeholder='Light' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value='light' className='px-3 py-[0.4375rem]'>
                Light
              </SelectItem>
              <SelectItem value='dark' className='px-3 py-[0.4375rem]'>
                Dark
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
