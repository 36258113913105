import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@constants';

export function BaseDatePicker({ className, date, setDate, disabled }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={'outline'} className={cn(className)} disabled={disabled}>
          {date ? format(date, DATE_FORMAT.DATE_PICKER) : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0'>
        <Calendar mode='single' selected={date} onSelect={setDate} initialFocus today={false} />
      </PopoverContent>
    </Popover>
  );
}
