import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function BaseToolTip({ id, content, placement = 'bottom-start', className }) {
  return (
    <Tooltip
      anchorSelect={`#${id}`}
      content={content}
      place={placement}
      className={`z-[100] break-all border border-lightGray !bg-white !p-1.5 text-xs font-normal leading-4 !text-darkSecondary shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)] ${className}`}
    />
  );
}
