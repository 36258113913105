export function Logo({ className }) {
  return (
    <svg
      width='170'
      height='32'
      viewBox='0 0 170 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`h-[1.625rem] w-[8.875rem] md:h-8 md:w-[11rem] lg:h-[1.375rem] lg:w-[7.5rem] xxl:h-[2.5rem] xxl:w-[10.625rem] ${className}`}
    >
      <g clipPath='url(#clip0_499_8182)'>
        <path d='M90.3469 3.66748H86.9062V19.0005H90.3469V3.66748Z' fill='url(#paint0_linear_499_8182)' />
        <path d='M84.4445 7.33057H81.0039V22.6636H84.4445V7.33057Z' fill='url(#paint1_linear_499_8182)' />
        <path
          d='M0 11.178H14.9836L0 27.9472V31.0999H19.3996V27.9472H4.3699L19.3996 11.178V8.06763H0V11.178Z'
          fill='black'
        />
        <path
          d='M131.934 14.1198C131.934 12.2728 133.616 10.9288 136.43 10.9288C139.502 10.9288 141.265 12.6069 141.422 14.9185H145.262C145.005 10.4219 141.645 7.68018 136.557 7.68018C131.469 7.68018 128.109 10.5794 128.109 14.1083C128.109 22.683 141.726 19.6993 141.726 24.8257C141.726 26.7188 140.044 28.2318 137.022 28.2318C133.785 28.2318 131.807 26.5499 131.6 24.3227H127.648C127.898 28.5236 131.68 31.465 137.06 31.465C142.148 31.465 145.47 28.6081 145.47 24.8257C145.297 16.6005 131.934 19.5841 131.934 14.1198Z'
          fill='black'
        />
        <path
          d='M123.538 19.584V8.04102H119.932V11.209C118.294 9.6558 116.237 8.61675 114.015 8.2204C111.793 7.82405 109.504 8.08779 107.43 8.97899C105.357 9.87019 103.59 11.3498 102.348 13.2346C101.107 15.1195 100.445 17.327 100.445 19.584C100.445 21.841 101.107 24.0485 102.348 25.9334C103.59 27.8183 105.357 29.2978 107.43 30.189C109.504 31.0802 111.793 31.344 114.015 30.9476C116.237 30.5513 118.294 29.5122 119.932 27.959V31.127H123.538V19.584ZM111.995 27.5251C110.424 27.5259 108.888 27.0608 107.582 26.1887C106.275 25.3165 105.257 24.0766 104.655 22.6256C104.054 21.1746 103.896 19.5778 104.202 18.0371C104.508 16.4965 105.264 15.0811 106.374 13.9702C107.485 12.8592 108.9 12.1025 110.44 11.7958C111.981 11.4891 113.578 11.6461 115.029 12.247C116.48 12.848 117.721 13.8658 118.593 15.1718C119.466 16.4778 119.932 18.0133 119.932 19.584C119.932 21.6895 119.096 23.7087 117.607 25.1979C116.119 26.687 114.1 27.5241 111.995 27.5251Z'
          fill='black'
        />
        <path
          d='M59.7164 7.64941C56.606 7.64941 53.8335 8.95117 52.3168 11.3473V8.04109H48.7148V31.1271H52.3168V18.3668C52.3168 13.4056 55.0047 10.971 58.9177 10.971C62.8306 10.971 65.3458 13.3633 65.3458 18.0711V31.104H69.1205V17.5259C69.1205 10.9249 65.054 7.64941 59.7164 7.64941Z'
          fill='black'
        />
        <path
          d='M160.587 7.64925C157.477 7.64925 154.705 8.95101 153.192 11.3472V0H149.586V31.1269H153.192V18.3667C153.192 13.4054 155.88 10.9708 159.789 10.9708C163.698 10.9708 166.217 13.3632 166.217 18.071V31.1039H170.003V17.5257C170.003 10.9248 165.925 7.64925 160.587 7.64925Z'
          fill='black'
        />
        <path
          d='M33.0571 8.04107C30.9488 8.03921 28.8802 8.61481 27.0759 9.7054C25.2716 10.796 23.8004 12.3599 22.822 14.2275C21.8437 16.095 21.3955 18.1949 21.5261 20.2991C21.6567 22.4034 22.3611 24.4317 23.5629 26.1639C24.7647 27.8962 26.418 29.2662 28.3433 30.1253C30.2687 30.9844 32.3925 31.2998 34.4844 31.0372C36.5763 30.7746 38.5563 29.9442 40.2096 28.6359C41.863 27.3276 43.1264 25.5916 43.8628 23.6161H39.8615C39.0368 25.0263 37.7948 26.1459 36.3069 26.8206C34.819 27.4952 33.1585 27.6917 31.5542 27.3829C29.95 27.0741 28.481 26.2752 27.3499 25.0964C26.2188 23.9176 25.4812 22.417 25.2389 20.8013H44.5463C44.7208 19.1902 44.5534 17.5605 44.0551 16.0185C43.5568 14.4765 42.7387 13.057 41.6544 11.8527C40.5701 10.6484 39.2439 9.68642 37.7624 9.02963C36.281 8.37283 34.6776 8.03597 33.0571 8.04107ZM25.4232 17.522C25.8692 15.8372 26.86 14.3472 28.2411 13.2841C29.6222 12.221 31.3161 11.6446 33.059 11.6446C34.8019 11.6446 36.4958 12.221 37.8769 13.2841C39.2581 14.3472 40.2488 15.8372 40.6948 17.522H25.4232Z'
          fill='black'
        />
        <path
          d='M92.7276 11.2204V19.5839C92.7286 20.855 92.4245 22.1077 91.8408 23.2368C91.2571 24.3659 90.4109 25.3384 89.3733 26.0726C88.3357 26.8067 87.1371 27.2811 85.8781 27.4558C84.6191 27.6305 83.3366 27.5005 82.1383 27.0766C80.94 26.6527 79.8609 25.9474 78.9918 25.0199C78.1228 24.0924 77.489 22.9698 77.1439 21.7465C76.7987 20.5232 76.7522 19.2349 77.0083 17.99C77.2645 16.745 77.8157 15.5797 78.6157 14.5919V9.86108C77.2728 10.7287 76.1253 11.8664 75.2461 13.2017C74.3668 14.537 73.7752 16.0407 73.5088 17.6171C73.2423 19.1936 73.3069 20.8082 73.6985 22.3583C74.09 23.9084 74.7999 25.36 75.783 26.6208C76.7661 27.8815 78.0009 28.9239 79.4088 29.6815C80.8167 30.4391 82.3668 30.8953 83.9606 31.0212C85.5545 31.147 87.157 30.9398 88.6664 30.4126C90.1757 29.8854 91.5588 29.0498 92.7276 27.9589V31.1269H96.3334V0H92.7276V11.2204Z'
          fill='black'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_499_8182'
          x1='90.5082'
          y1='6.58587'
          x2='83.7344'
          y2='23.6661'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_499_8182'
          x1='86.664'
          y1='5.06113'
          x2='79.8903'
          y2='22.1414'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <clipPath id='clip0_499_8182'>
          <rect width='170' height='31.4764' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
