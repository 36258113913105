import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useGetQuery, usePatchMutation } from '@services';
import {
  FIELD_REQUIRED,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  PERMISSION_DROP_DOWN_OPTIONS,
  SUCCESSFUL_MESSAGE,
} from '@constants';

export function useEditTeam() {
  const scrollToRef = useRef(null);

  const { organizationId } = useParams();

  const [teamId, setTeamId] = useState(null);
  const [showEditTeamDialog, setShowEditTeamDialog] = useState(false);
  const [isEditTeamLoading, setIsEditTeamLoading] = useState(false);

  const { refetch: teamsListingRefetch } = useGetQuery(
    'team-listing',
    apiEndpoints.ORGANIZATIONS_TEAMS(organizationId),
    { page: 1 }
  );

  const { data: organizationTeamDetail, refetch: organizationTeamRefetch } = useGetQuery(
    'organizationTeam-details',
    apiEndpoints.GET_TEAM_DETAILS(organizationId, teamId),
    {},
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const teamInitialValues = {
    teamName: organizationTeamDetail?.data?.name || '',
    permission:
      PERMISSION_DROP_DOWN_OPTIONS.find(option => option.id === organizationTeamDetail?.data?.permission) || '',
    dashboards: organizationTeamDetail?.data?.dashboards || [],
    members: organizationTeamDetail?.data?.users || [],
  };

  const teamValidationSchema = Yup.object({
    teamName: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Team name'))
      .max(60, MAX_LENGTH_REQUIRED('Team name'))
      .required(FIELD_REQUIRED('Team name')),
    permission: Yup.object().when('dashboards', {
      is: value => isEmpty(value),
      then: rule => rule.notRequired(),
      otherwise: rule => rule.required(FIELD_REQUIRED('Permission')),
    }),
  });

  const handleSubmit = () => {
    setIsEditTeamLoading(true);
    let payload = {
      name: values.teamName,
      permission: values.permission['id'],
      dashboards: values.dashboards.map(dashboard => dashboard.id),
      members: values.members.map(member => member.id),
    };

    organizationTeamDetail?.data?.name === values?.teamName && delete payload['name'];
    isEmpty(values.permission['id']) && delete payload['permission'];
    isEmpty(values.dashboards) && delete payload['dashboards'];
    isEmpty(values.members) && delete payload['members'];

    handleEditTeam({ payload });
  };
  const { ...formik } = useFormikForm(teamInitialValues, handleSubmit, teamValidationSchema);

  const { values, resetForm, setErrors, isSubmitting, errors } = formik;

  const openEditTeamDialog = () => {
    setShowEditTeamDialog(true);
    resetForm();
    setIsEditTeamLoading(false);
  };

  const { mutate: handleEditTeam } = usePatchMutation(
    apiEndpoints.EDIT_TEAM(organizationId, teamId),
    () => {
      teamsListingRefetch();
      setShowEditTeamDialog(false);
      organizationTeamRefetch();
      resetForm();
      toast.success(SUCCESSFUL_MESSAGE('Team edit'));
    },
    ({ response: { data } }) => {
      setIsEditTeamLoading(false);
      setErrors({
        teamName: data.name[0] && data.name[0],
      });
      setErrors({
        permission: data.permission[0] && data.permission[0],
      });
      setErrors({
        dashboards: data.dashboards[0] && data.dashboards[0],
      });
      setErrors({
        members: data.members[0] && data.members[0],
      });
    }
  );

  useEffect(() => {
    teamId && organizationTeamRefetch();
  }, [teamId]);

  useEffect(() => {
    if (isSubmitting && !isEmpty(errors)) {
      let teamDialog = document.querySelector('#teamDialog');
      let errorField = teamDialog.querySelector(`#${Object.keys(errors)[0]}`);
      let coordinates = errorField.getBoundingClientRect();

      scrollToRef.current.scrollTo({
        top: coordinates.top - 80,
        behavior: 'smooth',
      });
    }
  }, [isSubmitting, errors]);

  return {
    formik,
    openEditTeamDialog,
    isEditTeamLoading,
    scrollToRef,
    setTeamId,
    organizationTeamRefetch,
    organizationTeamDetail,
    showEditTeamDialog,
    setShowEditTeamDialog,
    setIsEditTeamLoading,
  };
}
