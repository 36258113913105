export function Google({ className }) {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_499_8222)'>
        <path
          d='M4.9458 12.2178C4.73111 11.9137 4.5433 11.5841 4.3872 11.2314C3.7655 9.82603 3.75599 8.18647 4.39889 6.74223C3.62827 6.14106 2.87736 5.52003 2.01592 4.92287C1.8792 4.82354 1.74731 4.72638 1.61726 4.62988C1.25232 5.301 0.965509 6.03522 0.757496 6.83205C0.631119 7.31602 0.593891 7.82336 0.514926 8.31969C0.506746 8.37061 0.494559 8.42102 0.484375 8.47161V9.5267C0.530452 9.86743 0.56167 10.211 0.624775 10.5486C0.922771 12.142 1.54614 13.5135 2.48788 14.6641C3.27368 13.8107 4.0847 12.9824 4.94597 12.2178H4.9458Z'
          fill='#FBBC05'
        />
        <path
          d='M13.1098 13.2325C12.3666 13.8702 11.4178 14.3498 10.3085 14.5103C8.18693 14.8171 6.14453 13.9148 4.94637 12.2178C4.0851 12.9824 3.27409 13.8106 2.48828 14.664C3.13987 15.46 3.94387 16.1503 4.89779 16.7348C6.10012 17.4715 7.41782 17.8743 8.8265 17.9737C8.87224 17.9769 8.91698 17.9927 8.96222 18.0027H10.0175C10.3589 17.9567 10.7026 17.9228 11.0415 17.8625C12.5605 17.5925 13.8981 16.9934 15.053 16.0739C14.5639 15.284 14.0123 14.5523 13.4689 13.7373C13.3515 13.5515 13.2285 13.3942 13.1098 13.2323V13.2325Z'
          fill='#34A853'
        />
        <path
          d='M16.2393 14.9462C17.5141 13.5097 18.2591 11.8249 18.4404 9.91534C18.5226 9.05007 18.4932 8.17428 18.5147 7.29248H9.50391V10.7127H14.7718C14.5199 11.6003 13.9445 12.5165 13.1096 13.2329C13.2283 13.395 13.3513 13.5521 13.4687 13.7379C14.0121 14.5529 14.5637 15.2846 15.0528 16.0744C15.4723 15.7404 15.868 15.3646 16.2391 14.9462H16.2393Z'
          fill='#4285F4'
        />
        <path
          d='M4.39882 6.74245C4.72085 6.01892 5.20649 5.34446 5.86125 4.77301C7.55306 3.29655 10.5031 2.77919 12.8909 4.57184C13.5829 3.67335 14.2749 2.77485 14.9921 1.8438C14.5657 1.57686 14.1616 1.29723 13.7332 1.06134C12.8596 0.580202 11.9218 0.264844 10.937 0.112591C9.08712 -0.173552 7.29747 0.04982 5.61384 0.880034C3.93671 1.70708 2.65408 2.86651 1.77462 4.35248C1.72053 4.4438 1.66811 4.53628 1.61719 4.63011C1.74724 4.7266 1.87912 4.82359 2.01585 4.92309C2.87728 5.52042 3.62803 6.14129 4.39882 6.74245Z'
          fill='#EA4335'
        />
      </g>
      <defs>
        <clipPath id='clip0_499_8222'>
          <rect width='18.03' height='18.009' fill='white' transform='translate(0.484375 -0.00585938)' />
        </clipPath>
      </defs>
    </svg>
  );
}
