import {
  Button,
  DialogContent,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { FormInput, FormSelect, FormTextArea, Loader } from '@common/components';
import { restrictedCharacterOnNumberField } from '@utils';
import { GOAL_CONDITION, TIME_PERIOD } from '@constants';

export function CreateEditGoalDialog({
  title,
  formik,
  dashboardsListing,
  widgetListing,
  isLoading,
  setSelectedDashboardId,
  disableDashboardField,
  disableWidgetField,
}) {
  const { values, handleChange, setFieldValue, errors, touched, submitForm } = formik;

  return (
    <DialogContent className='max-h-[85vh] w-[calc(100vw-2.5rem)] max-w-[24.375rem] overflow-y-auto px-5 py-6 md:max-w-[40.0625rem] md:px-16 md:py-[1.875rem] lg:max-w-[35.8125rem] lg:p-[1.875rem] lg:pb-6 xxl:max-w-[40.0625rem] xxl:px-16 xxl:py-[1.875rem]'>
      <div className='flex flex-col gap-4 border-b border-lightGray pb-6 md:pb-[1.875rem] lg:pb-[0.9375rem] xxl:gap-4 xxl:pb-[1.875rem]'>
        <h2 className='mb-2 text-lg font-bold leading-[1.375rem] md:text-2xl md:leading-7 xxl:mb-2 xxl:text-2xl xxl:leading-7'>
          {title}
        </h2>
        <FormInput
          id='name'
          labelText='Goal Name'
          placeholder='Add goal name'
          value={values.name}
          handleChange={handleChange}
          showError={touched.name && errors.name}
          className='gap-1.5'
          labelClassName='text-sm'
          inputClassName='h-10'
        />
        <FormSelect
          labelText='Dashboard'
          placeholder='Select dashboard'
          handleChange={value => {
            setFieldValue('dashboard', value);
            setSelectedDashboardId(value?.id);
          }}
          options={dashboardsListing?.data?.results}
          value={values?.dashboard}
          showError={touched.dashboard && errors.dashboard}
          triggerClassName={values?.dashboard ? '' : 'custom-select'}
          optionPlaceholder={'No dashboard'}
          disabled={disableDashboardField}
        />
        <FormSelect
          labelText='Time Period'
          placeholder='Month'
          handleChange={value => setFieldValue('timePeriod', value)}
          options={TIME_PERIOD}
          value={values?.timePeriod}
          showError={touched.timePeriod && errors.timePeriod}
          triggerClassName={values?.timePeriod ? '' : 'custom-select'}
        />
        <FormTextArea
          id='description'
          labelText='Description'
          placeholder='Enter description'
          textAreaClassName='min-h-[6.25rem] max-h-[6.25rem] md:min-h-[4.875rem] md:max-h-[4.875rem] lg:min-h-[3.5625rem] lg:max-h-[3.5625rem] xxl:max-h-[4.875rem] xxl:min-h-[4.875rem]'
          value={values.description}
          handleChange={e => setFieldValue('description', e?.target?.value)}
          showError={touched.description && errors.description}
        />
        <div className='flex flex-col gap-1.5'>
          <Label className='relative w-fit text-sm font-medium leading-5'>Widget</Label>
          <Select
            disabled={!values?.dashboard?.id || disableWidgetField}
            onValueChange={value => setFieldValue('widget', value)}
          >
            <SelectTrigger
              className={`h-10 w-full lg:h-8 xxl:h-10 ${touched.widget && errors.widget ? 'border-errorColor' : ''} ${values?.widget ? '' : 'custom-select'}`}
            >
              <SelectValue placeholder={isEmpty(values?.widget) ? 'Select widget' : values?.widget?.name} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {isEmpty(widgetListing?.data?.results) ? (
                  <SelectLabel className='text-xs font-normal leading-[0.9075rem] text-mediumGray'>
                    No widget
                  </SelectLabel>
                ) : (
                  widgetListing?.data?.results?.map(item => (
                    <SelectItem key={item?.widgetId} value={item}>
                      {item?.widgetLabel}
                    </SelectItem>
                  ))
                )}
              </SelectGroup>
            </SelectContent>
          </Select>
          {!!touched.widget && !!errors.widget && (
            <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>
              {touched.widget && errors.widget}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-3 md:flex-row'>
          <FormSelect
            labelText='Condition'
            placeholder='Greater than or equal to'
            handleChange={value => setFieldValue('condition', value)}
            options={GOAL_CONDITION}
            value={values?.condition}
            showError={touched.condition && errors.condition}
            triggerClassName={values?.condition ? '' : 'custom-select'}
            className='w-full md:w-[15.625rem] xxl:w-[15.625rem]'
          />
          <FormInput
            id='metric'
            labelText='Metric'
            placeholder='15000'
            type='number'
            onKeyDown={restrictedCharacterOnNumberField}
            className='w-full md:w-[15.625rem] xxl:w-[15.625rem]'
            inputClassName='h-10'
            value={values.metric}
            handleChange={handleChange}
            showError={touched.metric && errors.metric}
          />
        </div>
      </div>
      <div className='mt-6 flex justify-end md:mt-4'>
        <Button
          className='h-10 w-full md:w-[11.25rem] lg:h-8 lg:w-[134px] xxl:h-10 xxl:w-[11.25rem]'
          disabled={isLoading}
          onClick={submitForm}
        >
          {title === 'Edit Goal' ? 'Save' : 'Create'} {isLoading && <Loader />}
        </Button>
      </div>
    </DialogContent>
  );
}
