import { useContext } from 'react';
import { ScrollArea } from '@shadcnComponent';
import { BaseLoader } from '@common/components';
import { useStaticWidgets } from '@pages/dashboard';
import { AppContext } from '@useContext';
import { WidgetsBulletIcon } from '@assets/svgs';

export function DashboardStaticWidgets({ handleOnDrag, isEditMode, AddWidgetsOnClick }) {
  const { staticWidgets, staticWidgetsFetching } = useStaticWidgets(isEditMode);
  const { isMobileScreen } = useContext(AppContext);

  return (
    <ScrollArea className='h-[calc(100vh-22rem)]'>
      {staticWidgetsFetching ? (
        <BaseLoader />
      ) : (
        <div className='flex flex-col gap-6 pb-4 lg:gap-3 lg:pb-3 xxl:gap-6 xxl:pb-4'>
          {staticWidgets?.data?.results.map(widget => (
            <div
              key={widget?.id}
              className='flex cursor-grab gap-3 border-b pb-4 last-of-type:border-b-0 md:gap-4 md:pb-6 lg:gap-2 lg:pb-3 xxl:gap-3 xxl:pb-6'
              draggable
              onDragStart={e => handleOnDrag(e, widget)}
              onClick={() => isMobileScreen && AddWidgetsOnClick(widget)}
            >
              <div className='mt-0 md:mt-0'>
                <div className='h-5 w-5 md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4'>
                  <WidgetsBulletIcon />
                </div>
              </div>
              <div className='flex flex-col gap-2 xxl:gap-2'>
                <div className='flex gap-2 lg:gap-1'>
                  <span className='h-6 w-6 md:h-5 md:w-5 lg:h-[.875rem] lg:w-[.875rem] xxl:h-5 xxl:w-5'>
                    <img src={widget?.icon?.image} alt='' className='h-full w-full' />
                  </span>
                  <h3 className='text-base font-semibold leading-5 lg:text-xs lg:leading-[.875rem] xxl:text-base xxl:leading-5'>
                    {widget?.type}
                  </h3>
                </div>
                <p className='max-w-[15.625rem] truncate text-xs font-light leading-[.875rem] lg:text-[.625rem] lg:leading-3 xxl:text-xs xxl:leading-[.875rem]'>
                  {widget?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </ScrollArea>
  );
}
