export function PlusIconDark({ className }) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <path d='M8 3.33301V12.6663' stroke='#00ACE7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.33203 8H12.6654' stroke='#00ACE7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
