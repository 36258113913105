import { useRef } from 'react';
import { Sheet, SheetClose, SheetContent, SheetTrigger, Button } from '@shadcnComponent';
import { useNotification } from '@common/hooks';
import { calculateDueDate, doInfiniteScroll } from '@utils';
import { ArrowLeft, NotificationIcon } from '@assets/svgs';

export function NotificationSheet({ strokeColor }) {
  const {
    notificationListing,
    notificationListingFetching,
    notificationListingNextPage,
    notificationListingHasNextPage,
  } = useNotification();

  const scrollContainerRef = useRef();

  const handleScroll = e =>
    doInfiniteScroll(e, notificationListingHasNextPage, notificationListingFetching, notificationListingNextPage);

  return (
    <Sheet>
      <SheetTrigger asChild className='md:hidden'>
        <Button variant='ghost' className='h-fit p-0'>
          <NotificationIcon strokeColor={strokeColor} />
        </Button>
      </SheetTrigger>
      <SheetContent
        closeIconStyle='hidden'
        className='w-[21.25rem] p-0 md:hidden'
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <div className='flex items-center gap-4 pb-4 pl-5 pt-[1.875rem] text-2xl font-bold leading-[1.8156rem]'>
          <SheetClose asChild>
            <Button variant='ghost' className='h-fit w-6 p-0'>
              <ArrowLeft className='h-6 w-6' />
            </Button>
          </SheetClose>
          Notifications
        </div>
        {notificationListing?.pages?.map(page =>
          page?.data?.results?.map(notification => (
            <div key={notification.id} className='flex items-start gap-2.5 border-b border-lightGray px-5 py-4'>
              <img src={notification?.senderImage?.image} alt='sender-avatar' className='h-10 w-10 rounded-full' />
              <div>
                <p className='text-sm font-normal leading-[1.0588rem]'>{notification.body}</p>
                <span className='text-xs font-normal leading-[0.9075rem] text-steelBlue'>
                  {calculateDueDate(notification.created)}
                </span>
              </div>
            </div>
          ))
        )}
      </SheetContent>
    </Sheet>
  );
}
