import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { setLocalStorageItem } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function useShopifyInstall() {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const shopValue = queryParams.get('shop');
  const hmacValue = queryParams.get('hmac');
  const hostValue = queryParams.get('host');
  const timestampValue = queryParams.get('timestamp');

  useGetQuery(
    'webhook-shopify-install',
    apiEndpoints.SHOPIFY_INSTALL,
    { shop: shopValue, hmac: hmacValue, host: hostValue, timestamp: timestampValue },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        let payload = {
          shop: shopValue,
          connectorName: UPPERCASE_DATA_SOURCES.SHOPIFY,
        };

        !isEmpty(payload) && installShopifyConnector({ payload });
      },
    }
  );

  const { mutate: installShopifyConnector } = usePostMutation(
    apiEndpoints.DATA_SOURCES_OAUTH,
    resp => {
      window.location.href = resp?.data?.url;
      setLocalStorageItem('showConnector', {
        2: true,
        dataSource: {
          id: 2,
          sourceName: 'SHOPIFY',
          description: 'Shopify shop connector',
          logo: {
            id: 540,
            image: 'https://stage.zendash.com/django-media/images/2024/03/shopify-logo-png-transparent.png',
          },
        },
      });
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  return { shopValue };
}
