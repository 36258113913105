import { useState } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Input,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '@shadcnComponent';
import { Expand, Share2, SquarePen, Trash2, FileText, Ellipsis, Save } from 'lucide-react';
import { DatePicker, DeleteDialog, FormInput, ImageCrop, ImageUpload, TourButton } from '@common/components';
import { AddDataSourceDialog, DashboardComments, SaveTemplateDialog } from '@pages/dashboard';
import { ROUTES } from '@routes';
import { fileResize, handleSave, setLocalStorageItem, useAppTours } from '@utils';
import { ADD_WIDGETS_BUTTON_ID, COLORS, EDIT_DASHBOARD_BUTTON_ID, ROLES } from '@constants';
import {
  ArrowLeft,
  CommentsIcon,
  CopyIcon,
  CrossIcon,
  EditIconWhite,
  GoalTrackingDarkIcon,
  ImageUploaderIcon,
} from '@assets/svgs';

export function DashboardHeader({
  logoRef,
  uploadedLogo,
  setIsUploadedLogo,
  isUploadedLogo,
  dashboardName,
  setDashboardName,
  isCommentMode,
  isEditMode,
  dashboard,
  handleDeleteDashboard,
  setShowDeleteDialog,
  showDeleteDialog,
  setDeleteDashboardLoading,
  deleteDashboardLoading,
  showAddDataSourceDialog,
  setShowAddDataSourceDialog,
  addedDataSource,
  handleSelectedDataSource,
  addDataSourceListing,
  selectedDataSourceIds,
  handleAddDataSource,
  addDataSourceLoading,
  setIsEditMode,
  setIsCommentMode,
  setIsEditWidgetMode,
  setEditWidget,
  setIsDateCleared,
  dateRange,
  handleDateRange,
  handleRangeDateFilter,
  showDateTimeFilter,
  setShowDateTimeFilter,
  handleClearRangeDateFilter,
  dateRangeSet,
  handleSelectDateRange,
  dateRangeStart,
  setDateRangeStart,
  dateRangeEnd,
  setDateRangeEnd,
  compareRangeSet,
  handleSelectCompareRange,
  compareRangeEnd,
  compareRangeStart,
  setCompareRangeEnd,
  setCompareRangeStart,
  commentsListing,
  formik,
  createCommentLoading,
  scrollContainerRef,
  handleScroll,
  shouldRenderComponent,
  resetForm,
  setIsCopied,
  shareDashboardUrlRefetch,
  shareDashboardUrlFetching,
  copyToClipboard,
  isCopied,
  sharedDashboardURL,
  organizationId,
  dashboardId,
  selectSliderWidget,
  handleLogoUpload,
  setCommentedWidget,
  isEditWidgetMode,
  saveAsTemplateLoading,
  showTemplateDialog,
  setShowTemplateDialog,
  templateCategory,
  templateCategoryFetching,
  templateCategoryNextPage,
  templateCategoryHasNextPage,
  isGoalTrackingMode,
  setIsGoalTrackingMode,
  page,
  viewType,
  saveAsTemplateFormik,
  userOrganizationRole,
}) {
  const { addWidgetTour } = useAppTours();

  const navigate = useNavigate();

  const [croppedImage, setCropedImage] = useState();

  const getFile = async file => {
    const resizedFile = await fileResize(file, 300, 300);
    setCropedImage(resizedFile);
    setIsUploadedLogo(true);
    return resizedFile;
  };

  return (
    <div className='flex flex-col items-center justify-between gap-0 border-b border-b-lightGray bg-white px-[1.25rem] py-6 md:flex-col md:p-8 lg:flex-row lg:px-8 lg:py-4 xxl:px-10 xxl:py-6'>
      <div className='mb-6 flex w-full items-center justify-start gap-3 md:mb-8 md:w-full md:justify-start md:gap-5 lg:mb-0 lg:gap-3 xxl:gap-4'>
        <span
          onClick={() => {
            navigate(generatePath(ROUTES.DASHBOARD, { organizationId }), {
              state: { page, viewType },
            });
            setLocalStorageItem('dashboardCurrentPage', page);
            setLocalStorageItem('dashboardViewType', viewType);
          }}
          className='ml-1 h-3 w-3 cursor-pointer md:ml-0 md:h-4 md:w-4 lg:h-3.5 lg:w-3.5 xxl:h-5 xxl:w-5'
        >
          <ArrowLeft />
        </span>
        <ImageUpload
          id='logo'
          imageRef={logoRef}
          placeHolderIcon={<ImageUploaderIcon />}
          className='h-[3.125rem] w-[3.125rem] md:h-[3.75rem] md:w-[3.75rem] lg:h-8 lg:w-8 xxl:h-[3.125rem] xxl:w-[3.125rem]'
          profileImage={uploadedLogo}
          handleChange={e => getFile(e.target.files[0])}
          showSkeletonLoader={isUploadedLogo}
          icon={isEditMode ? <EditIconWhite /> : ''}
          inputWrapperClassName={`xxl:w-5 xxl:h-5 lg:w-3 lg:h-3 md:h-6 md:w-6 h-5 w-5 ${!isEditMode ? 'hidden' : ''}`}
          editIconClassName='h-2 w-2 md:h-[.625rem] md:w-[.625rem] lg:w-[.375rem] lg:h-[.375rem]'
        />
        <div className='hidden md:block md:w-full lg:w-[10.625rem] xxl:w-[17.3125rem]'>
          <Input
            id='dashboardName'
            value={dashboardName}
            placeholder='Dashboard Name'
            className={`${dashboardName.length > 40 ? ' outline-errorColor focus:border-errorColor focus:outline-2' : 'focus:border-darkPrimary'} ${!isEditMode ? 'border-0 bg-white' : ''} w-full rounded-md border border-gainsboro md:h-[3.75rem] md:px-3 md:py-[1.125rem] md:text-2xl md:leading-6 lg:h-8 lg:p-[.5313rem] lg:text-xs lg:leading-[.875rem] ${dashboardName.length > 40 ? 'rounded-md border border-errorColor' : ''}`}
            disabled={!isEditMode}
            onChange={e => setDashboardName(e.target.value)}
          />
          {dashboardName.length > 40 && (
            <span className='text-xs text-errorColor'>Dashboard names must be 40 characters or less</span>
          )}
        </div>
        <p className='block text-xl font-semibold leading-6 md:hidden'>{dashboardName}</p>
      </div>
      <div className={`${isCommentMode ? 'gap-4 xxl:gap-12' : ''} flex w-full md:justify-end`}>
        {(isEditMode || isEditWidgetMode) && dashboard?.data?.canEdit && (
          <>
            <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
              <div className='border-r md:pr-4 lg:pr-3 xxl:pr-6'>
                <Button
                  onClick={() => setShowDeleteDialog(true)}
                  className='bg-errorColor !p-2 lg:h-8 lg:text-xs lg:leading-[.875rem] xxl:h-10'
                >
                  <Trash2 color='#fff' width={24} height={24} />
                </Button>
              </div>
              <DeleteDialog
                heading={dashboardName}
                text={dashboardName}
                className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[21.375rem] md:min-h-[16.5625rem] md:max-w-[28.125rem] lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
                deleteBtnClick={() => {
                  handleDeleteDashboard();
                  setDeleteDashboardLoading(true);
                }}
                isLoading={deleteDashboardLoading}
              />
            </Dialog>
            <Dialog open={showAddDataSourceDialog} onOpenChange={setShowAddDataSourceDialog}>
              <div className='border-r md:pr-4 lg:px-3 xxl:px-6'>
                <Button
                  onClick={() => setShowAddDataSourceDialog(true)}
                  className='md:w-[11.75rem] lg:h-8 lg:w-[7.0625rem] lg:text-xs lg:leading-[.875rem] xxl:h-10  xxl:w-40'
                >
                  Edit Data Source
                </Button>
              </div>
              <AddDataSourceDialog
                addedDataSource={addedDataSource}
                handleSelectedDataSource={handleSelectedDataSource}
                addDataSourceListing={addDataSourceListing}
                selectedDataSourceIds={selectedDataSourceIds}
                handleAddDataSource={handleAddDataSource}
                addDataSourceLoading={addDataSourceLoading}
              />
            </Dialog>
          </>
        )}
        <div
          className={` ${isEditMode || isEditWidgetMode ? 'hidden' : 'flex justify-end gap-[1.125rem] md:flex-row md:items-center md:gap-4 lg:h-8 lg:gap-3 xxl:h-10 xxl:gap-4'} ${isCommentMode ? '' : 'w-full'}`}
        >
          {!(isEditMode || isEditWidgetMode || isCommentMode) && (
            <DatePicker
              placeholder='Pick a date'
              setIsDateCleared={setIsDateCleared}
              date={dateRange}
              setDate={handleDateRange}
              handleRangeDateFilter={handleRangeDateFilter}
              showDateTimeFilter={showDateTimeFilter}
              setShowDateTimeFilter={setShowDateTimeFilter}
              handleClearRangeDateFilter={handleClearRangeDateFilter}
              dateRangeSet={dateRangeSet}
              handleSelectDateRange={handleSelectDateRange}
              dateRangeStart={dateRangeStart}
              setDateRangeStart={setDateRangeStart}
              dateRangeEnd={dateRangeEnd}
              setDateRangeEnd={setDateRangeEnd}
              compareRangeSet={compareRangeSet}
              handleSelectCompareRange={handleSelectCompareRange}
              compareRangeEnd={compareRangeEnd}
              compareRangeStart={compareRangeStart}
              setCompareRangeEnd={setCompareRangeEnd}
              setCompareRangeStart={setCompareRangeStart}
              isComparable={true}
              className='flex-1 lg:flex-[unset]'
              buttonClass='w-full md:w-full lg:w-[13.75rem] xxl:w-[16.625rem] !border border-darkPrimary'
            />
          )}
          {!isCommentMode && <div className='hidden h-[1.875rem] w-px bg-lightGray md:block'></div>}
          <div className='flex gap-[1.125rem] md:gap-4 lg:gap-3 xxl:gap-4'>
            {!(isEditMode || isEditWidgetMode || isCommentMode) && (
              <Link
                to={generatePath(ROUTES.REPORTS, { organizationId, dashboardId })}
                className='flex w-12 items-center justify-center rounded-md bg-sidebar-gradient md:h-10 md:w-10 lg:h-8 lg:w-8 xxl:h-10 xxl:w-10'
              >
                <FileText className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' color='#fff' />
              </Link>
            )}
            {userOrganizationRole !== ROLES.MEMBER && !isCommentMode && (
              <Button
                className='hidden w-[84px] bg-errorColor p-0 md:flex md:h-10 md:w-10 lg:h-8 lg:w-8 xxl:h-10 xxl:w-10'
                onClick={() => {
                  setIsGoalTrackingMode(!isGoalTrackingMode);
                  setIsEditMode(false);
                  setIsEditWidgetMode(false);
                  setIsCommentMode(false);
                  setEditWidget({});
                }}
              >
                <GoalTrackingDarkIcon className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
              </Button>
            )}
            {dashboard?.data?.canEdit && (
              <div
                id={EDIT_DASHBOARD_BUTTON_ID}
                className={`hidden ${!isCommentMode && 'w-[10.6875rem] md:block md:w-10 lg:w-8 xxl:w-10'} `}
              >
                {!(isEditMode || isEditWidgetMode || isCommentMode) && (
                  <Button
                    onClick={() => {
                      setIsEditMode(true);
                      setIsEditWidgetMode(false);
                      setIsGoalTrackingMode(false);
                      setEditWidget({});
                    }}
                    className='!p-0'
                  >
                    <SquarePen color='#fff' className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                  </Button>
                )}
              </div>
            )}
            {!isCommentMode && (
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className='w-12 !border !p-0 md:h-10 md:w-10 lg:h-8 lg:w-8 xxl:h-10 xxl:w-10'
                  >
                    <Ellipsis className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                  </Button>
                </PopoverTrigger>
                <PopoverContent align='end' className='w-[11.25rem] !p-[0.3125rem]'>
                  {!(isEditMode || isEditWidgetMode || isCommentMode) && (
                    <Dialog open={showTemplateDialog} onOpenChange={setShowTemplateDialog}>
                      <Button
                        variant='outline'
                        onClick={() => {
                          setShowTemplateDialog(true);
                          saveAsTemplateFormik.resetForm();
                        }}
                        className='h-8 justify-start gap-2 rounded-md border-none bg-transparent !px-2 !py-1.5 text-xs font-normal leading-5 text-darkSecondary hover:bg-tableRowHover'
                      >
                        <Save />
                        Save as Template
                      </Button>
                      <SaveTemplateDialog
                        isLoading={saveAsTemplateLoading}
                        templateCategory={templateCategory}
                        templateCategoryFetching={templateCategoryFetching}
                        templateCategoryNextPage={templateCategoryNextPage}
                        templateCategoryHasNextPage={templateCategoryHasNextPage}
                        formik={saveAsTemplateFormik}
                      />
                    </Dialog>
                  )}
                  {!(isEditMode || isEditWidgetMode || isCommentMode) && dashboard?.data?.canEdit && (
                    <Dialog onOpenChange={() => setIsCopied(false)}>
                      <DialogTrigger className='h-12 md:h-10 lg:h-8 xxl:h-10'>
                        <div
                          onClick={() => shareDashboardUrlRefetch()}
                          className='flex h-8 w-[10.625rem] items-center justify-start gap-2 rounded-md bg-transparent !px-2 !py-1.5 text-sm font-normal leading-5 text-darkSecondary hover:bg-tableRowHover'
                        >
                          <Share2 className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                          Share
                        </div>
                      </DialogTrigger>
                      {!shareDashboardUrlFetching && (
                        <DialogContent className='h-[10.1875rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-lg p-3 md:h-[13.375rem] md:max-w-[35.875rem] md:p-5 lg:h-[10.1875rem] lg:max-w-[24.375rem] xxl:h-[13.375rem] xxl:max-w-[35.875rem] xxl:p-10'>
                          <h3 className='mb:mb-10 mb-6 mt-3 text-lg font-bold leading-[1.75rem] md:mt-4 md:text-2xl md:leading-7 lg:mt-3 lg:text-lg xxl:mb-10 xxl:mt-0'>
                            Share Dashboard
                          </h3>
                          <div className='flex items-end gap-3'>
                            <div className='max-w-[16.0625rem] flex-grow md:max-w-[24rem] lg:max-w-[16.0625rem] xxl:max-w-[24rem]'>
                              <FormInput
                                disabled
                                value={sharedDashboardURL}
                                labelText='URL'
                                placeholder='www.example url.com'
                                labelClassName='text-sm leading-[1.0625rem] font-medium'
                                inputClassName='h-10 md:h-[2.875rem] lg:h-10 xxl:h-[3rem]'
                              />
                            </div>

                            <div
                              onClick={copyToClipboard}
                              className='flex h-10 cursor-pointer items-center gap-2 text-xs font-normal leading-[.875rem] md:text-sm lg:text-xs xxl:text-sm'
                            >
                              {isCopied ? (
                                'Link Copied!'
                              ) : (
                                <>
                                  <CopyIcon className='h-4 w-4 md:h-5 md:w-5 lg:h-4 lg:w-4 xxl:h-5 xxl:w-5' />
                                  Copy Link
                                </>
                              )}
                            </div>
                          </div>
                        </DialogContent>
                      )}
                    </Dialog>
                  )}
                  <div className='md:hidden'>
                    <Sheet>
                      <SheetHeader>
                        <SheetTrigger>
                          <span
                            className={`flex h-8 items-center justify-start gap-2 rounded-md bg-transparent !px-2 !py-1.5 text-sm font-normal leading-5 text-darkSecondary hover:bg-tableRowHover ${isCommentMode && '!bg-darkPrimary'}`}
                          >
                            <CommentsIcon
                              strokeColor={`${isCommentMode ? 'white' : '#1C1C23'}`}
                              className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6'
                            />
                            Comments
                          </span>
                        </SheetTrigger>
                      </SheetHeader>
                      <SheetContent closeIconStyle='hidden' className='w-[21.3125rem] px-5 py-[1.875rem]'>
                        <SheetClose asChild className='absolute left-5 top-[2.0625rem]'>
                          <Button variant='ghost' className='h-fit w-6 p-0'>
                            <ArrowLeft className='h-6 w-6' />
                          </Button>
                        </SheetClose>
                        <DashboardComments
                          isCommentMode={isCommentMode}
                          commentsListing={commentsListing}
                          formik={formik}
                          createCommentLoading={createCommentLoading}
                          scrollContainerRef={scrollContainerRef}
                          handleScroll={handleScroll}
                          className='block'
                        />
                      </SheetContent>
                    </Sheet>
                  </div>
                  <div className='hidden md:block'>
                    {(!isEditMode || isEditWidgetMode || isCommentMode) && (
                      <PopoverClose
                        disabled={isCommentMode}
                        onClick={() => {
                          setIsCommentMode(true);
                          setCommentedWidget(null);
                          setIsGoalTrackingMode(false);
                          resetForm();
                        }}
                        className={`flex h-8 w-full items-center justify-start gap-2 rounded-md bg-transparent !px-2 !py-1.5 text-sm font-normal leading-5 text-darkSecondary hover:bg-tableRowHover ${isCommentMode && '!bg-darkPrimary'}`}
                      >
                        <CommentsIcon
                          strokeColor={`${isCommentMode ? 'white' : COLORS.DARK_SECONDARY}`}
                          className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6'
                        />
                        Comments
                      </PopoverClose>
                    )}
                  </div>
                  {shouldRenderComponent && !isCommentMode ? (
                    <Link
                      to={generatePath(ROUTES.DASHBOARD_FULL_VIEW, { organizationId, dashboardId, selectSliderWidget })}
                      className='flex w-full cursor-pointer items-center justify-start gap-2 rounded-md bg-transparent !px-2 !py-1.5 text-sm font-normal leading-5 text-darkSecondary hover:bg-tableRowHover lg:h-8'
                    >
                      <Expand className='h-6 w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                      Full View
                    </Link>
                  ) : (
                    ''
                  )}
                </PopoverContent>
              </Popover>
            )}
          </div>
          {!isCommentMode && dashboard?.data?.canEdit && (
            <TourButton
              className='bottom-[7.125rem] right-[2.4375rem] h-12 p-3 md:bottom-[9.75rem] md:right-12 md:h-16 md:p-4 lg:bottom-[6.25rem] lg:right-[2.875rem] lg:!h-10 lg:p-2.5 xxl:bottom-[8rem] xxl:right-[4.1875rem] xxl:!h-12 xxl:p-3'
              iconClass='h-6 w-6 md:h-8 md:w-8 lg:h-5 lg:w-5 xxl:h-6 xxl:w-6'
              Label='“Edit Dashboard”'
              id={ADD_WIDGETS_BUTTON_ID}
              onClick={() => {
                addWidgetTour().drive();
                setIsEditWidgetMode(false);
                setEditWidget({});
              }}
            />
          )}
        </div>
        <div className='hidden md:block'>
          {(isEditMode || isEditWidgetMode || isCommentMode) && (
            <div className='md:pl-4 lg:pl-3 xxl:pl-6'>
              <Button
                onClick={() => {
                  setIsEditMode(false);
                  setIsEditWidgetMode(false);
                  setIsCommentMode(false);
                }}
                variant='outline'
                className='bg-white !p-2 lg:h-8 lg:text-xs lg:leading-[.875rem] xxl:h-10'
              >
                <CrossIcon className='h-6 w-6' />
              </Button>
            </div>
          )}
        </div>
      </div>

      {isUploadedLogo && (
        <ImageCrop
          uploadedImage={croppedImage}
          handleCancel={() => {
            setIsUploadedLogo(false);
            logoRef.current.value = '';
          }}
          setIsUploaded={setIsUploadedLogo}
          handleSave={file => handleSave(file, handleLogoUpload)}
          imageRef={logoRef}
          isDialog
        />
      )}
    </div>
  );
}
