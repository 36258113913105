export function InformationIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='h-5 w-5 md:h-6 md:w-6 lg:h-3.5 lg:w-3.5 xxl:h-4 xxl:w-4'
    >
      <g clipPath='url(#clip0_1170_8804)'>
        <path
          d='M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z'
          stroke='#00ACE7'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M8 5.3335V8.00016' stroke='#00ACE7' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 10.6665H8.00583' stroke='#00ACE7' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_1170_8804'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
