import { Popover, PopoverTrigger, PopoverContent, Button, Calendar, Label, Checkbox } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { ConnectorGuide } from '@common/components';

export function DateField({ oAuthListing, checkedOauthId, date, setDate, showInfoIcon, infoContent }) {
  return (
    <div>
      <div className='flex h-[2.5625rem] items-center border-b border-lightGray pl-4 text-sm font-normal leading-[1.0588rem] md:h-11 md:pl-4 md:text-sm md:leading-[1.0588rem] lg:h-[2.4375rem] lg:text-xs lg:leading-[0.9075rem] xxl:h-11 xxl:pl-4 xxl:text-sm xxl:leading-[1.0588rem]'>
        <div className='flex items-center gap-2 md:gap-3 lg:gap-2 xxl:gap-3'>
          <Checkbox checked={true} />
          <label>{oAuthListing?.data?.results?.find(oauth => oauth?.id === checkedOauthId)?.label}</label>
        </div>
      </div>
      <div className='mx-auto flex h-[9.6875rem] flex-col justify-center gap-1.5 px-10 md:h-[12.3125rem] lg:h-[8.25rem] xxl:h-[12.3125rem]'>
        <Label className='relative w-fit'>
          Select Date <ConnectorGuide showInfoIcon={showInfoIcon} infoContent={infoContent} />
        </Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={'outline'}
              className={cn('w-full justify-start text-left font-normal', !date && 'text-muted-foreground')}
            >
              <CalendarIcon className='mr-2 h-4 w-4' />
              {date ? format(date, 'PPP') : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className='w-auto p-0'>
            <Calendar mode='single' selected={date} onSelect={setDate} initialFocus today={false} />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
