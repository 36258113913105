/* eslint-disable indent */
import {
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Button,
  Calendar,
  SelectLabel,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { X } from 'lucide-react';
import moment from 'moment';
import { FormInput, BaseDatePicker } from '@common/components';
import { BAR_CHART_CONVERSION, DATE_FORMAT, DATE_RANGE_DROP_DOWN, DATE_RANGE_OPTIONS, WIDGETS_TYPE } from '@constants';

export function GeneralTab({
  editWidget,
  setEditWidget,
  editWidgetDateRange,
  setEditWidgetDateRange,
  handleSelectEditWidgetDateRange,
  handleSelectEditWidgetCompareRange,
  handleEditClearRangeDateFilter,
  editWidgetDateRangeSet,
  editWidgetDateRangeStart,
  editWidgetDateRangeEnd,
  editWidgetCompareRangeStart,
  editWidgetCompareRangeEnd,
  editWidgetCompareRangeSet,
  setEditWidgetDateRangeStart,
  handleEditWidgetRangeDateFilter,
  setEditWidgetDateRangeEnd,
  setEditWidgetCompareRangeEnd,
  setEditWidgetCompareRangeStart,
  handleDateRangeChange,
  showDatePicker,
  setShowDatePicker,
  handleCommentUpdate,
}) {
  return (
    <div className='flex flex-col gap-6'>
      <FormInput
        labelText='Title'
        placeholder='Type here'
        labelClassName='text-sm font-medium leading-5'
        inputClassName='h-10 bg-transparent'
        className='gap-1.5'
        value={editWidget?.displayLabel}
        onChange={e => {
          setEditWidget({ ...editWidget, displayLabel: e?.target?.value });
          handleCommentUpdate({ payload: { widgetLabel: e?.target?.value } });
        }}
      />
      <FormInput
        labelText='Tooltip'
        placeholder='Type here'
        labelClassName='text-sm font-medium leading-5'
        inputClassName='h-10 bg-transparent'
        className='gap-1.5'
        value={editWidget?.tooltip}
        onChange={e => setEditWidget({ ...editWidget, tooltip: e?.target?.value })}
      />
      {[WIDGETS_TYPE.BAR_CHART, WIDGETS_TYPE.LINE_CHART, WIDGETS_TYPE.PROGRESS_BAR, WIDGETS_TYPE.PIE_CHART].includes(
        editWidget?.id?.split('-')[0]
      ) ? (
        <div className='flex flex-col gap-1.5'>
          <Label>Chart Type</Label>
          <div className='flex flex-wrap gap-1.5'>
            {BAR_CHART_CONVERSION.map((chart, index) => (
              <div
                key={index}
                className={`flex min-w-[4.25rem] cursor-pointer flex-col items-center gap-1 rounded-md border border-lightGray px-2 py-2.5 text-xs font-light leading-[0.9075rem] ${chart.value === editWidget?.id?.split('-')[0] ? 'border !border-darkPrimary' : ''}`}
                onClick={() =>
                  setEditWidget({
                    ...editWidget,
                    id: editWidget?.id.replace(editWidget?.id?.split('-')[0], chart.value),
                  })
                }
              >
                <chart.Icon width={24} height={24} />
                {chart.label}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      {![WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.IMAGE, WIDGETS_TYPE.TEXT, WIDGETS_TYPE.CSV].includes(
        editWidget?.id?.split('-')[0]
      ) &&
        editWidget?.connectorName !== WIDGETS_TYPE.GOAL && (
          <div className='relative flex flex-col gap-1.5'>
            <Label>Date Range</Label>
            <Select value={editWidget?.dateRange} onValueChange={handleDateRangeChange}>
              <SelectTrigger className='bg-transparent'>
                <SelectValue placeholder='Selected date range' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {DATE_RANGE_DROP_DOWN?.map(
                    option =>
                      option.id !== DATE_RANGE_OPTIONS.CUSTOM && (
                        <SelectItem key={option?.id} value={option?.id} className='px-3 py-[0.4375rem]'>
                          {option?.name}
                        </SelectItem>
                      )
                  )}
                </SelectGroup>
                <SelectItem
                  value='CUSTOM'
                  className='px-3 py-[0.4375rem]'
                  onMouseDown={e => {
                    e.stopPropagation();
                    setShowDatePicker(true);
                  }}
                >
                  Custom
                </SelectItem>
              </SelectContent>
            </Select>
            {showDatePicker && (
              <div className='absolute bottom-12 right-0 flex h-[31.25rem] w-[24.375rem] flex-col items-center justify-start overflow-y-auto bg-white px-4 py-3.5 shadow-md md:h-auto md:w-[36.25rem] lg:w-auto lg:flex-row lg:overflow-x-hidden lg:px-[0.6875rem] lg:py-[0.5938rem] xxl:overflow-hidden xxl:px-4 xxl:py-3.5'>
                <span
                  className='absolute right-[12px] top-[12px] cursor-pointer'
                  onClick={() => setShowDatePicker(false)}
                >
                  <X />
                </span>
                <Calendar
                  initialFocus
                  mode='range'
                  today={false}
                  selected={editWidgetDateRange}
                  onSelect={value => {
                    setEditWidgetDateRange(value);
                    if (value?.to) {
                      let payload = {
                        ...editWidget,
                        dateRange: 'CUSTOM',
                        startDate: moment(value?.from).format(DATE_FORMAT.RANGE_DATE),
                        endDate: moment(value?.to).format(DATE_FORMAT.RANGE_DATE),
                      };

                      editWidget?.startDate1 && delete payload['startDate1'];
                      editWidget?.endDate1 && delete payload['endDate1'];
                      editWidget?.startDate2 && delete payload['startDate2'];
                      editWidget?.endDate2 && delete payload['endDate2'];
                      editWidget?.comparisonDateRange && delete payload['comparisonDateRange'];
                      editWidget?.comparisonCompareTo && delete payload['comparisonCompareTo'];
                      editWidget?.isWidgetComparisonFilterApply && delete payload['isWidgetComparisonFilterApply'];

                      setEditWidget(payload);
                      setShowDatePicker(false);
                    }
                  }}
                  numberOfMonths={2}
                />
                <div className='mt-6 flex w-[18.75rem] flex-col border-t pt-6 md:mt-0 md:w-full md:px-2 lg:w-[10.875rem] lg:border-l lg:border-t-0 lg:px-0 lg:pl-3.5 lg:pt-0 xxl:w-[14.0625rem] xxl:pl-4'>
                  <div>
                    <p className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-5 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-5'>
                      Date Range
                    </p>
                    <div className='flex flex-col md:flex-row md:items-end md:justify-between md:gap-3 lg:flex-col lg:items-start lg:gap-2 '>
                      <div className='flex flex-col gap-2'>
                        <Select value={editWidgetDateRangeSet} onValueChange={handleSelectEditWidgetDateRange}>
                          <SelectTrigger
                            className={`h-[2.625rem] w-full text-xs md:h-8 md:w-[16.25rem] lg:h-7 lg:w-[10rem] xxl:w-[12.0625rem] ${editWidgetDateRangeSet ? '' : 'custom-select'}`}
                          >
                            <SelectValue placeholder='Last 30 days' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {isEmpty(DATE_RANGE_DROP_DOWN) ? (
                                <SelectLabel className='text-xs font-normal leading-[0.9075rem] text-mediumGray'>
                                  Selected date range
                                </SelectLabel>
                              ) : (
                                DATE_RANGE_DROP_DOWN?.map(option => (
                                  <SelectItem key={option?.id} value={option?.id}>
                                    {option?.name}
                                  </SelectItem>
                                ))
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className='mt-3 flex w-full gap-[.5625rem] md:mt-0 md:w-[15.8125rem] lg:w-[16.25rem] xxl:mt-0 xxl:w-[12.0625rem]'>
                        <BaseDatePicker
                          className='h-[2.625rem] rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                          date={editWidgetDateRangeStart}
                          disabled={editWidgetDateRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                          setDate={setEditWidgetDateRangeStart}
                        />
                        <BaseDatePicker
                          className='h-[2.625rem] rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                          date={editWidgetDateRangeEnd}
                          disabled={editWidgetDateRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                          setDate={setEditWidgetDateRangeEnd}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <p className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-5 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-5'>
                      Compare To
                    </p>
                    <div className='flex flex-col justify-between md:flex-row md:items-end md:gap-3 lg:flex-col lg:items-start lg:gap-2'>
                      <Select value={editWidgetCompareRangeSet} onValueChange={handleSelectEditWidgetCompareRange}>
                        <SelectTrigger
                          className={`h-[2.625rem] w-full text-xs md:h-8 md:w-[16.25rem] lg:h-7 lg:w-[10rem] xxl:w-[12.0625rem] ${editWidgetCompareRangeSet ? '' : 'custom-select'}`}
                        >
                          <SelectValue placeholder='Last period' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {isEmpty(DATE_RANGE_DROP_DOWN) ? (
                              <SelectLabel className='text-xs font-normal leading-[0.9075rem] text-mediumGray'>
                                Selected date range
                              </SelectLabel>
                            ) : (
                              DATE_RANGE_DROP_DOWN?.map(option => (
                                <SelectItem key={option?.id} value={option?.id}>
                                  {option?.name}
                                </SelectItem>
                              ))
                            )}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <div className='mt-3 flex w-full gap-[0.5625rem] md:mt-0 md:w-[15.8125rem] lg:w-[16.25rem] xxl:mt-0'>
                        <BaseDatePicker
                          className='h-[2.625rem] w-full rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                          date={editWidgetCompareRangeStart}
                          setDate={setEditWidgetCompareRangeStart}
                          disabled={editWidgetCompareRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                        />
                        <BaseDatePicker
                          className='h-[2.625rem] w-full rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                          date={editWidgetCompareRangeEnd}
                          setDate={setEditWidgetCompareRangeEnd}
                          disabled={editWidgetCompareRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 flex justify-end gap-2 lg:mt-2 lg:gap-2.5 xxl:mt-4 xxl:gap-2.5'>
                    <Button
                      variant='outline'
                      className='h-[2.625rem] md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                      onClick={handleEditClearRangeDateFilter}
                    >
                      Clear Filter
                    </Button>
                    <Button
                      className='h-[2.625rem] md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                      onClick={handleEditWidgetRangeDateFilter}
                    >
                      Apply Filter
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  );
}
