import { useEffect, useState, useRef } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { setLocalStorageItem } from '@utils';
import {
  ERROR_MESSAGE,
  FIELD_REQUIRED,
  SUCCESSFUL_MESSAGE,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  SELECTED_ORGANIZATION_ID,
} from '@constants';
export function useHome() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const imageRef = useRef(null);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [isUploadedLogo, setIsUploadedLogo] = useState(null);
  const [showAddEditOrgDialog, setShowAddEditOrgDialog] = useState(false);

  const { data: userDetail } = useGetQuery('user-detail', apiEndpoints.USER_DETAIL);
  const createOrgInitialValues = { logo: '', organizationName: '' };

  const handleSubmit = () => {
    handleCreateOrg({
      payload: {
        logo: uploadedLogo?.id,
        name: values.organizationName,
      },
    });
  };

  const createOrgValidationSchema = Yup.object({
    organizationName: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Organization name'))
      .max(60, MAX_LENGTH_REQUIRED('Organization name'))
      .required(FIELD_REQUIRED('Organization name')),
  });

  const { ...formik } = useFormikForm(createOrgInitialValues, handleSubmit, createOrgValidationSchema);

  const { values, resetForm } = formik;

  const { mutate: handleImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedLogo(resp?.data);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
      resetForm();
    }
  );

  let queryParams = {};

  if (search) queryParams = { name: search };
  else queryParams = { page };

  const {
    data: organizations,
    isFetching: organizationFetching,
    refetch: organizationRefetch,
  } = useGetQuery(
    ['organizations-home', pathname],
    apiEndpoints.ORGANIZATIONS_HOME,
    { myOrganizations: pathname === '/home/my-organizations' ? true : false, ...queryParams },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { mutate: handleCreateOrg, isLoading: createOrganizationLoading } = usePostMutation(
    apiEndpoints.ORGANIZATIONS,
    resp => {
      organizationRefetch();
      resetForm();
      setUploadedLogo(null);
      setShowAddEditOrgDialog(false);
      navigate(generatePath(ROUTES.DASHBOARD, { organizationId: resp?.data?.id }));
      setLocalStorageItem(SELECTED_ORGANIZATION_ID, resp?.data?.id);
      toast.success(SUCCESSFUL_MESSAGE('Organization created'));
    },
    ({ response: { data } }) => {
      data?.detail ? toast.error(data?.detail) : toast.error(data?.name?.name[0]);
    }
  );

  useEffect(() => {
    !!pathname && organizationRefetch();
  }, [pathname, search, page]);

  return {
    formik,
    imageRef,
    organizations,
    organizationFetching,
    search,
    setSearch,
    page,
    setPage,
    uploadedLogo,
    setUploadedLogo,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    setIsUploadedLogo,
    createOrganizationLoading,
    userDetail,
    handleImageUpload,
  };
}
