export function ClosedSidebarIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.9613 2.79639H13.3379V14.4875H15.9613V2.79639Z' fill='url(#paint0_linear_1579_13568)' />
      <path d='M11.4593 5.58936H8.83594V17.2805H11.4593V5.58936Z' fill='url(#paint1_linear_1579_13568)' />
      <path
        d='M17.7768 8.55533V14.9323C17.7775 15.9014 17.5456 16.8566 17.1006 17.7175C16.6555 18.5784 16.0103 19.32 15.2192 19.8797C14.4281 20.4395 13.5141 20.8012 12.5542 20.9344C11.5942 21.0676 10.6163 20.9685 9.70263 20.6453C8.78897 20.3221 7.96623 19.7843 7.30357 19.0771C6.64091 18.3699 6.15768 17.5139 5.89451 16.5812C5.63134 15.6485 5.5959 14.6662 5.79119 13.7169C5.98647 12.7677 6.40677 11.8791 7.01675 11.126V7.51885C5.99286 8.18042 5.11789 9.04787 4.4475 10.066C3.77711 11.0842 3.32599 12.2307 3.12283 13.4327C2.91968 14.6346 2.96894 15.8658 3.26749 17.0477C3.56603 18.2296 4.10732 19.3364 4.85692 20.2977C5.60652 21.259 6.54801 22.0538 7.62148 22.6314C8.69496 23.2091 9.87692 23.557 11.0922 23.6529C12.3074 23.7489 13.5293 23.5909 14.6802 23.1889C15.831 22.7869 16.8856 22.1498 17.7768 21.318V23.7336H20.5261V0H17.7768V8.55533Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1579_13568'
          x1='16.0843'
          y1='5.02159'
          x2='10.9194'
          y2='18.0449'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1579_13568'
          x1='13.1517'
          y1='3.85897'
          x2='7.98684'
          y2='16.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
      </defs>
    </svg>
  );
}
