import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { BaseDatePicker, FormSelect } from '@common/components';
import { DATE_RANGE_DROP_DOWN, DATE_RANGE_OPTIONS } from '@constants';

export function DatePicker({
  className,
  buttonClass,
  calendarClass,
  placeholder,
  date,
  setDate,
  showDateTimeFilter,
  setShowDateTimeFilter,
  handleRangeDateFilter,
  handleClearRangeDateFilter,
  dateRangeSet,
  handleSelectDateRange,
  dateRangeStart,
  setDateRangeStart,
  dateRangeEnd,
  setDateRangeEnd,
  compareRangeSet,
  handleSelectCompareRange,
  compareRangeEnd,
  compareRangeStart,
  setCompareRangeEnd,
  setCompareRangeStart,
  isComparable,
}) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={showDateTimeFilter} onOpenChange={setShowDateTimeFilter}>
        <PopoverTrigger asChild className='lg:px-3'>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'flex w-full items-center justify-between text-left font-normal md:w-[17rem] lg:w-[7.5rem] xxl:w-[15.5rem]',
              !date && 'text-muted-foreground',
              buttonClass
            )}
          >
            <div className='flex items-center'>
              <CalendarIcon
                className={cn('mr-2 md:h-6 md:w-6 lg:mr-1.5 lg:h-5 lg:w-5 xxl:mr-2 xxl:h-6 xxl:w-6', calendarClass)}
              />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y')
                )
              ) : (
                <span>{placeholder}</span>
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={`flex w-auto px-4 py-3.5 lg:overflow-x-hidden lg:px-[0.6875rem] lg:py-[0.5938rem] xxl:px-4 xxl:py-3.5 ${isComparable ? 'absolute right-0 h-[31.25rem] w-[24.375rem] flex-col items-center justify-start overflow-y-auto md:right-[-308px] md:h-auto md:w-[36.25rem] lg:w-auto lg:flex-row xxl:overflow-hidden' : 'flex-col'}`}
          align='end'
        >
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            today={false}
          />
          {isComparable ? (
            <div className='mt-6 flex w-[18.75rem] flex-col border-t pt-6 md:mt-0 md:w-full md:px-2 lg:w-[10.875rem] lg:border-l lg:border-t-0 lg:px-0 lg:pl-3.5 lg:pt-0 xxl:w-[14.0625rem] xxl:pl-4'>
              <div>
                <p className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-5 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-5'>
                  Date Range
                </p>
                <div className='flex flex-col md:flex-row md:items-end md:justify-between md:gap-3 lg:flex-col lg:items-start lg:gap-2 '>
                  <FormSelect
                    placeholder='Select date range'
                    value={dateRangeSet}
                    handleChange={handleSelectDateRange}
                    options={DATE_RANGE_DROP_DOWN}
                    className='gap-2'
                    labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
                    triggerClassName={`h-[2.625rem] md:h-8 w-full md:w-[16.25rem] lg:w-[10rem] lg:h-7 xxl:w-[12.0625rem] text-xs ${dateRangeSet ? '' : 'custom-select'}`}
                  />
                  <div className='mt-3 flex w-full gap-[.5625rem] md:mt-0 md:w-[15.8125rem] lg:w-[16.25rem] xxl:mt-0 xxl:w-[12.0625rem]'>
                    <BaseDatePicker
                      className='h-[2.625rem] rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                      date={dateRangeStart}
                      setDate={setDateRangeStart}
                      disabled={dateRangeSet?.id !== DATE_RANGE_OPTIONS.CUSTOM}
                    />
                    <BaseDatePicker
                      className='h-[2.625rem] rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                      date={dateRangeEnd}
                      setDate={setDateRangeEnd}
                      disabled={dateRangeSet?.id !== DATE_RANGE_OPTIONS.CUSTOM}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <p className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-5 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-5'>
                  Compare To
                </p>
                <div className='flex flex-col justify-between md:flex-row md:items-end md:gap-3 lg:flex-col lg:items-start lg:gap-2'>
                  <FormSelect
                    placeholder='Select date range'
                    value={compareRangeSet}
                    handleChange={handleSelectCompareRange}
                    options={DATE_RANGE_DROP_DOWN}
                    className='gap-2'
                    labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
                    triggerClassName={`h-[2.625rem] md:h-8 md:w-[16.25rem] lg:w-[10rem] lg:h-7 w-full xxl:w-[12.0625rem] text-xs ${compareRangeSet ? '' : 'custom-select'}`}
                  />
                  <div className='mt-3 flex w-full gap-[0.5625rem] md:mt-0 md:w-[15.8125rem] lg:w-[16.25rem] xxl:mt-0'>
                    <BaseDatePicker
                      className='h-[2.625rem] w-full rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                      date={compareRangeStart}
                      setDate={setCompareRangeStart}
                      disabled={compareRangeSet?.id !== DATE_RANGE_OPTIONS.CUSTOM}
                    />
                    <BaseDatePicker
                      className='h-[2.625rem] w-full rounded-md border border-lightGray px-3 py-1 text-sm text-slateGray focus:outline-none md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                      date={compareRangeEnd}
                      setDate={setCompareRangeEnd}
                      disabled={compareRangeSet?.id !== DATE_RANGE_OPTIONS.CUSTOM}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-4 flex justify-end gap-2 lg:mt-2 lg:gap-2.5 xxl:mt-4 xxl:gap-2.5'>
                <Button
                  variant='outline'
                  className='h-[2.625rem] md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                  onClick={handleClearRangeDateFilter}
                >
                  Clear Filter
                </Button>
                <Button
                  className='h-[2.625rem] md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                  onClick={handleRangeDateFilter}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          ) : (
            <div className='mt-4 flex justify-end gap-2 lg:gap-2.5 xxl:gap-2.5'>
              <Button
                variant='outline'
                className='h-[2.625rem] md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                onClick={handleClearRangeDateFilter}
              >
                Clear Filter
              </Button>
              <Button
                className='h-[2.625rem] md:h-8 md:w-[7.5rem] lg:h-7 lg:w-[4.75rem] xxl:h-8 xxl:w-[5.75rem]'
                onClick={handleRangeDateFilter}
              >
                Apply Filter
              </Button>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}
