import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { ROUTES } from '@routes';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePatchMutation, usePostMutation } from '@services';
import { AppContext } from '@useContext';
import { setLocalStorageItem } from '@utils';
import {
  ERROR_MESSAGE,
  FIELD_REQUIRED,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  SUCCESSFUL_MESSAGE,
  DEFAULT_ORGANIZATION_DELETE_MESSAGE,
  ROLES,
  MEMBERSHIP_CONSUMED,
  NOT_FOUND,
} from '@constants';

export function useManageOrganization() {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const imageRef = useRef(null);

  const { setDataSourcePage } = useContext(AppContext);

  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [isUploadedLogo, setIsUploadedLogo] = useState(null);
  const [showAddEditOrgDialog, setShowAddEditOrgDialog] = useState(false);
  const [showDeleteOrganizationDialog, setShowDeleteOrganizationDialog] = useState(false);
  const [isEditOrganizationLoading, setIsEditOrganizationLoading] = useState(false);
  const [isDeleteOrgLoading, setIsDeleteOrgLoading] = useState(false);

  const { refetch: organizationMembersRefetch } = useGetQuery(
    'organization-members-listing',
    apiEndpoints.ORGANIZATIONS_MEMBERS(organizationId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: !!organizationId }
  );

  const { refetch: teamsListingRefetch } = useGetQuery(
    'team-listing',
    apiEndpoints.ORGANIZATIONS_TEAMS(organizationId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!organizationId,
    }
  );

  const { data: userDetail, refetch: userDetailRefetch } = useGetQuery('user-detail', apiEndpoints.USER_DETAIL);

  const { refetch: inviteListingRefetch } = useGetQuery(
    'invite-listing',
    apiEndpoints.INVITATIONS_LISTING(organizationId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: !!organizationId }
  );

  const { refetch: connectedDataSourceRefetch } = useGetQuery(
    'poll-connectedDataSource-listing',
    apiEndpoints.CONNECTED_DATA_SOURCE_LIST(organizationId),
    { page: 1 },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!organizationId,
    }
  );

  const { refetch: whiteLabelRefetch } = useGetQuery(
    'white-label',
    apiEndpoints.WHITE_LABEL(organizationId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: goalsProgressRefetch } = useGetQuery(
    'goals-progress',
    apiEndpoints.GOALS_PROGRESS(organizationId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: goalsListingRefetch } = useGetQuery(
    'goals-listing',
    apiEndpoints.ORGANIZATION_GOALS(organizationId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: dashboardsListingRefetch } = useGetQuery(
    'team-dashboards-listing',
    apiEndpoints.CREATE_TEAM_DASHBOARDS_LISTING(organizationId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: manageOrganization,
    refetch: manageOrganizationRefetch,
    isFetching: manageOrganizationFetching,
  } = useGetQuery(
    'manage-organization',
    `${apiEndpoints.ORGANIZATIONS}${organizationId}/`,
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!organizationId,
      onSuccess: resp => {
        resp?.data?.userRole !== ROLES.MEMBER && organizationMembersRefetch();
        teamsListingRefetch();
        connectedDataSourceRefetch();
        whiteLabelRefetch();
        goalsProgressRefetch();
        goalsListingRefetch();
        dashboardsListingRefetch();
        setDataSourcePage(1);
        resp?.data?.userRole !== ROLES.MEMBER && inviteListingRefetch();
        setLocalStorageItem('userOrganizationRole', resp?.data?.userRole);
      },
      onError: ({ response: { data } }) => {
        if (data.detail === MEMBERSHIP_CONSUMED) navigate(ROUTES.SUBSCRIPTION_EXPIRED);
        else if (data.detail === NOT_FOUND) navigate(ROUTES.HOME);
        else navigate('/profile/memberships-tab');
      },
    }
  );

  const { refetch: organizationListingRefetch } = useGetQuery('organization-listing', apiEndpoints.ORGANIZATIONS);

  const editOrgInitialValues = {
    logo: manageOrganization?.data?.logo?.image,
    organizationName: manageOrganization?.data?.name,
  };

  const editOrgValidationSchema = Yup.object({
    logo: Yup.string().optional(),
    organizationName: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Organization name'))
      .max(60, MAX_LENGTH_REQUIRED('Organization name'))
      .required(FIELD_REQUIRED('Organization name')),
  });

  const handleSubmit = () => {
    setIsEditOrganizationLoading(true);
    let payload = {
      logo: uploadedLogo?.id,
      name: values.organizationName,
    };

    !uploadedLogo?.id && delete payload['logo'];
    manageOrganization?.data?.name === values?.organizationName && delete payload['name'];

    handleOrganizationUpdate({ payload });
  };

  const { ...formik } = useFormikForm(editOrgInitialValues, handleSubmit, editOrgValidationSchema);

  const { values, resetForm } = formik;

  const { mutate: handleImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedLogo(resp?.data);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleOrganizationUpdate } = usePatchMutation(
    apiEndpoints.UPDATE_ORGANIZATIONS(organizationId),
    () => {
      toast.success(SUCCESSFUL_MESSAGE('Organization updated'));
      setShowAddEditOrgDialog(false);
      manageOrganizationRefetch();
      organizationListingRefetch();
    },
    ({ response: { data } }) => {
      setIsEditOrganizationLoading(false);
      data?.detail ? toast.error(data?.detail) : toast.error(data?.name?.name[0]);
    }
  );

  const handleDeleteBtn = () => {
    setShowAddEditOrgDialog(false);
    setShowDeleteOrganizationDialog(true);
    setIsDeleteOrgLoading(false);
  };

  const handleDeleteOrganizationBtn = () => {
    handleOrganizationDelete();
    setIsDeleteOrgLoading(true);
  };

  const openEditDialogue = () => {
    resetForm();
    setUploadedLogo(null);
    setIsEditOrganizationLoading(false);
  };

  const { mutate: handleOrganizationDelete } = useDeleteMutation(
    apiEndpoints.DELETE_ORGANIZATIONS(organizationId),
    () => {
      navigate(ROUTES.HOME);
      setShowDeleteOrganizationDialog(false);
      organizationListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Organization deleted'));
    },
    ({ response: { data } }) => {
      data?.detail ? toast.error(data.detail) : toast.error(DEFAULT_ORGANIZATION_DELETE_MESSAGE);
      setIsDeleteOrgLoading(false);
      navigate(ROUTES.HOME);
    }
  );

  useEffect(() => {
    organizationId && manageOrganizationRefetch();
  }, [organizationId]);

  return {
    manageOrganization,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    handleOrganizationDelete,
    handleDeleteBtn,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    showDeleteOrganizationDialog,
    setShowDeleteOrganizationDialog,
    isUploadedLogo,
    manageOrganizationFetching,
    openEditDialogue,
    isEditOrganizationLoading,
    isDeleteOrgLoading,
    setIsDeleteOrgLoading,
    handleDeleteOrganizationBtn,
    userDetail,
    userDetailRefetch,
    handleImageUpload,
  };
}
