import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Resizer from 'react-image-file-resizer';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useGetQuery, usePatchMutation, usePostMutation } from '@services';
import { ERROR_MESSAGE, SUCCESSFUL_MESSAGE, DOMAIN_REGEX, FIELD_REQUIRED } from '@constants';

export function useWhiteLabel() {
  const { organizationId } = useParams();

  const mainLogoRef = useRef(null);
  const sponsorLogoRef = useRef(null);

  const [mainLogoUpload, setMainLogoUpload] = useState(null);
  const [mainLogoUploaded, setMainLogoUploaded] = useState(null);
  const [sponsorLogoUpload, setSponsorLogoUpload] = useState(null);
  const [sponsorLogoUploaded, setSponsorLogoUploaded] = useState(null);
  const [showAddDomain, setShowAddDomain] = useState();
  const [domainId] = useState(null);

  const {
    data: whiteLabelData,
    isFetching: whiteLabelFetching,
    refetch: whiteLabelRefetch,
  } = useGetQuery('white-label', apiEndpoints.WHITE_LABEL(organizationId));

  const { data: emailDomainDetail, refetch: emailDomainDetailRefetch } = useGetQuery(
    'email-domain-detail',
    apiEndpoints.WHITE_LABEL_EMAIL_DETAIL(organizationId, domainId || whiteLabelData?.data?.emailConfig?.domainId),
    {},
    {
      enabled: !!whiteLabelData?.data?.emailConfig?.domainId,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: verifiedEmailDomain } = useGetQuery(
    'verified-email-domain',
    apiEndpoints.WHITE_LABEL_EMAIL_VERIFIED_LIST
  );

  const whiteLabelInitialValues = {
    mainLogo: whiteLabelData?.data?.mainLogo,
    sponsorLogo: whiteLabelData?.data?.sponsorLogo,
    headerColor: whiteLabelData?.data?.headerColor,
    headerTextColor: whiteLabelData?.data?.headerTextColor,
    domain: whiteLabelData?.data?.domain,
    isTextureApplied: whiteLabelData?.data?.isTextureApplied,
    emailDomain: whiteLabelData?.data?.emailConfig?.domain,
    customEmail: whiteLabelData?.data?.email,
    verifiedDomain: whiteLabelData?.data?.email?.split('@')[1],
  };

  const validationSchema = Yup.object({
    domain: Yup.string().matches(DOMAIN_REGEX, 'Invalid URL').required().label('Domain'),
    headerColor: Yup.string().required().label('Header Color').max(7, 'Cannot exceed 7 characters'),
    headerTextColor: Yup.string().required().label('Header Text Color').max(7, 'Cannot exceed 7 characters'),
    customEmail: Yup.string().when('verifiedDomain', {
      is: value => isEmpty(value),
      then: rule => rule.notRequired(),
      otherwise: rule => rule.required(FIELD_REQUIRED('Email')),
    }),
  });

  const { mutate: handleMainLogoUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setMainLogoUpload(resp?.data);
      setMainLogoUploaded(false);
      mainLogoRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : !isEmpty(data.image?.detail)
          ? toast.error(data.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setMainLogoUploaded(false);
      mainLogoRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleSponsorLogoUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setSponsorLogoUpload(resp?.data);
      setSponsorLogoUploaded(false);
      sponsorLogoRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : !isEmpty(data.image?.detail)
          ? toast.error(data.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setSponsorLogoUploaded(false);
      sponsorLogoRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleWhiteLabelUpdate, isLoading: handleWhiteLabelUpdateLoading } = usePatchMutation(
    apiEndpoints.WHITE_LABEL(organizationId),
    () => {
      whiteLabelRefetch();
      toast.success(SUCCESSFUL_MESSAGE('White label updated'));
    },
    ({ response: { data } }) => {
      data?.detail ? toast.error(data?.detail) : toast.error(data?.name?.name[0]);
      setErrors({ domain: data.domain[0] && data.domain[0] });
    }
  );

  const handleSubmit = () => {
    let payload = {
      mainLogo: mainLogoUpload?.id,
      sponsorLogo: sponsorLogoUpload?.id,
      headerColor: values.headerColor,
      headerTextColor: values.headerTextColor,
      domain: values.domain,
      isTextureApplied: values.isTextureApplied,
      email: `${values.customEmail}@${values.verifiedDomain}`,
    };

    !mainLogoUpload?.id && delete payload['mainLogo'];
    !sponsorLogoUpload?.id && delete payload['sponsorLogo'];
    whiteLabelData?.data?.headerColor === values?.headerColor && delete payload['headerColor'];
    whiteLabelData?.data?.headerTextColor === values?.headerTextColor && delete payload['headerTextColor'];
    whiteLabelData?.data?.domain === values?.domain && delete payload['domain'];
    whiteLabelData?.data?.isTextureApplied === values?.isTextureApplied && delete payload['isTextureApplied'];
    values?.customEmail === '' && delete payload['email'];

    handleWhiteLabelUpdate({ payload });
  };

  const resizeFile = (file, width, height, handleLogoUpload) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      uri => {
        const formData = new FormData();
        formData.append('image', uri);
        handleLogoUpload({
          payload: formData,
        });
      },
      'file'
    );
  };

  const { ...formik } = useFormikForm(whiteLabelInitialValues, handleSubmit, validationSchema);
  const { values, resetForm, setErrors } = formik;

  useEffect(() => {
    if (domainId) emailDomainDetailRefetch();
  }, [domainId]);

  return {
    whiteLabelFetching,
    formik,
    mainLogoUpload,
    sponsorLogoUpload,
    setMainLogoUploaded,
    setSponsorLogoUploaded,
    mainLogoRef,
    sponsorLogoRef,
    handleWhiteLabelUpdateLoading,
    mainLogoUploaded,
    sponsorLogoUploaded,
    handleMainLogoUpload,
    handleSponsorLogoUpload,
    resizeFile,
    showAddDomain,
    setShowAddDomain,
    emailDomainDetail,
    verifiedEmailDomain,
  };
}
