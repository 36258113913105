import * as React from 'react';
import { cn } from '@shadcnUtils';

const Textarea = React.forwardRef(({ placeholder, className, showError, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        `flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${showError ? 'border-errorColor' : ''}`,
        className
      )}
      placeholder={placeholder}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
