import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  Dialog,
  DialogTrigger,
  Progress,
} from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { isEmpty } from 'lodash';
import { AddEditOrgDialog, BaseToolTip, BaseSkeltonLoader } from '@common/components';
import { useCreateOrganization, useManageOrganization } from '@pages/manageOrganization';
import { useMembershipTab } from '@pages/profile';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  showSubscriptionPlan,
  showSubscriptionText,
  showUpgradeBtn,
  truncateString,
} from '@utils';
import {
  COLORS,
  DATA_SOURCES_TEXT,
  GOAL_TRACKING_TEXT,
  INDIVIDUAL_PLAN,
  ROLES,
  SIDEBAR_CONTENT,
  SKELETON_TYPE,
} from '@constants';
import {
  LogoWhite,
  ArrowBottomDark,
  PlusIconDark,
  PlusIconWhite,
  OpenSidebarIcon,
  ClosedSidebarIcon,
  OrganizationIcon,
  HelpDesk,
} from '@assets/svgs';

export function Sidebar() {
  const {
    organizationListing,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    switcherSelectedOrganization,
    handleSelectedOrganization,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    organizationListingLoading,
    openCreateDialogue,
    handleImageUpload,
    createOrganizationLoading,
  } = useCreateOrganization();

  const { manageOrganization } = useManageOrganization();

  const [openSidebar, setOpenSidebar] = useState(true);

  const { membershipPlan, calculateTrialDays } = useMembershipTab();
  const [sectionVisible, setSectionVisible] = useState(false);

  const { data: userDetail } = useGetQuery('user-detail-sidebar', apiEndpoints.USER_DETAIL);

  let consumePercentage = Math.floor(
    (userDetail?.data?.tokens?.consumedTokens * 100) / userDetail?.data?.tokens?.totalTokens
  );

  let findMembershipPlan = membershipPlan?.data?.results?.find(m => m.isActiveMembership === true)?.nickname;

  setLocalStorageItem('showAiToken', userDetail?.data?.isSubscribed && findMembershipPlan !== INDIVIDUAL_PLAN);
  const showAiToken = getLocalStorageItem('showAiToken');
  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  useEffect(() => {
    setTimeout(() => {
      setSectionVisible(!sectionVisible);
    }, 500);
  }, [openSidebar]);

  return (
    <div
      className={`relative hidden min-h-screen flex-col justify-between gap-10 bg-sidebar-gradient pb-10 transition-all duration-500 ease-in-out lg:flex lg:px-2.5 lg:pb-10 lg:pt-5 xxl:px-3 xxl:pt-8  ${openSidebar ? ' lg:w-[10.75rem] xxl:w-[15rem]' : 'lg:w-[3.5rem] xxl:w-[5.5rem]'}`}
    >
      <div>
        <div
          className={`flex justify-center lg:mb-8 xxl:mb-10 ${openSidebar ? 'mx-auto h-6 w-[8.125rem]' : 'flex w-full items-center'}`}
        >
          {openSidebar ? (
            <Link to={ROUTES.HOME} className='lg:h-4 lg:w-[5.4375rem] xxl:h-6 xxl:w-[8.125rem]'>
              <LogoWhite />
            </Link>
          ) : (
            <Link to={ROUTES.HOME}>
              <ClosedSidebarIcon />
            </Link>
          )}
        </div>
        <div className='absolute -right-2 top-1/2 z-10'>
          <button onClick={() => setOpenSidebar(!openSidebar)}>
            <div className='flex h-6 w-6 items-center justify-center rounded-full bg-darkPrimary'>
              <OpenSidebarIcon className={`${openSidebar ? 'rotate-0' : 'rotate-180'} `} />
            </div>
          </button>
        </div>
        <div id='org-switcher' className='border-b border-lightGray lg:mb-4 lg:pb-3 xxl:mb-6 xxl:pb-4'>
          <Dialog open={showAddEditOrgDialog} onOpenChange={setShowAddEditOrgDialog}>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div
                  id='switcherOrganizationName'
                  className={`flex items-center text-white ${openSidebar ? 'lg:px-2.5 xxl:px-3.5' : 'xxl:px-1'}`}
                >
                  <div className='relative mr-2 overflow-hidden rounded-full lg:h-6 lg:min-w-6 xxl:h-8 xxl:min-w-8'>
                    {organizationListingLoading ? (
                      <BaseSkeltonLoader type={SKELETON_TYPE.IMAGE_UPLOADER} />
                    ) : switcherSelectedOrganization?.logo?.image ? (
                      <img
                        src={switcherSelectedOrganization?.logo?.image}
                        alt='profile-image'
                        className='h-full w-full'
                      />
                    ) : (
                      <OrganizationIcon className='h-full w-full bg-white p-1' />
                    )}
                  </div>
                  {!organizationListingLoading ? (
                    <span className='max-w-[7.5rem] overflow-hidden whitespace-nowrap font-normal leading-[1.0588rem] text-white lg:text-[.625rem] xxl:text-sm'>
                      {openSidebar ? truncateString(switcherSelectedOrganization?.name, 12, 'text-white') : ''}
                    </span>
                  ) : (
                    <BaseSkeltonLoader type={SKELETON_TYPE.SINGLE_LINE} count={1} width={100} height={14} />
                  )}
                  <div className='ml-auto'>
                    <ArrowBottomDark
                      className={`lg:h-3.5 lg:w-3.5 xxl:h-4 xxl:w-4 ${openSidebar && sectionVisible ? 'block' : 'hidden xxl:block'}`}
                    />
                  </div>
                  {switcherSelectedOrganization?.name?.length > 15 && (
                    <BaseToolTip
                      className='!w-[9.5rem] xxl:!w-[13.5rem]'
                      id='switcherOrganizationName'
                      content={switcherSelectedOrganization?.name}
                    />
                  )}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className={`w-[8.875rem] xxl:w-[12.5rem] ${openSidebar ? '' : 'absolute left-4'}`}>
                <div className='max-h-[12.5rem] overflow-y-auto scrollbar-none'>
                  {organizationListing?.data?.results?.map(organization => (
                    <DropdownMenuItem key={organization.id} onClick={() => handleSelectedOrganization(organization)}>
                      <div className='h-6 w-6 min-w-6 overflow-hidden rounded-full'>
                        {organization?.logo?.image ? (
                          <img src={organization?.logo?.image} alt='organization-logo' className='h-full w-full' />
                        ) : (
                          <OrganizationIcon className='h-full w-full bg-white p-1' />
                        )}
                      </div>
                      <p
                        id={`organizationName-${organization.id}`}
                        className='text-[0.625rem] font-normal leading-5 xxl:text-sm xxl:leading-[1.0588rem]'
                      >
                        {truncateString(organization?.name, 15, 'text-darkSecondary')}
                      </p>
                      {organization?.name?.length > 15 && (
                        <BaseToolTip
                          className='!w-[7.5rem] xxl:!w-[11.25rem]'
                          id={`organizationName-${organization.id}`}
                          content={organization?.name}
                        />
                      )}
                    </DropdownMenuItem>
                  ))}
                </div>
                <DropdownMenuSeparator />
                <DialogTrigger id='create-org' asChild onClick={openCreateDialogue}>
                  <DropdownMenuItem className='justify-center font-light lg:text-[0.625rem] lg:leading-5 xxl:text-sm'>
                    <PlusIconDark className='lg:h-3 lg:w-3 xxl:h-4 xxl:w-4' />
                    Create Organization
                  </DropdownMenuItem>
                </DialogTrigger>
              </DropdownMenuContent>
            </DropdownMenu>
            <AddEditOrgDialog
              dialogTitle='Create Organization'
              formik={formik}
              imageRef={imageRef}
              uploadedLogo={uploadedLogo}
              handleImageUpload={handleImageUpload}
              setUploadedLogo={setUploadedLogo}
              setIsUploadedLogo={setIsUploadedLogo}
              icon={
                <div className='h-3.5 w-3.5 lg:h-3 lg:w-3 xxl:h-3.5 xxl:w-3.5'>
                  <PlusIconWhite />
                </div>
              }
              isUploadedLogo={isUploadedLogo}
              organizationLoading={createOrganizationLoading}
            />
          </Dialog>
        </div>
        <ul className='flex flex-col gap-1 xxl:gap-2'>
          {SIDEBAR_CONTENT.map(data => {
            const isMember = manageOrganization?.data?.userRole === ROLES.MEMBER;
            const shouldRenderDataSource = isMember
              ? ![DATA_SOURCES_TEXT, GOAL_TRACKING_TEXT].includes(data.link)
              : true;

            if (!shouldRenderDataSource) return null;

            return (
              <li key={data.text}>
                <NavLink
                  id={data.link}
                  to={`${data.link}/${switcherSelectedOrganization?.id}`}
                  className='sidebarLink flex items-center gap-3 whitespace-nowrap font-normal text-white lg:rounded lg:px-2.5 lg:py-1.5 lg:text-[.625rem] lg:leading-3 xxl:rounded-md xxl:px-5 xxl:py-2 xxl:text-sm xxl:leading-[1.0588rem]'
                >
                  <div className='lg:h-4 lg:w-4 xxl:h-6 xxl:w-6'>
                    <data.iconUrl />
                  </div>
                  {openSidebar && data.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <a href='' className='flex items-center gap-3 border-t border-lightGray lg:px-2.5 lg:py-2 xxl:px-5 xxl:py-4'>
          <HelpDesk className='lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
          {openSidebar && (
            <span className='text-[0.625rem] font-normal leading-3 text-white xxl:text-sm xxl:leading-[1.0588rem]'>
              Help Desk
            </span>
          )}
        </a>
        {userOrganizationRole !== ROLES.MEMBER && (
          <>
            {openSidebar && !isEmpty(membershipPlan?.data?.results) && (
              <div className='mt-[1.1875rem] flex flex-col items-center rounded-lg border border-lightGray/40 bg-white/10 px-[0.6875rem] py-2.5 shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] lg:mx-[.3125rem] xxl:mx-2 xxl:px-[0.7rem] xxl:py-6'>
                <h4 className='mb-1 font-semibold text-white lg:text-xs lg:leading-[.875rem] xxl:text-lg xxl:leading-[1.3613rem]'>
                  {showSubscriptionPlan(userDetail, membershipPlan?.data?.results)}
                </h4>
                <p className='mb-5 mt-1 text-center font-normal text-white lg:text-[.625rem] lg:leading-[.875rem] xxl:mb-6 xxl:mt-1.5 xxl:text-sm xxl:leading-5'>
                  {membershipPlan?.data?.results[0].trialEndDate ? (
                    <>
                      Free trial ends in{' '}
                      <span className='end-subscription-gradient font-bold'>
                        {calculateTrialDays(membershipPlan?.data?.results[0].trialEndDate)}{' '}
                        {calculateTrialDays(membershipPlan?.data?.results[0].trialEndDate) === 1 ? 'day!' : 'days!'}
                      </span>
                      <br />
                      For more features upgrade your plan.
                    </>
                  ) : (
                    showSubscriptionText(userDetail, membershipPlan?.data?.results)
                  )}
                </p>
                {showUpgradeBtn(userDetail, membershipPlan?.data?.results) ? (
                  <Link to={`${ROUTES.PROFILE}/${ROUTES.MEMBERSHIPS_TAB}`}>
                    <Button className='subscription-btn-bg-gradient max-h-6 xxl:max-h-10'>Upgrade</Button>
                  </Link>
                ) : null}
              </div>
            )}
            {openSidebar && showAiToken && (
              <div className='mt-[1rem] flex flex-col rounded-lg border border-lightGray/40 bg-white/10 px-[0.6875rem] py-[0.8125rem] shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] lg:mx-[.3125rem] xxl:mx-2'>
                <p className='mb-[0.3125rem] font-normal text-white lg:text-[.625rem] lg:leading-[.875rem] xxl:text-sm xxl:leading-5'>
                  Token&apos;s Consumption
                </p>
                <Progress
                  value={Math.min(consumePercentage, 100) || 0}
                  className={cn('h-1 w-full bg-[#ffffff20]')}
                  progressColor={COLORS.LIME_GREEN}
                />
                <span className='font-semi-bold mt-[0.3125rem] flex justify-end text-[0.5rem] leading-[0.605rem] text-white'>
                  {Math.min(consumePercentage, 100) || 0}%
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
