import { Link } from 'react-router-dom';
import { Checkbox } from '@shadcnComponent';
import { ROUTES } from '@routes';

export function SignInTerms({ id, value, showError, setFieldValue }) {
  return (
    <div className='relative flex flex-col gap-1.5'>
      <div className='flex gap-2 md:gap-3 lg:gap-2'>
        <Checkbox
          id={id}
          checked={value}
          onCheckedChange={checked => setFieldValue(id, checked)}
          className={`h-6 w-6 border-2 md:h-[1.3438rem] md:w-[1.3438rem] lg:h-5 lg:w-5 xxl:h-5 xxl:w-5 ${showError ? 'border-errorColor' : ''}`}
        />
        <label
          htmlFor={id}
          className='text-xs font-normal leading-5 text-darkSecondary md:text-sm md:leading-5 lg:text-[0.625rem] lg:leading-[0.9375rem] xxl:text-xs xxl:leading-5'
        >
          By signing in you agree to our{' '}
          <Link
            to={`${import.meta.env.VITE_BACKEND_BASE_URL}${ROUTES.TERMS_AND_CONDITIONS}`}
            className='text-xs font-semibold text-darkPrimary md:text-sm md:leading-5 lg:text-[0.625rem] lg:leading-[0.9375rem] xxl:text-xs xxl:leading-5'
          >
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link
            to={`${import.meta.env.VITE_BACKEND_BASE_URL}${ROUTES.PRIVACY_POLICY}`}
            className='text-xs font-semibold text-darkPrimary md:text-sm md:leading-5 lg:text-[0.625rem] lg:leading-[0.9375rem] xxl:text-xs xxl:leading-5'
          >
            Privacy Policy.
          </Link>{' '}
        </label>
      </div>
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>{showError}</p>
      )}
    </div>
  );
}
