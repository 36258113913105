import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { Ellipsis } from 'lucide-react';

export function GoalActionPopover({
  setShowDeleteDialog,
  showDeleteDialog,
  setGoalDetail,
  goal,
  showEditDialog,
  setShowEditDialog,
  setEditGoalDetail,
  setDashboardGoal,
  optionClass,
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Ellipsis className={`${optionClass}`} />
      </PopoverTrigger>
      <PopoverContent align='end' className='w-[10.6875rem] p-0'>
        <PopoverClose>
          <ul>
            <li
              className='px-[0.8125rem] py-[0.6875rem] text-left'
              onClick={() => {
                setEditGoalDetail(goal);
                setShowEditDialog({ ...showEditDialog, [goal.id]: true });
              }}
            >
              Edit Goal
            </li>
            <li className='px-[0.8125rem] py-[0.6875rem] text-left' onClick={() => setDashboardGoal(goal)}>
              Add to dashboard
            </li>
            <li
              className='cursor-pointer px-[0.8125rem] py-[0.6875rem] text-left text-errorColor'
              onClick={() => {
                setGoalDetail(goal);
                setShowDeleteDialog({ ...showDeleteDialog, [goal.id]: true });
              }}
            >
              Delete
            </li>
          </ul>
        </PopoverClose>
      </PopoverContent>
    </Popover>
  );
}
