import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmpty, uniq } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { formatTime, getLocalStorageItem, handleTimeChange, validateCheckField } from '@utils';
import {
  DATE_FORMAT,
  EMAIL_REGEX,
  ENTER,
  ENTER_KEY,
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  MONTHLY,
  ONE_TIME,
  PAST_TIME_ERROR_MESSAGE,
  PRESS_ENTER_MESSAGE,
  SUCCESSFUL_MESSAGE,
} from '@constants';

export function useCreateReport() {
  const { organizationId, dashboardId } = useParams();
  const navigate = useNavigate();

  const [showAiToken, setShowAiToken] = useState(null);

  const [invitedEmails, setInvitedEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');

  const { data: summary, isFetching: summaryFetching } = useGetQuery(
    'report-summary-widget',
    apiEndpoints.GET_SUMMARY_WIDGET(organizationId, dashboardId),
    {},
    {
      // enabled: !!showAiToken,
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      // onError: ({ response: { data } }) => {
      //   toast.error(data?.detail);
      // },
    }
  );

  const initialValues = {
    email: '',
    subject: '',
    description: '',
    date: '',
    time: '',
    frequency: '',
    type: '',
    dayChecked: [],
    monthly: '',
    scheduleMonthly: '',
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required(FIELD_REQUIRED('Subject')),
    date: Yup.string().required(FIELD_REQUIRED('Date')),
    time: Yup.string().required(FIELD_REQUIRED('Time')),
    frequency: Yup.number().positive().min(1),
  });

  const validate = values => {
    const errors = {};
    validateCheckField(
      !(isEmpty(values.type.id) && isEmpty(values.dayChecked)) && !values.frequency,
      'frequency',
      FIELD_REQUIRED('Field'),
      errors
    );
    validateCheckField(
      !(!values.frequency && isEmpty(values.dayChecked)) && isEmpty(values.type.id),
      'type',
      FIELD_REQUIRED('Field'),
      errors
    );
    validateCheckField(
      !(!values.frequency && isEmpty(values.type.id)) && isEmpty(values.dayChecked) && values.type.id !== MONTHLY,
      'dayChecked',
      FIELD_REQUIRED('Field'),
      errors
    );
    validateCheckField(
      !(!values.frequency && isEmpty(values.type.id)) && isEmpty(values.scheduleMonthly) && values.type.id === MONTHLY,
      'scheduleMonthly',
      FIELD_REQUIRED('Field'),
      errors
    );
    return errors;
  };

  const onSubmit = () => {
    if (isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    } else if (!isEmpty(values.email)) {
      setEmailFieldErrorMessage(PRESS_ENTER_MESSAGE);
    } else {
      let payload = {
        dashboard: dashboardId,
        subject: values.subject,
        description: values.description,
        recipients: invitedEmails.join(','),
        date: moment(values.date).format(DATE_FORMAT.REPORT_FORMAT),
        time: formatTime(values.time),
        scheduledFrequency: values.frequency,
        scheduledType: values?.type?.id ? values?.type?.id : ONE_TIME,
        scheduledDay: values.dayChecked.join(','),
        scheduledMonth: values.scheduleMonthly.id,
        scheduledLabel: values.scheduleMonthly.name,
        scheduledRegion: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      values.dayChecked.join(',') === '' && delete payload['scheduledDay'];
      values?.scheduleMonthly?.value === 2 && delete payload['scheduledDay'];
      values?.type?.id !== MONTHLY && delete payload['scheduledMonth'];
      values?.type?.id === '' && delete payload['scheduledLabel'];
      !values?.frequency && delete payload['scheduledFrequency'];

      if (handleTimeChange(values.date, values.time).isPast) {
        toast.error(PAST_TIME_ERROR_MESSAGE);
        return;
      } else {
        handleCreateReport({ payload });
      }
    }
  };

  const formik = useFormik({ initialValues, validate, onSubmit, validationSchema, enableReinitialize: true });

  const { values, errors, setFieldValue, isSubmitting } = formik;

  const handleInvitedEmailKeyDown = e => {
    if (e.code === ENTER || e.keyCode === ENTER_KEY) {
      const match = values.email.match(EMAIL_REGEX);

      if (match) {
        setInvitedEmails(uniq([...invitedEmails, values.email.toLowerCase()]));
        setFieldValue('email', '');
        setEmailFieldErrorMessage('');
      }
    }
  };

  const handleRemoveInvitedEmail = selectedEmail =>
    setInvitedEmails(invitedEmails.filter(email => email !== selectedEmail));

  const handleSelectedDay = value => {
    setFieldValue(
      'dayChecked',
      values.dayChecked.includes(value)
        ? values.dayChecked.filter(item => item !== value)
        : [...values.dayChecked, value]
    );
  };

  const { mutate: handleCreateReport, isLoading: createReportLoading } = usePostMutation(
    apiEndpoints.CREATE_REPORT(organizationId),
    () => {
      navigate(ROUTES.SCHEDULED_REPORTS);
      toast.success(SUCCESSFUL_MESSAGE('Report created'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  useEffect(() => {
    if (isSubmitting && isEmpty(invitedEmails)) {
      isEmpty(values.email) && setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    }

    if (values.email) {
      const match = values.email.match(EMAIL_REGEX);
      match ? setEmailFieldErrorMessage('') : setEmailFieldErrorMessage(INVALID_EMAIL_ADDRESS);
    } else if (!isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage('');
    }
  }, [isSubmitting, errors, values.email]);

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const token = getLocalStorageItem('showAiToken');
      if (!showAiToken && token) {
        setShowAiToken(token);
      }
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [showAiToken]);

  useEffect(() => {
    if (showAiToken) {
      clearInterval(intervalRef.current);
    }

    if (summaryFetching && showAiToken) {
      setFieldValue('description', 'Summary loading ...');
    } else {
      setFieldValue('description', summary?.data?.text || '');
    }
  }, [summary?.data?.text, showAiToken, summaryFetching, setFieldValue]);

  return {
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    emailFieldErrorMessage,
    invalidEmails,
    setInvalidEmails,
    handleSelectedDay,
    createReportLoading,
  };
}
