import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ScrollArea,
  ScrollBar,
  Dialog,
} from '@shadcnComponent';
import moment from 'moment';
import { BaseLoader, BaseToolTip, DeleteDialog } from '@common/components';
import { DashboardActionPopover, DataSourcePopover, TeamPopover } from '@pages/dashboard';
import { ROUTES } from '@routes';
import { truncateString } from '@utils';

export function DashboardTabularView({
  dashboardsListingFetching,
  dashboardsListing,
  setDashboardId,
  handleDeleteDashboard,
  selectedDashboard,
  setSelectedDashboard,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteDashboardLoading,
  handleDuplicateDashboard,
  isMember,
  setDeleteDashboardLoading,
  page,
  viewType,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  return (
    <>
      <div className='mt-6 h-full md:mt-0'>
        <ScrollArea className='relative z-[1] h-full w-[calc(100vw-2.5rem)] whitespace-nowrap rounded-md border md:w-[calc(100vw-4rem)] lg:h-[calc(100vh-12.875rem)] lg:w-auto xxl:h-[calc(100vh-17rem)]'>
          <Table className='text-lg'>
            <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
              <TableRow>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-9 xxl:px-[3.75rem] xxl:pb-3 xxl:pt-3.5'>
                  Name
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-9 xxl:px-[3.75rem] xxl:pb-3 xxl:pt-3.5'>
                  Last Update
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-9 xxl:px-[3.75rem] xxl:pb-3 xxl:pt-3.5'>
                  Teams With Access
                </TableHead>
                <TableHead className='px-6 py-3.5 md:w-[11.25rem] md:px-[1.875rem] md:py-3.5 lg:w-[11.25rem] lg:px-9 xxl:w-[15.625rem] xxl:px-[3.75rem] xxl:pb-3 xxl:pt-3.5'>
                  Data Sources
                </TableHead>
                <TableHead className='px-6 py-3.5 md:w-[11.25rem] md:px-[1.875rem] md:py-3.5 lg:w-[11.25rem] lg:px-9 xxl:w-[15.625rem] xxl:px-[3.75rem] xxl:pb-3 xxl:pt-3.5'>
                  Action
                </TableHead>
              </TableRow>
            </TableHeader>
            {dashboardsListingFetching ? (
              <BaseLoader />
            ) : (
              <TableBody className='pt-5'>
                {dashboardsListing?.data?.results?.map(dashboard => (
                  <TableRow
                    key={dashboard?.id}
                    onClick={() =>
                      navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: dashboard?.id }), {
                        state: { page, viewType },
                      })
                    }
                  >
                    <TableCell className='px-6 py-3.5 font-medium md:px-[1.875rem] md:py-3.5 lg:px-9 lg:py-2.5 xxl:px-[3.75rem] xxl:py-3.5'>
                      <p id={`dashboardName-${dashboard?.id}`} className='lg:w-20 xxl:w-24'>
                        {truncateString(dashboard?.name, 24, 'text-darkSecondary')}
                      </p>
                      {dashboard?.name?.length > 24 && (
                        <BaseToolTip
                          className='w-full'
                          id={`dashboardName-${dashboard?.id}`}
                          content={dashboard?.name}
                        />
                      )}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-9 lg:py-2.5 xxl:px-[3.75rem] xxl:py-3.5'>
                      {moment(dashboard?.modified).fromNow()}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-9 lg:py-2.5 xxl:px-[3.75rem] xxl:py-3.5'>
                      <TeamPopover dashboard={dashboard} />
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-9 lg:py-2.5 xxl:px-[3.75rem] xxl:py-3.5'>
                      <DataSourcePopover dashboard={dashboard} />
                    </TableCell>
                    <TableCell className='px-6 py-3.5 font-medium md:px-[1.875rem] md:py-3.5 lg:px-9 lg:py-2.5 xxl:px-[3.75rem] xxl:py-3.5'>
                      <DashboardActionPopover
                        dashboard={dashboard}
                        setDashboardId={setDashboardId}
                        handleDuplicateDashboard={handleDuplicateDashboard}
                        setSelectedDashboard={setSelectedDashboard}
                        setShowDeleteDialog={setShowDeleteDialog}
                        showDeleteDialog={showDeleteDialog}
                        isMember={isMember}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DeleteDialog
          heading={selectedDashboard?.name}
          text={selectedDashboard?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleDeleteDashboard();
            setDeleteDashboardLoading(true);
          }}
          isLoading={deleteDashboardLoading}
        />
      </Dialog>
    </>
  );
}
