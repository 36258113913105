export function ErrorIcon() {
  return (
    <svg width='100%' height='100%' viewBox='0 0 279 287' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.1'
        d='M269.526 169.619C270.923 151.057 266.605 131.677 260.821 114.428C252.214 88.747 235.552 62.4299 207.502 56.352C191.308 52.8589 174.828 56.6035 160.946 65.3781C147.589 73.8175 137.193 84.7229 120.762 86.8327C104.155 88.9705 87.1231 88.1112 70.461 88.1112C27.5448 88.1112 -7.28143 133.193 1.42337 180.154C10.1282 227.116 51.1092 259.678 85.9424 259.678C117.241 259.678 142.985 240.906 172.711 235.862C187.452 233.361 202.256 236.323 216.843 232.579C233.798 228.219 249.741 217.831 259.137 202.79C265.537 192.534 268.652 181.237 269.526 169.619Z'
        fill='#00ACE7'
      />
      <path
        d='M264.513 163.102C264.513 163.102 264.513 159.609 259.623 154.767C254.732 149.926 251.631 147.117 251.631 147.117C251.631 147.117 249.199 155.109 253.726 159.979C257.048 163.401 260.652 166.537 264.499 169.354L264.513 163.102Z'
        fill='#00ACE7'
      />
      <path
        d='M257.451 184.801C257.451 184.801 257.451 181.308 252.56 176.466C247.67 171.625 244.568 168.816 244.568 168.816C244.568 168.816 242.137 176.809 246.664 181.678C249.987 185.098 253.591 188.234 257.437 191.053L257.451 184.801Z'
        fill='#00ACE7'
      />
      <path
        d='M264.207 169.02C264.207 169.02 264.57 165.568 269.921 161.244C275.273 156.919 278.682 154.488 278.682 154.488C278.682 154.488 280.254 162.69 275.245 167.063C271.58 170.116 267.666 172.855 263.543 175.251L264.207 169.02Z'
        fill='#00ACE7'
      />
      <path
        d='M255.711 194.642C255.711 194.642 256.409 191.247 262.215 187.509C268.02 183.772 271.632 181.711 271.632 181.711C271.632 181.711 272.331 190.038 266.896 193.846C262.931 196.494 258.753 198.808 254.404 200.762L255.711 194.642Z'
        fill='#00ACE7'
      />
      <path
        d='M257.281 222.59C257.281 222.59 257.281 219.097 262.172 214.248C267.062 209.4 270.164 206.605 270.164 206.605C270.164 206.605 272.595 214.598 268.068 219.46C264.749 222.884 261.148 226.023 257.302 228.843L257.281 222.59Z'
        fill='#00ACE7'
      />
      <path
        d='M257.199 229.407C257.199 229.407 257.695 225.97 253.566 220.465C249.437 214.959 246.748 211.76 246.748 211.76C246.748 211.76 243.199 219.326 246.978 224.782C249.781 228.642 252.903 232.259 256.312 235.597L257.199 229.407Z'
        fill='#00ACE7'
      />
      <path
        d='M252.398 249.619C252.398 249.619 252.398 246.126 257.289 241.285C262.179 236.443 265.281 233.635 265.281 233.635C265.281 233.635 267.712 241.627 263.185 246.496C259.863 249.919 256.259 253.058 252.412 255.879L252.398 249.619Z'
        fill='#00ACE7'
      />
      <path
        d='M255.646 267.945C255.646 267.945 254.766 264.584 258.245 258.646C261.724 252.708 264.037 249.229 264.037 249.229C264.037 249.229 268.417 256.34 265.28 262.195C262.93 266.345 260.238 270.292 257.232 273.995L255.646 267.945Z'
        fill='#00ACE7'
      />
      <path
        d='M264.952 162.215C264.705 165.832 264.26 169.433 263.618 173.002C261.696 182.936 256.89 189.349 254.962 198.32C253.034 207.29 258.56 218.852 257.058 230.379C255.556 241.906 251.091 248.313 252.37 256.005C253.648 263.696 260.383 284.529 260.383 284.529'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M264.156 171.331L269.396 167.307'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M264.401 166.698L262.207 162.793'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M255.256 197.905C255.256 197.905 259.643 194.615 264.156 191.324'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M257.45 191.053C257.45 191.053 253.796 183.885 249.646 179.135'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M257.449 227.534C259.955 222.911 263.118 218.675 266.839 214.959'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M256.6 232.412C255.269 229.277 253.68 226.258 251.849 223.386C249.285 219.606 248.922 219.117 248.922 219.117'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M252.398 256.002C253.512 253.249 254.834 250.584 256.353 248.031C257.872 245.658 259.585 243.415 261.474 241.324'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M257.235 273.995C256.985 271.362 257.438 268.71 258.549 266.31C260.379 262.775 262.454 258.143 263.306 256.312'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M224.811 209.526C224.811 209.526 224.434 207.346 226.956 203.762C229.478 200.178 231.148 198.104 231.148 198.104C231.148 198.104 233.544 202.854 231.238 206.389C229.526 208.897 227.608 211.257 225.503 213.445L224.811 209.526Z'
        fill='#00ACE7'
      />
      <path
        d='M231.592 222.366C231.592 222.366 231.215 220.186 233.737 216.602C236.259 213.018 237.929 210.943 237.929 210.943C237.929 210.943 240.318 215.694 238.02 219.236C236.306 221.74 234.388 224.098 232.284 226.285L231.592 222.366Z'
        fill='#00ACE7'
      />
      <path
        d='M225.67 213.199C225.67 213.199 225.062 211.104 221.241 208.945C217.419 206.786 215.016 205.654 215.016 205.654C215.016 205.654 214.918 210.971 218.509 213.164C221.14 214.679 223.893 215.971 226.739 217.028L225.67 213.199Z'
        fill='#00ACE7'
      />
      <path
        d='M233.78 228.346C233.78 228.346 232.949 226.292 228.925 224.574C224.901 222.855 222.393 221.961 222.393 221.961C222.393 221.961 222.854 227.256 226.675 229.059C229.449 230.288 232.321 231.284 235.261 232.035L233.78 228.346Z'
        fill='#00ACE7'
      />
      <path
        d='M235.805 246.042C235.805 246.042 235.428 243.862 231.851 241.34C228.274 238.818 226.01 237.414 226.01 237.414C226.01 237.414 225.347 242.689 228.707 245.246C231.162 247.033 233.763 248.611 236.483 249.961L235.805 246.042Z'
        fill='#00ACE7'
      />
      <path
        d='M236.596 250.31C236.596 250.31 235.898 248.214 237.903 244.302C239.908 240.39 241.235 238.105 241.235 238.105C241.235 238.105 244.281 242.465 242.5 246.3C241.163 249.023 239.599 251.629 237.826 254.09L236.596 250.31Z'
        fill='#00ACE7'
      />
      <path
        d='M242.702 267.879C242.702 267.879 242.953 265.686 246.383 262.989C249.814 260.292 251.972 258.734 251.972 258.734C251.972 258.734 252.929 263.967 249.716 266.713C247.367 268.634 244.862 270.357 242.227 271.861L242.702 267.879Z'
        fill='#00ACE7'
      />
      <path
        d='M241.801 262.458C241.801 262.458 241.424 260.278 237.847 257.756C234.27 255.234 231.999 253.83 231.999 253.83C231.999 253.83 231.342 259.105 234.703 261.669C237.159 263.453 239.76 265.031 242.478 266.384L241.801 262.458Z'
        fill='#00ACE7'
      />
      <path
        d='M241.739 274.307C241.739 274.307 241.928 272.099 239.106 268.753C236.283 265.407 234.446 263.479 234.446 263.479C234.446 263.479 232.476 268.411 235.075 271.743C236.999 274.09 239.116 276.273 241.404 278.268L241.739 274.307Z'
        fill='#00ACE7'
      />
      <path
        d='M224.439 209.008C224.986 211.25 225.655 213.461 226.444 215.631C228.729 221.653 232.439 225.153 234.618 230.567C236.798 235.982 234.618 243.841 236.77 250.904C238.922 257.967 242.457 261.502 242.492 266.462C242.527 271.423 240.564 285.22 240.564 285.22'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M225.929 214.639L222.205 212.689'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M225.271 211.759L226.229 209.076'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M234.395 230.344C234.395 230.344 231.286 228.759 228.107 227.18'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M232.279 226.283C233.192 223.404 234.395 220.625 235.87 217.99'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M236.234 249.158C234.163 246.533 231.72 244.224 228.982 242.305'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M237.303 252.119C237.796 250.011 238.464 247.947 239.301 245.95C240.495 243.302 240.698 242.953 240.698 242.953'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M242.324 270.227C243.533 268.725 244.872 267.334 246.327 266.07C247.254 265.388 248.123 264.631 248.926 263.807'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M242.492 266.462C241.494 264.852 240.375 263.32 239.145 261.879C237.934 260.557 236.616 259.338 235.205 258.232'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M241.416 278.267C241.287 276.587 240.713 274.972 239.753 273.587C238.223 271.568 236.421 268.885 235.688 267.83'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.3881 179.091L23.3281 170.351L26.3881 174.061L23.9778 160.948L27.6945 170.351L29.0079 151.984L31.1946 169.471L33.5978 165.978V170.351L36.4412 164.448L34.9112 174.717L37.5311 172.971L33.3813 178.651L26.3881 179.091Z'
        fill='#00ACE7'
      />
      <path
        d='M36.0013 176.904H24.6348L26.6817 199.854H33.9613L36.0013 176.904Z'
        fill='#263238'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.3222 199.596H18.5977V281.614H57.3222V199.596Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M59.4396 199.596H37.957V281.614H59.4396V199.596Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.0224 199.596H37.957V281.614H57.0224V199.596Z'
        fill='#263238'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 262.541H37.957V279.336H54.8776V262.541Z'
        fill='#00ACE7'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 263.449H37.957V265.601H54.8776V263.449Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 275.814H37.957V277.966H54.8776V275.814Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.1719 262.891V284.129'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M47.0664 262.891V284.129'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M52.25 262.891V284.129'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 202.641H37.957V227.337H54.8776V202.641Z'
        fill='#00ACE7'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 203.676H37.957V206.121H54.8776V203.676Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 219.506H37.957V221.951H54.8776V219.506Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.1719 203.033V227.171'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M47.0664 203.033V227.171'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M52.25 203.033V227.171'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 232.402H37.957V257.099H54.8776V232.402Z'
        fill='#00ACE7'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 233.436H37.957V235.881H54.8776V233.436Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.8776 249.268H37.957V251.713H54.8776V249.268Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.1719 232.801V256.931'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M47.0664 232.801V256.931'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M52.25 232.801V256.931'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.3158 226.994H37.957V229.803H57.3158V226.994Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.3158 256.945H37.957V259.754H57.3158V256.945Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.3223 199.596V281.607'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.2324 199.588V281.606H37.957V202.641H56.9874V199.561L35.2324 199.588Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.957 202.642V199.596'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.1735 161.916C55.1336 157.996 54.2812 155.188 54.2812 154.538C54.2812 153.253 57.0757 152.484 57.0757 152.484C61.4281 150.179 81.9326 153.511 91.1614 155.048C96.3276 155.949 101.554 156.463 106.797 156.585C106.797 156.585 108.082 155.565 109.591 154.28C111.1 152.994 118.303 144.541 121.125 143.8C123.948 143.06 126.505 144.827 126.505 144.827C126.505 144.827 131.122 147.622 130.354 148.16C129.585 148.698 118.303 158.409 115.487 160.204C112.672 161.999 106.769 161.231 101.648 162.258C97.2324 163.138 79.3477 164.228 75.2468 164.563C72.167 165.293 68.9347 165.024 66.0181 163.795'
        fill='white'
      />
      <mask id='mask0_2471_24399' maskUnits='userSpaceOnUse' x='54' y='143' width='77' height='22'>
        <path
          d='M57.1735 161.916C55.1336 157.996 54.2812 155.188 54.2812 154.538C54.2812 153.253 57.0757 152.484 57.0757 152.484C61.4281 150.179 81.9326 153.511 91.1614 155.048C96.3276 155.949 101.554 156.463 106.797 156.585C106.797 156.585 108.082 155.565 109.591 154.28C111.1 152.994 118.303 144.541 121.125 143.8C123.948 143.06 126.505 144.827 126.505 144.827C126.505 144.827 131.122 147.622 130.354 148.16C129.585 148.698 118.303 158.409 115.487 160.204C112.672 161.999 106.769 161.231 101.648 162.258C97.2324 163.138 79.3477 164.228 75.2468 164.563C72.167 165.293 68.9347 165.024 66.0181 163.795'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2471_24399)'>
        <path
          d='M126.563 144.8C126.563 144.8 124.006 143.005 121.184 143.773C118.361 144.542 111.187 153.002 109.65 154.253C108.113 155.503 106.855 156.558 106.855 156.558C101.612 156.435 96.3862 155.922 91.22 155.021C81.9912 153.484 61.4867 150.131 57.1343 152.457C57.1343 152.457 54.3398 153.226 54.3398 154.511C54.4372 155.23 54.6249 155.935 54.8987 156.607L55.283 156.747C55.283 156.747 60.4039 156.209 71.449 156.474C82.4942 156.74 108.071 158.256 109.705 158.85C110.446 159.115 115.357 157.997 120.848 156.055C125.04 152.639 129.965 148.37 130.468 148.07C131.181 147.616 126.563 144.8 126.563 144.8Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M126.563 144.8C126.563 144.8 124.006 143.005 121.184 143.773C118.361 144.542 111.187 153.002 109.65 154.253C108.113 155.503 106.855 156.558 106.855 156.558C101.612 156.435 96.3862 155.922 91.22 155.021C81.9912 153.484 61.4867 150.131 57.1343 152.457C57.1343 152.457 54.3398 153.226 54.3398 154.511C54.4372 155.23 54.6249 155.935 54.8987 156.607L55.283 156.747C55.283 156.747 60.4039 156.209 71.449 156.474C82.4942 156.74 108.071 158.256 109.705 158.85C110.446 159.115 115.357 157.997 120.848 156.055C125.04 152.639 129.965 148.37 130.468 148.07C131.181 147.616 126.563 144.8 126.563 144.8Z'
          fill='white'
        />
      </g>
      <path
        d='M57.1735 161.916C55.1336 157.996 54.2812 155.188 54.2812 154.538C54.2812 153.253 57.0757 152.484 57.0757 152.484C61.4281 150.179 81.9326 153.511 91.1614 155.048C96.3276 155.949 101.554 156.463 106.797 156.585C106.797 156.585 108.082 155.565 109.591 154.28C111.1 152.994 118.303 144.541 121.125 143.8C123.948 143.06 126.505 144.827 126.505 144.827C126.505 144.827 131.122 147.622 130.354 148.16C129.585 148.698 118.303 158.409 115.487 160.204C112.672 161.999 106.769 161.231 101.648 162.258C97.2324 163.138 79.3477 164.228 75.2468 164.563C72.167 165.293 68.9347 165.024 66.0181 163.795'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M158.405 184.706C158.869 183.826 159.565 183.089 160.417 182.576C161.269 182.062 162.245 181.792 163.24 181.793H209.845C209.845 181.793 213.212 181.793 212.199 188.192C211.186 194.592 197.381 233.666 194.035 245.479C190.689 257.293 189.997 282.192 189.997 282.192H134.107C134.107 282.192 138.53 222.648 158.405 184.706Z'
        fill='white'
      />
      <mask id='mask1_2471_24399' maskUnits='userSpaceOnUse' x='134' y='181' width='79' height='102'>
        <path
          d='M158.403 184.708C158.867 183.828 159.563 183.091 160.415 182.578C161.267 182.064 162.243 181.794 163.238 181.795H209.843C209.843 181.795 213.21 181.795 212.197 188.194C211.184 194.594 197.379 233.667 194.033 245.481C190.687 257.295 189.995 282.194 189.995 282.194H134.105C134.105 282.194 138.528 222.65 158.403 184.708Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_2471_24399)'>
        <path
          opacity='0.16'
          d='M179.396 181.795H163.237C162.242 181.794 161.266 182.064 160.414 182.578C159.562 183.091 158.866 183.828 158.403 184.708C138.527 222.65 134.049 282.173 134.049 282.173H159.122L165.298 258.692L191.077 264.009C191.259 262.038 191.475 260.033 191.713 258.056L191.154 251.769C191.154 251.769 185.872 246.215 184.168 244.824C182.463 243.434 183.609 240.926 183.336 230.091C183.064 219.255 173.325 211.465 169.713 204.793C166.102 198.122 171.656 193.951 176.665 187.279C178.481 184.869 179.201 183.094 179.396 181.795Z'
          fill='black'
        />
      </g>
      <path
        d='M158.405 184.706C158.869 183.826 159.565 183.089 160.417 182.576C161.269 182.062 162.245 181.792 163.24 181.793H209.845C209.845 181.793 213.212 181.793 212.199 188.192C211.186 194.592 197.381 233.666 194.035 245.479C190.689 257.293 189.997 282.192 189.997 282.192H134.107C134.107 282.192 138.53 222.648 158.405 184.706Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M201.753 192.907C197.044 203.897 187.082 247.4 185.461 282.17H189.967C189.967 282.17 190.666 257.243 194.005 245.458C197.344 233.672 211.184 194.591 212.169 188.171C213.154 181.751 209.815 181.771 209.815 181.771C209.815 181.771 205.532 184.098 201.753 192.907Z'
        fill='#263238'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M119.903 170.943C119.903 170.943 112.47 166.584 109.647 158.899C106.825 151.214 131.689 138.904 142.483 138.136C153.276 137.368 156.322 140.183 162.743 151.207C169.163 162.231 173.508 177.35 170.176 184.783C166.843 192.216 161.206 194.012 165.286 200.152C169.365 206.293 180.921 223.731 180.152 231.933C179.384 240.134 171.433 247.302 159.892 248.085C148.351 248.867 132.981 241.93 120.162 230.619C107.342 219.309 110.681 202.674 115.271 197.295C119.861 191.916 126.806 189.862 127.323 184.482C127.84 179.103 119.903 170.943 119.903 170.943Z'
        fill='#263238'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M151.613 158.781C155.567 166.131 160.5 175.974 162.044 181.92C164.74 192.329 150.866 198.882 154.722 203.891'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M146.207 149.152C146.207 149.152 147.737 151.772 149.868 155.629'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M166.906 167.646C167.36 169.025 167.658 170.449 167.794 171.894'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.902 145.332C148.902 145.332 161.037 154.302 165.886 165.04'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M132.051 152.059C123.381 154.49 110.918 158.437 111.931 160.714'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.038 149.922C140.038 149.922 138.179 150.383 135.42 151.123'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M129.299 145.396C134.265 143.831 139.466 143.145 144.668 143.37'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M125.766 146.841C126.551 146.432 127.363 146.077 128.197 145.779'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.2956 154.728C54.2956 154.77 54.2956 154.819 54.2956 154.881C54.3096 154.819 54.3026 154.77 54.2956 154.728ZM54.2956 154.923C54.2956 154.986 54.2957 155.049 54.3376 155.126C54.3585 155.049 54.3446 154.986 54.3306 154.923H54.2956ZM57.139 161.91C59.7494 166.86 63.0003 171.445 66.8079 175.547C76.0367 185.544 102.689 211.693 105.232 213.489C107.775 215.284 107.027 220.405 105.232 225.533C103.437 230.661 96.7717 238.108 97.0302 246.296C97.2887 254.484 99.8247 267.059 99.5942 269.623C99.3636 272.187 92.9293 280.898 92.9293 280.898H159.061C159.061 280.898 150.915 270.433 150.915 266.842C150.915 263.251 162.225 217.338 162.742 214.006C163.259 210.673 161.205 208.368 157.105 208.109C153.004 207.851 137.362 204.518 136.593 201.954C135.825 199.39 140.694 189.652 140.694 189.652L125.324 190.42C125.324 190.42 124.556 197.085 123.529 198.37C122.502 199.656 120.965 197.085 117.381 195.297C113.797 193.508 106.867 191.964 104.303 189.91C101.739 187.856 77.1335 164.76 77.1335 164.76'
        fill='white'
      />
      <mask id='mask2_2471_24399' maskUnits='userSpaceOnUse' x='54' y='154' width='109' height='127'>
        <path
          d='M54.2956 154.728C54.2956 154.77 54.2956 154.819 54.2956 154.881C54.3096 154.819 54.3026 154.77 54.2956 154.728ZM54.2956 154.923C54.2956 154.986 54.2957 155.049 54.3376 155.126C54.3585 155.049 54.3446 154.986 54.3306 154.923H54.2956ZM57.139 161.91C59.7494 166.86 63.0003 171.445 66.8079 175.547C76.0367 185.544 102.689 211.693 105.232 213.489C107.775 215.284 107.027 220.405 105.232 225.533C103.437 230.661 96.7717 238.108 97.0302 246.296C97.2887 254.484 99.8247 267.059 99.5942 269.623C99.3636 272.187 92.9293 280.898 92.9293 280.898H159.061C159.061 280.898 150.915 270.433 150.915 266.842C150.915 263.251 162.225 217.338 162.742 214.006C163.259 210.673 161.205 208.368 157.105 208.109C153.004 207.851 137.362 204.518 136.593 201.954C135.825 199.39 140.694 189.652 140.694 189.652L125.324 190.42C125.324 190.42 124.556 197.085 123.529 198.37C122.502 199.656 120.965 197.085 117.381 195.297C113.797 193.508 106.867 191.964 104.303 189.91C101.739 187.856 77.1335 164.76 77.1335 164.76'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_2471_24399)'>
        <path
          d='M108.186 191.889C109.471 194.124 110.742 196.143 110.742 196.143C110.742 196.143 115.053 198.567 116.136 198.567C117.219 198.567 123.681 202.061 123.681 202.061C123.681 202.061 129.067 200.712 127.174 196.129C126.577 194.955 125.82 193.868 124.924 192.902C124.61 194.935 124.086 197.596 123.478 198.358C122.451 199.636 120.914 197.072 117.33 195.284C114.99 194.124 111.232 193.062 108.186 191.889Z'
          fill='#00ACE7'
        />
        <path
          d='M95.0223 181.514C87.1 174.186 77.0817 164.79 77.0817 164.79L71.2553 163.951L70.5566 164.342C70.5566 164.342 86.9952 178.895 90.5022 180.515C91.9199 181.169 93.4614 181.509 95.0223 181.514Z'
          fill='#00ACE7'
        />
        <path
          d='M103.324 189.1L102.695 188.541C103.01 189.072 103.198 189.407 103.198 189.407C103.198 189.407 103.24 189.289 103.324 189.1Z'
          fill='#00ACE7'
        />
        <g opacity='0.5'>
          <path
            d='M108.184 191.889C109.469 194.124 110.741 196.143 110.741 196.143C110.741 196.143 115.051 198.567 116.134 198.567C117.217 198.567 123.679 202.061 123.679 202.061C123.679 202.061 129.065 200.712 127.172 196.129C126.575 194.955 125.818 193.868 124.923 192.902C124.608 194.935 124.084 197.596 123.476 198.358C122.449 199.636 120.912 197.072 117.329 195.284C114.988 194.124 111.23 193.062 108.184 191.889Z'
            fill='white'
          />
          <path
            d='M95.0223 181.514C87.1 174.186 77.0817 164.79 77.0817 164.79L71.2553 163.951L70.5566 164.342C70.5566 164.342 86.9952 178.895 90.5022 180.515C91.9199 181.169 93.4614 181.509 95.0223 181.514Z'
            fill='white'
          />
          <path
            d='M103.322 189.1L102.693 188.541C103.008 189.072 103.196 189.407 103.196 189.407C103.196 189.407 103.238 189.289 103.322 189.1Z'
            fill='white'
          />
        </g>
      </g>
      <path
        d='M54.2817 154.539C54.2785 154.588 54.2785 154.637 54.2817 154.686M57.174 161.916C55.9954 159.763 55.0582 157.485 54.3795 155.126M57.139 161.91C59.7494 166.86 63.0003 171.445 66.8079 175.547C76.0367 185.544 102.689 211.693 105.232 213.489C107.775 215.284 107.027 220.405 105.232 225.533C103.437 230.661 96.7717 238.108 97.0302 246.296C97.2887 254.484 99.8247 267.059 99.5942 269.623C99.3636 272.187 92.9293 280.898 92.9293 280.898H159.061C159.061 280.898 150.915 270.433 150.915 266.842C150.915 263.251 162.225 217.338 162.742 214.006C163.259 210.673 161.205 208.368 157.105 208.109C153.004 207.851 137.362 204.518 136.593 201.954C135.825 199.39 140.694 189.652 140.694 189.652L125.324 190.42C125.324 190.42 124.556 197.085 123.529 198.37C122.502 199.656 120.965 197.085 117.381 195.297C113.797 193.508 106.867 191.964 104.303 189.91C101.739 187.856 77.1335 164.76 77.1335 164.76M54.2956 154.728C54.2956 154.77 54.2956 154.819 54.2956 154.881C54.3096 154.819 54.3026 154.77 54.2956 154.728ZM54.2956 154.923C54.2956 154.986 54.2957 155.049 54.3376 155.126C54.3585 155.049 54.3446 154.986 54.3306 154.923H54.2956Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M77.0908 164.79C77.0908 164.79 68.9099 164.943 64.334 161.178'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.558 201.955C135.789 199.398 140.659 189.652 140.659 189.652L125.289 190.421C125.289 190.421 124.521 197.086 123.494 198.371C122.467 199.657 120.93 197.086 117.346 195.297C117.073 195.157 116.773 195.025 116.465 194.892L115.529 209.5C115.529 209.5 116.878 223.647 133.044 220.957C139.59 219.868 146.576 214.027 152.605 207.46C146.401 206.321 137.145 203.918 136.558 201.955Z'
        fill='white'
      />
      <mask id='mask3_2471_24399' maskUnits='userSpaceOnUse' x='115' y='189' width='38' height='33'>
        <path
          d='M136.558 201.953C135.789 199.396 140.659 189.65 140.659 189.65L125.289 190.419C125.289 190.419 124.521 197.084 123.494 198.369C122.467 199.655 120.93 197.084 117.346 195.295C117.073 195.156 116.773 195.023 116.465 194.89L115.529 209.498C115.529 209.498 116.878 223.645 133.044 220.956C139.59 219.866 146.576 214.025 152.605 207.458C146.401 206.319 137.145 203.916 136.558 201.953Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask3_2471_24399)'>
        <path
          d='M136.125 191.585C136.125 191.585 135.195 196.797 134.448 202.561C133.7 208.324 133.148 212.614 133.148 212.614L136.558 201.953L140.435 190.656C140.435 190.656 136.683 190.656 136.125 191.585Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M136.125 191.585C136.125 191.585 135.195 196.797 134.448 202.561C133.7 208.324 133.148 212.614 133.148 212.614L136.558 201.953L140.435 190.656C140.435 190.656 136.683 190.656 136.125 191.585Z'
          fill='white'
        />
      </g>
      <path
        d='M136.558 201.955C135.789 199.398 140.659 189.652 140.659 189.652L125.289 190.421C125.289 190.421 124.521 197.086 123.494 198.371C122.467 199.657 120.93 197.086 117.346 195.297C117.073 195.157 116.773 195.025 116.465 194.892L115.529 209.5C115.529 209.5 116.878 223.647 133.044 220.957C139.59 219.868 146.576 214.027 152.605 207.46C146.401 206.321 137.145 203.918 136.558 201.955Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M123.486 198.37C122.459 199.649 120.922 197.085 117.339 195.296C115.068 194.158 111.47 193.124 108.48 192.006C107.635 190.001 106.517 187.388 105.804 185.809C104.519 183.015 102.472 184.782 102.472 184.782C101.4 183.565 100.536 182.18 99.915 180.681C98.6295 177.887 96.5826 178.117 93.7601 177.607C90.9377 177.097 85.0484 171.711 85.0484 171.711C85.0484 171.711 82.2888 169.615 81.1431 168.574C79.8227 171.187 76.637 175.784 69.7695 178.655C80.7239 190.106 102.968 211.861 105.294 213.495C107.858 215.284 107.09 220.412 105.294 225.54C103.499 230.668 96.8341 238.115 97.0926 246.303C97.3511 254.49 99.887 267.066 99.6565 269.63C99.426 271.914 94.319 279.075 93.2082 280.612L95.8141 280.905H109.374C109.297 274.031 109.304 245.974 114.013 234.796C119.386 221.949 123.486 198.37 123.486 198.37Z'
        fill='#00ACE7'
      />
      <mask id='mask4_2471_24399' maskUnits='userSpaceOnUse' x='69' y='168' width='55' height='113'>
        <path
          d='M123.486 198.372C122.459 199.651 120.922 197.087 117.339 195.298C115.068 194.16 111.47 193.126 108.48 192.008C107.635 190.003 106.517 187.39 105.804 185.811C104.519 183.017 102.472 184.784 102.472 184.784C101.4 183.567 100.536 182.182 99.915 180.683C98.6295 177.889 96.5826 178.119 93.7601 177.609C90.9377 177.099 85.0484 171.713 85.0484 171.713C85.0484 171.713 82.2888 169.617 81.1431 168.576C79.8227 171.189 76.637 175.786 69.7695 178.657C80.7239 190.108 102.968 211.863 105.294 213.497C107.858 215.286 107.09 220.414 105.294 225.542C103.499 230.67 96.8341 238.117 97.0926 246.305C97.3511 254.492 99.887 267.068 99.6565 269.632C99.426 271.916 94.319 279.077 93.2082 280.614L95.8141 280.907H109.374C109.297 274.033 109.304 245.976 114.013 234.798C119.386 221.951 123.486 198.372 123.486 198.372Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask4_2471_24399)'>
        <path
          opacity='0.16'
          d='M117.338 195.298C115.067 194.16 111.469 193.126 108.479 192.008C107.634 190.003 106.516 187.39 105.803 185.811C104.518 183.017 102.471 184.784 102.471 184.784C101.399 183.567 100.535 182.182 99.914 180.683C98.6285 177.889 96.5816 178.119 93.7591 177.609C90.9367 177.099 85.0473 171.713 85.0473 171.713C85.0473 171.713 82.2878 169.617 81.1421 168.576C80.5738 169.686 79.8976 170.736 79.123 171.713C83.706 175.59 88.764 179.698 90.5385 180.516C94.0316 182.129 94.849 180.781 97.5247 182.402C100.2 184.023 103.184 189.388 103.184 189.388C103.184 189.388 104.797 185.071 106.146 188.04C107.494 191.009 110.729 196.123 110.729 196.123C110.729 196.123 115.039 198.547 116.122 198.547C116.96 198.547 121.11 200.699 122.864 201.628C123.255 199.581 123.471 198.351 123.471 198.351C122.458 199.651 120.921 197.087 117.338 195.298Z'
          fill='black'
        />
      </g>
      <path
        d='M123.486 198.37C122.459 199.649 120.922 197.085 117.339 195.296C115.068 194.158 111.47 193.124 108.48 192.006C107.635 190.001 106.517 187.388 105.804 185.809C104.519 183.015 102.472 184.782 102.472 184.782C101.4 183.565 100.536 182.18 99.915 180.681C98.6295 177.887 96.5826 178.117 93.7601 177.607C90.9377 177.097 85.0484 171.711 85.0484 171.711C85.0484 171.711 82.2888 169.615 81.1431 168.574C79.8227 171.187 76.637 175.784 69.7695 178.655C80.7239 190.106 102.968 211.861 105.294 213.495C107.858 215.284 107.09 220.412 105.294 225.54C103.499 230.668 96.8341 238.115 97.0926 246.303C97.3511 254.49 99.887 267.066 99.6565 269.63C99.426 271.914 94.319 279.075 93.2082 280.612L95.8141 280.905H109.374C109.297 274.031 109.304 245.974 114.013 234.796C119.386 221.949 123.486 198.37 123.486 198.37Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M123.668 197.478C123.668 197.478 120.482 196.5 118.526 198.219C116.57 199.937 113.635 208.754 111.903 218.318C110.842 223.886 111.576 229.647 113.999 234.771C113.999 234.771 118.023 224.201 119.252 219.052C120.482 213.903 123.668 197.478 123.668 197.478Z'
        fill='#00ACE7'
      />
      <mask id='mask5_2471_24399' maskUnits='userSpaceOnUse' x='111' y='197' width='13' height='38'>
        <path
          d='M123.668 197.478C123.668 197.478 120.482 196.5 118.526 198.219C116.57 199.937 113.635 208.754 111.903 218.318C110.842 223.886 111.576 229.647 113.999 234.771C113.999 234.771 118.023 224.201 119.252 219.052C120.482 213.903 123.668 197.478 123.668 197.478Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask5_2471_24399)'>
        <path
          opacity='0.16'
          d='M117.557 199.033C117.557 199.033 119.862 198.474 120.812 198.795C121.613 199.137 122.361 199.591 123.034 200.144L123.67 197.475C122.716 196.988 121.634 196.809 120.575 196.965C118.989 197.286 117.557 199.033 117.557 199.033Z'
          fill='black'
        />
      </g>
      <path
        d='M123.668 197.478C123.668 197.478 120.482 196.5 118.526 198.219C116.57 199.937 113.635 208.754 111.903 218.318C110.842 223.886 111.576 229.647 113.999 234.771C113.999 234.771 118.023 224.201 119.252 219.052C120.482 213.903 123.668 197.478 123.668 197.478Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M102.214 198.314L106.315 214.977L97.0859 193.955'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M129.258 280.906H159.138C159.138 280.906 150.915 270.434 150.915 266.843C150.915 263.252 162.226 217.339 162.743 214.006C163.26 210.674 161.206 208.368 157.105 208.11C153.004 207.851 137.362 204.519 136.594 201.955C136.594 201.955 130.697 222.718 128.65 236.816C126.855 248.909 128.678 274.004 129.258 280.906Z'
        fill='#00ACE7'
      />
      <mask id='mask6_2471_24399' maskUnits='userSpaceOnUse' x='127' y='201' width='36' height='80'>
        <path
          d='M129.254 280.906H159.134C159.134 280.906 150.911 270.434 150.911 266.843C150.911 263.252 162.222 217.339 162.739 214.006C163.256 210.674 161.202 208.368 157.101 208.11C153 207.851 137.358 204.519 136.59 201.955C136.59 201.955 130.693 222.718 128.646 236.816C126.851 248.909 128.674 274.004 129.254 280.906Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask6_2471_24399)'>
        <path
          opacity='0.16'
          d='M157.068 208.111C153.701 207.901 142.6 205.624 138.275 203.402L139.847 207.035C142.706 208.549 145.781 209.615 148.964 210.193C154.176 211.122 159.01 210.94 162.175 215.593L162.329 215.824C162.531 214.93 162.657 214.301 162.706 214.007C163.216 210.675 161.169 208.37 157.068 208.111Z'
          fill='black'
        />
      </g>
      <path
        d='M129.258 280.906H159.138C159.138 280.906 150.915 270.434 150.915 266.843C150.915 263.252 162.226 217.339 162.743 214.006C163.26 210.674 161.206 208.368 157.105 208.11C153.004 207.851 137.362 204.519 136.594 201.955C136.594 201.955 130.697 222.718 128.65 236.816C126.855 248.909 128.678 274.004 129.258 280.906Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.559 201.955C136.559 201.955 128.657 227.105 128.615 236.816C128.615 236.816 139.849 223.214 141.323 213.657C142.797 204.1 136.559 201.955 136.559 201.955Z'
        fill='#00ACE7'
      />
      <mask id='mask7_2471_24399' maskUnits='userSpaceOnUse' x='128' y='201' width='14' height='36'>
        <path
          d='M136.56 201.955C136.56 201.955 128.659 227.105 128.617 236.816C128.617 236.816 139.851 223.214 141.325 213.657C142.799 204.1 136.56 201.955 136.56 201.955Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask7_2471_24399)'>
        <path
          opacity='0.16'
          d='M135.197 205.169C136.386 205.129 137.566 205.379 138.636 205.898C139.706 206.416 140.633 207.187 141.338 208.145C141.338 208.145 139.787 203.583 136.56 201.955L135.197 205.169Z'
          fill='black'
        />
      </g>
      <path
        d='M136.559 201.955C136.559 201.955 128.657 227.105 128.615 236.816C128.615 236.816 139.849 223.214 141.323 213.657C142.797 204.1 136.559 201.955 136.559 201.955Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M220.994 276.176C220.994 276.176 226.785 276.414 230.572 276.958C233.101 277.301 235.648 277.504 238.201 277.566C238.201 277.566 236.105 273.333 233.876 272.138C231.647 270.943 227.588 268.806 224.186 269.281C220.784 269.756 215.949 273.151 216.341 274.269C216.732 275.387 218.562 275.848 220.994 276.176Z'
        fill='white'
      />
      <mask id='mask8_2471_24399' maskUnits='userSpaceOnUse' x='216' y='269' width='23' height='9'>
        <path
          d='M220.995 276.174C220.995 276.174 226.787 276.412 230.574 276.956C233.103 277.299 235.65 277.502 238.202 277.564C238.202 277.564 236.107 273.331 233.878 272.136C231.649 270.941 227.59 268.804 224.188 269.279C220.786 269.754 215.951 273.149 216.343 274.267C216.734 275.385 218.564 275.846 220.995 276.174Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask8_2471_24399)'>
        <path
          d='M220.995 276.174C220.995 276.174 226.787 276.412 230.574 276.956C233.103 277.299 235.65 277.502 238.202 277.564C238.202 277.564 236.107 273.331 233.878 272.136C231.649 270.941 227.59 268.804 224.188 269.279C220.786 269.754 215.951 273.149 216.343 274.267C216.734 275.385 218.564 275.846 220.995 276.174Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M220.995 276.174C220.995 276.174 226.787 276.412 230.574 276.956C233.103 277.299 235.65 277.502 238.202 277.564C238.202 277.564 236.107 273.331 233.878 272.136C231.649 270.941 227.59 268.804 224.188 269.279C220.786 269.754 215.951 273.149 216.343 274.267C216.734 275.385 218.564 275.846 220.995 276.174Z'
          fill='white'
        />
      </g>
      <path
        d='M220.994 276.176C220.994 276.176 226.785 276.414 230.572 276.958C233.101 277.301 235.648 277.504 238.201 277.566C238.201 277.566 236.105 273.333 233.876 272.138C231.647 270.943 227.588 268.806 224.186 269.281C220.784 269.756 215.949 273.151 216.341 274.269C216.732 275.387 218.562 275.848 220.994 276.176Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M230.559 274.443C230.559 274.443 235.149 272.061 236.301 272.096C237.454 272.131 245.062 277.28 244.685 278.104C244.427 278.486 244.055 278.778 243.623 278.938C243.191 279.099 242.719 279.12 242.274 278.998C241.436 278.6 236.308 276.421 236.308 276.421L232.955 277.21L230.559 274.443Z'
        fill='white'
      />
      <mask id='mask9_2471_24399' maskUnits='userSpaceOnUse' x='230' y='272' width='15' height='8'>
        <path
          d='M230.559 274.443C230.559 274.443 235.149 272.061 236.301 272.096C237.454 272.131 245.062 277.28 244.685 278.104C244.427 278.486 244.055 278.778 243.623 278.938C243.191 279.099 242.719 279.12 242.274 278.998C241.436 278.6 236.308 276.421 236.308 276.421L232.955 277.21L230.559 274.443Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask9_2471_24399)'>
        <path
          d='M230.559 274.443C230.559 274.443 235.149 272.061 236.301 272.096C237.454 272.131 245.062 277.28 244.685 278.104C244.307 278.929 242.498 279.599 242.274 278.998C242.051 278.398 236.308 276.421 236.308 276.421L232.955 277.21L230.559 274.443Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M230.559 274.443C230.559 274.443 235.149 272.061 236.301 272.096C237.454 272.131 245.062 277.28 244.685 278.104C244.307 278.929 242.498 279.599 242.274 278.998C242.051 278.398 236.308 276.421 236.308 276.421L232.955 277.21L230.559 274.443Z'
          fill='white'
        />
      </g>
      <path
        d='M230.559 274.443C230.559 274.443 235.149 272.061 236.301 272.096C237.454 272.131 245.062 277.28 244.685 278.104C244.427 278.486 244.055 278.778 243.623 278.938C243.191 279.099 242.719 279.12 242.274 278.998C241.436 278.6 236.308 276.421 236.308 276.421L232.955 277.21L230.559 274.443Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M227.33 270.526C227.33 270.526 233.457 270.33 234.666 270.945C235.874 271.56 241.372 280.977 240.548 281.662C239.724 282.346 237.453 282.144 237.523 281.382C237.593 280.621 232.458 275.577 232.458 275.577L228.462 274.703L227.33 270.526Z'
        fill='white'
      />
      <mask id='mask10_2471_24399' maskUnits='userSpaceOnUse' x='227' y='270' width='14' height='13'>
        <path
          d='M227.33 270.526C227.33 270.526 233.457 270.33 234.666 270.945C235.874 271.56 241.372 280.977 240.548 281.662C239.724 282.346 237.453 282.144 237.523 281.382C237.593 280.621 232.458 275.577 232.458 275.577L228.462 274.703L227.33 270.526Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask10_2471_24399)'>
        <path
          d='M227.33 270.526C227.33 270.526 233.457 270.33 234.666 270.945C235.874 271.56 241.372 280.977 240.548 281.662C239.724 282.346 237.453 282.144 237.523 281.382C237.593 280.621 232.458 275.577 232.458 275.577L228.462 274.703L227.33 270.526Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M227.33 270.526C227.33 270.526 233.457 270.33 234.666 270.945C235.874 271.56 241.372 280.977 240.548 281.662C239.724 282.346 237.453 282.144 237.523 281.382C237.593 280.621 232.458 275.577 232.458 275.577L228.462 274.703L227.33 270.526Z'
          fill='white'
        />
      </g>
      <path
        d='M227.33 270.526C227.33 270.526 233.457 270.33 234.666 270.945C235.874 271.56 241.372 280.977 240.548 281.662C239.724 282.346 237.453 282.144 237.523 281.382C237.593 280.621 232.458 275.577 232.458 275.577L228.462 274.703L227.33 270.526Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M223.711 269.26C223.711 269.26 229.908 269.057 231.137 269.686C232.367 270.315 237.921 279.837 237.083 280.535C236.244 281.234 233.953 281.017 234.023 280.249C234.092 279.48 228.902 274.374 228.902 274.374L224.864 273.486L223.711 269.26Z'
        fill='white'
      />
      <mask id='mask11_2471_24399' maskUnits='userSpaceOnUse' x='223' y='269' width='15' height='12'>
        <path
          d='M223.713 269.262C223.713 269.262 229.91 269.059 231.139 269.688C232.369 270.316 237.923 279.839 237.084 280.537C236.246 281.236 233.955 281.019 234.025 280.251C234.094 279.482 228.904 274.375 228.904 274.375L224.866 273.488L223.713 269.262Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask11_2471_24399)'>
        <path
          d='M223.713 269.262C223.713 269.262 229.91 269.059 231.139 269.688C232.369 270.316 237.923 279.839 237.084 280.537C236.246 281.236 233.955 281.019 234.025 280.251C234.094 279.482 228.904 274.375 228.904 274.375L224.866 273.488L223.713 269.262Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M223.713 269.262C223.713 269.262 229.91 269.059 231.139 269.688C232.369 270.316 237.923 279.839 237.084 280.537C236.246 281.236 233.955 281.019 234.025 280.251C234.094 279.482 228.904 274.375 228.904 274.375L224.866 273.488L223.713 269.262Z'
          fill='white'
        />
      </g>
      <path
        d='M223.711 269.26C223.711 269.26 229.908 269.057 231.137 269.686C232.367 270.315 237.921 279.837 237.083 280.535C236.244 281.234 233.953 281.017 234.023 280.249C234.092 279.48 228.902 274.374 228.902 274.374L224.864 273.486L223.711 269.26Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M153.249 233.754C157.734 240.664 166.739 258.171 169.373 260.141C173.474 263.215 200.888 270.676 203.529 272.716C206.17 274.756 205.981 276.803 208.489 277.551C210.997 278.298 220.366 280.443 223.942 280.778C227.519 281.114 225.92 278.899 225.92 278.899L219.814 274.707L225.137 273.394C225.137 273.394 230.461 279.807 231.46 280.157C232.459 280.506 234.254 280.157 234.107 279.367C233.919 278.103 228.225 267.756 226.213 267.316C224.201 266.876 211.933 268.574 211.933 268.574C211.933 268.574 186.238 251.241 185.777 250.626C185.777 250.626 184.24 246.267 182.982 244.471C181.725 242.676 180.935 243.193 180.935 243.193C180.935 243.193 181.445 240.88 180.677 240.112L176.576 236.011L176.429 236.206C175.731 234.809 175.032 233.216 174.271 231.4C172.119 226.349 164.532 212.174 163.246 210.127C162.296 208.597 154.269 207.5 150.203 207.039'
        fill='#00ACE7'
      />
      <mask id='mask12_2471_24399' maskUnits='userSpaceOnUse' x='150' y='207' width='85' height='74'>
        <path
          d='M153.245 233.754C157.73 240.664 166.736 258.171 169.369 260.141C173.47 263.215 200.884 270.676 203.525 272.716C206.166 274.756 205.977 276.803 208.485 277.551C210.993 278.298 220.362 280.443 223.939 280.778C227.515 281.114 225.916 278.899 225.916 278.899L219.81 274.707L225.133 273.394C225.133 273.394 230.457 279.807 231.456 280.157C232.455 280.506 234.25 280.157 234.103 279.367C233.915 278.103 228.221 267.756 226.209 267.316C224.197 266.876 211.929 268.574 211.929 268.574C211.929 268.574 186.234 251.241 185.773 250.626C185.773 250.626 184.236 246.267 182.978 244.471C181.721 242.676 180.932 243.193 180.932 243.193C180.932 243.193 181.442 240.88 180.673 240.112L176.572 236.011L176.425 236.206C175.727 234.809 175.028 233.216 174.267 231.4C172.115 226.349 164.528 212.174 163.242 210.127C162.292 208.597 154.265 207.5 150.199 207.039'
          fill='white'
        />
      </mask>
      <g mask='url(#mask12_2471_24399)'>
        <path
          opacity='0.16'
          d='M171.111 234.194C174.458 237.17 173.34 237.17 172.6 239.782C171.859 242.395 173.158 238.853 176.316 239.042C179.474 239.231 178.552 243.317 178.181 244.065C177.811 244.813 182.087 245.742 181.339 247.411C180.592 249.081 176.316 251.505 173.899 253.93C171.482 256.354 180.228 250.017 183.205 249.836C184.106 249.773 184.853 249.891 185.482 249.878C184.826 248.007 183.978 246.209 182.953 244.512C181.675 242.717 180.906 243.234 180.906 243.234C180.906 243.234 181.416 240.921 180.648 240.153L176.547 236.052L176.4 236.247C175.701 234.85 175.003 233.257 174.241 231.441C172.09 226.39 164.503 212.215 163.217 210.168C162.267 208.638 154.24 207.541 150.174 207.08L150.551 210.315L150.642 210.385C150.642 210.385 155.665 211.314 157.342 211.502C159.018 211.691 161.058 213.172 160.877 214.297C160.695 215.422 155.847 220.438 157.342 220.815C158.837 221.192 160.499 217.091 162.176 217.65C163.853 218.209 167.758 231.217 171.111 234.194Z'
          fill='black'
        />
      </g>
      <path
        d='M153.249 233.754C157.734 240.664 166.739 258.171 169.373 260.141C173.474 263.215 200.888 270.676 203.529 272.716C206.17 274.756 205.981 276.803 208.489 277.551C210.997 278.298 220.366 280.443 223.942 280.778C227.519 281.114 225.92 278.899 225.92 278.899L219.814 274.707L225.137 273.394C225.137 273.394 230.461 279.807 231.46 280.157C232.459 280.506 234.254 280.157 234.107 279.367C233.919 278.103 228.225 267.756 226.213 267.316C224.201 266.876 211.933 268.574 211.933 268.574C211.933 268.574 186.238 251.241 185.777 250.626C185.777 250.626 184.24 246.267 182.982 244.471C181.725 242.676 180.935 243.193 180.935 243.193C180.935 243.193 181.445 240.88 180.677 240.112L176.576 236.011L176.429 236.206C175.731 234.809 175.032 233.216 174.271 231.4C172.119 226.349 164.532 212.174 163.246 210.127C162.296 208.597 154.269 207.5 150.203 207.039'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M153.319 224.275C153.13 224.904 152.97 225.533 152.844 226.176'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M160.109 212.713C157.913 215.215 156.062 217.999 154.604 220.992'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M170.371 252.383C172.257 249.588 175.261 246.046 178.573 245.033'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M168.281 255.819C168.281 255.819 168.617 255.162 169.217 254.17'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M168.281 251.403C168.281 251.403 171.711 242.824 175.142 240.616C178.572 238.409 176.616 242.824 176.616 242.824'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M203.528 272.726C206.169 274.773 205.98 276.813 208.488 277.56C210.996 278.308 220.365 280.453 223.942 280.788C227.519 281.123 225.919 278.909 225.919 278.909L219.813 274.717L225.136 273.404C225.136 273.404 230.46 279.817 231.459 280.166C232.458 280.516 234.358 280.641 234.107 279.377C233.618 276.932 228.224 267.766 226.212 267.326C224.2 266.886 211.933 268.583 211.933 268.583C211.933 268.583 188.522 252.515 185.958 250.775C176.016 251.732 171.287 256.937 169.303 260.06L169.373 260.116C173.473 263.218 200.887 270.679 203.528 272.726Z'
        fill='white'
      />
      <mask id='mask13_2471_24399' maskUnits='userSpaceOnUse' x='169' y='250' width='66' height='31'>
        <path
          d='M203.526 272.722C206.167 274.769 205.978 276.809 208.486 277.557C210.994 278.304 220.363 280.449 223.94 280.784C227.517 281.12 225.917 278.905 225.917 278.905L219.811 274.713L225.134 273.4C225.134 273.4 230.458 279.813 231.457 280.162C232.456 280.512 234.356 280.637 234.105 279.373C233.616 276.928 228.222 267.762 226.21 267.322C224.198 266.882 211.931 268.579 211.931 268.579C211.931 268.579 188.52 252.511 185.956 250.771C176.015 251.729 171.285 256.933 169.301 260.056L169.371 260.112C173.472 263.214 200.885 270.675 203.526 272.722Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask13_2471_24399)'>
        <path
          d='M234.106 279.402C232.842 276.293 228.224 267.791 226.212 267.351C224.2 266.911 211.932 268.609 211.932 268.609C211.932 268.609 188.521 252.54 185.957 250.801C183.348 251.029 180.788 251.648 178.363 252.638C182.059 254.734 206.692 268.706 209.801 269.328C213.099 270.027 224.102 268.217 226.044 269.517C227.274 270.341 230.934 276.147 233.401 280.269C234.113 280.164 234.365 280.031 234.106 279.402Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M234.106 279.402C232.122 274.952 228.224 267.791 226.212 267.351C224.2 266.911 211.932 268.609 211.932 268.609C211.932 268.609 188.521 252.54 185.957 250.801C183.348 251.029 180.788 251.648 178.363 252.638C182.059 254.734 206.692 268.706 209.801 269.328C213.099 270.027 224.102 268.217 226.044 269.517C227.274 270.341 230.934 276.147 233.401 280.269C234.113 280.164 234.386 280.024 234.106 279.402Z'
          fill='white'
        />
      </g>
      <path
        d='M203.528 272.726C206.169 274.773 205.98 276.813 208.488 277.56C210.996 278.308 220.365 280.453 223.942 280.788C227.519 281.123 225.919 278.909 225.919 278.909L219.813 274.717L225.136 273.404C225.136 273.404 230.46 279.817 231.459 280.166C232.458 280.516 234.358 280.641 234.107 279.377C233.618 276.932 228.224 267.766 226.212 267.326C224.2 266.886 211.933 268.583 211.933 268.583C211.933 268.583 188.522 252.515 185.958 250.775C176.016 251.732 171.287 256.937 169.303 260.06L169.373 260.116C173.473 263.218 200.887 270.679 203.528 272.726Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.776 150.73L120.734 159.512C120.483 163.21 119.57 166.833 118.037 170.208C115.55 175.469 116.996 185.424 119.385 190.796C121.775 196.169 132.303 194.066 136.152 193.395C138.765 192.899 141.325 192.153 143.795 191.167C144.918 191.6 146.132 191.74 147.324 191.574C148.516 191.407 149.645 190.94 150.607 190.216C150.607 190.216 139.352 158.604 141.776 150.73Z'
        fill='white'
      />
      <mask id='mask14_2471_24399' maskUnits='userSpaceOnUse' x='116' y='150' width='35' height='45'>
        <path
          d='M141.778 150.73L120.736 159.512C120.485 163.21 119.572 166.833 118.039 170.208C115.552 175.469 116.998 185.424 119.387 190.796C121.777 196.169 132.305 194.066 136.154 193.395C138.767 192.899 141.327 192.153 143.797 191.167C144.92 191.6 146.134 191.74 147.326 191.574C148.518 191.407 149.647 190.94 150.609 190.216C150.609 190.216 139.354 158.604 141.778 150.73Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask14_2471_24399)'>
        <path
          d='M141.778 150.73L120.736 159.512C120.485 163.21 119.572 166.833 118.039 170.208C115.552 175.469 116.998 185.424 119.387 190.796C119.79 191.659 120.433 192.386 121.239 192.892V192.487C121.239 192.487 117.333 182.811 118.081 177.229C118.828 171.647 121.986 168.846 122.356 165.353C122.727 161.859 122.545 160.148 129.615 157.542C136.685 154.936 139.661 152.512 140.437 155.111C141.212 157.71 144.153 174.84 146.2 178.934C148.247 183.028 148.066 187.317 144.901 189.169C141.71 191.086 138.29 192.593 134.722 193.654L136.21 193.395C138.823 192.899 141.383 192.153 143.853 191.167C144.976 191.6 146.19 191.74 147.382 191.574C148.574 191.407 149.703 190.94 150.665 190.216C150.665 190.216 139.354 158.604 141.778 150.73Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M141.778 150.73L120.736 159.512C120.485 163.21 119.572 166.833 118.039 170.208C115.552 175.469 116.998 185.424 119.387 190.796C119.79 191.659 120.433 192.386 121.239 192.892V192.487C121.239 192.487 117.333 182.811 118.081 177.229C118.828 171.647 121.986 168.846 122.356 165.353C122.727 161.859 122.545 160.148 129.615 157.542C136.685 154.936 139.661 152.512 140.437 155.111C141.212 157.71 144.153 174.84 146.2 178.934C148.247 183.028 148.066 187.317 144.901 189.169C141.71 191.086 138.29 192.593 134.722 193.654L136.21 193.395C138.823 192.899 141.383 192.153 143.853 191.167C144.976 191.6 146.19 191.74 147.382 191.574C148.574 191.407 149.703 190.94 150.665 190.216C150.665 190.216 139.354 158.604 141.778 150.73Z'
          fill='white'
        />
      </g>
      <path
        d='M141.776 150.73L120.734 159.512C120.483 163.21 119.57 166.833 118.037 170.208C115.55 175.469 116.996 185.424 119.385 190.796C121.775 196.169 132.303 194.066 136.152 193.395C138.765 192.899 141.325 192.153 143.795 191.167C144.918 191.6 146.132 191.74 147.324 191.574C148.516 191.407 149.645 190.94 150.607 190.216C150.607 190.216 139.352 158.604 141.776 150.73Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M131.143 164.502C131.204 165.739 131.053 166.977 130.696 168.163C130.137 169.56 124.862 175.051 125.023 175.645C125.184 176.239 125.652 178.146 126.588 178.328'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.977 169.414C137.675 167.947 140.127 169.38 140.092 172.684'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M125.066 165.096C125.066 165.096 127.12 164.195 128.951 167.52'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M134.072 174.254C136.446 174.373 138.702 175.32 140.451 176.93'
        stroke='#263238'
        strokeWidth='0.34931'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.57 169.887C123.795 170.051 124.992 170.382 126.126 170.872'
        stroke='#263238'
        strokeWidth='0.34931'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M130.921 161.402C130.377 161.116 129.774 160.959 129.159 160.944C128.545 160.93 127.935 161.058 127.378 161.318C125.499 162.219 124.346 163.211 124.472 162.059C124.598 160.906 126.813 159.634 127.469 159.467C128.126 159.299 131.102 158.838 130.921 161.402Z'
        fill='#263238'
      />
      <path
        d='M136.189 158.795C138.145 160.381 139.698 162.406 140.723 164.705C142.121 168.198 143.225 164.384 142.121 162.099C141.017 159.815 137.831 157.384 136.189 158.795Z'
        fill='#263238'
      />
      <path
        d='M150.913 196.191C150.913 197.818 149.998 199.139 148.859 199.139C147.721 199.139 146.812 197.818 146.812 196.191C146.812 194.563 147.728 190.42 148.859 190.42C149.991 190.42 150.913 194.563 150.913 196.191Z'
        fill='#00ACE7'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.172 168.777C137.094 168.991 137.033 169.211 136.99 169.434C136.781 170.468 136.578 172.627 137.025 172.718C137.473 172.808 138.388 170.789 138.59 169.755C138.628 169.552 138.654 169.347 138.667 169.141C138.177 168.99 137.677 168.868 137.172 168.777Z'
        fill='#263238'
      />
      <path
        d='M125.26 164.892C125.196 165.075 125.145 165.261 125.107 165.451C124.904 166.485 124.904 168.756 125.351 168.839C125.798 168.923 126.504 166.8 126.749 165.766C126.797 165.515 126.823 165.26 126.825 165.004C126.317 164.862 125.784 164.824 125.26 164.892Z'
        fill='#263238'
      />
      <path
        d='M137.014 169.448L137.712 168.148C138.822 168.365 139.827 168.945 140.57 169.797'
        stroke='#263238'
        strokeWidth='0.523965'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M125.262 164.893L125.96 164.076C125.96 164.076 126.261 163.929 127.916 164.677'
        stroke='#263238'
        strokeWidth='0.523965'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.607 183.84C122.607 183.84 123.788 181.682 125.353 181.682C126.918 181.682 128.099 185.21 128.099 185.21'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.0496 276.939C70.5305 276.863 69.0384 276.508 67.6483 275.891C67.2983 275.718 67.0278 275.418 66.8928 275.052C66.7578 274.686 66.7684 274.282 66.9226 273.923C67.0767 273.565 67.3626 273.279 67.7212 273.125C68.0799 272.971 68.4839 272.961 68.8499 273.096C70.2471 273.69 72.0566 274.06 72.5456 273.851C73.4259 272.859 74.7952 266.865 73.0276 263.972C72.6364 263.33 72.2661 263.211 72.0216 263.183C71.0226 263.043 69.5345 264.07 69.0106 264.58C68.716 264.858 68.323 265.008 67.9182 264.996C67.5133 264.984 67.1298 264.812 66.8518 264.517C66.5739 264.223 66.4244 263.83 66.4362 263.425C66.448 263.02 66.6201 262.636 66.9147 262.358C67.1942 262.093 69.7092 259.822 72.4059 260.186C73.0719 260.284 73.7064 260.534 74.2601 260.917C74.8138 261.299 75.2718 261.805 75.5986 262.393C78.1974 266.62 76.416 274.661 74.4249 276.254C73.7334 276.745 72.896 276.986 72.0496 276.939Z'
        fill='white'
      />
      <mask id='mask15_2471_24399' maskUnits='userSpaceOnUse' x='66' y='260' width='11' height='17'>
        <path
          d='M72.0476 276.937C70.5285 276.861 69.0364 276.506 67.6463 275.889C67.2963 275.716 67.0259 275.416 66.8908 275.05C66.7558 274.684 66.7665 274.28 66.9206 273.921C67.0748 273.563 67.3607 273.277 67.7193 273.123C68.0779 272.969 68.4819 272.959 68.8479 273.094C70.2452 273.688 72.0546 274.058 72.5436 273.849C73.4239 272.857 74.7932 266.863 73.0257 263.97C72.6345 263.328 72.2642 263.209 72.0197 263.181C71.0207 263.041 69.5326 264.068 69.0086 264.578C68.714 264.856 68.3211 265.006 67.9162 264.994C67.5114 264.982 67.1278 264.81 66.8499 264.515C66.572 264.221 66.4225 263.828 66.4342 263.423C66.446 263.018 66.6182 262.634 66.9128 262.357C67.1922 262.091 69.7072 259.821 72.4039 260.184C73.0699 260.282 73.7044 260.532 74.2581 260.915C74.8119 261.298 75.2699 261.803 75.5966 262.391C78.1955 266.618 76.414 274.659 74.4229 276.252C73.7315 276.743 72.8941 276.984 72.0476 276.937Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask15_2471_24399)'>
        <path
          d='M74.0698 263.51C73.8856 263.077 73.5984 262.695 73.2331 262.398C72.8679 262.101 72.4357 261.898 71.9739 261.805C70.2274 261.526 68.5856 263.28 68.404 263.475C68.2376 263.676 68.1342 263.922 68.1069 264.182C68.0796 264.442 68.1294 264.704 68.2503 264.935C68.5256 264.882 68.78 264.751 68.9839 264.558C69.5078 264.069 70.9959 263.042 71.9949 263.161C72.2394 263.161 72.6097 263.308 73.0009 263.95C74.7684 266.815 73.3991 272.837 72.5189 273.829C72.0298 274.038 70.2274 273.668 68.8232 273.074C68.6503 273.002 68.4656 272.961 68.2782 272.955C68.291 273.147 68.3513 273.332 68.4538 273.494C68.5563 273.657 68.6977 273.791 68.8651 273.885C69.7506 274.357 70.7275 274.634 71.7294 274.695C72.2997 274.722 72.858 274.526 73.2874 274.15C74.6077 272.941 75.7605 266.759 74.0698 263.51Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M74.0698 263.51C73.8856 263.077 73.5984 262.695 73.2331 262.398C72.8679 262.101 72.4357 261.898 71.9739 261.805C70.2274 261.526 68.5856 263.28 68.404 263.475C68.2376 263.676 68.1342 263.922 68.1069 264.182C68.0796 264.442 68.1294 264.704 68.2503 264.935C68.5256 264.882 68.78 264.751 68.9839 264.558C69.5078 264.069 70.9959 263.042 71.9949 263.161C72.2394 263.161 72.6097 263.308 73.0009 263.95C74.7684 266.815 73.3991 272.837 72.5189 273.829C72.0298 274.038 70.2274 273.668 68.8232 273.074C68.6503 273.002 68.4656 272.961 68.2782 272.955C68.291 273.147 68.3513 273.332 68.4538 273.494C68.5563 273.657 68.6977 273.791 68.8651 273.885C69.7506 274.357 70.7275 274.634 71.7294 274.695C72.2997 274.722 72.858 274.526 73.2874 274.15C74.6077 272.941 75.7605 266.759 74.0698 263.51Z'
          fill='white'
        />
      </g>
      <path
        d='M72.0496 276.939C70.5305 276.863 69.0384 276.508 67.6483 275.891C67.2983 275.718 67.0278 275.418 66.8928 275.052C66.7578 274.686 66.7684 274.282 66.9226 273.923C67.0767 273.565 67.3626 273.279 67.7212 273.125C68.0799 272.971 68.4839 272.961 68.8499 273.096C70.2471 273.69 72.0566 274.06 72.5456 273.851C73.4259 272.859 74.7952 266.865 73.0276 263.972C72.6364 263.33 72.2661 263.211 72.0216 263.183C71.0226 263.043 69.5345 264.07 69.0106 264.58C68.716 264.858 68.323 265.008 67.9182 264.996C67.5133 264.984 67.1298 264.812 66.8518 264.517C66.5739 264.223 66.4244 263.83 66.4362 263.425C66.448 263.02 66.6201 262.636 66.9147 262.358C67.1942 262.093 69.7092 259.822 72.4059 260.186C73.0719 260.284 73.7064 260.534 74.2601 260.917C74.8138 261.299 75.2718 261.805 75.5986 262.393C78.1974 266.62 76.416 274.661 74.4249 276.254C73.7334 276.745 72.896 276.986 72.0496 276.939Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M69.3526 258.914H52.5508V281.214H69.3526V258.914Z' fill='white' />
      <mask id='mask16_2471_24399' maskUnits='userSpaceOnUse' x='52' y='258' width='18' height='24'>
        <path d='M69.3545 258.914H52.5527V281.214H69.3545V258.914Z' fill='white' />
      </mask>
      <g mask='url(#mask16_2471_24399)'>
        <path d='M59.4341 258.914H52.5527V281.214H59.4341V258.914Z' fill='#00ACE7' />
        <path opacity='0.5' d='M59.4341 258.914H52.5527V281.214H59.4341V258.914Z' fill='white' />
      </g>
      <path
        d='M69.3526 258.914H52.5508V281.214H69.3526V258.914Z'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M161.682 280.906H89.9129L87.5656 241.448C87.5017 241.063 87.5223 240.669 87.6259 240.293C87.7295 239.918 87.9137 239.569 88.1655 239.271C88.4174 238.973 88.731 238.734 89.0846 238.569C89.4382 238.405 89.8233 238.319 90.2133 238.318H161.396C161.786 238.319 162.171 238.405 162.524 238.569C162.878 238.734 163.192 238.973 163.443 239.271C163.695 239.569 163.879 239.918 163.983 240.293C164.087 240.669 164.107 241.063 164.043 241.448L161.682 280.906Z'
        fill='#263238'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M93.4414 239.262H160.858C161.239 239.258 161.617 239.338 161.964 239.495C162.311 239.652 162.62 239.883 162.868 240.171C163.117 240.46 163.3 240.799 163.404 241.166C163.508 241.533 163.531 241.918 163.471 242.294'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M88.0957 241.672C88.1519 241.012 88.455 240.397 88.9445 239.95C89.4339 239.503 90.0738 239.258 90.7365 239.262'
        stroke='white'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M161.982 277.064H89.6055V280.907H161.982V277.064Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M125.542 262.839C128.374 262.839 130.67 261.463 130.67 259.765C130.67 258.068 128.374 256.691 125.542 256.691C122.71 256.691 120.414 258.068 120.414 259.765C120.414 261.463 122.71 262.839 125.542 262.839Z'
        fill='white'
        stroke='#263238'
        strokeWidth='0.69862'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M271.148 280.455H1.57812V287.001H271.148V280.455Z' fill='#263238' />
      <path
        d='M29.8898 106.864V85.0795L71.4409 19.7273H91.208V48.7727H79.9125L57.5233 84.2727V85.0795H118.842V106.864H29.8898ZM80.1142 123V100.207L80.7193 90.7273V19.7273H106.941V123H80.1142Z'
        fill='#0E436A'
      />
      <path
        d='M138.506 125.824C129.127 125.79 121.025 123.622 114.201 119.319C107.376 115.016 102.115 108.813 98.4173 100.712C94.7193 92.6098 92.8872 82.8944 92.9208 71.5653C92.9544 60.2026 94.8034 50.5544 98.4677 42.6207C102.166 34.687 107.41 28.6527 114.201 24.5178C121.025 20.3828 129.127 18.3153 138.506 18.3153C147.885 18.3153 155.987 20.3996 162.811 24.5682C169.636 28.7031 174.897 34.7374 178.595 42.6712C182.293 50.6049 184.125 60.2363 184.091 71.5653C184.091 82.9616 182.242 92.7107 178.544 100.812C174.846 108.914 169.585 115.117 162.761 119.42C155.97 123.689 147.885 125.824 138.506 125.824ZM138.506 103.031C143.347 103.031 147.314 100.51 150.407 95.4673C153.533 90.3911 155.079 82.4238 155.046 71.5653C155.046 64.4721 154.34 58.6731 152.928 54.1683C151.516 49.6636 149.566 46.3355 147.078 44.1839C144.591 41.9988 141.733 40.9062 138.506 40.9062C133.665 40.9062 129.715 43.3603 126.656 48.2685C123.597 53.1766 122.033 60.9422 121.966 71.5653C121.933 78.7931 122.622 84.7434 124.034 89.4162C125.446 94.0554 127.395 97.4844 129.883 99.7031C132.404 101.922 135.279 103.031 138.506 103.031Z'
        fill='#00ACE7'
      />
      <path
        d='M202.458 124.412C194.323 124.412 187.095 123.017 180.775 120.227C174.488 117.403 169.547 113.52 165.949 108.578C162.352 103.636 160.537 97.955 160.503 91.5341H188.742C188.776 93.5175 189.381 95.2992 190.557 96.8793C191.768 98.4257 193.415 99.6359 195.499 100.51C197.583 101.384 199.97 101.821 202.66 101.821C205.248 101.821 207.534 101.367 209.518 100.46C211.501 99.5182 213.047 98.224 214.157 96.5767C215.266 94.9294 215.804 93.0469 215.77 90.929C215.804 88.8447 215.165 86.9957 213.854 85.3821C212.577 83.7685 210.778 82.5078 208.459 81.6001C206.139 80.6925 203.466 80.2386 200.441 80.2386H189.952V60.875H200.441C203.231 60.875 205.685 60.4212 207.803 59.5135C209.955 58.6058 211.619 57.3452 212.795 55.7315C214.006 54.1179 214.594 52.2689 214.56 50.1847C214.594 48.1676 214.106 46.3859 213.098 44.8395C212.089 43.2931 210.677 42.0829 208.862 41.2088C207.08 40.3348 205.013 39.8977 202.66 39.8977C200.105 39.8977 197.819 40.3516 195.802 41.2592C193.818 42.1669 192.255 43.4276 191.112 45.0412C189.969 46.6548 189.381 48.5038 189.347 50.5881H162.52C162.554 44.268 164.285 38.6875 167.714 33.8466C171.177 29.0057 175.917 25.2069 181.935 22.4503C187.952 19.6937 194.86 18.3153 202.66 18.3153C210.291 18.3153 217.031 19.6096 222.881 22.1981C228.764 24.7867 233.352 28.3501 236.647 32.8885C239.975 37.3932 241.622 42.5535 241.589 48.3693C241.656 54.1515 239.672 58.8916 235.638 62.5895C231.638 66.2874 226.562 68.4725 220.41 69.1449V69.9517C228.747 70.8594 235.016 73.4143 239.219 77.6165C243.421 81.785 245.488 87.0294 245.421 93.3494C245.455 99.4006 243.639 104.763 239.975 109.435C236.344 114.108 231.285 117.772 224.797 120.428C218.342 123.084 210.896 124.412 202.458 124.412Z'
        fill='#0E436A'
      />
    </svg>
  );
}
