import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Label,
  SelectLabel,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { ConnectorGuide } from '@common/components';

export function FormSelect({
  isDriverEnabled,
  id,
  labelText,
  placeholder,
  value,
  handleChange,
  showError,
  optionPlaceholder,
  options,
  labelClassName,
  className,
  triggerClassName,
  tourId,
  selectRef,
  handleScroll,
  selectGroupClassName,
  showInfoIcon = false,
  infoContent,
  ...restProps
}) {
  return (
    <div id={id} className={`flex flex-col gap-1.5 ${className}`}>
      {!!labelText && (
        <Label className={`relative w-fit leading-5 ${labelClassName}`}>
          {labelText}
          <ConnectorGuide showInfoIcon={showInfoIcon} infoContent={infoContent} />
        </Label>
      )}
      <Select disabled={isDriverEnabled} value={value} onValueChange={handleChange} {...restProps}>
        <SelectTrigger
          className={`h-10 w-full lg:h-8 xxl:h-10 ${showError ? 'border-errorColor' : ''} ${triggerClassName}`}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent id={tourId} ref={selectRef}>
          <SelectGroup className={selectGroupClassName} onScroll={handleScroll}>
            {isEmpty(options) ? (
              <SelectLabel className='text-xs font-normal leading-[0.9075rem] text-mediumGray'>
                {optionPlaceholder}
              </SelectLabel>
            ) : (
              options?.map(option => (
                <SelectItem key={option?.id} value={option}>
                  {option?.name || option?.email}
                </SelectItem>
              ))
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>{showError}</p>
      )}
    </div>
  );
}
