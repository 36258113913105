export function EmptyDashboardScreenIcon({ className }) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 314 234'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_588_16255)'>
        <path d='M313.179 205.713H-0.00390625V205.869H313.179V205.713Z' fill='#EBEBEB' />
        <path d='M281.798 215.791H261.053V215.948H281.798V215.791Z' fill='#EBEBEB' />
        <path d='M207.461 217.494H202.018V217.651H207.461V217.494Z' fill='#EBEBEB' />
        <path d='M260.426 209.979H248.406V210.135H260.426V209.979Z' fill='#EBEBEB' />
        <path d='M59.9082 211.031H32.8555V211.188H59.9082V211.031Z' fill='#EBEBEB' />
        <path d='M69.4532 211.031H65.4883V211.188H69.4532V211.031Z' fill='#EBEBEB' />
        <path d='M141.022 213.674H82.3438V213.83H141.022V213.674Z' fill='#EBEBEB' />
        <path
          d='M148.445 177.777H27.5C26.5525 177.775 25.6444 177.398 24.975 176.727C24.3056 176.057 23.9297 175.148 23.9297 174.2V4.18592C23.9297 3.23845 24.3056 2.32969 24.975 1.65913C25.6444 0.98858 26.5525 0.611034 27.5 0.609375L148.445 0.609375C149.393 0.609375 150.303 0.986189 150.974 1.65692C151.645 2.32765 152.021 3.23736 152.021 4.18592V174.2C152.021 174.67 151.929 175.135 151.749 175.569C151.569 176.003 151.306 176.397 150.974 176.729C150.642 177.061 150.247 177.325 149.814 177.505C149.38 177.684 148.915 177.777 148.445 177.777ZM27.5 0.765966C26.594 0.767626 25.7258 1.12867 25.0857 1.76986C24.4457 2.41105 24.0863 3.27998 24.0863 4.18592V174.2C24.0863 175.106 24.4457 175.975 25.0857 176.616C25.7258 177.258 26.594 177.619 27.5 177.62H148.445C149.351 177.619 150.22 177.258 150.861 176.617C151.502 175.976 151.863 175.107 151.865 174.2V4.18592C151.863 3.2794 151.502 2.41048 150.861 1.76948C150.22 1.12847 149.351 0.767622 148.445 0.765966H27.5Z'
          fill='#EBEBEB'
        />
        <path
          d='M283.934 177.777H162.983C162.035 177.775 161.126 177.398 160.456 176.727C159.785 176.057 159.408 175.148 159.406 174.2V4.18592C159.408 3.23787 159.785 2.32912 160.456 1.65875C161.126 0.988376 162.035 0.611031 162.983 0.609375L283.934 0.609375C284.88 0.61269 285.787 0.990966 286.455 1.66134C287.123 2.33171 287.498 3.23953 287.498 4.18592V174.2C287.498 175.147 287.123 176.055 286.455 176.725C285.787 177.395 284.88 177.774 283.934 177.777ZM162.983 0.765966C162.076 0.767622 161.207 1.12847 160.566 1.76948C159.925 2.41048 159.564 3.2794 159.563 4.18592V174.2C159.564 175.107 159.925 175.976 160.566 176.617C161.207 177.258 162.076 177.619 162.983 177.62H283.934C284.84 177.619 285.709 177.258 286.35 176.617C286.991 175.976 287.352 175.107 287.354 174.2V4.18592C287.352 3.2794 286.991 2.41048 286.35 1.76948C285.709 1.12847 284.84 0.767622 283.934 0.765966H162.983Z'
          fill='#EBEBEB'
        />
        <path d='M181.447 75.2158L267.748 75.2158V18.6989L181.447 18.6989V75.2158Z' fill='#E6E6E6' />
        <path d='M178.81 75.2158L266.514 75.2158V18.6989L178.81 18.6989V75.2158Z' fill='#F0F0F0' />
        <path d='M181.447 86.3018L267.748 86.3018V75.2088L181.447 75.2088V86.3018Z' fill='#E6E6E6' />
        <path d='M174.433 86.3018L262.137 86.3018V75.2088L174.433 75.2088V86.3018Z' fill='#F0F0F0' />
        <path d='M262.832 71.5323V22.3877L182.501 22.3877V71.5323H262.832Z' fill='#FAFAFA' />
        <path d='M244.719 71.5261L234.459 22.3877H218.449L228.709 71.5261H244.719Z' fill='white' />
        <path
          d='M261.128 67.8558C261.198 67.8558 261.265 67.8281 261.314 67.7788C261.364 67.7294 261.391 67.6625 261.391 67.5927V24.7807C261.391 24.7461 261.385 24.7119 261.371 24.68C261.358 24.6481 261.339 24.6191 261.314 24.5946C261.29 24.5702 261.261 24.5508 261.229 24.5376C261.197 24.5244 261.163 24.5176 261.128 24.5176C261.094 24.5176 261.06 24.5244 261.028 24.5376C260.996 24.5508 260.967 24.5702 260.942 24.5946C260.918 24.6191 260.898 24.6481 260.885 24.68C260.872 24.7119 260.865 24.7461 260.865 24.7807V67.5927C260.865 67.6273 260.872 67.6615 260.885 67.6934C260.898 67.7253 260.918 67.7543 260.942 67.7788C260.967 67.8032 260.996 67.8226 261.028 67.8358C261.06 67.849 261.094 67.8558 261.128 67.8558Z'
          fill='#F0F0F0'
        />
        <path d='M225.268 71.5261L215.009 22.3877H208.764L219.03 71.5261H225.268Z' fill='white' />
        <path d='M182.971 71.5323V22.3877H182.501V71.5323H182.971Z' fill='#E6E6E6' />
        <path opacity='0.6' d='M177.947 27.9442H264.248L264.586 23.8164H178.286L177.947 27.9442Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M177.947 34.7088H264.248L264.586 30.5811H178.286L177.947 34.7088Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M177.947 41.474H264.248L264.586 37.3525H178.286L177.947 41.474Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M177.947 48.244H264.248L264.586 44.1162H178.286L177.947 48.244Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M177.947 55.0086H264.248L264.586 50.8809H178.286L177.947 55.0086Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M177.947 61.7733H264.248L264.586 57.6455H178.286L177.947 61.7733Z' fill='#EBEBEB' />
        <path d='M255.359 164.61H237.264V168.181H255.359V164.61Z' fill='#E6E6E6' />
        <path d='M203.132 205.713H206.471V123.615H203.132V205.713Z' fill='#E6E6E6' />
        <path d='M191.556 168.175H237.256V164.605H191.556V168.175Z' fill='#F5F5F5' />
        <path d='M255.359 184.135H237.264V187.705H255.359V184.135Z' fill='#E6E6E6' />
        <path d='M191.556 187.699H237.256V184.129H191.556V187.699Z' fill='#F5F5F5' />
        <path d='M255.359 125.569H237.264V129.14H255.359V125.569Z' fill='#E6E6E6' />
        <path d='M191.556 129.134H237.256V125.564H191.556V129.134Z' fill='#F5F5F5' />
        <path d='M255.359 145.087H237.264V148.657H255.359V145.087Z' fill='#E6E6E6' />
        <path d='M191.556 148.657H237.256V145.087H191.556V148.657Z' fill='#F5F5F5' />
        <path d='M248.837 205.713H252.176V123.615H248.837V205.713Z' fill='#E6E6E6' />
        <path d='M233.917 205.713H237.256V123.615H233.917V205.713Z' fill='#F5F5F5' />
        <path d='M191.558 205.713H194.896V123.615H191.558V205.713Z' fill='#F5F5F5' />
        <path d='M40.9477 205.713H75.1348L75.1348 139.387H40.9477L40.9477 205.713Z' fill='#E6E6E6' />
        <path d='M50.0738 205.713H40.9414V196.556H59.6447L50.0738 205.713Z' fill='#FAFAFA' />
        <path d='M134.153 205.713H168.34V139.387H134.153V205.713Z' fill='#E6E6E6' />
        <path d='M40.9482 202.562H142.125V139.387H40.9482L40.9482 202.562Z' fill='#FAFAFA' />
        <path d='M132.993 205.713H142.119V196.556H123.422L132.993 205.713Z' fill='#FAFAFA' />
        <path d='M48.0263 178.735H135.041V162.926H48.0263V178.735Z' fill='#F0F0F0' />
        <path d='M48.0263 197.927H135.041V182.117H48.0263V197.927Z' fill='#F0F0F0' />
        <path
          d='M118.505 164.586H64.5684C63.8075 164.586 63.0779 164.284 62.5399 163.746C62.0019 163.208 61.6996 162.478 61.6996 161.717V161.523H121.367V161.717C121.367 162.477 121.066 163.206 120.529 163.743C119.993 164.281 119.264 164.584 118.505 164.586Z'
          fill='#FAFAFA'
        />
        <path d='M48.0263 159.55H135.041V143.74H48.0263V159.55Z' fill='#F0F0F0' />
        <path
          d='M118.505 145.425H64.5684C63.8075 145.425 63.0779 145.123 62.5399 144.585C62.0019 144.047 61.6996 143.317 61.6996 142.556V142.362H121.367V142.556C121.367 143.316 121.066 144.045 120.529 144.582C119.993 145.12 119.264 145.423 118.505 145.425Z'
          fill='#FAFAFA'
        />
        <path
          d='M118.505 183.803H64.5684C63.8075 183.803 63.0779 183.5 62.5399 182.962C62.0019 182.424 61.6996 181.695 61.6996 180.934V180.74H121.367V180.934C121.367 181.694 121.066 182.422 120.529 182.96C119.993 183.498 119.264 183.801 118.505 183.803Z'
          fill='#FAFAFA'
        />
        <path d='M46.3927 75.2158L132.693 75.2158V18.6989L46.3927 18.6989V75.2158Z' fill='#E6E6E6' />
        <path d='M43.7612 75.2158L131.465 75.2158V18.6989L43.7612 18.6989V75.2158Z' fill='#F0F0F0' />
        <path d='M46.3927 86.3018L132.693 86.3018V75.2088L46.3927 75.2088V86.3018Z' fill='#E6E6E6' />
        <path d='M39.3686 86.3018L127.072 86.3018V75.2088L39.3686 75.2088V86.3018Z' fill='#F0F0F0' />
        <path d='M127.775 71.5323V22.3877L47.444 22.3877V71.5323H127.775Z' fill='#FAFAFA' />
        <path d='M109.66 71.5261L99.4005 22.3877H83.3906L93.6505 71.5261H109.66Z' fill='white' />
        <path
          d='M126.066 67.8558C126.136 67.8558 126.202 67.8281 126.252 67.7788C126.301 67.7294 126.329 67.6625 126.329 67.5927V24.7807C126.329 24.7461 126.322 24.7119 126.309 24.68C126.296 24.6481 126.276 24.6191 126.252 24.5946C126.227 24.5702 126.198 24.5508 126.166 24.5376C126.135 24.5244 126.1 24.5176 126.066 24.5176C125.996 24.5176 125.929 24.5453 125.88 24.5946C125.83 24.644 125.803 24.7109 125.803 24.7807V67.5927C125.803 67.6625 125.83 67.7294 125.88 67.7788C125.929 67.8281 125.996 67.8558 126.066 67.8558Z'
          fill='#F0F0F0'
        />
        <path d='M90.2118 71.5261L79.9519 22.3877H73.707L83.9669 71.5261H90.2118Z' fill='white' />
        <path d='M47.9121 71.5323V22.3877H47.4423V71.5323H47.9121Z' fill='#E6E6E6' />
        <path opacity='0.6' d='M42.8828 27.9442H129.183L129.522 23.8164H43.2211L42.8828 27.9442Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M42.8828 30.268H129.183L129.522 26.1465H43.2211L42.8828 30.268Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M42.8828 32.5985H129.183L129.522 28.4707H43.2211L42.8828 32.5985Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M42.8828 34.9223H129.183L129.522 30.8008H43.2211L42.8828 34.9223Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M42.8828 37.2518H129.183L129.522 33.124H43.2211L42.8828 37.2518Z' fill='#EBEBEB' />
        <path opacity='0.6' d='M42.8828 39.5819H129.183L129.522 35.4541H43.2211L42.8828 39.5819Z' fill='#EBEBEB' />
        <path d='M60.1396 137.351H63.1211L63.1211 104.097H60.1396L60.1396 137.351Z' fill='#F5F5F5' />
        <path d='M60.5138 137.333H61.3594L61.3594 104.079H60.5138L60.5138 137.333Z' fill='#FAFAFA' />
        <path d='M61.711 137.333H62.043L62.043 104.079H61.711L61.711 137.333Z' fill='#FAFAFA' />
        <path
          d='M72.6738 139.387H50.6007C50.6007 138.649 50.8937 137.942 51.4153 137.42C51.9368 136.899 52.6442 136.606 53.3818 136.606H69.8865C70.6241 136.606 71.3315 136.899 71.853 137.42C72.3746 137.942 72.6676 138.649 72.6676 139.387H72.6738Z'
          fill='#F0F0F0'
        />
        <path
          d='M56.2687 124.749C56.4958 124.747 56.7131 124.656 56.8737 124.496C57.0342 124.335 57.1252 124.118 57.1268 123.891V102.081C57.1268 101.853 57.0364 101.635 56.8755 101.474C56.7145 101.313 56.4963 101.223 56.2687 101.223C56.1555 101.222 56.0432 101.243 55.9384 101.286C55.8335 101.329 55.7382 101.392 55.6578 101.472C55.5775 101.552 55.5137 101.646 55.4702 101.751C55.4267 101.855 55.4043 101.968 55.4043 102.081V123.885C55.4043 124.114 55.4954 124.334 55.6575 124.496C55.8196 124.658 56.0394 124.749 56.2687 124.749Z'
          fill='#F0F0F0'
        />
        <path d='M48.2695 112.008H74.984L71.9086 93.3428H51.345L48.2695 112.008Z' fill='#E0E0E0' />
        <path
          d='M156.589 233.999C223.661 233.999 278.035 230.825 278.035 226.909C278.035 222.993 223.661 219.818 156.589 219.818C89.5158 219.818 35.1426 222.993 35.1426 226.909C35.1426 230.825 89.5158 233.999 156.589 233.999Z'
          fill='#F5F5F5'
        />
        <path d='M65.2165 92.9433L64.5918 92.9883L65.4239 104.54L66.0486 104.495L65.2165 92.9433Z' fill='#00ACE7' />
        <path d='M66.4744 110.458L65.8496 110.503L66.1628 114.851L66.7876 114.806L66.4744 110.458Z' fill='#00ACE7' />
        <path
          d='M211.529 163.597H77.3865C75.8933 163.57 74.4629 162.991 73.3711 161.972C72.2793 160.953 71.6034 159.566 71.4736 158.078L64.8842 66.5476C64.823 65.8417 64.9102 65.1307 65.1404 64.4606C65.3706 63.7905 65.7385 63.176 66.2206 62.6567C66.7027 62.1375 67.2882 61.7249 67.9394 61.4457C68.5907 61.1665 69.2932 61.0268 70.0017 61.0355H204.144C205.636 61.0622 207.066 61.64 208.158 62.6577C209.249 63.6754 209.926 65.0609 210.057 66.5476L216.646 158.078C216.708 158.785 216.62 159.496 216.39 160.166C216.16 160.837 215.792 161.452 215.31 161.971C214.828 162.491 214.243 162.904 213.592 163.184C212.94 163.464 212.238 163.605 211.529 163.597Z'
          fill='#00ACE7'
        />
        <path
          d='M212.043 163.597H77.9002C76.407 163.57 74.9766 162.991 73.8848 161.972C72.793 160.953 72.117 159.566 71.9873 158.078L65.3979 66.5476C65.3366 65.8417 65.4239 65.1307 65.6541 64.4606C65.8842 63.7905 66.2522 63.176 66.7343 62.6567C67.2164 62.1375 67.8019 61.7249 68.4531 61.4457C69.1043 61.1665 69.8068 61.0268 70.5153 61.0355H204.658C206.15 61.0622 207.58 61.64 208.671 62.6577C209.763 63.6754 210.439 65.0609 210.571 66.5476L217.16 158.078C217.222 158.785 217.136 159.496 216.906 160.167C216.676 160.838 216.308 161.453 215.826 161.973C215.344 162.493 214.758 162.906 214.106 163.186C213.455 163.466 212.752 163.605 212.043 163.597Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M212.043 163.597H77.9002C76.407 163.57 74.9766 162.991 73.8848 161.972C72.793 160.953 72.117 159.566 71.9873 158.078L65.3979 66.5476C65.3366 65.8417 65.4239 65.1307 65.6541 64.4606C65.8842 63.7905 66.2522 63.176 66.7343 62.6567C67.2164 62.1375 67.8019 61.7249 68.4531 61.4457C69.1043 61.1665 69.8068 61.0268 70.5153 61.0355H204.658C206.15 61.0622 207.58 61.64 208.671 62.6577C209.763 63.6754 210.439 65.0609 210.571 66.5476L217.16 158.078C217.222 158.785 217.136 159.496 216.906 160.167C216.676 160.838 216.308 161.453 215.826 161.973C215.344 162.493 214.758 162.906 214.106 163.186C213.455 163.466 212.752 163.605 212.043 163.597Z'
          fill='white'
        />
        <path
          d='M204.858 63.791H70.7153C70.565 63.791 70.4146 63.791 70.2643 63.791C66.8381 64.0729 67.5396 69.2905 70.9971 69.2905H205.365C208.829 69.2905 208.779 64.0729 205.309 63.791C205.158 63.791 205.008 63.791 204.858 63.791Z'
          fill='#00ACE7'
        />
        <path
          d='M74.2116 66.5477C74.2247 66.7092 74.2039 66.8716 74.1504 67.0245C74.0969 67.1775 74.0119 67.3175 73.9009 67.4355C73.79 67.5536 73.6555 67.647 73.5061 67.7099C73.3568 67.7728 73.196 67.8036 73.034 67.8004C72.6936 67.7956 72.3669 67.665 72.1171 67.4337C71.8673 67.2024 71.712 66.8868 71.681 66.5477C71.6689 66.387 71.6902 66.2255 71.7437 66.0734C71.7972 65.9213 71.8817 65.782 71.9918 65.6643C72.1019 65.5465 72.2352 65.4529 72.3833 65.3893C72.5314 65.3258 72.6912 65.2936 72.8523 65.295C73.1935 65.2996 73.5208 65.43 73.7717 65.6612C74.0225 65.8924 74.1792 66.2081 74.2116 66.5477Z'
          fill='#FAFAFA'
        />
        <path
          d='M78.5123 66.5477C78.5255 66.7092 78.5047 66.8716 78.4512 67.0245C78.3977 67.1775 78.3127 67.3175 78.2017 67.4355C78.0907 67.5536 77.9563 67.647 77.8069 67.7099C77.6576 67.7728 77.4968 67.8036 77.3348 67.8004C76.9943 67.7956 76.6677 67.665 76.4179 67.4337C76.1681 67.2024 76.0127 66.8868 75.9818 66.5477C75.9697 66.387 75.991 66.2255 76.0445 66.0734C76.098 65.9213 76.1825 65.782 76.2926 65.6643C76.4026 65.5465 76.536 65.4529 76.6841 65.3893C76.8322 65.3258 76.9919 65.2936 77.1531 65.295C77.4942 65.2996 77.8216 65.43 78.0725 65.6612C78.3233 65.8924 78.48 66.2081 78.5123 66.5477Z'
          fill='#FAFAFA'
        />
        <path
          d='M82.8113 66.5478C82.8244 66.7087 82.8037 66.8706 82.7506 67.0231C82.6975 67.1756 82.613 67.3153 82.5028 67.4332C82.3925 67.5512 82.2588 67.6448 82.1102 67.708C81.9616 67.7713 81.8014 67.8028 81.64 67.8005C81.2991 67.7971 80.9718 67.6669 80.7216 67.4354C80.4715 67.2038 80.3166 66.8874 80.287 66.5478C80.2739 66.3868 80.2946 66.2249 80.3477 66.0724C80.4009 65.9199 80.4853 65.7802 80.5956 65.6623C80.7058 65.5443 80.8396 65.4507 80.9881 65.3875C81.1367 65.3243 81.2969 65.2928 81.4583 65.295C81.7984 65.3012 82.1242 65.4323 82.3737 65.6634C82.6233 65.8944 82.779 66.2092 82.8113 66.5478Z'
          fill='#FAFAFA'
        />
        <path
          d='M208.485 154.458H80.1736C79.6326 154.449 79.114 154.24 78.7183 153.871C78.3226 153.502 78.0779 152.999 78.0315 152.46L72.6134 77.1895C72.5893 76.9312 72.6204 76.6707 72.7046 76.4254C72.7889 76.18 72.9244 75.9554 73.1021 75.7664C73.2798 75.5774 73.4957 75.4284 73.7354 75.3293C73.9751 75.2301 74.2332 75.1831 74.4925 75.1914H202.835C203.376 75.1988 203.896 75.4075 204.292 75.7769C204.688 76.1463 204.932 76.6498 204.977 77.1895L210.401 152.46C210.424 152.721 210.39 152.984 210.303 153.231C210.216 153.478 210.077 153.704 209.896 153.893C209.714 154.082 209.494 154.23 209.251 154.328C209.008 154.425 208.746 154.47 208.485 154.458Z'
          fill='white'
        />
        <path d='M154.416 125.79L152.324 96.7765L145.997 92.9619H128.578L130.946 125.79H154.416Z' fill='white' />
        <path
          d='M154.416 126.089H130.946C130.869 126.09 130.795 126.061 130.738 126.008C130.681 125.956 130.646 125.884 130.639 125.807L128.278 92.9859C128.275 92.9438 128.281 92.9015 128.295 92.8617C128.309 92.8219 128.331 92.7853 128.359 92.7542C128.387 92.7241 128.421 92.7002 128.459 92.684C128.497 92.6678 128.538 92.6597 128.579 92.6602H145.998C146.053 92.6591 146.108 92.6744 146.154 92.7041L152.481 96.5124C152.524 96.5363 152.56 96.5707 152.586 96.6122C152.612 96.6538 152.628 96.7013 152.631 96.7504L154.711 125.764C154.715 125.806 154.709 125.848 154.695 125.888C154.681 125.928 154.659 125.965 154.629 125.995C154.573 126.052 154.497 126.086 154.416 126.089ZM131.241 125.463H154.084L152.03 96.932L145.942 93.2678H128.904L131.241 125.463Z'
          fill='#00ACE7'
        />
        <path d='M152.324 96.7765L145.998 92.9619L148.322 98.0104L152.324 96.7765Z' fill='#EBEBEB' />
        <path
          d='M148.322 98.3232C148.263 98.3231 148.206 98.3058 148.157 98.2735C148.108 98.2413 148.069 98.1954 148.046 98.1415L145.722 93.1306C145.692 93.0716 145.682 93.0043 145.695 92.9391C145.707 92.874 145.74 92.8147 145.789 92.7704C145.838 92.7261 145.901 92.6993 145.967 92.6942C146.033 92.6892 146.099 92.706 146.154 92.7423L152.481 96.5506C152.532 96.5812 152.573 96.6258 152.6 96.679C152.626 96.7323 152.637 96.792 152.631 96.8512C152.622 96.9084 152.597 96.9619 152.559 97.006C152.522 97.0501 152.473 97.0832 152.418 97.1018L148.409 98.3545L148.322 98.3232ZM146.687 93.7382L148.485 97.6467L151.616 96.6884L146.687 93.7382Z'
          fill='#00ACE7'
        />
        <path
          d='M138.426 107.749C138.436 107.92 138.411 108.091 138.353 108.252C138.294 108.413 138.204 108.56 138.086 108.684C137.969 108.809 137.827 108.908 137.67 108.975C137.513 109.043 137.344 109.078 137.173 109.077C136.814 109.071 136.471 108.931 136.209 108.686C135.948 108.441 135.787 108.107 135.757 107.749C135.741 107.578 135.762 107.404 135.818 107.241C135.874 107.078 135.965 106.929 136.083 106.804C136.202 106.679 136.346 106.58 136.506 106.516C136.666 106.451 136.838 106.421 137.01 106.428C137.368 106.434 137.71 106.573 137.972 106.817C138.233 107.061 138.395 107.393 138.426 107.749Z'
          fill='#00ACE7'
        />
        <path
          d='M147.007 107.75C147.024 107.922 147.003 108.096 146.947 108.259C146.89 108.422 146.8 108.572 146.682 108.697C146.563 108.823 146.419 108.922 146.259 108.988C146.099 109.053 145.927 109.084 145.755 109.078C145.395 109.072 145.051 108.932 144.789 108.687C144.526 108.442 144.364 108.108 144.333 107.75C144.318 107.579 144.339 107.406 144.396 107.243C144.452 107.081 144.543 106.932 144.661 106.807C144.78 106.682 144.923 106.584 145.083 106.518C145.242 106.453 145.414 106.422 145.586 106.428C145.945 106.432 146.29 106.569 146.553 106.814C146.816 107.058 146.978 107.392 147.007 107.75Z'
          fill='#00ACE7'
        />
        <path
          d='M149.436 116.35C149.359 116.35 149.285 116.321 149.229 116.268C149.174 116.216 149.14 116.144 149.135 116.068C148.978 113.938 145.634 112.203 141.681 112.203C139.057 112.203 136.702 112.98 135.537 114.239C135.298 114.469 135.111 114.747 134.988 115.055C134.865 115.363 134.809 115.693 134.823 116.024C134.825 116.065 134.82 116.107 134.807 116.146C134.794 116.185 134.773 116.221 134.746 116.252C134.692 116.315 134.614 116.353 134.531 116.359C134.448 116.365 134.366 116.338 134.303 116.283C134.241 116.228 134.202 116.151 134.196 116.068C134.178 115.654 134.247 115.24 134.398 114.854C134.549 114.468 134.779 114.118 135.073 113.826C136.326 112.448 138.875 111.596 141.662 111.596C146.003 111.596 149.542 113.544 149.718 116.024C149.721 116.064 149.716 116.104 149.704 116.143C149.692 116.181 149.672 116.216 149.645 116.246C149.619 116.277 149.587 116.302 149.551 116.319C149.515 116.337 149.476 116.347 149.436 116.35Z'
          fill='#00ACE7'
        />
        <path
          d='M133.52 105.062C133.482 105.063 133.444 105.056 133.408 105.042C133.373 105.028 133.34 105.007 133.313 104.981C133.285 104.953 133.263 104.92 133.248 104.883C133.232 104.847 133.225 104.807 133.225 104.768C133.225 104.728 133.232 104.689 133.248 104.652C133.263 104.616 133.285 104.583 133.313 104.555L134.647 103.12C134.675 103.092 134.708 103.069 134.744 103.053C134.781 103.037 134.82 103.029 134.86 103.029C134.9 103.029 134.939 103.037 134.976 103.053C135.013 103.069 135.046 103.092 135.073 103.12C135.101 103.148 135.123 103.182 135.139 103.218C135.154 103.255 135.162 103.294 135.162 103.333C135.162 103.373 135.154 103.412 135.139 103.449C135.123 103.485 135.101 103.518 135.073 103.546L133.739 104.981C133.71 105.009 133.675 105.03 133.638 105.044C133.6 105.058 133.56 105.064 133.52 105.062Z'
          fill='#00ACE7'
        />
        <path
          d='M148.811 105.062C148.735 105.061 148.661 105.032 148.605 104.981L147.07 103.553C147.041 103.525 147.018 103.491 147.002 103.454C146.987 103.417 146.979 103.377 146.979 103.337C146.979 103.296 146.987 103.256 147.002 103.219C147.018 103.182 147.041 103.148 147.07 103.12C147.098 103.092 147.131 103.069 147.167 103.053C147.204 103.037 147.243 103.029 147.283 103.029C147.323 103.029 147.362 103.037 147.399 103.053C147.436 103.069 147.469 103.092 147.496 103.12L149.031 104.555C149.059 104.583 149.081 104.616 149.096 104.652C149.111 104.689 149.119 104.728 149.119 104.768C149.119 104.807 149.111 104.847 149.096 104.883C149.081 104.92 149.059 104.953 149.031 104.981C148.97 105.035 148.892 105.064 148.811 105.062Z'
          fill='#00ACE7'
        />
        <path
          d='M126.688 132.272H128.103L130.145 134.985L129.951 132.272H131.379L131.73 137.177H130.283L128.253 134.484L128.448 137.177H127.019L126.688 132.272Z'
          fill='#00ACE7'
        />
        <path
          d='M132.369 134.728C132.32 134.394 132.343 134.054 132.436 133.73C132.529 133.406 132.689 133.106 132.907 132.849C133.144 132.613 133.429 132.432 133.743 132.316C134.056 132.2 134.391 132.153 134.724 132.178C135.43 132.14 136.123 132.376 136.659 132.836C137.151 133.325 137.435 133.985 137.455 134.678C137.505 135.156 137.441 135.639 137.267 136.087C137.105 136.448 136.833 136.748 136.49 136.945C136.078 137.163 135.616 137.269 135.15 137.252C134.67 137.268 134.192 137.176 133.753 136.983C133.367 136.8 133.041 136.511 132.813 136.15C132.545 135.722 132.391 135.232 132.369 134.728ZM133.891 134.728C133.885 135.114 134.009 135.491 134.242 135.799C134.34 135.905 134.461 135.989 134.595 136.045C134.729 136.102 134.873 136.129 135.018 136.125C135.157 136.134 135.296 136.11 135.424 136.055C135.551 135.999 135.664 135.913 135.751 135.805C135.94 135.452 135.999 135.045 135.92 134.653C135.923 134.284 135.799 133.926 135.569 133.638C135.469 133.532 135.347 133.449 135.212 133.394C135.077 133.339 134.932 133.313 134.786 133.318C134.65 133.313 134.514 133.339 134.39 133.396C134.266 133.452 134.157 133.537 134.072 133.644C133.88 133.973 133.816 134.361 133.891 134.734V134.728Z'
          fill='#00ACE7'
        />
        <path
          d='M140.398 132.272H142.653C143.025 132.26 143.395 132.322 143.743 132.454C144.018 132.569 144.263 132.747 144.457 132.973C144.655 133.203 144.806 133.469 144.902 133.756C145.004 134.065 145.067 134.385 145.09 134.708C145.138 135.118 145.106 135.533 144.996 135.93C144.91 136.205 144.757 136.455 144.551 136.656C144.38 136.838 144.164 136.972 143.925 137.045C143.633 137.127 143.332 137.172 143.029 137.176H140.781L140.398 132.272ZM141.996 133.399L142.19 136.08H142.559C142.787 136.097 143.016 136.062 143.23 135.98C143.367 135.895 143.469 135.764 143.518 135.61C143.589 135.332 143.606 135.043 143.568 134.759C143.578 134.375 143.462 133.998 143.236 133.688C143.119 133.584 142.982 133.504 142.833 133.455C142.685 133.405 142.528 133.387 142.371 133.399H141.996Z'
          fill='#00ACE7'
        />
        <path
          d='M148.729 136.369H147.006L146.824 137.177H145.277L146.768 132.272H148.447L150.645 137.177H149.054L148.729 136.369ZM148.334 135.31L147.67 133.544L147.257 135.31H148.334Z'
          fill='#00ACE7'
        />
        <path
          d='M149.932 132.272H154.542L154.629 133.481H153.082L153.345 137.177H151.83L151.566 133.481H150.019L149.932 132.272Z'
          fill='#00ACE7'
        />
        <path
          d='M158.076 136.369H156.354L156.172 137.177H154.625L156.116 132.272H157.769L159.962 137.177H158.377L158.076 136.369ZM157.682 135.31L157.011 133.544L156.604 135.31H157.682Z'
          fill='#00ACE7'
        />
        <path
          d='M221.539 74.6035C222.097 74.3216 222.792 73.9771 223.381 73.6201C223.969 73.2631 224.633 72.9123 225.26 72.5427C226.481 71.7848 227.721 71.0395 228.893 70.2064C231.246 68.6009 233.452 66.7876 235.482 64.7883C235.739 64.5503 235.964 64.281 236.209 64.0304L236.566 63.6484L236.741 63.4542L236.829 63.3602C236.741 63.5294 236.829 63.4792 236.829 63.3164C236.884 63.0927 236.915 62.8639 236.923 62.6336C236.909 61.3018 236.755 59.975 236.465 58.675C235.908 55.8626 235.062 52.9751 234.229 50.1439L236.66 49.0791C238.195 51.7988 239.476 54.6547 240.487 57.6102C241.056 59.2046 241.418 60.8656 241.564 62.5522C241.602 63.0766 241.585 63.6035 241.514 64.1244C241.421 64.859 241.159 65.5621 240.75 66.1789L240.643 66.3229L240.562 66.4294L240.468 66.5484L240.274 66.7802L239.885 67.25C239.629 67.5631 239.378 67.8763 239.102 68.177C236.96 70.5405 234.57 72.6677 231.974 74.5221C230.722 75.4554 229.394 76.3448 228.034 77.1465C227.366 77.5641 226.684 77.9587 225.986 78.3304C225.278 78.7187 224.615 79.0632 223.813 79.4328L221.539 74.6035Z'
          fill='#FFB573'
        />
        <path
          d='M215.963 221.862C216.432 221.85 216.898 221.787 217.353 221.674C217.376 221.667 217.397 221.654 217.414 221.637C217.43 221.619 217.442 221.597 217.447 221.574C217.45 221.55 217.447 221.525 217.437 221.503C217.427 221.481 217.411 221.462 217.391 221.449C217.209 221.33 215.618 220.302 215.005 220.578C214.936 220.61 214.878 220.659 214.835 220.721C214.792 220.782 214.766 220.854 214.76 220.929C214.736 221.047 214.743 221.169 214.779 221.284C214.815 221.399 214.88 221.503 214.967 221.586C215.256 221.793 215.609 221.89 215.963 221.862ZM216.996 221.493C216.088 221.674 215.399 221.643 215.117 221.405C215.061 221.347 215.02 221.276 214.998 221.198C214.976 221.121 214.974 221.039 214.992 220.96C214.992 220.854 215.055 220.822 215.098 220.804C215.43 220.659 216.351 221.092 216.996 221.493Z'
          fill='#00ACE7'
        />
        <path
          d='M217.348 221.674C217.381 221.673 217.412 221.659 217.435 221.636C217.458 221.613 217.472 221.582 217.474 221.549C217.474 221.486 217.474 219.977 216.897 219.469C216.827 219.406 216.743 219.358 216.652 219.33C216.561 219.302 216.466 219.294 216.371 219.306C216.272 219.307 216.177 219.342 216.101 219.405C216.024 219.467 215.972 219.554 215.952 219.651C215.833 220.24 216.785 221.374 217.286 221.662L217.348 221.674ZM216.453 219.551C216.503 219.549 216.554 219.558 216.601 219.576C216.649 219.595 216.692 219.622 216.728 219.657C217.06 220.144 217.23 220.722 217.217 221.311C216.716 220.91 216.121 220.058 216.196 219.701C216.196 219.638 216.24 219.57 216.396 219.551H216.453Z'
          fill='#00ACE7'
        />
        <path
          d='M216.86 58.9316C216.233 62.0635 214.98 68.3271 217.141 70.4317C217.141 70.4317 216.29 73.5635 210.508 73.5635C204.144 73.5635 207.47 70.4317 207.47 70.4317C210.94 69.6049 210.846 67.0243 210.245 64.6065L216.86 58.9316Z'
          fill='#FFB573'
        />
        <path
          d='M206.249 71.6844C205.253 71.8223 206.105 69.2354 206.505 68.966C207.445 68.3397 219.571 67.469 219.49 68.966C219.44 69.5924 219.139 70.8451 218.613 71.2523C218.087 71.6594 214.968 70.3816 206.249 71.6844Z'
          fill='#263238'
        />
        <path
          d='M208.228 70.8136C207.432 71.0829 207.507 68.4772 207.777 68.164C208.403 67.4375 218.218 64.9132 218.506 66.3413C218.619 66.9677 218.675 68.2204 218.337 68.6651C217.999 69.1099 215.118 68.352 208.228 70.8136Z'
          fill='#263238'
        />
        <path
          d='M204.576 53.3258C204.537 53.3261 204.498 53.3179 204.462 53.3017C204.427 53.2854 204.395 53.2616 204.369 53.2319C204.178 52.9854 203.932 52.7872 203.65 52.6534C203.368 52.5196 203.059 52.4538 202.747 52.4614C202.715 52.4658 202.681 52.4635 202.65 52.4548C202.618 52.446 202.588 52.4309 202.563 52.4105C202.537 52.39 202.516 52.3645 202.5 52.3356C202.484 52.3067 202.475 52.2749 202.472 52.2422C202.466 52.177 202.486 52.1121 202.527 52.0609C202.567 52.0097 202.626 51.9759 202.691 51.9666C203.085 51.9519 203.477 52.0304 203.835 52.1959C204.193 52.3613 204.507 52.609 204.752 52.9187C204.794 52.9695 204.814 53.0348 204.808 53.1005C204.802 53.1661 204.771 53.2268 204.72 53.2694C204.678 53.3 204.628 53.3194 204.576 53.3258Z'
          fill='#263238'
        />
        <path
          d='M203.362 56.3887C203.078 57.3879 202.656 58.3429 202.109 59.2261C202.339 59.3582 202.595 59.4384 202.859 59.4611C203.123 59.4838 203.389 59.4485 203.638 59.3576L203.362 56.3887Z'
          fill='#FF5652'
        />
        <path
          d='M203.669 55.6243C203.713 56.0503 203.518 56.4136 203.243 56.4386C202.967 56.4637 202.71 56.1442 202.673 55.7246C202.635 55.3049 202.823 54.9416 203.092 54.9103C203.362 54.879 203.625 55.2047 203.669 55.6243Z'
          fill='#263238'
        />
        <path d='M203.218 54.9233L202.178 54.7354C202.178 54.7354 202.773 55.4745 203.218 54.9233Z' fill='#263238' />
        <path d='M223.423 221.549H218.174L218.587 209.397H223.836L223.423 221.549Z' fill='#FFB573' />
        <path
          d='M217.763 220.94H223.657C223.759 220.94 223.858 220.976 223.936 221.043C224.013 221.11 224.063 221.202 224.077 221.304L224.747 225.964C224.759 226.082 224.747 226.2 224.71 226.313C224.674 226.425 224.614 226.529 224.535 226.617C224.456 226.705 224.359 226.775 224.251 226.824C224.143 226.872 224.026 226.897 223.908 226.897C221.853 226.86 220.864 226.741 218.271 226.741C216.673 226.741 214.343 226.91 212.145 226.91C209.946 226.91 209.821 224.73 210.742 224.53C214.851 223.646 215.502 222.431 216.886 221.266C217.131 221.057 217.442 220.942 217.763 220.94Z'
          fill='#263238'
        />
        <g opacity='0.2'>
          <path d='M223.837 209.403H218.588L218.375 215.667H223.624L223.837 209.403Z' fill='#1C1C23' />
        </g>
        <path
          d='M202.547 77.7101C199.302 76.4574 196.208 75.0606 193.12 73.4821C189.95 71.9311 186.931 70.0901 184.101 67.9826C183.341 67.3938 182.625 66.7512 181.958 66.0597C181.783 65.8718 181.608 65.7027 181.438 65.4834C181.24 65.2594 181.056 65.023 180.887 64.7757C180.426 64.1022 180.138 63.3256 180.048 62.5145C179.979 61.7452 180.084 60.9703 180.355 60.247C180.573 59.6644 180.871 59.1144 181.238 58.6122C181.846 57.7876 182.56 57.0463 183.361 56.4074C184.779 55.277 186.312 54.2987 187.934 53.4885C188.711 53.0751 189.5 52.7056 190.302 52.3611C191.103 52.0166 191.893 51.6909 192.751 51.3965L193.859 53.808C191.128 55.5305 188.297 57.4221 186.218 59.5455C185.752 60.0171 185.343 60.5404 184.996 61.1051C184.696 61.6 184.702 61.9695 184.752 61.9382C184.802 61.9069 184.752 61.9382 184.84 62.0009L185.09 62.2702C185.199 62.3891 185.314 62.502 185.435 62.6084C185.964 63.1007 186.527 63.5549 187.12 63.9676C188.414 64.8809 189.761 65.7175 191.153 66.4731C192.563 67.2561 194.016 68.0077 195.494 68.7218C198.451 70.1311 201.507 71.4966 204.508 72.7368L202.547 77.7101Z'
          fill='#FFB573'
        />
        <path
          d='M195.426 52.0979L196.29 50.2188L192.332 48.8408C192.332 48.8408 191.079 52.5364 192.564 54.2464C193.181 54.1518 193.765 53.9062 194.264 53.5314C194.764 53.1565 195.163 52.6641 195.426 52.0979Z'
          fill='#FFB573'
        />
        <path d='M196.272 46.1475L193.121 45.2393L192.332 48.8471L196.272 50.2189V46.1475Z' fill='#FFB573' />
        <path
          d='M236.985 50.4002L237.442 46.0156L233.383 46.9614C233.383 46.9614 233.32 51.0829 235.469 51.7406L236.985 50.4002Z'
          fill='#FFB573'
        />
        <path d='M235.487 42.9219L232.838 44.1621L233.383 46.9557L237.442 46.0036L235.487 42.9219Z' fill='#FFB573' />
        <path
          d='M217.686 52.7872C217.88 57.9923 218.068 60.1908 215.719 63.1034C212.18 67.488 205.748 66.5798 204.006 61.5375C202.44 56.9964 202.39 49.2545 207.251 46.6927C208.32 46.1243 209.517 45.8386 210.727 45.8627C211.938 45.8869 213.122 46.2201 214.168 46.8308C215.213 47.4414 216.085 48.3092 216.701 49.3517C217.317 50.3942 217.656 51.5768 217.686 52.7872Z'
          fill='#FFB573'
        />
        <path
          d='M215.42 63.0907C220.531 60.8859 223.888 56.2007 222.472 48.9537C221.107 41.9885 216.416 41.3245 214.474 42.7464C212.532 44.1682 207.69 42.0386 204.784 44.4564C199.773 48.6906 204.508 53.2255 206.989 55.9627C208.467 59.0319 210.409 65.258 215.42 63.0907Z'
          fill='#263238'
        />
        <path
          d='M213.295 47.7012C213.918 48.502 214.761 49.1027 215.722 49.4292C216.683 49.7557 217.718 49.7937 218.7 49.5384C219.681 49.2831 220.567 48.7458 221.247 47.9929C221.927 47.2399 222.371 46.3042 222.525 45.3015C222.679 44.2987 222.536 43.2728 222.114 42.3504C221.691 41.4281 221.008 40.6497 220.148 40.1114C219.288 39.5731 218.289 39.2986 217.275 39.3217C216.261 39.3447 215.275 39.6644 214.441 40.2412C213.302 41.0805 212.543 42.3365 212.328 43.7346C212.113 45.1326 212.461 46.5588 213.295 47.7012Z'
          fill='#00ACE7'
        />
        <path
          d='M214.625 43.2349C213.892 39.0383 217.857 35.8062 223.168 37.3659C228.48 38.9255 225.674 43.6295 224.577 47.3877C223.481 51.1459 226.35 54.7287 227.54 51.8662C228.73 49.0038 226.726 48.1081 226.726 48.1081C226.726 48.1081 232.363 49.5675 227.127 55.9188C221.89 62.2702 217.149 55.2925 218.433 51.1209C219.473 47.7886 215.32 47.1998 214.625 43.2349Z'
          fill='#263238'
        />
        <path
          d='M209.318 44.8202C206.894 43.5675 202.791 42.534 200.455 46.4613C199.352 48.3404 199.778 50.8458 199.778 50.8458L206.912 51.3219L209.318 44.8202Z'
          fill='#263238'
        />
        <path
          d='M198.94 49.6054C198.919 49.6055 198.898 49.6012 198.879 49.5929C198.86 49.5846 198.843 49.5724 198.829 49.5571C198.815 49.5417 198.804 49.5237 198.797 49.5039C198.79 49.4842 198.788 49.4633 198.79 49.4426C198.79 49.3361 198.971 46.8181 200.493 45.2334C204.17 41.4126 208.479 44.607 209.707 45.6468C209.734 45.6747 209.749 45.7114 209.75 45.7499C209.751 45.7883 209.738 45.8258 209.713 45.8553C209.688 45.8847 209.654 45.9039 209.616 45.9094C209.577 45.9148 209.539 45.9061 209.507 45.8848C208.323 44.8638 204.201 41.8322 200.738 45.4526C199.297 46.9496 199.122 49.4363 199.122 49.4614C199.12 49.4831 199.114 49.5044 199.104 49.5237C199.094 49.543 199.08 49.56 199.063 49.5736C199.046 49.5872 199.026 49.597 199.005 49.6025C198.983 49.608 198.961 49.609 198.94 49.6054Z'
          fill='#263238'
        />
        <path
          d='M207.859 55.7809C207.829 56.7662 207.465 57.7119 206.825 58.4618C205.961 59.4702 204.946 58.9754 204.739 57.8354C204.533 56.8207 204.739 55.0794 205.829 54.4656C206.919 53.8517 207.871 54.6222 207.859 55.7809Z'
          fill='#FFB573'
        />
        <path
          d='M206.73 103.441C206.73 103.441 207.069 139.858 210.226 160.153C212.781 176.545 217.498 214.452 217.498 214.452H224.657C224.657 214.452 225.352 177.86 224.031 161.643C220.755 120.623 229.217 112.95 222.39 103.441H206.73Z'
          fill='#263238'
        />
        <path
          opacity='0.1'
          d='M206.73 103.441C206.73 103.441 207.069 139.858 210.226 160.153C212.781 176.545 217.498 214.452 217.498 214.452H224.657C224.657 214.452 225.352 177.86 224.031 161.643C220.755 120.623 229.217 112.95 222.39 103.441H206.73Z'
          fill='white'
        />
        <path
          opacity='0.3'
          d='M210.494 119.965C212.999 130.957 211.001 148.27 209.629 155.893C208.176 144.318 207.443 129.316 207.08 118.311C208.377 116.219 209.604 116.081 210.494 119.965Z'
          fill='#1C1C23'
        />
        <path d='M216.521 214.596H225.634L226.11 211.395L216.471 211.069L216.521 214.596Z' fill='#00ACE7' />
        <path
          d='M219.122 72.6996C219.98 70.9959 224.59 69.9248 227.108 69.9248L228.987 78.2993C228.987 78.2993 223.976 85.7468 221.884 84.9576C219.423 84.0243 217.287 76.3513 219.122 72.6996Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.4'
          d='M219.122 72.6996C219.98 70.9959 224.59 69.9248 227.108 69.9248L228.987 78.2993C228.987 78.2993 223.976 85.7468 221.884 84.9576C219.423 84.0243 217.287 76.3513 219.122 72.6996Z'
          fill='#1C1C23'
        />
        <path
          d='M213.809 212.548C213.82 212.529 213.826 212.507 213.826 212.485C213.826 212.463 213.82 212.441 213.809 212.422C213.796 212.4 213.776 212.382 213.753 212.371C213.729 212.36 213.703 212.356 213.677 212.36C213.414 212.397 211.097 212.767 210.783 213.431C210.75 213.49 210.732 213.557 210.732 213.625C210.732 213.693 210.75 213.76 210.783 213.819C210.836 213.919 210.913 214.004 211.007 214.067C211.101 214.129 211.21 214.167 211.322 214.176C212.08 214.258 213.201 213.205 213.765 212.566L213.809 212.548ZM211.028 213.512C211.241 213.161 212.443 212.836 213.358 212.667C212.531 213.506 211.804 213.976 211.379 213.919C211.305 213.911 211.235 213.885 211.174 213.843C211.113 213.802 211.063 213.746 211.028 213.681C211.014 213.658 211.007 213.631 211.007 213.603C211.007 213.576 211.014 213.549 211.028 213.525C211.028 213.525 211.022 213.519 211.028 213.512Z'
          fill='#00ACE7'
        />
        <path
          d='M213.809 212.548C213.819 212.53 213.825 212.51 213.825 212.489C213.825 212.468 213.819 212.447 213.809 212.429C213.771 212.379 212.844 211.314 211.986 211.258C211.862 211.246 211.737 211.26 211.62 211.3C211.502 211.34 211.394 211.405 211.303 211.49C211.212 211.546 211.144 211.634 211.113 211.736C211.081 211.839 211.089 211.95 211.134 212.047C211.403 212.586 213.07 212.768 213.74 212.63C213.757 212.623 213.772 212.612 213.784 212.598C213.796 212.583 213.805 212.566 213.809 212.548ZM211.36 211.759C211.387 211.717 211.421 211.679 211.46 211.646C211.525 211.584 211.603 211.537 211.689 211.508C211.774 211.478 211.865 211.468 211.955 211.477C212.536 211.621 213.058 211.94 213.452 212.392C212.706 212.454 211.504 212.229 211.341 211.909C211.33 211.885 211.327 211.858 211.33 211.832C211.333 211.805 211.343 211.78 211.36 211.759Z'
          fill='#00ACE7'
        />
        <path
          d='M219.276 210.324L214.478 212.454L212.342 208.007L209.549 202.182L209.217 201.505L214.015 199.369L214.384 200.127L217.09 205.771L219.276 210.324Z'
          fill='#FFB573'
        />
        <path
          opacity='0.2'
          d='M217.09 205.771L212.342 208.007L209.549 202.181L214.384 200.127L217.09 205.771Z'
          fill='#1C1C23'
        />
        <path
          d='M201.105 103.441C201.105 103.441 185.778 141.543 189.93 159.489C193.689 175.705 210.287 206.416 210.287 206.416L216.72 203.196C216.72 203.196 206.454 166.122 205.345 158.067C203.178 142.408 217.102 120.278 217.102 103.441H201.105Z'
          fill='#263238'
        />
        <path
          opacity='0.1'
          d='M201.105 103.441C201.105 103.441 185.778 141.543 189.93 159.489C193.689 175.705 210.287 206.416 210.287 206.416L216.72 203.196C216.72 203.196 206.454 166.122 205.345 158.067C203.178 142.408 217.102 120.278 217.102 103.441H201.105Z'
          fill='white'
        />
        <path
          d='M213.628 211.789L218.388 208.319C218.47 208.259 218.571 208.23 218.672 208.238C218.773 208.246 218.868 208.29 218.939 208.363L222.228 211.739C222.308 211.827 222.368 211.931 222.405 212.044C222.442 212.157 222.454 212.277 222.442 212.395C222.429 212.513 222.392 212.628 222.333 212.73C222.273 212.833 222.193 212.922 222.096 212.992C220.411 214.17 219.541 214.658 217.449 216.18C216.158 217.12 213.59 219.193 211.811 220.49C210.032 221.786 208.679 220.095 209.306 219.393C212.112 216.262 212.701 214.32 213.139 212.566C213.207 212.258 213.38 211.984 213.628 211.789Z'
          fill='#263238'
        />
        <path d='M209.876 207.775L218.163 203.985L217.104 200.609L208.229 204.656L209.876 207.775Z' fill='#00ACE7' />
        <path
          d='M205.171 72.2732C204.488 70.4944 198.5 68.515 195.493 67.8887L194.172 77.7351C194.172 77.7351 199.083 84.788 201.231 84.1992C203.774 83.5165 206.649 76.0815 205.171 72.2732Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.4'
          d='M205.171 72.2732C204.488 70.4944 198.5 68.515 195.493 67.8887L194.172 77.7351C194.172 77.7351 199.083 84.788 201.231 84.1992C203.774 83.5165 206.649 76.0815 205.171 72.2732Z'
          fill='#1C1C23'
        />
        <path
          d='M198.601 71.7723C198.601 71.7723 196.096 72.6554 201.106 103.441H222.403C222.046 94.7661 222.033 89.4232 226.161 71.6219C223.177 71.0062 220.152 70.6085 217.11 70.4318C213.891 70.1998 210.659 70.1998 207.439 70.4318C203.311 70.8076 198.601 71.7723 198.601 71.7723Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.4'
          d='M198.601 71.7723C198.601 71.7723 196.096 72.6554 201.106 103.441H222.403C222.046 94.7661 222.033 89.4232 226.161 71.6219C223.177 71.0062 220.152 70.6085 217.11 70.4318C213.891 70.1998 210.659 70.1998 207.439 70.4318C203.311 70.8076 198.601 71.7723 198.601 71.7723Z'
          fill='#1C1C23'
        />
        <path
          d='M222.736 102.189L223.694 104.099C223.769 104.243 223.594 104.393 223.35 104.393H201.001C200.807 104.393 200.65 104.299 200.638 104.18L200.443 102.301C200.443 102.17 200.6 102.057 200.807 102.057H222.36C222.429 102.044 222.5 102.049 222.566 102.072C222.632 102.095 222.69 102.135 222.736 102.189Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.3'
          d='M222.736 102.189L223.694 104.099C223.769 104.243 223.594 104.393 223.35 104.393H201.001C200.807 104.393 200.65 104.299 200.638 104.18L200.443 102.301C200.443 102.17 200.6 102.057 200.807 102.057H222.36C222.429 102.044 222.5 102.049 222.566 102.072C222.632 102.095 222.69 102.135 222.736 102.189Z'
          fill='white'
        />
        <path
          d='M219.855 104.587H220.431C220.55 104.587 220.637 104.53 220.625 104.455L220.356 101.981C220.356 101.906 220.249 101.85 220.136 101.85H219.554C219.441 101.85 219.353 101.906 219.36 101.981L219.629 104.455C219.623 104.543 219.723 104.587 219.855 104.587Z'
          fill='#263238'
        />
        <path
          d='M205.829 104.587H206.405C206.518 104.587 206.606 104.53 206.6 104.455L206.33 101.981C206.33 101.906 206.224 101.85 206.105 101.85H205.528C205.409 101.85 205.328 101.906 205.334 101.981L205.604 104.455C205.61 104.543 205.71 104.587 205.829 104.587Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_588_16255'>
          <rect width='313.183' height='233.39' fill='white' transform='translate(0 0.610352)' />
        </clipPath>
      </defs>
    </svg>
  );
}
