import { Checkbox } from '@shadcnComponent';
import { capitalizeSourceName } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function OAuthAccountListing({ listing, checkedId, setCheckedId, setCheckedAccount, dataSource }) {
  return (
    <div className='flex h-[9.5rem] flex-col overflow-y-auto md:h-[11.6875rem] lg:h-[8.1875rem] xxl:h-[11.6875rem]'>
      {listing?.map((item, index) => (
        <div
          key={index + 1}
          className=' flex items-center gap-2 border-b border-lightGray px-4 py-3.5 text-sm font-normal leading-[1.0588rem] md:gap-3 lg:gap-3 lg:py-3 lg:text-xs lg:leading-[0.9075rem] xxl:gap-3 xxl:py-3.5 xxl:text-sm xxl:leading-[1.0588rem]'
        >
          {item?.name && (
            <Checkbox
              id={`checkbox-${index + 1}`}
              checked={checkedId === index + 1}
              onCheckedChange={status => {
                setCheckedId(prev => (prev === index + 1 ? null : index + 1));
                setCheckedAccount(status ? item : null);
              }}
            />
          )}
          <label htmlFor={`checkbox-${index + 1}`}>
            {capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS
              ? item?.displayName
              : item?.name}
          </label>
        </div>
      ))}
    </div>
  );
}
