import React from 'react';
import { DialogContent, DialogFooter, DialogHeader, DialogTitle, Button, Dialog } from '@shadcnComponent';
import { toast } from 'react-toastify';
import { Progressbar } from '@common/components';
import {
  useCreateDashboard,
  CreateDashboardStep1,
  CreateDashboardStep2,
  CreateDashboardStep3,
  CreateDashboardStep4,
  CreateDashboardStep5,
} from '@pages/dashboard';
import {
  CREATE_DASHBOARD_BUTTON_ID,
  CREATE_DASHBOARD_STEP1_BUTTON,
  CREATE_DASHBOARD_TYPE,
  ERROR_MESSAGE,
} from '@constants';

export function CreateDashboardDialog({ btnDisabled, setCreateDashboardStep1, isDriverEnabled }) {
  const {
    formik: createDashboardFormik,
    currentStep,
    handleBack,
    progressValue,
    showCreateDashboardDialog,
    setShowCreateDashboardDialog,
    addDataSourceListing,
    handleSelectedDataSource,
    addedDataSource,
    handleAddDataSource,
    selectedDataSourceIds,
    selectDashboardType,
    setSelectDashboardType,
    selectDashboardMode,
    setSelectDashboardMode,
    handleDashboardUpdate,
    imageRef,
    uploadedImage,
    setIsUploadedImage,
    isUploadedImage,
    templateListing,
    handleScroll,
    handleCategoryScroll,
    createTemplateDashboardStep,
    handleSelectedTemplate,
    selectedTemplateId,
    handleCreateTemplatedDashboard,
    createdDashboardId,
    createDashboardLoading,
    setCreateDashboardLoading,
    handleCreateDashboardStep,
    selectedTemplateImg,
    setTemplateName,
    handleImageUpload,
    dashboardsListingRefetch,
    selectedType,
    setSelectedType,
    handleCustomDashboardStep,
    selectedCategory,
    setSelectedCategory,
    templateCategory,
    selectedTemplateTab,
    setSelectedTemplateTab,
  } = useCreateDashboard();

  const handleNextBtn = () => {
    /* eslint-disable indent */
    switch (currentStep) {
      case 1:
        createDashboardFormik?.submitForm();
        break;
      case 2:
        handleAddDataSource({
          payload: { dataSources: selectedDataSourceIds },
        });
        break;
      case 3:
        selectDashboardType === CREATE_DASHBOARD_TYPE.CUSTOM
          ? handleCustomDashboardStep()
          : createTemplateDashboardStep();
        break;
      case 4:
        handleCreateTemplatedDashboard({ payload: { dashboardId: createdDashboardId } });
        setCreateDashboardLoading(true);
        break;
      case 5:
        handleDashboardUpdate({
          payload: { viewType: selectDashboardMode },
        });
        break;
      default:
        toast.error(ERROR_MESSAGE);
        break;
    }
    /* eslint-enable indent */
  };

  return (
    <Dialog
      open={showCreateDashboardDialog}
      onOpenChange={isOpen => {
        setShowCreateDashboardDialog(isOpen);
        if (!isOpen && currentStep > 1) dashboardsListingRefetch();
        setIsUploadedImage(false);
      }}
    >
      <div className='w-[calc(100vw-2.5rem)] sm:w-[10.6875rem] lg:w-40 xxl:w-[11.25rem]'>
        <Button
          id={CREATE_DASHBOARD_BUTTON_ID}
          onClick={handleCreateDashboardStep}
          disabled={btnDisabled}
          className='w-full md:h-10 md:text-sm md:leading-6 lg:h-8 lg:text-xs lg:leading-[.9375rem] xxl:h-10 xxl:text-sm xxl:leading-6'
        >
          Create New Dashboard
        </Button>
      </div>
      <DialogContent
        className={`w-[calc(100vw-2.5rem)] max-w-[24.375rem] overflow-y-auto overflow-x-hidden rounded-md px-5 py-6 scrollbar-none md:h-[47.375rem] md:max-w-[44rem] md:p-8 md:px-8 md:py-10 lg:h-[32.75rem] lg:max-w-[39rem] lg:p-8 xxl:h-[47.375rem] xxl:max-w-[55rem] xxl:p-10 ${currentStep === 1 && 'h-[35.5625rem]'} ${currentStep === 4 && 'h-[calc(100vh-150px)]'}`}
      >
        <DialogHeader className='block w-full gap-4 md:gap-6 lg:gap-4 xxl:w-[50rem] xxl:gap-6'>
          <DialogTitle className='mb-4 text-start text-lg leading-[1.375rem] md:mb-6 md:text-2xl md:leading-7 lg:left-7 lg:mb-4 lg:text-lg lg:leading-[1.375rem] xxl:mb-6 xxl:text-2xl xxl:leading-7'>
            Create Dashboard
          </DialogTitle>
          <Progressbar progressValue={progressValue} />
        </DialogHeader>
        <div className='flex h-[calc(100%-3.75rem)] flex-col gap-6 lg:h-[calc(100%-2.75rem)] xxl:h-[calc(100%-3.75rem)]'>
          {currentStep === 1 && (
            <CreateDashboardStep1
              isDriverEnabled={isDriverEnabled}
              setCreateDashboardStep1={setCreateDashboardStep1}
              formik={createDashboardFormik}
              imageRef={imageRef}
              handleImageUpload={handleImageUpload}
              uploadedImage={uploadedImage}
              setIsUploadedImage={setIsUploadedImage}
              isUploadedImage={isUploadedImage}
            />
          )}
          {currentStep === 2 && (
            <CreateDashboardStep2
              setCreateDashboardStep1={setCreateDashboardStep1}
              addDataSourceListing={addDataSourceListing}
              handleCheck={handleSelectedDataSource}
              addedDataSource={addedDataSource}
              selectedDataSourceIds={selectedDataSourceIds}
            />
          )}
          {currentStep === 3 && (
            <CreateDashboardStep3
              setCreateDashboardStep1={setCreateDashboardStep1}
              value={selectDashboardType}
              handleSelect={setSelectDashboardType}
            />
          )}
          {currentStep === 4 && (
            <CreateDashboardStep4
              setCreateDashboardStep1={setCreateDashboardStep1}
              templateListing={templateListing}
              handleScroll={handleScroll}
              handleCategoryScroll={handleCategoryScroll}
              handleSelectedTemplate={handleSelectedTemplate}
              selectedTemplateId={selectedTemplateId}
              selectedTemplateImg={selectedTemplateImg}
              setTemplateName={setTemplateName}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              templateCategory={templateCategory}
              selectedTemplateTab={selectedTemplateTab}
              setSelectedTemplateTab={setSelectedTemplateTab}
            />
          )}
          {currentStep === 5 && (
            <CreateDashboardStep5
              setCreateDashboardStep1={setCreateDashboardStep1}
              value={selectDashboardMode}
              handleSelect={setSelectDashboardMode}
            />
          )}
          <DialogFooter className='mt-auto w-full flex-col-reverse md:flex-row md:items-end md:gap-4 xxl:gap-3'>
            {[1, 2, 3, 4, 5].map(
              step =>
                currentStep === step && (
                  <React.Fragment key={step}>
                    <div className='w-full md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'>
                      {![1, 2].includes(step) && (
                        <Button
                          variant='outline'
                          onClick={() => handleBack(currentStep)}
                          className='mt-4 h-12 text-sm leading-[1.0625rem] md:mt-0 md:h-10 md:leading-6 lg:h-8 lg:text-[.625rem] lg:leading-3 xxl:h-10 xxl:text-sm xxl:leading-6'
                        >
                          Back
                        </Button>
                      )}
                    </div>
                    {!isUploadedImage && (
                      <div className='w-full md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'>
                        <Button
                          id={CREATE_DASHBOARD_STEP1_BUTTON}
                          onClick={handleNextBtn}
                          className='h-12 md:h-10 lg:h-8 lg:text-[.625rem] lg:leading-3 xxl:h-10 xxl:text-sm xxl:leading-6'
                          disabled={
                            currentStep === 4 && !selectedTemplateId ? true : createDashboardLoading ? true : false
                          }
                        >
                          {currentStep > 3 ? 'Create' : 'Next'}
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                )
            )}
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
