import { Popover, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { InfoIcon } from '@assets/svgs';

export function DomainInstruction({ parentClass }) {
  const instructions = [
    'To enable email white labeling, first add your email domain.',
    'After adding the domain, you\'ll receive corresponding DNS entries.',
    'The domain will initially display a \'pending\' status, meaning it hasn\'t been verified yet.',
    'To complete the verification, add the provided DNS entries to your Domain CNAME records.',
    'Once the CNAME records are updated, click \'verify\' to confirm the domain\'s status.',
    'After verification, you can use any email associated with the domain to send analytics reports.',
    'For adding a custom email, check the organization white labeling section.',
  ];

  return (
    <div
      className={`absolute -top-[2px] left-[3.375rem] md:-top-0 md:left-[4.3125rem] lg:-top-[0.375rem] lg:left-12 xxl:-top-0 xxl:left-[4.3125rem] ${parentClass}`}
    >
      <Popover>
        <PopoverTrigger asChild>
          <button>
            <InfoIcon className='h-4 w-4 lg:h-2.5 lg:w-2.5 xxl:h-4 xxl:w-4' />
          </button>
        </PopoverTrigger>
        <PopoverContent
          align='start'
          side='top'
          className='flex w-[calc(100vw-123px)] max-w-[19.1875rem] flex-col gap-[1.1875rem] px-3 py-1.5 text-xs font-normal leading-5 md:max-w-[32.5rem] md:gap-[1.0625rem] md:leading-[1.125rem] lg:max-w-[645px] lg:gap-[1.0625rem] lg:text-[0.625rem] xxl:max-w-[769px] xxl:gap-[1.4375rem] xxl:text-xs'
        >
          <p>Please follow these instructions to point your domain to the client access area:</p>
          <ul>
            {instructions?.map((instruction, index) => (
              <li key={index}>
                {index + 1}. {instruction}
              </li>
            ))}
          </ul>
          <p>
            Note: Cloudflare routing is incompatible with custom domains. Please disable Cloudflare for your custom
            domain if you&apos;re using this.
          </p>
        </PopoverContent>
      </Popover>
    </div>
  );
}
