import { NavLink, useParams, generatePath } from 'react-router-dom';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ScrollArea,
  ScrollBar,
  Button,
  Dialog,
  CommandInput,
  Command,
} from '@shadcnComponent';
import { debounce } from 'lodash';
import { BaseLoader, CustomPagination, DeleteDialog, Loader } from '@common/components';
import { useDataSource } from '@pages/dataSource';
import { ROUTES } from '@routes';
import { capitalizeString } from '@utils';
import { DATA_SOURCE_NOT_CONNECTED, DATA_SOURCE_SEARCH_NOT_MATCHED, TOTAL_ITEMS_PER_PAGE } from '@constants';
import { DeleteIcon, EmptyDashboardScreenIcon } from '@assets/svgs';

export function DataSource() {
  const { organizationId } = useParams();
  const {
    connectedDataSource,
    connectedDataSourceLoading,
    showDeleteDialog,
    setShowDeleteDialog,
    handleDeleteDataSource,
    deleteDataSourceLoading,
    setDeleteDataSourceLoading,
    setSearch,
    search,
    dataSourcePage,
    setDataSourcePage,
    connectedDataSourceFetching,
  } = useDataSource();

  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  return connectedDataSourceLoading ? (
    <BaseLoader />
  ) : (
    <div className='h-available mx-auto my-[1.6875rem] max-w-[30rem] px-5 md:my-[1.4375rem] md:max-w-[48rem] md:px-8 lg:my-4 lg:max-w-full xxl:my-6 xxl:px-10 '>
      <div className='mb-6 flex flex-col gap-6 md:mb-[1.875rem] md:gap-8 lg:mb-4 lg:flex-row lg:items-center lg:justify-end xxl:mb-6'>
        <div className='relative flex flex-col justify-end gap-6 md:flex-row lg:gap-4'>
          {(connectedDataSource?.data?.results.length > 0 || !!search) && (
            <Command
              className='h-12 w-full border border-lightGray text-slateGray md:h-11 lg:h-8 lg:w-[11.875rem] xxl:h-10 xxl:w-[16.5625rem]'
              onChange={handleSearchChange}
            >
              <CommandInput placeholder='Search' className='h-12 md:h-11 lg:h-8 xxl:h-10' />
            </Command>
          )}
          <NavLink
            to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}
            className='w-full md:w-[10.6875rem] lg:static lg:w-[8.0625rem] xxl:w-[9.0625rem]'
          >
            <Button>Add Data Source</Button>
          </NavLink>
        </div>
      </div>
      {connectedDataSourceLoading ? (
        <div className='relative h-[calc(100vh-11.25rem)] lg:h-[calc(100vh-16.875rem)] xxl:h-[calc(100vh-18.875rem)]'>
          <BaseLoader />
        </div>
      ) : connectedDataSource?.data?.results.length === 0 ? (
        <div className='flex h-[calc(100vh-258px)] flex-col items-center justify-center gap-6 md:gap-10 lg:gap-6 xxl:h-[calc(100vh-208px)] xxl:gap-10'>
          <EmptyDashboardScreenIcon className='h-[8.9375rem] w-[12rem] md:h-[11.75rem] md:w-[15.75rem] lg:h-[7.8125rem] lg:w-[10.5rem] xxl:h-[11.75rem] xxl:w-[15.75rem]' />
          <div>
            {search ? (
              <>
                <p className='text-center text-base font-bold leading-[1.21rem] text-darkSecondary md:text-lg md:leading-[1.3613rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-base xxl:leading-[1.21rem]'>
                  {DATA_SOURCE_SEARCH_NOT_MATCHED}
                </p>
                <p className='mt-3 max-w-[19.8125rem] text-center text-sm font-normal leading-[1.375rem] md:max-w-[30.8125rem] lg:mt-2 lg:max-w-[23.1875rem] lg:text-xs xxl:mt-3 xxl:max-w-[30.8125rem] xxl:text-sm'>
                  Sorry, we couldn&apos;t find any matches.
                </p>
              </>
            ) : (
              <p className='text-center text-base font-bold leading-[1.21rem] text-darkSecondary md:text-lg md:leading-[1.3613rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-base xxl:leading-[1.21rem]'>
                {connectedDataSourceFetching ? '' : DATA_SOURCE_NOT_CONNECTED}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-8'>
          <div className='h-[34rem] md:h-[37.5rem] lg:h-[calc(100vh-16.875rem)] xxl:h-[calc(100vh-18.875rem)]'>
            <ScrollArea className='relative z-[1] h-full w-[calc(100vw-40px)] whitespace-nowrap rounded-md border xsm:w-[440px] md:w-full lg:w-auto'>
              <Table className='text-lg'>
                <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
                  <TableRow>
                    <TableHead className='lg:px-[1.875rem] xxl:px-[3.75rem]'>Name</TableHead>
                    <TableHead className='lg:px-[1.875rem] xxl:px-[3.75rem]'>Account</TableHead>
                    <TableHead className='lg:px-[1.875rem] xxl:px-[3.75rem]'>Status</TableHead>
                    <TableHead className='w-[6.25rem] lg:px-[1.875rem] xxl:px-[3.75rem]'>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody className='pt-5'>
                  {connectedDataSource?.data?.results.map(connector => (
                    <TableRow key={connector?.id}>
                      <TableCell className='flex items-center gap-1 font-medium md:gap-1.5 lg:gap-2 lg:px-[1.875rem] lg:py-3 xxl:gap-4 xxl:px-[3.75rem]'>
                        <div className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6'>
                          <img
                            src={connector?.connector?.logo?.image}
                            alt='data source image'
                            className='h-full w-full'
                          />
                        </div>
                        {connector?.connector?.sourceName}
                      </TableCell>
                      <TableCell className='lg:px-[1.875rem] lg:py-3 xxl:px-[3.75rem]'>{connector?.account}</TableCell>
                      <TableCell className='flex items-center lg:px-[1.875rem] lg:py-3 xxl:px-[3.75rem]'>
                        {capitalizeString(connector?.syncStatus)}
                        {connector?.syncStatus == 'RUNNING' && <Loader strokeColorClass='status-loader' />}
                      </TableCell>
                      <TableCell className='lg:px-[1.875rem] lg:py-3 xxl:px-[3.75rem]'>
                        <Dialog
                          open={showDeleteDialog[connector?.id]}
                          onOpenChange={isOpen => {
                            setShowDeleteDialog({
                              ...showDeleteDialog,
                              [connector.id]: isOpen,
                            });
                          }}
                        >
                          <span
                            onClick={() => {
                              setShowDeleteDialog({
                                ...showDeleteDialog,
                                [connector.id]: true,
                                connector: connector,
                              });
                              setDeleteDataSourceLoading(false);
                            }}
                          >
                            <DeleteIcon className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                          </span>
                          <DeleteDialog
                            heading={connector?.connector?.sourceName}
                            text={connector?.connector?.description}
                            className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[21.8125rem] md:min-h-[16.5625rem] md:max-w-[28.125rem] lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
                            deleteBtnClick={() => {
                              handleDeleteDataSource();
                              setDeleteDataSourceLoading(true);
                            }}
                            isLoading={deleteDataSourceLoading}
                          />
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <ScrollBar orientation='horizontal' />
            </ScrollArea>
          </div>
          {connectedDataSource?.data?.count > TOTAL_ITEMS_PER_PAGE && (
            <CustomPagination page={dataSourcePage} setPage={setDataSourcePage} data={connectedDataSource} />
          )}
        </div>
      )}
    </div>
  );
}
