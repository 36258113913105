import { useEffect, useState } from 'react';
import { apiEndpoints, useGetQuery } from '@services';

export function useBillingTab() {
  const [page, setPage] = useState(1);

  const { data: billingListing, refetch: billingListingRefetch } = useGetQuery(
    'billing-listing',
    apiEndpoints.BILLING_LISTING,
    { page }
  );

  useEffect(() => {
    page && billingListingRefetch();
  }, [page]);

  return { billingListing, page, setPage };
}
