import SyncLoader from 'react-spinners/SyncLoader';
import { COLORS } from '@constants';

export function BaseLoader({ className }) {
  return (
    <div className={`w-available absolute flex h-full items-center justify-center ${className}`}>
      <SyncLoader color={COLORS.DARK_PRIMARY} />
    </div>
  );
}
