import { NotificationDropdown, NotificationSheet, ProfileDropdown } from '@common/components';

export function UserAction({ iconColor, userDetail, userDetailRefetch }) {
  return (
    <>
      <NotificationDropdown strokeColor={iconColor} userDetail={userDetail} userDetailRefetch={userDetailRefetch} />
      <NotificationSheet strokeColor={iconColor} />
      <ProfileDropdown strokeColor={iconColor} />
    </>
  );
}
