import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, generatePath, useNavigate } from 'react-router-dom';
import { Button, Command, CommandInput, Dialog, Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { debounce } from 'lodash';
import { Plus } from 'lucide-react';
import { AddEditOrgDialog, BaseLoader, Header, TourButton } from '@common/components';
import { useHome, useHomeEditDialog } from '@pages/Home';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import { useAppTours, getLocalStorageItem } from '@utils';
import {
  HOME_ORGANIZATION_TABS,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  ORGANIZATION_ID_BY_ONBOARDING,
} from '@constants';
import { HomeBottomIcon, HomeCircleIcon, HomeWelcomeIcon, PlusIconWhite, ZendashLogo } from '@assets/svgs';

export function Home() {
  const {
    formik,
    imageRef,
    organizations,
    organizationFetching,
    search,
    setSearch,
    page,
    setPage,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    setIsUploadedLogo,
    uploadedLogo,
    setUploadedLogo,
    createOrganizationLoading,
    userDetail,
    handleImageUpload,
  } = useHome();

  const {
    formik: editFormik,
    isUploadedLogo: isUploadedLogoEdit,
    setIsUploadedLogo: setIsUploadedLogoEdit,
    uploadedLogo: uploadedLogoEdit,
    setUploadedLogo: setUploadedLogoEdit,
    isDeleteOrgLoading,
    isEditOrganizationLoading,
    handleDeleteOrganizationBtn,
    handleDeleteBtn,
    showAddEditDialog,
    showDeleteOrgDialog,
    setShowDeleteOrgDialog,
    setShowAddEditDialog,
    selectedOrganization,
    setSelectedOrganization,
    manageOrganizationFetching,
    handleImageUpload: editHandleImageUpload,
    openEditDialogue,
  } = useHomeEditDialog();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isTemporaryOrganization = getLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
  const orgIdFromOnBoarding = getLocalStorageItem(ORGANIZATION_ID_BY_ONBOARDING);

  const [currentTab, setCurrentTab] = useState(HOME_ORGANIZATION_TABS.MY_ORGANIZATION);

  const { isFetching: userDetailFetching } = useGetQuery('user-details', apiEndpoints.USER_DETAIL);
  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  useEffect(() => {
    if (pathname === '/home/my-organizations') {
      navigate(ROUTES.MY_ORGANIZATIONS);
      setCurrentTab('my-organization');
    } else if (pathname === '/home/invited-organizations') {
      navigate(ROUTES.INVITED_ORGANIZATIONS);
      setCurrentTab('invited-organization');
    } else if (isTemporaryOrganization || orgIdFromOnBoarding) {
      navigate(generatePath(ROUTES.DASHBOARD, { organizationId: isTemporaryOrganization ?? orgIdFromOnBoarding }));
    } else {
      navigate(ROUTES.MY_ORGANIZATIONS);
    }
  }, [pathname]);

  const { createHomeOrgTour } = useAppTours();

  return (
    <div className='flex-1'>
      <Header logo={<ZendashLogo fillColor='#fff' />} />
      <div className='flex flex-col'>
        <div className='absolute top-[1.3125rem] -z-10 lg:-top-1.5 xxl:top-0'>
          <HomeCircleIcon className='h-[16.875rem] w-[16.875rem] xxl:h-[23.625rem] xxl:w-[23.625rem]' />
        </div>
        <div className='z-[5] mx-auto flex max-w-[30rem] flex-col gap-4 px-5 md:max-w-[44rem] md:gap-8 md:px-0 lg:max-w-[50rem] lg:gap-9 xxl:max-w-[63.125rem] xxl:gap-8'>
          <div className='z-100 relative mt-5 flex flex-col-reverse items-center gap-4 rounded-[.625rem] bg-homeBgPrimary p-5 md:mt-8 md:flex-row md:gap-[1.3125rem] md:px-6 md:py-[0.5625rem] lg:mx-auto lg:mt-[2.6875rem] lg:w-[41.75rem] lg:px-[1.625rem] lg:py-[1.6875rem] xxl:mt-[4.8125rem] xxl:w-[58.625rem] xxl:px-[2.25rem] xxl:pb-[2.625rem] xxl:pt-[2.5625rem]'>
            <div>
              <h1 className='mb-2 text-lg font-bold leading-[1.3613rem] text-darkPrimary md:mb-3 md:text-2xl md:leading-[1.8156rem] lg:mb-2 lg:text-xl lg:leading-6 xxl:mb-1.5 xxl:text-[1.75rem] xxl:leading-[2.125rem]'>
                Welcome to Zendash
              </h1>
              <p className='text-sm font-normal leading-6 md:text-base md:leading-[1.875rem] lg:max-w-[21.5rem] lg:pb-1 lg:text-xs lg:leading-[1.375rem] xxl:max-w-[30.1875rem] xxl:pb-[0.3125rem] xxl:text-lg xxl:leading-[1.9375rem]'>
                We&apos;re thrilled to have you on board. With Zendash, you&apos;re equipped to seamlessly manage your
                business and integrate data sources like never before.
              </p>
            </div>
            <div className='lg:absolute lg:-right-[1.3125rem] lg:-top-5 xxl:-right-4 xxl:-top-[3.625rem]'>
              <HomeWelcomeIcon className='h-[8.75rem] w-[13.625rem] md:h-[12.9375rem] md:w-[20.0625rem] lg:h-[12.25rem] lg:w-[18.9375rem] xxl:h-[17.25rem] xxl:w-[26.6875rem]' />
            </div>
          </div>
          {userDetailFetching ? (
            <div className='relative mt-40'>
              <BaseLoader />
            </div>
          ) : (
            <div className='flex flex-col gap-4 md:gap-3 xxl:gap-4'>
              <h2 className='text-left text-xl font-semibold leading-[1.5125rem] md:text-2xl md:leading-[1.8156rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-xl xxl:leading-6'>
                Organizations
              </h2>
              <Tabs defaultValue={currentTab}>
                <div className='mb-4 flex flex-col gap-3 md:gap-[1.1875rem] lg:mb-3 lg:flex-row lg:items-center lg:justify-between xxl:mb-4'>
                  <TabsList className='w-available h-12 max-w-[24.375rem] md:h-[2.625rem] md:max-w-[20.5rem] lg:h-8 lg:max-w-[15.5rem] xxl:h-[2.625rem] xxl:max-w-[20.625rem]'>
                    <Link to={ROUTES.MY_ORGANIZATIONS} className='w-full'>
                      <TabsTrigger
                        value={HOME_ORGANIZATION_TABS.MY_ORGANIZATION}
                        className='h-10 text-xs xs:text-sm md:h-[2.125rem] md:w-40 lg:h-6 lg:w-[7.5rem] lg:text-[0.625rem] xxl:h-8 xxl:w-40 xxl:text-sm'
                      >
                        My Organizations
                      </TabsTrigger>
                    </Link>
                    <Link to={ROUTES.INVITED_ORGANIZATIONS} className='w-full'>
                      <TabsTrigger
                        value={HOME_ORGANIZATION_TABS.INVITED_ORGANIZATION}
                        className='h-10 text-xs xs:text-sm md:h-[2.125rem] md:w-40 lg:h-6 lg:w-[7.5rem] lg:text-[0.625rem] xxl:h-8 xxl:w-40 xxl:text-sm'
                      >
                        Invited Organizations
                      </TabsTrigger>
                    </Link>
                  </TabsList>
                  <div className='relative flex flex-col gap-3 lg:flex-row'>
                    <Command
                      onChange={handleSearchChange}
                      className='h-10 rounded-md border border-lightGray lg:h-8 lg:w-[13.75rem] lg:rounded-sm xxl:h-10 xxl:w-[18.75rem] xxl:rounded-md'
                    >
                      <CommandInput placeholder='Search' className='h-10 md:h-10 lg:h-8 xxl:h-10' />
                    </Command>
                    {currentTab === HOME_ORGANIZATION_TABS.MY_ORGANIZATION && (
                      <Button
                        id='create-home-org'
                        onClick={() => {
                          formik.resetForm();
                          setUploadedLogo(null);
                          setIsUploadedLogo(false);
                          setShowAddEditOrgDialog(true);
                        }}
                        className='h-10 items-center gap-2 bg-limeGreen md:absolute md:-top-[3.6875rem] md:right-0 md:w-[11.5625rem] md:gap-1.5 lg:static lg:w-[9.4375rem] lg:gap-1 lg:px-2 xxl:w-[12.5625rem] xxl:gap-2.5 xxl:px-4'
                      >
                        <Plus className='h-6 w-6 md:h-5 md:w-5 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' color='#FFF' />
                        <span className='mt-0.5 text-sm font-normal leading-[1.0588rem] text-white lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6'>
                          Create Organization
                        </span>
                      </Button>
                    )}
                    <Dialog open={showAddEditOrgDialog} onOpenChange={setShowAddEditOrgDialog}>
                      <AddEditOrgDialog
                        dialogTitle='Create Organization'
                        formik={formik}
                        imageRef={imageRef}
                        uploadedLogo={uploadedLogo}
                        handleImageUpload={handleImageUpload}
                        setUploadedLogo={setUploadedLogo}
                        setIsUploadedLogo={setIsUploadedLogo}
                        icon={
                          <div className='h-3.5 w-3.5 lg:h-3 lg:w-3 xxl:h-3.5 xxl:w-3.5'>
                            <PlusIconWhite />
                          </div>
                        }
                        isUploadedLogo={isUploadedLogo}
                        organizationLoading={createOrganizationLoading}
                      />
                    </Dialog>
                  </div>
                </div>
                <TabsContent value={HOME_ORGANIZATION_TABS.MY_ORGANIZATION}>
                  <Outlet
                    context={[
                      organizations,
                      page,
                      setPage,
                      organizationFetching,
                      search,
                      editFormik,
                      imageRef,
                      uploadedLogoEdit,
                      setUploadedLogoEdit,
                      setIsUploadedLogoEdit,
                      isUploadedLogoEdit,
                      isEditOrganizationLoading,
                      isDeleteOrgLoading,
                      handleDeleteOrganizationBtn,
                      handleDeleteBtn,
                      showAddEditDialog,
                      showDeleteOrgDialog,
                      setShowDeleteOrgDialog,
                      setShowAddEditDialog,
                      selectedOrganization,
                      setSelectedOrganization,
                      userDetail,
                      manageOrganizationFetching,
                      editHandleImageUpload,
                      openEditDialogue,
                    ]}
                  />
                </TabsContent>
                <TabsContent value={HOME_ORGANIZATION_TABS.INVITED_ORGANIZATION}>
                  <Outlet
                    context={[
                      organizations,
                      page,
                      setPage,
                      organizationFetching,
                      search,
                      editFormik,
                      imageRef,
                      uploadedLogoEdit,
                      setUploadedLogoEdit,
                      setIsUploadedLogoEdit,
                      isUploadedLogoEdit,
                      isEditOrganizationLoading,
                      selectedOrganization,
                      setSelectedOrganization,
                      showAddEditDialog,
                      setShowAddEditDialog,
                      showDeleteOrgDialog,
                      setShowDeleteOrgDialog,
                      handleDeleteBtn,
                      handleDeleteOrganizationBtn,
                      isDeleteOrgLoading,
                      userDetail,
                      manageOrganizationFetching,
                      editHandleImageUpload,
                      openEditDialogue,
                    ]}
                  />
                </TabsContent>
              </Tabs>
            </div>
          )}
        </div>
        <div className='fixed bottom-0 right-0 -z-10 hidden lg:block'>
          <HomeBottomIcon className='h-[14.5rem] w-[13.4375rem] xxl:h-[20.375rem] xxl:w-[18.75rem]' />
        </div>
        {currentTab === HOME_ORGANIZATION_TABS.MY_ORGANIZATION && (
          <TourButton Label='Create Organization' onClick={() => createHomeOrgTour().drive()} />
        )}
      </div>
    </div>
  );
}
