import { useRef } from 'react';
import { DialogContent, Button, DialogHeader, DialogTitle } from '@shadcnComponent';
import { FormInput, FormSelect, Loader } from '@common/components';
import { doInfiniteScroll } from '@utils';

export function SaveTemplateDialog({
  isLoading,
  templateCategory,
  templateCategoryFetching,
  templateCategoryNextPage,
  templateCategoryHasNextPage,
  formik,
}) {
  const categoryRef = useRef();

  const { values, handleChange, setFieldValue, submitForm, touched, errors } = formik;

  const handleScroll = e =>
    doInfiniteScroll(e, templateCategoryHasNextPage, templateCategoryFetching, templateCategoryNextPage);

  return (
    <DialogContent className='w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:max-w-[28.125rem] md:px-8 md:py-[1.875rem]'>
      <DialogHeader>
        <DialogTitle className='mb-8 text-start text-lg font-bold leading-[1.375rem] md:text-2xl md:leading-7'>
          Save as Template
        </DialogTitle>
      </DialogHeader>
      <div className='flex flex-col gap-6'>
        <FormInput
          id='templateName'
          labelText='Template Name'
          placeholder='Enter Template Name'
          value={values?.templateName}
          handleChange={handleChange}
          labelClassName='text-sm md:text-sm xxl:text-base leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-base'
          inputClassName='h-10 md:h-10'
          className='gap-1'
          showError={touched.templateName && errors.templateName}
        />
        <FormSelect
          id='category'
          labelText='Category'
          placeholder='Select category'
          selectRef={categoryRef}
          options={templateCategory?.pages?.flatMap(page => page?.data?.results)}
          handleChange={value => setFieldValue('category', value?.id)}
          triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values.category ? '' : 'custom-select'}`}
          handleScroll={handleScroll}
          selectGroupClassName='h-[18.75rem] overflow-y-auto'
          showError={touched.category && errors.category}
        />
        <Button
          onClick={submitForm}
          className='h-10 w-full lg:h-8 lg:text-[.625rem] lg:leading-3 xxl:mt-2 xxl:h-10'
          disabled={isLoading}
        >
          Confirm
          {isLoading ? <Loader /> : ''}
        </Button>
      </div>
    </DialogContent>
  );
}
