import { Button, Calendar, Popover, PopoverContent, PopoverTrigger, Label } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { format, isToday } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import moment from 'moment';
import { FormInput, FormSelect, Loader } from '@common/components';
import { TextEditor, CustomTimePicker } from '@pages/reports';
import { calculateMonthlyOptions, convertToDayNumber, restrictedCharacterOnNumberField } from '@utils';
import { COLORS, DATE_FORMAT, DAY_VALUE, MONTHLY, REPORT_TIME_PERIOD } from '@constants';
import { CrossIcon } from '@assets/svgs';

export function CreateEditReport({
  label,
  formik,
  handleInvitedEmailKeyDown,
  invitedEmails,
  handleRemoveInvitedEmail,
  emailFieldErrorMessage,
  invalidEmails,
  handleSelectedDay,
  scheduleLoading,
  scheduleClass,
  isReadOnly,
}) {
  const { values, handleChange, setFieldValue, touched, errors, submitForm } = formik;

  return (
    <div>
      <div className='border-b border-lightGray pb-6 md:pb-4 xxl:pb-6'>
        {!!label && (
          <h2 className='mb-4 text-xl font-bold leading-[1.5125rem] md:mb-[1.5625rem] md:text-2xl md:leading-[1.8156rem] lg:mb-5 lg:text-base lg:leading-[1.21rem] xxl:mb-[1.5625rem] xxl:text-2xl xxl:leading-[1.8156rem]'>
            {isReadOnly ? 'Report' : label}
          </h2>
        )}
        <div className='flex flex-col gap-4 lg:gap-3 xxl:gap-4'>
          <FormInput
            id='email'
            labelText='Add Recipients'
            placeholder='Add'
            value={values.email}
            handleChange={handleChange}
            showError={emailFieldErrorMessage}
            onKeyDown={handleInvitedEmailKeyDown}
            isMultipleEmail={true}
            isMultipleEmailMessage='*Press enter after adding an recipient'
            className='gap-1.5'
            labelClassName='text-sm leading-[1.0625rem]'
            inputClassName='h-10 md:h-10 lg:h-8 xxl:h-10 bg-transparent'
            disabled={isReadOnly}
          />
          {!!invitedEmails?.length > 0 && (
            <div className={`flex flex-wrap gap-2 ${isReadOnly ? 'pointer-events-none' : ''}`}>
              {invitedEmails.map(email => (
                <span
                  key={email}
                  className={`flex items-center gap-2 break-all rounded-lg bg-tableRowHover px-2.5 py-1 text-sm font-normal leading-4 text-colorPrimary ${invalidEmails.includes(email) ? 'border border-errorColor text-errorColor' : ''}`}
                >
                  {email}
                  <span onClick={() => handleRemoveInvitedEmail(email)} className='cursor-pointer'>
                    <CrossIcon
                      strokeColor={invalidEmails.includes(email) ? COLORS.ERROR_COLOR : COLORS.DARK_SECONDARY}
                    />
                  </span>
                </span>
              ))}
            </div>
          )}
          <FormInput
            labelText='Subject'
            placeholder='Enter subject'
            id='subject'
            value={values.subject}
            handleChange={handleChange}
            showError={touched.subject && errors.subject}
            className='gap-1.5'
            labelClassName='text-sm leading-[1.0625rem]'
            inputClassName='h-10 md:h-10 lg:h-8 xxl:h-10 bg-transparent'
            disabled={isReadOnly}
          />
          <TextEditor formik={formik} isReadOnly={isReadOnly} />
          <div className='flex flex-col gap-4 md:flex-row md:gap-3 xxl:gap-6'>
            <div
              className={`flex w-full flex-col gap-1.5 md:w-[15.5rem] lg:w-[17.5rem] xxl:w-[15.5rem] 
              ${isReadOnly ? 'pointer-events-none' : ''}
              `}
            >
              <Label className='text-sm font-medium leading-5 xxl:text-sm xxl:leading-5'>Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <div>
                    <Button
                      variant={'outline'}
                      className={cn(
                        'h-10 w-full justify-between border-lightGray px-3 py-2 font-normal lg:px-2 xxl:h-10 xxl:px-3',
                        !values.date && 'text-muted-foreground'
                      )}
                      disabled={isReadOnly}
                    >
                      {values.date ? format(values.date, DATE_FORMAT.DATE_PICKER) : <span>Pick a date</span>}
                      <CalendarIcon className='h-5 w-5' />
                    </Button>
                    {touched.date && errors.date && (
                      <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>
                        {touched.date && errors.date}
                      </p>
                    )}
                  </div>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0'>
                  <Calendar
                    mode='single'
                    selected={values.date}
                    onSelect={value => setFieldValue('date', value)}
                    disabled={date => date < new Date() && !isToday(date)}
                    initialFocus
                    today={false}
                  />
                </PopoverContent>
              </Popover>
            </div>
            <CustomTimePicker
              scheduleClass={scheduleClass}
              value={values.time}
              handleChange={value => setFieldValue('time', value)}
              showError={touched.time && errors.time}
              disabled={isReadOnly}
            />
          </div>
          <div className='mt-2'>
            <h3 className='mb-3 text-base font-semibold leading-5 md:mb-4 md:text-sm md:leading-[1.0588rem] xxl:mb-3 xxl:text-base xxl:leading-5'>
              Schedule Report <span className='font-normal opacity-50'>(Optional)</span>
            </h3>
            <Label className='text-sm font-medium leading-5 xxl:text-sm xxl:leading-5'>Repeat Every</Label>
            <div className='mt-1.5 flex gap-4 lg:gap-2 xxl:mt-1.5 xxl:gap-4'>
              <FormInput
                type='number'
                placeholder='number'
                id='frequency'
                value={values.frequency}
                handleChange={handleChange}
                onKeyDown={restrictedCharacterOnNumberField}
                showError={touched.frequency && errors.frequency}
                inputClassName='w-[6.9375rem] h-10 lg:w-[3.75rem] lg:h-8 xxl:h-10 xxl:w-[6.9375rem] bg-transparent'
                disabled={isReadOnly}
              />
              <FormSelect
                id='type'
                placeholder='Schedule Type'
                value={values.type}
                handleChange={value => setFieldValue('type', value)}
                options={REPORT_TIME_PERIOD}
                showError={touched.type && errors.type}
                className='w-full flex-[unset] gap-0'
                triggerClassName={`w-full flex-1 h-10 lg:h-8 xxl:h-10 lg:w-[11.25rem] md:w-[11.5rem] xxl:w-[11.5rem] bg-transparent lg:px-2 ${values?.type && values?.type.id !== '' ? '' : 'custom-select'}`}
                disabled={isReadOnly}
              />
            </div>
          </div>
          {values?.type?.id !== MONTHLY && (
            <div className='lg:mt-2.5 xxl:mt-0'>
              <Label className='text-sm font-medium leading-5 xxl:text-sm xxl:leading-5'>Select day</Label>
              <div
                className={`mt-[0.6875rem] flex gap-4 lg:mt-1.5 lg:gap-2 xxl:mt-[0.6875rem] xxl:gap-4 ${isReadOnly ? 'pointer-events-none' : ''}`}
              >
                {DAY_VALUE.map(({ value, label }) => (
                  <div
                    key={value}
                    onClick={() => handleSelectedDay(value)}
                    className={`border-paleGray flex h-[2.2906rem] w-[2.2906rem] items-center justify-center rounded-full border text-base font-semibold leading-[1.3619rem] lg:h-[1.875rem] lg:w-[1.875rem] lg:text-sm lg:leading-[1.0588rem] xxl:h-[2.2906rem] xxl:w-[2.2906rem] xxl:text-base xxl:leading-[1.3619rem] ${values?.dayChecked?.includes(value) ? 'bg-slateBlue text-white' : ''}`}
                  >
                    {label}
                  </div>
                ))}
              </div>
              {touched.dayChecked && errors.dayChecked && (
                <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>
                  {touched.dayChecked && errors.dayChecked}
                </p>
              )}
            </div>
          )}
          {values?.type?.id === MONTHLY && (
            <FormSelect
              id='monthly'
              placeholder={
                values.scheduleMonthly.id
                  ? values.scheduleMonthly.name
                  : values.scheduledLabel
                    ? values.scheduledLabel
                    : 'Select monthly'
              }
              value={values.monthly}
              handleChange={value => {
                setFieldValue('dayChecked', convertToDayNumber(moment().format('dddd')));
                setFieldValue('scheduleMonthly', value);
              }}
              options={calculateMonthlyOptions(values?.date)}
              showError={touched.scheduleMonthly && errors.scheduleMonthly}
              triggerClassName={`w-full h-10 lg:h-8 xxl:h-10 md:w-[15.5rem] xxl:w-[19.375rem] bg-transparent lg:px-2 ${values?.scheduleMonthly || values.scheduledLabel ? '' : 'custom-select'}`}
              disabled={isReadOnly}
            />
          )}
        </div>
      </div>
      {!isReadOnly && (
        <div className='mt-6 flex justify-end lg:mt-4 xxl:mt-6'>
          <Button
            className='inline-flex w-full items-center md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'
            disabled={scheduleLoading}
            onClick={submitForm}
          >
            {label === 'Edit Report' ? 'Reschedule' : 'Schedule'} {scheduleLoading && <Loader />}
          </Button>
        </div>
      )}
    </div>
  );
}
