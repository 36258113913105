import { Avatar, AvatarImage, Popover, PopoverTrigger, PopoverContent } from '@shadcnComponent';
import { BaseToolTip } from '@common/components';
import { truncateString } from '@utils';
import TeamImage from '@assets/images/Team.png';
import { PersonIcon } from '@assets/svgs';

export function TeamPopover({ dashboard }) {
  return (
    <div className='flex'>
      <Popover>
        {dashboard?.teams?.slice(0, 3).map(team => (
          <PopoverTrigger key={team?.id} onClick={event => event.stopPropagation()}>
            <Avatar className='-mr-1.5 flex h-5 w-5 items-center justify-center md:-mr-2 md:h-7 md:w-7 lg:-mr-1.5 lg:h-5 lg:w-5 xxl:-mr-2 xxl:h-7 xxl:w-7'>
              <AvatarImage
                src={TeamImage}
                className='h-5 min-w-5 md:h-7 md:min-w-7 lg:h-5 lg:min-w-5 xxl:h-7 xxl:min-w-7'
              />
            </Avatar>
          </PopoverTrigger>
        ))}
        <PopoverContent
          onClick={e => e.stopPropagation()}
          align='start'
          className='max-h-[9.75rem] w-[12.75rem] overflow-y-auto px-[0.9375rem] py-3'
        >
          <ul className='flex flex-col gap-3'>
            {dashboard?.teams?.map(team => (
              <li
                onClick={e => e.stopPropagation()}
                key={team?.id}
                className='flex items-center gap-2 text-sm font-normal leading-5'
              >
                <div className='flex h-6 w-6 items-center justify-center rounded-full bg-slateBlue'>
                  <PersonIcon />
                </div>
                <span className='max-w-[8.875rem] overflow-hidden whitespace-nowrap' id={`teamMemberPopup-${team.id}`}>
                  {truncateString(team?.name, 15, 'text-darkSecondary')}
                </span>
                {team?.name?.length > 15 && <BaseToolTip id={`teamMemberPopup-${team.id}`} content={team?.name} />}
              </li>
            ))}
          </ul>
        </PopoverContent>
      </Popover>
      {dashboard?.teams?.length === 0 && 'N/A'}
      <Popover>
        {dashboard?.teamCount > 3 && (
          <PopoverTrigger onClick={event => event.stopPropagation()}>
            <Avatar className='-mr-3 h-5 w-5 md:h-7 md:w-7 lg:h-5 lg:w-5 xxl:h-7 xxl:w-7'>
              <span className='flex w-full items-center justify-center bg-darkPrimary text-[.4375rem] font-semibold leading-[.5rem] text-white'>
                +{dashboard?.teamCount - 3}
              </span>
            </Avatar>
          </PopoverTrigger>
        )}
        {dashboard?.teamCount > 0 && (
          <PopoverContent
            onClick={e => e.stopPropagation()}
            align='start'
            className='max-h-[9.75rem] w-[12.75rem] overflow-y-auto px-[0.9375rem] py-3'
          >
            <ul className='flex flex-col gap-3'>
              {dashboard?.teams?.map(team => (
                <li
                  onClick={e => e.stopPropagation()}
                  key={team?.id}
                  className='flex items-center gap-2 text-sm font-normal leading-5'
                >
                  <div className='flex h-6 w-6 items-center justify-center rounded-full bg-slateBlue'>
                    <PersonIcon />
                  </div>
                  <span
                    className='max-w-[8.875rem] overflow-hidden whitespace-nowrap'
                    id={`teamMemberPopup-${team.id}`}
                  >
                    {truncateString(team?.name, 15, 'text-darkSecondary')}
                  </span>
                  {team?.name?.length > 15 && <BaseToolTip id={`teamMemberPopup-${team.id}`} content={team?.name} />}
                </li>
              ))}
            </ul>
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
}
