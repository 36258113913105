import { Card, CardContent, Dialog } from '@shadcnComponent';
import { BaseToolTip, DeleteDialog, GoalTrackingProgress } from '@common/components';
import { CreateEditGoalDialog, GoalActionPopover } from '@pages/goalTracking';
import { truncateString } from '@utils';

export function GoalTrackGridView({
  goalsListing,
  goalDetail,
  setGoalDetail,
  setEditGoalDetail,
  handleGoalDelete,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteGoalLoading,
  setDeleteGoalLoading,
  showEditDialog,
  setShowEditDialog,
  widgetListing,
  formik,
  dashboardsListing,
  isLoading,
  setSelectedDashboardId,
  setDashboardGoal,
}) {
  return (
    <div className='grid grid-cols-17 gap-4 md:gap-3 lg:grid-cols-19 lg:gap-x-[1.875rem] lg:gap-y-4 xxl:grid-cols-21 xxl:gap-7'>
      {goalsListing?.data?.results.map(goal => (
        <Card key={goal.id} className='w-full'>
          <CardContent className='p-5 lg:pb-[1.125rem] lg:pl-4 lg:pr-5 lg:pt-4 xxl:px-[1.875rem] xxl:py-6'>
            <div className='mb-4 flex items-center justify-between lg:mb-[1.125rem] xxl:mb-[1.625rem]'>
              <h3
                className='text-xl font-semibold leading-6 lg:text-base lg:leading-[1.1875rem] xxl:text-2xl xxl:leading-[1.8125rem]'
                id={`name-${goal?.id}`}
              >
                {goal?.name?.length > 20 && <BaseToolTip id={`name-${goal?.id}`} content={goal?.name} />}
                {truncateString(goal.name, 20)}
              </h3>
              <GoalActionPopover
                goal={goal}
                setShowDeleteDialog={setShowDeleteDialog}
                showDeleteDialog={showDeleteDialog}
                setGoalDetail={setGoalDetail}
                showEditDialog={showEditDialog}
                setShowEditDialog={setShowEditDialog}
                setEditGoalDetail={setEditGoalDetail}
                setDashboardGoal={setDashboardGoal}
              />
            </div>
            <div className='flex flex-col gap-4 border-b border-lightGray pb-5 lg:gap-5 xxl:gap-4'>
              <div className='flex items-center justify-between'>
                <span className='text-base font-normal leading-[1.1875rem] text-davysGray lg:text-xs lg:leading-[0.9375rem] xxl:text-base xxl:leading-[1.1875rem]'>
                  Dashboard
                </span>
                <h5 className='text-base font-normal leading-[1.1875rem] lg:text-xs lg:leading-[0.9375rem] xxl:text-base xxl:leading-[1.1875rem]'>
                  {goal.dashboardName}
                </h5>
              </div>
              <div className='flex items-center justify-between'>
                <span className='text-base font-normal leading-[1.1875rem] text-davysGray lg:text-xs lg:leading-[0.9375rem] xxl:text-base xxl:leading-[1.1875rem]'>
                  Widget
                </span>
                <h5
                  id={`label-${goal?.id}`}
                  className='text-base font-normal leading-[1.1875rem] lg:text-xs lg:leading-[0.9375rem] xxl:text-base xxl:leading-[1.1875rem]'
                >
                  {goal?.widgetLabel?.length > 20 && (
                    <BaseToolTip id={`label-${goal?.id}`} content={goal?.widgetLabel} />
                  )}
                  {truncateString(goal.widgetLabel, 20)}
                </h5>
              </div>
              <div className='flex items-center justify-between'>
                <span className='text-base font-normal leading-[1.1875rem] text-davysGray lg:text-xs lg:leading-[0.9375rem] xxl:text-base xxl:leading-[1.1875rem]'>
                  Time period
                </span>
                <h5 className='text-base font-normal leading-[1.1875rem] lg:text-xs lg:leading-[0.9375rem] xxl:text-base xxl:leading-[1.1875rem]'>
                  {goal.periodInDays}
                </h5>
              </div>
            </div>
            <GoalTrackingProgress goal={goal} className='mt-5 !w-full lg:mt-3' />
          </CardContent>
        </Card>
      ))}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DeleteDialog
          heading={goalDetail?.name}
          text={goalDetail?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleGoalDelete();
            setDeleteGoalLoading(true);
          }}
          isLoading={deleteGoalLoading}
        />
      </Dialog>
      <Dialog
        open={showEditDialog}
        onOpenChange={status => {
          setShowEditDialog(status);
          !status && setGoalDetail({});
        }}
      >
        <CreateEditGoalDialog
          title='Edit Goal'
          formik={formik}
          widgetListing={widgetListing}
          dashboardsListing={dashboardsListing}
          isLoading={isLoading}
          setSelectedDashboardId={setSelectedDashboardId}
        />
      </Dialog>
    </div>
  );
}
