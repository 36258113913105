import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, uniq } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import {
  EMAIL_REGEX,
  ENTER,
  ENTER_KEY,
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  PRESS_ENTER_MESSAGE,
  ROLES,
  SUCCESSFUL_MESSAGE,
} from '@constants';

export function useMembersTab() {
  const { organizationId } = useParams();

  const [showInviteMemberDialog, setShowInviteMemberDialog] = useState(false);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');
  const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState({});
  const [deleteMemberLoading, setDeleteMemberLoading] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [page, setPage] = useState(1);
  const [invalidEmails, setInvalidEmails] = useState([]);

  const {
    data: organizationMembers,
    refetch: organizationMembersRefetch,
    isFetching: organizationMembersFetching,
  } = useGetQuery('organization-members-listing', apiEndpoints.ORGANIZATIONS_MEMBERS(organizationId), { page });

  const { data: teamListing } = useGetQuery('team-listing', apiEndpoints.TEAMS_LISTING(organizationId));

  const inviteMembersValues = {
    role: '',
    team: '',
    email: '',
  };
  const inviteMembersValidationSchema = Yup.object({
    role: Yup.object().required(FIELD_REQUIRED('Role')),
    team: Yup.object().when('role', {
      is: value => value && value?.id === ROLES.ADMIN,
      then: rule => rule.notRequired(),
      otherwise: rule => rule.required(FIELD_REQUIRED('Team')),
    }),
  });

  const handleSubmit = () => {
    if (isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    } else if (!isEmpty(values.email)) {
      setEmailFieldErrorMessage(PRESS_ENTER_MESSAGE);
    } else {
      let payload = {
        organization: organizationId,
        role: values.role?.id,
        team: values.team?.id,
        emails: invitedEmails,
      };

      values.role === ROLES.ADMIN && delete payload['team'];

      handleInviteMember({ payload });
    }
  };

  const { ...formik } = useFormikForm(inviteMembersValues, handleSubmit, inviteMembersValidationSchema);

  const { values, errors, setFieldValue, isSubmitting, resetForm } = formik;

  const handleInvitedEmailKeyDown = e => {
    if (e.code === ENTER || e.keyCode === ENTER_KEY) {
      const match = values.email.match(EMAIL_REGEX);

      if (match) {
        setInvitedEmails(uniq([...invitedEmails, values.email.toLowerCase()]));
        setFieldValue('email', '');
        setEmailFieldErrorMessage('');
      }
    }
  };

  const handleRemoveInvitedEmail = selectedEmail =>
    setInvitedEmails(invitedEmails.filter(email => email !== selectedEmail));

  const { mutate: handleInviteMember, isLoading: inviteMemberLoading } = usePostMutation(
    apiEndpoints.SEND_INVITATION,
    () => {
      setShowInviteMemberDialog(false);
      toast.success(SUCCESSFUL_MESSAGE('Invitation sent'));
    },
    ({ response: { data } }) => {
      data?.detail
        ? (toast.error(data?.detail), data?.emails && setInvalidEmails(data?.emails))
        : data?.map(item => item?.non_field_errors?.map(errMsg => toast.error(errMsg)));
    }
  );

  const { mutate: handleOrganizationMemberDelete } = useDeleteMutation(
    apiEndpoints.DELETE_ORGANIZATION_MEMBER(organizationId, memberId),
    () => {
      organizationMembersRefetch();
      setMemberId(null);
      setShowDeleteMemberDialog(false);
      toast.success(SUCCESSFUL_MESSAGE('Member deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
      setDeleteMemberLoading(false);
    }
  );

  const openInviteMemberDialogue = () => {
    setShowInviteMemberDialog(true);
    resetForm();
    setInvalidEmails([]);
    setInvitedEmails([]);
  };

  useEffect(() => {
    if (isSubmitting && !isEmpty(errors?.email)) {
      isEmpty(values.email) && setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    }

    if (values.email) {
      const match = values.email.match(EMAIL_REGEX);
      match ? setEmailFieldErrorMessage('') : setEmailFieldErrorMessage(INVALID_EMAIL_ADDRESS);
    } else if (!isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage('');
    }
  }, [isSubmitting, errors, values.email]);

  useEffect(() => {
    if (memberId) {
      setDeleteMemberLoading(true);
      handleOrganizationMemberDelete();
    }
  }, [memberId]);

  useEffect(() => {
    page && organizationMembersRefetch();
  }, [page]);

  return {
    organizationMembers,
    teamListing,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    showInviteMemberDialog,
    setShowInviteMemberDialog,
    emailFieldErrorMessage,
    showDeleteMemberDialog,
    setShowDeleteMemberDialog,
    setMemberId,
    page,
    setPage,
    openInviteMemberDialogue,
    inviteMemberLoading,
    deleteMemberLoading,
    setDeleteMemberLoading,
    invalidEmails,
    organizationMembersFetching,
  };
}
