/* eslint-disable indent */
import {
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { MultiSelect } from '@common/components';
import { capitalizeString, transformedIntoSelectOptions, transformedIntoSelectTableOptions } from '@utils';
import { LIMIT_DROP_DOWN, OPERATIONS_TYPE, WIDGETS_TYPE } from '@constants';

export function DataTab({ editWidget, setEditWidget, widgetDimension }) {
  return (
    <div className='flex flex-col gap-6'>
      {/* {![WIDGETS_TYPE.TABLE, WIDGETS_TYPE.COUNTER, WIDGETS_TYPE.MAP].includes(editWidget?.id?.split('-')[0]) &&
        transformedIntoSelectOptions(widgetDimension?.data?.dimensions).length > 0 && ( */}
      <div className='flex flex-col gap-1.5'>
        <Label>Dimension</Label>
        <Select
          value={editWidget?.dimensions}
          onValueChange={value => setEditWidget({ ...editWidget, dimensions: value })}
          disabled={!transformedIntoSelectOptions(widgetDimension?.data?.dimensions).length > 0}
        >
          <SelectTrigger className='bg-transparent'>
            <SelectValue placeholder='Select dimensions' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel className='font-light text-slateGray'>Select dimensions</SelectLabel>
              {transformedIntoSelectOptions(widgetDimension?.data?.dimensions)?.map((option, index) => (
                <SelectItem key={index} value={option?.value} className='px-3 py-[0.4375rem]'>
                  {capitalizeString(option?.label.replace('_', ' '))}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {/* )} */}
      {transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions) && (
        <div className='flex flex-col gap-1.5'>
          <Label>Metrics</Label>
          {editWidget?.id?.split('-')[0] === WIDGETS_TYPE.TABLE ? (
            <MultiSelect
              editWidget={editWidget}
              setEditWidget={setEditWidget}
              disabled={
                !transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions)
                  .length > 0
              }
              options={transformedIntoSelectTableOptions(
                widgetDimension?.data?.metrics,
                widgetDimension?.data?.dimensions
              )}
            />
          ) : (
            <Select
              value={editWidget?.metrics}
              onValueChange={value => setEditWidget({ ...editWidget, metrics: value })}
              disabled={!transformedIntoSelectOptions(widgetDimension?.data?.metrics).length > 0}
            >
              <SelectTrigger className='bg-transparent'>
                <SelectValue placeholder='Select metrics' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel className='font-light text-slateGray'>Select metrics</SelectLabel>
                  {transformedIntoSelectOptions(widgetDimension?.data?.metrics)?.map((option, index) => (
                    <SelectItem key={index} value={option?.value} className='px-3 py-[0.4375rem]'>
                      {capitalizeString(option?.label.replace('_', ' '))}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        </div>
      )}
      {/* {!isEmpty(editWidget?.metrics) && editWidget?.id?.split('-')[0] !== WIDGETS_TYPE.TABLE && ( */}
      <div className='flex flex-col gap-1.5'>
        <Label>Operations</Label>
        <Select
          value={editWidget?.operationType}
          onValueChange={value => setEditWidget({ ...editWidget, operationType: value })}
          disabled={!isEmpty(editWidget?.metrics) && editWidget?.id?.split('-')[0] === WIDGETS_TYPE.TABLE}
        >
          <SelectTrigger className='bg-transparent'>
            <SelectValue placeholder='Select operation' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel className='font-light text-slateGray'>Select operation</SelectLabel>
              {OPERATIONS_TYPE?.map((option, index) => (
                <SelectItem key={index} value={option?.value} className='px-3 py-[0.4375rem]'>
                  {option?.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {/* )} */}
      {/* {editWidget?.id?.split('-')[0] !== WIDGETS_TYPE.COUNTER && ( */}
      <div className='flex flex-col gap-1.5'>
        <Label>Limits</Label>
        <Select
          value={editWidget?.limit}
          onValueChange={value => setEditWidget({ ...editWidget, limit: value, page: 1 })}
          disabled={editWidget?.id?.split('-')[0] === WIDGETS_TYPE.COUNTER}
        >
          <SelectTrigger className='bg-transparent'>
            <SelectValue placeholder='Select limit' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel className='font-light text-slateGray'>Select limit</SelectLabel>
              {LIMIT_DROP_DOWN?.map((option, index) => (
                <SelectItem key={index} value={option?.value} className='px-3 py-[0.4375rem]'>
                  {option?.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {/* )} */}
      {/* {editWidget?.id?.split('-')[0] === WIDGETS_TYPE.TABLE &&
        transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions).length >
          0 && ( */}
      <div className='flex flex-col gap-1.5'>
        <Label>Sort metrics</Label>
        <Select
          value={editWidget?.sortMetrics}
          onValueChange={value => setEditWidget({ ...editWidget, sortMetrics: value })}
          disabled={
            editWidget?.id?.split('-')[0] !== WIDGETS_TYPE.TABLE ||
            !transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions)
              .length > 0
          }
        >
          <SelectTrigger className='bg-transparent'>
            <SelectValue placeholder='Sort metrics' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel className='font-light text-slateGray'>Sort metrics</SelectLabel>
              {transformedIntoSelectTableOptions(
                widgetDimension?.data?.metrics,
                widgetDimension?.data?.dimensions
              )?.map((option, index) => (
                <SelectItem key={index} value={option?.value} className='px-3 py-[0.4375rem]'>
                  {option?.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {/* )} */}
      {/* {![WIDGETS_TYPE.COUNTER, WIDGETS_TYPE.MAP].includes(editWidget?.id?.split('-')[0]) && ( */}
      <div className='flex flex-col gap-1.5'>
        <Label>Sort by</Label>
        <Select
          value={editWidget?.sort}
          disabled={[WIDGETS_TYPE.COUNTER, WIDGETS_TYPE.MAP].includes(editWidget?.id?.split('-')[0])}
          onValueChange={value => setEditWidget({ ...editWidget, sort: value })}
        >
          <SelectTrigger className='bg-transparent'>
            <SelectValue placeholder='Sort by' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel className='font-light text-slateGray'>Sort by</SelectLabel>
              <SelectItem value='ASC' className='px-3 py-[0.4375rem]'>
                Asc
              </SelectItem>
              <SelectItem value='DESC' className='px-3 py-[0.4375rem]'>
                Desc
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {/* )} */}
    </div>
  );
}
