import { useRef } from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@shadcnComponent';
import { BaseLoader } from './BaseLoader';
import { useNotification } from '@common/hooks';
import { calculateDueDate, doInfiniteScroll } from '@utils';
import { NO_NOTIFICATION_MESSAGE } from '@constants';
import { NotificationIcon } from '@assets/svgs';

export function NotificationDropdown({ strokeColor, userDetail, userDetailRefetch }) {
  const {
    errorMessage,
    notificationListing,
    notificationListingFetching,
    notificationListingNextPage,
    notificationListingHasNextPage,
    notificationListingRefetch,
    notificationListingLoading,
  } = useNotification();

  const scrollContainerRef = useRef();

  const handleScroll = e =>
    doInfiniteScroll(e, notificationListingHasNextPage, notificationListingFetching, notificationListingNextPage);

  return (
    <DropdownMenu
      className='relative'
      onOpenChange={status => {
        if (status) {
          notificationListingRefetch();
          userDetailRefetch();
        }
      }}
    >
      <DropdownMenuTrigger className='hidden md:block'>
        <div className='relative'>
          <NotificationIcon className='md:h-9 md:w-9 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' strokeColor={strokeColor} />
          {userDetail?.data?.hasUnreadNotifications && (
            <span className='absolute right-[3px] top-[1px] h-2 w-2 rounded-[50%] bg-darkPrimary'></span>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className='absolute -right-3 top-[0.1875rem] hidden h-[37.3125rem] w-[29.625rem] overflow-y-auto p-0 text-darkSecondary scrollbar-none md:block lg:h-[25.6875rem] lg:w-[23.4375rem] xxl:h-[37.3125rem] xxl:w-[29.625rem]'
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        {notificationListingLoading ? (
          <BaseLoader />
        ) : (
          <>
            {errorMessage && <p className='p-4 text-center text-[1rem] font-normal leading-5'>{errorMessage}</p>}
            {notificationListing?.pages?.map(page =>
              page?.data?.count === 0 ? (
                <p key={page} className='p-4 text-center text-[1rem] font-normal leading-5'>
                  {NO_NOTIFICATION_MESSAGE}
                </p>
              ) : (
                page?.data?.results?.map(notification => (
                  <DropdownMenuItem
                    key={notification.id}
                    className={`items-start gap-3 border-b border-lightGray px-4 py-3.5 lg:px-4 lg:py-1.5 xxl:gap-3 xxl:px-4 xxl:py-3.5 ${notification?.isRead ? '' : 'bg-softSilver'}`}
                  >
                    <img
                      src={notification?.senderImage?.image}
                      alt='sender-avatar'
                      className='h-10 w-10 rounded-full lg:h-[2.0625rem] lg:w-[2.0625rem] xxl:h-10 xxl:w-10'
                    />
                    <div>
                      <p className='text-xs font-normal leading-5 lg:text-[0.625rem] xxl:text-xs'>
                        {notification.body}
                      </p>
                      <span className='text-[0.6875rem] font-normal leading-5 text-steelBlue lg:text-[0.625rem] xxl:text-[0.6875rem]'>
                        {calculateDueDate(notification.created)}
                      </span>
                    </div>
                  </DropdownMenuItem>
                ))
              )
            )}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
