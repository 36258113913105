import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useDeleteMutation, useGetQuery } from '@services';
import { AppContext } from '@useContext';
import { REFETCH_INTERVAL_TIME, SUCCESSFUL_MESSAGE } from '@constants';

export function useDataSource() {
  const { dataSourcePage, setDataSourcePage } = useContext(AppContext);

  const { organizationId } = useParams();
  const [search, setSearch] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState({});
  const [deleteDataSourceLoading, setDeleteDataSourceLoading] = useState(false);

  let queryParams = {};

  if (search) queryParams = { search };
  else queryParams = { page: dataSourcePage };

  const {
    data: connectedDataSource,
    refetch: connectedDataSourceRefetch,
    isLoading: connectedDataSourceLoading,
    isFetching: connectedDataSourceFetching,
  } = useGetQuery(
    'poll-connectedDataSource-listing',
    apiEndpoints.CONNECTED_DATA_SOURCE_LIST(organizationId),
    queryParams,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );

  const { mutate: handleDeleteDataSource } = useDeleteMutation(
    apiEndpoints.DELETE_DATA_SOURCE(organizationId, showDeleteDialog?.connector?.id),
    () => {
      setShowDeleteDialog({});
      connectedDataSourceRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Data source deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteDataSourceLoading(false);
    }
  );

  useEffect(() => {
    dataSourcePage && connectedDataSourceRefetch();
  }, [dataSourcePage, search]);

  return {
    dataSourcePage,
    setDataSourcePage,
    connectedDataSource,
    connectedDataSourceRefetch,
    connectedDataSourceLoading,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDataSourceLoading,
    setDeleteDataSourceLoading,
    handleDeleteDataSource,
    setSearch,
    search,
    connectedDataSourceFetching,
  };
}
