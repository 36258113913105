/* eslint-disable indent */
import { BaseLoader, CustomPagination, TourButton } from '@common/components';
import {
  ConnectDatasource,
  CreateDashboardDialog,
  DashboardGridView,
  DashboardTabularView,
  useDashboardsListing,
  EmptyDashboard,
} from '@pages/dashboard';
import { useManageOrganization } from '@pages/manageOrganization';
import { getLocalStorageItem, removeLocalStorageItem, useAppTours } from '@utils';
import {
  COLORS,
  DASHBOARD_VIEW_TYPE,
  ROLES,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  ORGANIZATION_ID_BY_ONBOARDING,
  TOTAL_ITEMS_PER_PAGE,
} from '@constants';
import { GridViewIcon, TableViewIcon } from '@assets/svgs';

export function DashboardListing() {
  const { manageOrganization, manageOrganizationFetching } = useManageOrganization();
  const {
    dashboardsListing,
    dashboardsListingFetching,
    dashboardPage,
    setDashboardPage,
    setDashboardId,
    selectedDashboard,
    setSelectedDashboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    handleDeleteDashboard,
    handleDuplicateDashboard,
    setDeleteDashboardLoading,
    viewType,
    setViewType,
  } = useDashboardsListing();
  const { createDashboardTour, setCreateDashboardStep1, isDriverEnabled } = useAppTours();

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  removeLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
  removeLocalStorageItem(ORGANIZATION_ID_BY_ONBOARDING);

  const isMember = manageOrganization?.data?.userRole === ROLES.MEMBER;

  return manageOrganizationFetching ? (
    <BaseLoader />
  ) : (
    <div className='relative pt-6 md:pt-6 lg:h-[calc(100vh-8.9375rem)] lg:pt-4 xxl:h-[calc(100vh-11.5625rem)] xxl:pt-6'>
      <div className='relative mx-5 flex h-full flex-col md:mx-8 xxl:mx-10'>
        <div className='max-[520px]:gap-4 flex w-full flex-col items-center bg-themeColor sm:flex-row sm:gap-2.5 md:flex-row lg:gap-4 xxl:gap-6 '>
          <div className='flex w-full items-center justify-between '>
            <h1 className='hidden text-nowrap font-semibold lg:block lg:text-sm lg:leading-[1.0625rem] xxl:text-xl xxl:leading-6'>
              All Dashboards
            </h1>
            <h1 className='block text-2xl font-bold leading-[1.875rem] md:text-[2rem] md:leading-6 lg:hidden'>
              Dashboards
            </h1>
            {dashboardsListing?.data?.results?.length !== 0 && (
              <div className='inline-flex items-center justify-center'>
                <div
                  onClick={() => setViewType('table')}
                  className={`inline-flex h-9 w-10 items-center justify-center rounded-md px-2 ring-offset-background transition-colors md:w-12 md:px-3 lg:w-[2.125rem] lg:px-2  xxl:w-12 xxl:px-3 ${viewType === DASHBOARD_VIEW_TYPE.TABLE ? 'bg-darkPrimary' : ''}`}
                >
                  <TableViewIcon
                    strokeColor={
                      viewType === DASHBOARD_VIEW_TYPE.TABLE ? COLORS.WHITE_COLOR : COLORS.TOGGLE_DISABLE_COLOR
                    }
                  />
                </div>
                <div
                  onClick={() => setViewType('grid')}
                  className={`inline-flex h-9 w-10 items-center justify-center rounded-md px-2 ring-offset-background transition-colors md:w-12 md:px-3 lg:w-[2.125rem] lg:px-2  xxl:w-12 xxl:px-3 ${viewType === DASHBOARD_VIEW_TYPE.GRID ? 'bg-darkPrimary' : ''}`}
                >
                  <GridViewIcon
                    strokeColor={
                      viewType === DASHBOARD_VIEW_TYPE.GRID ? COLORS.WHITE_COLOR : COLORS.TOGGLE_DISABLE_COLOR
                    }
                  />
                </div>
              </div>
            )}
          </div>
          {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
            <div className='mt-7 flex items-end sm:mt-0'>
              <CreateDashboardDialog
                isDriverEnabled={isDriverEnabled}
                setCreateDashboardStep1={setCreateDashboardStep1}
                btnDisabled={manageOrganization?.data?.haveDataSources === 0}
              />
            </div>
          )}
        </div>
        {dashboardsListing?.data?.results?.length === 0 &&
        manageOrganization?.data?.haveDataSources === 0 &&
        !isMember ? (
          <ConnectDatasource />
        ) : dashboardsListing?.data?.results?.length === 0 ? (
          <EmptyDashboard />
        ) : (
          <div className='md:pt-[1.875rem] lg:pt-4 xxl:pt-6'>
            {viewType === DASHBOARD_VIEW_TYPE.TABLE && (
              <DashboardTabularView
                dashboardsListing={dashboardsListing}
                dashboardsListingFetching={dashboardsListingFetching}
                setDashboardId={setDashboardId}
                selectedDashboard={selectedDashboard}
                setSelectedDashboard={setSelectedDashboard}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteDashboardLoading={deleteDashboardLoading}
                handleDeleteDashboard={handleDeleteDashboard}
                handleDuplicateDashboard={handleDuplicateDashboard}
                isMember={isMember}
                setDeleteDashboardLoading={setDeleteDashboardLoading}
                page={dashboardPage}
                viewType={viewType}
              />
            )}
            {viewType === DASHBOARD_VIEW_TYPE.GRID && (
              <DashboardGridView
                dashboardsListing={dashboardsListing}
                dashboardsListingFetching={dashboardsListingFetching}
                setDashboardId={setDashboardId}
                selectedDashboard={selectedDashboard}
                setSelectedDashboard={setSelectedDashboard}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteDashboardLoading={deleteDashboardLoading}
                handleDeleteDashboard={handleDeleteDashboard}
                handleDuplicateDashboard={handleDuplicateDashboard}
                isMember={isMember}
                setDeleteDashboardLoading={setDeleteDashboardLoading}
                page={dashboardPage}
                viewType={viewType}
              />
            )}
          </div>
        )}
      </div>
      <div className='mb-10 mt-6 md:mt-[2.375rem] lg:mb-0 lg:mt-6'>
        {dashboardsListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={dashboardPage} setPage={setDashboardPage} data={dashboardsListing} />
        )}
      </div>
      {manageOrganization?.data?.haveDataSources !== 0 && userOrganizationRole !== ROLES.MEMBER && (
        <TourButton Label='“Create Dashboard”' onClick={() => createDashboardTour().drive()} />
      )}
    </div>
  );
}
