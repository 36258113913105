import { useParams } from 'react-router-dom';
import { apiEndpoints, useGetQuery } from '@services';

export function useCustomWidgets(isEditMode) {
  const { organizationId, dashboardId } = useParams();

  const { data: customWidgets, isFetching: customWidgetsFetching } = useGetQuery(
    'custom-widgets',
    apiEndpoints.CUSTOM_WIDGETS(organizationId, dashboardId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEditMode,
    }
  );

  return { customWidgets, customWidgetsFetching };
}
