import { useEffect, useRef, useState } from 'react';
import { cn } from '@shadcnUtils';
import { GoalTrackingProgress } from '@common/components';
import { COLORS } from '@constants';

export function GoalWidget({ widget, className, isSharedDashboard }) {
  const goalWidgetRef = useRef(null);

  const [widgetLabelFontSize, setWidgetLabelFontSize] = useState('17px');
  const [percentageFontSize, setPercentageFontSize] = useState('43px');

  const goal = {
    achieved: widget?.achieved,
    target: widget?.target,
    growthTrend: widget?.trend,
    remainingDays: widget?.remainingDays,
  };

  const trendStr = widget?.trend?.toString();
  const hasNegative = trendStr.includes('-');

  const color = widget?.trend === 0 ? COLORS.ON_TRACK_COLOR : hasNegative ? COLORS.OFF_TRACK_COLOR : COLORS.COMPLETED;

  useEffect(() => {
    if (goalWidgetRef?.current) {
      setWidgetLabelFontSize(Math.min(goalWidgetRef?.current?.clientHeight, goalWidgetRef?.current?.clientWidth) / 10);
      setPercentageFontSize(Math.min(goalWidgetRef?.current?.clientHeight, goalWidgetRef?.current?.clientWidth) / 4);
    }
  }, [goalWidgetRef?.current?.clientHeight]);

  return (
    <div ref={goalWidgetRef} className={cn('flex h-full flex-col justify-center', className)}>
      <div className='flex flex-col items-center'>
        <span
          className={`${isSharedDashboard ? '' : 'my-[1.5625rem]'} text-center font-bold`}
          style={{ fontSize: widgetLabelFontSize }}
        >
          {widget?.widgetLabel}
        </span>
        <span className='font-[800]' style={{ fontSize: className ? '14px' : percentageFontSize, color }}>
          {widget?.percentage.toFixed(2)}%
        </span>
      </div>
      <GoalTrackingProgress goal={goal} isSharedDashboard={isSharedDashboard} className='!w-full' />
    </div>
  );
}
