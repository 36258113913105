import { MembershipCards, MembershipHeader } from '@pages/Membership';
import { HomeCircleIcon, ZendashLogo } from '@assets/svgs';

export function Membership() {
  return (
    <div className='flex-1'>
      <MembershipHeader logo={<ZendashLogo fillColor='#fff' className='h-6 w-[8.125rem]' />} />
      <div className='mt-[3.75rem] flex flex-col'>
        <div className='absolute top-[1.3125rem] -z-10 lg:-top-1.5 xxl:top-0'>
          <HomeCircleIcon className='h-[16.875rem] w-[16.875rem] xxl:h-[23.625rem] xxl:w-[23.625rem]' />
        </div>
        <MembershipCards />
      </div>
    </div>
  );
}
