export function ResponsiveLogo({ className }) {
  return (
    <svg
      width='130'
      height='24'
      viewBox='0 0 130 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_3624_17231)'>
        <path d='M68.8871 2.79639H66.2637V14.4875H68.8871V2.79639Z' fill='url(#paint0_linear_3624_17231)' />
        <path d='M64.3851 5.58936H61.7617V17.2805H64.3851V5.58936Z' fill='url(#paint1_linear_3624_17231)' />
        <path
          d='M0 8.52297H11.4247L0 21.3091V23.7129H14.7918V21.3091H3.33195L14.7918 8.52297V6.15137H0V8.52297Z'
          fill='white'
        />
        <path
          d='M100.598 10.766C100.598 9.35773 101.88 8.33296 104.026 8.33296C106.369 8.33296 107.712 9.61245 107.832 11.375H110.76C110.564 7.94648 108.002 5.85596 104.123 5.85596C100.243 5.85596 97.6814 8.06652 97.6814 10.7573C97.6814 17.2953 108.064 15.0203 108.064 18.929C108.064 20.3725 106.781 21.5261 104.477 21.5261C102.009 21.5261 100.501 20.2437 100.343 18.5455H97.3301C97.5204 21.7486 100.404 23.9914 104.506 23.9914C108.386 23.9914 110.918 21.813 110.918 18.929C110.787 12.6575 100.598 14.9324 100.598 10.766Z'
          fill='white'
        />
        <path
          d='M94.1933 14.9321V6.13086H91.444V8.54638C90.1954 7.3621 88.627 6.56984 86.9328 6.26764C85.2386 5.96543 83.493 6.16652 81.9119 6.84604C80.3309 7.52557 78.9837 8.6537 78.0371 10.0909C77.0905 11.5281 76.5859 13.2112 76.5859 14.9321C76.5859 16.653 77.0905 18.3362 78.0371 19.7734C78.9837 21.2106 80.3309 22.3387 81.9119 23.0182C83.493 23.6977 85.2386 23.8988 86.9328 23.5966C88.627 23.2944 90.1954 22.5022 91.444 21.3179V23.7334H94.1933V14.9321ZM85.392 20.987C84.1943 20.9876 83.0234 20.633 82.0273 19.968C81.0312 19.303 80.2547 18.3576 79.7959 17.2513C79.3372 16.1449 79.2169 14.9274 79.4502 13.7527C79.6835 12.5779 80.2599 11.4988 81.1066 10.6517C81.9533 9.80462 83.0321 9.22766 84.2068 8.99379C85.3814 8.75993 86.599 8.87966 87.7055 9.33786C88.8121 9.79605 89.7579 10.5721 90.4233 11.5679C91.0888 12.5637 91.444 13.7345 91.444 14.9321C91.444 16.5375 90.8065 18.0771 89.6716 19.2126C88.5367 20.348 86.9974 20.9863 85.392 20.987Z'
          fill='white'
        />
        <path
          d='M45.531 5.83252C43.1594 5.83252 41.0455 6.82508 39.889 8.65209V6.13117H37.1426V23.7337H39.889V14.0043C39.889 10.2214 41.9385 8.36515 44.922 8.36515C47.9055 8.36515 49.8233 10.1892 49.8233 13.7788V23.7161H52.7014V13.3631C52.7014 8.33002 49.6008 5.83252 45.531 5.83252Z'
          fill='white'
        />
        <path
          d='M122.441 5.83238C120.07 5.83238 117.956 6.82494 116.802 8.65195V0H114.053V23.7336H116.802V14.0042C116.802 10.2213 118.852 8.36501 121.832 8.36501C124.813 8.36501 126.733 10.1891 126.733 13.7787V23.716H129.62V13.3629C129.62 8.32988 126.511 5.83238 122.441 5.83238Z'
          fill='white'
        />
        <path
          d='M25.2036 6.1309C23.596 6.12948 22.0188 6.56836 20.6431 7.39992C19.2673 8.23147 18.1456 9.42393 17.3996 10.8479C16.6536 12.2719 16.3119 13.8729 16.4114 15.4774C16.511 17.0818 17.0481 18.6284 17.9645 19.9492C18.8808 21.27 20.1414 22.3145 21.6094 22.9696C23.0775 23.6246 24.6968 23.8651 26.2919 23.6649C27.8869 23.4647 29.3966 22.8315 30.6572 21.834C31.9178 20.8365 32.8812 19.5127 33.4427 18.0065H30.3918C29.7629 19.0817 28.816 19.9354 27.6815 20.4498C26.547 20.9642 25.2809 21.114 24.0577 20.8786C22.8345 20.6431 21.7144 20.034 20.852 19.1352C19.9895 18.2364 19.4271 17.0922 19.2424 15.8603H33.9638C34.0969 14.6319 33.9693 13.3892 33.5893 12.2135C33.2093 11.0377 32.5856 9.9554 31.7588 9.03716C30.932 8.11892 29.9208 7.38545 28.7913 6.88465C27.6617 6.38386 26.4392 6.12701 25.2036 6.1309ZM19.3829 13.3599C19.723 12.0752 20.4784 10.9391 21.5315 10.1286C22.5845 9.31802 23.8761 8.8785 25.205 8.8785C26.5339 8.8785 27.8255 9.31802 28.8786 10.1286C29.9317 10.9391 30.6871 12.0752 31.0272 13.3599H19.3829Z'
          fill='white'
        />
        <path
          d='M70.7026 8.55533V14.9323C70.7033 15.9014 70.4714 16.8566 70.0264 17.7175C69.5813 18.5784 68.9361 19.32 68.145 19.8797C67.3538 20.4395 66.4399 20.8012 65.4799 20.9344C64.52 21.0676 63.5421 20.9685 62.6284 20.6453C61.7147 20.3221 60.892 19.7843 60.2293 19.0771C59.5667 18.3699 59.0835 17.5139 58.8203 16.5812C58.5571 15.6485 58.5217 14.6662 58.717 13.7169C58.9123 12.7677 59.3325 11.8791 59.9425 11.126V7.51885C58.9186 8.18042 58.0437 9.04787 57.3733 10.066C56.7029 11.0842 56.2518 12.2307 56.0486 13.4327C55.8455 14.6346 55.8947 15.8658 56.1933 17.0477C56.4918 18.2296 57.0331 19.3364 57.7827 20.2977C58.5323 21.259 59.4738 22.0538 60.5473 22.6314C61.6207 23.2091 62.8027 23.557 64.0179 23.6529C65.2332 23.7489 66.4551 23.5909 67.6059 23.1889C68.7568 22.7869 69.8114 22.1498 70.7026 21.318V23.7336H73.4519V0H70.7026V8.55533Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3624_17231'
          x1='69.01'
          y1='5.02159'
          x2='63.8452'
          y2='18.0449'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3624_17231'
          x1='66.0774'
          y1='3.85897'
          x2='60.9126'
          y2='16.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <clipPath id='clip0_3624_17231'>
          <rect width='130' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
