import { Label, RadioGroup, RadioGroupItem } from '@shadcnComponent';
import { CREATE_DASHBOARD_TYPE_ID } from '@constants';
import { CreateCustomIcon, TemplateIcon } from '@assets/svgs';

export function CreateDashboardStep3({ value, handleSelect, setCreateDashboardStep1 }) {
  return (
    <div id='step-3' className='mt-4 md:mt-6 lg:mt-4 xxl:mt-6'>
      <>
        <div className='flex flex-col gap-2 md:gap-1.5 lg:gap-1 xxl:gap-1.5'>
          <h2 className='text-sm font-semibold leading-[1.0625rem] text-midNightBlue md:text-lg md:leading-7 lg:text-xs lg:leading-[.9375rem] xxl:text-lg'>
            Select One
          </h2>
          <p className='text-xs font-normal leading-[.9375rem] text-slateGray md:text-sm md:leading-5 lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-5'>
            Make changes to your profile here. Click save when you&apos;re done.
          </p>
        </div>
      </>
      <RadioGroup
        id={CREATE_DASHBOARD_TYPE_ID}
        className='mt-6 flex h-40 justify-center gap-4 md:mt-[5.625rem] md:h-[13.9375rem] md:gap-5 lg:mt-[4.4375rem] lg:h-[9.75rem] xxl:mt-[5.6875rem] xxl:h-[13.9375rem] xxl:gap-5'
        value={value}
        onValueChange={value => {
          handleSelect(value);
          setCreateDashboardStep1(prev => {
            return {
              ...prev,
              dashboardType: value,
            };
          });
        }}
      >
        <div className='h-full w-[10.4375rem] md:w-[18.75rem] lg:w-[12.5rem] xxl:w-[18.75rem]'>
          <RadioGroupItem value='custom' id='custom' className='peer sr-only' />
          <Label
            htmlFor='custom'
            className='flex h-full flex-col items-center gap-5 rounded-md border-2 border-muted bg-popover px-4 py-4 peer-data-[state=checked]:border-darkPrimary md:p-6 lg:gap-5 lg:pb-3 lg:pt-4 xxl:gap-4 xxl:pb-6 xxl:pt-[1.6875rem] [&:has([data-state=checked])]:border-darkPrimary'
          >
            <div>
              <CreateCustomIcon className='h-20 w-[5.4375rem] md:h-[8.625rem] md:w-[9.3125rem] lg:h-[5.75rem] lg:w-[6.25rem] xxl:h-[8.625rem] xxl:w-[9.375rem]' />
            </div>
            <p className='text-xs font-semibold leading-[.875rem] text-midNightBlue md:text-sm md:leading-4 lg:text-xs lg:leading-[.875rem] xxl:text-sm xxl:leading-4'>
              Create Custom
            </p>
          </Label>
        </div>
        <div className='h-full w-[10.4375rem] md:w-[18.75rem] lg:w-[12.5rem] xxl:w-[18.75rem]'>
          <RadioGroupItem value='template' id='template' className='peer sr-only' />
          <Label
            htmlFor='template'
            className='flex h-full flex-col items-center gap-5 rounded-md border-2 border-muted bg-popover px-4 py-4 peer-data-[state=checked]:border-darkPrimary md:p-6 lg:gap-5 lg:pb-3 lg:pt-4 xxl:pb-6 xxl:pt-[1.875rem] [&:has([data-state=checked])]:border-darkPrimary'
          >
            <TemplateIcon className='h-20 w-[5.4375rem] md:h-[8.625rem] md:w-[9.3125rem] lg:h-[5.75rem] lg:w-[6.25rem] xxl:h-[8.25rem] xxl:w-[8.375rem]' />
            <p className='text-xs font-semibold leading-[.875rem] text-midNightBlue md:text-sm md:leading-4 lg:text-xs lg:leading-[.875rem] xxl:text-sm xxl:leading-4'>
              Start From a Template
            </p>
          </Label>
        </div>
      </RadioGroup>
    </div>
  );
}
