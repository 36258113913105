import { cn } from '@shadcnUtils';
import { COLORS, THEME_STYLE } from '@constants';
import { CommentsIcon } from '@assets/svgs';

export function WidgetCommentBtn({ widget, showCommentBtn, handleEditCommentMode, className }) {
  return (
    <>
      {!showCommentBtn && (
        <span
          onClick={handleEditCommentMode}
          className={cn('hidden h-6 w-8 cursor-pointer items-center justify-center rounded-sm border', className)}
          style={{ borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }}
        >
          <CommentsIcon
            strokeColor={widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY}
            className='h-4 w-4'
          />
        </span>
      )}
    </>
  );
}
