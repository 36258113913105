export function LogoUpload({
  className,
  parentClass,
  inputClass,
  placeholder,
  labelText,
  logo,
  handleChange,
  imgRef,
  labelClass,
  sizeCount,
}) {
  return (
    <div className={`${parentClass}`}>
      <div className={'space-y-3 lg:space-y-1.5 xxl:space-y-4'}>
        <label
          className={`whitespace-nowrap text-sm font-bold leading-[1.0588rem] md:text-lg md:leading-[1.3613rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-lg xxl:leading-[1.3613rem] ${labelClass}`}
        >
          {labelText}
        </label>
        <div
          className={`shadow-[0_0.97px_3.88px_0.57px_rgba(25, 33,  61, 0.08)] relative flex items-center justify-center rounded-md border border-paleBlueGray xxl:rounded-lg ${className}`}
        >
          <input
            type='file'
            accept='.jpg, .jpeg, .png'
            ref={imgRef}
            onChange={handleChange}
            className={`absolute opacity-0 ${inputClass}`}
          />
          {!logo && placeholder}
          {logo && (
            <div className='h-full w-full p-2'>
              <img src={logo} alt='Preview' className='h-full w-full object-contain' />
            </div>
          )}
        </div>
      </div>
      <p className='mt-1 text-xs font-normal leading-[0.9075rem] md:mt-2 lg:mt-1 lg:text-[0.5rem] lg:leading-[0.625rem] xxl:mt-2 xxl:text-xs xxl:leading-[0.9075rem]'>
        {sizeCount}
      </p>
    </div>
  );
}
