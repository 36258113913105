import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { AppContext } from '@useContext';
import { getLocalStorageItem, removeLocalStorageItem } from '@utils';
import { SUCCESSFUL_MESSAGE } from '@constants';

export function useDashboardsListing() {
  const { organizationId } = useParams();

  const { dashboardPage, setDashboardPage } = useContext(AppContext);
  const dashboardViewType = getLocalStorageItem('dashboardViewType');

  const [dashboardId, setDashboardId] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [deleteDashboardLoading, setDeleteDashboardLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewType, setViewType] = useState(dashboardViewType || 'table');

  const {
    data: dashboardsListing,
    refetch: dashboardsListingRefetch,
    isFetching: dashboardsListingFetching,
  } = useGetQuery('dashboards-listing', apiEndpoints.DASHBOARDS_LISTING(organizationId), { page: dashboardPage });

  const { mutate: handleDuplicateDashboard } = usePostMutation(
    apiEndpoints.DUPLICATE_DASHBOARD(organizationId, dashboardId),
    () => {
      dashboardsListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Dashboard duplicated'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleDeleteDashboard } = useDeleteMutation(
    apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId),
    () => {
      setShowDeleteDialog(false);
      dashboardsListingRefetch();
      setDeleteDashboardLoading(false);
      toast.success(SUCCESSFUL_MESSAGE('Dashboard deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteDashboardLoading(false);
    }
  );

  useEffect(() => {
    dashboardPage && dashboardsListingRefetch();
  }, [dashboardPage, organizationId]);

  useEffect(() => {
    const handleBeforeUnload = () => removeLocalStorageItem('dashboardCurrentPage');

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return {
    dashboardPage,
    setDashboardPage,
    dashboardsListing,
    dashboardsListingFetching,
    setDashboardId,
    selectedDashboard,
    setSelectedDashboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    handleDeleteDashboard,
    handleDuplicateDashboard,
    setDeleteDashboardLoading,
    viewType,
    setViewType,
  };
}
