import { DialogContent, Button } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { FormInput, FormSelect, Loader } from '@common/components';
import {
  CREATE_TEAM_MODAL_BUTTON_ID,
  CREATE_TEAM_PERMISSION_DROPDOWN_ID,
  PERMISSION_DROP_DOWN_OPTIONS,
  CREATE_TEAM_NAME_ID,
  CREATE_TEAM_PERMISSION_ID,
  CREATE_TEAM_DASHBOARD_DROPDOWN_ID,
  CREATE_TEAM_DASHBOARD_ID,
  CREATE_TEAM_MEMBER_DROPDOWN_ID,
  CREATE_TEAM_MEMBER_ID,
} from '@constants';
import { CrossIcon } from '@assets/svgs';

export function AddEditTeamDialog({
  label,
  formik,
  scrollToRef,
  teamLoading,
  dashboardsListing,
  organizationMembersListing,
  isDriverEnabled,
  setIsOptionClicked,
}) {
  const { values, handleChange, touched, errors, submitForm, setFieldValue } = formik;

  const handleSelect = (key, value) => setFieldValue(key, [...values[key], value]);
  const handleRemove = (key, id) =>
    setFieldValue(
      key,
      values[key]?.filter(item => item.id !== id)
    );

  return (
    <DialogContent
      className='w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:max-w-[37.0625rem] md:p-10 lg:max-w-[24.625rem] lg:p-8 xxl:max-w-[37.0625rem] xxl:p-10'
      id='teamDialog'
    >
      <h3 className='mb-6 leading-[1.375rem] md:mb-10 md:leading-7 lg:mb-6 lg:leading-[1.375rem] xxl:mb-7 xxl:text-2xl xxl:leading-7'>
        {label}
      </h3>
      <div
        className='max-h-[414px] overflow-y-auto scrollbar-none md:mx-[3.9375rem] lg:mx-[1.6875rem] xxl:mx-[3.9375rem]'
        ref={scrollToRef}
        id='scrollTeamDialog'
      >
        <div className='flex flex-col gap-3'>
          <FormInput
            id={CREATE_TEAM_NAME_ID}
            labelText='Team Name'
            placeholder='Enter your team name'
            value={values.teamName}
            handleChange={handleChange}
            showError={touched.teamName && errors.teamName}
            labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
            inputClassName='h-10 md:h-10 lg:h-8 xxl:h-10'
          />
          <div className='flex flex-col gap-1.5'>
            <FormSelect
              isDriverEnabled={isDriverEnabled}
              id={CREATE_TEAM_DASHBOARD_ID}
              labelText='Dashboards'
              tourId={CREATE_TEAM_DASHBOARD_DROPDOWN_ID}
              placeholder='Select dashboards'
              handleChange={value => handleSelect('dashboards', value)}
              options={dashboardsListing?.data?.results.filter(
                option => !values.dashboards?.some(dashboard => dashboard.id === option.id)
              )}
              value=''
              showError={touched.dashboards && errors.dashboards}
              optionPlaceholder='No dashboard'
              triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values?.dashboards.length > 0 ? '' : 'custom-select'}`}
              labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
            />
          </div>
          {!isEmpty(values?.dashboards) && (
            <>
              <div className='flex flex-wrap gap-2 border-b border-lightGray pb-6'>
                {values?.dashboards?.map(dashboard => {
                  return (
                    <span
                      key={dashboard?.id}
                      onClick={() => handleRemove('dashboards', dashboard?.id)}
                      className='flex items-center gap-2 rounded-lg bg-tableRowHover px-2.5 py-1 text-sm font-normal leading-4 text-colorPrimary lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-4'
                    >
                      {dashboard?.name}
                      <CrossIcon className='h-3 w-3 md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4' />{' '}
                    </span>
                  );
                })}
              </div>
              <div className='flex flex-col gap-1.5'>
                <FormSelect
                  isDriverEnabled={isDriverEnabled}
                  id={CREATE_TEAM_PERMISSION_ID}
                  tourId={CREATE_TEAM_PERMISSION_DROPDOWN_ID}
                  labelText='Permission'
                  placeholder='Select permission'
                  value={values.permission}
                  handleChange={selectedPermission => {
                    setFieldValue('permission', selectedPermission);
                    setIsOptionClicked(true);
                  }}
                  options={PERMISSION_DROP_DOWN_OPTIONS}
                  showError={touched.permission && errors.permission}
                  triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values?.permission ? '' : 'custom-select'}`}
                  labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
                />
              </div>
            </>
          )}
        </div>
        <div className='pt-6'>
          <h4 className='mb-4 text-base font-semibold leading-5 lg:text-sm lg:leading-[1.0625rem] xxl:text-base xxl:leading-5'>
            Add Members
          </h4>
          <FormSelect
            isDriverEnabled={isDriverEnabled}
            id={CREATE_TEAM_MEMBER_ID}
            tourId={CREATE_TEAM_MEMBER_DROPDOWN_ID}
            labelText='Select Members'
            placeholder='Select members'
            value=''
            handleChange={value => handleSelect('members', value)}
            options={organizationMembersListing?.data?.results.filter(
              option => !values.members.some(dashboard => dashboard.id === option.id)
            )}
            optionPlaceholder='No member'
            triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values?.members.length > 0 ? '' : 'custom-select'}`}
            labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
          />
          <>
            {!isEmpty(values?.members) && (
              <div className='mt-3 flex flex-wrap gap-2'>
                {values?.members?.map(member => {
                  return (
                    <span
                      key={member?.id}
                      onClick={() => handleRemove('members', member?.id)}
                      className='flex items-center gap-2 rounded-lg bg-tableRowHover px-2.5 py-1 text-sm font-normal leading-4 text-colorPrimary lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-4'
                    >
                      {member?.email}
                      <CrossIcon className='h-3 w-3 md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4' />
                    </span>
                  );
                })}
              </div>
            )}
          </>
          <div className='ml-auto mt-6 md:w-[11.25rem]'>
            <Button id={CREATE_TEAM_MODAL_BUTTON_ID} onClick={submitForm} disabled={teamLoading}>
              {label === 'Edit Team' ? 'Edit' : 'Create'}
              {teamLoading ? <Loader /> : ''}
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
