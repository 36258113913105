export function ImageUploaderIcon() {
  return (
    <svg width='122' height='122' viewBox='0 0 122 122' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='61' cy='61' r='61' fill='#B2B8C0' />
      <path
        d='M121.999 61.2327C121.953 73.2849 118.338 85.0533 111.61 95.0528C104.882 105.052 95.3424 112.835 84.1956 117.418C73.0487 122.001 60.7941 123.179 48.9782 120.804C37.1624 118.428 26.315 112.606 17.8049 104.072C9.29491 95.5376 3.50372 84.6735 1.16227 72.8509C-1.17919 61.0283 0.0340222 48.7771 4.64879 37.6433C9.26357 26.5096 17.0731 16.9924 27.0917 10.2929C37.1103 3.59338 48.8889 0.0118297 60.9411 0.000252831C68.9806 -0.0229152 76.945 1.54637 84.3747 4.61755C91.8045 7.68872 98.5524 12.201 104.229 17.8938C109.906 23.5867 114.399 30.3475 117.449 37.786C120.499 45.2245 122.045 53.1934 121.999 61.2327ZM60.9411 113.219C72.9471 113.28 84.6014 109.169 93.9124 101.589C94.2473 101.415 94.5013 101.117 94.6207 100.759C94.7401 100.401 94.7154 100.011 94.552 99.6702C93.0501 94.3641 90.3118 89.4895 86.5617 85.4463C82.8117 81.403 78.1566 78.3063 72.9783 76.41C72.4099 76.2351 71.8021 76.2351 71.2337 76.41C68.0051 77.7775 64.5346 78.4821 61.0284 78.4821C57.5221 78.4821 54.0516 77.7775 50.823 76.41C50.2546 76.2351 49.6468 76.2351 49.0785 76.41C43.8782 78.2685 39.2024 81.3528 35.447 85.4018C31.6916 89.4507 28.9673 94.345 27.5047 99.6702C27.3504 100.006 27.3244 100.386 27.4316 100.74C27.5387 101.093 27.7716 101.396 28.0862 101.589C37.3408 109.068 48.8678 113.17 60.7667 113.219H60.9411ZM60.883 34.8906C57.4327 34.8906 54.0598 35.9137 51.191 37.8306C48.3222 39.7475 46.0862 42.472 44.7658 45.6597C43.4454 48.8474 43.1 52.3551 43.7731 55.7391C44.4462 59.1231 46.1077 62.2315 48.5474 64.6713C50.9872 67.111 54.0956 68.7725 57.4796 69.4456C60.8636 70.1188 64.3713 69.7733 67.5589 68.4529C70.7466 67.1325 73.4712 64.8965 75.3881 62.0277C77.3049 59.1589 78.3281 55.786 78.3281 52.3357C78.3281 47.709 76.4901 43.2717 73.2185 40.0001C69.9469 36.7285 65.5097 34.8906 60.883 34.8906Z'
        fill='#E2E8F0'
      />
    </svg>
  );
}
