import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@shadcnComponent';
import moment from 'moment';
import { BaseToolTip } from '@common/components';
import { DashboardActionPopover, DataSourcePopover, TeamPopover } from '@pages/dashboard';
import { ROUTES } from '@routes';
import { truncateString } from '@utils';

export function DataSourceCard({
  dashboard,
  setDashboardId,
  setSelectedDashboard,
  showDeleteDialog,
  setShowDeleteDialog,
  handleDuplicateDashboard,
  isMember,
  setDeleteDashboardLoading,
  page,
  viewType,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  return (
    <Card
      className='relative h-[7.8125rem] min-w-[11.5625rem] md:h-[9.5rem] lg:h-[7.3125rem] xxl:h-[10.8125rem]'
      onClick={() =>
        navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: dashboard?.id }), {
          state: { page, viewType },
        })
      }
    >
      <p className='px-2.5 pt-2 text-right text-[.5rem] leading-[.625rem] text-slateGray md:px-2.5 md:pt-2.5 md:text-[.625rem] md:leading-3 lg:px-2 lg:pt-2 lg:text-[.5rem] lg:leading-[.5625rem] xxl:pt-2.5 xxl:text-[.625rem] xxl:leading-3'>
        {moment(dashboard?.modified).fromNow()}
      </p>
      <CardHeader className='flex-row items-center justify-between px-4 pb-2 pt-0 md:px-6 md:pb-4 md:pt-0.5 lg:px-4 lg:pb-2 xxl:px-6 xxl:pb-3 xxl:pt-0.5'>
        <CardTitle
          id={`dashboardName-${dashboard?.id}`}
          className='text-sm font-semibold leading-4 md:text-lg md:leading-6 lg:text-sm lg:leading-4 xxl:text-xl xxl:leading-6'
        >
          {truncateString(dashboard?.name, 10, 'text-darkSecondary')}
        </CardTitle>
        {dashboard?.name?.length > 10 && (
          <BaseToolTip className='w-full' id={`dashboardName-${dashboard?.id}`} content={dashboard?.name} />
        )}
        <DashboardActionPopover
          dashboard={dashboard}
          setDashboardId={setDashboardId}
          handleDuplicateDashboard={handleDuplicateDashboard}
          setSelectedDashboard={setSelectedDashboard}
          setShowDeleteDialog={setShowDeleteDialog}
          showDeleteDialog={showDeleteDialog}
          isMember={isMember}
          setDeleteDashboardLoading={setDeleteDashboardLoading}
        />
      </CardHeader>
      <CardContent className='px-4 !pb-0 md:px-6 lg:px-4 lg:pb-5 xxl:px-6'>
        <TeamPopover dashboard={dashboard} />
      </CardContent>
      <CardFooter className='absolute -bottom-1 flex gap-3 px-4 pb-4 md:px-6 md:pb-6 md:pt-4 lg:px-4 lg:pb-3 xxl:px-6 xxl:pb-6 xxl:pt-10'>
        <DataSourcePopover dashboard={dashboard} />
      </CardFooter>
    </Card>
  );
}
