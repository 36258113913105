import { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent } from '@shadcnComponent';
import { Image } from 'lucide-react';
import Cropper from 'react-easy-crop';
import { getCircularCrop, getRectCrop } from '@utils';
import { SAVE_BTN_ID } from '@constants';

export function ImageCrop({
  uploadedImage,
  setIsUploaded,
  handleSave,
  handleCancel,
  isDialog = false,
  cropShape = 'round',
  imageRef,
  applyRef = true,
  handleCloseDialog,
}) {
  const [image, setImage] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [open, setIsOpen] = useState(false);

  const onFileChange = file => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setIsOpen(true);
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImageComplete =
      cropShape === 'rect'
        ? await getRectCrop(image, croppedAreaPixels)
        : await getCircularCrop(image, croppedAreaPixels);
    setCropImage(croppedImageComplete);
  };

  const CropImage = (
    <div className='w-[21.875rem] md:w-[26.25rem] lg:w-[18.75rem] xxl:w-[26.25rem]'>
      <Cropper
        image={image}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={1}
        cropShape={cropShape}
        showGrid={false}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        classes={{
          containerClassName:
            'h-[12.5rem] w-[calc(100vw-5rem)] max-w-[21.875rem] md:max-w-[26.25rem] md:h-[15rem] lg:max-w-[18.75rem] lg:h-[10.75rem] xxl:h-[15rem] xxl:max-w-[420px] rounded-[0.4375rem] xxl:rounded-lg',
        }}
      />
      <div className='my-[2.125rem] flex items-center gap-2 md:my-4 xxl:gap-2.5'>
        <Image className='h-4 w-4' />
        <input
          type='range'
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={e => setZoom(e.target.value)}
          className='flex-1'
        />
        <Image className='h-6 w-6' />
      </div>
    </div>
  );

  const handleDialog = () => {
    setIsUploaded(false);
    setIsOpen(false);
    applyRef && (imageRef.current.value = '');
    handleCloseDialog();
  };

  useEffect(() => {
    onFileChange(uploadedImage);
  }, [uploadedImage]);

  return (
    <div className='h-full'>
      {!isDialog && (
        <div className='flex h-full flex-col justify-around'>
          {image && <div className='mt-1 flex justify-center'>{CropImage}</div>}
          <div className='flex justify-between gap-4'>
            <Button
              variant='outline'
              className='w-full md:w-[11.25rem] lg:w-[7.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[11.25rem]'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSave(cropImage)}
              id={SAVE_BTN_ID}
              className='w-full md:w-[11.25rem] lg:w-[7.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[11.25rem]'
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {isDialog && (
        <Dialog open={open} onOpenChange={handleDialog}>
          <DialogContent
            id='crop-modal'
            className='flex !h-auto max-w-[24.375rem] flex-col p-5 md:max-w-[31.25rem] md:p-10 lg:max-w-[22.875rem] lg:p-8 xxl:!h-auto xxl:max-w-[31.25rem] xxl:p-10'
          >
            <h2 className='mb-5 text-2xl font-bold leading-7 md:mb-8 lg:text-xl lg:leading-[1.3613rem] xxl:mb-8 xxl:text-2xl xxl:leading-7'>
              Edit Image
            </h2>
            {image && <>{CropImage}</>}
            <div className='flex flex-col justify-between gap-4 md:flex-row'>
              <Button
                onClick={handleCancel}
                variant='outline'
                className='w-full md:w-[11.25rem] lg:w-[7.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[11.25rem]'
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSave(cropImage)}
                id={SAVE_BTN_ID}
                className='w-full md:w-[11.25rem] lg:w-[7.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[11.25rem]'
              >
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
