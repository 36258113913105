import { Button } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { BaseLoader } from '@common/components';
import { useEmailAcceptance } from '@pages/emailAcceptance';
import { capitalizeString } from '@utils';
import { InviteImage, Logo } from '@assets/svgs';

export function EmailAcceptance() {
  const { organizationInvitation, handleAcceptInvitation, expiredMessage, isLoading } = useEmailAcceptance();

  return isLoading ? (
    <BaseLoader />
  ) : (
    <div className='relative'>
      <div className='absolute left-10 top-10'>
        <Logo />
      </div>
      <div className='mx-auto flex h-screen max-w-[30rem] flex-col items-center justify-center px-5 md:max-w-[32.125rem] md:px-0 xxl:max-w-[32.125rem]'>
        <InviteImage className='h-[12.5rem] w-[12.5rem] md:h-[18.875rem] md:w-[18.875rem] lg:h-[12.5rem] lg:w-[12.5rem] xxl:h-[18.875rem] xxl:w-[18.875rem]' />
        {expiredMessage ? (
          <h2 className='mt-6 text-center text-lg font-bold leading-7 md:mt-3 md:text-[2rem] md:leading-[2.875rem] lg:text-[1.375rem] lg:leading-8 xxl:mt-6 xxl:text-[2rem] xxl:leading-[2.875rem]'>
            {expiredMessage}
          </h2>
        ) : !isEmpty(organizationInvitation?.data) ? (
          <>
            <h2 className='mt-6 text-center text-lg font-bold leading-7 md:mt-3 md:text-[2rem] md:leading-[2.875rem] lg:text-[1.375rem] lg:leading-8 xxl:mt-6 xxl:text-[2rem] xxl:leading-[2.875rem]'>
              {organizationInvitation?.data?.invitedBy} has invited you to join{' '}
              {organizationInvitation?.data?.organization?.name} on Zendash.
            </h2>
            <p className='mb-6 mt-3 text-center text-sm font-normal leading-6 md:mb-8 md:mt-4 md:text-lg md:leading-[1.3613rem] lg:mt-3.5 lg:text-xs lg:leading-[0.9075rem] xxl:mt-4 xxl:text-lg xxl:leading-[1.3613rem]'>
              Accept {organizationInvitation?.data?.invitedBy} invitation to join{' '}
              {organizationInvitation?.data?.organization?.name} as a{' '}
              {capitalizeString(organizationInvitation?.data?.role)}.
            </p>
          </>
        ) : (
          <h2 className='mt-6 text-center text-lg font-bold leading-7 md:mt-3 md:text-[2rem] md:leading-[2.875rem] lg:text-[1.375rem] lg:leading-8 xxl:mt-6 xxl:text-[2rem] xxl:leading-[2.875rem]'>
            This invite has been accepted
          </h2>
        )}
        {!isEmpty(organizationInvitation?.data) && (
          <div className='w-full md:w-[21.875rem] lg:w-[13.75rem] xxl:w-[21.875rem]' onClick={handleAcceptInvitation}>
            <Button>Accept Invitation</Button>
          </div>
        )}
      </div>
    </div>
  );
}
