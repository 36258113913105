export function HomeIcon() {
  return (
    <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.00004 16.0034C1.00004 14.0869 1.00647 12.1711 1.00004 10.2552C0.995363 9.2337 1.39817 8.44954 2.23126 7.85233C5.28299 5.66004 8.32849 3.45897 11.3677 1.2491C11.822 0.920311 12.1769 0.915622 12.6305 1.24323C15.7119 3.4703 18.7921 5.69833 21.8711 7.92735C22.6311 8.4765 22.9994 9.22842 23 10.1614C23 14.0881 23 18.0148 23 21.9414C23 22.5597 22.5791 22.9881 21.9564 22.9964C21.3338 23.0046 20.717 22.9964 20.0968 22.9964C18.3904 22.9964 16.6837 22.9964 14.9766 22.9964C14.4546 22.9964 14.0857 22.7379 13.9722 22.289C13.9486 22.1747 13.939 22.058 13.9436 21.9414C13.9436 20.5155 13.9436 19.0896 13.9407 17.6631C13.9372 16.7061 13.3713 15.9295 12.5107 15.6805C11.2035 15.3024 9.93835 16.236 9.92548 17.6033C9.91321 19.0439 9.92548 20.4845 9.92548 21.9256C9.92548 22.6242 9.556 22.9964 8.86089 22.9969H2.07925C1.38355 22.9969 1.00764 22.6219 1.00706 21.9285C1.00238 19.9535 1.00004 17.9784 1.00004 16.0034ZM21.1275 21.1549V20.9094C21.1275 17.3539 21.129 13.7994 21.1321 10.2458C21.1321 9.86314 20.9918 9.59647 20.6884 9.3767C17.8343 7.30866 14.9833 5.23632 12.1354 3.15968C12.0091 3.06767 11.9342 3.07939 11.815 3.16554C8.96514 5.22694 6.11277 7.28482 3.25786 9.33919C2.9597 9.55369 2.82348 9.81684 2.82406 10.1902C2.83147 13.7456 2.83303 17.3011 2.82874 20.8566V21.1139H8.14881C8.14881 21.019 8.14881 20.9328 8.14881 20.8472C8.14881 19.7278 8.12484 18.6085 8.15875 17.4902C8.22131 15.4337 9.85708 13.8302 11.905 13.7781C13.929 13.7265 15.6998 15.3429 15.7834 17.3713C15.8325 18.5469 15.7992 19.7255 15.8027 20.9035C15.8027 20.9873 15.8027 21.0717 15.8027 21.1579L21.1275 21.1549Z'
        fill='white'
      />
    </svg>
  );
}
