import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useGetQuery, usePatchMutation } from '@services';
import { FIELD_REQUIRED, GOAL_CONDITION, SUCCESSFUL_MESSAGE, TIME_PERIOD } from '@constants';

export function useEditGoal() {
  const { organizationId } = useParams();

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editGoalDetail, setEditGoalDetail] = useState({});
  const [selectedDashboardId, setSelectedDashboardId] = useState(null);

  const { data: goalDetail, refetch: goalDetailRefetch } = useGetQuery(
    'goal-detail',
    apiEndpoints.UPDATE_GOAL(organizationId, editGoalDetail?.id),
    {},
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: goalsListingRefetch } = useGetQuery(
    'goals-listing',
    apiEndpoints.ORGANIZATION_GOALS(organizationId)
  );

  const { data: dashboardsListing } = useGetQuery(
    'team-dashboards-listing',
    apiEndpoints.CREATE_TEAM_DASHBOARDS_LISTING(organizationId)
  );

  const { data: widgetListing, refetch: widgetListingRefetch } = useGetQuery(
    'goal-widget-listing',
    apiEndpoints.WIDGET_LIST(organizationId, selectedDashboardId || goalDetail?.data?.dashboard),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const goalInitialValues = {
    name: goalDetail?.data?.name || '',
    dashboard: dashboardsListing?.data?.results.find(option => option.id === goalDetail?.data?.dashboard) || '',
    timePeriod: TIME_PERIOD.find(option => option.id === goalDetail?.data?.periodInDays) || '',
    description: goalDetail?.data?.description || '',
    widget: widgetListing?.data?.results.find(option => option.widgetId === goalDetail?.data?.widgetId) || '',
    condition: GOAL_CONDITION.find(option => option.id === goalDetail?.data?.condition) || '',
    metric: goalDetail?.data?.target || '',
  };

  const goalValidationSchema = Yup.object({
    name: Yup.string().required(FIELD_REQUIRED('Goal name')),
    dashboard: Yup.object().required(FIELD_REQUIRED('Dashboard')),
    timePeriod: Yup.object().required(FIELD_REQUIRED('Time period')),
    description: Yup.string().required(FIELD_REQUIRED('Description')),
    widget: Yup.object().required(FIELD_REQUIRED('Widget')),
    condition: Yup.object().required(FIELD_REQUIRED('Condition')),
    metric: Yup.string().required(FIELD_REQUIRED('Metric')),
  });

  const handleSubmit = () => {
    let payload = {
      name: values.name,
      dashboard: values.dashboard?.id,
      description: values?.description,
      widgetId: values?.widget?.widgetId,
      widgetSource: values?.widget?.widgetSource.toUpperCase(),
      widgetLabel: values?.widget?.widgetLabel,
      widgetPrefix: values?.widget?.widgetPrefix,
      periodInDays: values.timePeriod?.id,
      target: parseInt(values?.metric),
      condition: values?.condition?.id,
    };

    if (initialValues?.name === values?.name) {
      delete payload['name'];
    }

    handleGoalEdit({ payload });
  };
  const { ...formik } = useFormikForm(goalInitialValues, handleSubmit, goalValidationSchema);

  const { initialValues, values, resetForm, setErrors } = formik;

  const { mutate: handleGoalEdit, isLoading: goalEditLoading } = usePatchMutation(
    apiEndpoints.UPDATE_GOAL(organizationId, goalDetail?.data?.id),
    () => {
      setShowEditDialog(false);
      setEditGoalDetail({});
      goalsListingRefetch();
      resetForm();
      toast.success(SUCCESSFUL_MESSAGE('Goal edited'));
    },
    ({ response: { data } }) => {
      setErrors({
        name: data.name[0] && data.name[0],
      });
    }
  );

  useEffect(() => {
    editGoalDetail?.id && goalDetailRefetch();
  }, [editGoalDetail?.id]);

  useEffect(() => {
    values?.dashboard?.id && widgetListingRefetch();
  }, [values?.dashboard?.id]);

  return {
    formik,
    showEditDialog,
    setShowEditDialog,
    editGoalDetail,
    setEditGoalDetail,
    goalEditLoading,
    setSelectedDashboardId,
  };
}
