import { FormInput, FormSelect, ImageCrop, ImageUpload } from '@common/components';
import { getFile, handleSave } from '@utils';
import {
  CREATE_DASHBOARD_CURRENCY_ID,
  CREATE_DASHBOARD_LOGO_ID,
  CREATE_DASHBOARD_NAME_ID,
  CURRENCY_DROP_DOWN_OPTIONS,
  CREATE_DASHBOARD_CURRENCY_DROPDOWN,
} from '@constants';
import { ImageUploaderIcon, PlusIconWhite } from '@assets/svgs';

export function CreateDashboardStep1({
  formik,
  imageRef,
  uploadedImage,
  isUploadedImage,
  setIsUploadedImage,
  setCreateDashboardStep1,
  isDriverEnabled,
  handleImageUpload,
}) {
  const { initialValues, values, setFieldValue, touched, errors } = formik;

  return (
    <div id='step-1' className='h-full w-full'>
      {!isUploadedImage && (
        <div className='flex flex-col items-center gap-4 pt-7 md:gap-12 md:pt-[3.6875rem] lg:gap-4 lg:pt-10 xxl:mx-auto xxl:w-[24rem] xxl:gap-12 xxl:pt-[3.6875rem]'>
          <div className='w-full md:w-[24rem] lg:w-[17.125rem] xxl:w-[24rem]'>
            <span className='text-left text-sm font-medium leading-[1.0588rem] md:leading-5 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-5'>
              Add Logo
            </span>
            <ImageUpload
              id={CREATE_DASHBOARD_LOGO_ID}
              icon={
                <PlusIconWhite className='h-[1.125rem] w-[1.125rem] md:h-[0.9844rem] md:w-[0.9844rem] lg:h-[0.5831rem] lg:w-[0.5831rem] xxl:h-3.5 xxl:w-3.5' />
              }
              imageRef={imageRef}
              placeHolderIcon={<ImageUploaderIcon />}
              className='mt-2 h-[6.25rem] w-[6.25rem] md:h-[7.625rem] md:w-[7.625rem] lg:mt-1.5 lg:h-[4.5rem] lg:w-[4.5rem] xxl:h-[7.625rem] xxl:w-[7.625rem]'
              inputWrapperClassName='h-[2rem] w-[2rem] md:h-[1.875rem] md:w-[1.875rem] lg:h-5 lg:w-5 xxl:h-[1.875rem] xxl:w-[1.875rem]'
              profileImage={uploadedImage?.image || values?.image}
              handleChange={e => {
                getFile(e.target.files[0], 'image', setFieldValue, setIsUploadedImage);
                setCreateDashboardStep1(prev => {
                  return {
                    ...prev,
                    logo: true,
                  };
                });
              }}
              showError={touched.image && errors.image}
              errorMessage={errors.image}
              showSkeletonLoader={isUploadedImage}
            />
          </div>
          <div className='flex w-[calc(100vw-5rem)] max-w-[21.875rem] flex-col gap-4 md:max-w-96 md:gap-6 lg:max-w-[17.125rem] lg:gap-4 xxl:max-w-96 xxl:gap-6'>
            <FormInput
              setCreateDashboardStep1={setCreateDashboardStep1}
              labelText='Name'
              placeholder='Enter name for the dashboard'
              labelClassName='xxl:text-sm xxl:leading-5 text-xs leading-[.9375rem] md:text-sm md:leading-5 lg:text-xs lg:leading-[0.9075rem]'
              id={CREATE_DASHBOARD_NAME_ID}
              value={values.name}
              handleChange={e => {
                setFieldValue('name', e.target.value);
                setCreateDashboardStep1(prev => {
                  return {
                    ...prev,
                    name: e.target.value,
                  };
                });
              }}
              showError={touched.name && errors.name}
              inputClassName='h-10'
            ></FormInput>
            <div className='flex flex-col gap-1.5'>
              <FormSelect
                isDriverEnabled={isDriverEnabled}
                id={CREATE_DASHBOARD_CURRENCY_ID}
                tourId={CREATE_DASHBOARD_CURRENCY_DROPDOWN}
                labelText='Currency'
                placeholder='US Dollar'
                optionPlaceholder='Dollars ($)'
                handleChange={selectedCurrency => {
                  setFieldValue('currency', selectedCurrency);
                  setCreateDashboardStep1(prev => {
                    return {
                      ...prev,
                      currency: selectedCurrency,
                    };
                  });
                }}
                options={CURRENCY_DROP_DOWN_OPTIONS}
                showError={touched.currency && errors.currency}
                labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
                triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values?.currency ? '' : 'custom-select'}`}
              />
            </div>
          </div>
        </div>
      )}
      {isUploadedImage && (
        <ImageCrop
          uploadedImage={values?.image}
          handleCancel={() => {
            setIsUploadedImage(false);
            setFieldValue('image', initialValues.image);
            imageRef.current.value = initialValues.image;
          }}
          imageRef={imageRef}
          setIsUploaded={setIsUploadedImage}
          handleSave={file => handleSave(file, handleImageUpload)}
        />
      )}
    </div>
  );
}
