import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useGetQuery, usePatchMutation, usePostMutation } from '@services';
import {
  ERROR_MESSAGE,
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  PROFILE_UPDATED_MESSAGE,
} from '@constants';

export function useProfileTab() {
  const imageRef = useRef(null);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [isUploadedImage, setIsUploadedImage] = useState(null);

  const {
    data: userDetail,
    isFetching: userDetailFetching,
    refetch: userDetailRefetch,
  } = useGetQuery('user-detail', apiEndpoints.USER_DETAIL);

  const personalTabInitialValues = {
    profileImage: userDetail?.data?.profileImage?.image,
    firstName: userDetail?.data?.firstName,
    lastName: userDetail?.data?.lastName,
    email: userDetail?.data?.email,
  };

  const personalTabValidationSchema = Yup.object({
    profileImage: Yup.string().required(FIELD_REQUIRED('Profile image')),
    firstName: Yup.string()
      .min(3, `${MIN_LENGTH_REQUIRED('First name')}`)
      .max(60, `${MAX_LENGTH_REQUIRED('First name')}`)
      .required(FIELD_REQUIRED('First name')),
    lastName: Yup.string()
      .min(3, `${MIN_LENGTH_REQUIRED('Last name')}`)
      .max(60, `${MAX_LENGTH_REQUIRED('Last name')}`)
      .required(FIELD_REQUIRED('Last name')),
    email: Yup.string().email(INVALID_EMAIL_ADDRESS).required(FIELD_REQUIRED('Email')),
  });

  const handleSubmit = () => {
    let payload = {
      profileImage: uploadedImage?.id,
      firstName: values.firstName,
      lastName: values.lastName,
    };

    !uploadedImage?.id && delete payload['profileImage'];

    handleProfileUpdate({ payload });
  };

  const { ...formik } = useFormikForm(personalTabInitialValues, handleSubmit, personalTabValidationSchema);

  const { values, resetForm } = formik;

  const handleCancelClick = () => {
    resetForm();
    setUploadedImage(null);
  };

  const { mutate: handleImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedImage(resp?.data);
      setIsUploadedImage(false);
      imageRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedImage(false);
      imageRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleProfileUpdate, isLoading: profileUpdateLoading } = usePatchMutation(
    apiEndpoints.USER_DETAIL,
    () => {
      toast.success(PROFILE_UPDATED_MESSAGE);
      userDetailRefetch();
    },
    () => toast.error(ERROR_MESSAGE)
  );

  return {
    formik,
    imageRef,
    uploadedImage,
    setUploadedImage,
    setIsUploadedImage,
    handleCancelClick,
    isUploadedImage,
    profileUpdateLoading,
    userDetailFetching,
    handleImageUpload,
  };
}
