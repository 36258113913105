import { DashboardTabsSection } from '@pages/dashboard';

export function WidgetsSidebar({ handleOnDrag, isEditMode, AddWidgetsOnClick }) {
  return (
    <div
      className={`relative z-[20] flex flex-col gap-4 overflow-y-auto border-l border-lightGray bg-white scrollbar-none md:h-[calc(100vh-17.6875rem)] md:p-4 lg:h-[calc(100vh-114px)] lg:gap-3 lg:p-5 xxl:h-[calc(100vh-10.625rem)] xxl:gap-3 xxl:px-7 xxl:py-6 ${isEditMode ? 'transition-all duration-500 ease-in-out md:w-[19.3125rem] lg:w-[15rem] xxl:w-[21.25rem]' : 'hidden'}`}
    >
      <h2 className='font-semibold md:text-lg md:leading-6 lg:text-sm lg:leading-[1.0625rem] xxl:text-lg xxl:leading-6'>
        Edit Dashboard
      </h2>
      <DashboardTabsSection handleOnDrag={handleOnDrag} isEditMode={isEditMode} AddWidgetsOnClick={AddWidgetsOnClick} />
    </div>
  );
}
