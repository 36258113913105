import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cn } from '@shadcnUtils';
import { cva } from 'class-variance-authority';

const labelVariants = cva(
  'text-base font-medium leading-[1.21rem] text-darkSecondary md:text-sm md:leading-[1.0588rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-5 peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
);
const Label = React.forwardRef(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
