import TimePicker from '@ashwinthomas/react-time-picker-dropdown';
import { Label } from '@shadcnComponent';

export function CustomTimePicker({ value, handleChange, showError, disabled, scheduleClass }) {
  return (
    <div
      className={`flex w-full flex-col gap-1.5 md:w-[15.5rem] lg:w-[17.5rem] xxl:w-[15.5rem] ${disabled ? 'disable-time' : ''} ${scheduleClass}`}
    >
      <Label className='text-sm font-medium leading-5 xxl:text-sm xxl:leading-5'>Time</Label>
      <TimePicker
        defaultValue={value}
        useTwelveHourFormat={true}
        showCloseIcon={false}
        onTimeChange={handleChange}
        onInputChange={handleChange}
        placeholder='HH : MM : SS'
        className={showError ? 'border border-errorColor' : ''}
        allowBackdrop={true}
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>{showError}</p>
      )}
    </div>
  );
}
