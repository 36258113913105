import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import {
  Dialog,
  ScrollArea,
  ScrollBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import {
  AddEditOrgDialog,
  BaseLoader,
  BaseToolTip,
  CustomPagination,
  DeleteDialog,
  EmptyDashboardSearch,
} from '@common/components';
import { ROUTES } from '@routes';
import { setLocalStorageItem, truncateString } from '@utils';
import { COLORS, SELECTED_ORGANIZATION_ID, TOTAL_ITEMS_PER_PAGE } from '@constants';
import { EditIcon, EditIconWhite } from '@assets/svgs';

export function MyOrganizationTab() {
  const navigate = useNavigate();

  const [
    organizations,
    page,
    setPage,
    organizationFetching,
    search,
    editFormik,
    imageRef,
    uploadedLogoEdit,
    setUploadedLogoEdit,
    setIsUploadedLogoEdit,
    isUploadedLogoEdit,
    isEditOrganizationLoading,
    isDeleteOrgLoading,
    handleDeleteOrganizationBtn,
    handleDeleteBtn,
    showAddEditDialog,
    showDeleteOrgDialog,
    setShowDeleteOrgDialog,
    setShowAddEditDialog,
    selectedOrganization,
    setSelectedOrganization,
    userDetail,
    manageOrganizationFetching,
    editHandleImageUpload,
    openEditDialogue,
  ] = useOutletContext();

  const defaultOrganizationId = userDetail?.data?.defaultOrganization;

  return (
    <>
      <div className='flex flex-col gap-6 lg:gap-4 xxl:gap-6'>
        <ScrollArea className='relative z-[1] min-h-[24.375rem] w-[calc(100vw-2.5rem)] max-w-[27.5rem] whitespace-nowrap rounded-md border md:min-h-[38.25rem] md:max-w-full lg:min-h-[calc(100vh-27.5625rem)] xxl:min-h-[calc(100vh-37.625rem)]'>
          <Table className='bg-white'>
            <TableHeader className='sticky top-0 rounded-t-lg bg-white'>
              <TableRow>
                <TableHead className='w-[8.4944rem] px-6 py-3.5 md:w-[11.25rem] md:px-[1.875rem] md:py-3.5 lg:w-[10.3987rem] lg:px-8 xxl:w-[13.4812rem] xxl:px-10 xxl:pb-3 xxl:pt-3.5'>
                  Name
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 xxl:px-10 xxl:pb-3 xxl:pt-3.5'>
                  Dashboards
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 xxl:px-10 xxl:pb-3 xxl:pt-3.5'>
                  Teams
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 xxl:px-10 xxl:pb-3 xxl:pt-3.5'>
                  Members
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 xxl:px-10 xxl:pb-3 xxl:pt-3.5'>
                  Data Sources
                </TableHead>
                <TableHead className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 xxl:px-10 xxl:pb-3 xxl:pt-3.5'>
                  Action
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className='pt-5'>
              {organizationFetching ? (
                <BaseLoader className='mt-[6.25rem]' />
              ) : search && organizations?.data?.count === 0 ? (
                <EmptyDashboardSearch />
              ) : (
                organizations?.data?.results?.map(organization => (
                  <TableRow
                    key={organization?.id}
                    onClick={() => {
                      setLocalStorageItem(SELECTED_ORGANIZATION_ID, organization?.organizationId);
                      navigate(generatePath(ROUTES.DASHBOARD, { organizationId: organization?.organizationId }));
                    }}
                  >
                    <TableCell className='px-6 py-3.5 font-medium md:w-[11.25rem] md:px-[1.875rem] md:py-3.5 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-3.5'>
                      <p id={`organization${organization?.id}`}>{truncateString(organization.name, 24)}</p>
                      {organization?.name?.length > 24 && (
                        <BaseToolTip
                          className='w-full'
                          id={`organization${organization?.id}`}
                          content={organization?.name}
                        />
                      )}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-3.5'>
                      {organization.dashboardsCount}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-3.5'>
                      {organization.teamCount}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-3.5'>
                      {organization.memberCount}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-3.5'>
                      {organization.dataSourcesCount}
                    </TableCell>
                    <TableCell className='px-6 py-3.5 md:px-[1.875rem] md:py-3.5 lg:px-8 lg:py-2.5 xxl:px-10 xxl:py-3.5'>
                      <div className={'flex gap-1.5'}>
                        <span
                          onClick={e => {
                            e.stopPropagation();
                            setSelectedOrganization(organization);
                            setShowAddEditDialog({ ...showAddEditDialog, [organization]: true });
                          }}
                          className={userDetail?.data?.isSubscribed ? '' : 'pointer-events-none'}
                        >
                          <EditIcon
                            strokeColor={userDetail?.data?.isSubscribed ? COLORS.DARK_PRIMARY : COLORS.TINT_GRAY}
                          />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
        <div className='mb-6 lg:mb-0'>
          {organizations?.data?.count > TOTAL_ITEMS_PER_PAGE && (
            <CustomPagination page={page} setPage={setPage} data={organizations} />
          )}
        </div>
      </div>
      {!isEmpty(selectedOrganization) && (
        <Dialog
          open={showAddEditDialog[selectedOrganization]}
          onOpenChange={isOpen => {
            setShowAddEditDialog({ ...showAddEditDialog, [selectedOrganization]: isOpen });
            openEditDialogue();
          }}
        >
          <AddEditOrgDialog
            dialogTitle='Edit Organization'
            formik={editFormik}
            imageRef={imageRef}
            uploadedLogo={uploadedLogoEdit}
            handleImageUpload={editHandleImageUpload}
            setUploadedLogo={setUploadedLogoEdit}
            setIsUploadedLogo={setIsUploadedLogoEdit}
            icon={
              <div className='h-3.5 w-3.5 lg:h-2.5 lg:w-2.5 xxl:h-3.5 xxl:w-3.5'>
                <EditIconWhite />
              </div>
            }
            isUploadedLogo={isUploadedLogoEdit}
            organizationLoading={isEditOrganizationLoading}
            handleDeleteBtn={handleDeleteBtn}
            organizationId={selectedOrganization?.organizationId}
            defaultOrganizationId={defaultOrganizationId}
            manageOrganizationFetching={manageOrganizationFetching}
          />
        </Dialog>
      )}
      <Dialog open={showDeleteOrgDialog} onOpenChange={setShowDeleteOrgDialog}>
        <DeleteDialog
          heading={selectedOrganization?.name}
          text={selectedOrganization?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={handleDeleteOrganizationBtn}
          isLoading={isDeleteOrgLoading}
        />
      </Dialog>
    </>
  );
}
