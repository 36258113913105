import { useNavigate } from 'react-router-dom';
import { Button } from '@shadcnComponent';
import { ChevronLeft } from 'lucide-react';
import { AuthDescription, OTPInputs, Loader } from '@common/components';
import { useOTPVerification } from '@pages/otpVerification';
import { Logo } from '@assets/svgs';

export function OTPVerification() {
  const {
    email,
    otp,
    setOtp,
    otpErrorMessage,
    showOtpError,
    countdown,
    handleOTPVerification,
    handleResend,
    isOtpExpire,
    isOTPLoading,
  } = useOTPVerification();

  const navigate = useNavigate();

  return (
    <div className='flex h-screen min-h-[42.5rem] overflow-y-auto'>
      <div className='relative flex-1'>
        <div className='absolute left-5 top-6 inline-flex flex-col gap-4 md:left-8 md:top-10 lg:left-7 lg:top-7 xxl:left-10 xxl:top-10'>
          <Logo />
          <span
            className='mt-2 inline-flex cursor-pointer items-center  gap-2 hover:opacity-[.7]'
            onClick={() => navigate(-1)}
          >
            <ChevronLeft /> Back
          </span>
        </div>
        <div className='h-available mx-auto flex max-w-[30rem] flex-col justify-center px-5 md:max-w-[25rem] md:px-0 lg:max-w-[15.5rem] xxl:max-w-[21.875rem]'>
          <h2>OTP Verification</h2>
          <p className='mb-8 mt-3 text-sm font-normal leading-[1.0588rem] md:mb-10 md:mt-3 md:text-sm md:leading-[1.0588rem] lg:mb-8 lg:text-xs lg:leading-[0.9075rem] xxl:mb-10 xxl:mt-4 xxl:text-sm xxl:leading-5'>
            Enter the 4 digit verification code received on your Email ID
          </p>
          <div className='flex flex-col gap-8 lg:gap-6'>
            <OTPInputs
              otp={otp}
              setOtp={setOtp}
              showOtpError={showOtpError}
              errorMessage={otpErrorMessage}
              handleOtpVerification={() =>
                handleOTPVerification({
                  payload: { email, otp },
                })
              }
            />
            <Button
              onClick={() =>
                handleOTPVerification({
                  payload: { email, otp },
                })
              }
              disabled={!otp}
            >
              Get Started {isOTPLoading ? <Loader /> : ''}
            </Button>
          </div>
          <div className='mt-[3.75rem] flex flex-col items-center gap-4 md:mt-20 md:gap-5 lg:mt-10 lg:gap-2'>
            <p className='text-center text-sm font-normal leading-[1.0588rem] text-colorSecondary md:text-base md:leading-[1.21rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-[1.0588rem]'>
              Code expires in <span className='font-bold text-darkPrimary'>{countdown} min</span>
            </p>
            <Button
              variant={isOtpExpire ? 'active' : 'link'}
              size='auto'
              disabled={!isOtpExpire}
              onClick={() =>
                handleResend({
                  payload: { email },
                })
              }
            >
              Resend
            </Button>
          </div>
        </div>
      </div>
      <AuthDescription />
    </div>
  );
}
