import { useRef, useState } from 'react';
import {
  Input,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  SelectGroup,
} from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { debounce } from 'lodash';
import { Search } from 'lucide-react';
import { TemplateListingTabContent } from '@pages/dashboard';
import { CREATE_DASHBOARD_TEMPLATE_ID, CREATE_DASHBOARD_TYPE_CLASS, TEMPLATE_TAB } from '@constants';

export function CreateDashboardStep4({
  templateListing,
  handleScroll,
  handleCategoryScroll,
  handleSelectedTemplate,
  selectedTemplateId,
  selectedTemplateImg,
  setCreateDashboardStep1,
  setTemplateName,
  selectedType,
  setSelectedType,
  selectedCategory,
  setSelectedCategory,
  templateCategory,
  selectedTemplateTab,
  setSelectedTemplateTab,
}) {
  const categoryRef = useRef();

  const [expandableSearch, setExpandableSearch] = useState(false);

  return (
    <div id='step-4' className='mt-4 md:mt-6 lg:mt-4 xxl:mt-6'>
      <div className='flex flex-col items-center justify-between gap-3 md:flex-row md:gap-2.5'>
        <div className='flex w-[14.375rem] flex-col gap-2 md:gap-1.5 lg:gap-1 xxl:w-[unset] xxl:gap-1.5'>
          <h2 className='text-sm font-semibold leading-[1.0625rem] text-midNightBlue md:text-lg md:leading-7 lg:text-xs lg:leading-[.9375rem] xxl:text-lg'>
            Select a Template
          </h2>
          <p className='text-xs font-normal leading-[.9375rem] text-slateGray md:text-sm md:leading-5 lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-5'>
            Make changes to your profile here.
          </p>
        </div>
        <div className='flex items-center gap-4'>
          <Select value={selectedCategory} onValueChange={setSelectedCategory}>
            <SelectTrigger
              className={`${['', '-1'].includes(selectedCategory) ? 'custom-select' : ''} border border-darkSecondary md:h-8 md:w-[8.25rem] lg:h-7 lg:w-[7.25rem] xxl:h-10 xxl:w-[10.25rem]`}
            >
              <SelectValue placeholder='Select Category' />
            </SelectTrigger>
            <SelectContent ref={categoryRef}>
              <SelectGroup className='h-[18.75rem] overflow-y-auto' onScroll={handleCategoryScroll}>
                {templateCategory?.pages
                  ?.flatMap(page => [{ id: '-1', name: 'Select Category' }, ...page.data.results])
                  ?.map(option => (
                    <SelectItem key={option?.id} value={option?.id}>
                      {option?.name}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select value={selectedType} onValueChange={setSelectedType}>
            <SelectTrigger
              className={`${['', '-1'].includes(selectedType) ? 'custom-select' : ''} border border-darkSecondary md:h-8 md:w-[7.25rem] lg:h-7 lg:w-[6.25rem] xxl:h-10 xxl:w-[9.25rem]`}
            >
              <SelectValue placeholder='Type' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='-1'>Type</SelectItem>
              <SelectItem value='NORMAL'>Normal</SelectItem>
              <SelectItem value='SLIDES'>Slides</SelectItem>
            </SelectContent>
          </Select>
          <div className='flex h-10 items-center gap-2 rounded-md border border-darkSecondary px-3.5 focus:!border-darkPrimary md:h-8 lg:h-7 xxl:h-10'>
            <span onClick={() => setExpandableSearch(true)} className='cursor-pointer'>
              <Search className=' xxl:h-4 xxl:w-4' height={16} width={16} />
            </span>
            <Input
              className={`focus:ring-offset-none max-w-full outline-none md:max-w-[106px] lg:max-w-[5.25rem] xxl:max-w-[106px] ${expandableSearch ? '' : 'hidden'}`}
              placeholder='Search'
              onChange={debounce(e => setTemplateName(e.target.value), 500)}
              leadIcon={<Search />}
              onBlur={e => !e.target.value?.length > 0 && setExpandableSearch(false)}
            />
          </div>
        </div>
      </div>
      <div
        id={CREATE_DASHBOARD_TEMPLATE_ID}
        className={cn(
          'mt-3 flex w-full flex-col gap-3 md:mt-6 md:h-[27.25rem] md:flex-row md:gap-3 lg:mt-3 lg:h-[17.8125rem] xxl:mt-6 xxl:h-[27.25rem] xxl:gap-6',
          CREATE_DASHBOARD_TYPE_CLASS
        )}
      >
        <Tabs value={selectedTemplateTab} onValueChange={value => setSelectedTemplateTab(value)}>
          <TabsList className='mb-3 h-[2.625rem] w-[19.125rem]'>
            <TabsTrigger value={TEMPLATE_TAB.SYSTEM_TEMPLATE} className='!w-[9.25rem]'>
              System Templates
            </TabsTrigger>
            <TabsTrigger value={TEMPLATE_TAB.MY_TEMPLATE} className='!w-[9.25rem]'>
              My Templates
            </TabsTrigger>
          </TabsList>
          <TabsContent value={TEMPLATE_TAB.SYSTEM_TEMPLATE}>
            <TemplateListingTabContent
              templateListing={templateListing}
              handleScroll={handleScroll}
              handleSelectedTemplate={handleSelectedTemplate}
              selectedTemplateId={selectedTemplateId}
              setCreateDashboardStep1={setCreateDashboardStep1}
            />
          </TabsContent>
          <TabsContent value={TEMPLATE_TAB.MY_TEMPLATE}>
            <TemplateListingTabContent
              templateListing={templateListing}
              handleScroll={handleScroll}
              handleSelectedTemplate={handleSelectedTemplate}
              selectedTemplateId={selectedTemplateId}
              setCreateDashboardStep1={setCreateDashboardStep1}
            />
          </TabsContent>
        </Tabs>
        <div className='h-[13.75rem] w-full rounded-lg shadow-sm md:h-full md:w-[18.3125rem] lg:w-[16.875rem] xxl:w-[21.8125rem]'>
          <img src={selectedTemplateImg} className='h-full w-full max-w-[unset] object-contain' />
        </div>
      </div>
    </div>
  );
}
