import {
  Dialog,
  ScrollArea,
  ScrollBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcnComponent';
import { BaseToolTip, DeleteDialog, GoalTrackingProgress } from '@common/components';
import { CreateEditGoalDialog, GoalActionPopover } from '@pages/goalTracking';
import { truncateString } from '@utils';
import { TIME_PERIOD } from '@constants';

export function GoalTrackTabularView({
  goalsListing,
  goalDetail,
  setGoalDetail,
  setEditGoalDetail,
  handleGoalDelete,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteGoalLoading,
  setDeleteGoalLoading,
  showEditDialog,
  setShowEditDialog,
  widgetListing,
  formik,
  dashboardsListing,
  isLoading,
  setSelectedDashboardId,
  setDashboardGoal,
}) {
  return (
    <div>
      <ScrollArea className='relative z-[1] h-full w-[calc(100vw-40px)] whitespace-nowrap rounded-md border xsm:w-[440px] md:w-full lg:w-auto'>
        <Table className='text-lg'>
          <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
            <TableRow>
              <TableHead className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                Name
              </TableHead>
              <TableHead className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                Dashboard
              </TableHead>
              <TableHead className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                Widget
              </TableHead>
              <TableHead className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                Progress
              </TableHead>
              <TableHead className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                Time Period
              </TableHead>
              <TableHead className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                Actions
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className='pt-5'>
            {goalsListing?.data?.results.map(goal => (
              <TableRow key={goal.id}>
                <TableCell className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                  <p id={`name-${goal?.id}`}>{truncateString(goal.name, 24)}</p>
                  {goal?.name?.length > 24 && <BaseToolTip id={`name-${goal?.id}`} content={goal?.name} />}
                </TableCell>
                <TableCell className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                  <p id={`dashboard-${goal?.id}`}>{truncateString(goal.dashboardName, 24)}</p>
                  {goal?.dashboardName?.length > 24 && (
                    <BaseToolTip id={`dashboard-${goal?.id}`} content={goal?.dashboardName} />
                  )}
                </TableCell>
                <TableCell className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                  <p id={`label-${goal?.id}`} className='inline-block'>
                    {truncateString(goal.widgetLabel, 10)}
                  </p>
                  {goal?.widgetLabel?.length > 10 && (
                    <BaseToolTip id={`label-${goal?.id}`} content={goal?.widgetLabel} />
                  )}
                </TableCell>
                <TableCell className='!px-[1.875rem] lg:!px-[1.125rem] xxl:!px-[1.875rem]'>
                  <GoalTrackingProgress goal={goal} />
                </TableCell>
                <TableCell className='!px-[1.875rem] text-sm font-medium leading-6 lg:!px-[1.125rem] lg:text-xs xxl:!px-[1.875rem] xxl:text-sm'>
                  {TIME_PERIOD.find(time => time.id === goal.periodInDays)?.name || 'N/A'}
                </TableCell>
                <TableCell className='!px-[1.875rem] lg:!px-[1.125rem] xxl:!px-[1.875rem]'>
                  <GoalActionPopover
                    goal={goal}
                    setShowDeleteDialog={setShowDeleteDialog}
                    showDeleteDialog={showDeleteDialog}
                    setGoalDetail={setGoalDetail}
                    showEditDialog={showEditDialog}
                    setShowEditDialog={setShowEditDialog}
                    setEditGoalDetail={setEditGoalDetail}
                    setDashboardGoal={setDashboardGoal}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DeleteDialog
          heading={goalDetail?.name}
          text={goalDetail?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleGoalDelete();
            setDeleteGoalLoading(true);
          }}
          isLoading={deleteGoalLoading}
        />
      </Dialog>
      <Dialog
        open={showEditDialog}
        onOpenChange={status => {
          setShowEditDialog(status);
          !status && setGoalDetail({});
        }}
      >
        <CreateEditGoalDialog
          title='Edit Goal'
          formik={formik}
          widgetListing={widgetListing}
          dashboardsListing={dashboardsListing}
          isLoading={isLoading}
          setSelectedDashboardId={setSelectedDashboardId}
        />
      </Dialog>
    </div>
  );
}
