import { Input, Label } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { ConnectorGuide } from '@common/components';

export function FormInput({
  id,
  labelText,
  placeholder,
  type = 'text',
  value = '',
  handleChange,
  showError,
  isMultipleEmail,
  isMultipleEmailMessage,
  labelClassName,
  className,
  inputClassName,
  showInfoIcon = false,
  infoContent,
  ...restProps
}) {
  return (
    <div id={id} className={cn('flex flex-col gap-2 lg:gap-1.5', className)}>
      {!!labelText && (
        <div className='flex items-center justify-between'>
          <Label htmlFor={id} className={`relative ${labelClassName}`}>
            {labelText}
            <ConnectorGuide showInfoIcon={showInfoIcon} infoContent={infoContent} />
          </Label>
          {isMultipleEmail && (
            <span className='text-xs font-normal leading-[0.9075rem] text-mediumGray'>{isMultipleEmailMessage}</span>
          )}
        </div>
      )}
      <Input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange}
        showError={showError}
        inputClassName={inputClassName}
        {...restProps}
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>{showError}</p>
      )}
    </div>
  );
}
