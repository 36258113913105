import { Checkbox } from '@shadcnComponent';

export function OAuthAccountListingForMultiple({ checkedIds, listing, linkedinDataSource, handleCheck }) {
  return (
    <div className='flex h-[9.5rem] flex-col overflow-y-auto md:h-[11.6875rem] lg:h-[8.1875rem] xxl:h-[11.6875rem]'>
      {listing?.map((item, index) => (
        <div
          key={index + 1}
          className=' flex items-center gap-2 border-b border-lightGray px-4 py-3.5 text-sm font-normal leading-[1.0588rem] md:gap-3 lg:gap-3 lg:py-3 lg:text-xs lg:leading-[0.9075rem] xxl:gap-3 xxl:py-3.5 xxl:text-sm xxl:leading-[1.0588rem]'
        >
          {item?.name && (
            <Checkbox
              id={`checkbox-${index + 1}`}
              checked={linkedinDataSource ? item.isChecked : checkedIds.includes(item?.id)}
              onCheckedChange={() => handleCheck(item, listing)}
            />
          )}
          <label htmlFor={`checkbox-${index + 1}`}>{item?.name}</label>
        </div>
      ))}
    </div>
  );
}
