import { useParams } from 'react-router-dom';
import { apiEndpoints, useGetQuery } from '@services';

export function useGoalWidgets(isGoalTrackingMode) {
  const { organizationId, dashboardId } = useParams();

  const { data: goalWidgets, isFetching: goalWidgetsFetching } = useGetQuery(
    'goal-widgets',
    apiEndpoints.GOALS_WIDGET(organizationId, dashboardId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isGoalTrackingMode,
    }
  );

  return { goalWidgets, goalWidgetsFetching };
}
