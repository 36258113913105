import { FormInput } from '@common/components';
import { NON_AUTH_CONNECTOR_INPUT_FIELDS } from '@constants';

export function NonAuthField({ nonAuthSourceName, nonAuthFields, handleNonAuthFields }) {
  return (
    <div className='mx-auto flex h-[9.6875rem] w-full flex-col justify-center gap-2.5 px-10 md:h-[12.3125rem] lg:h-[8.25rem] xxl:h-[12.3125rem]'>
      {NON_AUTH_CONNECTOR_INPUT_FIELDS[nonAuthSourceName]?.map(connector => (
        <div className='mx-auto flex w-full flex-col justify-center gap-1.5 px-10' key={connector.label}>
          <FormInput
            id={connector.name}
            labelText={connector.label}
            placeholder={connector.label}
            value={nonAuthFields[nonAuthSourceName][connector.name]}
            handleChange={e => handleNonAuthFields(nonAuthSourceName, connector.name, e.target.value)}
            showInfoIcon={!!nonAuthFields[nonAuthSourceName][connector.infoContent]}
            infoContent={nonAuthFields[nonAuthSourceName][connector.infoContent]}
          />
        </div>
      ))}
    </div>
  );
}
