import { FormInput } from '@common/components';
import { capitalizeSourceName } from '@utils';
import { BING_ADS_TEXT, UPPERCASE_DATA_SOURCES, WOO_COMMERCE_TEXT } from '@constants';

export function NameField({ dataSource, nameField, handleNameChange }) {
  const labelText = dataSource => {
    switch (capitalizeSourceName(dataSource?.sourceName)) {
    case UPPERCASE_DATA_SOURCES.WOO_COMMERCE:
      return 'Store Name';
    case UPPERCASE_DATA_SOURCES.BING_ADS:
      return 'Account Name';
    default:
      return 'Name';
    }
  };

  return (
    <div className='mx-auto flex h-[9.6875rem] w-full flex-col justify-center gap-1.5 px-10 md:h-[12.3125rem] lg:h-[8.25rem] xxl:h-[12.3125rem]'>
      <FormInput
        id='name'
        labelText={labelText(dataSource)}
        placeholder={`Enter ${labelText(dataSource).toLocaleLowerCase()}`}
        value={nameField}
        handleChange={handleNameChange}
        showInfoIcon={true}
        infoContent={
          capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.WOO_COMMERCE
            ? { text: WOO_COMMERCE_TEXT }
            : { text: BING_ADS_TEXT }
        }
      />
    </div>
  );
}
