import { apiEndpoints, useGetQuery } from '@services';

export function useStaticWidgets(isEditMode) {
  const { data: staticWidgets, isFetching: staticWidgetsFetching } = useGetQuery(
    'static-widgets',
    apiEndpoints.STATIC_WIDGETS,
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEditMode,
    }
  );

  return { staticWidgets, staticWidgetsFetching };
}
