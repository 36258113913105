import { parse, set } from 'date-fns';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';
import { COLORS, DATE_FORMAT, FIRST_PAGE, PAGE_REGEX, TIME_UNITS } from '@constants';
import { TrendArrow } from '@assets/svgs';

export const capitalizeString = string => {
  let lowerCaseString = string?.toLowerCase();
  return lowerCaseString?.charAt(0)?.toUpperCase() + lowerCaseString?.slice(1);
};

export const truncateString = (str, charLength, className = 'text-darkSecondary') => {
  if (str?.length > charLength) {
    return <span className={`${className}`}>{str?.slice(0, charLength)?.concat('...')}</span>;
  }
  return str || 'N/A';
};

export const formatWidgetName = string =>
  string
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word?.slice(1))
    .join(' ');

export const formatConnectorName = string => string.split('-').join('_').toUpperCase();

export const calculateDueDate = date => {
  if (date === null) {
    return 'N/A';
  } else {
    const dueDate = moment(moment(date).format(DATE_FORMAT.NOTIFICATION_DATE));
    const todayDate = moment().format(DATE_FORMAT.NOTIFICATION_DATE);
    const diffMinutes = dueDate.diff(todayDate, TIME_UNITS.MINUTES);

    return moment.duration(diffMinutes, TIME_UNITS.MINUTES).humanize(true);
  }
};

/**
 * Extracts the next page number from the given last page object.
 *
 * @param {Object} lastPage - The last page object that contains pagination data.
 * @param {Object} lastPage.data - The data object within the last page.
 * @param {string} lastPage.data.next - The URL or string containing the next page information.
 * @returns {string|undefined} The next page number as a string if available, otherwise undefined.
 */

export const getNextPage = lastPage => {
  if (lastPage?.data?.next) {
    const match = lastPage?.data?.next.match(PAGE_REGEX);
    const pageNumber = match ? match[1] : FIRST_PAGE;
    return pageNumber;
  }
};

/**
 * Handles infinite scroll event to fetch the next page of data.
 *
 * @param {Object} event - The scroll event object.
 * @param {boolean} hasNextPage - Flag indicating if there are more pages to fetch.
 * @param {boolean} isRefetching - Flag indicating if a refetch operation is in progress.
 * @param {Function} fetchNextPage - Function to call to fetch the next page of data.
 */

export const doInfiniteScroll = (event, hasNextPage, isRefetching, fetchNextPage) => {
  const target = event.target;
  const { scrollHeight, scrollTop, clientHeight } = target;

  if (scrollHeight - scrollTop <= clientHeight + 10 && hasNextPage && !isRefetching) fetchNextPage();
};

export const doInfiniteCommentsScroll = (event, hasNextPage, isRefetching, fetchNextPage) => {
  const target = event.target;
  const { scrollHeight, scrollTop, clientHeight } = target;

  if (scrollHeight + scrollTop <= clientHeight + 10 && hasNextPage && !isRefetching) fetchNextPage();
};

export const convertToTitleCase = string =>
  string
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word?.slice(1))
    .join(' ');

export const convertCamelCaseToReadable = str => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

/**
 * Transforms a comma-separated string of options into an array of objects suitable for a select input.
 *
 * @param {string} options - A comma-separated string of options.
 * @returns {Array<{value: string, label: string}>} An array of objects where each object represents an option
 *   with a `value` and a `label`. The `value` is the original option, and the `label` is a human-readable version
 *   of the option. If the input is empty or an empty string, an empty array is returned.
 */

export const transformedIntoSelectOptions = options => {
  if (isEmpty(options) || options === '""') return [];
  else {
    return options?.split(',')?.map(item => ({
      value: item,
      label: convertCamelCaseToReadable(item),
    }));
  }
};

/**
 * Transforms metrics and dimensions into an array of options suitable for a select table.
 *
 * @param {string} metrics - A comma-separated string of metrics.
 * @param {string} dimensions - A comma-separated string of dimensions.
 * @returns {Array<{value: string, label: string}>} An array of objects where each object represents an option
 *   with a `value` and a `label`. The `value` is the original metric or dimension, and the `label` is a
 *   human-readable version of the metric or dimension.
 */

export const transformedIntoSelectTableOptions = (metrics, dimensions) => {
  let metricsList = metrics?.split(',');
  let dimensionsList = dimensions?.split(',');

  let options = [...dimensionsList, ...metricsList];

  return options
    .filter(v => v !== '')
    ?.map(item => ({
      value: item,
      label: convertCamelCaseToReadable(item),
    }));
};

export const formatFileSize = bytes => {
  if (bytes < 1024 * 1024) {
    return Math.ceil(bytes / 1024) + ' KB';
  } else {
    return Math.ceil(bytes / (1024 * 1024)) + ' MB';
  }
};

export const fileSizeExceeds = (bytes, fileSizeLimit) =>
  Math.ceil(bytes / (1024 * 1024)) > fileSizeLimit ? true : false;

export const removeUnderScore = string => string.replace('_', ' ');

export const fileResize = (file, width, height) => {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      file?.name?.split('.').pop(),
      100,
      0,
      uri => {
        resolve(uri);
      },
      'file'
    );
  });
};

export const fileResizeWithoutCrop = (file, width, height, handleLogoUpload) => {
  Resizer.imageFileResizer(
    file,
    width,
    height,
    file?.name?.split('.').pop(),
    100,
    0,
    uri => {
      const formData = new FormData();
      formData.append('image', uri);
      handleLogoUpload({
        payload: formData,
      });
    },
    'file'
  );
  return file;
};

export const nonAuthConnectorFields = fields => {
  if (!isEmpty(fields)) {
    for (let key in fields) {
      if (fields[key] === '') {
        return true;
      }
    }
    return false;
  } else return true;
};

export const parseLinkedinListing = listing => {
  if (!isEmpty(listing?.data) && !isEmpty(listing?.data?.elements)) {
    const { elements } = listing.data;
    const list = elements.map(element => {
      const parts = element.account.split(':');
      return { name: parts[3], isChecked: false };
    });

    return list;
  }

  return [];
};

export const formatTime = timeString => {
  let [time, period] = timeString.replace(/\s+/g, '').split(/(AM|PM|am|pm)/);

  period = period?.toUpperCase();

  let [hours, minutes] = time.split(':');

  return `${hours}:${minutes} ${period}`;
};

export const validateCheckField = (condition, fieldName, errorMessage, errors) => {
  if (condition) {
    errors[fieldName] = errorMessage;
  }
};

export const getFile = async (file, fileName, setFieldValue, setIsUploadedLogo) => {
  const resizedFile = await fileResize(file, 600, 600);
  setFieldValue(fileName, resizedFile);
  setIsUploadedLogo(true);
  return resizedFile;
};

export const handleSave = (file, handleUpload) => {
  const formData = new FormData();
  formData.append('image', file, 'cropped_image.png');
  handleUpload({ payload: formData });
};

export const calculateDashboardHeight = height => {
  const ranges = [
    { max: 651, divisor: 35 },
    { max: 671, divisor: 34 },
    { max: 701, divisor: 32 },
    { max: 721, divisor: 31 },
    { max: 751, divisor: 30 },
    { max: 801, divisor: 29 },
    { max: 851, divisor: 27 },
    { max: 901, divisor: 26 },
    { max: 951, divisor: 25 },
    { max: 1001, divisor: 24 },
    { max: 1051, divisor: 23 },
    { max: 1101, divisor: 22.5 },
    { max: 1151, divisor: 22 },
    { max: 1201, divisor: 21.5 },
    { max: 1301, divisor: 21 },
    { max: 1351, divisor: 20 },
    { max: 1501, divisor: 19.5 },
    { max: 1601, divisor: 19 },
    { max: 1751, divisor: 18.5 },
    { max: 1901, divisor: 18 },
    { max: 2001, divisor: 18 },
    { max: 2101, divisor: 17.5 },
  ];

  const range = ranges.find(r => height < r.max);
  return range ? height / range.divisor : height / 17.5;
};

export const calculateSharedDashboardHeight = height => {
  const ranges = [
    { max: 751, divisor: 31 },
    { max: 801, divisor: 29 },
    { max: 826, divisor: 29.4 },
    { max: 851, divisor: 27 },
    { max: 876, divisor: 25.7 },
    { max: 901, divisor: 25.2 },
    { max: 916, divisor: 26.3 },
    { max: 926, divisor: 24.9 },
    { max: 951, divisor: 24.5 },
    { max: 976, divisor: 24.2 },
    { max: 1001, divisor: 24.3 },
    { max: 1026, divisor: 24.1 },
    { max: 1051, divisor: 23.9 },
    { max: 1076, divisor: 23.6 },
    { max: 1089, divisor: 23.1 },
    { max: 1101, divisor: 22.9 },
    { max: 1116, divisor: 22.7 },
    { max: 1126, divisor: 22.5 },
    { max: 1151, divisor: 22.3 },
    { max: 1176, divisor: 22 },
    { max: 1201, divisor: 21.8 },
    { max: 1226, divisor: 21.5 },
    { max: 1251, divisor: 21.3 },
    { max: 1276, divisor: 21 },
    { max: 1301, divisor: 20.8 },
    { max: 1326, divisor: 20.6 },
    { max: 1351, divisor: 20.4 },
    { max: 1376, divisor: 20.2 },
    { max: 1401, divisor: 20.05 },
    { max: 1426, divisor: 19.9 },
    { max: 1451, divisor: 19.75 },
    { max: 1476, divisor: 19.65 },
    { max: 1501, divisor: 19.55 },
    { max: 1526, divisor: 19.4 },
    { max: 1551, divisor: 19.3 },
    { max: 1576, divisor: 19.2 },
    { max: 1601, divisor: 19.1 },
    { max: 1651, divisor: 19 },
    { max: 1676, divisor: 18.9 },
    { max: 1701, divisor: 18.8 },
    { max: 1751, divisor: 18.7 },
    { max: 1776, divisor: 18.6 },
    { max: 1801, divisor: 18.45 },
    { max: 1851, divisor: 18.35 },
    { max: 1876, divisor: 18.25 },
    { max: 1926, divisor: 18.2 },
    { max: 1976, divisor: 18.1 },
    { max: 2001, divisor: 18 },
  ];

  const range = ranges.find(r => height < r.max);
  return range ? height / range.divisor : height / 17.8;
};

export const calculateFullViewHeight = height => {
  const ranges = [
    { max: 721, divisor: 46 },
    { max: 751, divisor: 33 },
    { max: 801, divisor: 29.5 },
    { max: 816, divisor: 29.4 },
    { max: 826, divisor: 29 },
    { max: 851, divisor: 27.5 },
    { max: 876, divisor: 26.2 },
    { max: 901, divisor: 26 },
    { max: 916, divisor: 26.5 },
    { max: 926, divisor: 25.3 },
    { max: 951, divisor: 24.8 },
    { max: 976, divisor: 24.5 },
    { max: 1001, divisor: 24.1 },
    { max: 1017, divisor: 24 },
    { max: 1026, divisor: 23.8 },
    { max: 1051, divisor: 23.2 },
    { max: 1076, divisor: 23 },
    { max: 1101, divisor: 22.9 },
    { max: 1126, divisor: 22.3 },
    { max: 1151, divisor: 22.1 },
    { max: 1176, divisor: 21.9 },
    { max: 1201, divisor: 21.6 },
    { max: 1226, divisor: 21.4 },
    { max: 1251, divisor: 21.2 },
    { max: 1276, divisor: 21.1 },
    { max: 1301, divisor: 20.8 },
    { max: 1326, divisor: 20.7 },
    { max: 1351, divisor: 20.55 },
    { max: 1376, divisor: 20.35 },
    { max: 1401, divisor: 20.25 },
    { max: 1426, divisor: 20.15 },
    { max: 1451, divisor: 20 },
    { max: 1476, divisor: 19.85 },
    { max: 1501, divisor: 19.75 },
    { max: 1526, divisor: 19.55 },
    { max: 1551, divisor: 19.45 },
    { max: 1576, divisor: 19.35 },
    { max: 1601, divisor: 19.25 },
    { max: 1626, divisor: 19.15 },
    { max: 1651, divisor: 19.05 },
    { max: 1676, divisor: 19 },
    { max: 1701, divisor: 18.9 },
    { max: 1751, divisor: 18.8 },
    { max: 1801, divisor: 18.7 },
    { max: 1826, divisor: 18.6 },
    { max: 1876, divisor: 18.5 },
    { max: 1901, divisor: 18.4 },
    { max: 1926, divisor: 18.3 },
    { max: 1976, divisor: 18.2 },
    { max: 2026, divisor: 18.1 },
  ];

  const range = ranges.find(r => height < r.max);
  return range ? height / range.divisor : height / 18;
};

export const NumbersIntoDays = string => {
  return string
    ?.replace('0', 'Sun')
    ?.replace('1', 'Mon')
    ?.replace('2', 'Tue')
    ?.replace('3', 'Wed')
    ?.replace('4', 'Thu')
    ?.replace('5', 'Fri')
    ?.replace('6', 'Sat');
};

export const convertTimeFormat = timeString => {
  if (timeString) {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
    const formattedTime = `${hours} : ${minutes} : 00 ${modifier.toLowerCase()}`;
    return formattedTime;
  }
};

export const calculateMonthlyOptions = date => {
  const now = date ? moment(date) : moment();
  const weekOfMonth = Math.floor(now.date() / 7);
  const weekNames = ['first', 'second', 'third', 'fourth'];
  const weekRanges = ['1-7', '8-14', '15-21', '22-31'];
  const currentWeek = weekNames[weekOfMonth - 1] || 'last';
  const currentWeekValue = weekRanges[weekOfMonth - 1] || '1-7';
  const currentDay = now.format('dddd');
  const dayOfMonth = now.format('DD');
  const options = [
    { name: `Monthly on the ${currentWeek} ${currentDay}`, id: currentWeekValue, value: 1 },
    { name: `Monthly on date ${dayOfMonth}`, id: dayOfMonth, value: 2 },
  ];
  return options;
};

export const convertToDayNumber = day => {
  switch (day) {
  case 'Sunday':
    return ['0'];
  case 'Monday':
    return ['1'];
  case 'Tuesday':
    return ['2'];
  case 'Wednesday':
    return ['3'];
  case 'Thursday':
    return ['4'];
  case 'Friday':
    return ['5'];
  case 'Saturday':
    return ['6'];
  default:
    break;
  }
};

export const addSecondsWithTime = timeString => {
  let [time, period] = timeString.replace(/\s+/g, '').split(/(AM|PM|am|pm)/);

  period = period?.toLowerCase();

  let [hours, minutes] = time.split(':');

  return `${hours}:${minutes}:${'00'} ${period}`;
};

export const handleTimeChange = (date, time) => {
  const selectedDate = date || new Date();
  const parsedTime = parse(addSecondsWithTime(time), 'hh:mm:ss a', new Date());

  const newDate = set(selectedDate, {
    hours: parsedTime.getHours(),
    minutes: parsedTime.getMinutes(),
    seconds: parsedTime.getSeconds(),
  });

  const now = moment();
  const isPast = moment(newDate).isBefore(now, 'minute');
  const isFuture = moment(newDate).isAfter(now, 'minute');
  const isToday = moment(newDate).isSame(now, 'minute');

  return { isPast, isFuture, isToday };
};

export const showSubscriptionPlan = (detail, membership) => {
  let text = '';
  let findMembership = membership?.find(m => m.isActiveMembership === true);

  if (!detail?.data?.isSubscribed) {
    text = 'Subscription';
  } else if (detail?.data?.hasTrialPeriod) {
    text = 'Free Trial Period';
  } else if (detail?.data?.membershipInterval) {
    text = findMembership?.nickname;
  } else if (detail?.data?.isSubscribed && !findMembership) {
    text = 'Enterprise Plan';
  }

  return text;
};

export const showSubscriptionText = (detail, membership) => {
  let text = '';
  let findMembership = membership?.find(m => m.isActiveMembership === true);

  if (!detail?.data?.isSubscribed) {
    text = 'Unlock Exclusive Benefits with Our Membership Plans!';
  } else if (detail?.data?.membershipInterval) {
    text = 'Upgrade your plan to enjoy wider range of features.';
  } else if (detail?.data?.isSubscribed && !findMembership) {
    text = 'World of tailored benefits designed just for you!';
  }

  return text;
};

export const showUpgradeBtn = (detail, membership) => {
  let showBtn = false;
  let findMembership = membership?.find(m => m.isActiveMembership === true);

  if (detail?.data?.isSubscribed && !findMembership) {
    showBtn = false;
  } else if (detail?.data?.isSubscribed) {
    showBtn = true;
  }
  return showBtn;
};

export const capitalizeSourceName = string => string?.toUpperCase()?.replace(' ', '_');

export const calculateTrend = (trend, type) => {
  const trendStr = trend.toString();
  const hasNegative = trendStr.includes('-');

  if (type === 'icon') {
    if (trend === 0) {
      return null;
    }
    return (
      <span className={hasNegative ? 'inline-block rotate-180' : ''}>
        <TrendArrow color={hasNegative ? COLORS.OFF_TRACK_COLOR : COLORS.COMPLETED} />
      </span>
    );
  }

  if (type === 'text') {
    if (trend === 0) {
      return 'On goal';
    }
    return hasNegative ? 'Below goal' : 'Above goal';
  }

  if (type === 'progress') {
    if (trend === 0) {
      return COLORS.ON_TRACK_COLOR;
    }
    return hasNegative ? COLORS.OFF_TRACK_COLOR : COLORS.COMPLETED;
  }
};

export const calculateBadgeColor = trend => {
  const trendStr = trend.toString();
  const hasNegative = trendStr.includes('-');

  const color = trend === 0 ? COLORS.ON_TRACK_COLOR : hasNegative ? COLORS.OFF_TRACK_COLOR : COLORS.COMPLETED;

  return {
    backgroundColor: `${color}20`,
    color,
  };
};

export const restrictedCharacterOnNumberField = e => {
  const invalidChars = ['e', 'E', '.', '+', '-'];

  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};

export const getLinkedinAccountIds = accounts => {
  return accounts.reduce((acc, account) => {
    if (account.isChecked) {
      acc.push(account.name);
    }
    return acc;
  }, []);
};
