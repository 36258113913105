import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, usePatchMutation, usePostMutation } from '@services';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils';
import {
  ERROR_MESSAGE,
  STATE,
  MIN_LENGTH_REQUIRED,
  MAX_LENGTH_REQUIRED,
  FIELD_REQUIRED,
  EMAIL,
  COUNT_DOWN_TIME_TEXT,
  INVITED_ORGANIZATION,
  LOGIN_STATE,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  ORGANIZATION_ID_BY_ONBOARDING,
} from '@constants';

export function useOnBoarding() {
  const navigate = useNavigate();

  const imageRef = useRef(null);

  const isTemporaryOrganization = getLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [isUploadedImage, setIsUploadedImage] = useState(null);

  const email = getLocalStorageItem(EMAIL);
  const invitedOrganization = getLocalStorageItem(INVITED_ORGANIZATION);
  const hmacValue = getLocalStorageItem('hmac');
  const hostValue = getLocalStorageItem('host');
  const timestampValue = getLocalStorageItem('timestamp');
  const codeValue = getLocalStorageItem('code');
  const shopValue = getLocalStorageItem('shop');
  const stateValue = getLocalStorageItem('state');
  const accessToken = getLocalStorageItem('accessToken');

  const OnBoardingInitialValues = { profileImage: '', firstName: '', lastName: '', email };

  const OnBoardingValidationSchema = Yup.object({
    profileImage: Yup.string().required(FIELD_REQUIRED('Profile image')),
    firstName: Yup.string()
      .min(3, `${MIN_LENGTH_REQUIRED('First name')}`)
      .max(60, `${MAX_LENGTH_REQUIRED('First name')}`)
      .required(FIELD_REQUIRED('First name')),
    lastName: Yup.string()
      .min(3, `${MIN_LENGTH_REQUIRED('Last name')}`)
      .max(60, `${MAX_LENGTH_REQUIRED('Last name')}`)
      .required(FIELD_REQUIRED('Last name')),
  });

  const handleOnBoardingSubmit = () => {
    let payload = {
      profileImage: uploadedImage?.id,
      firstName: values.firstName,
      lastName: values.lastName,
    };

    !uploadedImage?.id && delete payload['profileImage'];

    handleOnBoarding({ payload });
  };

  const { mutate: addUser } = usePatchMutation(
    `${apiEndpoints.OAUTH_ADD_USER}?code=${codeValue}&host=${hostValue}&timestamp=${timestampValue}&shop=${shopValue}&state=${stateValue}&hmac=${hmacValue}`,
    () => {
      navigate(`/oauth/shopify/redirect/?code=${codeValue}&shop=${shopValue}&state=${stateValue}`);
      setLocalStorageItem('showConnector', {
        2: true,
        dataSource: {
          id: 2,
          sourceName: 'SHOPIFY',
          description: 'Shopify shop connector',
          logo: {
            id: 540,
            image: 'https://stage.zendash.com/django-media/images/2024/03/shopify-logo-png-transparent.png',
          },
        },
      });
      removeLocalStorageItem('code');
      removeLocalStorageItem('shop');
      removeLocalStorageItem('state');
      removeLocalStorageItem('hmac');
      removeLocalStorageItem('host');
      removeLocalStorageItem('timestamp');
    },
    ({ response: { data } }) => data?.non_field_errors?.map(errMsg => toast.error(errMsg))
  );

  const { ...formik } = useFormikForm(OnBoardingInitialValues, handleOnBoardingSubmit, OnBoardingValidationSchema);

  const { values, resetForm } = formik;

  const { mutate: handleImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedImage(resp?.data);
      setIsUploadedImage(false);
      imageRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedImage(false);
      imageRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleOnBoarding, isLoading: onBoardingLoading } = usePatchMutation(
    apiEndpoints.USER_ONBOARDING,
    resp => {
      setLocalStorageItem(STATE, resp?.data?.state);
      removeLocalStorageItem(EMAIL);
      removeLocalStorageItem(COUNT_DOWN_TIME_TEXT);
      setLocalStorageItem('defaultOrganizationId', resp?.data?.defaultOrganization);
      if (isTemporaryOrganization) {
        setLocalStorageItem(ORGANIZATION_ID_BY_ONBOARDING, isTemporaryOrganization);
      }
      if (
        accessToken &&
        hmacValue &&
        hostValue &&
        timestampValue &&
        codeValue &&
        shopValue &&
        stateValue &&
        resp?.data?.state === LOGIN_STATE.ON_BOARDED
      ) {
        addUser({});
      } else window.location.reload();
    },
    () => toast.error(ERROR_MESSAGE)
  );

  return {
    formik,
    imageRef,
    uploadedImage,
    setUploadedImage,
    setIsUploadedImage,
    handleOnBoarding,
    isUploadedImage,
    invitedOrganization,
    onBoardingLoading,
    handleImageUpload,
  };
}
