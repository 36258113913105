import { Badge } from '@shadcnComponent';
import { Progressbar } from '@common/components';
import { calculateBadgeColor, calculateTrend } from '@utils';
import { GoalTrackingIcon } from '@assets/svgs';

export function GoalTrackingProgress({ goal, className }) {
  return (
    <div className={`flex w-[17.0625rem] flex-col gap-2 lg:w-[12.5rem] xxl:w-[17.0625rem] xxl:gap-2 ${className}`}>
      <div className='flex items-center justify-between'>
        <span className='text-sm font-medium leading-[1.1744rem] lg:text-xs xxl:text-sm'>{goal.achieved}</span>
        <div className='flex items-center gap-[0.3125rem]'>
          <GoalTrackingIcon />
          <span className='text-sm font-medium leading-[1.1744rem] text-darkSecondary/50 lg:text-xs xxl:text-sm'>
            {goal.target}
          </span>
        </div>
      </div>
      <Progressbar
        progressValue={(goal.achieved * 100) / goal.target}
        progressColor={calculateTrend(goal.growthTrend, 'progress')}
      />
      <div className='flex items-center justify-between'>
        <div className='flex items-center text-sm font-medium leading-[1.1744rem] lg:text-xs xxl:text-sm'>
          <Badge
            className={'mr-[0.3125rem] h-[1.4375rem] rounded lg:h-5 lg:px-1 xxl:h-[1.4375rem]'}
            style={calculateBadgeColor(goal.growthTrend)}
          >
            <span>{calculateTrend(goal.growthTrend, 'icon')}</span> {goal.growthTrend.toFixed(2)}%
          </Badge>
          <span className='text-sm font-medium leading-[1.0588rem] tracking-[3%] opacity-[.5] lg:text-[0.625rem] xxl:text-sm'>
            {calculateTrend(goal.growthTrend, 'text')}
          </span>
        </div>
        <span className='text-sm font-medium leading-[1.0588rem] text-darkSecondary/50 lg:text-[0.625rem] xxl:text-sm'>
          {Math.floor(goal.remainingDays) === 0 ? 'Completed' : `${Math.floor(goal.remainingDays)} day(s) left`}
        </span>
      </div>
    </div>
  );
}
