import { Checkbox } from '@shadcnComponent';

export function OAuthUrlListing({ listing, checkedId, setCheckedId }) {
  return (
    <div className='flex h-[9.5rem] flex-col overflow-y-auto md:h-[11.6875rem] lg:h-[8.1875rem] xxl:h-[11.6875rem]'>
      {listing?.data?.results?.map(oauth => (
        <div
          key={oauth?.id}
          className=' flex items-center gap-2 border-b border-lightGray px-4 py-3.5 text-sm font-normal leading-[1.0588rem] md:gap-3 lg:gap-3 lg:py-3 lg:text-xs lg:leading-[0.9075rem] xxl:gap-3 xxl:py-3.5 xxl:text-sm xxl:leading-[1.0588rem]'
        >
          {oauth?.label && (
            <Checkbox
              id={`checkbox-${oauth?.id}`}
              checked={checkedId === oauth?.id}
              onCheckedChange={() => setCheckedId(prev => (prev === oauth?.id ? null : oauth.id))}
            />
          )}
          <label htmlFor={`checkbox-${oauth?.id}`}>{oauth?.label}</label>
        </div>
      ))}
    </div>
  );
}
