export function ChatbotIcon({ className }) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <path
        d='M15.9484 34.1475C15.5913 34.1403 15.5406 33.966 15.5158 33.7663C15.404 32.8555 15.3031 31.9448 15.175 31.0373C14.9571 29.4956 14.6591 27.9715 14.2103 26.4746C13.3646 23.6475 11.551 21.7727 8.72121 20.955C7.22958 20.5244 5.69696 20.2362 4.18256 19.8836C4.13963 19.8738 4.09474 19.8745 4.05246 19.8667C3.86706 19.8322 3.6693 19.76 3.72135 19.5414C3.74997 19.4217 3.9165 19.278 4.0466 19.2507C4.80185 19.0893 5.57076 18.9904 6.32341 18.8103C7.23413 18.5949 8.14485 18.3588 9.03541 18.0693C11.6134 17.2327 13.2898 15.4913 14.127 12.9101C14.6851 11.1895 14.9948 9.42005 15.2387 7.63634C15.3422 6.88109 15.4241 6.12324 15.5152 5.36669C15.538 5.17999 15.5848 5.00826 15.8183 5.00305C16.0519 4.99785 16.1046 5.15983 16.1306 5.35238C16.3192 6.74188 16.4558 8.14179 16.716 9.51568C16.9537 10.7837 17.2738 12.0349 17.6743 13.2613C18.4633 15.6448 20.1397 17.1801 22.4881 18.014C24.0975 18.5845 25.7628 18.9143 27.4372 19.2116C27.4912 19.2214 27.5621 19.2116 27.5979 19.2415C27.7091 19.3372 27.8073 19.4478 27.9108 19.5531C27.8015 19.6572 27.7072 19.827 27.581 19.855C26.9558 19.9935 26.3216 20.0846 25.6945 20.2108C24.3505 20.4808 23.0274 20.8216 21.7647 21.372C19.7676 22.2424 18.4471 23.7353 17.6912 25.7584C17.0725 27.414 16.7505 29.1372 16.4994 30.876C16.3602 31.8375 16.2574 32.8048 16.1221 33.7669C16.1052 33.9159 15.9959 34.0518 15.9484 34.1475Z'
        fill='white'
      />
      <path
        d='M27.2433 1.85872C27.3174 1.98427 27.4267 2.10071 27.4592 2.23602C27.603 2.83579 27.6986 3.44858 27.86 4.04315C28.2054 5.31166 29.0179 6.15017 30.2773 6.54308C30.6136 6.64782 30.9564 6.73173 31.2973 6.82215C31.4833 6.87159 31.6785 6.91713 31.6876 7.15847C31.6941 7.40957 31.4996 7.4421 31.3084 7.48373C30.9753 7.56049 30.6448 7.64766 30.317 7.74394C29.0335 8.11668 28.2112 8.95585 27.8613 10.2393C27.7077 10.802 27.6108 11.3803 27.4892 11.9515C27.4482 12.1466 27.4404 12.3776 27.1639 12.3743C26.8874 12.3711 26.8822 12.1564 26.8387 11.9534C26.7177 11.3595 26.6064 10.7617 26.4431 10.1795C26.0964 8.94219 25.2943 8.11993 24.0577 7.73938C23.6889 7.62619 23.3096 7.54423 22.9466 7.41803C22.8263 7.37509 22.648 7.20986 22.663 7.13375C22.6877 7.0108 22.8321 6.87355 22.9596 6.8189C23.1802 6.72523 23.4247 6.6888 23.6609 6.6322C25.2742 6.26271 26.2213 5.2414 26.5772 3.64699C26.6819 3.18447 26.7469 2.71415 26.8588 2.25358C26.8926 2.11177 27.0071 1.98492 27.0852 1.85156L27.2433 1.85872Z'
        fill='white'
      />
    </svg>
  );
}
