import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { ROUTES } from '@routes';
import { apiEndpoints, usePostMutation } from '@services';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils';
import {
  COUNT_DOWN_TIME_TEXT,
  EMAIL,
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  IS_OTP_EXPIRE,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  SEND_OTP_MESSAGE,
  TERMS_AND_CONDITIONS_REQUIRED,
} from '@constants';

export function useExpressLogin() {
  const navigate = useNavigate();

  const isTemporaryOrganization = getLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
  const loginInitialValues = { email: '', termsAndPrivacy: false };

  const loginValidationSchema = Yup.object({
    email: Yup.string().email(INVALID_EMAIL_ADDRESS).required(FIELD_REQUIRED('Email')),
    termsAndPrivacy: Yup.bool().oneOf([true], TERMS_AND_CONDITIONS_REQUIRED),
  });

  const handleSubmit = () => {
    handleExpressLogin({
      payload: {
        email: values.email,
      },
    });
  };

  const { ...formik } = useFormikForm(loginInitialValues, handleSubmit, loginValidationSchema);

  const { values, setErrors } = formik;

  const { mutate: handleExpressLogin, isLoading: isExpressLoading } = usePostMutation(
    apiEndpoints.EXPRESS_LOGIN,
    () => {
      navigate(ROUTES.OTP_VERIFICATION, { state: isTemporaryOrganization });
      setLocalStorageItem(EMAIL, values.email);
      removeLocalStorageItem(COUNT_DOWN_TIME_TEXT);
      setLocalStorageItem(IS_OTP_EXPIRE, false);
      toast.success(SEND_OTP_MESSAGE);
    },
    ({ response: { data } }) => {
      setErrors({
        email: data.email[0] && data.email[0],
      });
    }
  );

  return { formik, isExpressLoading };
}
