import { useContext } from 'react';
import { ScrollArea } from '@shadcnComponent';
import { BaseLoader, BaseToolTip, CustomWidgetAccordion } from '@common/components';
import { useCustomWidgets } from '@pages/dashboard';
import { AppContext } from '@useContext';
import { formatWidgetName, truncateString } from '@utils';
import { WidgetsBulletIcon } from '@assets/svgs';

export function DashboardCustomWidgets({ handleOnDrag, isEditMode, AddWidgetsOnClick }) {
  const { customWidgets, customWidgetsFetching } = useCustomWidgets(isEditMode);
  const { isMobileScreen } = useContext(AppContext);

  return (
    <ScrollArea className='h-[calc(100vh-22rem)]'>
      {customWidgetsFetching ? (
        <BaseLoader />
      ) : (
        <div className='flex flex-col md:gap-6 lg:gap-3 xxl:gap-6'>
          {customWidgets?.data?.results?.map(customWidget => (
            <CustomWidgetAccordion
              key={customWidget?.id}
              accordionTitle={customWidget?.accountName}
              titleImage={
                <div className='h-[1.875rem] w-[1.875rem] md:h-6 md:w-6 lg:h-5 lg:w-5 xxl:h-8 xxl:w-8'>
                  <img src={customWidget?.connector?.logo?.image} />
                </div>
              }
            >
              <div className='flex flex-col gap-6 md:gap-6 lg:gap-4 xxl:gap-[1.625rem]'>
                {customWidget?.dataSourceWidget?.map(({ id, widget }) => (
                  <div
                    key={id}
                    className='flex cursor-pointer gap-3 md:gap-4 lg:gap-2 xxl:gap-3'
                    draggable
                    onDragStart={e => handleOnDrag(e, widget, customWidget)}
                    onClick={e => isMobileScreen && AddWidgetsOnClick(widget, customWidget, e)}
                  >
                    <div className='md:mt-1.5 lg:mt-0 xxl:mt-1.5'>
                      <div className='h-6 w-6 md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4'>
                        <WidgetsBulletIcon />
                      </div>
                    </div>
                    <div className='flex gap-2'>
                      <div className='h-6 w-6 md:h-7 md:w-7 lg:h-[1.125rem] lg:w-[1.125rem] xxl:h-7 xxl:w-7'>
                        <img src={widget?.icon?.image} />
                      </div>
                      <div className='flex flex-col gap-1.5 md:gap-1 lg:gap-[.3125rem] xxl:gap-[.5625rem]'>
                        <h3 className='text-base font-normal leading-5 md:text-sm md:leading-4 lg:text-xs lg:leading-[.875rem] xxl:text-sm xxl:leading-4'>
                          {formatWidgetName(widget?.type)}
                        </h3>
                        <p
                          id={`widget-${widget.id}`}
                          className='text-sm font-light leading-[.875rem] md:text-xs md:leading-[.875rem] lg:text-[.625rem] lg:leading-3 xxl:text-xs xxl:leading-[.875rem]'
                        >
                          {truncateString(widget?.label, 15)}
                        </p>
                        {widget?.label?.length > 15 && (
                          <BaseToolTip id={`widget-${widget.id}`} content={widget?.label} />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CustomWidgetAccordion>
          ))}
        </div>
      )}
    </ScrollArea>
  );
}
