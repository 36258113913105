import { buttonVariants } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

function Calendar({ className, classNames, showOutsideDays = true, ...props }) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('px-[0.9375rem] py-[0.2188rem] lg:pl-0 lg:pr-2.5 xxl:px-[0.9375rem]', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 md:space-y-3',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-base leading-6 md:text-sm font-medium lg:text-xs xxl:text-sm',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-[1.875rem] w-[1.875rem] md:h-7 md:w-7 lg:h-6 lg:w-6 xxl:h-6 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex gap-2 md:gap-0',
        head_cell:
          'text-muted-foreground rounded-md lg:w-[1.6875rem] xxl:w-9 font-normal text-sm lg:text-xs xxl:text-sm',
        row: 'flex w-full mt-1 md:mt-2 gap-2 md:gap-0',
        cell: 'h-7 w-[1.6875rem] xxl:h-9 xxl:w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-start)]:rounded-l-md [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-darkPrimary/60 [&:has([aria-selected])]:bg-darkPrimary first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md  focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          '!h-9 w-9 lg:!h-7 lg:w-[1.6875rem] xxl:!h-9 xxl:w-9 p-0 font-normal aria-selected:opacity-100'
        ),
        day_range_start: 'day-range-start',

        day_range_end: 'day-range-end',
        day_selected:
          'bg-darkPrimary text-white hover:bg-darkPrimary hover:text-primary-foreground focus:bg-darkPrimary focus:text-primary-foreground',
        day_today: 'bg-darkPrimary text-white',
        day_outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-darkPrimary/50 aria-selected:text-white aria-selected:opacity-50',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-darkPrimary aria-selected:text-white',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className='h-4 w-4' />,
        IconRight: () => <ChevronRight className='h-4 w-4' />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
