import { Mention, MentionsInput } from 'react-mentions';
import CustomSuggestionComponent from './CustomSuggestionComponent';
import { COLORS } from '@constants';

const styless = {
  control: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      outline: 'none',
      border: `1px solid ${COLORS.LIGHT_GREY}`,
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 5,
      borderRadius: '4px',
      maxHeight: '300px',
      overflow: 'auto',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const TaggedUsers = ({ users, setFieldValue, values }) => {
  return (
    <div>
      <section>
        <MentionsInput
          placeholder='Type your comment here'
          value={values.text}
          onChange={e => setFieldValue('text', e.target.value)}
          allowSuggestionsAboveCursor={true}
          forceSuggestionsAboveCursor={true}
          style={styless}
          className='h-auto min-h-[7.5rem] border border-lightGray'
        >
          <Mention
            markup='@[__display__](user:__id__)'
            trigger='@'
            style={{ backgroundColor: '#dcdcdc' }}
            data={users}
            renderSuggestion={suggestion => {
              return <CustomSuggestionComponent user={suggestion} />;
            }}
          />
        </MentionsInput>
      </section>
    </div>
  );
};

export default TaggedUsers;
