import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { SUCCESSFUL_MESSAGE } from '@constants';

export function useDomainTab() {
  const [domainId, setDomainId] = useState(null);
  const [showAddDomain, setShowAddDomain] = useState(false);
  const [deleteDomainId, setDeleteDomainId] = useState(null);
  const [accordionItem, setAccordionItem] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState({});
  const [deleteDomainLoading, setDeleteDomainLoading] = useState(false);
  const [page, setPage] = useState(1);

  const domainInitialValues = {
    emailDomain: '',
  };

  const validationSchema = Yup.object({
    emailDomain: Yup.string(),
  });

  const handleSubmit = () => {
    let payload = { emailDomain: values.emailDomain };

    handleAddDomain({ payload });
  };

  const { data: emailListing, refetch: emailDomainDetailRefetch } = useGetQuery(
    'email-listing',
    apiEndpoints.WHITE_LABEL_EMAIL_LIST,
    { page }
  );

  const { mutate: handleAddDomain, isLoading: addDomainLoading } = usePostMutation(
    apiEndpoints.WHITE_LABEL_EMAIL,
    () => {
      setShowAddDomain(false);
      emailDomainDetailRefetch();
      resetForm();
    },
    ({ response: { data } }) => setErrors({ emailDomain: data.detail[0] && data.detail[0] })
  );

  const { mutate: handleAddDomainValidate, isLoading: addDomainValidateLoading } = usePostMutation(
    apiEndpoints.WHITE_LABEL_EMAIL_VALIDATE(domainId),
    () => {
      emailDomainDetailRefetch();
      setDomainId(null);
    },
    ({ response: { data } }) => setErrors({ emailDomain: data.detail[0] && data.detail[0] })
  );

  const { mutate: handleDeleteDomain } = useDeleteMutation(
    apiEndpoints.DELETE_DOMAIN(deleteDomainId),
    () => {
      setShowDeleteDialog({});
      emailDomainDetailRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Domain deleted'));
      setDeleteDomainId(null);
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteDomainLoading(false);
      setShowDeleteDialog({});
    }
  );

  const { ...formik } = useFormikForm(domainInitialValues, handleSubmit, validationSchema);
  const { values, resetForm, setErrors } = formik;

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    toast.success('Copied');
  };

  useEffect(() => {
    domainId && handleAddDomainValidate({ payload: {} });
  }, [domainId]);

  useEffect(() => {
    page && emailDomainDetailRefetch();
  }, [page]);

  return {
    formik,
    emailListing,
    handleAddDomain,
    addDomainLoading,
    showAddDomain,
    setShowAddDomain,
    handleAddDomainValidate,
    addDomainValidateLoading,
    setDomainId,
    accordionItem,
    setAccordionItem,
    copyToClipboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDomainLoading,
    setDeleteDomainLoading,
    setDeleteDomainId,
    handleDeleteDomain,
    page,
    setPage,
  };
}
