import { DialogContent, DialogHeader, DialogTitle, Button } from '@shadcnComponent';
import { BaseToolTip, FormInput, ImageCrop, ImageUpload, Loader } from '@common/components';
import { getFile, handleSave } from '@utils';
import { CREATED_ORGANIZATION } from '@constants';
import { OrganizationIcon } from '@assets/svgs';

export function AddEditOrgDialog({
  dialogTitle,
  formik,
  imageRef,
  uploadedLogo,
  icon,
  handleDeleteBtn,
  isUploadedLogo,
  organizationLoading,
  organizationId,
  handleImageUpload,
  setIsUploadedLogo,
  defaultOrganizationId,
  manageOrganizationFetching,
}) {
  const { initialValues, values, handleChange, setFieldValue, touched, errors, submitForm } = formik;

  return (
    <DialogContent
      id='org-modal'
      className='w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:h-[27.1875rem] md:max-w-[37.0625rem] md:p-10 lg:h-[21.25rem] lg:max-w-[24.625rem] lg:p-8 xxl:h-[27.1875rem] xxl:max-w-[37.0625rem] xxl:p-10'
    >
      <DialogHeader>
        <DialogTitle className='text-start text-lg font-bold leading-[1.375rem] md:text-2xl md:leading-7 lg:text-lg lg:leading-[1.375rem] xxl:text-2xl xxl:leading-7'>
          {isUploadedLogo ? 'Edit Image' : dialogTitle}
        </DialogTitle>
      </DialogHeader>
      {!isUploadedLogo && (
        <div className='flex flex-col gap-6 md:mx-16 lg:mx-[1.6875rem] lg:gap-4 xxl:mx-[3.9375rem] xxl:gap-6'>
          <ImageUpload
            id='logo'
            icon={icon}
            imageRef={imageRef}
            placeHolderIcon={
              <OrganizationIcon className='h-[3.1875rem] w-[3.3125rem] md:h-[3.5rem] md:w-[3.625rem] lg:h-[2.625rem] lg:w-[2.625rem] xxl:h-[3.5rem] xxl:w-[3.625rem] ' />
            }
            className='mt-6 h-[6.25rem] w-[6.25rem] md:mb-1.5 md:mt-[1.875rem] md:h-[6.75rem] md:w-[6.75rem] lg:mb-0 lg:mt-6 lg:h-20 lg:w-20 xxl:mb-1.5 xxl:mt-[1.875rem] xxl:h-[6.75rem] xxl:w-[6.75rem]'
            profileImage={uploadedLogo?.image || values?.logo}
            handleChange={e => getFile(e.target.files[0], 'logo', setFieldValue, setIsUploadedLogo)}
            inputWrapperClassName='h-[1.875rem] w-[1.875rem] lg:w-6 lg:h-6 xxl:h-[1.875rem] xxl:w-[1.875rem]'
            showSkeletonLoader={manageOrganizationFetching || isUploadedLogo}
          />
          <div id='org-input-div'>
            <FormInput
              id='organizationName'
              labelText='Organization Name'
              placeholder='Organization Name'
              value={manageOrganizationFetching ? '' : values.organizationName}
              handleChange={handleChange}
              showError={touched.organizationName && errors.organizationName}
              labelClassName='text-sm md:text-sm xxl:text-base leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-base'
              inputClassName='h-10 md:h-10'
              className='gap-1'
            />
          </div>
          <div className='flex flex-col-reverse gap-3 md:flex-row md:justify-end md:gap-6 lg:mt-2 lg:gap-[2.125rem] xxl:ml-auto xxl:mt-0 xxl:gap-6'>
            <Button
              className={`w-full border-errorColor md:w-[11.25rem] lg:w-[7.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[11.25rem] ${dialogTitle === CREATED_ORGANIZATION ? 'hidden' : 'block'}`}
              variant='outline'
              onClick={defaultOrganizationId !== Number(organizationId) && handleDeleteBtn}
              id='deleteOrganizationBtn'
            >
              Delete Organization
            </Button>
            {defaultOrganizationId === Number(organizationId) && (
              <BaseToolTip id='deleteOrganizationBtn' content='Default organization deletion is not allowed' />
            )}
            <Button
              id='create-org-button'
              onClick={submitForm}
              className='w-full md:w-[11.25rem] lg:w-[7.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[11.25rem]'
              disabled={isUploadedLogo || organizationLoading}
            >
              {dialogTitle === CREATED_ORGANIZATION ? 'Create' : 'Save'}
              {organizationLoading ? <Loader /> : ''}
            </Button>
          </div>
        </div>
      )}
      {isUploadedLogo && (
        <ImageCrop
          uploadedImage={values?.logo}
          setIsUploaded={setIsUploadedLogo}
          handleCancel={() => {
            setIsUploadedLogo(false);
            setFieldValue('logo', initialValues.logo);
            imageRef.current.value = initialValues.logo;
          }}
          imageRef={imageRef}
          handleSave={file => handleSave(file, handleImageUpload)}
        />
      )}
    </DialogContent>
  );
}
