/* eslint-disable indent */
import { CategoryScale } from 'chart.js';
// eslint-disable-next-line import/no-named-as-default
import Chart from 'chart.js/auto';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import { EmptyWidget } from '@common/widgets';
import { COLORS, DATE_FORMAT, DEFAULT_VALUE, INVALID_DATE, THEME_STYLE } from '@constants';

Chart.register(CategoryScale);

export function PieChartWidget({ widget, isComparisonFilterApply, isSharedDashboard, isWidgetComparisonFilterApply }) {
  const options = {
    responsive: true,
    animation: isSharedDashboard || isWidgetComparisonFilterApply ? false : true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color:
            widget?.themeColor === DEFAULT_VALUE
              ? widget?.themeStyle === THEME_STYLE.DARK
                ? COLORS.WHITE_COLOR
                : COLORS.DARK_SECONDARY
              : widget.themeStyle === THEME_STYLE.DARK
                ? COLORS.WHITE_COLOR
                : COLORS.COLOR_PRIMARY,
        },
      },
    },
  };

  const labels = widget?.widgetData?.data?.map(xAxis => {
    return moment(xAxis?.label).format(DATE_FORMAT.BILLING_DATE) === INVALID_DATE
      ? xAxis?.label
      : moment(xAxis?.label).format(DATE_FORMAT.BILLING_DATE);
  });

  const data = {
    labels,
    datasets: [
      {
        data: widget?.widgetData?.data?.map(xAxis => xAxis?.data),
        backgroundColor:
          widget?.themeColor !== DEFAULT_VALUE
            ? widget?.widgetData?.data
                ?.map((xAxis, index) => `${`${widget.foreground}${100 / (index + 1) - 1}`.slice(0, 9)}`)
                .reverse()
            : [
                'rgba(54, 162, 235)',
                'rgba(255, 99, 132)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)',
              ],
      },
    ],
  };

  const comparisonData = isComparisonFilterApply && {
    labels,
    datasets: [
      {
        data: widget?.widgetData?.data?.map(xAxis => xAxis?.data),
        backgroundColor:
          widget?.themeColor !== DEFAULT_VALUE
            ? widget?.widgetData?.data
                ?.map((xAxis, index) => `${`${widget.foreground}${100 / (index + 1) - 1}`.slice(0, 9)}`)
                .reverse()
            : [
                'rgba(54, 162, 235)',
                'rgba(255, 99, 132)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)',
              ],
      },
    ],
  };

  const comparisonData2 = isComparisonFilterApply && {
    labels,
    datasets: [
      {
        data: widget?.widgetData?.data?.map(xAxis => xAxis?.data2),
        backgroundColor:
          widget?.themeColor !== DEFAULT_VALUE
            ? widget?.widgetData?.data?.map(
                (xAxis, index) => `${`${widget.foreground}${100 / (index + 1) - 1}`.slice(0, 9)}`
              )
            : [
                'rgba(54, 162, 235)',
                'rgba(255, 99, 132)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)',
              ],
      },
    ],
  };

  const nullOptions = {
    responsive: true,
    animation: isSharedDashboard ? false : true,
    maintainAspectRatio: false,
    plugins: {
      legend: true,
      tooltip: {
        enabled: false,
      },
    },
  };

  const nullData = {
    labels: labels?.length > 0 ? labels : ['No Data'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#D6D6DA'],
      },
    ],
  };

  const widgetData = widget?.widgetData?.data;
  const hasValidData = !isEmpty(widgetData) && widgetData[0]?.label !== null;

  const allDataIsNull = widgetData?.every(item => item.data === null);

  const pieChartWidget = dataSet => (
    <Pie
      data={hasValidData && !allDataIsNull ? dataSet : nullData}
      options={hasValidData && !allDataIsNull ? options : nullOptions}
    />
  );

  return isEmpty(widgetData) ? (
    <EmptyWidget widget={widget} />
  ) : (
    <>
      {isComparisonFilterApply ? (
        <div className='flex h-full w-full flex-col items-center justify-between gap-1 overflow-y-auto scrollbar-none sm:flex-row'>
          <div className='flex h-full w-full items-center justify-center border-b-2 pb-2 sm:border-b-0 sm:border-r-2 sm:pb-0'>
            {pieChartWidget(comparisonData2)}
          </div>
          <div className='flex h-full w-full items-center justify-center'>{pieChartWidget(comparisonData)}</div>
        </div>
      ) : (
        pieChartWidget(data)
      )}
    </>
  );
}
