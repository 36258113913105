import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { SUCCESSFUL_MESSAGE } from '@constants';

export function useInvitesTab() {
  const { organizationId } = useParams();
  const [resendLoading, setResendLoading] = useState({});

  const [page, setPage] = useState(1);

  const {
    data: inviteListing,
    refetch: inviteListingRefetch,
    isFetching: inviteListingFetching,
  } = useGetQuery('invite-listing', apiEndpoints.INVITATIONS_LISTING(organizationId), { page });

  const { mutate: handleResendInvite, isLoading: resendInviteLoading } = usePostMutation(
    apiEndpoints.RESEND_INVITATION,
    () => {
      toast.success(SUCCESSFUL_MESSAGE('Invitation resent'));
      setResendLoading({});
    },
    ({ response: { data } }) => {
      toast.error(data[0]?.non_field_errors[0]);
      setResendLoading({});
    }
  );

  useEffect(() => {
    page && inviteListingRefetch();
  }, [page]);

  return {
    inviteListing,
    handleResendInvite,
    organizationId,
    page,
    setPage,
    resendInviteLoading,
    inviteListingFetching,
    resendLoading,
    setResendLoading,
  };
}
