import { cn } from '@shadcnUtils';
import { Tooltip } from 'react-tooltip';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import { TooltipIcon } from '@assets/svgs';
import 'react-tooltip/dist/react-tooltip.css';

export function WidgetTitle({ widget, titleText, hideTooltip }) {
  return (
    <span className='flex items-center gap-2'>
      <p
        className={cn('line-clamp text-lg font-semibold leading-[3rem]', titleText)}
        style={
          widget?.themeColor === DEFAULT_VALUE
            ? { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
            : { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
        }
      >
        {widget?.displayLabel}
      </p>
      {widget?.tooltip?.length > 0 && (
        <span className='cursor-pointer' id={widget?.id}>
          <TooltipIcon
            strokeColor={
              widget?.themeColor === DEFAULT_VALUE
                ? widget.themeStyle === THEME_STYLE.DARK
                  ? COLORS.WHITE_COLOR
                  : COLORS.DARK_PRIMARY
                : widget.themeStyle === THEME_STYLE.DARK
                  ? COLORS.WHITE_COLOR
                  : COLORS.DARK_PRIMARY
            }
          />
        </span>
      )}
      {!hideTooltip && (
        <Tooltip
          className='!max-w-[12.5rem] break-words !bg-darkPrimary !opacity-[1]'
          anchorSelect={`#${widget?.id}`}
          content={widget?.tooltip}
          place='right'
        />
      )}
    </span>
  );
}
