import { Input } from '@shadcnComponent';
import { ENTER, ENTER_KEY } from '@constants';
import { SendMessageIcon } from '@assets/svgs';

export function ChatbotFooter({ message, setMessage, handleChatMessage }) {
  return (
    <div className='mt-4 flex items-center rounded-md bg-softSilver py-[0.4375rem] pl-6 pr-4'>
      <Input
        className='h-9 w-full border-r border-softGray bg-transparent pr-1 outline-none'
        placeholder='Type here'
        value={message}
        onChange={e => setMessage(e?.target?.value)}
        autoFocus={true}
        onKeyDown={e => {
          if (e.code === ENTER || e.keyCode === ENTER_KEY) handleChatMessage();
        }}
      />
      <div className='ml-4 cursor-pointer' onClick={handleChatMessage}>
        <SendMessageIcon />
      </div>
    </div>
  );
}
