import { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import { EditWidgetsTabs } from '@pages/dashboard';
import { EDIT_WIDGET_TABS } from '@constants';

export function EditWidgetsSidebar({
  editWidget,
  setEditWidget,
  isEditWidgetMode,
  setIsEditWidgetMode,
  setIsEditMode,
  editWidgetDateRange,
  setEditWidgetDateRange,
  handleDateRangeChange,
  showDatePicker,
  setShowDatePicker,
  handleSelectEditWidgetDateRange,
  handleSelectEditWidgetCompareRange,
  handleEditClearRangeDateFilter,
  editWidgetDateRangeStart,
  editWidgetDateRangeEnd,
  editWidgetDateRangeSet,
  editWidgetCompareRangeStart,
  editWidgetCompareRangeEnd,
  editWidgetCompareRangeSet,
  setEditWidgetDateRangeStart,
  setEditWidgetDateRangeEnd,
  setEditWidgetCompareRangeStart,
  setEditWidgetCompareRangeEnd,
  handleEditWidgetRangeDateFilter,
  widgetDimension,
  widgetDimensionRefetch,
  handleCommentUpdate,
  setIsCommentMode,
  setIsGoalTrackingMode,
}) {
  const [activeTab, setActiveTab] = useState(EDIT_WIDGET_TABS.GENERAL);

  return (
    <div
      className={`w-[21.25rem] border-l border-lightGray bg-white px-6 py-6 ${isEditWidgetMode ? 'transition-all duration-500 ease-in-out md:w-[19.3125rem] lg:w-[15rem] xxl:w-[21.25rem]' : 'hidden'}`}
    >
      <div
        className='mb-[1.0625rem] flex cursor-pointer items-center gap-2'
        onClick={() => {
          setIsEditWidgetMode(false);
          setIsEditMode(true);
          setIsCommentMode(false);
          setIsGoalTrackingMode(false);
          setEditWidget({});
          setActiveTab(EDIT_WIDGET_TABS.GENERAL);
        }}
      >
        <ChevronLeft />
        Edit Widgets
      </div>
      <EditWidgetsTabs
        editWidget={editWidget}
        setEditWidget={setEditWidget}
        editWidgetDateRange={editWidgetDateRange}
        setEditWidgetDateRange={setEditWidgetDateRange}
        handleDateRangeChange={handleDateRangeChange}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
        handleSelectEditWidgetDateRange={handleSelectEditWidgetDateRange}
        handleSelectEditWidgetCompareRange={handleSelectEditWidgetCompareRange}
        handleEditClearRangeDateFilter={handleEditClearRangeDateFilter}
        editWidgetDateRangeStart={editWidgetDateRangeStart}
        editWidgetDateRangeEnd={editWidgetDateRangeEnd}
        editWidgetDateRangeSet={editWidgetDateRangeSet}
        editWidgetCompareRangeStart={editWidgetCompareRangeStart}
        editWidgetCompareRangeEnd={editWidgetCompareRangeEnd}
        editWidgetCompareRangeSet={editWidgetCompareRangeSet}
        setEditWidgetDateRangeStart={setEditWidgetDateRangeStart}
        setEditWidgetDateRangeEnd={setEditWidgetDateRangeEnd}
        setEditWidgetCompareRangeStart={setEditWidgetCompareRangeStart}
        setEditWidgetCompareRangeEnd={setEditWidgetCompareRangeEnd}
        handleEditWidgetRangeDateFilter={handleEditWidgetRangeDateFilter}
        widgetDimension={widgetDimension}
        widgetDimensionRefetch={widgetDimensionRefetch}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleCommentUpdate={handleCommentUpdate}
      />
    </div>
  );
}
