import { Link } from 'react-router-dom';
import { Table, TableHeader, TableBody, TableRow, TableCell, TableHead, ScrollArea, ScrollBar } from '@shadcnComponent';
import moment from 'moment';
import { CustomPagination } from '@common/components';
import { useBillingTab } from '@pages/profile';
import { DATE_FORMAT, TOTAL_ITEMS_PER_PAGE } from '@constants';
import { DownloadIcon, EmptyBill } from '@assets/svgs';

export function BillingTab() {
  const { billingListing, setPage, page } = useBillingTab();

  return (
    <div className='flex flex-col gap-10'>
      <div className='h-[33.6875rem] md:h-[38.125rem] lg:h-[calc(100vh-13.25rem)] xxl:h-[calc(100vh-16.5rem)]'>
        <ScrollArea className='relative z-[1] h-full w-[calc(100vw-2.5rem)] max-w-[27.5rem] whitespace-nowrap rounded-md border md:max-w-full lg:w-auto'>
          <Table className='text-lg'>
            <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
              <TableRow>
                <TableHead>Billing Date</TableHead>
                <TableHead>Amount </TableHead>
                <TableHead className='w-[15.125rem]'>Download Invoice</TableHead>
              </TableRow>
            </TableHeader>
            {billingListing?.data?.results.length === 0 ? (
              <div className='absolute top-[50%] flex h-[18.75rem] w-full translate-y-[-50%] flex-col items-center justify-center gap-4 md:gap-6 lg:gap-4 xxl:h-[400px] xxl:gap-10'>
                <EmptyBill className='h-[5.8125rem] w-[7.8rem] md:h-[8.75rem] md:w-[11.7413rem] lg:h-[5.8125rem] lg:w-[7.8rem] xxl:h-[12.9375rem] xxl:w-[14.125rem]' />
                <div className='flex flex-col items-center gap-2 md:gap-3 lg:gap-2 xxl:gap-4'>
                  <h4 className='text-sm font-semibold leading-[1.0588rem] md:text-base md:leading-[1.21rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-2xl xxl:leading-[1.8156rem]'>
                    No Billing Details Yet
                  </h4>
                  <p className='text-xs font-normal leading-[1.375rem] md:text-sm md:leading-[1.375rem] lg:text-xs lg:leading-[1.375rem] xxl:text-base xxl:leading-7'>
                    You haven&apos;t added any billing information yet.
                  </p>
                </div>
              </div>
            ) : (
              <TableBody className='pt-5'>
                {billingListing?.data?.results.map(billing => (
                  <TableRow key={billing?.id}>
                    <TableCell className='font-medium'>
                      <p className='w-24 truncate'>{moment(billing?.created).format(DATE_FORMAT.BILLING_DATE)}</p>
                    </TableCell>
                    <TableCell>${billing?.total}</TableCell>
                    <TableCell>
                      <Link to={billing?.invoicePdf}>
                        <DownloadIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
      <div className='mb-6 md:mb-0'>
        {billingListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={page} setPage={setPage} data={billingListing} />
        )}
      </div>
    </div>
  );
}
