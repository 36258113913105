import { Avatar, Popover, PopoverTrigger, PopoverContent } from '@shadcnComponent';

export function DataSourcePopover({ dashboard }) {
  return (
    <div className='flex gap-2 md:gap-3 lg:gap-2 xxl:gap-3'>
      {dashboard?.dataSources?.slice(0, 3)?.map(dataSource => (
        <div
          onClick={event => event.stopPropagation()}
          className='h-5 w-5 md:h-6 md:w-6 lg:h-5 lg:w-5 xxl:h-6 xxl:w-6'
          key={dataSource?.id}
          title={dataSource?.connector?.sourceName}
        >
          <img src={dataSource?.connector?.logo?.image} />
        </div>
      ))}
      {dashboard?.dataSourceCount === 0 && 'N/A'}
      <Popover>
        {dashboard?.dataSourceCount > 3 && (
          <PopoverTrigger onClick={event => event.stopPropagation()}>
            <Avatar className='-mr-3 h-5 w-5 md:h-6 md:w-6 xxl:h-6 xxl:w-6'>
              <span className='flex w-full items-center justify-center bg-darkPrimary text-[.4375rem] font-semibold leading-[.5rem] text-white'>
                +{dashboard?.dataSourceCount - 3}
              </span>
            </Avatar>
          </PopoverTrigger>
        )}
        {dashboard?.dataSourceCount > 0 && (
          <PopoverContent
            onClick={e => e.stopPropagation()}
            align='end'
            className='max-h-[7.5rem] w-[10.0625rem] overflow-y-scroll px-3 py-[0.9375rem]'
          >
            <ul className='flex flex-col gap-3'>
              {dashboard?.dataSources.map(dataSource => (
                <li
                  onClick={e => e.stopPropagation()}
                  key={dataSource?.id}
                  className='flex cursor-pointer items-center gap-2 text-sm font-normal leading-5'
                >
                  <img src={dataSource?.connector?.logo?.image} className='h-6 w-6' />
                  {dataSource?.connector?.sourceName}
                </li>
              ))}
            </ul>
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
}
