export function PersonIcon() {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2739_22873)'>
        <path
          d='M8.76649 11.9907V10.8999C8.76649 10.3213 8.53664 9.76637 8.12751 9.35724C7.71838 8.94811 7.16348 8.71826 6.58488 8.71826H3.31247C2.73387 8.71826 2.17897 8.94811 1.76984 9.35724C1.36071 9.76637 1.13086 10.3213 1.13086 10.8999V11.9907'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.94821 6.53607C6.15308 6.53607 7.12982 5.55933 7.12982 4.35446C7.12982 3.14959 6.15308 2.17285 4.94821 2.17285C3.74334 2.17285 2.7666 3.14959 2.7666 4.35446C2.7666 5.55933 3.74334 6.53607 4.94821 6.53607Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.039 11.9906V10.8998C12.0387 10.4164 11.8778 9.94683 11.5816 9.5648C11.2855 9.18277 10.8709 8.90991 10.4028 8.78906'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.76611 2.24414C9.23539 2.36429 9.65132 2.63721 9.94835 3.01987C10.2454 3.40253 10.4066 3.87316 10.4066 4.35757C10.4066 4.84198 10.2454 5.31262 9.94835 5.69528C9.65132 6.07794 9.23539 6.35086 8.76611 6.47101'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2739_22873'>
          <rect width='13.0897' height='13.0897' fill='white' transform='translate(0.0400391 0.537109)' />
        </clipPath>
      </defs>
    </svg>
  );
}
