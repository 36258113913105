import { cn } from '@shadcnUtils';
import { COLORS, THEME_STYLE, WIDGETS_TYPE } from '@constants';
import { GoalTrackingWidgetIcon } from '@assets/svgs';

export function GoalTrackingBtn({ widget, showBtn, handleGoalTrackingMode, className }) {
  return (
    <>
      {!showBtn && widget.connectorName !== WIDGETS_TYPE.GOAL && (
        <span
          onClick={handleGoalTrackingMode}
          className={cn('hidden h-6 w-8 cursor-pointer items-center justify-center rounded-sm', className)}
          style={{ borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }}
        >
          <GoalTrackingWidgetIcon />
        </span>
      )}
    </>
  );
}
