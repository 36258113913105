import { Label } from '@shadcnComponent';
import OtpInput from 'react-otp-input';
import { COLORS, ENTER, ENTER_KEY } from '@constants';

export function OTPInputs({ otp, setOtp, showOtpError, errorMessage, handleOtpVerification }) {
  return (
    <div className='flex flex-col gap-2 lg:gap-1.5'>
      <Label className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-[1.0588rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-xs xxl:leading-5 '>
        Verification Code
      </Label>
      <div className='otp-container'>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputType='number'
          shouldAutoFocus={true}
          inputStyle={showOtpError ? { borderColor: COLORS.ERROR_COLOR } : ''}
          renderInput={props => (
            <input
              {...props}
              className='border-darkSecondary text-sm font-normal leading-5 !text-darkSecondary focus:border-darkSecondary'
              onKeyDown={e => {
                if (e.code === ENTER || e.keyCode === ENTER_KEY) handleOtpVerification();
              }}
            />
          )}
        />
      </div>
      {showOtpError && <p className='h-min text-xs font-normal leading-5 text-errorColor'>{errorMessage}</p>}
    </div>
  );
}
