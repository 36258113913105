/* eslint-disable indent */
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { Trash2 } from 'lucide-react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  BarChartWidget,
  CounterWidget,
  CsvWidget,
  GoalWidget,
  ImageWidget,
  LineChartWidget,
  MapWidget,
  PieChartWidget,
  ProgressWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  WidgetTopHead,
} from '@common/widgets';
import { COLORS, DEFAULT_VALUE, STATIC, THEME_STYLE, WIDGETS_TYPE } from '@constants';
import { MenuDots } from '@assets/svgs';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function DashboardSlideWidgets({
  dashboard,
  page,
  csvPage,
  selectSliderWidget,
  setSelectSliderWidget,
  handleRemoveSlide,
  isSharedDashboard,
  removeWidgetSlide,
  setRemoveWidgetSlide,
}) {
  return (
    <>
      {dashboard?.map((slide, index) => (
        <div className='flex flex-col-reverse items-center gap-2 lg:flex-row' key={index}>
          <span className='text-[1rem] font-bold leading-[1.21rem] text-black'>{index + 1}</span>
          <div
            className={`relative h-[7.25rem] min-w-[12.75rem] xxl:h-[8.5rem] ${selectSliderWidget === index ? 'border border-darkPrimary' : ''}`}
            onClick={() => setSelectSliderWidget(index)}
          >
            {dashboard.length > 1 && !isSharedDashboard && (
              <Popover
                open={removeWidgetSlide[index]}
                onOpenChange={isOpen => {
                  setRemoveWidgetSlide({
                    ...removeWidgetSlide,
                    [index]: isOpen,
                  });
                }}
              >
                <PopoverTrigger
                  onClick={e => e.stopPropagation()}
                  className='absolute right-2 top-2 z-[1] flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-sidebar-gradient'
                >
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      setRemoveWidgetSlide({
                        ...removeWidgetSlide,
                        [index]: true,
                      });
                    }}
                  >
                    <MenuDots strokeColor={COLORS.WHITE_COLOR} className='h-4 w-4' />
                  </span>
                </PopoverTrigger>
                <PopoverContent
                  className='w-[8.75rem] cursor-pointer p-0 text-sm font-normal leading-5 text-errorColor'
                  align='end'
                >
                  <ul>
                    <PopoverClose>
                      <li
                        className='flex items-center gap-2 px-3 py-[0.8125rem] text-sm font-normal leading-5'
                        onClick={e => {
                          e.stopPropagation();
                          handleRemoveSlide(index);
                          setRemoveWidgetSlide({});
                        }}
                      >
                        <Trash2 width={16} height={16} color='#EF4444' />
                        Remove
                      </li>
                    </PopoverClose>
                  </ul>
                </PopoverContent>
              </Popover>
            )}
            {!isEmpty(slide) && (
              <ResponsiveGridLayout
                className='layout flex !h-full w-full flex-wrap items-start gap-2.5 overflow-hidden scrollbar-none'
                layouts={{ xxs: slide }}
                rowHeight={0.5}
                isResizable={false}
                isDraggable={false}
                cols={{ xxs: 6 }}
                breakpoints={{ xxs: 0 }}
              >
                {slide?.map(widget => (
                  <div
                    key={widget?.i}
                    className={`${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'text-widget' : ''} pointer-events-none`}
                  >
                    <div
                      className={`h-full w-full overflow-hidden overflow-y-auto border border-widgetBorderColor p-1 shadow-[0_5px_20px_0_rgba(0,0,0,0.05)] scrollbar-none ${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'mb-2 !h-[0.875rem] rounded-[0.375rem]' : ''}`}
                      style={
                        widget?.themeColor === DEFAULT_VALUE
                          ? {
                              backgroundColor:
                                widget.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.WHITE_COLOR,
                            }
                          : { backgroundColor: widget?.background }
                      }
                    >
                      {!widget?.connectorName?.includes(STATIC) && (
                        <WidgetTopHead
                          className='mb-0 gap-0'
                          titleText='text-[6px] text-left'
                          widgetImage='h-2 min-w-2'
                          widgetWrapper='gap-0.5'
                          accountName='text-[0.375rem]'
                          widget={widget}
                          hideTooltip={true}
                        />
                      )}
                      {widget?.widgetData?.error ? (
                        <p
                          className='mt-2 flex h-[calc(100%-3.4375rem)] w-full items-center justify-center text-[0.375rem] font-bold'
                          style={
                            widget?.themeColor === DEFAULT_VALUE
                              ? {
                                  color:
                                    widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                                }
                              : {
                                  color:
                                    widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
                                }
                          }
                        >
                          {widget?.widgetData?.error}
                        </p>
                      ) : (
                        <>
                          {widget?.id?.includes(WIDGETS_TYPE.GOAL) && (
                            <div className='h-[calc(100%-3.4375rem)] w-full'>
                              <GoalWidget widget={widget} className='text-[0.5rem]' />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.COUNTER) && (
                            <div className='w-full'>
                              <CounterWidget
                                index={index}
                                value={widget?.widgetData?.data}
                                widget={widget}
                                isComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                                className='py-0'
                                dataText='text-[0.5rem] justify-start'
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.BAR_CHART) && (
                            <div className='h-5 w-full'>
                              <BarChartWidget
                                widget={widget}
                                isWidgetComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                                size='5'
                                xSize='1'
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.LINE_CHART) && (
                            <div className='h-5 w-full'>
                              <LineChartWidget
                                widget={widget}
                                isWidgetComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                                size='5'
                                xSize='1'
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.PIE_CHART) && (
                            <div className='slider-pie-chart flex h-[calc(100%-3.4375rem)] w-full flex-col items-center'>
                              <PieChartWidget
                                widget={widget}
                                isComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                                isWidgetComparisonFilterApply={widget.isWidgetComparisonFilterApply}
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.PROGRESS_BAR) && (
                            <div className='h-5 w-full overflow-auto scrollbar-none'>
                              <ProgressWidget
                                widget={widget}
                                isComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                                className='my-0'
                                progressTitle='text-[0.3125rem] mb-0'
                                progressBar='h-1 scale-x-[3] rounded-[0]'
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.TABLE) && (
                            <div className='h-5 w-full overflow-auto scrollbar-none'>
                              <TableWidget
                                tableBodyClass='pt-0'
                                tableHeadClass='p-0'
                                tableCellClass='!p-0 !text-[0.375rem]'
                                textClass='text-[0.375rem]'
                                widget={widget}
                                page={page}
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.MAP) && <MapWidget mapImage='h-[unset]' widget={widget} />}
                        </>
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.TEXT) && (
                        <TextWidget textClass='text-[0.5rem] text-left -mt-2.5' widget={widget} hideTooltip={true} />
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.VIDEO) && (
                        <VideoWidget
                          titleText='text-[0.375rem]'
                          headingText='text-[0.375rem]'
                          inputText='text-[0.375rem] p-0 rounded-[0.0625rem]'
                          wrapperClass='gap-0'
                          widget={widget}
                          hideTooltip={true}
                        />
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.IMAGE) && (
                        <ImageWidget
                          className='gap-0'
                          titleText='text-[0.375rem]'
                          inputText='text-[0.25rem] gap-0.5 rounded-[0.125rem]'
                          uploadIcon='h-1.5 w-1.5'
                          inputWrapper='h-3.5 rounded-none'
                          widget={widget}
                          hideTooltip={true}
                        />
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.CSV) && (
                        <CsvWidget widget={widget} page={csvPage} hideTooltip={true} />
                      )}
                    </div>
                  </div>
                ))}
              </ResponsiveGridLayout>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
