import { useEffect } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ROUTES } from '@routes';
import { apiEndpoints, usePostMutation } from '@services';
import { getLocalStorageItem, setLocalStorageItem } from '@utils';
import { DATA_SOURCES } from '@constants';

export function useOauthConnection() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { connectorName } = useParams();

  const selectedOrganizationId = getLocalStorageItem('selectedOrganization');
  const defaultOrganizationId = getLocalStorageItem('defaultOrganizationId');
  const accessToken = getLocalStorageItem('accessToken');

  const queryParams = new URLSearchParams(search);
  const codeValue = queryParams.get('code');
  const shopValue = queryParams.get('shop');
  const stateValue = queryParams.get('state');
  const hmacValue = queryParams.get('hmac');
  const hostValue = queryParams.get('host');
  const timestampValue = queryParams.get('timestamp');
  const accountsServerValue = queryParams.get('accounts-server');
  const locationValue = queryParams.get('location');

  const { mutate: handleOauthConnection } = usePostMutation(
    apiEndpoints.COMPLETE_OAUTH,
    () =>
      (window.location.href = `${import.meta.env.VITE_FRONTEND_BASE_URL}${generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId: selectedOrganizationId || defaultOrganizationId })}`),
    ({ response: { data } }) => toast.error(data?.detail)
  );

  useEffect(() => {
    let payload = {};

    if (connectorName === DATA_SOURCES.WOO_COMMERCE) {
      navigate(
        generatePath(ROUTES.ADD_DATA_SOURCE, {
          organizationId: selectedOrganizationId || defaultOrganizationId,
          page: 1,
        })
      );
    } else if (connectorName === DATA_SOURCES.SHOPIFY) {
      payload = {
        code: codeValue,
        shop: shopValue,
        state: stateValue,
      };
    } else if (connectorName === DATA_SOURCES.ZOHO_CRM) {
      payload = {
        code: codeValue,
        state: stateValue,
        location: locationValue,
        'accounts-server': accountsServerValue,
      };
    } else {
      payload = {
        code: codeValue,
        state: stateValue,
      };
    }

    if (accessToken) !isEmpty(payload) && handleOauthConnection({ payload });
    else {
      setLocalStorageItem('code', codeValue);
      setLocalStorageItem('shop', shopValue);
      setLocalStorageItem('state', stateValue);
      setLocalStorageItem('hmac', hmacValue);
      setLocalStorageItem('host', hostValue);
      setLocalStorageItem('timestamp', timestampValue);
      navigate(ROUTES.SIGN_IN);
    }
  }, []);

  return { handleOauthConnection };
}
