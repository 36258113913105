export default function ColorPicker({ labelText, componentColor, handleChange, name, showError }) {
  return (
    <div className='space-y-3 lg:space-y-3 xxl:space-y-3'>
      <label className='text-sm font-bold leading-6 md:text-lg md:leading-6 lg:text-sm lg:leading-6 xxl:text-lg xxl:leading-6'>
        {labelText}
      </label>
      <div className='flex gap-3 md:w-[18.6875rem] md:gap-2 lg:w-full'>
        <input
          type='color'
          name={name}
          id='favcolor'
          className='h-12 min-w-[4.125rem] rounded-lg border md:h-10 md:min-w-[2.5rem] lg:h-8 lg:min-w-[2rem] lg:max-w-[2rem] xxl:h-10 xxl:min-w-10'
          style={{ backgroundColor: componentColor }}
          value={componentColor}
          onChange={handleChange}
        />
        <input
          disabled
          type='text'
          name={name}
          value={componentColor}
          onChange={handleChange}
          placeholder='Enter hex code'
          className='h-12 w-[60%] flex-grow rounded-md border border-lightGray px-3 py-2 md:h-10 md:min-w-[251px] md:max-w-[15.6875rem] lg:h-8 lg:min-w-[unset] lg:max-w-[unset] xxl:h-10'
        />
      </div>
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>{showError}</p>
      )}
    </div>
  );
}
