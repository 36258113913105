import { cn } from '@shadcnUtils';
import { range } from 'lodash';
import ReactPaginate from 'react-paginate';
import { TOTAL_ITEMS_PER_PAGE } from '@constants';
import { ArrowLeft } from '@assets/svgs';

export function CustomPagination({ page, setPage, data, totalItems = TOTAL_ITEMS_PER_PAGE }) {
  const totalPages = range(1, data?.data?.count / totalItems + 1);

  const isPagination = data?.data.count <= totalItems ? 'hidden' : 'flex';

  return (
    <ReactPaginate
      pageCount={totalPages.length}
      onPageChange={({ selected }) => setPage(selected + 1)}
      disabledClassName='disabled'
      className={cn('flex items-center justify-center gap-0.5 xsm:gap-1', isPagination)}
      breakLabel='...'
      nextLabel={
        <>
          Next
          {
            <div className='h-4 w-4 rotate-180'>
              <ArrowLeft />
            </div>
          }
        </>
      }
      previousLabel={
        <>
          {
            <div className='h-4 w-4'>
              <ArrowLeft />
            </div>
          }
          Previous
        </>
      }
      pageRangeDisplayed={page === 1 ? 3 : 2}
      marginPagesDisplayed={1}
      forcePage={page - 1}
      renderOnZeroPageCount={null}
      activeClassName='!bg-darkPrimary'
      activeLinkClassName='text-white'
      pageClassName='rounded-lg hover:bg-tableRowHover h-6 xsm:h-8 md:h-10 lg:h-8 xxl:h-10 text-center flex items-center w-6 xsm:w-8 md:w-10 lg:w-8 xxl:w-10 justify-center xxl:px-2 px-1'
      previousClassName={`mr-1 xsm:px-4 xsm:py-2 flex ${totalPages.length === 0 && 'disabled'}`}
      nextClassName={`ml-1 xsm:px-4 xsm:py-2 ${totalPages.length === 0 && 'disabled'}`}
      pageLinkClassName='text-sm '
      previousLinkClassName='flex gap-1 items-center text-sm leading-6 font-medium'
      nextLinkClassName='flex gap-1 items-center font-medium text-sm leading-6'
    />
  );
}
