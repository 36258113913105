import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ScrollArea,
  Dialog,
  Button,
  ScrollBar,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { CustomPagination, DeleteDialog, BaseToolTip, BaseLoader, TourButton } from '@common/components';
import { InviteMemberDialog, useMembersTab } from '@pages/manageOrganization';
import { capitalizeString, truncateString, useAppTours } from '@utils';
import { INVITE_MEMBER_BUTTON, TOTAL_ITEMS_PER_PAGE } from '@constants';
import { DeleteIcon } from '@assets/svgs';

export function MembersTab({ setInviteMemberTourRole }) {
  const {
    teamListing,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    showInviteMemberDialog,
    setShowInviteMemberDialog,
    emailFieldErrorMessage,
    organizationMembers,
    showDeleteMemberDialog,
    setShowDeleteMemberDialog,
    setMemberId,
    page,
    setPage,
    openInviteMemberDialogue,
    inviteMemberLoading,
    deleteMemberLoading,
    setDeleteMemberLoading,
    invalidEmails,
    organizationMembersFetching,
  } = useMembersTab();

  const { inviteMemberTour } = useAppTours();

  return (
    <div className='flex flex-col gap-6 md:gap-8 lg:gap-6'>
      <div className='relative'>
        <div className='absolute right-0 top-4 w-full md:-top-10 md:w-[10.6875rem] lg:-top-8 lg:w-[8.0625rem] xxl:-top-[2.5rem] xxl:w-[8.5rem]'>
          <Dialog open={showInviteMemberDialog} onOpenChange={setShowInviteMemberDialog}>
            <Button onClick={openInviteMemberDialogue} id={INVITE_MEMBER_BUTTON}>
              Invite Members
            </Button>
            <InviteMemberDialog
              setInviteMemberTourRole={setInviteMemberTourRole}
              inviteMemberLoading={inviteMemberLoading}
              teamListing={teamListing}
              formik={formik}
              onEnter={handleInvitedEmailKeyDown}
              invitedEmails={invitedEmails}
              onCrossBtnClick={handleRemoveInvitedEmail}
              emailFieldErrorMessage={emailFieldErrorMessage}
              invalidEmails={invalidEmails}
            />
          </Dialog>
        </div>
        <div className='mt-[5.5rem] h-[23.8125rem] md:mt-6 md:h-[38.125rem] lg:mt-4 lg:h-[calc(100vh-16.75rem)] xxl:h-[calc(100vh-21.9375rem)]'>
          <ScrollArea className='relative z-[1] h-full w-[calc(100vw-2.5rem)] whitespace-nowrap rounded-md border xsm:w-[27.5rem] md:w-full lg:w-auto'>
            <Table className='text-lg'>
              <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
                <TableRow>
                  <TableHead className='w-[8.625rem] min-w-[8.625rem] md:w-[13.0594rem] md:px-10 md:pb-3 md:pt-3.5 lg:w-[13.125rem] xxl:w-[20rem]'>
                    Name
                  </TableHead>
                  <TableHead className='md:px-10 md:pb-3 md:pt-3.5'>Account</TableHead>
                  <TableHead className='md:px-10 md:pb-3 md:pt-3.5'>Role</TableHead>
                  <TableHead className='md:px-10 md:pb-3 md:pt-3.5'>Action</TableHead>
                </TableRow>
              </TableHeader>
              {organizationMembersFetching ? (
                <BaseLoader />
              ) : (
                !isEmpty(organizationMembers?.data?.results) && (
                  <TableBody className='pt-5'>
                    {organizationMembers?.data?.results.map(member => (
                      <TableRow key={member?.id}>
                        <TableCell className='font-medium md:px-10 md:pb-3 md:pt-3.5'>
                          <p id={`memberName${member?.id}`} className='whitespace-nowrap'>
                            {truncateString(member?.name, 36)}
                            {member?.name?.length > 36 && (
                              <BaseToolTip id={`memberName${member?.id}`} content={member?.name} />
                            )}
                          </p>
                        </TableCell>
                        <TableCell id={`memberEmail${member?.id}`} className='md:px-10 md:pb-3 md:pt-3.5'>
                          {truncateString(member?.email, 36)}
                          {member?.email?.length > 36 && (
                            <BaseToolTip id={`memberEmail${member?.id}`} content={member?.email} />
                          )}
                        </TableCell>
                        <TableCell className='md:px-10 md:pb-3 md:pt-3.5'>{capitalizeString(member.role)}</TableCell>
                        <TableCell className='md:px-10 md:pb-3 md:pt-3.5'>
                          <Dialog
                            open={showDeleteMemberDialog[member.id]}
                            onOpenChange={isOpen => {
                              setShowDeleteMemberDialog({ ...showDeleteMemberDialog, [member.id]: isOpen });
                              setDeleteMemberLoading(false);
                            }}
                          >
                            <span
                              onClick={() => {
                                setShowDeleteMemberDialog({ ...showDeleteMemberDialog, [member.id]: true });
                                setDeleteMemberLoading(false);
                              }}
                              className='cursor-pointer'
                            >
                              <DeleteIcon className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                            </span>
                            <DeleteDialog
                              heading={member.name}
                              text={member.name}
                              deleteBtnClick={() => setMemberId(member?.id)}
                              className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
                              isLoading={deleteMemberLoading}
                            />
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )
              )}
            </Table>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        </div>
      </div>
      <div className='mb-6 md:mb-0'>
        {organizationMembers?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={page} setPage={setPage} data={organizationMembers} />
        )}
      </div>
      <TourButton Label='“Invite Members”' onClick={() => inviteMemberTour().drive()} />
    </div>
  );
}
