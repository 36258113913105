import { Button, Label } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import TaggedUsers from './TaggedUsers';
import { Loader } from '@common/components';
import { NO_COMMENTS_MESSAGE } from '@constants';

export function DashboardComments({
  isCommentMode,
  commentsListing,
  formik,
  createCommentLoading,
  scrollContainerRef,
  handleScroll,
  className,
  tagUsersList,
}) {
  const { touched, errors, submitForm, setFieldValue, values } = formik;

  const parseList = list => {
    if (!isEmpty(list)) {
      return list?.map(lis => {
        return {
          id: lis.id,
          img: lis.profileImage?.image,
          display: lis.name,
        };
      });
    }

    return [];
  };

  return (
    <div
      className={cn(
        `border-lightGray bg-white md:absolute md:w-[19.375rem] md:border-l md:px-4 md:pb-8 md:pt-4 lg:w-[20.1875rem] lg:pb-7 lg:pl-[1.375rem] xxl:w-[21.3125rem] xxl:px-7 xxl:pb-[2.3125rem] xxl:pt-6 ${isCommentMode ? 'right-0' : '-right-full'} z-[20] hidden overflow-y-auto transition-all duration-500 ease-in-out md:block md:h-[calc(100vh-17.75rem)] lg:h-[calc(100vh-7.25rem)] xxl:h-[calc(100vh-11rem)]  ${className}`
      )}
    >
      <h5 className='md:lead pl-10 text-2xl font-bold leading-[1.8156rem] md:pl-0 md:text-lg md:leading-6 lg:text-base xxl:text-lg'>
        Comments
      </h5>
      <ul
        className='my-4 flex h-[calc(100vh-28.75rem)] flex-col-reverse overflow-y-auto overflow-x-hidden scrollbar-none md:my-2 md:h-[calc(100vh-36.875rem)] lg:my-1 lg:h-[calc(100vh-25rem)] xxl:my-2 xxl:h-[calc(100vh-30.75rem)]'
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        {commentsListing?.pages?.map(page =>
          page?.data?.count === 0 ? (
            <p key={page} className='p-4 text-center text-[1rem] font-normal leading-5'>
              {NO_COMMENTS_MESSAGE}
            </p>
          ) : (
            page?.data?.results?.map(comment => (
              <li key={comment?.id} className='border-b border-lightGray py-4 lg:py-3 xxl:py-4'>
                <div className='flex items-center gap-2 lg:gap-4 xxl:gap-2'>
                  <img
                    src={comment?.user?.profileImage?.image}
                    alt='person'
                    className='h-10 w-10 rounded-full md:h-8 md:w-8 lg:h-6 lg:w-6 xxl:h-8 xxl:w-8'
                  />
                  <p className='text-base font-medium leading-[1.21rem] md:text-sm md:leading-[1.0588rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-[1.0588rem]'>
                    {comment?.user?.name}
                  </p>
                  <span className='ml-auto text-xs font-normal leading-[0.9075rem] text-lightSilver md:text-[0.625rem] md:leading-[0.7562rem]'>
                    {moment(comment?.created).fromNow()}
                  </span>
                </div>
                {!!comment?.widgetLabel && (
                  <span className='mt-[0.3125rem] text-[0.75rem] font-medium leading-[0.9075rem] text-darkSecondary opacity-[.5]'>
                    Widget: <span className='font-normal'>{comment?.widgetLabel}</span>
                  </span>
                )}
                <p
                  className='mt-3 break-words text-sm font-extralight leading-6 md:leading-5 lg:mt-2 lg:text-xs xxl:mt-3 xxl:text-sm'
                  dangerouslySetInnerHTML={{ __html: comment?.text }}
                />
              </li>
            ))
          )
        )}
      </ul>

      <div className='relative mt-6 flex flex-col gap-3 md:gap-1.5'>
        <Label
          htmlFor='comment'
          className='text-sm leading-[0.875rem] md:leading-[0.875rem] lg:leading-[0.875rem] xxl:text-sm xxl:leading-[0.875rem]'
        >
          Add your comment
        </Label>

        <TaggedUsers users={parseList(tagUsersList)} setFieldValue={setFieldValue} values={values} />

        {!!(touched.text && errors.text) && (
          <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>
            {touched.text && errors.text}
          </p>
        )}
      </div>
      <Button disabled={createCommentLoading} onClick={submitForm} className='mt-4'>
        Send {createCommentLoading ? <Loader /> : ''}
      </Button>
    </div>
  );
}
