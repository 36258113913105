export function EditIconWhite() {
  return (
    <svg width='100%' height='100%' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1463_9760)'>
        <path
          d='M9.91699 1.75083C10.0702 1.59762 10.2521 1.47609 10.4523 1.39317C10.6524 1.31025 10.867 1.26758 11.0837 1.26758C11.3003 1.26758 11.5149 1.31025 11.7151 1.39317C11.9152 1.47609 12.0971 1.59762 12.2503 1.75083C12.4035 1.90404 12.5251 2.08592 12.608 2.2861C12.6909 2.48628 12.7336 2.70082 12.7336 2.91749C12.7336 3.13416 12.6909 3.34871 12.608 3.54889C12.5251 3.74907 12.4035 3.93095 12.2503 4.08416L4.37533 11.9592L1.16699 12.8342L2.04199 9.62583L9.91699 1.75083Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1463_9760'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
