import { NavLink } from 'react-router-dom';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@shadcnComponent';
import { BaseSkeltonLoader } from '@common/components';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import { setLocalStorageItem } from '@utils';
import { DEFAULT_ORGANIZATION_ID, SKELETON_TYPE } from '@constants';
import { ArrowDown, ProfileIcon, LogoutIcon } from '@assets/svgs';

export function ProfileDropdown({ strokeColor }) {
  const { data: userDetail, isLoading: userDetailFetching } = useGetQuery(
    'user-detail',
    apiEndpoints.USER_DETAIL,
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: resp => {
        setLocalStorageItem(DEFAULT_ORGANIZATION_ID, resp?.data?.defaultOrganization);
      },
    }
  );

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <DropdownMenu className='relative'>
      <DropdownMenuTrigger>
        <div className='flex items-center gap-1'>
          <div className='relative h-8 w-8 overflow-hidden rounded-full md:h-10 md:w-10 lg:h-7 lg:w-7 xxl:h-10 xxl:w-10'>
            {userDetailFetching ? (
              <BaseSkeltonLoader type={SKELETON_TYPE.IMAGE_UPLOADER} />
            ) : (
              <img
                src={userDetail?.data?.profileImage?.image}
                alt='profile-image'
                className='h-full w-full object-contain'
              />
            )}
          </div>
          <ArrowDown
            strokeColor={strokeColor}
            className='h-3.5 w-3.5 md:h-5 md:w-5 lg:h-3.5 lg:w-3.5 xxl:h-5 xxl:w-5'
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='absolute -right-5 top-[-0.625rem] w-[8.875rem] text-darkSecondary xxl:-right-8 xxl:w-40'>
        <DropdownMenuItem asChild>
          <NavLink className='flex items-center gap-2' to={ROUTES.PROFILE}>
            <ProfileIcon className='lg:h-3 lg:w-3 xxl:h-4 xxl:w-4' /> My Profile
          </NavLink>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleLogout}>
          <LogoutIcon className='lg:h-3 lg:w-3 xxl:h-4 xxl:w-4' /> Log Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
