import { DialogContent, Button } from '@shadcnComponent';
import { FormInput, FormSelect, Loader } from '@common/components';
import {
  COLORS,
  INVITE_MEMBER_EMAIL,
  INVITE_MEMBER_ROLE,
  INVITE_MEMBER_ROLE_DROPDOWN,
  INVITE_MEMBER_SUBMIT,
  INVITE_MEMBER_TEAM,
  INVITE_MEMBER_TEAM_DROPDOWN,
  ROLES,
  ROLE_DROP_DOWN_OPTIONS,
} from '@constants';
import { CrossIcon } from '@assets/svgs';

export function InviteMemberDialog({
  teamListing,
  formik,
  onEnter,
  invitedEmails,
  onCrossBtnClick,
  emailFieldErrorMessage,
  inviteMemberLoading,
  invalidEmails,
  setInviteMemberTourRole,
}) {
  const { values, handleChange, setFieldValue, touched, errors, submitForm } = formik;

  return (
    <DialogContent className='w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:max-w-[37.0625rem] md:p-10 lg:max-w-[24.625rem] lg:p-8 xxl:max-w-[37.0625rem] xxl:p-10'>
      <h3 className='mb-6 leading-[1.375rem] md:mb-10 md:leading-7 lg:mb-6 lg:leading-[1.375rem] xxl:mb-7 xxl:text-2xl xxl:leading-7'>
        Invite Members
      </h3>
      <div
        id='inviteScrollDialog'
        className='max-h-[19.125rem] overflow-y-auto scrollbar-none md:mx-[3.9375rem] lg:mx-[1.6875rem] xxl:mx-[63.5px]'
      >
        <div className='flex flex-col gap-4'>
          <FormSelect
            id={INVITE_MEMBER_ROLE}
            tourId={INVITE_MEMBER_ROLE_DROPDOWN}
            labelText='Role'
            placeholder='Select role'
            value={values.role}
            handleChange={selectedRole => {
              setFieldValue('role', selectedRole);
              setInviteMemberTourRole(prev => {
                return {
                  ...prev,
                  role: selectedRole.name,
                };
              });
            }}
            options={ROLE_DROP_DOWN_OPTIONS}
            showError={touched.role && errors.role}
            labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
            triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values?.role ? '' : 'custom-select'}`}
          />
          {values.role?.id !== ROLES.ADMIN && (
            <FormSelect
              id={INVITE_MEMBER_TEAM}
              tourId={INVITE_MEMBER_TEAM_DROPDOWN}
              labelText='Team'
              placeholder='Select team'
              value={values.team}
              optionPlaceholder='First create a team'
              handleChange={selectedTeam => setFieldValue('team', selectedTeam)}
              options={teamListing?.data?.results}
              showError={touched.team && errors.team}
              labelClassName='text-sm md:text-sm xxl:text-sm leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-5'
              triggerClassName={`h-10 lg:h-8 xxl:h-10 ${values?.team ? '' : 'custom-select'}`}
            />
          )}
          <FormInput
            id={INVITE_MEMBER_EMAIL}
            labelText='Email'
            placeholder='Enter email'
            value={values.email}
            handleChange={handleChange}
            showError={emailFieldErrorMessage}
            onKeyDown={onEnter}
            isMultipleEmail={true}
            isMultipleEmailMessage='*Press enter after adding an email'
            labelClassName='text-sm md:text-sm xxl:text-base leading-[1.0625rem] md:leading-[1.25rem] xxl:leading-base'
            inputClassName='h-10 md:h-10 lg:h-8 xxl:h-10'
          />
          <div className='flex flex-wrap gap-2'>
            {invitedEmails.map(email => (
              <span
                key={email}
                className={`flex items-center gap-2 break-all rounded-lg bg-tableRowHover px-2.5 py-1 text-sm font-normal leading-4 text-colorPrimary ${invalidEmails.includes(email) ? 'border border-errorColor text-errorColor' : ''}`}
              >
                {email}
                <span onClick={() => onCrossBtnClick(email)} className='cursor-pointer'>
                  <CrossIcon strokeColor={invalidEmails.includes(email) ? COLORS.ERROR_COLOR : COLORS.DARK_SECONDARY} />
                </span>
              </span>
            ))}
          </div>
          <div className='ml-auto w-full md:mt-2 md:w-[11.25rem] lg:mt-0 lg:w-[7.5rem] xxl:w-[11.25rem]'>
            <Button id={INVITE_MEMBER_SUBMIT} onClick={submitForm} disabled={inviteMemberLoading}>
              Invite {inviteMemberLoading ? <Loader /> : ''}{' '}
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
