export function SubscriptionExpiredIcon({ className }) {
  return (
    <svg
      width='376'
      height='280'
      viewBox='0 0 376 280'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g clipPath='url(#clip0_2540_40553)'>
        <path d='M376 246.064H0V246.252H376V246.064Z' fill='#EBEBEB' />
        <path d='M338.325 258.154H313.419V258.342H338.325V258.154Z' fill='#EBEBEB' />
        <path d='M249.078 260.199H242.543V260.387H249.078V260.199Z' fill='#EBEBEB' />
        <path d='M312.666 251.182H298.235V251.37H312.666V251.182Z' fill='#EBEBEB' />
        <path d='M71.9291 252.443H39.4502V252.631H71.9291V252.443Z' fill='#EBEBEB' />
        <path d='M83.3891 252.443H78.6289V252.631H83.3891V252.443Z' fill='#EBEBEB' />
        <path d='M169.313 255.615H98.8652V255.803H169.313V255.615Z' fill='#EBEBEB' />
        <path
          d='M178.224 212.549H33.0208C31.8833 212.547 30.793 212.094 29.9894 211.29C29.1857 210.485 28.7344 209.395 28.7344 208.259V4.29081C28.7344 3.15412 29.1857 2.06387 29.9894 1.2594C30.793 0.454936 31.8833 0.00199071 33.0208 0L178.224 0C179.363 0 180.455 0.452067 181.261 1.25675C182.066 2.06143 182.518 3.15282 182.518 4.29081V208.259C182.518 208.822 182.407 209.38 182.192 209.901C181.976 210.421 181.659 210.894 181.261 211.293C180.862 211.691 180.389 212.007 179.868 212.223C179.347 212.438 178.788 212.549 178.224 212.549ZM33.0208 0.187864C31.9331 0.189855 30.8907 0.623007 30.1223 1.39224C29.3539 2.16148 28.9224 3.20394 28.9224 4.29081V208.259C28.9224 209.345 29.3539 210.388 30.1223 211.157C30.8907 211.926 31.9331 212.359 33.0208 212.361H178.224C179.313 212.359 180.356 211.927 181.126 211.158C181.895 210.389 182.328 209.346 182.33 208.259V4.29081C182.328 3.20325 181.895 2.16081 181.126 1.39178C180.356 0.622762 179.313 0.18985 178.224 0.187864H33.0208Z'
          fill='#EBEBEB'
        />
        <path
          d='M340.889 212.549H195.678C194.539 212.547 193.448 212.095 192.644 211.29C191.839 210.486 191.386 209.396 191.384 208.259V4.29081C191.386 3.15343 191.839 2.0632 192.644 1.25894C193.448 0.454691 194.539 0.0019867 195.678 0L340.889 0C342.025 0.00397687 343.113 0.457798 343.915 1.26205C344.717 2.0663 345.168 3.15542 345.168 4.29081V208.259C345.168 209.394 344.717 210.483 343.915 211.287C343.113 212.092 342.025 212.545 340.889 212.549ZM195.678 0.187864C194.589 0.18985 193.546 0.622762 192.777 1.39178C192.007 2.16081 191.574 3.20325 191.572 4.29081V208.259C191.574 209.346 192.007 210.389 192.777 211.158C193.546 211.927 194.589 212.359 195.678 212.361H340.889C341.977 212.359 343.02 211.927 343.79 211.158C344.56 210.389 344.993 209.346 344.995 208.259V4.29081C344.993 3.20325 344.56 2.16081 343.79 1.39178C343.02 0.622762 341.977 0.18985 340.889 0.187864H195.678Z'
          fill='#EBEBEB'
        />
        <path
          d='M270.209 101.671C291.98 101.671 309.629 84.0352 309.629 62.28C309.629 40.5248 291.98 22.8887 270.209 22.8887C248.438 22.8887 230.789 40.5248 230.789 62.28C230.789 84.0352 248.438 101.671 270.209 101.671Z'
          fill='#E0E0E0'
        />
        <path
          d='M267.216 101.671C288.987 101.671 306.636 84.0352 306.636 62.28C306.636 40.5248 288.987 22.8887 267.216 22.8887C245.445 22.8887 227.796 40.5248 227.796 62.28C227.796 84.0352 245.445 101.671 267.216 101.671Z'
          fill='#F5F5F5'
        />
        <path
          d='M292.985 88.0256C307.214 73.8074 307.214 50.7551 292.985 36.5369C278.757 22.3186 255.688 22.3187 241.459 36.5369C227.231 50.7551 227.231 73.8074 241.459 88.0256C255.688 102.244 278.757 102.244 292.985 88.0256Z'
          fill='white'
        />
        <path
          d='M273.427 88.7762C273.229 88.7765 273.036 88.7098 272.88 88.5871C272.725 88.4643 272.615 88.2926 272.57 88.0999L266.358 62.4828C266.322 62.3669 266.31 62.245 266.324 62.1245C266.337 62.0039 266.375 61.8874 266.435 61.782C266.495 61.6766 266.576 61.5847 266.673 61.5119C266.77 61.439 266.881 61.3868 266.998 61.3585C267.116 61.3302 267.239 61.3264 267.359 61.3472C267.478 61.3681 267.592 61.4133 267.693 61.4799C267.795 61.5465 267.881 61.6332 267.948 61.7346C268.014 61.836 268.059 61.95 268.08 62.0695L274.284 87.6866C274.336 87.9146 274.297 88.1541 274.175 88.3535C274.053 88.553 273.857 88.6967 273.63 88.7537C273.563 88.7686 273.495 88.7761 273.427 88.7762Z'
          fill='#F5F5F5'
        />
        <path
          d='M267.215 64.0474C266.983 64.0475 266.754 64.0017 266.539 63.9127C266.325 63.8237 266.131 63.6933 265.967 63.5289L244.648 42.2251C244.484 42.0608 244.354 41.8659 244.265 41.6514C244.177 41.4369 244.131 41.2071 244.132 40.9751C244.132 40.743 244.178 40.5134 244.267 40.2991C244.357 40.0849 244.487 39.8903 244.652 39.7265C244.816 39.5627 245.011 39.4329 245.226 39.3444C245.44 39.2559 245.67 39.2106 245.903 39.2109C246.135 39.2113 246.365 39.2573 246.579 39.3464C246.793 39.4356 246.988 39.566 247.152 39.7303L267.215 59.7791L278.924 48.094C279.085 47.9178 279.28 47.776 279.498 47.6773C279.715 47.5786 279.95 47.5249 280.189 47.5195C280.428 47.5141 280.665 47.5571 280.887 47.6459C281.108 47.7347 281.31 47.8675 281.478 48.0362C281.647 48.205 281.78 48.4062 281.869 48.6276C281.958 48.8491 282.001 49.0863 281.996 49.3249C281.99 49.5635 281.936 49.7984 281.838 50.0157C281.739 50.2329 281.597 50.4279 281.421 50.5888L268.464 63.5289C268.133 63.8602 267.684 64.0467 267.215 64.0474Z'
          fill='#EBEBEB'
        />
        <path d='M166.568 240.57H172.509V199.917H166.568V240.57Z' fill='#F0F0F0' />
        <path d='M46.4059 246.064L168.591 246.064V240.579L46.4059 240.579V246.064Z' fill='#F0F0F0' />
        <path d='M166.568 199.926H42.4805V240.58H166.568V199.926Z' fill='#F5F5F5' />
        <path d='M158.536 206.996H50.5039V230.216H158.536V206.996Z' fill='#F0F0F0' />
        <path
          d='M78.2457 210.115H130.811C131.795 210.115 132.593 209.318 132.593 208.334V208.004C132.593 207.02 131.795 206.223 130.811 206.223H78.2457C77.2614 206.223 76.4635 207.02 76.4635 208.004V208.334C76.4635 209.318 77.2614 210.115 78.2457 210.115Z'
          fill='#F5F5F5'
        />
        <path d='M39.7586 199.926L166.568 199.926V196.296L39.7586 196.296V199.926Z' fill='#F0F0F0' />
        <path d='M175.359 196.287H166.568V199.917H175.359V196.287Z' fill='#E6E6E6' />
        <path d='M323.127 157.002H311.546V246.065H323.127V157.002Z' fill='#F0F0F0' />
        <path d='M210.184 246.057H311.546V156.994H210.184V246.057Z' fill='#F5F5F5' />
        <path d='M209.259 238.242H298.251V190.607H209.259V238.242Z' fill='#FAFAFA' />
        <path d='M209.259 185.549H298.251V169.866H209.259V185.549Z' fill='#FAFAFA' />
        <g style={{ mixBlendMode: 'multiply' }} opacity='0.7'>
          <path d='M299.048 190.607H298.251V238.242H299.048V190.607Z' fill='#E6E6E6' />
        </g>
        <g style={{ mixBlendMode: 'multiply' }} opacity='0.7'>
          <path d='M299.048 169.875H298.251V185.558H299.048V169.875Z' fill='#E6E6E6' />
        </g>
        <g style={{ mixBlendMode: 'multiply' }} opacity='0.7'>
          <path d='M323.127 160.76H209.929V162.774H323.127V160.76Z' fill='#E6E6E6' />
        </g>
        <path d='M324.48 155.785H311.546V161.609H324.48V155.785Z' fill='#F0F0F0' />
        <path d='M208.83 161.602H311.546V155.778H208.83V161.602Z' fill='#F5F5F5' />
        <path
          d='M247.363 138.636C246.664 137.536 246.203 136.302 246.009 135.014C245.805 133.727 245.74 132.422 245.814 131.121C245.973 128.533 246.526 125.984 247.453 123.561C247.892 122.359 248.394 121.18 248.957 120.03C249.559 118.902 250.16 117.775 250.837 116.701C251.514 115.626 252.258 114.589 253.025 113.582C253.792 112.575 254.657 111.628 255.552 110.727C255.116 111.936 254.597 113.079 254.048 114.213C253.499 115.348 253.07 116.513 252.544 117.617L251.115 120.984L250.416 122.712L250.07 123.554L249.769 124.418C248.909 126.676 248.228 128.997 247.731 131.362C247.472 132.543 247.296 133.74 247.205 134.946C247.093 136.177 247.146 137.418 247.363 138.636Z'
          fill='#E6E6E6'
        />
        <path
          d='M232.767 140.628C232.864 138.813 232.705 136.994 232.293 135.225C231.91 133.611 231.156 132.108 230.09 130.836C228.957 129.573 227.614 128.515 226.119 127.71C224.578 126.816 222.878 126.11 221.126 125.29C223.073 125.083 225.042 125.293 226.901 125.907C227.862 126.221 228.781 126.65 229.639 127.184C230.517 127.743 231.31 128.427 231.992 129.213C233.358 130.825 234.151 132.844 234.248 134.954C234.351 136.953 233.834 138.934 232.767 140.628Z'
          fill='#E6E6E6'
        />
        <path
          d='M233.42 140.365C233.357 138.251 233.159 136.142 232.826 134.053C232.517 132.062 231.964 130.116 231.179 128.259C230.396 126.434 229.319 124.748 227.991 123.27C226.558 121.741 224.964 120.37 223.238 119.182C225.462 119.526 227.56 120.437 229.329 121.827C231.145 123.265 232.57 125.136 233.473 127.267C234.366 129.364 234.809 131.624 234.774 133.903C234.786 136.128 234.325 138.331 233.42 140.365Z'
          fill='#E6E6E6'
        />
        <path
          d='M246.363 136.525C244.731 134.406 243.343 132.109 242.227 129.679C241.094 127.254 240.16 124.741 239.437 122.164C238.705 119.586 238.219 116.944 237.985 114.274C237.739 111.6 237.863 108.904 238.354 106.264C238.903 108.894 239.324 111.449 239.925 113.974C240.527 116.498 241.129 119.008 241.843 121.488C242.557 123.968 243.279 126.463 244.054 128.95C244.829 131.437 245.618 133.955 246.363 136.525Z'
          fill='#E6E6E6'
        />
        <path
          d='M245.686 136.337C243.822 134.519 242.163 132.503 240.738 130.325C239.307 128.158 238.049 125.882 236.978 123.517C235.89 121.145 235.024 118.677 234.391 116.145C233.75 113.609 233.46 110.998 233.526 108.383C234.473 110.825 235.293 113.207 236.263 115.544C237.233 117.881 238.196 120.196 239.271 122.488C240.346 124.78 241.384 127.064 242.482 129.363C243.58 131.663 244.64 133.947 245.686 136.337Z'
          fill='#E6E6E6'
        />
        <path
          d='M248.483 138.733C246.373 137.622 244.414 136.246 242.655 134.637C240.883 133.038 239.265 131.277 237.82 129.377C236.349 127.485 235.088 125.439 234.06 123.275C233.032 121.112 232.347 118.803 232.029 116.43C233.323 118.451 234.466 120.427 235.789 122.314C237.113 124.2 238.391 126.071 239.797 127.859C241.204 129.648 242.61 131.466 244.069 133.255C245.528 135.043 247.009 136.854 248.483 138.733Z'
          fill='#E6E6E6'
        />
        <path
          d='M244.4 136.345C242.35 135.507 240.451 134.342 238.775 132.895C237.084 131.473 235.569 129.854 234.263 128.071C233.602 127.181 232.989 126.255 232.428 125.298C231.885 124.326 231.401 123.322 230.977 122.292C230.112 120.251 229.581 118.085 229.405 115.875C230.586 117.761 231.556 119.632 232.729 121.391C233.256 122.292 233.887 123.142 234.436 124.021C234.985 124.9 235.624 125.742 236.271 126.561C237.514 128.244 238.818 129.885 240.181 131.483C241.505 133.121 242.896 134.699 244.4 136.345Z'
          fill='#E6E6E6'
        />
        <path
          d='M247.604 137.637C246.631 136.951 245.854 136.024 245.348 134.947C244.826 133.872 244.458 132.728 244.258 131.55C243.882 129.214 243.948 126.828 244.453 124.517C244.695 123.382 244.997 122.261 245.355 121.158C245.784 120.083 246.168 118.993 246.679 117.964C247.19 116.934 247.732 115.927 248.341 114.958C248.95 113.989 249.627 113.064 250.364 112.178C250.123 113.32 249.785 114.395 249.461 115.469C249.138 116.544 248.867 117.626 248.514 118.67C248.16 119.715 247.95 120.797 247.627 121.841L247.258 123.427C247.13 123.953 246.98 124.472 246.905 125.005C246.451 127.092 246.219 129.22 246.213 131.355C246.203 132.421 246.298 133.486 246.499 134.534C246.687 135.623 247.061 136.673 247.604 137.637Z'
          fill='#E6E6E6'
        />
        <path
          d='M246.205 135.262C244.555 133.499 243.36 131.361 242.723 129.033C242.029 126.687 241.806 124.228 242.069 121.796C242.106 121.187 242.257 120.594 242.354 119.985L242.527 119.091L242.791 118.219C242.986 117.64 243.152 117.054 243.37 116.491C243.588 115.927 243.873 115.394 244.122 114.853L244.528 114.049L245.017 113.297L246.009 111.794C246.753 110.868 247.554 109.99 248.408 109.164C248.017 110.306 247.498 111.336 247.077 112.395L246.483 114.003L246.182 114.755L245.941 115.566C245.784 116.1 245.618 116.634 245.445 117.16L245.077 118.775L244.881 119.579L244.768 120.391C244.701 120.932 244.565 121.465 244.535 122.014C244.306 124.202 244.336 126.409 244.625 128.589C244.904 130.865 245.434 133.103 246.205 135.262Z'
          fill='#E6E6E6'
        />
        <path d='M243.031 134.323L232.255 124.742L233.849 137.75L243.031 134.323Z' fill='#E6E6E6' />
        <path d='M222.261 132.572H253.529L249.431 155.785H226.359L222.261 132.572Z' fill='#F5F5F5' />
        <path d='M241.798 132.572H253.529L249.431 155.785H243.272L241.798 132.572Z' fill='#EBEBEB' />
        <path
          d='M281.143 138.681C281.29 137.655 281.247 136.61 281.015 135.6C280.815 134.631 280.505 133.688 280.09 132.789C279.246 130.996 278.177 129.317 276.909 127.792C276.623 127.379 276.255 127.041 275.932 126.635C275.608 126.229 275.277 125.884 274.931 125.493C274.179 124.741 273.533 123.99 272.788 123.238C272.044 122.487 271.329 121.736 270.577 120.984C269.825 120.233 269.073 119.481 268.321 118.662C269.329 119.057 270.315 119.503 271.277 120C272.227 120.518 273.148 121.088 274.036 121.706C274.487 122.01 274.919 122.341 275.33 122.697C275.751 123.043 276.165 123.396 276.578 123.757C277.382 124.511 278.136 125.317 278.834 126.169C280.277 127.904 281.331 129.929 281.925 132.106C282.21 133.209 282.309 134.352 282.218 135.487C282.166 136.631 281.793 137.738 281.143 138.681Z'
          fill='#E6E6E6'
        />
        <path
          d='M298.724 135.533C297.933 134.849 297.348 133.959 297.032 132.963C296.858 132.447 296.744 131.912 296.694 131.369C296.679 131.097 296.679 130.823 296.694 130.55C296.685 130.273 296.71 129.995 296.769 129.724C297.214 127.588 298.331 125.65 299.958 124.193C300.334 123.832 300.762 123.539 301.161 123.209C301.555 122.877 301.978 122.58 302.424 122.322C302.86 122.066 303.296 121.803 303.748 121.57C303.966 121.45 304.184 121.315 304.417 121.21L305.109 120.917C305.575 120.721 306.041 120.541 306.507 120.361C306.972 120.174 307.45 120.021 307.936 119.902C308.905 119.652 309.887 119.454 310.877 119.309C310.097 119.922 309.287 120.493 308.448 121.022C308.034 121.27 307.643 121.548 307.252 121.833L306.064 122.63C305.244 123.118 304.56 123.742 303.808 124.268C303.431 124.529 303.074 124.818 302.74 125.132C302.394 125.425 301.988 125.696 301.702 126.012C300.401 127.166 299.374 128.596 298.694 130.197C298.551 130.6 298.435 131.011 298.348 131.43C298.279 131.864 298.244 132.304 298.243 132.745C298.242 133.695 298.405 134.638 298.724 135.533Z'
          fill='#E6E6E6'
        />
        <path
          d='M297.92 135.533C296.563 133.761 295.823 131.595 295.815 129.363C295.785 127.083 296.366 124.836 297.499 122.856L298.401 121.421C298.747 120.985 299.116 120.564 299.484 120.143L300.041 119.519L300.657 118.978C301.079 118.61 301.5 118.227 301.921 117.911C302.816 117.288 303.756 116.731 304.696 116.205C305.678 115.764 306.682 115.373 307.704 115.033C306.952 115.837 306.2 116.536 305.395 117.212C304.59 117.889 303.936 118.67 303.229 119.384C302.898 119.767 302.575 120.136 302.237 120.519L301.733 121.067L301.304 121.669C301.018 122.067 300.703 122.42 300.424 122.841L299.672 124.081C298.728 125.752 298.091 127.58 297.792 129.476C297.476 131.487 297.519 133.538 297.92 135.533Z'
          fill='#E6E6E6'
        />
        <path
          d='M282.105 136.644C282.73 134.315 283.406 132.083 284.061 129.844C284.715 127.604 285.354 125.387 285.941 123.163C286.527 120.939 287.016 118.707 287.445 116.445C287.873 114.183 288.106 111.936 288.309 109.494C289.099 111.792 289.471 114.212 289.407 116.64C289.317 119.056 288.966 121.454 288.362 123.794C287.749 126.121 286.921 128.385 285.888 130.557C284.88 132.733 283.61 134.777 282.105 136.644Z'
          fill='#E6E6E6'
        />
        <path
          d='M282.888 136.269C283.865 133.977 284.918 131.761 285.956 129.596C286.994 127.432 288.039 125.246 289.039 123.074C290.039 120.902 291.024 118.73 291.897 116.476C292.769 114.222 293.559 111.967 294.311 109.615C294.543 112.11 294.363 114.626 293.777 117.062C293.184 119.485 292.36 121.845 291.318 124.111C290.272 126.357 289.047 128.516 287.655 130.566C286.281 132.636 284.682 134.549 282.888 136.269Z'
          fill='#E6E6E6'
        />
        <path
          d='M279.827 139.132C281.294 137.11 282.835 135.216 284.339 133.308C285.843 131.399 287.347 129.551 288.806 127.634C290.265 125.718 291.694 123.817 293.062 121.856C294.431 119.894 295.679 117.858 296.95 115.701C296.619 118.185 295.88 120.597 294.762 122.84C293.647 125.071 292.317 127.187 290.791 129.16C289.269 131.12 287.592 132.954 285.775 134.645C283.962 136.353 281.968 137.857 279.827 139.132Z'
          fill='#E6E6E6'
        />
        <path
          d='M284.429 135.855C285.865 134.059 287.301 132.361 288.715 130.64C290.129 128.919 291.498 127.213 292.836 125.477C294.175 123.741 295.415 121.953 296.596 120.127C297.777 118.301 298.852 116.37 300.01 114.363C299.842 116.674 299.272 118.937 298.326 121.051C297.384 123.165 296.195 125.161 294.784 126.995C293.385 128.818 291.808 130.497 290.076 132.007C288.372 133.533 286.473 134.827 284.429 135.855Z'
          fill='#E6E6E6'
        />
        <path
          d='M280.751 137.953C281.566 136.322 281.959 134.514 281.894 132.693C281.795 130.957 281.35 129.258 280.586 127.695L280.33 127.087L280.007 126.501L279.375 125.306C278.871 124.554 278.42 123.758 277.871 122.984C277.322 122.21 276.826 121.436 276.27 120.662C275.685 119.883 275.141 119.076 274.638 118.242C275.501 118.718 276.339 119.237 277.149 119.798C277.936 120.402 278.689 121.05 279.405 121.736C279.762 122.086 280.096 122.457 280.406 122.849C280.726 123.229 281.042 123.62 281.353 124.021L282.158 125.313L282.541 125.975L282.857 126.726C283.775 128.631 284.114 130.763 283.835 132.858C283.695 133.872 283.35 134.847 282.82 135.724C282.289 136.6 281.586 137.358 280.751 137.953Z'
          fill='#E6E6E6'
        />
        <path
          d='M282.15 135.548C282.771 133.686 283.194 131.764 283.414 129.814C283.616 127.996 283.481 126.157 283.015 124.389C282.782 123.519 282.454 122.677 282.038 121.879C281.624 121.052 281.136 120.265 280.579 119.527C279.382 117.963 278.07 116.491 276.653 115.123C278.668 115.719 280.515 116.78 282.045 118.219C282.845 118.951 283.538 119.79 284.106 120.714C284.692 121.645 285.138 122.658 285.429 123.72C285.996 125.841 285.962 128.079 285.331 130.182C284.762 132.219 283.665 134.07 282.15 135.548Z'
          fill='#E6E6E6'
        />
        <path d='M285.828 133.775L297.589 122.488L297.116 133.55L285.828 133.775Z' fill='#E6E6E6' />
        <path d='M272.961 132.572H304.229L300.131 155.785H277.059L272.961 132.572Z' fill='#F5F5F5' />
        <path d='M292.49 132.572H304.229L300.131 155.785H293.972L292.49 132.572Z' fill='#EBEBEB' />
        <path
          d='M78.1106 26.8789V148.57C78.1116 151.077 77.6182 153.56 76.6588 155.876C75.6993 158.193 74.2925 160.298 72.5187 162.071C70.745 163.844 68.639 165.251 66.3211 166.211C64.0032 167.17 61.5188 167.664 59.0098 167.664H147.746C152.814 167.664 157.674 165.652 161.257 162.072C164.841 158.491 166.854 153.634 166.854 148.57V26.8789H78.1106Z'
          fill='#F5F5F5'
        />
        <path d='M152.927 57.6895H95.5869V114.988H152.927V57.6895Z' fill='#F0F0F0' />
        <path d='M151.167 55.9316H93.8271V113.23H151.167V55.9316Z' fill='white' />
        <path d='M107.905 121.705H93.3232V136.276H107.905V121.705Z' fill='white' />
        <path d='M151.159 121.705H136.578V136.276H151.159V121.705Z' fill='white' />
        <path d='M129.532 121.705H114.951V136.276H129.532V121.705Z' fill='white' />
        <path d='M166.869 26.8789H78.1104V41.367H166.869V26.8789Z' fill='#F0F0F0' />
        <path
          d='M83.4717 22.2273V31.5604C83.4717 32.248 83.745 32.9074 84.2316 33.3936C84.7181 33.8798 85.378 34.1529 86.0661 34.1529C86.7483 34.143 87.3994 33.8658 87.879 33.3809C88.3587 32.896 88.6285 32.2422 88.6304 31.5604V22.2273C88.6304 21.5397 88.3571 20.8803 87.8705 20.3941C87.384 19.9079 86.7241 19.6348 86.036 19.6348C85.3525 19.6407 84.6992 19.9167 84.2187 20.4025C83.7382 20.8883 83.4697 21.5443 83.4717 22.2273Z'
          fill='#E6E6E6'
        />
        <path
          d='M156.355 22.2273V31.5604C156.357 32.2474 156.631 32.9056 157.118 33.3914C157.604 33.8771 158.262 34.1509 158.95 34.1529C159.291 34.1529 159.628 34.0858 159.943 33.9556C160.257 33.8253 160.543 33.6343 160.784 33.3936C161.025 33.1528 161.216 32.867 161.347 32.5525C161.477 32.238 161.544 31.9008 161.544 31.5604V22.2273C161.545 21.8866 161.479 21.549 161.349 21.234C161.219 20.919 161.028 20.6328 160.787 20.3919C160.545 20.151 160.259 19.96 159.944 19.8301C159.629 19.7002 159.291 19.6338 158.95 19.6348C158.609 19.6348 158.272 19.7018 157.957 19.8321C157.642 19.9624 157.356 20.1534 157.115 20.3941C156.874 20.6348 156.683 20.9206 156.553 21.2352C156.423 21.5497 156.355 21.8868 156.355 22.2273Z'
          fill='#E6E6E6'
        />
        <path
          d='M141.774 22.2273V31.5604C141.784 32.2448 142.064 32.8976 142.552 33.3773C143.041 33.857 143.699 34.1249 144.384 34.1229C145.064 34.1171 145.714 33.8457 146.196 33.3668C146.678 32.8879 146.953 32.2396 146.963 31.5604V22.2273C146.963 21.5423 146.692 20.8852 146.209 20.3994C145.725 19.9137 145.069 19.6388 144.384 19.6348C144.042 19.6328 143.703 19.6984 143.386 19.8278C143.07 19.9572 142.782 20.1479 142.54 20.3888C142.297 20.6297 142.105 20.9162 141.973 21.2317C141.842 21.5473 141.774 21.8856 141.774 22.2273Z'
          fill='#E6E6E6'
        />
        <path
          d='M127.193 22.2273V31.5604C127.193 32.248 127.467 32.9074 127.953 33.3936C128.44 33.8798 129.1 34.1529 129.788 34.1529C130.471 34.145 131.123 33.8684 131.603 33.383C132.083 32.8977 132.352 32.2428 132.352 31.5604V22.2273C132.353 21.8872 132.287 21.5502 132.157 21.2357C132.028 20.9212 131.837 20.6354 131.597 20.3946C131.357 20.1537 131.071 19.9626 130.757 19.8323C130.443 19.7019 130.106 19.6348 129.765 19.6348C129.08 19.6387 128.425 19.9139 127.943 20.3999C127.461 20.8858 127.191 21.543 127.193 22.2273Z'
          fill='#E6E6E6'
        />
        <path
          d='M112.612 22.2273V31.5604C112.612 32.2467 112.885 32.9049 113.37 33.3909C113.854 33.8769 114.512 34.1509 115.199 34.1529C115.887 34.1529 116.547 33.8798 117.034 33.3936C117.52 32.9074 117.794 32.248 117.794 31.5604V22.2273C117.795 21.8866 117.728 21.549 117.598 21.234C117.468 20.919 117.277 20.6328 117.036 20.3919C116.795 20.151 116.508 19.96 116.193 19.8301C115.878 19.7002 115.54 19.6338 115.199 19.6348C114.859 19.6348 114.522 19.7019 114.208 19.8323C113.893 19.9626 113.608 20.1537 113.367 20.3946C113.127 20.6354 112.937 20.9213 112.807 21.2358C112.678 21.5502 112.611 21.8872 112.612 22.2273Z'
          fill='#E6E6E6'
        />
        <path
          d='M98.0234 22.2273V31.5604C98.0254 32.2473 98.2994 32.9056 98.7855 33.3914C99.2716 33.8771 99.9304 34.1509 100.618 34.1529C101.305 34.1509 101.964 33.8771 102.45 33.3914C102.936 32.9056 103.21 32.2473 103.212 31.5604V22.2273C103.212 21.5397 102.939 20.8803 102.452 20.3941C101.966 19.9079 101.306 19.6348 100.618 19.6348C100.277 19.6348 99.9398 19.7018 99.625 19.8321C99.3102 19.9624 99.0242 20.1534 98.7833 20.3941C98.5424 20.6348 98.3513 20.9206 98.2209 21.2352C98.0905 21.5497 98.0234 21.8868 98.0234 22.2273Z'
          fill='#E6E6E6'
        />
        <path
          d='M139.774 159.678C139.774 161.796 140.617 163.828 142.116 165.326C143.615 166.824 145.648 167.666 147.768 167.666H47.8951C45.775 167.666 43.7418 166.824 42.2427 165.326C40.7436 163.828 39.9014 161.796 39.9014 159.678H139.774Z'
          fill='#E6E6E6'
        />
        <path
          d='M188 279.999C268.526 279.999 333.805 276.191 333.805 271.493C333.805 266.795 268.526 262.986 188 262.986C107.474 262.986 42.1943 266.795 42.1943 271.493C42.1943 276.191 107.474 279.999 188 279.999Z'
          fill='#F5F5F5'
        />
        <path
          opacity='0.2'
          d='M202.731 199.723C202.731 199.723 147.467 232.328 147.467 263.221H258.011C258.003 232.328 202.731 199.723 202.731 199.723Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.2'
          d='M202.732 238.625C202.732 238.625 151.701 251.249 151.701 263.22H253.763C253.763 251.249 202.732 238.625 202.732 238.625Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.3'
          d='M209.439 208.725C219.817 218.914 239.986 241.518 239.986 263.22H226.156C226.156 241.518 215.117 218.914 209.439 208.725Z'
          fill='white'
        />
        <path
          opacity='0.2'
          d='M202.731 199.723C202.731 199.723 147.467 167.117 147.467 136.225H258.011C258.003 167.117 202.731 199.723 202.731 199.723Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.2'
          d='M202.732 196.739C202.732 196.739 174.517 183.213 174.517 170.363H230.954C230.954 183.198 202.732 196.739 202.732 196.739Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.3'
          d='M196.709 190.713C186.338 180.523 166.192 157.927 166.192 136.225H180.022C179.992 157.927 191.008 180.523 196.709 190.713Z'
          fill='white'
        />
        <path
          opacity='0.3'
          d='M209.439 190.713C219.817 180.523 239.482 155.312 239.986 136.225H236.301C234.571 159.49 217.651 180.854 209.439 190.713Z'
          fill='white'
        />
        <path d='M250.499 125.32H244.234V126.072H250.499V125.32Z' fill='#00ACE7' />
        <path
          d='M271.765 135.645H271.013C271.011 133.108 270.002 130.675 268.208 128.881C266.413 127.086 263.979 126.076 261.44 126.072H254.83V125.32H261.44C264.179 125.324 266.804 126.414 268.739 128.349C270.675 130.285 271.763 132.909 271.765 135.645Z'
          fill='#00ACE7'
        />
        <path
          d='M261.433 127.951H144.031C139.778 127.951 136.33 131.396 136.33 135.646V136.803C136.33 141.053 139.778 144.498 144.031 144.498H261.433C265.686 144.498 269.133 141.053 269.133 136.803V135.646C269.133 131.396 265.686 127.951 261.433 127.951Z'
          fill='#00ACE7'
        />
        <path
          d='M261.433 254.947H144.031C139.778 254.947 136.33 258.392 136.33 262.642V263.799C136.33 268.049 139.778 271.494 144.031 271.494H261.433C265.686 271.494 269.133 268.049 269.133 263.799V262.642C269.133 258.392 265.686 254.947 261.433 254.947Z'
          fill='#00ACE7'
        />
        <path d='M105.822 187.542L100.724 183.018L109.417 169.672L114.515 174.196L105.822 187.542Z' fill='#FF8B7B' />
        <path
          d='M101.776 180.146L108.258 184.655C108.371 184.726 108.45 184.838 108.48 184.968C108.509 185.097 108.487 185.233 108.416 185.346L105.168 190.689C104.982 190.959 104.697 191.145 104.375 191.207C104.052 191.269 103.718 191.202 103.446 191.02C101.19 189.411 100.355 188.637 97.6552 186.458C95.9933 185.121 92.0302 181.897 89.9848 179.778C87.9393 177.659 90.0976 175.697 91.1579 176.411C93.6621 178.11 98.0161 179.973 100.535 179.83C100.973 179.793 101.41 179.905 101.776 180.146Z'
          fill='#263238'
        />
        <path
          opacity='0.2'
          d='M114.507 174.202L109.409 169.678L105.228 176.103L110.454 180.431L114.507 174.202Z'
          fill='black'
        />
        <path
          d='M166.381 107.57C166.381 107.57 132.165 105.233 127.924 116.82C118.275 143.121 105.725 171.113 105.725 171.113L115.839 178.402C115.839 178.402 137.158 145.834 143.663 124.921C156.823 128.107 180.616 130.843 188.279 122.051C194.822 114.536 189.926 107.57 189.926 107.57H166.381Z'
          fill='#263238'
        />
        <path
          opacity='0.3'
          d='M137.075 128.047C136.278 131.256 135.067 140.476 134.315 146.585C135.977 143.369 137.557 140.108 138.978 136.922L137.075 128.047Z'
          fill='black'
        />
        <path d='M115.162 181.709L103.724 172.752L106.062 167.732L118.846 177.494L115.162 181.709Z' fill='#00ACE7' />
        <path
          d='M99.0316 179.734C99.5603 180.08 100.125 180.367 100.716 180.59C100.741 180.608 100.772 180.618 100.803 180.618C100.834 180.618 100.864 180.608 100.889 180.59C100.911 180.568 100.926 180.54 100.933 180.51C100.94 180.479 100.937 180.447 100.927 180.418C100.829 180.162 99.9716 177.908 99.0768 177.697C98.9741 177.674 98.8672 177.677 98.766 177.706C98.6647 177.735 98.5724 177.789 98.4977 177.863C98.3713 177.962 98.2734 178.093 98.214 178.242C98.1545 178.391 98.1357 178.553 98.1593 178.712C98.3178 179.146 98.6274 179.509 99.0316 179.734ZM100.483 180.14C99.3023 179.636 98.5654 179.073 98.4827 178.637C98.4697 178.532 98.4852 178.426 98.5276 178.329C98.5699 178.233 98.6375 178.15 98.7233 178.088C98.7571 178.049 98.8011 178.021 98.8504 178.006C98.8997 177.992 98.9521 177.991 99.0015 178.005C99.4828 178.096 100.107 179.253 100.483 180.14Z'
          fill='#00ACE7'
        />
        <path
          d='M100.679 180.574H100.754C100.782 180.577 100.81 180.573 100.836 180.563C100.863 180.552 100.886 180.536 100.904 180.514C100.957 180.446 102.235 178.876 102.047 177.914C102.024 177.79 101.971 177.673 101.894 177.572C101.817 177.472 101.718 177.391 101.604 177.335C101.488 177.262 101.352 177.229 101.216 177.241C101.08 177.253 100.952 177.31 100.852 177.403C100.28 177.937 100.303 179.74 100.641 180.476C100.643 180.512 100.656 180.547 100.679 180.574ZM101.513 177.689C101.568 177.726 101.615 177.774 101.651 177.829C101.687 177.885 101.712 177.947 101.724 178.012C101.837 178.583 101.228 179.567 100.829 180.101C100.618 179.349 100.686 178.019 101.055 177.666C101.13 177.598 101.235 177.538 101.453 177.666L101.513 177.689Z'
          fill='#00ACE7'
        />
        <path d='M150.777 195.422L144.197 196.737L142.979 179.131L149.664 179.341L150.777 195.422Z' fill='#FF8B7B' />
        <path
          d='M143.302 194.334L150.822 191.163C150.946 191.106 151.089 191.1 151.218 191.146C151.347 191.192 151.453 191.287 151.513 191.411L154.386 197.189C154.517 197.502 154.521 197.853 154.396 198.168C154.271 198.483 154.027 198.736 153.717 198.873C151.085 199.925 149.776 200.3 146.475 201.691C144.445 202.547 138.955 205.035 136.022 205.764C133.089 206.492 132.428 203.554 133.616 202.983C136.428 201.623 141.091 197.505 142.346 195.228C142.556 194.832 142.892 194.517 143.302 194.334Z'
          fill='#263238'
        />
        <path
          d='M141.429 196.498C142.022 196.228 142.584 195.895 143.106 195.507C143.132 195.487 143.152 195.46 143.164 195.429C143.176 195.399 143.179 195.366 143.174 195.334C143.166 195.302 143.149 195.273 143.125 195.25C143.101 195.227 143.071 195.212 143.039 195.206C142.768 195.146 140.331 194.65 139.647 195.319C139.568 195.394 139.511 195.488 139.481 195.592C139.45 195.696 139.448 195.807 139.474 195.912C139.491 196.075 139.551 196.23 139.647 196.362C139.743 196.494 139.873 196.598 140.023 196.664C140.496 196.785 140.997 196.726 141.429 196.498ZM142.58 195.461C141.489 196.213 140.595 196.536 140.151 196.356C140.052 196.312 139.968 196.241 139.907 196.152C139.846 196.064 139.811 195.96 139.805 195.852C139.79 195.801 139.79 195.747 139.803 195.695C139.816 195.644 139.843 195.597 139.88 195.559C140.249 195.198 141.602 195.296 142.58 195.461Z'
          fill='#00ACE7'
        />
        <path
          d='M143.069 195.522C143.095 195.514 143.119 195.498 143.136 195.477C143.149 195.452 143.155 195.425 143.155 195.398C143.155 195.37 143.149 195.343 143.136 195.319C143.136 195.229 142.444 193.252 141.497 192.884C141.375 192.836 141.244 192.817 141.114 192.827C140.984 192.837 140.857 192.877 140.745 192.944C140.613 193.005 140.506 193.109 140.441 193.239C140.375 193.368 140.355 193.516 140.384 193.658C140.534 194.447 142.121 195.424 142.956 195.537C142.994 195.547 143.034 195.541 143.069 195.522ZM141.016 193.207C141.077 193.179 141.144 193.165 141.211 193.165C141.279 193.165 141.345 193.179 141.407 193.207C141.971 193.425 142.49 194.5 142.745 195.146C141.993 194.898 140.843 194.116 140.745 193.598C140.745 193.5 140.745 193.372 140.948 193.245L141.016 193.207Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.2'
          d='M149.671 179.341L142.979 179.131L143.618 188.329L150.28 188.208L149.671 179.341Z'
          fill='black'
        />
        <path
          d='M183 107.57C183 107.57 134.12 112.192 135.879 126.898C138.451 148.111 140.331 185.256 140.331 185.256L152.792 185.744C152.792 185.744 157.469 153.086 154.732 131.361C167.388 131.361 194.881 132.023 199.717 125.493C205.454 117.738 202.8 107.57 202.8 107.57H183Z'
          fill='#263238'
        />
        <path d='M154.168 186.511L139.211 185.767L138.677 179.26L155.078 180.394L154.168 186.511Z' fill='#00ACE7' />
        <path
          d='M172.149 64.5049C172.043 65.85 171.923 67.0448 171.758 68.2622C171.592 69.4796 171.434 70.742 171.239 71.9744C170.878 74.4542 170.382 76.9115 169.825 79.3837C169.269 81.856 168.584 84.3208 167.727 86.8006C166.858 89.4177 165.716 91.9363 164.32 94.3152C163.49 95.7139 162.478 96.9974 161.312 98.1326C161.004 98.4228 160.68 98.6963 160.342 98.9517C160.034 99.2071 159.726 99.4176 159.41 99.643C158.809 100.067 158.183 100.456 157.537 100.808C155.151 102.101 152.629 103.127 150.017 103.866C148.777 104.242 147.528 104.558 146.257 104.828C144.987 105.099 143.776 105.347 142.497 105.542C140.01 105.956 137.498 106.207 134.977 106.293C134.356 106.32 133.746 106.12 133.261 105.732C132.776 105.343 132.448 104.792 132.338 104.181C132.227 103.569 132.343 102.939 132.662 102.405C132.982 101.872 133.484 101.473 134.075 101.281H134.173C138.583 99.8872 142.909 98.2413 147.13 96.3516C149.069 95.471 150.926 94.4199 152.679 93.2105C153.078 92.9175 153.431 92.6319 153.792 92.3388C153.958 92.1961 154.131 92.0533 154.274 91.903C154.406 91.7792 154.531 91.6487 154.65 91.5122C155.151 90.9132 155.58 90.2574 155.928 89.5585C156.838 87.7054 157.571 85.7706 158.116 83.7798C158.726 81.6682 159.252 79.4664 159.688 77.2271C160.124 74.9877 160.515 72.7183 160.884 70.4189C161.252 68.1194 161.531 65.7749 161.786 63.5881V63.4679C161.974 62.1502 162.665 60.956 163.713 60.1348C164.762 59.3135 166.087 58.9287 167.412 59.0606C168.738 59.1924 169.961 59.8309 170.827 60.8425C171.693 61.8542 172.134 63.1609 172.059 64.4899L172.149 64.5049Z'
          fill='#00ACE7'
        />
        <path
          d='M135.225 99.9883L131.465 100.229L134.473 107.142C134.473 107.142 140.978 106.992 140.842 103.791L139.669 102.288C139.132 101.614 138.458 101.06 137.693 100.664C136.927 100.268 136.086 100.038 135.225 99.9883Z'
          fill='#FF8B7B'
        />
        <path d='M127.344 100.785L130.592 107.142H134.465L131.457 100.229L127.344 100.785Z' fill='#FF8B7B' />
        <path
          d='M171.607 53.849L178.879 52.3461L184.67 51.5045C185.497 51.3718 186.338 51.3365 187.174 51.3993C189.395 51.6001 191.502 52.4731 193.214 53.9017C194.925 55.3303 196.161 57.2465 196.754 59.3948C198.206 64.6549 199.514 71.1024 200.514 76.8285C201.913 84.7714 202.635 96.7046 202.77 107.556H168.321C167.569 87.905 163.388 75.243 161.967 67.3902C161.537 65.0194 161.9 62.5733 163 60.4291C164.099 58.2848 165.874 56.5615 168.05 55.5248L171.607 53.849Z'
          fill='#00ACE7'
        />
        <path
          d='M168.32 58.1698C168.403 61.401 169.072 72.9208 172.539 79.466C173.148 73.3341 180.435 54.668 180.435 54.668L168.32 58.1698Z'
          fill='#F5F5F5'
        />
        <path
          opacity='0.3'
          d='M201.303 82.2311L201.258 81.8704L201.19 81.3219L201.077 80.4577L200.965 79.6536L200.852 78.9022C200.852 78.6918 200.784 78.4739 200.754 78.2635C200.679 77.79 200.604 77.3166 200.521 76.8657C200.273 75.4605 200.01 74.0027 199.724 72.5374V72.4096C199.438 70.9067 199.145 69.4639 198.829 67.9911V67.8934C198.701 67.2697 198.566 66.6459 198.423 66.0298L198.265 65.3384C198.175 64.9326 198.085 64.5419 197.994 64.1436L197.769 63.2118L197.724 63.0164C197.423 61.784 197.114 60.5742 196.799 59.432L191.572 56.6816C192.174 58.9886 192.745 61.521 193.287 64.1286C192.663 64.4556 192.086 64.8651 191.572 65.3459C190.324 66.4656 190.369 68.8327 191.617 71.6281C195.069 79.6365 196.982 88.2221 197.257 96.9371C197.415 100.484 197.513 104.068 197.558 107.548H202.822C202.672 98.9585 202.19 89.6706 201.303 82.2311Z'
          fill='black'
        />
        <path
          d='M176.171 39.3691L173.216 43.8779L169.2 49.9947C169.725 50.533 170.148 51.1619 170.449 51.8508C171.201 53.3537 171.787 54.4283 168.681 56.5474C168.02 56.9983 167.997 57.7122 168.147 58.5012C168.328 59.4631 168.899 60.4625 172.238 59.8764C175.465 59.2561 178.41 57.6241 180.646 55.2174C181.759 54.0977 181.503 53.256 180.428 52.1063C177.382 48.7999 176.472 43.5848 176.171 39.3691Z'
          fill='#FF8B7B'
        />
        <path
          d='M169.072 59.9507L171.2 61.2808L174.66 59.7779C173.843 58.9577 172.792 58.4115 171.652 58.2148C170.652 58.5524 169.761 59.1518 169.072 59.9507Z'
          fill='#263238'
        />
        <path
          d='M171.614 58.2535C173.201 58.9073 173.998 60.3802 174.72 62.0785C174.72 62.0785 182.323 56.3148 182.481 52.5275C182.075 51.235 180.172 51.4604 180.172 51.4604C180.172 51.4604 177.856 55.4656 171.614 58.2535Z'
          fill='white'
        />
        <path
          d='M171.615 58.2517C170.873 58.5514 170.243 59.074 169.812 59.7472C169.381 60.4203 169.17 61.2107 169.208 62.009C169.208 62.009 165.824 58.0413 168.577 55.043C169.324 54.6501 170.117 54.3522 170.938 54.1562C170.938 54.1562 170.111 55.817 171.615 58.2517Z'
          fill='white'
        />
        <path
          d='M171.87 60.6641H170.9C170.584 61.2051 170.148 68.2538 169.975 71.996C170.438 74.1879 171.121 76.3278 172.013 78.3834C172.01 78.4083 172.01 78.4336 172.013 78.4585C172.065 78.5713 172.118 78.6915 172.178 78.8042C172.238 78.9169 172.389 79.2476 172.509 79.4655C172.856 77.1377 173.397 74.8428 174.126 72.6047C173.133 68.2613 171.87 60.6641 171.87 60.6641Z'
          fill='#263238'
        />
        <path
          opacity='0.2'
          d='M170.193 48.4907L172.66 44.1172C172.705 44.2299 172.765 44.3501 172.825 44.4779C173.041 45.0228 173.217 45.5829 173.352 46.1536C173.803 48.0924 172.299 50.8653 170.637 52.1653L170.441 51.7896C170.147 51.1576 169.765 50.5706 169.306 50.0462C169.277 50.0165 169.244 49.9912 169.208 49.971L170.193 48.4907Z'
          fill='black'
        />
        <path
          d='M158.176 39.0747C157.582 36.3995 161.831 32.7174 161.831 32.7174L156.8 30.959C154.529 32.6272 156.003 37.2862 158.176 39.0747Z'
          fill='#263238'
        />
        <path
          d='M175.126 31.4036C176.314 38.069 177.382 41.924 174.87 46.1847C174.083 47.5838 172.928 48.7411 171.53 49.5314C170.132 50.3217 168.545 50.7149 166.939 50.6684C165.334 50.622 163.772 50.1377 162.422 49.2679C161.072 48.3981 159.986 47.176 159.281 45.7338C156.461 40.2332 154.995 30.2539 160.733 25.8729C161.949 24.8932 163.401 24.2502 164.944 24.0081C166.487 23.7661 168.066 23.9335 169.524 24.4937C170.982 25.0539 172.267 25.9871 173.25 27.1999C174.233 28.4126 174.88 29.8623 175.126 31.4036Z'
          fill='#FF8B7B'
        />
        <path
          d='M175.217 38.1503L172.058 37.2861V33.2208C172.058 33.2208 168.065 30.9664 168.682 28.1484C168.982 26.5669 169.407 25.0116 169.953 23.4969C169.953 23.4969 176.285 23.0911 178.225 23.8952C177.724 24.4406 177.178 24.9433 176.593 25.3981C178.111 26.4477 179.413 27.7795 180.428 29.3207C179.635 29.4972 178.894 29.8547 178.262 30.3652C178.262 30.3652 179.105 35.941 175.217 38.1503Z'
          fill='#263238'
        />
        <path
          d='M167.952 19.1694C170.276 20.6197 168.93 23.3024 168.93 23.3024L175.149 23.4301C174.818 21.4688 169.426 19.0641 167.952 19.1694Z'
          fill='#263238'
        />
        <path
          d='M170.381 29.516C169.953 30.8086 164.899 32.3115 160.304 32.4542C158.014 32.4046 155.748 31.978 153.596 31.1918C154.245 30.526 155.01 29.9837 155.852 29.5912C153.078 28.8999 149.566 26.4651 148.596 24.4888C150.618 23.7527 152.807 23.5991 154.912 24.0454C154.225 23.4651 153.659 22.7535 153.25 21.9522C152.841 21.1509 152.595 20.2761 152.529 19.3789C152.529 19.3789 164.29 24.0379 168.817 22.1067C173.735 20.0026 177.472 23.9478 174.239 26.9536C171.005 29.9594 170.381 29.516 170.381 29.516Z'
          fill='#263238'
        />
        <path
          d='M178.721 36.2356C178.785 37.7074 178.306 39.1514 177.375 40.2935C176.157 41.7438 174.262 40.5866 173.825 38.7906C173.434 37.1599 173.946 34.635 175.548 33.861C177.149 33.087 178.638 34.4021 178.721 36.2356Z'
          fill='#FF8B7B'
        />
        <path
          d='M164.96 37.5722C165.125 38.0982 164.96 38.6168 164.659 38.7445C164.358 38.8722 163.907 38.5416 163.734 37.993C163.561 37.4445 163.734 36.9485 164.035 36.8208C164.335 36.693 164.787 37.0537 164.96 37.5722Z'
          fill='#263238'
        />
        <path
          d='M159.131 39.7148C159.297 40.2333 159.131 40.7594 158.831 40.8796C158.53 40.9998 158.079 40.6842 157.906 40.1281C157.733 39.5721 157.906 39.0836 158.206 38.9634C158.507 38.8431 158.958 39.1888 159.131 39.7148Z'
          fill='#263238'
        />
        <path
          d='M161.124 39.4355C161.149 40.9907 160.967 42.5422 160.583 44.0495C160.965 44.1306 161.359 44.1262 161.739 44.0365C162.119 43.9469 162.474 43.7742 162.779 43.531L161.124 39.4355Z'
          fill='#A02724'
        />
        <path
          d='M164.689 45.5376C165.294 45.336 165.853 45.0145 166.331 44.5923C166.809 44.1701 167.197 43.6558 167.471 43.0804C167.486 43.0404 167.485 42.9962 167.468 42.957C167.452 42.9178 167.42 42.8866 167.381 42.87C167.341 42.8549 167.297 42.8559 167.258 42.8727C167.218 42.8895 167.187 42.9209 167.17 42.9601C166.865 43.5816 166.421 44.1245 165.872 44.5467C165.323 44.9689 164.684 45.2591 164.005 45.3949C163.984 45.3983 163.964 45.4061 163.946 45.4177C163.929 45.4292 163.914 45.4444 163.903 45.4621C163.891 45.4798 163.884 45.4997 163.881 45.5204C163.877 45.5412 163.879 45.5624 163.884 45.5827C163.888 45.6036 163.896 45.6236 163.907 45.6414C163.919 45.6592 163.934 45.6746 163.951 45.6865C163.969 45.6984 163.988 45.7067 164.009 45.7108C164.03 45.7149 164.051 45.7148 164.072 45.7105C164.29 45.6579 164.493 45.6053 164.689 45.5376Z'
          fill='#263238'
        />
        <path
          d='M166.252 34.5216C166.298 34.4969 166.337 34.4607 166.365 34.4164C166.389 34.3816 166.405 34.3423 166.414 34.301C166.422 34.2596 166.422 34.217 166.414 34.1757C166.405 34.1343 166.388 34.0951 166.365 34.0603C166.341 34.0255 166.31 33.9958 166.275 33.973C165.853 33.6885 165.367 33.5123 164.861 33.4599C164.355 33.4074 163.843 33.4804 163.372 33.6725C163.333 33.6907 163.299 33.7164 163.27 33.748C163.242 33.7796 163.22 33.8165 163.205 33.8566C163.191 33.8967 163.185 33.9393 163.187 33.9818C163.189 34.0244 163.2 34.0661 163.218 34.1045C163.236 34.143 163.262 34.1775 163.293 34.2061C163.325 34.2347 163.362 34.2568 163.402 34.2711C163.442 34.2854 163.485 34.2916 163.527 34.2895C163.57 34.2873 163.612 34.2768 163.65 34.2586C164.019 34.1137 164.417 34.0612 164.81 34.1057C165.203 34.1503 165.58 34.2905 165.906 34.5141C165.957 34.5498 166.016 34.5696 166.078 34.5709C166.14 34.5722 166.2 34.5551 166.252 34.5216Z'
          fill='#263238'
        />
        <path
          d='M157.973 36.5509C157.987 36.4993 157.987 36.4448 157.973 36.3931C157.957 36.3536 157.934 36.3176 157.904 36.2871C157.874 36.2566 157.839 36.2323 157.8 36.2156C157.761 36.1988 157.719 36.19 157.676 36.1895C157.633 36.189 157.591 36.1969 157.552 36.2128C157.072 36.4001 156.648 36.7046 156.316 37.0985C155.985 37.4923 155.758 37.9629 155.657 38.4672C155.643 38.5116 155.638 38.5585 155.644 38.6047C155.65 38.651 155.666 38.6954 155.69 38.735C155.715 38.7746 155.748 38.8084 155.787 38.8341C155.825 38.8598 155.869 38.8768 155.915 38.8839C155.962 38.891 156.009 38.8881 156.053 38.8753C156.098 38.8626 156.14 38.8403 156.175 38.81C156.211 38.7797 156.239 38.7421 156.259 38.6998C156.278 38.6575 156.288 38.6114 156.288 38.5648C156.383 38.1796 156.569 37.8229 156.831 37.5247C157.093 37.2265 157.422 36.9956 157.792 36.8515C157.847 36.8227 157.893 36.7794 157.925 36.7264C157.956 36.6734 157.973 36.6127 157.973 36.5509Z'
          fill='#263238'
        />
        <path
          d='M195.152 56.1714C195.98 57.1933 196.701 58.1101 197.408 59.102C198.115 60.094 198.852 61.0633 199.544 62.0628C200.95 64.0541 202.259 66.1206 203.537 68.2247C204.816 70.3288 206.004 72.523 207.072 74.8375C208.23 77.2467 209.149 79.7636 209.816 82.3521C210.191 83.8569 210.388 85.4004 210.403 86.951C210.385 88.4989 210.162 90.0377 209.741 91.5274C208.994 94.1261 207.901 96.6131 206.492 98.9217C205.846 100.034 205.146 101.093 204.417 102.115C203.688 103.137 202.913 104.129 202.161 105.076C200.591 106.998 198.885 108.803 197.055 110.479C196.597 110.887 196.009 111.118 195.395 111.132C194.782 111.145 194.184 110.94 193.709 110.553C193.234 110.165 192.912 109.621 192.802 109.019C192.691 108.416 192.799 107.793 193.107 107.263L193.175 107.15C195.509 103.364 197.551 99.4047 199.281 95.3072C200.059 93.4611 200.624 91.5325 200.965 89.5586C201.091 88.7661 201.124 87.9615 201.063 87.1614C200.972 86.372 200.801 85.5939 200.552 84.8394C199.901 82.918 199.083 81.0573 198.108 79.2786C197.108 77.3925 195.98 75.5214 194.776 73.6202C193.573 71.719 192.31 69.8629 191.016 68.0293L189.039 65.2865C188.377 64.3848 187.685 63.4379 187.061 62.6189L187.016 62.5587C186.168 61.4845 185.782 60.1178 185.943 58.7593C186.104 57.4008 186.798 56.1617 187.873 55.3147C188.948 54.4677 190.316 54.0821 191.675 54.2427C193.035 54.4034 194.275 55.0971 195.122 56.1714H195.152Z'
          fill='#00ACE7'
        />
        <path
          d='M123.501 111.605L187.482 108.712C187.683 108.699 187.886 108.727 188.077 108.793C188.268 108.86 188.443 108.965 188.593 109.101C188.742 109.237 188.863 109.402 188.947 109.585C189.031 109.769 189.078 109.968 189.083 110.17L122.043 113.176C122.034 112.977 122.064 112.778 122.133 112.59C122.202 112.403 122.307 112.231 122.443 112.085C122.578 111.939 122.742 111.821 122.924 111.739C123.106 111.656 123.302 111.611 123.501 111.605Z'
          fill='#00ACE7'
        />
        <g opacity='0.6'>
          <path
            d='M123.501 111.605L187.482 108.712C187.683 108.699 187.886 108.727 188.077 108.793C188.268 108.86 188.443 108.965 188.593 109.101C188.742 109.237 188.863 109.402 188.947 109.585C189.031 109.769 189.078 109.968 189.083 110.17L122.043 113.176C122.034 112.977 122.064 112.778 122.133 112.59C122.202 112.403 122.307 112.231 122.443 112.085C122.578 111.939 122.742 111.821 122.924 111.739C123.106 111.656 123.302 111.611 123.501 111.605Z'
            fill='white'
          />
        </g>
        <path
          d='M113.32 81.9086L158.838 79.8496C159.131 79.8514 159.416 79.9442 159.653 80.1153C159.89 80.2863 160.068 80.5271 160.162 80.804L168.9 108.51C168.949 108.625 168.968 108.75 168.956 108.874C168.944 108.998 168.902 109.117 168.833 109.221C168.763 109.325 168.669 109.409 168.559 109.468C168.449 109.526 168.325 109.556 168.201 109.555L122.682 111.614C122.391 111.611 122.107 111.518 121.871 111.347C121.635 111.176 121.459 110.936 121.366 110.659L112.628 82.9531C112.579 82.8389 112.56 82.7145 112.572 82.591C112.583 82.4675 112.625 82.3487 112.693 82.2453C112.762 82.1419 112.855 82.057 112.964 81.9982C113.073 81.9395 113.196 81.9087 113.32 81.9086Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.6'
          d='M113.32 81.9086L158.838 79.8496C159.131 79.8514 159.416 79.9442 159.653 80.1153C159.89 80.2863 160.068 80.5271 160.162 80.804L168.9 108.51C168.949 108.625 168.968 108.75 168.956 108.874C168.944 108.998 168.902 109.117 168.833 109.221C168.763 109.325 168.669 109.409 168.559 109.468C168.449 109.526 168.325 109.556 168.201 109.555L122.682 111.614C122.391 111.611 122.107 111.518 121.871 111.347C121.635 111.176 121.459 110.936 121.366 110.659L112.628 82.9531C112.579 82.8389 112.56 82.7145 112.572 82.591C112.583 82.4675 112.625 82.3487 112.693 82.2453C112.762 82.1419 112.855 82.057 112.964 81.9982C113.073 81.9395 113.196 81.9087 113.32 81.9086Z'
          fill='white'
        />
        <path
          opacity='0.5'
          d='M160.176 80.8333L168.915 108.539C168.963 108.654 168.983 108.779 168.971 108.903C168.959 109.028 168.917 109.147 168.847 109.25C168.778 109.354 168.684 109.439 168.573 109.497C168.463 109.555 168.34 109.585 168.215 109.584L158.838 79.8789C159.133 79.8775 159.421 79.9689 159.661 80.1402C159.902 80.3115 160.082 80.554 160.176 80.8333Z'
          fill='#00ACE7'
        />
        <path
          opacity='0.5'
          d='M170.659 109.471L187.481 108.72C187.683 108.707 187.885 108.734 188.076 108.801C188.267 108.868 188.443 108.972 188.592 109.109C188.742 109.245 188.862 109.41 188.947 109.593C189.031 109.777 189.077 109.976 189.083 110.178L169.2 111.065C169.188 110.863 169.216 110.662 169.284 110.472C169.351 110.282 169.456 110.108 169.592 109.959C169.728 109.811 169.892 109.691 170.076 109.607C170.259 109.523 170.457 109.477 170.659 109.471Z'
          fill='#00ACE7'
        />
        <path
          d='M191.911 106.947L187.609 107.579L192.016 113.703C192.016 113.703 199.017 112.576 198.205 109.48L196.701 108.232C196.036 107.697 195.266 107.306 194.442 107.085C193.617 106.863 192.755 106.817 191.911 106.947Z'
          fill='#FF8B7B'
        />
        <path d='M182.346 109.412L187.738 114.108L192.016 113.702L187.61 107.578L182.346 109.412Z' fill='#FF8B7B' />
      </g>
      <defs>
        <clipPath id='clip0_2540_40553'>
          <rect width='376' height='280' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
