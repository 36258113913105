import AuthImage from '@assets/images/AuthImage.png';

export function AuthDescription() {
  return (
    <div className='bubble-top auth-bg-gradient hidden h-full basis-[54.49%] flex-col items-center justify-center gap-[1.375rem] py-10 lg:flex xxl:basis-[54.44%] xxl:gap-[1.875rem]'>
      <div className='mt-10 h-[24rem] w-[29.1875rem] xxl:h-[33.75rem] xxl:w-[41rem]'>
        <img src={AuthImage} alt='Auth-image' className='h-full w-full' />
      </div>
      <p className='bubble-bottom mb-10 max-w-[22.25rem] text-center text-xl font-bold leading-[1.7712rem] text-white xxl:max-w-[31.25rem] xxl:text-[1.75rem] xxl:leading-[2.4912rem]'>
        Harnessing the Potential of Data in Marketing Strategies
      </p>
    </div>
  );
}
