import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  Dialog,
  DialogTrigger,
  SheetClose,
} from '@shadcnComponent';
import { AddEditOrgDialog, BaseToolTip, BaseSkeltonLoader } from '@common/components';
import { useCreateOrganization } from '@pages/manageOrganization';
import { useMembershipTab } from '@pages/profile';
import { ROUTES } from '@routes';
import { showSubscriptionPlan, showSubscriptionText, showUpgradeBtn, truncateString } from '@utils';
import { SIDEBAR_CONTENT, SKELETON_TYPE } from '@constants';
import {
  ArrowBottomDark,
  PlusIconDark,
  PlusIconWhite,
  OrganizationIcon,
  ResponsiveLogo,
  ArrowClose,
  HelpDesk,
} from '@assets/svgs';

export function ResponsiveSidebar() {
  const {
    organizationListing,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    switcherSelectedOrganization,
    handleSelectedOrganization,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    organizationListingFetching,
    openCreateDialogue,
    handleImageUpload,
    createOrganizationLoading,
  } = useCreateOrganization();

  const { membershipPlan, calculateTrialDays, userDetail } = useMembershipTab();

  return (
    <div className='relative flex min-h-screen w-[21.25rem] flex-col justify-between gap-10 bg-sidebar-gradient px-5 py-10 md:w-[16rem] md:pb-[3.75rem] md:pt-8 lg:hidden'>
      <div>
        <div className='relative mb-10 flex w-full items-center justify-center'>
          <Link to={ROUTES.HOME} className='h-[2.0625rem] w-[10.9375rem] md:h-6 md:w-[8.125rem]'>
            <ResponsiveLogo className='h-[2.0625rem] w-[10.9375rem] md:h-6 md:w-[8.125rem]' />
          </Link>
          <SheetClose asChild className='absolute right-0'>
            <Button variant='ghost' className='h-fit w-6 p-0'>
              <ArrowClose className='h-6 w-6' />
            </Button>
          </SheetClose>
        </div>
        <div id='responsive-org-switcher' className='mb-6 border-b border-lightGray pb-4'>
          <Dialog open={showAddEditOrgDialog} onOpenChange={setShowAddEditOrgDialog}>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div id='switcherOrganizationName' className='flex items-center px-5 text-white md:px-3.5'>
                  <div className='relative mr-3 h-10 w-10 overflow-hidden rounded-full md:mr-2 md:h-8 md:w-8'>
                    {organizationListingFetching ? (
                      <BaseSkeltonLoader type={SKELETON_TYPE.IMAGE_UPLOADER} />
                    ) : switcherSelectedOrganization?.logo?.image ? (
                      <img
                        src={switcherSelectedOrganization?.logo?.image}
                        alt='profile-image'
                        className='h-full w-full'
                      />
                    ) : (
                      <OrganizationIcon className='h-full w-full bg-white p-1' />
                    )}
                  </div>
                  {!organizationListingFetching ? (
                    <span className='max-w-[7.5rem] overflow-hidden whitespace-nowrap text-base font-normal leading-[1.21rem] text-white md:text-sm md:leading-[1.0588rem]'>
                      {truncateString(switcherSelectedOrganization?.name, 15, 'text-white')}
                    </span>
                  ) : (
                    <BaseSkeltonLoader type={SKELETON_TYPE.SINGLE_LINE} count={1} width={100} height={14} />
                  )}
                  <div className='ml-auto'>
                    <ArrowBottomDark className='h-6 w-6 md:h-5 md:w-5' />
                  </div>
                  {switcherSelectedOrganization?.name?.length > 15 && (
                    <BaseToolTip
                      className='!w-[13.5rem]'
                      id='switcherOrganizationName'
                      content={switcherSelectedOrganization?.name}
                    />
                  )}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='w-[16.25rem] px-1.5 py-2 md:w-[12.5rem] md:p-[0.3125rem]'>
                <div className='max-h-[12.5rem] overflow-y-auto scrollbar-none'>
                  {organizationListing?.data?.results?.map(organization => (
                    <DropdownMenuItem
                      key={organization.id}
                      onClick={() => handleSelectedOrganization(organization)}
                      className='px-2'
                    >
                      <div className='h-7 w-7 min-w-7 overflow-hidden rounded-full md:h-6 md:w-6 md:min-w-6'>
                        {organization?.logo?.image ? (
                          <img src={organization?.logo?.image} alt='organization-logo' className='h-full w-full' />
                        ) : (
                          <OrganizationIcon className='h-full w-full bg-white p-1' />
                        )}
                      </div>
                      <p
                        id='organizationName'
                        className='xxl:leading-base text-sm leading-[1.0625rem] md:text-sm md:leading-[1.25rem] xxl:text-base'
                      >
                        {truncateString(organization?.name, 15, 'text-darkSecondary')}
                      </p>
                      {organization?.name?.length > 15 && (
                        <BaseToolTip className='!w-[180px]' id='organizationName' content={organization?.name} />
                      )}
                    </DropdownMenuItem>
                  ))}
                </div>
                <DropdownMenuSeparator />
                <DialogTrigger id='create-org' asChild onClick={openCreateDialogue}>
                  <DropdownMenuItem className='justify-center gap-1 text-sm font-light leading-5 md:gap-2'>
                    <PlusIconDark />
                    Create Organization
                  </DropdownMenuItem>
                </DialogTrigger>
              </DropdownMenuContent>
            </DropdownMenu>
            <AddEditOrgDialog
              dialogTitle='Create Organization'
              formik={formik}
              imageRef={imageRef}
              uploadedLogo={uploadedLogo}
              handleImageUpload={handleImageUpload}
              setUploadedLogo={setUploadedLogo}
              setIsUploadedLogo={setIsUploadedLogo}
              icon={
                <PlusIconWhite className='h-[1.125rem] w-[1.125rem] md:h-[1.4063rem] md:w-[1.4063rem] lg:h-[0.5831rem] lg:w-[0.5831rem] xxl:h-3.5 xxl:w-3.5' />
              }
              isUploadedLogo={isUploadedLogo}
              organizationLoading={createOrganizationLoading}
            />
          </Dialog>
        </div>
        <ul className='flex flex-col gap-[0.6875rem] md:gap-2'>
          {SIDEBAR_CONTENT.map(data => (
            <li key={data.text}>
              <NavLink to={`${data.link}/${switcherSelectedOrganization?.id}`} className='sidebarLink'>
                <SheetClose className='flex w-full items-center gap-3 whitespace-nowrap rounded px-5 py-2.5 text-sm font-normal leading-[1.0588rem] text-white md:rounded-md md:py-2'>
                  <div className='h-6 w-6 md:h-5 md:w-5'>
                    <data.iconUrl />
                  </div>
                  {data.text}
                </SheetClose>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <a href='' className='flex items-center gap-3 border-t border-lightGray px-5 py-2.5'>
          <HelpDesk />
          <span className='text-sm font-normal leading-[1.0588rem] text-white'>Help Desk</span>
        </a>
        <div className=' mt-4 flex flex-col items-center rounded-md border border-lightGray/40 bg-white/10 px-4 pb-[1.0462rem] pt-[1.6094rem] shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] md:mx-2 md:px-4 md:py-6'>
          <h4 className='text-base font-semibold leading-[1.21rem] text-white md:text-lg md:leading-[1.3613rem]'>
            {showSubscriptionPlan(userDetail, membershipPlan?.data?.results)}
          </h4>
          <p className='mx-[0.6563rem] mb-[0.5625rem] mt-1 text-center text-[0.8125rem] font-normal leading-5 text-white md:mx-[0.4375rem] md:mb-6 md:mt-1.5 md:text-sm'>
            {membershipPlan?.data?.results[0].trialEndDate ? (
              <>
                Free trial ends in{' '}
                <span className='end-subscription-gradient font-bold'>
                  ${calculateTrialDays(membershipPlan?.data?.results[0].trialEndDate)}{' '}
                  {calculateTrialDays(membershipPlan?.data?.results[0].trialEndDate) === 1 ? 'day' : 'days'}
                </span>
                <br />
                For more features upgrade your plan.
              </>
            ) : (
              showSubscriptionText(userDetail, membershipPlan?.data?.results)
            )}
          </p>
          {showUpgradeBtn(userDetail, membershipPlan?.data?.results) ? (
            <Link to={`${ROUTES.PROFILE}/${ROUTES.MEMBERSHIPS_TAB}`}>
              <Button className='subscription-btn-bg-gradient max-h-6 xxl:max-h-10'>Upgrade</Button>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}
