import { Progress } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { COLORS } from '@constants';

export function Progressbar({ progressValue, className, progressColor = COLORS.DARK_PRIMARY }) {
  return (
    <Progress
      value={progressValue}
      progressColor={progressColor}
      className={cn('h-1.5 md:h-2 lg:h-1.5 xxl:h-2', className)}
    />
  );
}
