import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ScrollArea,
  Button,
  ScrollBar,
} from '@shadcnComponent';
import { BaseLoader, BaseToolTip, CustomPagination, Loader } from '@common/components';
import { useInvitesTab } from '@pages/manageOrganization';
import { capitalizeString, truncateString } from '@utils';
import { STATUS, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function InvitesTab() {
  const {
    inviteListing,
    handleResendInvite,
    organizationId,
    page,
    setPage,
    inviteListingFetching,
    resendLoading,
    setResendLoading,
  } = useInvitesTab();

  return (
    <div className='flex flex-col gap-6 md:gap-8 lg:gap-6'>
      <div className='mt-6 h-[28.3125rem] md:h-[38.125rem] lg:mt-4 lg:h-[calc(100vh-16.75rem)] xxl:h-[calc(100vh-22rem)]'>
        <ScrollArea className='relative z-[1] h-full w-[calc(100vw-2.5rem)] whitespace-nowrap rounded-md border xsm:w-[27.5rem] md:w-full lg:w-auto'>
          <Table className='text-lg'>
            <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
              <TableRow className='h-[3.0625rem] md:h-[3.125rem] lg:h-[2.4375rem] xxl:h-[3.25rem]'>
                <TableHead className='w-[13.5rem] min-w-[13.5rem] px-6 py-2.5 md:w-[16.125rem] md:px-10 md:pb-3 md:pt-3.5 lg:w-[16.5625rem] lg:py-3 xxl:w-[25.125rem] xxl:py-3.5'>
                  Email
                </TableHead>
                <TableHead className='px-6 py-2.5 md:px-10 md:pb-3 md:pt-3.5 lg:py-3 xxl:py-3.5'>Role</TableHead>
                <TableHead className='px-6 py-2.5 md:px-10 md:pb-3 md:pt-3.5 lg:py-3 xxl:py-3.5'>Status</TableHead>
                <TableHead className='w-[9.75rem] px-6 py-2.5 md:px-10 md:pb-3 md:pt-3.5 lg:py-3 xxl:py-3.5'>
                  Action
                </TableHead>
              </TableRow>
            </TableHeader>
            {inviteListingFetching ? (
              <BaseLoader />
            ) : (
              <TableBody className='pt-5'>
                {inviteListing?.data?.results?.map(invite => (
                  <TableRow key={invite?.id} className='h-[3.0625rem] md:h-14 lg:h-[2.5625rem] xxl:h-[3.25rem]'>
                    <TableCell className='px-6 py-2.5 font-medium md:px-10 md:py-3.5 lg:py-[0.5625rem] xxl:py-2.5'>
                      <p id={`invitedEmail${invite?.id}`}>{truncateString(invite.invitedEmail, 44)}</p>
                      {invite?.invitedEmail?.length > 44 && (
                        <BaseToolTip
                          className='w-full'
                          id={`invitedEmail${invite?.id}`}
                          content={invite?.invitedEmail}
                        />
                      )}
                    </TableCell>
                    <TableCell className='px-6 py-2.5 md:px-10 md:py-3.5 lg:py-[0.5625rem] xxl:py-2.5'>
                      {capitalizeString(invite.role)}
                    </TableCell>
                    <TableCell className='px-6 py-2.5 md:px-10 md:py-3.5 lg:py-[0.5625rem] xxl:py-2.5'>
                      {capitalizeString(invite.status)}
                    </TableCell>
                    <TableCell className='px-6 py-2.5 md:px-10 md:py-3.5 lg:py-[0.5625rem] xxl:py-2.5'>
                      {invite.status === STATUS.ACCEPTED ? (
                        '-'
                      ) : (
                        <div className='w-[3.75rem] xxl:w-[6rem]'>
                          <Button
                            disabled={resendLoading[invite?.id]}
                            className='h-7 rounded-[0.1875rem] text-xs leading-[0.9075rem] md:h-7 md:rounded-[0.1875rem] md:text-xs md:leading-[0.9075rem] lg:h-[1.4375rem] xxl:h-8'
                            onClick={() => {
                              handleResendInvite({
                                payload: {
                                  invite: invite?.id,
                                  organization: organizationId,
                                },
                              });
                              setResendLoading({
                                ...resendLoading,
                                [invite.id]: true,
                              });
                            }}
                          >
                            Resend {resendLoading[invite?.id] ? <Loader /> : ''}
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
      <div className='mb-6 lg:mb-0'>
        {inviteListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={page} setPage={setPage} data={inviteListing} />
        )}
      </div>
    </div>
  );
}
