import { useRef } from 'react';
import { cn } from '@shadcnUtils';
import CountUp from 'react-countup';
import { EmptyWidget } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function CounterWidget({ value, isComparisonFilterApply, isSharedDashboard, widget, className, dataText }) {
  const counterWidgetRef = useRef(null);

  const counterWidgetFontSize =
    Math.min(counterWidgetRef?.current?.clientHeight, counterWidgetRef?.current?.clientWidth) / 4;

  return (
    <>
      {!!value || value === 0 ? (
        <div ref={counterWidgetRef} className={cn('flex h-full items-center justify-center py-3', className)}>
          <div
            className={cn(
              `flex h-full items-center justify-center ${isComparisonFilterApply ? 'w-full pr-4 text-[2rem]' : 'text-[4rem]'} font-bold`,
              dataText
            )}
            style={{ fontSize: counterWidgetFontSize || '' }}
          >
            <CountUp
              duration={isSharedDashboard ? 0 : '2'}
              end={isComparisonFilterApply ? value?.data : value}
              style={
                widget?.themeColor === DEFAULT_VALUE
                  ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                  : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
              }
            />
          </div>
          {isComparisonFilterApply && (
            <div
              className={`flex h-full items-center justify-center ${isComparisonFilterApply ? 'w-full pl-4 text-[2rem]' : 'text-[4rem]'} border-l-[1px] font-bold`}
              style={{ fontSize: counterWidgetFontSize || '' }}
            >
              <CountUp
                duration={isSharedDashboard ? 0 : '2'}
                end={value?.data2}
                style={
                  widget?.themeColor === DEFAULT_VALUE
                    ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                    : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
                }
              />
            </div>
          )}
        </div>
      ) : (
        <EmptyWidget widget={widget} />
      )}
    </>
  );
}
