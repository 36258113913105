import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import {
  Dialog,
  ScrollArea,
  ScrollBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import {
  AddEditOrgDialog,
  BaseLoader,
  BaseToolTip,
  CustomPagination,
  DeleteDialog,
  EmptyDashboardSearch,
} from '@common/components';
import { ROUTES } from '@routes';
import { capitalizeString, setLocalStorageItem, truncateString } from '@utils';
import { SELECTED_ORGANIZATION_ID, ROLES, COLORS, TOTAL_ITEMS_PER_PAGE } from '@constants';
import { EmptyInvitation, EditIcon, EditIconWhite } from '@assets/svgs';

export function InvitedOrganizationTab() {
  const navigate = useNavigate();
  const [
    organizations,
    page,
    setPage,
    organizationFetching,
    search,
    editFormik,
    imageRef,
    uploadedLogoEdit,
    setUploadedLogoEdit,
    setIsUploadedLogoEdit,
    isUploadedLogoEdit,
    isEditOrganizationLoading,
    selectedOrganization,
    setSelectedOrganization,
    showAddEditDialog,
    setShowAddEditDialog,
    showDeleteOrgDialog,
    setShowDeleteOrgDialog,
    handleDeleteBtn,
    handleDeleteOrganizationBtn,
    isDeleteOrgLoading,
    userDetail,
    manageOrganizationFetching,
    editHandleImageUpload,
    openEditDialogue,
  ] = useOutletContext();

  const defaultOrganizationId = userDetail?.data?.defaultOrganization;

  return (
    <div className='flex flex-col gap-6 lg:gap-4 xxl:gap-6'>
      <ScrollArea className='relative z-[1] min-h-[24.375rem] w-[calc(100vw-2.5rem)] max-w-[27.5rem] whitespace-nowrap rounded-md border md:min-h-[38.25rem] md:max-w-full lg:min-h-[calc(100vh-27.5625rem)] xxl:min-h-[calc(100vh-37.625rem)]'>
        <Table className='bg-white'>
          <TableHeader className='sticky top-0 rounded-t-lg bg-white'>
            <TableRow>
              <TableHead className='w-[8.4944rem] px-4 py-3.5 md:w-[11.25rem] md:px-[1rem] md:py-3.5 lg:w-[10.3987rem] lg:px-5 xxl:w-[13.4812rem] xxl:px-8 xxl:pb-3 xxl:pt-3.5'>
                Name
              </TableHead>
              <TableHead className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                Role
              </TableHead>
              <TableHead className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                Dashboards
              </TableHead>
              <TableHead className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                Teams
              </TableHead>
              <TableHead className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                Members
              </TableHead>
              <TableHead className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                Data Sources
              </TableHead>
              <TableHead className='px-6 py-3.5 md:px-[1rem] md:py-3.5 lg:px-5 xxl:px-8 xxl:pb-3 xxl:pt-3.5'>
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className='pt-5'>
            {organizationFetching ? (
              <BaseLoader className='mt-[6.25rem]' />
            ) : search && organizations?.data?.count === 0 ? (
              <EmptyDashboardSearch />
            ) : organizations?.data?.results.length === 0 ? (
              <div className='absolute flex h-[18.75rem] w-[calc(100vw-2.5rem)] flex-col items-center justify-center gap-4 xsm:w-[27.5rem] md:w-[44rem] md:gap-6 lg:left-[50%] lg:w-[41.75rem] lg:translate-x-[-50%] lg:gap-4 xxl:w-[58.625rem] xxl:gap-6'>
                <EmptyInvitation className='h-[5.8125rem] w-[7.8rem] md:h-[8.75rem] md:w-[11.7413rem] lg:h-[5.8125rem] lg:w-[7.8rem] xxl:h-[8.75rem] xxl:w-[11.9063rem]' />
                <div className='flex flex-col items-center gap-2 md:gap-3 lg:gap-2 xxl:gap-3'>
                  <h4 className='text-sm font-bold leading-[1.0588rem] md:text-base md:leading-[1.21rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-base xxl:leading-[1.21rem]'>
                    No Invitations Received
                  </h4>
                  <p className='text-xs font-normal leading-[1.375rem] md:text-sm md:leading-[1.375rem] lg:text-xs lg:leading-[1.375rem] xxl:text-sm xxl:leading-[1.375rem]'>
                    You are not invited by any organization
                  </p>
                </div>
              </div>
            ) : (
              organizations?.data?.results?.map(organization => (
                <TableRow
                  key={organization?.id}
                  onClick={() => {
                    setLocalStorageItem(SELECTED_ORGANIZATION_ID, organization?.organizationId);
                    navigate(generatePath(ROUTES.DASHBOARD, { organizationId: organization?.organizationId }));
                  }}
                >
                  <TableCell className='px-4 py-3.5 font-medium md:px-[1rem] md:py-3.5 lg:px-5 lg:py-2.5 xxl:px-8 xxl:py-3.5'>
                    <p id={`organization${organization?.id}`}>{truncateString(organization.name, 24)}</p>
                    {organization?.name?.length > 24 && (
                      <BaseToolTip
                        className='w-full'
                        id={`organization${organization?.id}`}
                        content={organization?.name}
                      />
                    )}
                  </TableCell>
                  <TableCell className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                    {capitalizeString(organization.role)}
                  </TableCell>
                  <TableCell className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                    {organization.dashboardsCount}
                  </TableCell>
                  <TableCell className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                    {organization.teamCount}
                  </TableCell>
                  <TableCell className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                    {organization.memberCount}
                  </TableCell>
                  <TableCell className='px-[0.9375rem] py-3.5 md:px-[1rem] md:py-3.5 lg:px-[1.5rem] lg:py-2.5 xxl:px-[1.8rem] xxl:py-3.5'>
                    {organization.dataSourcesCount}
                  </TableCell>
                  <TableCell
                    className={`px-6 py-3.5 md:px-[1rem] md:py-3.5 lg:px-5 lg:py-2.5 xxl:px-8 xxl:py-3.5 ${organization?.role === ROLES.ADMIN ? '' : 'pointer-events-none'}`}
                  >
                    <div className='flex gap-1.5'>
                      <span
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedOrganization(organization);
                          setShowAddEditDialog({ ...showAddEditDialog, [organization]: true });
                        }}
                      >
                        <EditIcon
                          strokeColor={`${organization?.role === ROLES.ADMIN ? COLORS.DARK_PRIMARY : COLORS.TINT_GRAY}`}
                        />
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <div className='mb-8 lg:mb-0'>
        {organizations?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={page} setPage={setPage} data={organizations} />
        )}
      </div>

      {!isEmpty(selectedOrganization) && selectedOrganization?.role === ROLES.ADMIN && (
        <Dialog
          open={showAddEditDialog[selectedOrganization]}
          onOpenChange={isOpen => {
            setShowAddEditDialog({ ...showAddEditDialog, [selectedOrganization]: isOpen });
            openEditDialogue();
          }}
        >
          <AddEditOrgDialog
            dialogTitle='Edit Organization'
            formik={editFormik}
            imageRef={imageRef}
            uploadedLogo={uploadedLogoEdit}
            handleImageUpload={editHandleImageUpload}
            setUploadedLogo={setUploadedLogoEdit}
            setIsUploadedLogo={setIsUploadedLogoEdit}
            icon={
              <div className='h-3.5 w-3.5 lg:h-2.5 lg:w-2.5 xxl:h-3.5 xxl:w-3.5'>
                <EditIconWhite />
              </div>
            }
            isUploadedLogo={isUploadedLogoEdit}
            handleDeleteBtn={handleDeleteBtn}
            organizationLoading={isEditOrganizationLoading}
            organizationId={selectedOrganization?.organizationId}
            defaultOrganizationId={defaultOrganizationId}
            manageOrganizationFetching={manageOrganizationFetching}
          />
        </Dialog>
      )}

      <Dialog open={showDeleteOrgDialog} onOpenChange={setShowDeleteOrgDialog}>
        <DeleteDialog
          heading={selectedOrganization?.name}
          text={selectedOrganization?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-4 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={handleDeleteOrganizationBtn}
          isLoading={isDeleteOrgLoading}
        />
      </Dialog>
    </div>
  );
}
