import ColorPicker from '@common/components/ColorPicker';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Input,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { BaseLoader, FormInput, ImageCrop, LogoUpload, WhiteLabelPreview } from '@common/components';
import { DomainInstruction, useWhiteLabel } from '@pages/whiteLabel';
import { getFile, getLocalStorageItem, handleSave } from '@utils';
export function WhiteLabel() {
  const {
    whiteLabelFetching,
    formik,
    mainLogoRef,
    sponsorLogoRef,
    mainLogoUpload,
    sponsorLogoUpload,
    setMainLogoUploaded,
    setSponsorLogoUploaded,
    handleWhiteLabelUpdateLoading,
    mainLogoUploaded,
    sponsorLogoUploaded,
    handleMainLogoUpload,
    handleSponsorLogoUpload,
    verifiedEmailDomain,
  } = useWhiteLabel();

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');
  const { initialValues, values, handleChange, touched, errors, submitForm, setFieldValue } = formik;

  const isActionDisabled = handleWhiteLabelUpdateLoading || mainLogoUploaded || sponsorLogoUploaded;

  return whiteLabelFetching ? (
    <BaseLoader />
  ) : (
    <div className='mx-auto h-full max-w-[30rem] overflow-x-hidden px-5 pb-5 pt-[1.6875rem] md:max-w-[48rem] md:px-8 md:pb-10 md:pt-[1.9375rem] lg:max-w-full lg:pb-[2.3125rem] lg:pt-[1.875rem] xxl:px-10 xxl:pb-[0.9375rem] xxl:pt-10'>
      <h3 className='mb-6 text-2xl font-bold leading-[1.8156rem] md:text-[2rem] md:leading-[2.4206rem] lg:hidden'>
        White Label
      </h3>
      <div className='relative h-full overflow-y-auto overflow-x-hidden rounded-lg border border-lightGray bg-white px-[1.1875rem] py-5 md:p-10 lg:h-min lg:p-8 xxl:p-10'>
        <div className='flex flex-col justify-between gap-6 border-b border-lightGray pb-8 md:gap-10 lg:flex-row lg:gap-8 lg:border-none lg:pb-0 xxl:gap-[3.75rem]'>
          <div className='flex flex-col gap-6 md:gap-8 lg:flex-1 lg:gap-6 xxl:gap-8'>
            <div className='flex gap-[1.375rem] md:gap-6'>
              <LogoUpload
                parentClass='basis-[46.86%] lg:basis-[46.555%] xxl:basis-[47.564%] xxl:space-y-3'
                logo={values.mainLogo?.image || mainLogoUpload?.image}
                className='h-[4.375rem] md:h-20 md:w-[18.6875rem] lg:h-[3.5rem] lg:w-full xxl:h-20'
                inputClass='w-full h-[4.375rem] md:h-20 md:w-[18.6875rem] lg:h-[3.5rem] lg:w-full xxl:h-20'
                placeholder={<p className='text-[0.875rem]'>Upload Logo</p>}
                labelText='Main Logo'
                imgRef={mainLogoRef}
                handleChange={e => getFile(e.target.files[0], 'mainLogo', setFieldValue, setMainLogoUploaded)}
                sizeCount='Max Size: 1MB'
              />
              <LogoUpload
                parentClass='flex-1 xxl:space-y-3'
                logo={values.sponsorLogo?.image || sponsorLogoUpload?.image}
                className='h-[4.375rem] md:h-20 md:w-[18.6875rem] lg:h-[3.5rem] lg:w-full xxl:h-20'
                inputClass='w-full h-[4.375rem] md:h-20 md:w-[18.6875rem] lg:h-[3.5rem] lg:w-full xxl:h-20'
                placeholder={<p className='text-[0.875rem]'>Upload Logo</p>}
                labelText='Sponsored Logo'
                imgRef={sponsorLogoRef}
                handleChange={e => getFile(e.target.files[0], 'sponsorLogo', setFieldValue, setSponsorLogoUploaded)}
                sizeCount='Max Size: 1MB'
              />
            </div>
            <div className='space-y-3'>
              <div className='flex flex-col-reverse gap-6 md:flex-row md:gap-6 lg:flex-col-reverse lg:gap-6 xxl:gap-8'>
                <ColorPicker
                  name='headerColor'
                  handleChange={handleChange}
                  componentColor={values.headerColor}
                  labelText='Background Color'
                  showError={touched.headerColor && errors.headerColor}
                />
                <ColorPicker
                  name='headerTextColor'
                  handleChange={handleChange}
                  componentColor={values.headerTextColor}
                  labelText='Text Color'
                  showError={touched.headerTextColor && errors.headerTextColor}
                />
              </div>
              <div className='flex items-center space-x-4 md:space-x-3'>
                <Label
                  htmlFor='isTextureApplied'
                  className='text-sm font-bold font-medium leading-[1.0588rem] lg:text-[0.625rem] lg:leading-[0.7562rem] xxl:text-sm xxl:leading-[1.0588rem]'
                >
                  Apply Texture
                </Label>
                <Switch
                  id='isTextureApplied'
                  checked={values.isTextureApplied ?? false}
                  onCheckedChange={checked => setFieldValue('isTextureApplied', checked)}
                  className='h-6 w-11 lg:h-[1.125rem] lg:w-8 xxl:h-6 xxl:w-11'
                  thumbClass='h-5 w-5 data-[state=checked]:translate-x-5 lg:h-3.5 lg:w-3.5 lg:data-[state=checked]:translate-x-3.5 xxl:h-5 xxl:w-5 xxl:data-[state=checked]:translate-x-5'
                />
              </div>
            </div>
            <div className='relative md:mb-0 lg:mb-2'>
              <FormInput
                value={values.domain}
                name='domain'
                handleChange={handleChange}
                labelText='Domain'
                labelClassName='text-sm leading-[1.0588rem] font-bold md:text-lg md:leading-5 xxl:text-lg xxl:leading-5'
                className='xxl:gap-3'
                placeholder='Enter domain'
                showError={touched.domain && errors.domain}
              />
              <DomainInstruction />
            </div>
            {userOrganizationRole === 'OWNER' && (
              <div className='hidden flex-col gap-3 border-t border-t-lightGray pt-8 lg:flex xxl:pt-10'>
                <Label className='text-sm font-bold leading-[1.0588rem] xxl:text-lg xxl:leading-5'>
                  Add Custom Email
                </Label>
                <div className='flex gap-2 xxl:gap-1.5'>
                  <div className='flex flex-1 flex-col gap-2 lg:gap-1.5'>
                    <Input
                      placeholder='Add Email'
                      value={values.customEmail}
                      disabled={verifiedEmailDomain?.data?.count === 0}
                      onChange={e => setFieldValue('customEmail', e.target.value)}
                      className='h-8 rounded-md border border-lightGray bg-transparent px-3 text-sm font-normal leading-6 xxl:h-10'
                    />
                    {errors.customEmail && (
                      <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>
                        {errors.customEmail}
                      </p>
                    )}
                  </div>
                  <Select
                    disabled={verifiedEmailDomain?.data?.count === 0}
                    value={values?.verifiedDomain}
                    onValueChange={value => setFieldValue('verifiedDomain', value)}
                  >
                    <SelectTrigger
                      className={`h-8 w-[7.5rem] border border-lightGray bg-transparent text-sm font-normal leading-6 xxl:h-10 xxl:w-[10.75rem] ${!isEmpty(values?.verifiedDomain) ? '' : 'custom-select'}`}
                    >
                      <SelectValue placeholder='domain.com' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {verifiedEmailDomain?.data?.results?.map(option => (
                          <SelectItem key={option?.id} value={option.domain}>
                            {option?.domain}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
            <div className='mt-12 hidden justify-end xxl:flex'>
              <Button
                disabled={isActionDisabled}
                onClick={submitForm}
                className='w-full md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'
              >
                Save
              </Button>
            </div>
          </div>
          <div
            className='w-available relative flex h-[8.75rem] flex-col items-center overflow-y-hidden rounded-md md:h-20 lg:h-[unset] lg:w-[21.375rem] lg:justify-center lg:rounded-xl lg:bg-lightGray xxl:w-[30.375rem] xxl:rounded-2xl
          '
          >
            <div className='absolute z-10 flex h-[8.75rem] w-full items-center justify-center rounded-md bg-black/50 md:h-20 lg:hidden'>
              <Dialog>
                <DialogTrigger className='text-sm font-semibold leading-[1.0588rem] text-white underline'>
                  Tap to Preview
                </DialogTrigger>
                <DialogContent
                  crossClass='hidden'
                  className='max-h-[85vh] w-[calc(100vw-2.5rem)] max-w-[24.375rem] overflow-y-auto rounded-lg rounded-lg border-none p-0 md:max-w-[44.25rem]'
                >
                  <WhiteLabelPreview
                    logo={values.mainLogo?.image || mainLogoUpload?.image}
                    sponsorLogo={values.sponsorLogo?.image || sponsorLogoUpload?.image}
                    headerTextColor={values.headerTextColor}
                    headerColor={values.headerColor}
                    isTextureApplied={values.isTextureApplied}
                    dashoboardHeader='rounded-t-lg'
                    className='rounded-lg md:!w-full'
                  />
                </DialogContent>
              </Dialog>
            </div>
            <WhiteLabelPreview
              logo={values.mainLogo?.image || mainLogoUpload?.image}
              sponsorLogo={values.sponsorLogo?.image || sponsorLogoUpload?.image}
              headerTextColor={values.headerTextColor}
              headerColor={values.headerColor}
              isTextureApplied={values.isTextureApplied}
            />
          </div>
        </div>
        {userOrganizationRole === 'OWNER' && (
          <div className='mt-8 flex flex-col gap-3 lg:hidden xxl:gap-3'>
            <Label className='text-sm font-bold leading-[1.0588rem] md:text-lg md:leading-5'>Add Custom Email</Label>
            <div className='flex gap-2 xxl:gap-3'>
              <div className='flex flex-1 flex-col gap-2 lg:gap-1.5'>
                <Input
                  placeholder='Add Email'
                  value={values.customEmail}
                  disabled={verifiedEmailDomain?.data?.count === 0}
                  onChange={e => setFieldValue('customEmail', e.target.value)}
                  className='h-12 rounded-md border border-lightGray bg-transparent px-3 text-sm font-normal leading-6'
                />
                {errors.customEmail && (
                  <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorColor'>
                    {errors.customEmail}
                  </p>
                )}
              </div>
              <Select
                disabled={verifiedEmailDomain?.data?.count === 0}
                value={values?.verifiedDomain}
                onValueChange={value => setFieldValue('verifiedDomain', value)}
              >
                <SelectTrigger
                  className={`h-12 w-[8.125rem] border border-lightGray bg-transparent text-sm font-normal leading-6 md:w-[13.125rem] ${!isEmpty(values?.verifiedDomain) ? '' : 'custom-select'}`}
                >
                  <SelectValue placeholder='domain.com' />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {verifiedEmailDomain?.data?.results?.map(option => (
                      <SelectItem key={option?.id} value={option.domain}>
                        {option?.domain}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        )}
        <div className='mt-6 flex justify-end lg:mt-[3.125rem] xxl:hidden'>
          <Button
            disabled={isActionDisabled}
            onClick={submitForm}
            className='w-full md:w-[11.25rem] lg:w-[7.5rem] xxl:w-[11.25rem]'
          >
            Save
          </Button>
        </div>
      </div>
      {mainLogoUploaded && (
        <ImageCrop
          uploadedImage={values?.mainLogo}
          handleCancel={() => {
            setMainLogoUploaded(false);
            setFieldValue('mainLogo', initialValues.mainLogo);
            mainLogoRef.current.value = '';
          }}
          handleCloseDialog={() => setFieldValue('mainLogo', initialValues.mainLogo)}
          setIsUploaded={setMainLogoUploaded}
          imageRef={mainLogoRef}
          handleSave={file => handleSave(file, handleMainLogoUpload)}
          isDialog
        />
      )}
      {sponsorLogoUploaded && (
        <ImageCrop
          cropShape='rect'
          uploadedImage={values?.sponsorLogo}
          handleCancel={() => {
            setSponsorLogoUploaded(false);
            setFieldValue('sponsorLogo', initialValues.sponsorLogo);
            sponsorLogoRef.current.value = '';
          }}
          handleCloseDialog={() => setFieldValue('sponsorLogo', initialValues.sponsorLogo)}
          setIsUploaded={setSponsorLogoUploaded}
          imageRef={sponsorLogoRef}
          handleSave={file => handleSave(file, handleSponsorLogoUpload)}
          isDialog
        />
      )}
    </div>
  );
}
