import { EmptyDashboardScreenIcon } from '@assets/svgs';

export function EmptyDashboardSearch() {
  return (
    <div className='absolute flex h-[18.75rem] w-[calc(100vw-2.5rem)] flex-col items-center justify-center gap-4 xsm:w-[27.5rem] md:w-[44rem] md:gap-6 lg:left-[50%] lg:w-[41.75rem] lg:translate-x-[-50%] lg:gap-4 xxl:w-[58.625rem] xxl:gap-6'>
      <EmptyDashboardScreenIcon className='h-[5.8125rem] w-[7.8rem] md:h-[8.75rem] md:w-[11.7413rem] lg:h-[5.8125rem] lg:w-[7.8rem] xxl:h-[8.75rem] xxl:w-[11.6875rem]' />
      <div className='flex flex-col items-center gap-2 md:gap-3 lg:gap-2 xxl:gap-3'>
        <h4 className='text-sm font-bold leading-[1.0588rem] md:text-base md:leading-[1.21rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-base xxl:leading-[1.21rem]'>
          No Data Found
        </h4>
        <div>
          <p className='text-xs font-normal leading-[1.375rem] md:text-sm md:leading-[1.375rem] lg:text-xs lg:leading-[1.375rem] xxl:text-sm xxl:leading-[1.375rem]'>
            Sorry, we couldn&apos;t find any matches.
          </p>
        </div>
      </div>
    </div>
  );
}
