import { useEffect, useState } from 'react';
import { apiEndpoints, useGetQuery } from '@services';
import { TIME_UNITS } from '@constants';

export function useMembershipCards() {
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchToggle = () => setIsChecked(!isChecked);

  const {
    data: membershipPlan,
    refetch: membershipRefetch,
    isFetching: membershipFetching,
  } = useGetQuery(
    'membership-plan-listing',
    apiEndpoints.MEMBERSHIP_PUBLIC_LIST,
    { interval: isChecked ? TIME_UNITS.YEAR : TIME_UNITS.MONTH },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    membershipRefetch();
  }, [isChecked]);

  return {
    isChecked,
    handleSwitchToggle,
    membershipPlan,
    membershipFetching,
  };
}
