import { Button, Card, CardContent, CardFooter } from '@shadcnComponent';
import { ArrowRight } from 'lucide-react';
import { useAIToken } from '@pages/profile';
import { INSTRUCTION_DATA } from '@constants';

export function AiTokensTab() {
  const { aiTokenListing, handleAiTokenCheckout, aiTokenListingFetching } = useAIToken();
  return (
    <div className='flex justify-center overflow-y-auto border border-lightGray bg-white px-[1.1875rem] pt-5 shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] md:pt-8 lg:h-[calc(100vh-10.875rem)] lg:pt-6 xxl:h-[calc(100vh-16.625rem)] xxl:pt-[2.5rem]'>
      <div className='flex flex-col items-center'>
        <h2 className='text-2xl font-bold leading-6 lg:text-base lg:leading-[1.21rem] xxl:text-2xl xxl:leading-6'>
          AI Tokens
        </h2>
        <p className='mt-3 max-w-[17.875rem] text-center text-sm font-normal leading-6 md:max-w-full md:text-xs md:leading-[1.3369rem] lg:mt-2 lg:text-[0.5625rem] lg:leading-[0.6875rem] xxl:mt-3 xxl:text-xs xxl:leading-[1.3369rem]'>
          Unlock Your Potential: Choose the Perfect Plan for You!
        </p>
        <div className='mt-6 flex flex-col gap-4 md:mt-8 md:flex-row md:gap-3.5 xxl:mt-[2.8125rem] xxl:gap-5'>
          {aiTokenListing?.data?.results?.map((token, index) => (
            <Card
              key={token.id}
              className='w-full max-w-[30rem] md:max-w-[13.25rem] lg:max-w-[14.5rem] xxl:max-w-[20.625rem]'
            >
              <CardContent className='px-5 pb-6 pt-5 md:pb-5 lg:px-4 lg:pb-[2.3125rem] lg:pt-4 xxl:px-6 xxl:pb-10 xxl:pt-[1.6875rem]'>
                <p className='mb-2 text-base font-medium leading-[1.21rem] md:mb-[0.9375rem] lg:mb-2 lg:text-xs lg:leading-[0.9375rem] xxl:mb-[0.9375rem] xxl:text-base xxl:leading-5'>
                  Plan {index + 1}
                </p>
                <span className='text-[1.375rem] font-bold leading-[1.6644rem] md:text-2xl md:leading-[1.8156rem] lg:text-base lg:leading-5 xxl:text-2xl xxl:leading-[1.875rem]'>
                  {token.nickname}
                </span>
                <h3 className='end-subscription-gradient mb-3 mt-2 text-[2.5rem] font-semibold leading-[3.0256rem] md:mb-[1.1875rem] md:text-[2.25rem] md:leading-[2.7231rem] lg:my-2 lg:text-[2rem] lg:leading-[2.4375rem] xxl:mb-3 xxl:mt-2 xxl:text-[2.25rem] xxl:leading-[2.7231rem]'>
                  ${token?.unitAmount / 100}
                </h3>
                <p className='text-xs font-normal leading-5 md:text-sm lg:text-[0.625rem] lg:leading-4 xxl:text-sm xxl:leading-5'>
                  {JSON.parse(token?.metadata.key).description}
                </p>
              </CardContent>
              <CardFooter className='px-5 pb-5 lg:px-4 lg:pb-4 xxl:px-6 xxl:pb-[2.3125rem]'>
                <Button
                  className='h-11 !rounded-[6.25rem] border border-paleBlueGray bg-transparent text-sm leading-[1.0588rem] text-darkBlue md:h-10 md:text-xs md:leading-5 lg:h-8 lg:leading-[0.9075rem] xxl:h-10 xxl:text-sm xxl:leading-5'
                  onClick={() => handleAiTokenCheckout({ payload: { priceId: token?.id } })}
                >
                  One-Time Purchase
                  <ArrowRight
                    className='ml-2 h-[0.625rem] w-[0.625rem] md:h-3 md:w-3 lg:ml-[0.1875rem] lg:h-[0.625rem] lg:w-[0.625rem] xxl:h-3 xxl:w-3'
                    color='#00216A'
                  />
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
        {!aiTokenListingFetching && (
          <div className='mb-5 mt-6 w-full md:mt-8 xxl:mt-[3.125rem]'>
            <h2 className='mb-3 text-xl font-bold leading-[1.5125rem] md:mb-[0.9375rem] md:text-2xl md:leading-6 lg:mb-3 lg:text-lg lg:leading-[1.3613rem] xxl:mb-[0.9375rem] xxl:text-2xl xxl:leading-6'>
              Instruction
            </h2>
            <ul className='flex flex-col gap-2 pl-5 md:gap-3'>
              {INSTRUCTION_DATA.map(data => (
                <li
                  key={data.id}
                  className='instruction-list-item text-sm font-normal leading-5 lg:text-xs lg:leading-[0.9081rem] xxl:text-sm xxl:leading-5'
                >
                  {data.instruction}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
