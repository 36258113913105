import { ErrorIcon } from '@assets/svgs';

export function AccessDenied() {
  return (
    <div className='flex h-screen flex-col items-center justify-center gap-8 text-center'>
      <div className='h-[17.9375rem] w-[17.4375rem]'>
        <ErrorIcon />
      </div>
      <div className='flex flex-col gap-4'>
        <h1 className='text-2xl font-semibold leading-[1.875rem]'>Access Denied</h1>
        <div className='flex flex-col'>
          <p className='text-base leading-7'>You do not have permission to access this resource.</p>
          <p className='max-w-[33.125rem] text-base leading-7'>
            The request could not be fulfilled by the server due to insufficient access privileges.
          </p>
        </div>
      </div>
    </div>
  );
}
