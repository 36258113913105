export function HomeCircleIcon({ className }) {
  return (
    <svg
      width='315'
      height='378'
      viewBox='0 0 315 378'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <g opacity='0.4'>
        <circle cx='126' cy='189' r='188.5' stroke='#9AC8FC' />
        <circle cx='126' cy='189' r='151.5' stroke='#9AC8FC' />
        <circle cx='126.5' cy='188.5' r='125' stroke='#9AC8FC' />
        <circle cx='126' cy='189' r='88.5' stroke='#9AC8FC' />
        <circle cx='126.5' cy='188.5' r='65' stroke='#9AC8FC' />
      </g>
    </svg>
  );
}
