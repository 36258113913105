import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePatchMutation, usePostMutation } from '@services';
import {
  ERROR_MESSAGE,
  FIELD_REQUIRED,
  SUCCESSFUL_MESSAGE,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  DEFAULT_ORGANIZATION_DELETE_MESSAGE,
} from '@constants';
export function useHomeEditDialog() {
  const { pathname } = useLocation();

  const imageRef = useRef(null);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [isEditOrganizationLoading, setIsEditOrganizationLoading] = useState(false);
  const [isDeleteOrgLoading, setIsDeleteOrgLoading] = useState(false);
  const [showDeleteOrgDialog, setShowDeleteOrgDialog] = useState(false);
  const [showAddEditDialog, setShowAddEditDialog] = useState();
  const [isUploadedLogo, setIsUploadedLogo] = useState(null);
  const [uploadedLogo, setUploadedLogo] = useState(null);

  const {
    data: manageOrganization,
    refetch: manageOrganizationRefetch,
    isFetching: manageOrganizationFetching,
  } = useGetQuery(
    'home-manage-organization',
    `${apiEndpoints.ORGANIZATIONS}${selectedOrganization?.organizationId}/`,
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      onError: ({ response: { data } }) => {
        toast.error(data?.detail);
        setShowAddEditDialog(false);
        setSelectedOrganization(null);
      },
    }
  );

  const editOrgInitialValues = {
    logo: manageOrganization?.data?.logo?.image,
    organizationName: manageOrganization?.data?.name,
  };

  const editOrgValidationSchema = Yup.object({
    organizationName: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Organization name'))
      .max(60, MAX_LENGTH_REQUIRED('Organization name'))
      .required(FIELD_REQUIRED('Organization name')),
  });

  const handleSubmit = () => {
    let payload = {
      logo: uploadedLogo?.id,
      name: values.organizationName,
    };

    !uploadedLogo?.id && delete payload['logo'];
    manageOrganization?.data?.name === values?.organizationName && delete payload['name'];

    handleOrganizationUpdate({ payload });
    setIsEditOrganizationLoading(true);
  };

  const { ...formik } = useFormikForm(editOrgInitialValues, handleSubmit, editOrgValidationSchema);

  const { values, resetForm } = formik;

  const { mutate: handleImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedLogo(resp?.data);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleOrganizationUpdate } = usePatchMutation(
    apiEndpoints.UPDATE_ORGANIZATIONS(selectedOrganization?.organizationId),
    () => {
      organizationRefetch();
      setShowAddEditDialog(false);
      setIsEditOrganizationLoading(false);
      toast.success(SUCCESSFUL_MESSAGE('Organization updated'));
    },
    ({ response: { data } }) => {
      setIsEditOrganizationLoading(false);
      data?.detail ? toast.error(data?.detail) : toast.error(data?.name?.name[0]);
    }
  );

  const handleDeleteBtn = () => {
    setIsDeleteOrgLoading(false);
    setShowDeleteOrgDialog(true);
    setShowAddEditDialog(false);
  };

  const handleDeleteOrganizationBtn = () => {
    handleOrganizationDelete();
    setIsDeleteOrgLoading(true);
  };

  const openEditDialogue = () => {
    resetForm();
    setUploadedLogo(null);
    setIsEditOrganizationLoading(false);
    setIsUploadedLogo(false);
  };

  const { mutate: handleOrganizationDelete } = useDeleteMutation(
    apiEndpoints.DELETE_ORGANIZATIONS(selectedOrganization?.organizationId),
    () => {
      setShowDeleteOrgDialog(false);
      organizationRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Organization deleted'));
    },
    ({ response: { data } }) => {
      data?.detail ? toast.error(data.detail) : toast.error(DEFAULT_ORGANIZATION_DELETE_MESSAGE);
      setIsDeleteOrgLoading(false);
      setShowDeleteOrgDialog(false);
    }
  );

  let queryParams = { page: 1 };

  const {
    data: organizations,
    refetch: organizationRefetch,
    isFetching: organizationFetching,
  } = useGetQuery(
    ['organizations-home', pathname],
    apiEndpoints.ORGANIZATIONS_HOME,
    { myOrganizations: pathname === '/home/my-organizations' ? true : false, ...queryParams },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    selectedOrganization?.organizationId && manageOrganizationRefetch();
  }, [selectedOrganization?.organizationId]);

  return {
    organizations,
    organizationFetching,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    isUploadedLogo,
    manageOrganization,
    handleOrganizationDelete,
    handleDeleteBtn,
    manageOrganizationFetching,
    openEditDialogue,
    isEditOrganizationLoading,
    isDeleteOrgLoading,
    setIsDeleteOrgLoading,
    handleDeleteOrganizationBtn,
    showAddEditDialog,
    setShowAddEditDialog,
    showDeleteOrgDialog,
    setShowDeleteOrgDialog,
    selectedOrganization,
    setSelectedOrganization,
    handleImageUpload,
  };
}
