import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { BaseLoader, CircularProgressBar, CustomPagination } from '@common/components';
import {
  CreateEditGoalDialog,
  GoalTrackGridView,
  GoalTrackTabularView,
  useEditGoal,
  useGoalsProgress,
} from '@pages/goalTracking';
import { ROUTES } from '@routes';
import { getLocalStorageItem } from '@utils';
import { COLORS, GOLD_TRACKING_TAB, ROLES, TOTAL_ITEMS_PER_PAGE } from '@constants';
import { GridViewIcon, TableViewIcon } from '@assets/svgs';

export function GoalTrack() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { organizationId } = useParams();
  const [currentTab, setCurrentTab] = useState(GOLD_TRACKING_TAB.TABULAR_VIEW);

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const {
    goalsProgress,
    goalsListing,
    goalDetail,
    setGoalDetail,
    handleGoalDelete,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteGoalLoading,
    setDeleteGoalLoading,
    formik,
    dashboardsListing,
    widgetListing,
    createGoalLoading,
    showCreateDialog,
    setShowCreateDialog,
    goalsProgressLoading,
    goalsListingLoading,
    setDashboardGoal,
    page,
    setPage,
  } = useGoalsProgress();

  const {
    formik: editFormik,
    showEditDialog,
    setShowEditDialog,
    setEditGoalDetail,
    goalEditLoading,
    setSelectedDashboardId,
  } = useEditGoal();

  useEffect(() => {
    if (pathname === `/goal-tracking/${organizationId}`) {
      navigate(`/goal-tracking/${organizationId}/${ROUTES.GOAL_TRACKING_TABULAR_VIEW}`);
      setCurrentTab(GOLD_TRACKING_TAB.TABULAR_VIEW);
    } else if (pathname === `/goal-tracking/${organizationId}/${ROUTES.GOAL_TRACKING_GRID_VIEW}`) {
      navigate(`/goal-tracking/${organizationId}/${ROUTES.GOAL_TRACKING_GRID_VIEW}`);
      setCurrentTab(GOLD_TRACKING_TAB.GRID_VIEW);
    }
  }, [pathname]);
  return (
    <div className='mx-auto max-w-[30rem] px-5 py-6 md:max-w-[48rem] md:p-8 lg:mx-0 lg:max-w-full lg:py-4 xxl:px-10 xxl:py-6'>
      {goalsProgressLoading || goalsListingLoading ? (
        <BaseLoader />
      ) : (
        <>
          <h2 className='mb-6 text-2xl font-bold leading-[1.8156rem] md:mb-5 md:text-[2rem] md:leading-[2.4206rem] lg:hidden'>
            Goal Tracking
          </h2>
          <div className='mb-6 flex flex-col justify-between gap-3 md:flex-row md:items-center lg:mb-[1.1875rem] xxl:mb-6'>
            <h3 className='text-xl font-semibold leading-[1.5125rem] md:text-2xl md:leading-[1.8125rem] lg:text-base lg:leading-[1.1875rem] xxl:text-xl xxl:leading-[1.5125rem]'>
              All Goals
            </h3>
            {userOrganizationRole !== ROLES.MEMBER && (
              <Dialog
                open={showCreateDialog}
                onOpenChange={status => {
                  setShowCreateDialog(status);
                  !status && formik.resetForm();
                }}
              >
                <Button
                  className='h-12 w-full rounded-md bg-darkPrimary text-sm font-medium leading-[1.0588rem] text-white md:h-10 md:w-[11.25rem] lg:h-8 lg:w-[6.625rem] xxl:h-10 xxl:w-[7rem] xxl:text-sm xxl:leading-6'
                  onClick={() => setShowCreateDialog(true)}
                >
                  Create Goal
                </Button>
                <CreateEditGoalDialog
                  title='Create Goal'
                  formik={formik}
                  dashboardsListing={dashboardsListing}
                  widgetListing={widgetListing}
                  isLoading={createGoalLoading}
                  setSelectedDashboardId={setSelectedDashboardId}
                />
              </Dialog>
            )}
          </div>
          <div className='flex flex-col gap-4 md:flex-row md:gap-[0.8125rem] lg:gap-9 xxl:gap-7'>
            <CircularProgressBar
              title='On Track'
              value={goalsProgress?.data?.totalOnTrack}
              color={COLORS.ON_TRACK_COLOR}
            />
            <CircularProgressBar
              title='Off Track'
              value={goalsProgress?.data?.totalOffTrack}
              color={COLORS.OFF_TRACK_COLOR}
            />
            <CircularProgressBar
              title='Completed'
              value={goalsProgress?.data?.totalCompleted}
              color={COLORS.COMPLETED}
            />
          </div>
          <Tabs value={currentTab} onValueChange={setCurrentTab} className='mt-6 w-full md:mt-8 lg:mt-4 xxl:mt-6'>
            <div className='mb-6 flex items-center justify-between lg:mb-4 xxl:mb-[1.6875rem]'>
              <h3 className='text-xl font-semibold leading-[1.5125rem] md:text-2xl md:leading-[1.8125rem] lg:text-base lg:leading-[1.1875rem] xxl:text-xl xxl:leading-[1.5125rem]'>
                Goal Details
              </h3>
              <TabsList className='!h-min w-min bg-transparent !p-0'>
                <Link to={ROUTES.GOAL_TRACKING_TABULAR_VIEW}>
                  <TabsTrigger
                    value={GOLD_TRACKING_TAB.TABULAR_VIEW}
                    className='!w-min px-2 py-1.5 md:px-3 lg:px-2.5 lg:py-[0.3125rem] xxl:px-3 xxl:py-1.5'
                  >
                    <TableViewIcon strokeColor={currentTab === GOLD_TRACKING_TAB.TABULAR_VIEW ? '#fff' : '#000'} />
                  </TabsTrigger>
                </Link>
                <Link to={ROUTES.GOAL_TRACKING_GRID_VIEW}>
                  <TabsTrigger
                    value={GOLD_TRACKING_TAB.GRID_VIEW}
                    className='!w-min px-2 py-1.5 md:px-3 lg:px-2.5 lg:py-[0.3125rem] xxl:px-3 xxl:py-1.5'
                  >
                    <GridViewIcon strokeColor={currentTab === GOLD_TRACKING_TAB.GRID_VIEW ? '#fff' : '#000'} />
                  </TabsTrigger>
                </Link>
              </TabsList>
            </div>
            <TabsContent value={GOLD_TRACKING_TAB.TABULAR_VIEW}>
              <GoalTrackTabularView
                goalsListing={goalsListing}
                goalDetail={goalDetail}
                setGoalDetail={setGoalDetail}
                setEditGoalDetail={setEditGoalDetail}
                handleGoalDelete={handleGoalDelete}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteGoalLoading={deleteGoalLoading}
                setDeleteGoalLoading={setDeleteGoalLoading}
                showEditDialog={showEditDialog}
                setShowEditDialog={setShowEditDialog}
                widgetListing={widgetListing}
                formik={editFormik}
                dashboardsListing={dashboardsListing}
                isLoading={goalEditLoading}
                setSelectedDashboardId={setSelectedDashboardId}
                setDashboardGoal={setDashboardGoal}
              />
            </TabsContent>
            <TabsContent value={GOLD_TRACKING_TAB.GRID_VIEW}>
              <GoalTrackGridView
                goalsListing={goalsListing}
                goalDetail={goalDetail}
                setGoalDetail={setGoalDetail}
                setEditGoalDetail={setEditGoalDetail}
                handleGoalDelete={handleGoalDelete}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteGoalLoading={deleteGoalLoading}
                setDeleteGoalLoading={setDeleteGoalLoading}
                showEditDialog={showEditDialog}
                setShowEditDialog={setShowEditDialog}
                widgetListing={widgetListing}
                formik={editFormik}
                dashboardsListing={dashboardsListing}
                isLoading={goalEditLoading}
                setSelectedDashboardId={setSelectedDashboardId}
                setDashboardGoal={setDashboardGoal}
              />
            </TabsContent>
          </Tabs>
          <div className='mb-10 mt-6 md:mt-[2.375rem] lg:mb-0 lg:mt-6'>
            {goalsListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
              <CustomPagination page={page} setPage={setPage} data={goalsListing} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
