/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CacheBuster from 'react-cache-buster';
import { ToastContainer } from 'react-toastify';
import { App } from './App.jsx';
import packageJson from '../package.json';
import { ENABLE } from '@constants';
import 'driver.js/dist/driver.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const queryClient = new QueryClient();
const CLIENT_ID = `${import.meta.env.VITE_APP_CLIENT_ID}`;

const isCacheBusterEnable = import.meta.env.VITE_CACHE_BUSTER === ENABLE ? true : false;

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={isCacheBusterEnable}
      isVerboseMode={false}
      metaFileDirectory={'.'}
    >
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </CacheBuster>
    <ToastContainer />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
);
