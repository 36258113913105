import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@shadcnUtils';
import { cva } from 'class-variance-authority';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm leading-[1.0588rem] md:text-sm md:leading-6 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6 font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'text-white bg-darkPrimary focus:bg-darkPrimary/80 rounded md:rounded-lg lg:rounded xxl:rounded-md hover:bg-slateGray',
        destructive: 'bg-errorColor text-white hover:bg-errorColor/90',
        outline: 'border md:border-2 lg:border border-darkSecondary rounded-md hover:cursor-pointer text-darkSecondary',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-colorTertiary/40',
        active: 'text-darkPrimary',
      },
      size: {
        default: 'xxl:h-10 h-12 md:h-10 lg:h-8 px-4 py-2 w-full',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-8 rounded-md px-8',
        icon: 'h-10 w-10',
        auto: 'p-0 w-min',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
