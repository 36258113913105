import { Button, Popover, PopoverContent, PopoverTrigger } from '@shadcnComponent';
import { ChatbotBody, ChatbotHeader } from '.';
import { useChatbot } from '@pages/dashboard';
import { ChatbotIcon } from '@assets/svgs';

export function Chatbot() {
  const {
    handleChatMessage,
    message,
    setMessage,
    chatbotRef,
    setShowChatbotPopup,
    chatbotMessages,
    chatMessageLoading,
  } = useChatbot();

  return (
    <Popover onOpenChange={setShowChatbotPopup}>
      <PopoverTrigger asChild>
        <Button className='chatbot-button chatbot-shadow fixed bottom-10 right-8 z-10 h-[3.875rem] w-[3.875rem] !rounded-full md:bottom-[3.75rem] md:right-10 md:h-20 md:w-20 lg:bottom-10 lg:h-[3.25rem] lg:w-[3.25rem] xxl:bottom-[3.75rem] xxl:right-[3.75rem] xxl:!h-[3.875rem] xxl:!w-[3.875rem]'>
          <ChatbotIcon className='h-[2.1738rem] min-w-[2.1738rem] md:h-[2.805rem] md:min-w-[3.055rem] lg:h-[1.8231rem] lg:min-w-[1.8231rem] xxl:h-[2.1738rem] xxl:min-w-[2.1738rem]' />
        </Button>
      </PopoverTrigger>
      <PopoverContent align='end' className='w-[27.5rem] rounded-xl p-0'>
        <ChatbotHeader />
        <ChatbotBody
          handleChatMessage={handleChatMessage}
          message={message}
          setMessage={setMessage}
          chatbotRef={chatbotRef}
          chatbotMessages={chatbotMessages}
          chatMessageLoading={chatMessageLoading}
        />
      </PopoverContent>
    </Popover>
  );
}
