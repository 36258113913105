import { cn } from '@shadcnUtils';
import { Upload } from 'lucide-react';
import { BarLoader } from 'react-spinners';
import { WidgetCommentBtn, WidgetMenu, WidgetTitle } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function ImageWidget({
  widget,
  onRemoveClick,
  handleImageInputChange,
  isSharedDashboard,
  isEditMode,
  handleEditWidget,
  handleClone,
  widgetImageRef,
  className,
  titleText,
  inputText,
  inputWrapper,
  uploadIcon,
  isUploadedWidgetImage,
  showCommentBtn,
  handleEditCommentMode,
  widgetCommentClass,
  hideTooltip,
}) {
  return (
    <div className={cn('flex h-full flex-col gap-2', className)}>
      <div className='flex h-6 items-center justify-between gap-3'>
        {widget?.showLabel && <WidgetTitle titleText={titleText} widget={widget} hideTooltip={hideTooltip} />}
        {!widget?.widgetData?.error && (
          <>
            {!isSharedDashboard && (
              <WidgetCommentBtn
                widget={widget}
                showCommentBtn={showCommentBtn}
                handleEditCommentMode={() => handleEditCommentMode(widget)}
                className={widgetCommentClass}
              />
            )}
          </>
        )}
        {!isSharedDashboard && isEditMode && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
          />
        )}
      </div>
      {widget?.widgetData?.error ? (
        <p
          className='text-md flex h-[calc(100%-3.4375rem)] w-full items-center justify-center font-bold'
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? {
                color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
              }
              : {
                color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
              }
          }
        >
          {widget?.widgetData?.error}
        </p>
      ) : (
        <>
          {!widget.image && (
            <div
              className={cn(
                'relative flex h-10 items-center justify-center rounded border border-darkPrimary text-xs leading-[0.9075rem] focus:outline-none focus:ring-0',
                inputWrapper
              )}
            >
              <input
                ref={widgetImageRef}
                type='file'
                accept='.jpg, .jpeg, .png'
                onChange={e => handleImageInputChange(e, widget)}
                className='w-available absolute z-10 h-full w-full opacity-0'
              />
              <div className={cn('flex items-center gap-2.5 text-sm font-medium leading-6', inputText)}>
                <Upload className={cn('h-5 w-5', uploadIcon)} />
                Upload
                {isUploadedWidgetImage && <BarLoader />}
              </div>
            </div>
          )}
          {!!widget.image && (
            <div className='h-full w-full'>
              <img src={widget.image} className='h-full w-full' />
            </div>
          )}
        </>
      )}
    </div>
  );
}
