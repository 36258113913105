import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { SUCCESSFUL_MESSAGE, TIME_UNITS } from '@constants';

export function useMembershipTab() {
  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState({});

  const { data: userDetail, refetch: userDetailRefetch } = useGetQuery('user-detail', apiEndpoints.USER_DETAIL);

  const membershipInterval = userDetail?.data?.membershipInterval;
  const [isChecked, setIsChecked] = useState(membershipInterval === TIME_UNITS.YEAR);

  const {
    data: membershipPlan,
    refetch: membershipRefetch,
    isFetching: membershipFetching,
  } = useGetQuery(
    'membership-plan',
    apiEndpoints.MEMBERSHIP,
    { interval: isChecked ? TIME_UNITS.YEAR : TIME_UNITS.MONTH },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { mutate: handleMembershipCheckout, isLoading: membershipCheckoutLoading } = usePostMutation(
    apiEndpoints.MEMBERSHIP_CHECKOUT,
    resp => {
      window.location.href = resp?.data?.checkoutSessionUrl;
      membershipRefetch();
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleMembershipUpgrade, isLoading: membershipUpgradeLoading } = usePostMutation(
    apiEndpoints.MEMBERSHIP_UPGRADE,
    () => {
      setTimeout(() => {
        membershipRefetch();
        toast.success(SUCCESSFUL_MESSAGE('Membership upgraded'));
      }, 2000);
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleCancelMembership, isLoading: isCancelMembershipLoading } = usePostMutation(
    apiEndpoints.MEMBERSHIP_CANCEL,
    () => {
      membershipRefetch();
      userDetailRefetch();
      setShowCancelSubscriptionDialog({});
      toast.success(SUCCESSFUL_MESSAGE('Membership cancel'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const handleSwitchToggle = () => setIsChecked(!isChecked);

  const calculateTrialDays = date => {
    const currendDate = moment();
    const endDate = moment(date);
    const daysDiff = endDate.diff(currendDate, 'days');
    return daysDiff;
  };

  useEffect(() => {
    [null, TIME_UNITS.MONTH, TIME_UNITS.YEAR].includes(userDetail?.data?.membershipInterval) && membershipRefetch();
  }, [isChecked, userDetail?.data]);

  useEffect(() => {
    userDetail?.data?.membershipInterval === TIME_UNITS.YEAR ? setIsChecked(true) : setIsChecked(false);
  }, [userDetail]);

  return {
    isChecked,
    handleSwitchToggle,
    membershipPlan,
    membershipFetching,
    userDetail,
    handleMembershipCheckout,
    handleMembershipUpgrade,
    handleCancelMembership,
    showCancelSubscriptionDialog,
    setShowCancelSubscriptionDialog,
    isCancelMembershipLoading,
    membershipCheckoutLoading,
    membershipUpgradeLoading,
    calculateTrialDays,
  };
}
