import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { FIELD_REQUIRED, SUCCESSFUL_MESSAGE } from '@constants';

export function useGoalsProgress() {
  const { organizationId } = useParams();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteGoalLoading, setDeleteGoalLoading] = useState(false);
  const [goalDetail, setGoalDetail] = useState({});
  const [dashboardGoal, setDashboardGoal] = useState({});
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [page, setPage] = useState(1);

  const { data: goalsProgress, isLoading: goalsProgressLoading } = useGetQuery(
    'goals-progress',
    apiEndpoints.GOALS_PROGRESS(organizationId)
  );

  const {
    data: goalsListing,
    refetch: goalsListingRefetch,
    isLoading: goalsListingLoading,
  } = useGetQuery(
    'goals-listing',
    apiEndpoints.ORGANIZATION_GOALS(organizationId),
    { page },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dashboardsListing } = useGetQuery(
    'team-dashboards-listing',
    apiEndpoints.CREATE_TEAM_DASHBOARDS_LISTING(organizationId)
  );

  const goalInitialValues = {
    name: '',
    dashboard: '',
    timePeriod: '',
    description: '',
    widget: '',
    condition: '',
    metric: '',
  };

  const goalValidationSchema = Yup.object({
    name: Yup.string().required(FIELD_REQUIRED('Goal name')),
    dashboard: Yup.object().required(FIELD_REQUIRED('Dashboard')),
    timePeriod: Yup.object().required(FIELD_REQUIRED('Time period')),
    description: Yup.string().required(FIELD_REQUIRED('Description')),
    widget: Yup.object().required(FIELD_REQUIRED('Widget')),
    condition: Yup.object().required(FIELD_REQUIRED('Condition')),
    metric: Yup.string().required(FIELD_REQUIRED('Metric')),
  });

  const handleSubmit = () => {
    let payload = {
      name: values?.name,
      dashboard: values?.dashboard?.id,
      description: values?.description,
      widgetId: values?.widget?.widgetId,
      widgetSource: values?.widget?.widgetSource.toUpperCase(),
      widgetLabel: values?.widget?.widgetLabel,
      widgetPrefix: values?.widget?.widgetPrefix,
      periodInDays: values.timePeriod?.id,
      target: parseInt(values?.metric),
      condition: values?.condition?.id,
    };
    handleCreateGoal({ payload });
  };

  const { ...formik } = useFormikForm(goalInitialValues, handleSubmit, goalValidationSchema);
  const { values, resetForm, setErrors } = formik;

  const { data: widgetListing, refetch: widgetListingRefetch } = useGetQuery(
    'goal-widget-listing',
    apiEndpoints.WIDGET_LIST(organizationId, values?.dashboard?.id),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { mutate: handleCreateGoal, isLoading: createGoalLoading } = usePostMutation(
    apiEndpoints.CREATE_GOAL(organizationId),
    () => {
      resetForm();
      setShowCreateDialog(false);
      goalsListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Goal created'));
    },
    ({ response: { data } }) => {
      setErrors({
        name: data.name[0] && data.name[0],
      });
    }
  );

  const { mutate: handleAddToDashboard } = usePostMutation(
    apiEndpoints.ADD_TO_DASHBOARD(organizationId, dashboardGoal?.dashboard, dashboardGoal?.id),
    () => {
      setDashboardGoal({});
      toast.success(SUCCESSFUL_MESSAGE('Goal added to the dashboard'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleGoalDelete } = useDeleteMutation(
    apiEndpoints.DELETE_GOAL(organizationId, goalDetail?.id),
    () => {
      setShowDeleteDialog(false);
      setDeleteGoalLoading(false);
      goalsListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Goal deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteGoalLoading(false);
      setShowDeleteDialog(false);
    }
  );

  useEffect(() => {
    values?.dashboard?.id && widgetListingRefetch();
  }, [values?.dashboard?.id]);

  useEffect(() => {
    dashboardGoal?.id && handleAddToDashboard({});
  }, [dashboardGoal?.id]);

  useEffect(() => {
    goalsListingRefetch();
  }, [page]);

  return {
    goalsProgress,
    goalsListing,
    goalDetail,
    setGoalDetail,
    handleGoalDelete,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteGoalLoading,
    setDeleteGoalLoading,
    formik,
    dashboardsListing,
    widgetListingRefetch,
    widgetListing,
    createGoalLoading,
    showCreateDialog,
    setShowCreateDialog,
    goalsProgressLoading,
    goalsListingLoading,
    setDashboardGoal,
    page,
    setPage,
  };
}
