export const getRectCrop = async (imageSrc, crop) => {
  const image = new Image();
  image.src = imageSrc;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }
      resolve(blob);
    }, 'image/png');
  });
};

export const getCircularCrop = async (imageSrc, croppedAreaPixels) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const maxSize = Math.max(croppedAreaPixels.width, croppedAreaPixels.height);
      const canvas = document.createElement('canvas');
      canvas.width = maxSize;
      canvas.height = maxSize;
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(0, 0, 0, 0)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.save();
      ctx.beginPath();
      ctx.arc(maxSize / 2, maxSize / 2, maxSize / 2, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.clip();

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const x = croppedAreaPixels.x * scaleX;
      const y = croppedAreaPixels.y * scaleY;
      const height = croppedAreaPixels.height * scaleY;
      const width = croppedAreaPixels.width * scaleX;
      ctx.drawImage(image, x, y, width, height, 0, 0, maxSize, maxSize);

      ctx.restore();

      canvas.toBlob(blob => {
        if (!blob) {
          reject('Canvas is empty');
          return;
        }
        resolve(blob);
      }, 'image/png');
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    image.src = imageSrc;
  });
};
