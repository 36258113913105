import { NavLink, generatePath } from 'react-router-dom';
import { ScrollArea, Button, Dialog, DialogContent } from '@shadcnComponent';
import { BaseLoader, CustomPagination, BaseToolTip } from '@common/components';
import {
  DataSourceSearch,
  OAuthUrlListing,
  useAddDataSource,
  DateField,
  NameField,
  AccountField,
  NonAuthField,
  NoDataDisplay,
  AddConnectButton,
  ConnectButton,
  BackButton,
  SelectField,
} from '@pages/dataSource';
import { ROUTES } from '@routes';
import { capitalizeSourceName, truncateString } from '@utils';
import { DATA_SOURCE_SEARCH_NOT_MATCHED, UPPERCASE_DATA_SOURCES } from '@constants';
import { ArrowLeft, EmptyDashboardScreenIcon } from '@assets/svgs';

const dataSourcesWithFields = [
  UPPERCASE_DATA_SOURCES.WOO_COMMERCE,
  UPPERCASE_DATA_SOURCES.PIPE_DRIVE,
  UPPERCASE_DATA_SOURCES.KLAVIYO,
  UPPERCASE_DATA_SOURCES.STRIPE,
  UPPERCASE_DATA_SOURCES.TYPEFORM,
  UPPERCASE_DATA_SOURCES.ZOHO_CRM,
  UPPERCASE_DATA_SOURCES.ACTIVE_CAMPAIGN,
  UPPERCASE_DATA_SOURCES.CLICK_UP,
];

const forConnectTextButtonConnectors = [UPPERCASE_DATA_SOURCES.HUBSPOT, ...dataSourcesWithFields];

export function AddDataSource() {
  const {
    addDataSourceListing,
    page,
    setPage,
    setSearch,
    showConnectDataSourceDialog,
    setShowConnectDataSourceDialog,
    addDataSourceLoading,
    connectedDataSourceLoading,
    handleConnectNewAccount,
    oAuthListing,
    setOAuthListing,
    oAuthListingLoading,
    handleConnectDataSource,
    checkedOauthId,
    setCheckedOauthId,
    setConnectDataSourceLoading,
    showDateField,
    organizationId,
    showNameField,
    clearDialogue,
    handleBackClick,
    date,
    setDate,
    showAccount,
    accountListing,
    checkedAccountId,
    setCheckedAccountId,
    setCheckedAccount,
    showProperties,
    accountPropertiesListing,
    checkedPropertyId,
    setCheckedPropertyId,
    setCheckedProperty,
    fbAccountListing,
    nameField,
    handleNameChange,
    nonAuthFields,
    handleNonAuthFields,
    isNonAuthConnector,
    linkedInAccounts,
    handleLinkedInAccountsChecked,
    typeFormAccountListing,
    showMultiChecks,
    handleMultipleChecked,
    checkedIds,
    showSelectField,
    clickUpSteps,
    clickUpTeamListing,
    clickUpSpacingListing,
    clickUpFolderListing,
    clickUpListing,
    teamId,
    setTeamId,
    spaceId,
    setSpaceId,
    folderId,
    setFolderId,
    listId,
    setListId,
    setClickUpSteps,
    connectNewAccountProcessing,
  } = useAddDataSource();

  const { sourceName: nonAuthSourceName = null } = showConnectDataSourceDialog?.dataSource || {};

  const getClickUpData = () => {
    const steps = [
      { condition: clickUpSteps.teamListing, data: { listing: clickUpTeamListing, id: teamId, setId: setTeamId } },
      {
        condition: clickUpSteps.spaceListing,
        data: { listing: clickUpSpacingListing, id: spaceId, setId: setSpaceId },
      },
      {
        condition: clickUpSteps.folderListing,
        data: { listing: clickUpFolderListing, id: folderId, setId: setFolderId },
      },
      {
        condition: clickUpSteps.listing,
        data: { listing: clickUpListing, id: listId, setId: setListId },
      },
    ];

    const defaultData = { listing: oAuthListing, id: checkedOauthId, setId: setCheckedOauthId };

    const stepData = steps.find(step => step.condition) || { data: defaultData };

    return stepData.data;
  };

  const { listing, id, setId } = getClickUpData();

  const getAuthHeading = () => {
    switch (true) {
    case clickUpSteps.listing:
      return 'Lists';
    case clickUpSteps.folderListing:
      return 'Folders';
    case clickUpSteps.spaceListing:
      return 'Spaces';
    case clickUpSteps.teamListing:
      return 'Teams';
    }
  };

  return (
    <div className='relative mx-auto my-[1.6875rem] max-w-[30rem] px-5 md:my-[1.4375rem] md:max-w-[48rem] md:px-8 lg:my-4 lg:max-w-full xxl:my-6 xxl:px-10'>
      <div className='mb-6 flex flex-col gap-6 md:mb-8 md:flex-row md:items-center md:justify-between lg:mb-4 xxl:mb-6'>
        <NavLink
          to={generatePath(ROUTES.DATA_SOURCE, { organizationId })}
          className='flex items-center gap-4 lg:gap-1.5 xxl:gap-4'
        >
          <ArrowLeft className='h-6 w-6 md:h-8 md:w-8 lg:h-3.5 lg:w-3.5 xxl:h-[1.1875rem] xxl:w-5' />
          <h4 className='font-bold lg:text-sm'>Add Data Source</h4>
        </NavLink>
        <div className='relative w-full sm:w-[11.25rem] md:w-[16.5625rem] lg:w-[11.875rem] xxl:w-[16.5625rem]'>
          <DataSourceSearch setSearch={setSearch} />
        </div>
      </div>
      {addDataSourceLoading ? (
        <div className='h-[calc(100vh-12rem)]'>
          <BaseLoader />
        </div>
      ) : (
        <div className='flex flex-col gap-9'>
          <div className='lg:h-[calc(100vh-16.875rem)] xxl:h-[calc(100vh-17.5rem)]'>
            <ScrollArea className='relative h-full'>
              <div className='overflow-hidden'>
                {addDataSourceListing?.data?.results.length === 0 ? (
                  <div className='flex h-[calc(100vh-258px)] flex-col items-center justify-center gap-6 md:gap-10 lg:gap-6 xxl:h-[calc(100vh-208px)] xxl:gap-10'>
                    <EmptyDashboardScreenIcon className='h-[8.9375rem] w-[12rem] md:h-[11.75rem] md:w-[15.75rem] lg:h-[7.8125rem] lg:w-[10.5rem] xxl:h-[11.75rem] xxl:w-[15.75rem]' />
                    <div>
                      <p className='text-center text-base font-bold leading-[1.21rem] text-darkSecondary md:text-lg md:leading-[1.3613rem] lg:text-sm lg:leading-[1.0588rem] xxl:text-base xxl:leading-[1.21rem]'>
                        {DATA_SOURCE_SEARCH_NOT_MATCHED}
                      </p>
                      <p className='mt-3 max-w-[19.8125rem] text-center text-sm font-normal leading-[1.375rem] md:max-w-[30.8125rem] lg:mt-2 lg:max-w-[23.1875rem] lg:text-xs xxl:mt-3 xxl:max-w-[30.8125rem] xxl:text-sm'>
                        Sorry, we couldn&apos;t find any matches. Try adjusting your search criteria or explore other
                        categories.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className='grid-layout grid gap-5 md:gap-[1.5625rem] lg:gap-[0.8125rem] xxl:grid-cols-17 xxl:gap-x-5 xxl:gap-y-[1.0625rem]'>
                    {addDataSourceListing?.data?.results.map(dataSource => (
                      <div
                        key={dataSource?.id}
                        className='flex flex-col gap-3 rounded-md border border-lightGray bg-white p-4 shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] md:px-3 lg:gap-1.5 lg:rounded-lg lg:p-3 xxl:gap-2.5 xxl:px-4 xxl:pb-3.5 xxl:pt-[1.125rem]'
                      >
                        <div className='flex items-center gap-2 lg:gap-1.5 xxl:gap-2'>
                          <div className='h-5 w-5 md:h-8 md:w-8 lg:h-5 lg:w-5 xxl:h-8 xxl:w-8'>
                            <img src={dataSource?.logo?.image} alt='data-source-image' className='h-full w-full' />
                          </div>
                          <h6 className='max-w-[14.0625rem] truncate'>{dataSource?.sourceName}</h6>
                        </div>
                        <p
                          id={`dataSource-${dataSource?.id}`}
                          className='text-xs font-normal leading-4 text-slateGray md:text-sm md:leading-5 lg:text-[0.625rem] lg:leading-[0.875rem] xxl:text-xs xxl:leading-5'
                        >
                          {truncateString(dataSource?.description, 30)}
                        </p>
                        {dataSource?.description?.length > 30 && (
                          <BaseToolTip
                            className='max-w-[18.75rem]'
                            id={`dataSource-${dataSource?.id}`}
                            content={dataSource?.description}
                          />
                        )}
                        <Dialog open={showConnectDataSourceDialog[dataSource?.id]} onOpenChange={clearDialogue}>
                          <Button
                            className='h-8 w-full text-[0.625rem] font-medium  md:h-10 md:w-[7.5rem] md:text-sm lg:h-6 lg:w-[4.0625rem] lg:text-[0.625rem] xxl:h-[1.625rem] xxl:w-[4.5625rem] xxl:text-xs'
                            onClick={() => {
                              setShowConnectDataSourceDialog({
                                ...showConnectDataSourceDialog,
                                [dataSource.id]: true,
                                dataSource: dataSource,
                              });
                              setConnectDataSourceLoading(false);
                            }}
                          >
                            Connect
                          </Button>
                          <DialogContent className='h-[31.25rem] w-[calc(100vw-2.5rem)] max-w-[24.125rem] rounded-md px-6 py-10 md:h-[34.3125rem] md:max-w-[34rem] md:rounded-lg md:px-10 md:py-[3.75rem] lg:h-[24.375rem] lg:w-[24.125rem] lg:rounded-md lg:px-6 lg:py-10 xxl:h-[34.3125rem] xxl:w-[34rem] xxl:rounded-lg xxl:px-10 xxl:py-[3.75rem]'>
                            {oAuthListingLoading ? (
                              <BaseLoader className='left-0 top-0 w-full' />
                            ) : (
                              <>
                                <div className='mb-6 flex flex-col items-center justify-center gap-3 text-xl font-bold leading-[1.5125rem] md:text-2xl md:leading-7 lg:gap-2 lg:text-base lg:leading-[1.21rem] xxl:gap-3 xxl:text-2xl xxl:leading-7'>
                                  <img
                                    src={dataSource?.logo?.image}
                                    alt='shopify'
                                    className='h-14 w-14 md:h-[3.75rem] md:w-[3.75rem] lg:h-[2.625rem] lg:w-[2.625rem] xxl:h-[3.75rem] xxl:w-[3.75rem]'
                                  />
                                  {dataSource?.sourceName}
                                </div>
                                <p className='mb-2 text-[1rem] font-bold'>{getAuthHeading()}</p>
                                <div className='flex h-[12.25rem] flex-col justify-between rounded-lg border border-lightGray md:h-[15.0625rem] lg:h-[10.6875rem] xxl:h-[15.0625rem]'>
                                  {showDateField ? (
                                    <DateField
                                      oAuthListing={oAuthListing}
                                      checkedOauthId={checkedOauthId}
                                      date={date}
                                      setDate={setDate}
                                      showInfoIcon={true}
                                      infoContent={{ text: 'The date you would like to replicate data from.' }}
                                    />
                                  ) : (
                                    <div className='h-full'>
                                      {showNameField ? (
                                        <NameField
                                          dataSource={dataSource}
                                          nameField={nameField}
                                          handleNameChange={handleNameChange}
                                        />
                                      ) : showAccount ? (
                                        <AccountField
                                          showProperties={showProperties}
                                          accountPropertiesListing={accountPropertiesListing}
                                          checkedPropertyId={checkedPropertyId}
                                          setCheckedPropertyId={setCheckedPropertyId}
                                          setCheckedProperty={setCheckedProperty}
                                          oAuthListing={oAuthListing}
                                          linkedInAccounts={linkedInAccounts}
                                          handleLinkedInAccountsChecked={handleLinkedInAccountsChecked}
                                          dataSource={dataSource}
                                          fbAccountListing={fbAccountListing}
                                          accountListing={accountListing}
                                          typeFormAccountListing={typeFormAccountListing}
                                          checkedAccountId={checkedAccountId}
                                          setCheckedAccountId={setCheckedAccountId}
                                          setCheckedAccount={setCheckedAccount}
                                          showMultiChecks={showMultiChecks}
                                          handleMultipleChecked={handleMultipleChecked}
                                          checkedIds={checkedIds}
                                        />
                                      ) : isNonAuthConnector ? (
                                        <>
                                          {isNonAuthConnector && nonAuthSourceName && (
                                            <NonAuthField
                                              nonAuthSourceName={capitalizeSourceName(nonAuthSourceName)}
                                              nonAuthFields={nonAuthFields}
                                              handleNonAuthFields={handleNonAuthFields}
                                            />
                                          )}
                                        </>
                                      ) : showSelectField ? (
                                        <SelectField
                                          nonAuthSourceName={capitalizeSourceName(nonAuthSourceName)}
                                          nonAuthFields={nonAuthFields}
                                          handleNonAuthFields={handleNonAuthFields}
                                        />
                                      ) : (
                                        <div className='flex-grow'>
                                          {oAuthListing?.data?.results.length > 0 ? (
                                            <OAuthUrlListing listing={listing} checkedId={id} setCheckedId={setId} />
                                          ) : (
                                            <NoDataDisplay />
                                          )}
                                        </div>
                                      )}
                                      {!showAccount && !connectNewAccountProcessing && (
                                        <AddConnectButton
                                          handleConnectNewAccount={handleConnectNewAccount}
                                          connectedDataSourceLoading={connectedDataSourceLoading}
                                          showNameField={showNameField}
                                          nameField={nameField}
                                          dataSource={dataSource}
                                          dataSourcesWithFields={dataSourcesWithFields}
                                          setConnectDataSourceLoading={setConnectDataSourceLoading}
                                          checkedOauthId={checkedOauthId}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className='mt-4 flex flex-col-reverse gap-3 md:mt-6 md:flex-row md:justify-end md:gap-4 lg:mt-4 xxl:mt-6 xxl:gap-4'>
                                  <BackButton
                                    showDateField={showDateField}
                                    showSelectField={showSelectField}
                                    showNameField={showNameField}
                                    showAccount={showAccount}
                                    showProperties={showProperties}
                                    isNonAuthConnector={isNonAuthConnector}
                                    handleBackClick={handleBackClick}
                                    setShowConnectDataSourceDialog={setShowConnectDataSourceDialog}
                                    connectedDataSourceLoading={connectedDataSourceLoading}
                                    setOAuthListing={setOAuthListing}
                                    clickUpSteps={clickUpSteps}
                                    setClickUpSteps={setClickUpSteps}
                                    setTeamId={setTeamId}
                                    setSpaceId={setSpaceId}
                                    setFolderId={setFolderId}
                                    setListId={setListId}
                                  />
                                  <ConnectButton
                                    connectedDataSourceLoading={connectedDataSourceLoading}
                                    isNonAuthConnector={isNonAuthConnector}
                                    nonAuthSourceName={capitalizeSourceName(nonAuthSourceName)}
                                    checkedOauthId={checkedOauthId}
                                    showAccount={showAccount}
                                    showSelectField={showSelectField}
                                    showProperties={showProperties}
                                    checkedPropertyId={checkedPropertyId}
                                    checkedAccountId={checkedAccountId}
                                    nonAuthFields={nonAuthFields}
                                    handleConnectDataSource={handleConnectDataSource}
                                    forConnectTextButtonConnectors={forConnectTextButtonConnectors}
                                    dataSource={dataSource}
                                    showDateField={showDateField}
                                    checkedIds={checkedIds}
                                    showMultiChecks={showMultiChecks}
                                    clickUpSteps={clickUpSteps}
                                    setClickUpSteps={setClickUpSteps}
                                    listId={listId}
                                    folderId={folderId}
                                    spaceId={spaceId}
                                    teamId={teamId}
                                    linkedInAccounts={linkedInAccounts}
                                    connectNewAccountProcessing={connectNewAccountProcessing}
                                    handleConnectNewAccount={handleConnectNewAccount}
                                    nameField={nameField}
                                  />
                                </div>
                              </>
                            )}
                          </DialogContent>
                        </Dialog>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ScrollArea>
          </div>
          {addDataSourceListing?.data?.count > 24 && (
            <CustomPagination page={page} setPage={setPage} totalItems={24} data={addDataSourceListing} />
          )}
        </div>
      )}
    </div>
  );
}
