import { cn } from '@shadcnUtils';
import { WidgetCommentBtn, WidgetMenu, WidgetTitle } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import zendashDashVideoPlaceholder from '@assets/images/zendashVideoPlaceholder.png';

export function VideoWidget({
  widget,
  onRemoveClick,
  handleVideoInputChange,
  isSharedDashboard,
  isEditMode,
  handleEditWidget,
  handleClone,
  isReadOnly,
  titleText,
  wrapperClass,
  headingText,
  inputText,
  showCommentBtn,
  handleEditCommentMode,
  widgetCommentClass,
  hideTooltip,
}) {
  return (
    <div className={cn('flex h-full flex-col gap-2', wrapperClass)}>
      <div className='flex h-6 items-center justify-between gap-3'>
        {widget?.showLabel && <WidgetTitle titleText={titleText} widget={widget} hideTooltip={hideTooltip} />}
        {!widget?.widgetData?.error && (
          <>
            {!isSharedDashboard && (
              <WidgetCommentBtn
                widget={widget}
                showCommentBtn={showCommentBtn}
                handleEditCommentMode={() => handleEditCommentMode(widget)}
                className={widgetCommentClass}
              />
            )}
          </>
        )}
        {!isSharedDashboard && isEditMode && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
          />
        )}
      </div>
      {widget?.widgetData?.error ? (
        <p
          className='text-md flex h-[calc(100%-3.4375rem)] w-full items-center justify-center font-bold'
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? {
                color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
              }
              : {
                color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
              }
          }
        >
          {widget?.widgetData?.error}
        </p>
      ) : (
        <>
          {!widget.url && (
            <>
              <h6
                className={cn('text-left', headingText)}
                style={
                  widget?.themeColor === DEFAULT_VALUE
                    ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                    : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
                }
              >
                Add Link
              </h6>
              <input
                type='text'
                placeholder='Paste your link here and press enter'
                onChange={e => handleVideoInputChange(e, widget)}
                className={cn(
                  `${isReadOnly ? 'pointer-events-none' : ''} rounded border border-darkPrimary px-3 py-2 text-xs leading-[0.9075rem] focus:outline-none focus:ring-0`,
                  inputText
                )}
              />
            </>
          )}
          {!!widget.url && !isSharedDashboard && (
            <div className='h-full'>
              <iframe
                width='100%'
                height='100%'
                src={widget.url}
                title='Zendash video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          )}
          {!!widget.url && isSharedDashboard && (
            <div className='h-full w-full'>
              <img src={zendashDashVideoPlaceholder} className='h-full w-full' />
            </div>
          )}
        </>
      )}
    </div>
  );
}
