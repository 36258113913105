import { useState } from 'react';
import { driver } from 'driver.js';
import {
  CREATE_TEAM_BUTTON_ID,
  CREATE_TEAM_NAME_ID,
  CREATE_TEAM_PERMISSION_ID,
  CREATE_TEAM_DASHBOARD_ID,
  CREATE_TEAM_MEMBER_ID,
  CREATE_TEAM_MODAL_BUTTON_ID,
  CREATE_DASHBOARD_BUTTON_ID,
  CREATE_DASHBOARD_LOGO_ID,
  CREATE_DASHBOARD_NAME_ID,
  CREATE_DASHBOARD_CURRENCY_ID,
  CREATE_DASHBOARD_STEP1_BUTTON,
  CREATE_DASHBOARD_DATA_SOURCE_ID,
  CREATE_DASHBOARD_TYPE_ID,
  CREATE_DASHBOARD_TYPE_CLASS,
  EDIT_DASHBOARD_BUTTON_ID,
  STATIC_WIDGETS_ID,
  STATIC_WIDGETS_TAB_ID,
  CREATE_TEAM_PERMISSION_DROPDOWN_ID,
  CREATE_TEAM_DASHBOARD_DROPDOWN_ID,
  CREATE_TEAM_MEMBER_DROPDOWN_ID,
  INVITE_MEMBER_BUTTON,
  INVITE_MEMBER_EMAIL,
  INVITE_MEMBER_ROLE,
  INVITE_MEMBER_ROLE_DROPDOWN,
  INVITE_MEMBER_SUBMIT,
  INVITE_MEMBER_TEAM,
  INVITE_MEMBER_TEAM_DROPDOWN,
  CREATE_DASHBOARD_CURRENCY_DROPDOWN,
  SAVE_BTN_ID,
} from '@constants';

export const useAppTours = () => {
  const [isDriverEnabled, setIsDriverEnabled] = useState(false);
  const [createDashboardStep1, setCreateDashboardStep1] = useState({
    logo: false,
    name: '',
    domain: '',
    currency: '',
    dataSource: '',
    dashboardType: '',
    template: '',
  });

  const [isOptionClicked, setIsOptionClicked] = useState(false);
  const [inviteMemberTourRole, setInviteMemberTourRole] = useState({ role: '' });

  const createOrgTour = sideBarId => {
    let timeout = null;
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: `#${sideBarId}`,
          popover: {
            title: 'Switch Organizations',
            description: 'Click here to switch between organizations and create new ones.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: '#create-org',
          popover: {
            title: 'Create Organization',
            description: 'Click here to create a new organization.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 1) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: '#logo',
          popover: {
            title: 'Upload organization logo',
            description: 'Upload organization logo, it is a mandatory field.',
            showButtons: ['close'],
          },
          onHighlighted: element => {
            element.addEventListener('change', e => {
              if (e.target.files && e.target.files.length > 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 500);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${SAVE_BTN_ID}`,
          popover: {
            title: 'Save Logo',
            description: 'Click here to save the logo.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 3) {
                timeout = setTimeout(() => driverObj.moveNext(), 3500);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: '#org-input-div',
          popover: {
            title: 'Enter Organization name here.',
            showButtons: ['next', 'close'],
          },
        },
        {
          element: '#create-org-button',
          popover: {
            title: 'Click here to create organization.',
            showButtons: ['next', 'close'],
          },
          onHighlighted: element => {
            element.addEventListener('click', () => {
              driverObj.destroy();
            });
          },
        },
      ],
      onCloseClick: () => driverObj.destroy(),
    });

    return driverObj;
  };

  const createHomeOrgTour = () => {
    let timeout = null;
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: '#create-home-org',
          popover: {
            title: 'Create Organization',
            description: 'Click here to create a new organization.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: '#logo',
          popover: {
            title: 'Upload organization logo',
            description: 'Upload organization logo, it is a mandatory field.',
            showButtons: ['close'],
          },
          onHighlighted: element => {
            element.addEventListener('change', e => {
              if (e.target.files && e.target.files.length > 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 500);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${SAVE_BTN_ID}`,
          popover: {
            title: 'Save Logo',
            description: 'Click here to save the logo.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 2) {
                timeout = setTimeout(() => driverObj.moveNext(), 3500);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: '#org-input-div',
          popover: {
            title: 'Enter Organization name here, it is a mandatory field.',
            showButtons: ['next', 'close'],
          },
        },
        {
          element: '#create-org-button',
          popover: {
            title: 'Click here to create organization.',
            showButtons: ['next', 'close'],
          },
          onHighlighted: element => {
            element.addEventListener('click', () => {
              driverObj.destroy();
            });
          },
        },
      ],
      onCloseClick: () => driverObj.destroy(),
    });

    return driverObj;
  };

  const createTeamTour = () => {
    let timeout = null;
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: `#${CREATE_TEAM_BUTTON_ID}`,
          popover: {
            title: 'Create Team Button',
            description: 'Click here to create a new team.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_NAME_ID}`,
          popover: {
            title: 'Enter Team name',
            description: 'Enter team name, it is a mandatory field.',
            showButtons: ['close', 'next'],
          },
        },
        {
          element: `#${CREATE_TEAM_DASHBOARD_ID}`,
          popover: {
            title: 'From here you can select the dashboards.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 2) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_DASHBOARD_DROPDOWN_ID}`,
          popover: {
            title: 'From here you can select the dashboards of the team.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('mousedown', () => {
              if (option?.state?.activeIndex === 3) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_PERMISSION_ID}`,
          popover: {
            title: 'From here you can select the permission of the team members.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 4) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_PERMISSION_DROPDOWN_ID}`,
          popover: {
            title: 'From here you can select the permission of the team members.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('mousedown', () => {
              if (option?.state?.activeIndex === 5) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_MEMBER_ID}`,
          popover: {
            title: 'From here you can select the members of the team.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 6) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_MEMBER_DROPDOWN_ID}`,
          popover: {
            title: 'From here you can select the members of the team.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('mousedown', () => {
              if (option?.state?.activeIndex === 7) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_TEAM_MODAL_BUTTON_ID}`,
          popover: {
            title: 'Click here to create team.',
            showButtons: ['close'],
          },
          onHighlightStarted: () => {
            let teamDialog = document.getElementById('scrollTeamDialog');
            teamDialog.scrollTop = teamDialog.scrollHeight;
          },
          onHighlighted: element => {
            element.addEventListener('click', () => {
              driverObj.destroy();
              setIsDriverEnabled(false);
            });
          },
        },
      ],
      onDestroyStarted: () => {
        setIsDriverEnabled(false);
        driverObj.destroy();
      },
      onCloseClick: () => {
        driverObj.destroy();
        setIsDriverEnabled(false);
      },
    });

    return driverObj;
  };

  const createDashboardTour = () => {
    let timeout = null;
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: `#${CREATE_DASHBOARD_BUTTON_ID}`,
          popover: {
            title: 'Create Dashboard Button',
            description: 'Click here to start creating new dashboard.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_DASHBOARD_LOGO_ID}`,
          popover: {
            title: 'Upload Dashboard Logo',
            description: 'Upload Dashboard logo, it is a mandatory field.',
            showButtons: ['close'],
          },
          onHighlighted: element => {
            element.addEventListener('change', e => {
              if (e.target.files && e.target.files.length > 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 500);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${SAVE_BTN_ID}`,
          popover: {
            title: 'Save Logo',
            description: 'Click here to save the logo.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 2) {
                timeout = setTimeout(() => driverObj.moveNext(), 3500);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_DASHBOARD_NAME_ID}`,
          popover: {
            title: 'Dashboard name',
            description: 'Enter dashboard name, it is mandatory field',
            showButtons: ['next', 'close'],
            onNextClick: () => {
              setCreateDashboardStep1(prev => {
                if (prev.name.trim() !== '') {
                  driverObj.moveNext();
                  return prev;
                }
                return prev;
              });
            },
          },
        },
        // TODO: We should need this code when we implement the Ahref and semRush
        // {
        //   element: `#${CREATE_DASHBOARD_DOMAIN_ID}`,
        //   popover: {
        //     title: 'Dashboard domain',
        //     description: 'Enter dashboard domain, it is mandatory field.',
        //     showButtons: ['next', 'close'],
        //     onNextClick: () => {
        //       setCreateDashboardStep1(prev => {
        //         if (prev.domain.trim() !== '') {
        //           driverObj.moveNext();
        //           return prev;
        //         }
        //         return prev;
        //       });
        //     },
        //   },
        // },
        {
          element: `#${CREATE_DASHBOARD_CURRENCY_ID}`,
          popover: {
            title: 'Select Currency',
            description: 'From here you will select the currency.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 4) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_DASHBOARD_CURRENCY_DROPDOWN}`,
          popover: {
            title: 'Select Currency',
            description: 'Select the currency for your dashboard from here',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('mousedown', () => {
              if (option?.state?.activeIndex === 5) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_DASHBOARD_STEP1_BUTTON}`,
          popover: {
            title: 'Move to the next step',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 6) {
                timeout = setTimeout(() => driverObj.moveNext(), 1000);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_DASHBOARD_DATA_SOURCE_ID}`,
          popover: {
            title: 'Add your data source in the dashboard',
            showButtons: ['next', 'close'],
            onNextClick: () => {
              setCreateDashboardStep1(prev => {
                if (prev.dataSource) {
                  driverObj.moveNext();
                  return prev;
                }
                return prev;
              });
            },
          },
        },
        {
          element: `#${CREATE_DASHBOARD_STEP1_BUTTON}`,
          popover: {
            title: 'Move to the next step',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 8) {
                timeout = setTimeout(() => driverObj.moveNext(), 1000);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${CREATE_DASHBOARD_TYPE_ID}`,
          popover: {
            title: 'Select Dashboard type',
            showButtons: ['next', 'close'],
            onNextClick: () => {
              setCreateDashboardStep1(prev => {
                // if (prev.dashboardType) {
                //   driverObj.moveNext();
                //   return prev;
                // }
                driverObj.moveNext();
                return prev;
              });
            },
          },
        },
        {
          element: `#${CREATE_DASHBOARD_STEP1_BUTTON}`,
          popover: {
            title: 'Move to the next step',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 10) {
                setCreateDashboardStep1(prev => {
                  // if (prev.dashboardType === 'custom') {
                  //   driverObj.destroy();
                  //   return prev;
                  // }
                  // else {
                  //   timeout = setTimeout(() => driverObj.moveNext(), 1000);
                  //   return prev;
                  // }
                  timeout = setTimeout(() => driverObj.moveNext(), 1000);
                  return prev;
                });
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `.${CREATE_DASHBOARD_TYPE_CLASS}`,
          popover: {
            title: 'Select one option',
            showButtons: ['next', 'close'],
            onNextClick: () => {
              setCreateDashboardStep1(prev => {
                // if (prev.template) {
                //   driverObj.moveNext();
                //   return prev;
                // }
                driverObj.moveNext();
                return prev;
              });
            },
          },
        },
        {
          element: `#${CREATE_DASHBOARD_STEP1_BUTTON}`,
          popover: {
            title: 'Create Dashboard.',
            showButtons: ['close'],
          },
          onHighlighted: element => {
            element.addEventListener('click', () => {
              driverObj.destroy();
            });
          },
        },
      ],
      onDestroyStarted: () => {
        setIsDriverEnabled(false);
        setCreateDashboardStep1({
          logo: false,
          name: '',
          domain: '',
          currency: '',
          dataSource: '',
          dashboardType: '',
          template: '',
        });
        driverObj.destroy();
      },
      onCloseClick: () => {
        driverObj.destroy();
        setIsDriverEnabled(false);
        setCreateDashboardStep1({
          logo: false,
          name: '',
          domain: '',
          currency: '',
          dataSource: '',
          dashboardType: '',
          template: '',
        });
      },
    });

    return driverObj;
  };

  const addWidgetTour = () => {
    let timeout = null;
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: `#${EDIT_DASHBOARD_BUTTON_ID}`,
          popover: {
            title: 'Edit Dashboard',
            description: 'Click here to open the widgets tab.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${STATIC_WIDGETS_TAB_ID}`,
          popover: {
            title: 'Static Widget Tab',
            description: 'Click here.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 1) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${STATIC_WIDGETS_ID}`,
          popover: {
            title: 'Static Widgets',
            description: 'Drag a widget from here to the left side to create an widget.',
            showButtons: ['close'],
          },
          onHighlighted: element => {
            element.addEventListener('mousedown', () => {
              driverObj.destroy();
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
      ],
      onCloseClick: () => driverObj.destroy(),
    });

    return driverObj;
  };

  const inviteMemberTour = () => {
    let timeout = null;
    const driverObj = driver({
      showProgress: true,

      steps: [
        {
          element: `#${INVITE_MEMBER_BUTTON}`,
          popover: {
            title: 'Invite Members Button',
            description: 'Click here to invite a member.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 0) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${INVITE_MEMBER_ROLE}`,
          popover: {
            title: 'Select the role of the member.',
            description: 'Member can be admin or member of the team.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('click', () => {
              if (option?.state?.activeIndex === 1) {
                timeout = setTimeout(() => driverObj.moveNext(), 300);
              }
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${INVITE_MEMBER_ROLE_DROPDOWN}`,
          popover: {
            title: 'From here you can select the role of the member in the team.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            element.addEventListener('mousedown', e => {
              timeout = setTimeout(() => {
                if (option?.state?.activeIndex === 2) {
                  setInviteMemberTourRole(prev => {
                    if (e.target.outerText === 'Admin') {
                      timeout = setTimeout(() => driverObj.moveTo(5), 300);
                      return prev;
                    } else {
                      timeout = setTimeout(() => driverObj.moveNext(), 300);
                      return prev;
                    }
                  });
                }
              }, 500);
            });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${INVITE_MEMBER_TEAM}`,
          popover: {
            title: 'Select the team of the member.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            if (element)
              element.addEventListener('click', () => {
                if (option?.state?.activeIndex === 3) {
                  timeout = setTimeout(() => driverObj.moveNext(), 300);
                }
              });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${INVITE_MEMBER_TEAM_DROPDOWN}`,
          popover: {
            title: 'From here you can select the team of the member.',
            showButtons: ['close'],
          },
          onHighlighted: (element, step, option) => {
            if (element)
              element.addEventListener('mousedown', () => {
                if (option?.state?.activeIndex === 4) {
                  timeout = setTimeout(() => driverObj.moveNext(), 300);
                }
              });
          },
          onDeselected: () => {
            clearTimeout(timeout);
            timeout = null;
          },
        },
        {
          element: `#${INVITE_MEMBER_EMAIL}`,
          popover: {
            title: 'Enter the email of the member.',
            description:
              'Input the valid email of the member, after writing the email do not forget to press enter button.',
            showButtons: ['next', 'close'],
          },
        },
        {
          element: `#${INVITE_MEMBER_SUBMIT}`,
          popover: {
            title: 'Click here to invite.',
            showButtons: ['close', 'previous'],
          },
          onHighlightStarted: () => {
            let teamDialog = document.getElementById('inviteScrollDialog');
            teamDialog.scrollTop = teamDialog.scrollHeight;
          },
          onHighlighted: element => {
            element.addEventListener('click', () => {
              driverObj.destroy();
              setIsDriverEnabled(false);
            });
          },
        },
      ],
      onDestroyStarted: () => {
        setIsDriverEnabled(false);
        driverObj.destroy();
        setInviteMemberTourRole({
          role: '',
        });
      },
      onCloseClick: () => {
        driverObj.destroy();
        setIsDriverEnabled(false);
        setInviteMemberTourRole({
          role: '',
        });
      },
    });

    return driverObj;
  };

  return {
    createOrgTour,
    createHomeOrgTour,
    createTeamTour,
    addWidgetTour,
    isDriverEnabled,
    createDashboardTour,
    setCreateDashboardStep1,
    createDashboardStep1,
    setIsOptionClicked,
    isOptionClicked,
    inviteMemberTour,
    setInviteMemberTourRole,
    inviteMemberTourRole,
  };
};
