export const ROUTES = {
  LAYOUT: '/',
  SIGN_IN: '/sign-in',
  OTP_VERIFICATION: '/otp-verification',
  ON_BOARDING: '/onboarding',
  ORGANIZATIONS_INVITATIONS: '/organizations/invitations/:uuid',
  DASHBOARD: '/dashboards/:organizationId/:page?',
  CUSTOM_DASHBOARD: '/dashboards/:organizationId/dashboard/:dashboardId',
  MANAGE_ORGANIZATION: '/manage-organization/:organizationId',
  DATA_SOURCE: '/data-sources/:organizationId',
  PROFILE: '/profile',
  PROFILE_TAB: 'profile-tab',
  MEMBERSHIPS_TAB: 'memberships-tab',
  AI_TOKENS_TAB: 'aitokens-tab',
  BILLING_TAB: 'billing-tab',
  DOMAIN_TAB: 'domain-tab',
  GOAL_TRACKING: '/goal-tracking/:organizationId',
  GOAL_TRACKING_TABULAR_VIEW: 'goal-tracking-tabular-view',
  GOAL_TRACKING_GRID_VIEW: 'goal-tracking-grid-view',
  ADD_DATA_SOURCE: '/data-sources/:organizationId/add-data-source/:page?',
  HOME: '/home',
  MY_ORGANIZATIONS: 'my-organizations',
  INVITED_ORGANIZATIONS: 'invited-organizations',
  REPORTS: '/dashboards/:organizationId/dashboard/:dashboardId/reports',
  CREATE_REPORT: 'create-report',
  SCHEDULED_REPORTS: 'scheduled-reports',
  ACCESS_DENIED: '/access-denied',
  WHITE_LABEL: '/white-label/:organizationId',
  SUBSCRIPTION_EXPIRED: '/subscription-expired',
  SHARED_DASHBOARD: '/dashboards/shared/:uuid',
  OAUTH_REDIRECTION: '/oauth/:connectorName/redirect',
  SHOPIFY_INSTALL: '/oauth/shopify/install',
  DASHBOARD_FULL_VIEW: '/dashboards/:organizationId/dashboard/:dashboardId/full-view/:selectSliderWidget',
  PRIVACY_POLICY: 'core/privacy-policy/',
  TERMS_AND_CONDITIONS: 'core/terms-and-conditions/',
  MEMBERSHIPS: '/memberships',
};
