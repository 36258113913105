import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';

export function useChatbot() {
  const { organizationId, dashboardId } = useParams();
  const chatbotRef = useRef();

  const [message, setMessage] = useState('');
  const [showChatbotPopup, setShowChatbotPopup] = useState(false);
  const [chatbotMessages, setChatbotMessages] = useState({});

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatbotRef.current)
        chatbotRef?.current.scrollTo({
          top: chatbotRef?.current?.scrollHeight,
          behavior: 'smooth',
        });
    }, 50);
  };

  const { refetch: chatbotRefetch } = useGetQuery(
    'chatbot',
    apiEndpoints.DASHBOARD_CHATBOT(organizationId, dashboardId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: showChatbotPopup,
      onSuccess: resp => {
        setChatbotMessages(resp);
        scrollToBottom();
      },
    }
  );

  const handleChatMessage = () => {
    if (message.length > 0) {
      setChatbotMessages({
        ...chatbotMessages,
        data: {
          ...chatbotMessages?.data,
          history: isEmpty(chatbotMessages.data.history)
            ? [{ human: message }]
            : [...chatbotMessages.data.history, { human: message }],
        },
      });
      setMessage('');
      scrollToBottom();
      chatMessage({ payload: { question: message } });
    }
  };

  const { mutate: chatMessage, isLoading: chatMessageLoading } = usePostMutation(
    apiEndpoints.DASHBOARD_CHATBOT(organizationId, dashboardId),
    () => chatbotRefetch(),
    ({ response: { data } }) => toast.error(data?.detail)
  );

  return {
    handleChatMessage,
    message,
    setMessage,
    chatbotRef,
    setShowChatbotPopup,
    chatbotMessages,
    chatMessageLoading,
  };
}
