export function ZendashLogo({ fillColor = '#1C1C23', className }) {
  return (
    <svg
      width='127'
      height='24'
      viewBox='0 0 127 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
    >
      <path d='M67.4961 2.79492H64.9258V14.4906H67.4961V2.79492Z' fill='url(#paint0_linear_4892_74753)' />
      <path d='M63.084 5.59375H60.5137V17.2895H63.084V5.59375Z' fill='url(#paint1_linear_4892_74753)' />
      <path
        d='M0 8.52878H11.1935L0 21.32V23.7247H14.4925V21.32H3.26454L14.4925 8.52878V6.15625H0V8.52878Z'
        fill={fillColor}
      />
      <path
        d='M98.5623 10.7694C98.5623 9.36057 99.8187 8.3354 101.921 8.3354C104.216 8.3354 105.533 9.6154 105.651 11.3787H108.519C108.327 7.94877 105.817 5.85742 102.016 5.85742C98.2152 5.85742 95.7051 8.06886 95.7051 10.7607C95.7051 17.3012 105.877 15.0254 105.877 18.9356C105.877 20.3797 104.621 21.5337 102.363 21.5337C99.945 21.5337 98.4676 20.2508 98.3127 18.5519H95.3608C95.5473 21.7563 98.3729 24 102.392 24C106.193 24 108.674 21.8208 108.674 18.9356C108.545 12.6616 98.5623 14.9375 98.5623 10.7694Z'
        fill={fillColor}
      />
      <path
        d='M92.2882 14.9375V6.13282H89.5946V8.54929C88.3712 7.36453 86.8345 6.57197 85.1746 6.26964C83.5147 5.96732 81.8045 6.16849 80.2554 6.84828C78.7063 7.52807 77.3863 8.65664 76.4589 10.0944C75.5314 11.5321 75.0371 13.216 75.0371 14.9375C75.0371 16.6591 75.5314 18.343 76.4589 19.7807C77.3863 21.2185 78.7063 22.347 80.2554 23.0268C81.8045 23.7066 83.5147 23.9078 85.1746 23.6055C86.8345 23.3031 88.3712 22.5106 89.5946 21.3258V23.7423H92.2882V14.9375ZM83.665 20.9948C82.4916 20.9954 81.3443 20.6406 80.3684 19.9754C79.3924 19.3102 78.6316 18.3644 78.1822 17.2576C77.7327 16.1508 77.6148 14.9328 77.8434 13.7576C78.072 12.5824 78.6368 11.5029 79.4663 10.6554C80.2959 9.80802 81.3529 9.23083 82.5038 8.99687C83.6546 8.76291 84.8476 8.8827 85.9317 9.34107C87.0159 9.79945 87.9426 10.5758 88.5946 11.572C89.2466 12.5682 89.5946 13.7394 89.5946 14.9375C89.5946 16.5435 88.9699 18.0838 87.858 19.2197C86.7461 20.3555 85.2379 20.9941 83.665 20.9948Z'
        fill={fillColor}
      />
      <path
        d='M44.6094 5.83398C42.2857 5.83398 40.2146 6.82693 39.0814 8.65466V6.13275H36.3906V23.7422H39.0814V14.009C39.0814 10.2246 41.0895 8.36762 44.0127 8.36762C46.9358 8.36762 48.8148 10.1924 48.8148 13.7834V23.7246H51.6347V13.3675C51.6347 8.33247 48.5968 5.83398 44.6094 5.83398Z'
        fill={fillColor}
      />
      <path
        d='M119.966 5.83467C117.643 5.83467 115.571 6.82762 114.441 8.65535V0H111.748V23.7429H114.441V14.0097C114.441 10.2253 116.449 8.36831 119.37 8.36831C122.29 8.36831 124.172 10.1931 124.172 13.7841V23.7253H127V13.3682C127 8.33316 123.954 5.83467 119.966 5.83467Z'
        fill={fillColor}
      />
      <path
        d='M24.6938 6.13286C23.1188 6.13144 21.5735 6.57049 20.2256 7.40237C18.8776 8.23425 17.7786 9.42718 17.0477 10.8517C16.3168 12.2762 15.982 13.8779 16.0796 15.483C16.1771 17.0881 16.7034 18.6352 17.6012 19.9566C18.499 21.2779 19.7341 22.3229 21.1724 22.9782C22.6107 23.6335 24.1973 23.874 25.7601 23.6738C27.3228 23.4735 28.802 22.84 30.0371 21.8421C31.2722 20.8442 32.2161 19.52 32.7662 18.0131H29.7771C29.1609 19.0888 28.2331 19.9428 27.1216 20.4574C26.0101 20.972 24.7696 21.1219 23.5711 20.8864C22.3726 20.6508 21.2753 20.0414 20.4303 19.1423C19.5853 18.2431 19.0343 17.0985 18.8532 15.8661H33.2769C33.4072 14.6372 33.2822 13.394 32.9099 12.2178C32.5376 11.0416 31.9265 9.95886 31.1164 9.04026C30.3064 8.12166 29.3156 7.38789 28.2089 6.8869C27.1022 6.38591 25.9044 6.12897 24.6938 6.13286ZM18.9909 13.3647C19.3241 12.0795 20.0642 10.943 21.096 10.1321C22.1278 9.32122 23.3932 8.88153 24.6952 8.88153C25.9973 8.88153 27.2627 9.32122 28.2945 10.1321C29.3262 10.943 30.0664 12.0795 30.3996 13.3647H18.9909Z'
        fill={fillColor}
      />
      <path
        d='M69.2728 8.55869V14.9382C69.2735 15.9077 69.0463 16.8633 68.6102 17.7245C68.1742 18.5858 67.542 19.3276 66.7669 19.8876C65.9918 20.4476 65.0963 20.8094 64.1558 20.9427C63.2153 21.0759 62.2571 20.9767 61.362 20.6534C60.4668 20.3301 59.6607 19.7921 59.0114 19.0846C58.3622 18.3771 57.8887 17.5208 57.6309 16.5877C57.373 15.6546 57.3383 14.672 57.5296 13.7223C57.721 12.7727 58.1328 11.8838 58.7304 11.1304V7.52181C57.7272 8.18364 56.87 9.05143 56.2131 10.07C55.5563 11.0885 55.1143 12.2355 54.9153 13.438C54.7162 14.6404 54.7645 15.872 55.057 17.0544C55.3495 18.2367 55.8798 19.344 56.6143 20.3057C57.3487 21.2674 58.2711 22.0625 59.3229 22.6403C60.3747 23.2182 61.5327 23.5662 62.7234 23.6622C63.914 23.7583 65.1112 23.6002 66.2388 23.198C67.3664 22.7959 68.3996 22.1585 69.2728 21.3264V23.7429H71.9664V0H69.2728V8.55869Z'
        fill={fillColor}
      />
      <defs>
        <linearGradient
          id='paint0_linear_4892_74753'
          x1='67.6166'
          y1='5.021'
          x2='62.3713'
          y2='17.9745'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4892_74753'
          x1='64.7421'
          y1='3.86268'
          x2='59.4968'
          y2='16.8162'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8AC33D' />
          <stop offset='1' stopColor='#00ACE7' />
        </linearGradient>
      </defs>
    </svg>
  );
}
