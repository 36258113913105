import { Checkbox } from '@shadcnComponent';

import { isEmpty } from 'lodash';
import { CREATE_DASHBOARD_DATA_SOURCE_ID } from '@constants';

export function CreateDashboardStep2({
  addDataSourceListing,
  handleCheck,
  addedDataSource,
  selectedDataSourceIds,
  setCreateDashboardStep1,
  showTour = true,
}) {
  return (
    <div id='step-2' className='mt-4 md:mt-6 lg:mt-4 xxl:mt-6'>
      <>
        <div className='mb-8 flex flex-col gap-2 md:gap-1.5 lg:gap-1 xxl:gap-1.5'>
          <h2 className='text-sm font-semibold leading-[1.0625rem] text-midNightBlue md:text-lg md:leading-7 lg:text-xs lg:leading-[.9375rem] xxl:text-lg'>
            Add Data Sources
          </h2>
        </div>
      </>
      <div
        id={CREATE_DASHBOARD_DATA_SOURCE_ID}
        className='mt-4 flex gap-5 md:mt-6 md:gap-9 lg:mt-3 lg:gap-5 xxl:mt-6 xxl:gap-8'
      >
        <div className='w-[52%] max-w-[10.875rem] border-r md:max-w-[19.75rem] lg:max-w-[17.5625rem] xxl:max-w-[24.5625rem]'>
          <h3 className='pb-3.5 text-sm font-medium leading-[1.0625rem] md:pb-4 md:text-base md:leading-5 lg:pb-3 lg:text-xs lg:leading-[.9375rem] xxl:pb-4 xxl:text-base xxl:leading-5'>
            Available Data Sources
          </h3>
          <div className='block h-60 overflow-y-auto pr-[1.1875rem] md:h-[24.8125rem] md:pr-[2.1875rem] lg:h-[15.625rem] lg:pr-6 xxl:h-[26rem] xxl:pr-8'>
            <div className='flex flex-col gap-2 md:gap-4 lg:gap-2 xxl:gap-4'>
              {addDataSourceListing?.data?.results?.map(dataSource => (
                <div
                  key={dataSource?.id}
                  className={`relative flex min-h-[3.375rem] rounded border border-lightGray pb-2 pl-2 pr-[.3125rem] pt-[.3125rem] md:min-h-[3.25rem] md:w-[17.5rem] md:items-center md:justify-between md:rounded-lg md:px-4 md:py-3.5 lg:min-h-8 lg:w-[16rem] lg:rounded lg:px-2.5 lg:py-[.4375rem] xxl:min-h-[3.25rem] xxl:w-[22.5rem] xxl:gap-2 xxl:rounded-lg xxl:px-4 xxl:py-3 ${selectedDataSourceIds.includes(dataSource?.id) ? 'border-darkPrimary' : ''}`}
                >
                  <div className='flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:gap-1.5'>
                    <div className='h-[1.375rem] w-[1.375rem] md:h-6 md:w-6 lg:h-[1.125rem] lg:w-[1.125rem] xxl:h-7 xxl:w-7'>
                      <img src={dataSource?.connector?.logo?.image} className='h-full w-full' alt='dataSourceIcon' />
                    </div>
                    <label
                      htmlFor={`dataSource-${dataSource?.id}`}
                      className='break-all text-[.625rem] font-normal leading-3 text-midNightBlue md:text-sm md:leading-[1.0625rem] lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-4'
                    >
                      {dataSource?.account}
                    </label>
                  </div>
                  <Checkbox
                    id={`dataSource-${dataSource?.id}`}
                    className='absolute right-[0.3125rem] top-[0.3125rem] h-3 w-3 md:static md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4'
                    checked={selectedDataSourceIds.includes(dataSource?.id)}
                    onCheckedChange={() => {
                      handleCheck(dataSource?.id);
                      showTour &&
                        setCreateDashboardStep1(prev => {
                          return {
                            ...prev,
                            dataSource: dataSource?.id,
                          };
                        });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {!isEmpty(addedDataSource) && (
          <div className='w-[46%] max-w-[178px] md:max-w-[20.625rem] lg:max-w-[17.5rem] xxl:max-w-[24.5rem]'>
            <h3 className='pb-3.5 text-sm font-medium leading-[1.0625rem] md:pb-4 md:text-base md:leading-5 lg:pb-3 lg:text-xs lg:leading-[.9375rem] xxl:pb-4 xxl:text-base xxl:leading-5'>
              Added Data Sources
            </h3>
            <div className='h-60 overflow-y-auto md:h-[24.8125rem] lg:h-[15.625rem] lg:pr-0 xxl:h-[26rem]'>
              <div className='flex flex-col gap-2 md:gap-4 lg:gap-2 xxl:gap-4'>
                {addedDataSource?.map(dataSource => (
                  <div
                    key={dataSource?.id}
                    className='flex min-h-[3.375rem] flex-col gap-1 rounded border border-lightGray p-2 pt-[.3125rem] md:min-h-[3.25rem] md:w-[17.5rem] md:flex-row md:gap-2 md:rounded-lg md:px-4 md:py-3.5 lg:min-h-8 lg:w-[16rem] lg:gap-1.5 lg:rounded lg:px-2.5 lg:py-[.4375rem] xxl:min-h-[3.25rem] xxl:w-[22.5rem] xxl:gap-2 xxl:rounded-lg xxl:px-4 xxl:py-3'
                  >
                    <div className='h-[1.375rem] w-[1.375rem] md:h-6 md:w-6 lg:h-[1.125rem] lg:w-[1.125rem] xxl:h-7 xxl:w-7'>
                      <img src={dataSource?.connector?.logo?.image} className='h-full w-full' alt='dataSourceLogo' />
                    </div>
                    <label
                      htmlFor={`dataSource-${dataSource?.id}`}
                      className='break-all text-[.625rem] font-normal leading-3 md:text-sm md:leading-[1.0625rem] lg:text-[.625rem] lg:leading-3 xxl:text-sm xxl:leading-4'
                    >
                      {dataSource?.account}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
