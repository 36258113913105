import { cn } from '@shadcnUtils';
import { GoalTrackingBtn, WidgetCommentBtn, WidgetMenu, WidgetTitle } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, ROLES, THEME_STYLE, WIDGETS_TYPE } from '@constants';

export function WidgetTopHead({
  widget,
  onRemoveClick,
  dashboardAccess,
  handleEditWidget,
  handleClone,
  className,
  titleText,
  widgetWrapper,
  widgetImage,
  accountName,
  handleEditCommentMode,
  showCommentBtn,
  isSharedDashboard,
  widgetCommentClass,
  hideTooltip,
  showGoalDialog,
  setShowGoalDialog,
  userOrganizationRole,
}) {
  return (
    <div className={cn('mb-4 flex flex-col gap-1', className)}>
      <div className='flex h-6 items-center justify-between gap-3'>
        {widget?.showLabel && <WidgetTitle titleText={titleText} widget={widget} hideTooltip={hideTooltip} />}
        <div className='flex items-center gap-1'>
          {!widget?.widgetData?.error && (
            <>
              {!isSharedDashboard && (
                <WidgetCommentBtn
                  widget={widget}
                  showCommentBtn={showCommentBtn}
                  handleEditCommentMode={() => handleEditCommentMode(widget)}
                  className={widgetCommentClass}
                />
              )}
            </>
          )}
          {!widget?.widgetData?.error &&
            !isSharedDashboard &&
            widget?.id?.split('-')[0] === WIDGETS_TYPE.COUNTER &&
            userOrganizationRole !== ROLES.MEMBER && (
            <GoalTrackingBtn
              widget={widget}
              showBtn={showCommentBtn}
              handleGoalTrackingMode={() => {
                setShowGoalDialog({ ...showGoalDialog, [widget?.i]: true });
              }}
              className={widgetCommentClass}
            />
          )}
        </div>
        {dashboardAccess && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
            handleEditCommentMode={handleEditCommentMode}
          />
        )}
      </div>
      <div className={cn('flex items-center gap-2', widgetWrapper)}>
        {widget?.showIntegrationIcon && (
          <span className={cn('h-6 w-6', widgetImage)}>
            <img src={widget?.logo} alt='dataSource' className='h-full w-full' />
          </span>
        )}
        <p
          className={cn('text-sm leading-[0.9075rem] text-primary', accountName)}
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
              : { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
          }
        >
          {widget?.accountName}
        </p>
      </div>
    </div>
  );
}
