import { useContext } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Dialog, DialogTrigger, Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcnComponent';
import { AddEditOrgDialog, DeleteDialog, BaseSkeltonLoader, BaseToolTip, BaseLoader } from '@common/components';
import { InvitesTab, MembersTab, TeamsTab, useManageOrganization } from '@pages/manageOrganization';
import { ROUTES } from '@routes';
import { AppContext } from '@useContext';
import { truncateString, useAppTours } from '@utils';
import { INVITE_MEMBER_TAB, MANAGE_ORGANIZATION_TABS, ROLES, SKELETON_TYPE, TEAMS_TAB_TOUR_ID } from '@constants';
import { EditIconWhite, OrganizationIcon } from '@assets/svgs';

export function ManageOrganization() {
  const {
    manageOrganization,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    handleDeleteBtn,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    showDeleteOrganizationDialog,
    setShowDeleteOrganizationDialog,
    isUploadedLogo,
    manageOrganizationFetching,
    openEditDialogue,
    isEditOrganizationLoading,
    isDeleteOrgLoading,
    handleDeleteOrganizationBtn,
    userDetail,
    handleImageUpload,
  } = useManageOrganization();

  const { isDriverEnabled, setIsOptionClicked, setInviteMemberTourRole } = useAppTours();

  const { isResponsive } = useContext(AppContext);

  const { organizationId } = useParams();
  const defaultOrganizationId = userDetail?.data?.defaultOrganization;

  return manageOrganizationFetching ? (
    <BaseLoader />
  ) : (
    <div className='mx-auto max-w-[30rem] pt-[1.6875rem] md:max-w-[48rem] md:pt-[1.9375rem] lg:max-w-full lg:pt-3 xxl:pt-4'>
      <h1 className='mb-6 ml-5 text-2xl font-bold leading-[1.875rem] md:mb-10 md:ml-8 md:text-[2rem] md:leading-6 lg:hidden'>
        Manage Organization
      </h1>
      <div className='flex flex-col items-start justify-between gap-6 border-b border-lightGray px-5 pb-6 md:flex-row md:items-center md:px-8 md:pb-6 lg:px-8 lg:pb-3 xxl:px-10 xxl:pb-4'>
        <div className='flex items-center gap-3 xxl:gap-4'>
          <div className='relative h-[3.125rem] w-[3.125rem] overflow-hidden rounded-full md:h-[3.75rem] md:w-[3.75rem] lg:h-10 lg:w-10 xxl:h-12 xxl:w-12'>
            {manageOrganizationFetching ? (
              <BaseSkeltonLoader type={SKELETON_TYPE.IMAGE_UPLOADER} />
            ) : manageOrganization?.data?.logo?.image ? (
              <img src={manageOrganization?.data?.logo?.image} alt='organization-logo' className='h-full w-full' />
            ) : (
              <OrganizationIcon className='h-full w-full bg-white p-1' />
            )}
          </div>
          <div>
            <div>
              {manageOrganizationFetching ? (
                <BaseSkeltonLoader type={SKELETON_TYPE.SINGLE_LINE} width={100} height={14} />
              ) : (
                <h5
                  className='text-xl font-semibold leading-6 md:text-2xl md:leading-[1.875rem] lg:text-sm lg:leading-[1.0625rem] xxl:text-lg xxl:leading-6'
                  id='organizationName'
                >
                  {truncateString(manageOrganization?.data?.name, isResponsive ? 15 : 60, 'text-darkSecondary')}
                </h5>
              )}
              {isResponsive && manageOrganization?.data?.name.length > 15 && (
                <BaseToolTip
                  className='!w-[7.5rem] xxl:!w-[11.25rem]'
                  id='organizationName'
                  content={manageOrganization?.data?.name}
                />
              )}
            </div>
            {!manageOrganizationFetching && manageOrganization?.data?.userRole !== ROLES.MEMBER && (
              <Dialog
                open={showAddEditOrgDialog}
                onOpenChange={status => {
                  setShowAddEditOrgDialog(status);
                  setIsUploadedLogo(false);
                }}
              >
                <DialogTrigger
                  onClick={openEditDialogue}
                  className='mt-2 text-base font-normal leading-5 text-darkPrimary lg:mt-1 lg:text-xs lg:leading-[.9375rem] xxl:mt-0.5 xxl:text-sm xxl:leading-[1.0588rem]'
                >
                  Edit
                </DialogTrigger>
                <AddEditOrgDialog
                  dialogTitle='Edit Organization'
                  formik={formik}
                  imageRef={imageRef}
                  uploadedLogo={uploadedLogo}
                  handleImageUpload={handleImageUpload}
                  setUploadedLogo={setUploadedLogo}
                  setIsUploadedLogo={setIsUploadedLogo}
                  icon={
                    <div className='h-3.5 w-3.5 lg:h-2.5 lg:w-2.5 xxl:h-3.5 xxl:w-3.5'>
                      <EditIconWhite />
                    </div>
                  }
                  handleDeleteBtn={handleDeleteBtn}
                  isUploadedLogo={isUploadedLogo}
                  organizationLoading={isEditOrganizationLoading}
                  organizationId={organizationId}
                  defaultOrganizationId={defaultOrganizationId}
                />
              </Dialog>
            )}
          </div>
        </div>
        {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
          <Link
            className='flex h-12 w-full items-center justify-center rounded bg-limeGreen text-sm font-bold leading-[1.0588rem] text-white md:h-10 md:w-[10.6875rem] md:rounded-md md:leading-6 lg:h-8 lg:w-[8.0625rem] lg:rounded lg:text-xs lg:leading-[0.9075rem] xxl:h-10 xxl:w-[8.5rem] xxl:rounded-md xxl:text-xs xxl:leading-6'
            to={generatePath(ROUTES.WHITE_LABEL, { organizationId })}
          >
            White Label
          </Link>
        )}
      </div>
      <div className='px-5 pt-6 md:px-8 md:pb-6 md:pt-6 lg:pt-4 xxl:px-10 xxl:pb-10'>
        <Tabs
          defaultValue={
            manageOrganization?.data?.userRole === ROLES.MEMBER
              ? MANAGE_ORGANIZATION_TABS.TEAMS
              : MANAGE_ORGANIZATION_TABS.MEMBERS
          }
        >
          <TabsList
            className={`h-12 w-full md:h-[2.625rem] lg:h-[2rem] xxl:h-[2.625rem] xxl:max-w-[23.125rem] ${manageOrganization?.data?.userRole === ROLES.MEMBER ? 'w-20' : 'md:w-[24.375rem] lg:w-[14rem] xxl:w-[23.125rem]'}`}
          >
            {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
              <TabsTrigger
                id={INVITE_MEMBER_TAB}
                value={MANAGE_ORGANIZATION_TABS.MEMBERS}
                className='h-full max-w-[7.9375rem] lg:w-[4.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[7.5rem]'
              >
                Members
              </TabsTrigger>
            )}
            <TabsTrigger
              id={TEAMS_TAB_TOUR_ID}
              value={MANAGE_ORGANIZATION_TABS.TEAMS}
              className='h-full max-w-[7.9375rem] lg:w-[4.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[7.5rem]'
            >
              Teams
            </TabsTrigger>
            {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
              <TabsTrigger
                value={MANAGE_ORGANIZATION_TABS.INVITES}
                className='h-full max-w-[7.9375rem] lg:w-[4.5rem] lg:text-[.625rem] lg:leading-3 xxl:w-[7.5rem]'
              >
                All Invites
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent value={MANAGE_ORGANIZATION_TABS.MEMBERS}>
            <MembersTab setInviteMemberTourRole={setInviteMemberTourRole} />
          </TabsContent>
          <TabsContent value={MANAGE_ORGANIZATION_TABS.TEAMS}>
            <TeamsTab
              setIsOptionClicked={setIsOptionClicked}
              isDriverEnabled={isDriverEnabled}
              manageOrganization={manageOrganization}
            />
          </TabsContent>
          <TabsContent value={MANAGE_ORGANIZATION_TABS.INVITES}>
            <InvitesTab />
          </TabsContent>
        </Tabs>
      </div>
      <Dialog open={showDeleteOrganizationDialog} onOpenChange={setShowDeleteOrganizationDialog}>
        <DeleteDialog
          heading={manageOrganization?.data?.name}
          text={manageOrganization?.data?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={handleDeleteOrganizationBtn}
          isLoading={isDeleteOrgLoading}
        />
      </Dialog>
    </div>
  );
}
