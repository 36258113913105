import { useState, useEffect } from 'react';
import {
  Dialog,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ScrollArea,
  Avatar,
  AvatarImage,
  Button,
  DialogTrigger,
  ScrollBar,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { CustomPagination, DeleteDialog, BaseToolTip, BaseLoader, TourButton } from '@common/components';
import { AddEditTeamDialog, useEditTeam, useTeamsTab } from '@pages/manageOrganization';
import { getLocalStorageItem, truncateString, useAppTours } from '@utils';
import { ROLES, CREATE_TEAM_BUTTON_ID, TOTAL_ITEMS_PER_PAGE } from '@constants';
import ProfileImage from '@assets/images/ProfileImage.png';
import { DeleteIcon, EditIcon } from '@assets/svgs';

const determineAlignment = () => {
  return window.innerWidth <= 768 ? 'end' : 'start';
};

export function TeamsTab({ manageOrganization, isDriverEnabled, setIsOptionClicked }) {
  const {
    formik,
    teamsListing,
    showCreateTeamDialog,
    setShowCreateTeamDialog,
    page,
    setPage,
    openCreateTeamDialog,
    createTeamLoading,
    scrollToRef,
    dashboardsListing,
    organizationMembersListing,
    setTeamId,
    deleteTeamLoading,
    handleDeleteTeam,
    teamsListingFetching,
  } = useTeamsTab();

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const { createTeamTour } = useAppTours();

  const {
    formik: editTeamFomik,
    isEditTeamLoading,
    setTeamId: setEditTeamId,
    setShowEditTeamDialog,
    showEditTeamDialog,
    setIsEditTeamLoading,
    scrollToRef: editScrollRef,
  } = useEditTeam();

  const [alignment, setAlignment] = useState(determineAlignment());

  useEffect(() => {
    const handleResize = () => {
      setAlignment(determineAlignment());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='flex flex-col gap-6 md:gap-8 lg:gap-6'>
      <div className='relative'>
        <div className='absolute right-0 top-4 w-full md:-top-10 md:w-[10.6875rem] lg:-top-8 lg:w-[8.0625rem] xxl:-top-[2.5rem] xxl:w-[8.5rem]'>
          {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
            <Dialog open={showCreateTeamDialog} onOpenChange={setShowCreateTeamDialog}>
              <Button id={CREATE_TEAM_BUTTON_ID} onClick={openCreateTeamDialog}>
                Create Team
              </Button>
              <AddEditTeamDialog
                setIsOptionClicked={setIsOptionClicked}
                isDriverEnabled={isDriverEnabled}
                label='Create Team'
                formik={formik}
                scrollToRef={scrollToRef}
                teamLoading={createTeamLoading}
                dashboardsListing={dashboardsListing}
                organizationMembersListing={organizationMembersListing}
              />
            </Dialog>
          )}
        </div>
        <div className='mt-[5.5rem] h-[23.8125rem] md:mt-6 md:h-[38.125rem] lg:mt-4 lg:h-[calc(100vh-16.75rem)] xxl:h-[calc(100vh-22rem)]'>
          <ScrollArea className='relative z-[1] h-full w-[calc(100vw-2.5rem)] whitespace-nowrap rounded-md border xsm:w-[27.5rem] md:w-full lg:w-auto'>
            <Table className='text-lg'>
              <TableHeader className='sticky top-0 rounded-t-lg bg-themeColor'>
                <TableRow>
                  <TableHead className='w-40 md:w-[18.3125rem] lg:w-[20rem] xxl:w-[31.25rem]'>Team</TableHead>
                  <TableHead>Members</TableHead>
                  {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
                    <TableHead className='w-[7.125rem]'>Action</TableHead>
                  )}
                </TableRow>
              </TableHeader>
              {teamsListingFetching ? (
                <BaseLoader />
              ) : (
                !isEmpty(teamsListing?.data?.results) &&
                !teamsListingFetching && (
                  <TableBody className='pt-5'>
                    {teamsListing?.data?.results?.map(team => (
                      <TableRow key={team?.id}>
                        <TableCell className='font-medium'>
                          <p id={`teamName${team?.id}`}>
                            {truncateString(team?.name, 42)}
                            {team?.name?.length > 42 && <BaseToolTip id={`teamName${team?.id}`} content={team?.name} />}
                          </p>
                        </TableCell>
                        <TableCell>
                          <div className='flex'>
                            <Popover>
                              {team?.users?.slice(0, 3)?.map(teamMember => (
                                <PopoverTrigger key={teamMember?.id}>
                                  <Avatar className='-mr-2.5 h-5 w-5 md:-mr-3 md:h-6 md:w-6 lg:-mr-2.5 lg:h-5 lg:w-5 xxl:-mr-3 xxl:h-6 xxl:w-6'>
                                    <AvatarImage
                                      src={teamMember?.profileImage?.image || ProfileImage}
                                      className='h-5 min-w-5 md:h-6 md:min-w-6 lg:h-5 lg:min-w-5 xxl:h-6 xxl:min-w-6'
                                    />
                                  </Avatar>
                                </PopoverTrigger>
                              ))}
                              <PopoverContent
                                align={alignment}
                                className='max-h-[9.25rem] w-[11.375rem] overflow-y-auto px-4 py-3 md:max-h-[9.75rem] md:w-[13.9375rem] lg:max-h-[9.25rem] lg:w-[11.375rem] xxl:max-h-[9.75rem] xxl:w-[13.9375rem]'
                              >
                                <ul className='flex flex-col gap-3'>
                                  {team?.users.map(teamMember => (
                                    <li
                                      key={teamMember?.id}
                                      className='flex items-center gap-2 text-xs font-normal leading-5 md:text-sm lg:text-xs xxl:text-sm'
                                    >
                                      <Avatar className='h-[1.375rem] w-[1.375rem] border-none md:h-6 md:w-6 lg:h-[1.375rem] lg:w-[1.375rem] xxl:h-6 xxl:w-6'>
                                        <AvatarImage
                                          src={teamMember?.profileImage?.image || ProfileImage}
                                          className='h-[1.375rem] min-w-[1.375rem] md:h-6 md:min-w-6 lg:h-[1.375rem] lg:min-w-[1.375rem] xxl:h-6 xxl:min-w-6'
                                        />
                                      </Avatar>
                                      <span
                                        className='max-w-[7.5rem] overflow-hidden whitespace-nowrap md:max-w-[8.875rem] lg:max-w-[7.5rem] xxl:max-w-[8.875rem]'
                                        id={`teamMemberPopup-${teamMember.id}`}
                                      >
                                        {truncateString(teamMember?.name, 15, 'text-darkSecondary')}
                                      </span>
                                      {team?.name?.length > 15 && (
                                        <BaseToolTip
                                          id={`teamMemberPopup-${teamMember.id}`}
                                          content={teamMember?.name}
                                        />
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </PopoverContent>
                            </Popover>
                            <Popover>
                              {team?.memberCount > 3 && (
                                <PopoverTrigger>
                                  <Avatar className='-mr-4 h-5 w-5 md:h-6 md:w-6 lg:h-5 lg:w-5 xxl:h-6 xxl:w-6'>
                                    <span className='flex w-full items-center justify-center bg-darkPrimary text-[.4375rem] font-semibold leading-[.5rem] text-white'>
                                      +{team?.memberCount - 3}
                                    </span>
                                  </Avatar>
                                </PopoverTrigger>
                              )}
                              <PopoverContent
                                align={alignment}
                                className='max-h-[9.75rem] w-[12.75rem] overflow-y-auto px-[0.9375rem] py-3'
                              >
                                <ul className='flex flex-col gap-3'>
                                  {team?.users.map(teamMember => (
                                    <li
                                      key={teamMember?.id}
                                      className='flex items-center gap-2 text-sm font-normal leading-5'
                                    >
                                      <Avatar className='h-5 w-5 items-center justify-center md:h-6 md:w-6 lg:h-5 lg:w-5 xxl:h-6 xxl:w-6'>
                                        <AvatarImage
                                          src={teamMember?.profileImage?.image || ProfileImage}
                                          className='h-5 min-w-5 md:h-6 md:min-w-6 lg:h-5 lg:min-w-5 xxl:h-6 xxl:min-w-6'
                                        />
                                      </Avatar>
                                      <span
                                        className='max-w-[8.875rem] overflow-hidden whitespace-nowrap'
                                        id={`teamMemberPopup-${teamMember.id}`}
                                      >
                                        {truncateString(teamMember?.name, 15, 'text-darkSecondary')}
                                      </span>
                                      <BaseToolTip id={`teamMemberPopup-${teamMember.id}`} content={teamMember?.name} />
                                    </li>
                                  ))}
                                </ul>
                              </PopoverContent>
                            </Popover>
                          </div>
                        </TableCell>
                        {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
                          <TableCell>
                            <div className='flex gap-1.5'>
                              <Dialog
                                open={showEditTeamDialog[team.id]}
                                onOpenChange={isOpen => {
                                  setShowEditTeamDialog({ ...showEditTeamDialog, [team.id]: isOpen });
                                  setIsEditTeamLoading(false);
                                }}
                              >
                                <span
                                  onClick={() => {
                                    setShowEditTeamDialog({ ...showEditTeamDialog, [team.id]: true });
                                    setEditTeamId(team.id);
                                    setIsEditTeamLoading(false);
                                    editTeamFomik.resetForm();
                                  }}
                                >
                                  <EditIcon className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                                </span>
                                <AddEditTeamDialog
                                  label='Edit Team'
                                  formik={editTeamFomik}
                                  scrollToRef={editScrollRef}
                                  teamLoading={isEditTeamLoading}
                                  dashboardsListing={dashboardsListing}
                                  organizationMembersListing={organizationMembersListing}
                                />
                              </Dialog>
                              <Dialog>
                                <DialogTrigger onClick={() => setTeamId(team?.id)}>
                                  <DeleteIcon className='h-5 w-5 md:h-6 md:w-6 lg:h-4 lg:w-4 xxl:h-6 xxl:w-6' />
                                </DialogTrigger>
                                <DeleteDialog
                                  deleteBtnClick={handleDeleteTeam}
                                  heading={team.name}
                                  text={team.name}
                                  className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
                                  isLoading={deleteTeamLoading}
                                />
                              </Dialog>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                )
              )}
            </Table>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        </div>
      </div>
      <div className='mb-6 md:mb-0'>
        {teamsListing?.data?.count > TOTAL_ITEMS_PER_PAGE && (
          <CustomPagination page={page} setPage={setPage} data={teamsListing} />
        )}
      </div>
      {userOrganizationRole !== ROLES.MEMBER && (
        <TourButton
          Label='“Create Team”'
          onClick={() => {
            createTeamTour().drive();
          }}
        />
      )}
    </div>
  );
}
