import * as React from 'react';
import { cn } from '@shadcnUtils';

const Input = React.forwardRef(({ inputClassName, placeholder, type, showError, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        `placeholder:slateGray flex h-12 w-full rounded border border-input bg-white px-2 py-[0.9688rem] text-sm text-black outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-normal placeholder:text-muted-foreground  focus:border-darkSecondary disabled:cursor-not-allowed disabled:opacity-50 md:h-10 md:rounded-lg md:border-2 md:px-[0.8931rem] md:py-[0.7188rem] md:text-sm lg:h-8 lg:rounded lg:border lg:px-2 lg:py-[0.5313rem] lg:text-xs xxl:h-10 xxl:rounded-md xxl:px-3 xxl:py-2.5 xxl:text-sm ${
          showError ? 'border-errorColor' : ''
        }`,
        inputClassName
      )}
      ref={ref}
      {...props}
      placeholder={placeholder}
    />
  );
});
Input.displayName = 'Input';

export { Input };
