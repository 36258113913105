import { Label, Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@shadcnComponent';

export function DisplayTabSelectField({ label, value, handleChange }) {
  return (
    <div className='flex flex-col gap-1.5'>
      <Label>{label}</Label>
      <Select value={value} onValueChange={handleChange}>
        <SelectTrigger className='bg-transparent'>
          <SelectValue placeholder='Yes' />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value={true} className='px-3 py-[0.4375rem]'>
              Yes
            </SelectItem>
            <SelectItem value={false} className='px-3 py-[0.4375rem]'>
              No
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
