import { PopoverClose } from '@shadcnComponent';
import { ChatbotIcon, CrossIcon } from '@assets/svgs';

export function ChatbotHeader() {
  return (
    <div className='chatbot-button flex h-[3.75rem] items-center justify-between rounded-t-xl bg-darkPrimary px-5 py-3'>
      <div className='flex items-center gap-3'>
        <ChatbotIcon className='h-8 w-8' />
        <div className='flex flex-col'>
          <p className='mb-[0.0625rem] text-base font-bold leading-[1.21rem] text-white'>Zen AI</p>
          {/* <span className='text-xs font-normal leading-[0.9075rem] text-white'>Online Now</span> */}
        </div>
      </div>
      <PopoverClose>
        <CrossIcon className='h-5 w-5' strokeColor='#fff' />
      </PopoverClose>
    </div>
  );
}
