import { COLORS } from '@constants';

export function TrendArrow({ color = COLORS.DARK_PRIMARY }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='7' viewBox='0 0 9 7' fill='none'>
      <path
        d='M1.68298 6.5H7.65068C8.22751 6.5 8.57158 5.85712 8.2516 5.37716L5.26776 0.901388C4.98188 0.472581 4.35178 0.472581 4.0659 0.901388L1.08206 5.37716C0.762085 5.85712 1.10615 6.5 1.68298 6.5Z'
        fill={color}
      />
    </svg>
  );
}
