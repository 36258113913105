/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcnComponent';
import { isEmpty } from 'lodash';
import { Upload } from 'lucide-react';
import { BarLoader } from 'react-spinners';
import { TableWidgetPagination, WidgetCommentBtn, WidgetMenu, WidgetTitle } from '@common/widgets';
import { convertCamelCaseToReadable } from '@utils';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function CsvWidget({
  widget,
  onRemoveClick,
  handleCsvInputChange,
  isSharedDashboard,
  isEditMode,
  handleEditWidget,
  handleClone,
  csvRef,
  isUploadedWidgetCsv,
  page,
  handleTablePagination,
  showCommentBtn,
  handleEditCommentMode,
  widgetCommentClass,
  hideTooltip,
}) {
  return (
    <div className='flex h-full flex-col gap-2'>
      <div className='flex h-6 items-center justify-between gap-3'>
        {widget?.showLabel && <WidgetTitle widget={widget} hideTooltip={hideTooltip} />}
        {!widget?.widgetData?.error && (
          <>
            {!isSharedDashboard && (
              <WidgetCommentBtn
                widget={widget}
                showCommentBtn={showCommentBtn}
                handleEditCommentMode={() => handleEditCommentMode(widget)}
                className={widgetCommentClass}
              />
            )}
          </>
        )}
        {!isSharedDashboard && isEditMode && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
          />
        )}
      </div>
      {widget?.widgetData?.error ? (
        <p
          className='text-md flex h-[calc(100%-3.4375rem)] w-full items-center justify-center font-bold'
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? {
                  color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                }
              : {
                  color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
                }
          }
        >
          {widget?.widgetData?.error}
        </p>
      ) : (
        <>
          {isEmpty(widget?.widgetData?.data?.csv) && (
            <div className='relative flex h-10 items-center justify-center rounded border border-darkPrimary px-3 py-2 text-xs leading-[0.9075rem] focus:outline-none focus:ring-0'>
              <input
                type='file'
                accept='.csv'
                onChange={e => handleCsvInputChange(e, widget)}
                ref={csvRef}
                className='w-available absolute z-10 h-full w-full opacity-0'
              />
              <div className='flex items-center gap-2.5 text-sm font-medium leading-6'>
                <Upload width={20} height={20} />
                Upload
                {isUploadedWidgetCsv && <BarLoader />}
              </div>
            </div>
          )}
          {!isEmpty(widget?.widgetData?.data?.csv) && (
            <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto'>
              <div className='relative z-[1]'>
                <Table>
                  <TableHeader
                    className='sticky top-0 rounded-t-lg'
                    style={
                      widget?.themeColor === DEFAULT_VALUE
                        ? {
                            color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                            backgroundColor:
                              widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.THEME_COLOR,
                          }
                        : {
                            backgroundColor:
                              widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : COLORS.THEME_COLOR,
                          }
                    }
                  >
                    <TableRow>
                      {Object.entries(widget?.widgetData?.data?.csv[0])?.map(([key], i) => (
                        <TableHead
                          key={i}
                          className='border bg-white !px-[1rem] !py-3.5'
                          style={
                            widget?.themeColor === DEFAULT_VALUE
                              ? {
                                  color:
                                    widget?.themeStyle === THEME_STYLE.DARK
                                      ? COLORS.WHITE_COLOR
                                      : COLORS.DARK_SECONDARY,
                                  backgroundColor:
                                    widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.THEME_COLOR,
                                  borderColor:
                                    widget?.themeStyle === THEME_STYLE.DARK
                                      ? COLORS.WHITE_COLOR
                                      : COLORS.DARK_SECONDARY,
                                }
                              : {
                                  backgroundColor:
                                    widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : COLORS.THEME_COLOR,
                                  color:
                                    widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
                                  borderColor:
                                    widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
                                }
                          }
                        >
                          {convertCamelCaseToReadable(key)}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody className='pt-5'>
                    {widget?.widgetData?.data?.csv?.map((row, i) => (
                      <TableRow key={i}>
                        {Object.entries(row)?.map(([key, value], i) => (
                          <TableCell
                            key={i}
                            className='pointer-events-none border !px-[1.875rem] !py-3.5'
                            style={
                              widget?.themeColor === DEFAULT_VALUE
                                ? {
                                    color:
                                      widget?.themeStyle === THEME_STYLE.DARK
                                        ? COLORS.WHITE_COLOR
                                        : COLORS.DARK_SECONDARY,
                                    borderColor:
                                      widget?.themeStyle === THEME_STYLE.DARK
                                        ? COLORS.WHITE_COLOR
                                        : COLORS.DARK_SECONDARY,
                                  }
                                : {
                                    color:
                                      widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
                                    borderColor:
                                      widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
                                  }
                            }
                          >
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {widget?.widgetData?.data?.count > 1 && (
                  <div className='my-6'>
                    <TableWidgetPagination page={page} widget={widget} handleTablePagination={handleTablePagination} />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
