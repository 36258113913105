import { EmptyDashboardIcon } from '@assets/svgs';

export function EmptyDashboard() {
  return (
    <div className='flex h-full flex-col items-center justify-center gap-3 md:gap-6 xxl:gap-8'>
      <EmptyDashboardIcon className='h-[12.5rem] w-[16.75rem] md:h-[21.25rem] md:w-[21.25rem] lg:h-[12.5rem] lg:w-[16.75rem] xxl:h-[21.3125rem] xxl:w-[28.625rem]' />
      <p className='text-sm font-normal leading-6 md:text-base md:leading-5 lg:text-xs lg:leading-[.9375rem] xxl:text-base xxl:leading-5'>
        You don’t have any dashboard yet
      </p>
    </div>
  );
}
