import { CategoryScale } from 'chart.js';
// eslint-disable-next-line import/no-named-as-default
import Chart from 'chart.js/auto';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { EmptyWidget } from '@common/widgets';
import { COLORS, DATE_FORMAT, DEFAULT_VALUE, INVALID_DATE, THEME_STYLE } from '@constants';

Chart.register(CategoryScale);

export function BarChartWidget({ widget, isSharedDashboard, isWidgetComparisonFilterApply, size, xSize }) {
  const options = {
    animation: isSharedDashboard || isWidgetComparisonFilterApply ? false : true,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
          lineWidth: 1,
        },
        ticks: {
          color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
          font: {
            size: size,
          },
        },
      },
      x: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
          lineWidth: 1,
        },
        ticks: {
          color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
          font: {
            size: xSize,
          },
        },
      },
    },
  };

  const labels = widget?.widgetData?.data?.map(xAxis => {
    return moment(xAxis?.label).format(DATE_FORMAT.BILLING_DATE) === INVALID_DATE
      ? xAxis?.label
      : moment(xAxis?.label).format(DATE_FORMAT.BILLING_DATE);
  });

  const data = {
    labels,
    datasets: [
      {
        data: widget?.widgetData?.data?.map(xAxis => xAxis?.data),
        backgroundColor: `${widget?.themeColor === DEFAULT_VALUE ? (widget.themeStyle === THEME_STYLE.DARK ? `${COLORS.WHITE_COLOR}80` : `${COLORS.DARK_PRIMARY}80`) : `${widget.foreground}80`}`,
        borderWidth: 1,
        borderColor:
          widget?.themeColor === DEFAULT_VALUE
            ? widget.themeStyle === THEME_STYLE.DARK
              ? COLORS.WHITE_COLOR
              : COLORS.DARK_PRIMARY
            : widget.foreground,
        borderRadius: 5,
        barThickness: 20,
        base: 0,
        indexAxis: 'x',
      },
      {
        data: widget?.widgetData?.data?.map(xAxis => xAxis?.data2),
        backgroundColor: `${COLORS.MAP_WIDGET_HOVER_COLOR}80`,
        borderWidth: 1,
        borderColor: COLORS.MAP_WIDGET_HOVER_COLOR,
        borderRadius: 5,
        barThickness: 20,
        base: 0,
        indexAxis: 'x',
      },
    ],
  };
  return isEmpty(data?.labels) ? <EmptyWidget widget={widget} /> : <Bar options={options} data={data} />;
}
