import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, usePatchMutation, usePostMutation } from '@services';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils';
import {
  ACCESS_TOKEN,
  COUNT_DOWN_TIME,
  COUNT_DOWN_TIME_TEXT,
  EMAIL,
  ERROR_MESSAGE,
  IS_OTP_EXPIRE,
  LOGIN_STATE,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  OTP_VERIFY_MESSAGE,
  SEND_OTP_MESSAGE,
  STATE,
} from '@constants';

export function useOTPVerification() {
  const navigate = useNavigate();

  const location = useLocation();

  const countDownTime = getLocalStorageItem(COUNT_DOWN_TIME_TEXT);
  const email = getLocalStorageItem(EMAIL);
  const hmacValue = getLocalStorageItem('hmac');
  const hostValue = getLocalStorageItem('host');
  const timestampValue = getLocalStorageItem('timestamp');
  const codeValue = getLocalStorageItem('code');
  const shopValue = getLocalStorageItem('shop');
  const stateValue = getLocalStorageItem('state');

  const [otp, setOtp] = useState('');
  const [showOtpError, setShowOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(countDownTime || COUNT_DOWN_TIME);
  const [restartCountDown, setRestartCountDown] = useState(false);
  const [isOtpExpire, setIsOtpExpire] = useState(getLocalStorageItem(IS_OTP_EXPIRE));

  const { mutate: addUser } = usePatchMutation(
    `${apiEndpoints.OAUTH_ADD_USER}?code=${codeValue}&host=${hostValue}&timestamp=${timestampValue}&shop=${shopValue}&state=${stateValue}&hmac=${hmacValue}`,
    () => {
      navigate(`/oauth/shopify/redirect/?code=${codeValue}&shop=${shopValue}&state=${stateValue}`);
      setLocalStorageItem('showConnector', {
        2: true,
        dataSource: {
          id: 2,
          sourceName: 'SHOPIFY',
          description: 'Shopify shop connector',
          logo: {
            id: 540,
            image: 'https://stage.zendash.com/django-media/images/2024/03/shopify-logo-png-transparent.png',
          },
        },
      });
      removeLocalStorageItem('code');
      removeLocalStorageItem('shop');
      removeLocalStorageItem('state');
      removeLocalStorageItem('hmac');
      removeLocalStorageItem('host');
      removeLocalStorageItem('timestamp');
    },
    ({ response: { data } }) => data?.non_field_errors?.map(errMsg => toast.error(errMsg))
  );

  const { mutate: handleOTPVerification, isLoading: isOTPLoading } = usePostMutation(
    apiEndpoints.VERIFY_OTP,
    resp => {
      toast.success(OTP_VERIFY_MESSAGE);
      setShowOtpError(false);
      setOtpErrorMessage('');
      setLocalStorageItem(ACCESS_TOKEN, resp?.data?.access);
      setLocalStorageItem(STATE, resp?.data?.state);
      removeLocalStorageItem(COUNT_DOWN_TIME_TEXT);
      setLocalStorageItem('defaultOrganizationId', resp?.data?.defaultOrganization);
      if (
        resp?.data?.access &&
        hmacValue &&
        hostValue &&
        timestampValue &&
        codeValue &&
        shopValue &&
        stateValue &&
        resp?.data?.state === LOGIN_STATE.ON_BOARDED
      ) {
        addUser({});
      } else {
        if (location.state) {
          setLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE, location.state);
        }
        window.location.reload();
      }
    },
    ({ response: { data } }) => {
      setShowOtpError(true);
      data?.otp?.map(errMsg => {
        setOtpErrorMessage(errMsg);
        toast.error(errMsg);
      });
    }
  );

  const { mutate: handleResend } = usePostMutation(
    apiEndpoints.EXPRESS_LOGIN,
    () => {
      setTimeRemaining(COUNT_DOWN_TIME);
      setRestartCountDown(!restartCountDown);
      setLocalStorageItem(IS_OTP_EXPIRE, false);
      setIsOtpExpire(false);
      toast.success(SEND_OTP_MESSAGE);
    },
    () => toast.error(ERROR_MESSAGE)
  );

  const minutes = String(Math.floor(timeRemaining / 60)).padStart(2, '0');
  const seconds = String(timeRemaining % 60).padStart(2, '0');
  const countdown = isOtpExpire ? '00:00' : `${minutes}:${seconds}`;

  useEffect(() => {
    const COUNT_DOWN = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime <= 0) {
          clearInterval(COUNT_DOWN);
          setLocalStorageItem(IS_OTP_EXPIRE, true);
          setIsOtpExpire(true);
          setLocalStorageItem(COUNT_DOWN_TIME_TEXT, 0);
          return 0;
        } else {
          setLocalStorageItem(COUNT_DOWN_TIME_TEXT, prevTime - 1);
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(COUNT_DOWN);
      removeLocalStorageItem(COUNT_DOWN_TIME_TEXT);
    };
  }, [restartCountDown]);

  return {
    email,
    otp,
    setOtp,
    showOtpError,
    otpErrorMessage,
    countdown,
    timeRemaining,
    handleOTPVerification,
    handleResend,
    isOtpExpire,
    isOTPLoading,
  };
}
