import { Link } from 'react-router-dom';
import { Button } from '@shadcnComponent';
import { ROUTES } from '@routes';
import { MEMBERSHIP_CONSUMED } from '@constants';
import { Logo, SubscriptionExpiredIcon } from '@assets/svgs';

export function SubscriptionExpired() {
  return (
    <div className='relative h-screen'>
      <div className='absolute left-5 top-6 md:left-8 md:top-10 lg:left-[1.875rems] lg:top-[1.625rem] xxl:left-10 xxl:top-10'>
        <Logo className='lg:!h-6 lg:!w-[8.1875rem] xxl:!h-8 xxl:!w-[10.625rem]' />
      </div>
      <div className='mx-auto flex h-full w-[calc(100vw-2.5rem)] max-w-[24.375rem] flex-col items-center justify-center gap-8 text-center md:max-w-[33.125rem] lg:max-w-[30.5rem] lg:gap-10 xxl:max-w-[33.125rem] xxl:gap-8'>
        <SubscriptionExpiredIcon className='h-[11.75rem] w-[15.8125rem] md:h-[17.5rem] md:w-[23.5rem] lg:h-[11.75rem] lg:w-[15.8125rem] xxl:h-[17.5rem] xxl:w-[23.5rem]' />
        <div className='flex w-full flex-col gap-6 md:gap-10 lg:gap-6'>
          <div className='flex flex-col items-center gap-3 md:gap-4 lg:gap-3 xxl:gap-4'>
            <h1 className='text-xl font-semibold leading-7 md:text-2xl md:leading-[1.8156rem] lg:text-xl lg:leading-[1.5125rem] xxl:text-2xl xxl:leading-[1.8125rem]'>
              Subscription Expired
            </h1>
            <p className='max-w-[21.0625rem] text-sm font-normal leading-6 md:max-w-full md:text-base md:leading-7 lg:text-sm lg:leading-6 xxl:text-base xxl:leading-7'>
              {MEMBERSHIP_CONSUMED}.
            </p>
          </div>
          <Link to={ROUTES.HOME}>
            <Button className='w-full md:w-[21.875rem] lg:w-[13.75rem] xxl:w-[17.5rem]'>Go back</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
