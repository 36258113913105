import { CreateEditReport, useCreateReport } from '@pages/reports';

export function CreateReport() {
  const {
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    emailFieldErrorMessage,
    invalidEmails,
    selectDay,
    handleSelectedDay,
    createReportLoading,
  } = useCreateReport();

  return (
    <CreateEditReport
      formik={formik}
      handleInvitedEmailKeyDown={handleInvitedEmailKeyDown}
      invitedEmails={invitedEmails}
      handleRemoveInvitedEmail={handleRemoveInvitedEmail}
      emailFieldErrorMessage={emailFieldErrorMessage}
      invalidEmails={invalidEmails}
      selectDay={selectDay}
      handleSelectedDay={handleSelectedDay}
      scheduleLoading={createReportLoading}
    />
  );
}
