import { capitalizeSourceName } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';
import { PlusIconDark } from '@assets/svgs';

export function AddConnectButton({
  handleConnectNewAccount,
  connectedDataSourceLoading,
  showNameField,
  nameField,
  dataSource,
  checkedOauthId,
}) {
  const getButtonText = (dataSource, showNameField) => {
    const nameFields = {
      [UPPERCASE_DATA_SOURCES.WOO_COMMERCE]: 'Store Name',
      [UPPERCASE_DATA_SOURCES.BING_ADS]: 'Account Name',
    };

    if (
      [UPPERCASE_DATA_SOURCES.WOO_COMMERCE, UPPERCASE_DATA_SOURCES.BING_ADS].includes(
        capitalizeSourceName(dataSource?.sourceName)
      )
    ) {
      return showNameField
        ? 'Connect New Account'
        : `Add ${nameFields[capitalizeSourceName(dataSource?.sourceName)] || 'Name'}`;
    }
    return 'Connect New Account';
  };

  const buttonText = getButtonText(dataSource, showNameField);

  return (
    <div
      className={`flex h-11 cursor-pointer items-center justify-center gap-1.5 border-t border-lightGray text-sm font-normal leading-[1.0588rem] md:h-[3.25rem] md:gap-2 lg:h-10 lg:gap-1.5 lg:text-xs lg:leading-[0.9075rem] xxl:h-[3.25rem] xxl:gap-2 xxl:text-sm xxl:leading-[1.0588rem] ${connectedDataSourceLoading ? 'pointer-events-none opacity-[.6]' : (showNameField && !nameField) || !!checkedOauthId ? 'pointer-events-none opacity-[.6]' : ''}`}
      onClick={handleConnectNewAccount}
    >
      <PlusIconDark />
      {buttonText}
    </div>
  );
}
